import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import { remuneracionActions } from "./redux";
import { remuneracionApi } from "./api";
import { ObjectToString, getToken } from "src/utils/helpers";
import { ErrorToast } from "src/modules/common/components/ErrorToast";
import { SuccessToast } from "src/modules/common/components/SuccessToast";
import { push } from "@lagunovsky/redux-react-router";
import { commonActions } from "src/modules/common/handler/redux";
import { RutasRemuneracion } from "./routes";

function* getEscalaReporteSaga({ payload }) {
  const token = getToken();
  const escala_reporte = yield select(
    (state) => state.remuneracion.escala_reporte
  );
  const escala_reporte_filter = yield select(
    (state) => state.remuneracion.escala_reporte_filter
  );
  const { id } = payload;
  const params = {
    id: !!id ? id : escala_reporte.id,
    filter: escala_reporte_filter,
  };
  //
  try {
    const data = yield call(remuneracionApi(token).getEscalaReporte, params);
    yield put(remuneracionActions.setEscalaReporte(data));
    yield put(remuneracionActions.setEscalaReporteComplete());
  } catch (error) {
    const errores = error.response.data;
    const message = ObjectToString(errores);
    ErrorToast({ message: message });
    yield put(remuneracionActions.setEscalaReporteComplete());
  }
}

function* getColaboradoresEscalaSaga({ payload }) {
  const token = getToken();
  const escala_reporte = yield select(
    (state) => state.remuneracion.escala_reporte
  );
  const { id } = payload;
  const colaboradores_escala_filter = yield select(
    (state) => state.remuneracion.colaboradores_escala_filter
  );
  const params = {
    id: !!id ? id : escala_reporte.id,
    filter: colaboradores_escala_filter,
  };
  //
  try {
    const data = yield call(
      remuneracionApi(token).getColaboradoresEscala,
      params
    );
    yield put(remuneracionActions.setColaboradoresEscala(data));
    yield put(remuneracionActions.setColaboradoresEscalaComplete());
  } catch (error) {
    const errores = error.response.data;
    const message = ObjectToString(errores);
    ErrorToast({ message: message });
    yield put(remuneracionActions.setColaboradoresEscalaComplete());
  }
}

function* getColaboradoresComparativoSaga({ payload }) {
  const token = getToken();
  const escala_reporte = yield select(
    (state) => state.remuneracion.escala_reporte
  );
  const { id } = payload;
  const colaboradores_comparativo_filter = yield select(
    (state) => state.remuneracion.colaboradores_comparativo_filter
  );
  const params = {
    id: !!id ? id : escala_reporte.id,
    filter: colaboradores_comparativo_filter,
  };
  //
  try {
    const data = yield call(
      remuneracionApi(token).getColaboradoresEscala,
      params
    );
    yield put(remuneracionActions.setColaboradoresComparativo(data));
    yield put(remuneracionActions.setColaboradoresComparativoComplete());
  } catch (error) {
    const errores = error.response.data;
    const message = ObjectToString(errores);
    ErrorToast({ message: message });
    yield put(remuneracionActions.setColaboradoresComparativoComplete());
  }
}

function* activarEscalaSaga({ payload }) {
  const token = getToken();
  const params = { id: payload.id };
  try {
    const data = yield call(
      remuneracionApi(token).activarEscalaSalarial,
      params
    );
    yield put(remuneracionActions.activarEscalaSalarialComplete());
    yield put(push(RutasRemuneracion.ESCALA));
    yield put(remuneracionActions.getEscalasSalariales());
    SuccessToast({ message: "Escala activada con éxito!" });
  } catch (error) {
    const errores = error.response.data;
    const message = ObjectToString(errores);
    ErrorToast({ message: message });
    yield put(remuneracionActions.activarEscalaSalarialComplete());
  }
}
function* getFactoresBaseSaga({ payload }) {
  const token = getToken();
  const params = { ...payload };
  try {
    const data = yield call(remuneracionApi(token).getFactoresBase, {});
    yield put(remuneracionActions.setFactoresBase(data.results));
    yield put(remuneracionActions.getFactoresBaseComplete());
  } catch (error) {
    const errores = error.response.data;
    const message = ObjectToString(errores);
    ErrorToast({ message: message });
    yield put(remuneracionActions.getFactoresBaseComplete());
  }
}

function* escalaSalarialBulkDeleteSaga({ payload }) {
  const token = getToken();
  const escalas_salariales_ids = yield select(
    (state) => state.remuneracion.escalas_salariales_ids
  );
  //
  try {
    const data = yield call(
      remuneracionApi(token).bulkDeleteEscalaSalarial,
      escalas_salariales_ids
    );
    //
    yield put(remuneracionActions.getEscalasSalariales());
    yield put(remuneracionActions.resetEscalasSalarialesChecked());
    SuccessToast({ message: "Escalas eliminadas con éxito!" });
  } catch (error) {
    const errores = error.response.data;
    const message = ObjectToString(errores);
    ErrorToast({ message: message });
    yield put(remuneracionActions.createEscalaSalarialComplete());
  }
}
function* escalaSalarialCreateSaga({ payload }) {
  const token = getToken();
  const escalaSalarial = yield select(
    (state) => state.remuneracion.escala_salarial
  );
  const params = { ...escalaSalarial };
  //
  try {
    const data = yield call(
      remuneracionApi(token).createEscalaSalarial,
      params
    );
    yield put(remuneracionActions.setEscalaSalarial(data));
    //
    yield put(remuneracionActions.createEscalaSalarialComplete());
    yield put(push(`/remuneracion/escala/${data.id}/valoracion`));
    // SuccessToast({ message: "Evaluación creada con éxito!" });
  } catch (error) {
    const errores = error.response.data;
    const message = ObjectToString(errores);
    ErrorToast({ message: message });
    yield put(remuneracionActions.createEscalaSalarialComplete());
  }
}
function* escalaSalarialUpdateSaga({ payload }) {
  const token = getToken();
  const escalaSalarial = yield select(
    (state) => state.remuneracion.escala_salarial
  );
  const params = {
    id: escalaSalarial.id,
    params: escalaSalarial,
  };
  //
  try {
    const data = yield call(
      remuneracionApi(token).updateEscalaSalarial,
      params
    );
    yield put(remuneracionActions.setEscalaSalarial(data));
    //
    yield put(remuneracionActions.createEscalaSalarialComplete());
    yield put(push(`/remuneracion/escala/${data.id}/valoracion`));
    SuccessToast({ message: "Configuración actualizada con éxito!" });
  } catch (error) {
    const errores = error.response.data;
    const message = ObjectToString(errores);
    ErrorToast({ message: message });
    yield put(remuneracionActions.createEscalaSalarialComplete());
  }
}
function* escalasSalarialesSaga({ payload }) {
  const token = getToken();
  const escalas_salariales_filter = yield select(
    (state) => state.remuneracion.escalas_salariales_filter
  );
  //
  try {
    const data = yield call(remuneracionApi(token).getEscalasSalariales, {
      ...escalas_salariales_filter,
    });
    //
    yield put(remuneracionActions.setEscalasSalariales(data));
  } catch (error) {
    yield put(remuneracionActions.setEscalasSalariales({}));
    const errores = error.response.data;
    const message = ObjectToString(errores);
    ErrorToast({ message: message });
  }
}
function* escalaSalarialDetailSaga({ payload }) {
  const token = getToken();
  try {
    if (!payload) {
      return;
    }
    const data = yield call(remuneracionApi(token).getEscalaSalarial, payload);
    let dataCopy = { ...data, fecha_apertura_aux: data.fecha_apertura };
    //
    yield put(remuneracionActions.setEscalaSalarial(dataCopy));
  } catch (error) {
    const errores = error.response?.data || [];
    const message = ObjectToString(errores);
    ErrorToast({ message: message });
  }
}
function* duplicarEscalaSalarialSaga({ payload }) {
  const token = getToken();
  try {
    if (!payload) {
      return;
    }
    const data = yield call(
      remuneracionApi(token).duplicarEscalaSalarial,
      payload
    );
    yield put(remuneracionActions.getEscalasSalariales());
  } catch (error) {
    const errores = error.response.data;
    const message = ObjectToString(errores);
    ErrorToast({ message: message });
  }
}
// CONFIGURACION
function* escalaSalarialConfiguracionDetailSaga({ payload }) {
  const token = getToken();
  try {
    if (!payload) {
      return;
    }
    const data = yield call(
      remuneracionApi(token).getConfiguracionEscala,
      payload
    );
    //
    yield put(remuneracionActions.setEscalaConfiguracion(data));
  } catch (error) {
    const errores = error.response.data;
    const message = ObjectToString(errores);
    ErrorToast({ message: message });
    yield put(remuneracionActions.updateEscalaConfiguracionComplete());
  }
}
function* escalaSalarialConfiguracionUpdateSaga({ payload }) {
  const token = getToken();
  const escalaConfiguracion = yield select(
    (state) => state.remuneracion.escala_configuracion
  );
  const params = {
    id: escalaConfiguracion.id,
    escala_salarial: escalaConfiguracion,
  };
  //
  try {
    const data = yield call(
      remuneracionApi(token).updateConfiguracionEscala,
      params
    );
    yield put(remuneracionActions.setEscalaConfiguracion(data));
    //
    yield put(remuneracionActions.updateEscalaConfiguracionComplete());
    yield put(
      remuneracionActions.getEscalaConfiguracion({ id: escalaConfiguracion.id })
    );
    SuccessToast({ message: "Configuración actualizada con éxito!" });
  } catch (error) {
    const errores = error.response.data;
    const message = ObjectToString(errores);
    ErrorToast({ message: message });
    yield put(remuneracionActions.updateEscalaConfiguracionComplete());
  }
}
// VALORACION CARGOS
function* valoracionesCargosSaga({ payload }) {
  const token = getToken();
  const escalaSalarial = yield select(
    (state) => state.remuneracion.escala_salarial
  );
  const valoraciones_cargos_filter = yield select(
    (state) => state.remuneracion.valoraciones_cargos_filter
  );
  if (!escalaSalarial.id) {
    return;
  }
  //
  try {
    const data = yield call(remuneracionApi(token).getValoracionesCargos, {
      id: escalaSalarial.id, //payload.id,
      filter: valoraciones_cargos_filter,
    });
    //
    yield put(remuneracionActions.setValoracionesCargos(data));
    yield put(remuneracionActions.setValoracionesCargosComplete());
  } catch (error) {
    yield put(remuneracionActions.setValoracionesCargosComplete());
    const errores = error.response?.data;
    let message = "Ocurrió un error.";
    if (errores) {
      message = ObjectToString(errores);
    }
    ErrorToast({ message: message });
  }
}
function* updateValoracionesCargosSaga({ payload }) {
  const token = getToken();
  const params = { ...payload };
  //
  try {
    const data = yield call(
      remuneracionApi(token).updateValoracionesCargos,
      params
    );
    yield put(remuneracionActions.getValoracionesCargos());
    yield put(remuneracionActions.updateValoracionesCargosComplete());
    yield put(remuneracionActions.setCategorias([]));
    SuccessToast({ message: "Cargos valorados con éxito!" });
    // yield put(push(`/remuneracion/escala/${params.id}/categorizacion`));
  } catch (error) {
    yield put(remuneracionActions.updateValoracionesCargosComplete());
    ErrorToast({ message: "Ocurrió un error." });
  }
}
// CATEGORIAS
function* createCategoriasSaga({ payload }) {
  const token = getToken();
  const categorias = yield select((state) => state.remuneracion.categorias);
  const params = { id: payload.id, categorias: categorias };
  //
  if (!payload.id) {
    return;
  }
  try {
    const data = yield call(remuneracionApi(token).createCategorias, params);
    //
    yield put(remuneracionActions.createCategoriasComplete());
    yield put(commonActions.setSelectModal({ open: false }));
    yield put(remuneracionActions.getCategorias({ id: payload.id }));
    SuccessToast({ message: "Categorías actualizadas con éxito!" });
  } catch (error) {
    const errores = error.response.data;
    const message = ObjectToString(errores);
    ErrorToast({ message: message });
    yield put(remuneracionActions.createCategoriasComplete());
  }
}
function* getCategoriasSaga({ payload }) {
  const token = getToken();
  const categorias_filter = yield select(
    (state) => state.remuneracion.categorias_filter
  );
  const params = { id: payload.id, filter: categorias_filter };
  try {
    if (!payload.id) {
      return;
    }
    const data = yield call(remuneracionApi(token).getCategorias, params);
    //
    yield put(remuneracionActions.setCategorias(data.results));
    yield put(remuneracionActions.setCategoriasComplete());
  } catch (error) {
    const errores = error.response.data;
    const message = ObjectToString(errores);
    ErrorToast({ message: message });
    yield put(remuneracionActions.setCategoriasComplete());
  }
}
// SALARIOS
function* salariosColaboradoresSaga({ payload }) {
  const token = getToken();
  const salarios_colaboradores_filter = yield select(
    (state) => state.remuneracion.salarios_colaboradores_filter
  );
  //
  try {
    const data = yield call(remuneracionApi(token).getSalariosColaboradores, {
      ...salarios_colaboradores_filter,
    });
    //
    yield put(remuneracionActions.setSalariosColaboradores(data));
  } catch (error) {
    const errores = error.response.data;
    const message = ObjectToString(errores);
    ErrorToast({ message: message });
  }
}

function* salarioColaboradorUpdateSaga({ payload }) {
  const token = getToken();
  const params = { ...payload };
  //
  try {
    const data = yield call(
      remuneracionApi(token).editSalarioColaborador,
      params
    );
    yield put(remuneracionActions.getSalariosColaboradores());
    yield put(remuneracionActions.salarioColaboradorEditarComplete());
    SuccessToast({ message: "Salario editado con éxito!" });
  } catch (error) {
    yield put(remuneracionActions.salarioColaboradorEditarComplete());
    ErrorToast({ message: "Ocurrió un error." });
  }
}

// SALARIOS MERCADO
function* salariosMercadoSaga({ payload }) {
  const token = getToken();
  const salarios_mercado_filter = yield select(
    (state) => state.remuneracion.salarios_mercado_filter
  );
  //
  try {
    const data = yield call(
      remuneracionApi(token).getSalariosMercado,
      salarios_mercado_filter
    );
    //
    yield put(remuneracionActions.setSalariosMercado(data));
  } catch (error) {
    const errores = error.response.data;
    const message = ObjectToString(errores);
    ErrorToast({ message: message });
  }
}
function* salarioMercadoUpdateSaga({ payload }) {
  const token = getToken();
  const params = { ...payload };
  //
  try {
    const data = yield call(remuneracionApi(token).editSalarioMercado, params);
    yield put(remuneracionActions.getSalariosMercado());
    yield put(remuneracionActions.salarioMercadoEditarComplete());
    SuccessToast({ message: "Salario editado con exito!" });
  } catch (error) {
    yield put(remuneracionActions.salarioMercadoEditarComplete());
    ErrorToast({ message: "Ocurrió un error." });
  }
}
function* importarSalariosMercadoSaga({ payload }) {
  const token = getToken();
  const fileToImportar = yield select(
    (state) => state.remuneracion.fileToImportar
  );
  //
  try {
    const data = yield call(remuneracionApi(token).importarSalariosMercado, {
      fileToImportar,
    });
    yield put(remuneracionActions.setFileToUploadImportarMercadoResponse(data));
    yield put(remuneracionActions.getSalariosMercado());
  } catch (error) {
    ErrorToast({ message: "Hubieron errores en la importación!" });
  }
}
// FIN SALARIOS MERCADO

function* importarSalariosSaga({ payload }) {
  const token = getToken();
  const fileToImportar = yield select(
    (state) => state.remuneracion.fileToImportar
  );
  //
  try {
    const data = yield call(remuneracionApi(token).importarSalarios, {
      fileToImportar,
    });
    yield put(remuneracionActions.setFileToUploadImportarResponse(data));
    yield put(remuneracionActions.getSalariosColaboradores());
  } catch (error) {
    ErrorToast({ message: "Hubieron errores en la importación!" });
  }
}

export function* remuneracionSagas() {
  yield takeEvery(
    remuneracionActions.getEscalaReporte.type,
    getEscalaReporteSaga
  );
  yield takeLatest(
    remuneracionActions.setEscalaReporteFilter.type,
    getEscalaReporteSaga
  );
  //
  yield takeEvery(
    remuneracionActions.getColaboradoresEscala.type,
    getColaboradoresEscalaSaga
  );
  yield takeLatest(
    remuneracionActions.setColaboradoresEscalaFilter.type,
    getColaboradoresEscalaSaga
  );
  //
  yield takeEvery(
    remuneracionActions.getColaboradoresComparativo.type,
    getColaboradoresComparativoSaga
  );
  yield takeLatest(
    remuneracionActions.setColaboradoresComparativoFilter.type,
    getColaboradoresComparativoSaga
  );
  //
  yield takeEvery(
    remuneracionActions.activarEscalaSalarial.type,
    activarEscalaSaga
  );
  //
  yield takeEvery(
    remuneracionActions.getFactoresBase.type,
    getFactoresBaseSaga
  );
  yield takeEvery(
    remuneracionActions.setEscalasIdsDelete.type,
    escalaSalarialBulkDeleteSaga
  );
  //
  yield takeEvery(
    remuneracionActions.createEscalaSalarial.type,
    escalaSalarialCreateSaga
  );
  yield takeEvery(
    remuneracionActions.updateEscalaSalarial.type,
    escalaSalarialUpdateSaga
  );
  yield takeEvery(
    remuneracionActions.getEscalaSalarial.type,
    escalaSalarialDetailSaga
  );
  yield takeEvery(
    remuneracionActions.getEscalasSalariales.type,
    escalasSalarialesSaga
  );
  yield takeLatest(
    remuneracionActions.setEscalasSalarialesFilter.type,
    escalasSalarialesSaga
  );
  yield takeEvery(
    remuneracionActions.duplicarEscalaSalarial.type,
    duplicarEscalaSalarialSaga
  );
  // CONFIGURACION
  yield takeEvery(
    remuneracionActions.getEscalaConfiguracion.type,
    escalaSalarialConfiguracionDetailSaga
  );
  yield takeEvery(
    remuneracionActions.updateEscalaConfiguracion.type,
    escalaSalarialConfiguracionUpdateSaga
  );
  // VALORACION CARGOS
  yield takeEvery(
    remuneracionActions.getValoracionesCargos.type,
    valoracionesCargosSaga
  );
  yield takeEvery(
    remuneracionActions.filterValoracionesCargos.type,
    valoracionesCargosSaga
  );
  yield takeEvery(
    remuneracionActions.updateValoracionesCargos.type,
    updateValoracionesCargosSaga
  );
  // CATEGORIAS
  yield takeEvery(remuneracionActions.getCategorias.type, getCategoriasSaga);
  yield takeEvery(
    remuneracionActions.createCategorias.type,
    createCategoriasSaga
  );
  // SALARIOS
  yield takeEvery(
    remuneracionActions.getSalariosColaboradores.type,
    salariosColaboradoresSaga
  );
  yield takeLatest(
    remuneracionActions.setSalariosColaboradoresFilter.type,
    salariosColaboradoresSaga
  );
  yield takeEvery(
    remuneracionActions.setSalarioColaboradorEditar.type,
    salarioColaboradorUpdateSaga
  );
  //setFileToUploadImportarMercado
  yield takeEvery(
    remuneracionActions.setFileToUploadImportar.type,
    importarSalariosSaga
  );
  // SALARIOS MERCADO
  yield takeEvery(
    remuneracionActions.getSalariosMercado.type,
    salariosMercadoSaga
  );
  yield takeLatest(
    remuneracionActions.setSalariosMercadoFilter.type,
    salariosMercadoSaga
  );
  yield takeEvery(
    remuneracionActions.setSalarioMercadoEditar.type,
    salarioMercadoUpdateSaga
  );
  yield takeEvery(
    remuneracionActions.setFileToUploadImportarMercado.type,
    importarSalariosMercadoSaga
  );
}
