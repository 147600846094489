import { createSlice } from "@reduxjs/toolkit";

const beneficioSlice = createSlice({
  name: "Beneficio",
  initialState: {
    beneficios: [],
    beneficios_count: 0,
    beneficios_loading: false,
    beneficios_filter: {},
    beneficios_checked: [],
    //
    beneficio: {},
    beneficio_loading: true,
    //
    tipos_configuraciones: [],
    tipos_configuraciones_loading: false,
    //
    beneficios_body: {}, // Se utiliza para guardar el cuerpo del json para guardar varios beneficios a la vez en Nuevo beneficio de la pestaña principal
    beneficios_body_loading: false,
    // Para los modal que aparecen para seleccionar cargos cuando se crea un beneficio
    cargos_habilitados: [],
    cargos_habilitados_loading: false,
    cargos_habilitados_count: 0,
    cargos_habilitados_filter: {},
    cargos_habilitados_checked: [],
    // Para los modal que aparecen para seleccionar colaboradores cuando se crea un beneficio
    colaboradores_habilitados: [],
    colaboradores_habilitados_loading: false,
    colaboradores_habilitados_count: 0,
    colaboradores_habilitados_filter: {},
    colaboradores_habilitados_checked: [],
    // Para la grilla de la pestaña Cargos
    cargo: {},
    cargo_loading: false,
    cargos_list: [],
    cargos_list_count: 0,
    cargos_list_loading: false,
    cargos_list_filter: {},
    cargos_list_checked: [],
    // Para los beneficios que estan relacionados a un cargo (de la pestaña Cargos)
    beneficios_cargo: [],
    beneficios_cargo_count: 0,
    beneficios_cargo_loading: false,
    // Para la grilla de la pestaña Colaboradores
    colaboradores_list: [],
    colaboradores_list_count: 0,
    colaboradores_list_loading: false,
    colaboradores_list_filter: {},
    colaboradores_list_checked: [],
    // Para la grilla de los beneficios al entrar a ver los detalles de un colaborador
    beneficios_colaborador: [],
    beneficios_colaborador_count: 0,
    beneficios_colaborador_loading: false,
    beneficios_colaborador_filter: {},
    beneficios_colaborador_checked: [],
    // Los detalles personales y laborales de un colaborador (pestaña Colaboradores en Ver detalles)
    colaborador: {},
    colaborador_loading: false,
    /* Para saber componentes mostrar en Beneficio Configuración. Si se llama desde la pestaña Creados
       debe mostrar todos. Si se llama desde pestaña Cargos no debe mostrar el alcance porque ya se carga
       solo. Si se llama desde la pestaña Colaboradores no debe mostrar los colaboradores excluidos porque
       ya se cargan solos también. */
    mostrar_alcance: true,
    mostrar_excluidos: true,
    onclickback_beneficio_configuracion: "/beneficios/",
  },
  reducers: {
    setBeneficios: (state, { payload = {} }) => {
      state.beneficios = payload;
    },
    setBeneficiosComplete: (state, { payload = {} }) => {
      state.beneficios_loading = false;
    },
    setBeneficiosCount: (state, { payload = {} }) => {
      state.beneficios_count = payload;
    },
    getBeneficios: (state, { payload = {} }) => {
      state.beneficios_loading = true;
    },
    setCheckBeneficios: (state, { payload = {} }) => {
      state.beneficios_checked = payload;
    },
    resetCheckBeneficios: (state) => {
      state.beneficios_checked = [];
    },
    checkBeneficios: (
      state,
      { payload: { beneficio_aux, checked = true } }
    ) => {
      state.beneficios_checked = state.beneficios_checked.filter(
        (beneficio) => beneficio.id !== beneficio_aux.id
      );
      if (!!checked) {
        state.beneficios_checked.push(beneficio_aux);
      }
    },
    setBeneficiosFilter: (state, { payload = {} }) => {
      state.beneficios_filter = payload;
    },
    ///////////////////////////////////////////////////////////////
    setBeneficio: (state, { payload = {} }) => {
      state.beneficio = payload;
    },
    setBeneficioComplete: (state, { payload = {} }) => {
      state.beneficio_loading = false;
    },
    getBeneficio: (state, { payload = {} }) => {
      state.beneficio_loading = true;
    },
    ///////////////////////////////////////////////////////////////
    setTiposConfiguraciones: (state, { payload = {} }) => {
      state.tipos_configuraciones = payload;
    },
    setTiposConfiguracionesComplete: (state, { payload = {} }) => {
      state.tipos_configuraciones_loading = false;
    },
    getTiposConfiguraciones: (state, { payload = {} }) => {
      state.tipos_configuraciones_loading = true;
    },
    createTipoConfiguracion: (state, { payload = {} }) => {},
    ///////////////////////////////////////////////////////////////
    setBeneficiosBody: (state, { payload = {} }) => {
      state.beneficios_body = payload;
    },
    bulkCreate: (state, { payload = {} }) => {
      state.beneficios_body_loading = true;
    },
    bulkCreateComplete: (state, { payload = {} }) => {
      state.beneficios_body_loading = false;
    },
    bulkDelete: (state, { payload = {} }) => {},
    updateBeneficio: (state, { payload = {} }) => {
      state.beneficios_body_loading = true;
    },
    updateBeneficioComplete: (state, { payload = {} }) => {
      state.beneficios_body_loading = false;
    },
    deleteBeneficio: (state, { payload = {} }) => {},
    ///////////////////////////////////////////////////////////////
    getCargosHabilitados: (state, { payload = {} }) => {
      state.cargos_habilitados_loading = true;
    },
    setCargosHabilitados: (state, { payload = {} }) => {
      state.cargos_habilitados = payload;
      state.cargos_habilitados_loading = false;
    },
    setCargosHabilitadosFilter: (state, { payload = {} }) => {
      state.cargos_habilitados_filter = payload;
    },
    resetCheckCargosHabilitados: (state) => {
      state.cargos_habilitados_checked = [];
      state.cargos_habilitados_loading = false;
    },
    setCheckCargosHabilitados: (state, { payload }) => {
      state.cargos_habilitados_checked = payload;
    },
    checkCargoHabilitado: (state, { payload: { cargo, checked = true } }) => {
      state.cargos_habilitados_checked =
        state.cargos_habilitados_checked.filter((id) => id != cargo.id);
      if (!!checked) {
        state.cargos_habilitados_checked.push(cargo.id);
      }
      state.cargos_habilitados_loading = false;
    },
    ///////////////////////////////////////////////////////////////
    getColaboradoresHabilitados: (state, { payload = {} }) => {
      state.colaboradores_habilitados_loading = true;
    },
    setColaboradoresHabilitados: (state, { payload = {} }) => {
      state.colaboradores_habilitados = payload;
      state.colaboradores_habilitados_loading = false;
    },
    setColaboradoresHabilitadosFilter: (state, { payload = {} }) => {
      state.colaboradores_habilitados_filter = payload;
    },
    resetCheckColaboradoresHabilitados: (state) => {
      state.colaboradores_habilitados_checked = [];
      state.colaboradores_habilitados_loading = false;
    },
    setCheckColaboradoresHabilitados: (state, { payload }) => {
      state.colaboradores_habilitados_checked = payload;
    },
    checkColaboradorHabilitado: (
      state,
      { payload: { colaborador, checked = true } }
    ) => {
      state.colaboradores_habilitados_checked =
        state.colaboradores_habilitados_checked.filter(
          (id) => id != colaborador.id
        );
      if (!!checked) {
        state.colaboradores_habilitados_checked.push(colaborador.id);
      }
      state.colaboradores_habilitados_loading = false;
    },
    ///////////////////////////////////////////////////////////////
    vincularCargos: (state, { payload = {} }) => {},
    desvincularCargo: (state, { payload = {} }) => {}, // Eliminar un CargoBeneficio con el id de CargoBeneficio
    desvincularCargosMasivo: (state, { payload = {} }) => {}, // Eliminar varios CargoBeneficio con los ids de CargoBeneficio
    desvincularBeneficiosCargo: (state, { payload = {} }) => {}, // Desvincular todos los beneficios que le correspondan a un cargo a partir de los ids de los cargos
    ///////////////////////////////////////////////////////////////
    incluirColaboradoresBeneficio: (state, { payload = {} }) => {},
    excluirColaboradoresBeneficio: (state, { payload = {} }) => {},
    cambiarBeneficioHabilitadoColaborador: (state, { payload = {} }) => {},
    excluirBeneficiosTotalColaboradores: (state, { payload = {} }) => {},
    ///////////////////////////////////////////////////////////////
    getCargo: (state, { payload = {} }) => {
      state.cargo_loading = true;
    },
    setCargo: (state, { payload = {} }) => {
      state.cargo = payload;
      state.cargo_loading = false;
    },
    setCargosList: (state, { payload = {} }) => {
      state.cargos_list = payload;
    },
    setCargosListComplete: (state, { payload = {} }) => {
      state.cargos_list_loading = false;
    },
    setCargosListCount: (state, { payload = {} }) => {
      state.cargos_list_count = payload;
    },
    getCargosList: (state, { payload = {} }) => {
      state.cargos_list_loading = true;
    },
    setCheckCargosList: (state, { payload = {} }) => {
      state.cargos_list_checked = payload;
    },
    resetCheckCargosList: (state) => {
      state.cargos_list_checked = [];
    },
    checkCargosList: (state, { payload: { cargo_aux, checked = true } }) => {
      state.cargos_list_checked = state.cargos_list_checked.filter(
        (cargo) => cargo.id !== cargo_aux.id
      );
      if (!!checked) {
        state.cargos_list_checked.push(cargo_aux);
      }
    },
    setCargosListFilter: (state, { payload = {} }) => {
      state.cargos_list_filter = payload;
    },
    ///////////////////////////////////////////////////////////////
    setBeneficiosCargo: (state, { payload = {} }) => {
      state.beneficios_cargo = payload;
    },
    setBeneficiosCargoComplete: (state, { payload = {} }) => {
      state.beneficios_cargo_loading = false;
    },
    setBeneficiosCargoCount: (state, { payload = {} }) => {
      state.beneficios_cargo_count = payload;
    },
    getBeneficiosCargo: (state, { payload = {} }) => {
      state.beneficios_cargo_loading = true;
    },
    ///////////////////////////////////////////////////////////////
    setColaboradoresList: (state, { payload = {} }) => {
      state.colaboradores_list = payload;
    },
    setColaboradoresListComplete: (state, { payload = {} }) => {
      state.colaboradores_list_loading = false;
    },
    setColaboradoresListCount: (state, { payload = {} }) => {
      state.colaboradores_list_count = payload;
    },
    getColaboradoresList: (state, { payload = {} }) => {
      state.colaboradores_list_loading = true;
    },
    setCheckColaboradoresList: (state, { payload = {} }) => {
      state.colaboradores_list_checked = payload;
    },
    resetCheckColaboradoresList: (state) => {
      state.colaboradores_list_checked = [];
    },
    checkColaboradoresList: (
      state,
      { payload: { colaborador_aux, checked = true } }
    ) => {
      state.colaboradores_list_checked =
        state.colaboradores_list_checked.filter(
          (colaborador) => colaborador.id !== colaborador_aux.id
        );
      if (!!checked) {
        state.colaboradores_list_checked.push(colaborador_aux);
      }
    },
    setColaboradoresListFilter: (state, { payload = {} }) => {
      state.colaboradores_list_filter = payload;
    },
    ///////////////////////////////////////////////////////////////
    setBeneficiosColaborador: (state, { payload = {} }) => {
      state.beneficios_colaborador = payload;
    },
    setBeneficiosColaboradorComplete: (state, { payload = {} }) => {
      state.beneficios_colaborador_loading = false;
    },
    setBeneficiosColaboradorCount: (state, { payload = {} }) => {
      state.beneficios_colaborador_count = payload;
    },
    setBeneficiosColaboradorFilter: (state, { payload = {} }) => {
      state.beneficios_colaborador_filter = payload;
    },
    getBeneficiosColaborador: (state, { payload = {} }) => {
      state.beneficios_colaborador_loading = true;
    },
    setCheckBeneficiosColaborador: (state, { payload = {} }) => {
      state.beneficios_colaborador_checked = payload;
    },
    resetCheckBeneficiosColaborador: (state) => {
      state.beneficios_colaborador_checked = [];
    },
    checkBeneficiosColaborador: (
      state,
      { payload: { beneficio_aux, checked = true } }
    ) => {
      state.beneficios_colaborador_checked =
        state.beneficios_colaborador_checked.filter(
          (beneficio) => beneficio.id !== beneficio_aux.id
        );
      if (!!checked) {
        state.beneficios_colaborador_checked.push(beneficio_aux);
      }
    },
    //////////////////////////////////////////////////////////////////////
    getColaborador: (state, { payload = {} }) => {
      state.colaborador_loading = true;
    },
    setColaborador: (state, { payload = {} }) => {
      state.colaborador = payload;
      state.colaborador_loading = false;
    },
    getBeneificiosColaborador: (state, { payload = {} }) => {},
    //////////////////////////////////////////////////////////////////////
    setMostrarAlcance: (state, { payload = {} }) => {
      state.mostrar_alcance = payload;
    },
    setMostrarExcluidos: (state, { payload = {} }) => {
      state.mostrar_excluidos = payload;
    },
    setOnClickBackBeneficioConfiguracion: (state, { payload = {} }) => {
      state.onclickback_beneficio_configuracion = payload;
    },
  },
});

export const beneficioActions = beneficioSlice.actions;
export const beneficioReducer = beneficioSlice.reducer;
