import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import { SuccessToast } from "src/modules/common/components/SuccessToast";
import { getToken } from "src/utils/helpers";
import { colaboradorLibresApi } from "./api";
import { colaboradorLaboralActions } from "./redux";

function* colaboradoresLibresSaga({ payload }) {
  const token = getToken();

  const colaborador_laboral = yield select(
    (state) => state.colaboradorLaboral.colaborador_laboral
  );

  const colaboradores_libres_filter = yield select(
    (state) => state.colaboradorLaboral.colaboradores_libres_filter
  );

  try {
    const data = yield call(colaboradorLibresApi(token).colaboradoresLibres, {
      id: colaborador_laboral.id,
      colaboradores_libres_filter,
    });
    yield put(
      colaboradorLaboralActions.setColaboradoresLibres({
        colaboradores_libres: data,
      })
    );
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
  }
}

// Laboral

function* colaboradorLaboralGetSaga({ payload }) {
  const token = getToken();
  try {
    const data = yield call(colaboradorLibresApi(token).getLaboral, payload);
    yield put(colaboradorLaboralActions.colaboradorLaboralSet(data));
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
  }
}

function* colaboradorLaboralUpdateSaga({ payload }) {
  const token = getToken();
  const colaborador_laboral = yield select(
    (state) => state.colaboradorLaboral.colaborador_laboral
  );

  const { colaboradores = [] } = colaborador_laboral;

  const params = {
    ...colaborador_laboral,
    colaboradores: colaboradores.map((c) => c.id),
  };
  try {
    const data = yield call(colaboradorLibresApi(token).updateLaboral, params);
    //
    yield put(colaboradorLaboralActions.colaboradorLaboralUpdateSuccess());
    // yield put(push(`/nomina/colaborador/${colaborador_laboral.id}/laboral`));
    yield put(
      colaboradorLaboralActions.colaboradorLaboralGet({
        id: colaborador_laboral.id,
      })
    );
    SuccessToast({ message: "Datos Laborales actualizados!" });
  } catch (error) {
    yield put(colaboradorLaboralActions.colaboradorLaboralUpdateSuccess());
    !!process.env.REACT_APP_DEBUG && console.log({ error });
  }
}

export function* colaboradorLaboralSagas() {
  // list libres
  yield takeLatest(
    colaboradorLaboralActions.colaboradoresLibres.type,
    colaboradoresLibresSaga
  );
  yield takeLatest(
    colaboradorLaboralActions.setColaboradoresLibresFilter.type,
    colaboradoresLibresSaga
  );

  // LABORAL
  // laboral get
  yield takeEvery(
    colaboradorLaboralActions.colaboradorLaboralGet.type,
    colaboradorLaboralGetSaga
  );
  // update
  yield takeEvery(
    colaboradorLaboralActions.colaboradorLaboralUpdate.type,
    colaboradorLaboralUpdateSaga
  );
}
