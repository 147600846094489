import { createSlice } from "@reduxjs/toolkit";

const colaboradorDesempenoSlice = createSlice({
  name: "ColaboradorDesempeno",
  initialState: {
    colaborador_desempeno: {},
    colaborador_desempeno_loading: false,
    colaborador_evaluaciones: [],
    colaborador_evaluaciones_filter: {},
    colaborador_evaluaciones_loading: false,
  },
  reducers: {
    getColaboradorDesempeno: (state, { payload = [] }) => {
      state.colaborador_desempeno_loading = true;
    },
    setColaboradorDesempeno: (state, { payload = [] }) => {
      state.colaborador_desempeno = payload;
    },
    setColaboradorDesempenoComplete: (state, { payload = [] }) => {
      state.colaborador_desempeno_loading = false;
    },
    //
    getColaboradorEvaluaciones: (state, { payload = [] }) => {
      state.colaborador_evaluaciones_loading = true;
    },
    setColaboradorEvaluaciones: (state, { payload = [] }) => {
      state.colaborador_evaluaciones = payload;
    },
    setColaboradorEvaluacionesFilter: (state, { payload = [] }) => {
      state.colaborador_evaluaciones_filter = payload;
      state.colaborador_evaluaciones_loading = true;
    },
    setColaboradorEvaluacionesComplete: (state) => {
      state.colaborador_evaluaciones_loading = false;
    },
  },
});

export const colaboradorDesempenoActions = colaboradorDesempenoSlice.actions;
export const colaboradorDesempenoReducer = colaboradorDesempenoSlice.reducer;
