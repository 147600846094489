import Politicas from "../pages/Politicas";
import PoliticasEvaluacion from "../pages/PoliticasEvaluacion";
import PoliticasPlanCarrera from "../pages/PoliticasPlancarrera";
import PoliticasRemuneracion from "../pages/PoliticasRemuneracion";

export const politicaRoutes = [
  {
    path: "/politica/evaluaciones",
    element: <PoliticasEvaluacion />,
  },
  {
    path: "/politica/remuneraciones",
    element: <PoliticasRemuneracion />,
  },
  {
    path: "/politica/planes-carrera",
    element: <PoliticasPlanCarrera />,
  },
];
