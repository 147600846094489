import { useState } from "react";
import { CaretDownFill, CaretUpFill } from "react-bootstrap-icons";

function CollapseCard({
  titulo = "",
  descripcion = "",
  extraEndContent,
  children,
  initCollapse = true,
  extraClass = "",
  titleClass = "",
}) {
  const [collapse, setCollapse] = useState(initCollapse);
  const toggleCollapse = () => {
    setCollapse(!collapse);
  };
  return (
    <div className={`main border rounded-md ${extraClass}`}>
      <div
        className={`header bg-gray-200 p-3 flex items-center justify-between${
          !!collapse ? " border-b" : ""
        }`}
      >
        <div className="w-10/12">
          <div className={`${titleClass}`}>{titulo}</div>
          <div className="text-xs">{descripcion}</div>
        </div>
        <div className="flex items-center justify-end gap-4 w-2/12">
          {extraEndContent}
          <button onClick={toggleCollapse}>
            {!!collapse && <CaretUpFill></CaretUpFill>}
            {!collapse && <CaretDownFill></CaretDownFill>}
          </button>
        </div>
      </div>
      {/* */}
      {!!collapse && <div className="body p-5">{children}</div>}
    </div>
  );
}

export default CollapseCard;
