import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Circulo from "src/modules/common/components/Circulo";
import { ErrorToast } from "src/modules/common/components/ErrorToast";
import { PrimaryButton } from "src/modules/common/components/PrimaryButton";
import TableCustom from "src/modules/common/components/TableCustom";
import TableRow from "src/modules/common/components/TableRow";
import { commonActions } from "src/modules/common/handler/redux";
import { colaboradorActions } from "../handlers/redux";
import LoadingSpinButton from "src/modules/common/components/LoadingSpinButton";

function ImportacionNominaPage() {
  const dispatch = useDispatch();
  const params = useParams();
  const { subject } = params;
  const [nuevos, setNuevos] = useState([]);
  const [duplicados, setDuplicados] = useState([]);
  const [searchNuevos, setSearchNuevos] = useState("");
  const [searchDuplicados, setSearchDuplicados] = useState("");

  useEffect(() => {
    dispatch(commonActions.setTitle(`Importador de nómina`));
    dispatch(commonActions.showSidebar(false));
    dispatch(
      commonActions.setSecondBar({
        hasSecondBar: true,
        secondBarTitle: "Datos recolectados",
        secondBarBackRoute: "/nomina",
      })
    );
  }, []);

  // @ts-ignore
  const data = useSelector((state) => state.colaborador.datosVerificados) || [];
  const importar_loading = useSelector(
    (state) => state.colaborador.importar_loading
  );
  // @ts-ignore
  const nuevosChecked = useSelector((state) => state.colaborador.nuevosChecked);
  const duplicadosChecked = useSelector(
    // @ts-ignore
    (state) => state.colaborador.duplicadosChecked
  );

  useEffect(() => {
    setNuevos(data.nuevos);
  }, [data.nuevos?.length]);

  useEffect(() => {
    setDuplicados(data.duplicados);
  }, [data.duplicados?.length]);

  const ColaboradorItem = ({ item }) => {
    // const dispatch = useDispatch();

    return (
      <>
        <TableRow>
          <div
            className="p-4 flex flex-row justify-between w-full pr-8"
            onClick={() => {}}
          >
            <div>
              <div className="font-bold">{`${item.nombre} ${item.apellido}`}</div>
              <div>CI: {item.ci || "Sin CI"}</div>
            </div>
            <div>
              <div>
                {dayjs(item.fecha_ingreso.slice(0, 10)).format("DD/MM/YYYY")}
              </div>
            </div>
            <div>
              <div>
                {dayjs(item.fecha_nacimiento.slice(0, 10)).format("DD/MM/YYYY")}
              </div>
            </div>
            <div>
              <div>{item.sexo === "M" ? "Hombre" : "Mujer"}</div>
            </div>
          </div>
        </TableRow>
      </>
    );
  };

  const DuplicadoItem = ({ item }) => {
    // const dispatch = useDispatch();

    return (
      <>
        <TableRow>
          <div
            className="p-4 flex gap-8 items-start justify-around cursor-pointer"
            onClick={() => {}}
          >
            <div>
              <div className="font-bold">{`${item.nuevo.nombre} ${item.nuevo.apellido}`}</div>
              <div>CI: {item.nuevo.ci || "Sin CI"}</div>
            </div>
            <div>
              <div>
                {dayjs(item.nuevo.fecha_ingreso.slice(0, 10)).format(
                  "DD/MM/YYYY"
                )}
              </div>
            </div>
            <div>
              <div>
                {dayjs(item.nuevo.fecha_nacimiento.slice(0, 10)).format(
                  "DD/MM/YYYY"
                )}
              </div>
            </div>
            <div>
              <div>{item.nuevo.sexo === "M" ? "Hombre" : "Mujer"}</div>
            </div>
          </div>
        </TableRow>
      </>
    );
  };

  const onCheckHandlerNuevos = (event, item) => {
    dispatch(
      colaboradorActions.checkNuevo({
        item: item,
        checked: event.target.checked,
      })
    );
  };

  const onCheckHandlerDuplicados = (event, item) => {
    const newItem = { id: item.anterior.id, ...item.nuevo };

    dispatch(
      colaboradorActions.checkDuplicado({
        item: newItem,
        checked: event.target.checked,
      })
    );
  };

  const onSearchNuevos = (e) => {
    let searchText = e.target.value;
    setSearchNuevos(searchText);
    searchText = searchText.toUpperCase();
    const nuevosCopy = data.nuevos?.filter((nuevo, nidx) =>
      nuevo.nombre.toUpperCase().includes(searchText)
    );
    setNuevos(nuevosCopy);
  };
  const onSearchDuplicados = (e) => {
    let searchText = e.target.value;
    setSearchDuplicados(searchText);
    searchText = searchText.toUpperCase();
    const duplicadosCopy = data.duplicados?.filter((duplicado, nidx) =>
      duplicado.anterior.nombre.toUpperCase().includes(searchText)
    );
    setDuplicados(duplicadosCopy);
  };

  const importarDatos = () => {
    // !!process.env.REACT_APP_DEBUG && console.info("importarDatos", nuevosChecked, duplicadosChecked);
    let nuevos = data.nuevos.filter(
      (item, idx) =>
        nuevosChecked.includes(idx) === true ||
        nuevosChecked.map((item, iidx) => item.id).includes(idx)
    );
    let duplicados = data.duplicados.filter(
      (item, idx) => duplicadosChecked.includes(item.anterior.id) === true
    );
    duplicados = duplicados.map((item, idx) => ({
      id: item.anterior.id,
      ...item.nuevo,
    }));
    //
    let datosImportar = [...nuevos, ...duplicados];
    if (datosImportar.length < 1) {
      ErrorToast({ message: "No se eligieron datos para importar" });
      return;
    }
    datosImportar = datosImportar.map((item, idx) => ({
      ...item,
      fecha_nacimiento: dayjs(item.fecha_nacimiento.slice(0, 10)).format(
        "DD/MM/YYYY"
      ),
      fecha_ingreso: dayjs(item.fecha_ingreso.slice(0, 10)).format(
        "DD/MM/YYYY"
      ),
    }));
    //
    !!process.env.REACT_APP_DEBUG &&
      console.info("datosImportar", datosImportar);
    dispatch(colaboradorActions.setDatosImportar(datosImportar));
  };

  !!process.env.REACT_APP_DEBUG && console.info("datosVerificados", data);

  return (
    <>
      <div className="flex justify-center gap-8 mt-8 w-full">
        <Circulo title={"Total"} count={data.totales.total} active={true} />
        <Circulo title={"Nuevos"} count={data.totales.nuevos} active={true} />
        <Circulo
          title={"Duplicados"}
          count={data.totales.duplicados}
          active={true}
        />
      </div>
      {/*  */}
      {!importar_loading && (
        <div className="body flex flex-col gap-8 items-center justify-center mt-8">
          {/* NUEVOS */}
          {data.nuevos?.length > 0 && (
            <>
              <div className="flex justify-start w-full md:w-2/3">
                <div className="text-lg font-bold">
                  Nuevos registros a importar
                </div>
              </div>
              <div className="flex justify-center w-full md:w-2/3">
                <TableCustom
                  ItemComponent={ColaboradorItem}
                  data={nuevos.map((item, idx) => {
                    return { id: idx, ...item };
                  })}
                  tableSecondTitle={"Lista de importados"}
                  subject={"colaborador"}
                  subjectGender={"M"}
                  hasAddBtn={false}
                  noBottomButtom={true}
                  customHeaders={["Ingreso", "Nacimiento", "Sexo"]}
                  selectOptions={[
                    { value: "eliminar", label: "Eliminar" },
                    { value: "cancel", label: "Cancelar" },
                  ]}
                  itemsChecked={nuevosChecked}
                  onCheckHandler={onCheckHandlerNuevos}
                  onChangeSearch={onSearchNuevos}
                  searchValue={searchNuevos}
                />
              </div>
            </>
          )}
          {/* DUPLICADOS */}
          {data.duplicados?.length > 0 && (
            <>
              <div className="flex flex-col justify-start w-full md:w-2/3">
                <div className="text-lg font-bold">Duplicados</div>
                <p className="text-sm">
                  Ya existen registros en la base de datos con los mismos datos
                  que intenta importar. ¿Quieres reemplazarlos por el que estás
                  importando?
                </p>
              </div>
              <div className="flex justify-center w-full md:w-2/3">
                <TableCustom
                  ItemComponent={DuplicadoItem}
                  data={duplicados.map((item, idx) => {
                    return { id: item.anterior.id, ...item };
                  })}
                  tableSecondTitle={"Lista de duplicados"}
                  subject={"colaborador"}
                  subjectGender={"M"}
                  hasAddBtn={false}
                  noBottomButtom={true}
                  customHeaders={["Ingreso", "Nacimiento", "Sexo"]}
                  selectOptions={[
                    { value: "eliminar", label: "Eliminar" },
                    { value: "cancel", label: "Cancelar" },
                  ]}
                  itemsChecked={duplicadosChecked}
                  onCheckHandler={onCheckHandlerDuplicados}
                  onChangeSearch={onSearchDuplicados}
                  searchValue={searchDuplicados}
                />
              </div>
            </>
          )}
          {/*  */}
          <PrimaryButton
            className="w-full md:w-2/3 mb-8 bg-sky-500"
            onClick={importarDatos}
          >
            Importar datos
          </PrimaryButton>
        </div>
      )}
      {!!importar_loading && (
        <div className="flex items-center justify-center h-screen">
          <LoadingSpinButton></LoadingSpinButton>
        </div>
      )}
    </>
  );
}

export default ImportacionNominaPage;
