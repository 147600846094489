import { createSlice } from "@reduxjs/toolkit";

const evaluacionSlice = createSlice({
  name: "Evaluacion",
  initialState: {
    evaluaciones: { result: [] },
    evaluaciones_count: 0,
    evaluaciones_filter: {},
    evaluaciones_checked: [],
    evaluaciones_loading: false,
    evaluacion: {},
    evaluacion_loading: false,
    duplicar_evaluacion_id: null,
    //
    evaluacion_cambios: {},
    evaluacion_cambios_loading: false,
    //
    formularios: { result: [] },
    formulariosCount: 0,
    formularios_filter: {},
    formularios_checked: [],
    formularios_loading: false,
    formulario_eliminar: {},
    formulario_eliminar_loading: false,
    formulario: {},
    formulario_loading: false,
    //
    colaboradores_agregar: [],
    colaboradores_agregar_loading: false,
    colaboradores_validos: [], // colaboradores seleccionables para una evaluacion
    colaboradores_validos_count: 0,
    colaboradores_validos_checked: [],
    colaboradores_validos_filter: {},
    colaboradores_validos_loading: false,
    //
    evaluadores_agregar: [],
    evaluadores_agregar_loading: false,
    evaluadores_evaluado: {},
    evaluadores_colaborador: [],
    evaluadores_colaborador_loading: false,
    evaluadores_colaborador_filter: [],
    evaluadores_colaborador_checked: [],
    //
    opciones_por_defecto: {},
    opciones_por_defecto_loading: false,
    //
    configuraciones: { result: [] },
    configuraciones_filter: {},
    configuraciones_checked: [],
    configuraciones_loading: false,
    configuracion_delete: {},
    configuracion: {},
    configuracion_loading: false,
    //
    informe_evaluacion: {},
    informe_evaluacion_loading: false,
    informe_colaborador: {},
    informe_colaborador_loading: false,
    //
    informe_evaluacion_conductas: {},
    informe_evaluacion_conductas_loading: false,
    //
    colaboradores_promedios: [],
    colaboradores_promedios_count: 0,
    colaboradores_promedios_filter: {},
    colaboradores_promedios_loading: false,
    // Estado inicial para áreas
    areas_promedios_loading: false,
    areas_promedios_filter: {},
    areas_promedios: [],
    // Estado inicial para sucursales
    sucursales_promedios_loading: false,
    sucursales_promedios_filter: {},
    sucursales_promedios: [],
    // Estado inicial para departamentos
    departamentos_promedios_loading: false,
    departamentos_promedios_filter: {},
    departamentos_promedios: [],
    //
    informe_area_colaboradores: [],
    informe_sucursal_colaboradores: [],
    informe_departamento_colaboradores: [],
    // /
    informe_area_colaboradores_loading: false,
    informe_sucursal_colaboradores_loading: false,
    informe_departamento_colaboradores_loading: false,
    //
    cambios_nomina: {},
    cambios_nomina_checked: [],
    cambios_nomina_loading: false,
    actualizar_nomina_loading: false,
    //
    cambios_competencias: {},
    cambios_competencias_checked: [],
    cambios_competencias_loading: false,
    actualizar_competencias_loading: false,
  },
  reducers: {
    getCambiosNomina: (state, { payload = {} }) => {
      state.cambios_nomina_loading = true;
    },
    setCambiosNomina: (state, { payload = {} }) => {
      state.cambios_nomina = payload;
      state.cambios_nomina_loading = false;
    },
    checkCambioNomina: (
      state,
      { payload: { colaborador, checked = true } }
    ) => {
      state.cambios_nomina_checked = state.cambios_nomina_checked.filter(
        (colab) => colab.id !== colaborador.id
      );
      if (!!checked) {
        state.cambios_nomina_checked.push(colaborador);
      }
    },
    resetCheckCambiosNomina: (state) => {
      state.cambios_nomina_checked = [];
    },
    setActualizarNomina: (state, { payload = {} }) => {
      state.actualizar_nomina_loading = true;
    },
    setActualizarNominaFin: (state, { payload = {} }) => {
      state.actualizar_nomina_loading = false;
    },
    //
    getCambiosCompetencias: (state, { payload = {} }) => {
      state.cambios_competencias_loading = true;
    },
    setCambiosCompetencias: (state, { payload = {} }) => {
      state.cambios_competencias = payload;
      state.cambios_competencias_loading = false;
    },
    setCheckCompetencias: (state, { payload = {} }) => {
      state.cambios_competencias_checked = payload;
    },
    checkCambioCompetencias: (
      state,
      { payload: { competencia, checked = true } }
    ) => {
      state.cambios_competencias_checked =
        state.cambios_competencias_checked.filter(
          (comp) => comp.id !== competencia.id
        );
      if (!!checked) {
        state.cambios_competencias_checked.push(competencia);
      }
    },
    resetCheckCambiosCompetencias: (state) => {
      state.cambios_competencias_checked = [];
    },
    setActualizarCompetencias: (state, { payload = {} }) => {
      state.actualizar_competencias_loading = true;
    },
    setActualizarCompetenciasFin: (state, { payload = {} }) => {
      state.actualizar_competencias_loading = false;
    },
    //
    getEvaluaciones: (state, { payload = {} }) => {
      state.evaluaciones_loading = true;
    },
    setEvaluaciones: (state, { payload = {} }) => {
      state.evaluaciones = payload;
      state.evaluaciones_loading = false;
    },
    setEvaluacionesCount: (state, { payload = {} }) => {
      state.evaluaciones_count = payload;
    },
    setEvaluacionesFilter: (state, { payload = {} }) => {
      state.evaluaciones_filter = payload;
    },
    resetCheckEvaluaciones: (state) => {
      state.evaluaciones_checked = [];
    },
    checkEvaluacion: (state, { payload: { evaluacion, checked = true } }) => {
      state.evaluaciones_checked = state.evaluaciones_checked.filter(
        (id) => id !== evaluacion.id
      );
      if (!!checked) {
        state.evaluaciones_checked.push(evaluacion.id);
      }
    },
    setDuplicarEvaluacionId: (state, { payload }) => {
      state.duplicar_evaluacion_id = payload;
    },
    duplicarEvaluacion: (state, { payload }) => {
      state.evaluacion = {};
      state.evaluacion_loading = true;
    },
    //
    getEvaluacion: (state, { payload = {} }) => {
      state.evaluacion = {};
      state.evaluacion_loading = true;
    },
    setEvaluacion: (state, { payload = {} }) => {
      state.evaluacion = payload;
      state.evaluacion_loading = false;
    },
    createEvaluacion: (state, { payload = {} }) => {
      state.evaluacion_loading = true;
    },
    updateEvaluacion: (state, { payload = {} }) => {
      state.evaluacion_loading = true;
    },
    evaluacionCreateComplete: (state, { payload = {} }) => {
      state.evaluacion = {};
      state.evaluacion_loading = false;
    },
    evaluacionError: (state, { payload = {} }) => {
      state.evaluacion_loading = false;
    },
    evaluacionDelete: (state, { payload = {} }) => {
      state.evaluaciones_loading = true;
    },
    evaluacionBulkDelete: (state, { payload = {} }) => {
      state.evaluaciones_loading = true;
    },
    evaluacionDeleteSuccess: (state) => {
      state.evaluaciones_loading = false;
    },
    //
    getEvaluacionCambios: (state, { payload = {} }) => {
      state.evaluacion_cambios_loading = true;
    },
    setEvaluacionCambios: (state, { payload = {} }) => {
      state.evaluacion_cambios = payload;
      state.evaluacion_cambios_loading = false;
    },
    // FORMULARIOS
    getFormularios: (state, { payload = {} }) => {
      state.formularios_loading = true;
    },
    setFormularios: (state, { payload = {} }) => {
      state.formularios = payload;
      state.formularios_loading = false;
    },
    setFormulariosCount: (state, { payload = {} }) => {
      state.formulariosCount = payload;
    },
    setFormulariosFilter: (state, { payload = {} }) => {
      state.formularios_filter = payload;
      state.formularios_loading = true;
    },
    resetCheckFormularios: (state) => {
      state.formularios_checked = [];
    },
    setFormularioEliminar: (state, { payload = {} }) => {
      state.formulario_eliminar = payload;
      state.formulario_eliminar_loading = true;
    },
    formularioEliminarComplete: (state) => {
      state.formulario_eliminar_loading = false;
    },
    // /
    getFormulario: (state, { payload = {} }) => {
      state.formulario_loading = true;
    },
    setFormulario: (state, { payload = {} }) => {
      state.formulario = payload;
    },
    setFormularioComplete: (state) => {
      state.formulario_loading = false;
    },
    //
    checkFormulario: (state, { payload: { formulario, checked = true } }) => {
      state.formularios_checked = state.formularios_checked.filter(
        (id) => id !== formulario.id
      );
      if (!!checked) {
        state.formularios_checked.push(formulario.id);
      }
    },
    ////
    getColaboradoresValidos: (state, { payload = {} }) => {
      state.colaboradores_validos_loading = true;
    },
    setColaboradoresValidos: (state, { payload = {} }) => {
      state.colaboradores_validos = payload;
      state.colaboradores_validos_loading = false;
    },
    setColaboradoresValidosCount: (state, { payload = {} }) => {
      state.colaboradores_validos_count = payload;
    },
    setColaboradoresValidosFilter: (state, { payload = {} }) => {
      state.colaboradores_validos_loading = true;
      state.colaboradores_validos_filter = payload;
    },
    colaboradoresValidosComplete: (state, { payload = {} }) => {
      state.colaboradores_validos_loading = false;
    },
    ///
    resetCheckColaboradoresValidos: (state) => {
      state.colaboradores_validos_checked = [];
    },
    setCheckColaboradoresValidos: (state, { payload = {} }) => {
      state.colaboradores_validos_checked = payload;
    },
    checkColaboradorValido: (
      state,
      { payload: { colaborador, checked = true } }
    ) => {
      state.colaboradores_validos_checked =
        state.colaboradores_validos_checked.filter(
          (id) => (id.id ? id.id : id) !== colaborador.id // Esto es porque para ColaboradoresValidos se usa valores de tipo objeto y para calibradores se usa valores numericos, entonces id puede ser un objeto {id: 1, nombre: ...} o un número id = 1
        );
      if (!!checked) {
        state.colaboradores_validos_checked.push(colaborador);
      }
    },
    //
    setColaboradoresAgregar: (state, { payload = {} }) => {
      state.colaboradores_agregar = payload;
      state.colaboradores_agregar_loading = true;
    },
    colaboradoresAgregarComplete: (state, { payload = {} }) => {
      state.colaboradores_agregar_loading = false;
    },
    //
    setEvaluadoresEvaluado: (state, { payload = {} }) => {
      state.evaluadores_evaluado = payload;
    },
    getEvaluadoresColaborador: (state, { payload = {} }) => {
      state.evaluadores_colaborador_loading = true;
    },
    setEvaluadoresColaborador: (state, { payload = {} }) => {
      state.evaluadores_colaborador = payload;
      state.evaluadores_colaborador_loading = false;
    },
    setEvaluadoresColaboradorFilter: (state, { payload = {} }) => {
      state.evaluadores_colaborador_filter = payload;
    },
    evaluadoresColaboradorComplete: (state, { payload = {} }) => {
      state.evaluadores_colaborador_loading = false;
    },
    resetCheckEvaluadoresColaborador: (state) => {
      state.evaluadores_colaborador_checked = [];
    },
    checkEvaluadorColaborador: (
      state,
      { payload: { evaluador, checked = true } }
    ) => {
      state.evaluadores_colaborador_checked =
        state.evaluadores_colaborador_checked.filter(
          (id) => id !== evaluador.id
        );
      if (!!checked) {
        state.evaluadores_colaborador_checked.push(evaluador.id);
      }
    },
    //
    setEvaluadoresAgregar: (state, { payload = {} }) => {
      state.evaluadores_agregar = payload.evaluadores_agregar
        ? payload.evaluadores_agregar
        : payload;
      state.evaluadores_agregar_loading = true;
    },
    evaluadoresAgregarComplete: (state, { payload = {} }) => {
      state.evaluadores_agregar_loading = false;
    },
    //
    setOpcionesPorDefecto: (state, { payload = {} }) => {
      state.opciones_por_defecto = payload;
      state.opciones_por_defecto_loading = true;
    },
    resetOpcionesPorDefecto: (state, { payload = {} }) => {
      state.opciones_por_defecto = {};
    },
    opcionesPorDefectoComplete: (state, { payload = {} }) => {
      state.opciones_por_defecto_loading = false;
    },
    // //
    agregarCalibradorDefecto: (state, { payload = {} }) => {
      state.formularios_loading = true;
    },
    agregarCalibradorDefectoComplete: (state, { payload = {} }) => {
      state.formularios_loading = false;
    },
    agregarCalibrador: (state, { payload = {} }) => {
      state.formularios_loading = true;
    },
    agregarCalibradorComplete: (state, { payload = {} }) => {
      state.formularios_loading = false;
    },
    // /
    agregarConfiguracion: (state, { payload = {} }) => {
      state.formularios_loading = true;
    },
    agregarConfiguracionComplete: (state, { payload = {} }) => {
      state.formularios_loading = false;
    },
    // / evaluadorD, calibrador, calibradorD, configuracion
    agregarEvaluadoresDefault: (state, { payload = {} }) => {
      state.formularios_loading = true;
    },
    agregarCalibradores: (state, { payload = {} }) => {
      state.formularios_loading = true;
    },
    agregarCalibradoresDefault: (state, { payload = {} }) => {
      state.formularios_loading = true;
    },
    agregarConfiguraciones: (state, { payload = {} }) => {
      state.formularios_loading = true;
    },
    //
    setQuitarEvaluador: (state, { payload = {} }) => {
      state.formularios_loading = true;
    },
    setQuitarCalibrador: (state, { payload = {} }) => {
      state.formularios_loading = true;
    },
    setQuitarConfiguracion: (state, { payload = {} }) => {
      state.formularios_loading = true;
    },
    // // complete
    agregarEvaluadoresDefaultComplete: (state, { payload = {} }) => {
      state.formularios_loading = false;
    },
    agregarCalibradoresComplete: (state, { payload = {} }) => {
      state.formularios_loading = false;
      state.colaboradores_validos_loading = false;
      state.colaboradores_validos_checked = [];
    },
    agregarCalibradoresDefaultComplete: (state, { payload = {} }) => {
      state.formularios_loading = false;
    },
    agregarConfiguracionesComplete: (state, { payload = {} }) => {
      state.formularios_loading = false;
    },
    setEliminarFormularios: (state, { payload = {} }) => {
      state.formularios_loading = true;
    },
    eliminarFormulariosComplete: (state, { payload = {} }) => {
      state.formularios_loading = false;
    },
    // enviar formularios
    setEnviarFormulario: (state, { payload = {} }) => {
      state.formularios_loading = true;
    },
    setEnviarFormularios: (state, { payload = {} }) => {
      state.formularios_loading = true;
    },
    enviosComplete: (state, { payload = {} }) => {
      state.formularios_loading = false;
    },
    //
    setEvaluacionReabrir: (state, { payload = {} }) => {
      // state.formularios_loading = false;
    },
    setEvaluacionFinalizar: (state, { payload = {} }) => {
      // state.formularios_loading = false;
    },
    // CONFIGURACIONES
    getConfiguraciones: (state, { payload = {} }) => {
      state.configuraciones_loading = true;
    },
    setConfiguraciones: (state, { payload = {} }) => {
      state.configuraciones = payload;
    },
    setConfiguracionesComplete: (state) => {
      state.configuraciones_loading = false;
    },
    setConfiguracionesFilter: (state, { payload = {} }) => {
      state.configuraciones_filter = payload;
    },
    resetCheckConfiguraciones: (state) => {
      state.configuraciones_checked = [];
    },
    checkConfiguracion: (
      state,
      { payload: { configuracion, checked = true } }
    ) => {
      state.configuraciones_checked = state.configuraciones_checked.filter(
        (id) => id !== configuracion.id
      );
      if (!!checked) {
        state.configuraciones_checked.push(configuracion.id);
      }
    },
    // /
    setConfiguracion: (state, { payload = {} }) => {
      state.configuracion = payload;
      state.configuracion_loading = false;
    },
    createConfiguracion: (state) => {
      state.configuracion_loading = true;
    },
    updateConfiguracion: (state) => {
      state.configuracion_loading = true;
    },
    configuracionCreateComplete: (state) => {
      state.configuracion = {};
      state.configuracion_loading = false;
    },
    configuracionError: (state) => {
      state.configuracion_loading = false;
    },
    configuracionDelete: (state, { payload = {} }) => {
      state.configuracion_delete = payload;
      state.configuraciones_loading = true;
    },
    configuracionDeleteSuccess: (state) => {
      state.configuraciones_loading = false;
    },
    // procesos de evaluacion
    evaluar: (state, { payload = {} }) => {
      state.formulario_loading = true;
    },
    calibrar: (state, { payload = {} }) => {
      state.formulario_loading = true;
    },
    devolver: (state, { payload = {} }) => {
      state.formulario_loading = true;
    },
    comentar: (state, { payload = {} }) => {
      state.formulario_loading = true;
    },
    evaluacionComplete: (state) => {
      state.formulario_loading = false;
    },
    //
    reiniciarFormulario: (state, { payload = {} }) => {
      state.formulario_loading = true;
    },
    reiniciarFormularios: (state, { payload = {} }) => {
      // state.formulario_loading = true;
    },
    reiniciarFormularioComplete: (state, { payload = {} }) => {
      state.formulario_loading = false;
    },
    //
    getInformeEvaluacion: (state, { payload = {} }) => {
      state.informe_evaluacion_loading = true;
    },
    setInformeEvaluacion: (state, { payload = {} }) => {
      state.informe_evaluacion = payload;
      state.informe_evaluacion_loading = false;
    },
    filterInformeEvaluacion: (state, { payload = {} }) => {
      state.informe_evaluacion_loading = false;
    },
    informeEvaluacionError: (state, { payload = {} }) => {
      state.informe_evaluacion_loading = false;
    },
    //
    getInformeEvaluacionConductas: (state, { payload = {} }) => {
      state.informe_evaluacion_conductas_loading = true;
    },
    setInformeEvaluacionConductas: (state, { payload = {} }) => {
      state.informe_evaluacion_conductas = payload;
      state.informe_evaluacion_conductas_loading = false;
    },
    filterInformeEvaluacionConductas: (state, { payload = {} }) => {
      state.informe_evaluacion_conductas_loading = false;
    },
    informeEvaluacionConductasError: (state, { payload = {} }) => {
      state.informe_evaluacion_conductas_loading = false;
    },
    //
    getInformeColaborador: (state, { payload = {} }) => {
      state.informe_colaborador_loading = true;
    },
    setInformeColaborador: (state, { payload = {} }) => {
      state.informe_colaborador = payload;
      state.informe_colaborador_loading = false;
    },
    informeColaboradorError: (state, { payload = {} }) => {
      state.informe_colaborador_loading = false;
    },
    //
    getColaboradoresPromedios: (state, { payload = {} }) => {
      state.colaboradores_promedios_loading = true;
    },
    setColaboradoresPromediosCount: (state, { payload = {} }) => {
      state.colaboradores_promedios_count = payload;
    },
    setColaboradoresPromediosFilter: (state, { payload = {} }) => {
      state.colaboradores_promedios_filter = payload;
      state.colaboradores_promedios_loading = true;
    },
    setColaboradoresPromedios: (state, { payload = {} }) => {
      state.colaboradores_promedios = payload;
      state.colaboradores_promedios_loading = false;
    },
    colaboradoresPromediosError: (state, { payload = {} }) => {
      state.colaboradores_promedios_loading = false;
    },
    // Para áreas
    getAreasPromedios: (state, { payload = {} }) => {
      state.areas_promedios_loading = true;
    },
    setAreasPromediosFilter: (state, { payload = {} }) => {
      state.areas_promedios_filter = payload;
      state.areas_promedios_loading = true;
    },
    setAreasPromedios: (state, { payload = {} }) => {
      state.areas_promedios = payload;
      state.areas_promedios_loading = false;
    },
    areasPromediosError: (state, { payload = {} }) => {
      state.areas_promedios_loading = false;
    },

    // Para departamentos
    getDepartamentosPromedios: (state, { payload = {} }) => {
      state.departamentos_promedios_loading = true;
    },
    setDepartamentosPromediosFilter: (state, { payload = {} }) => {
      state.departamentos_promedios_filter = payload;
      state.departamentos_promedios_loading = true;
    },
    setDepartamentosPromedios: (state, { payload = {} }) => {
      state.departamentos_promedios = payload;
      state.departamentos_promedios_loading = false;
    },
    departamentosPromediosError: (state, { payload = {} }) => {
      state.departamentos_promedios_loading = false;
    },
    // Para sucursales
    getSucursalesPromedios: (state, { payload = {} }) => {
      state.sucursales_promedios_loading = true;
    },
    setSucursalesPromediosFilter: (state, { payload = {} }) => {
      state.sucursales_promedios_filter = payload;
      state.sucursales_promedios_loading = true;
    },
    setSucursalesPromedios: (state, { payload = {} }) => {
      state.sucursales_promedios = payload;
      state.sucursales_promedios_loading = false;
    },
    sucursalesPromediosError: (state, { payload = {} }) => {
      state.sucursales_promedios_loading = false;
    },
    getAreaColaboradores: (state, { payload = {} }) => {
      state.informe_area_colaboradores_loading = true;
    },
    setAreaColaboradores: (state, { payload = {} }) => {
      state.informe_area_colaboradores = payload;
    },
    getAreaColaboradoresComplete: (state, { payload = {} }) => {
      state.informe_area_colaboradores_loading = false;
    },
    //
    getSucursalColaboradores: (state, { payload = {} }) => {
      state.informe_sucursal_colaboradores_loading = true;
    },
    setSucursalColaboradores: (state, { payload = {} }) => {
      state.informe_sucursal_colaboradores = payload;
    },
    getSucursalColaboradoresComplete: (state, { payload = {} }) => {
      state.informe_sucursal_colaboradores_loading = false;
    },
    //
    getDepartamentoColaboradores: (state, { payload = {} }) => {
      state.informe_departamento_colaboradores_loading = true;
    },
    setDepartamentoColaboradores: (state, { payload = {} }) => {
      state.informe_departamento_colaboradores = payload;
    },
    getDepartamentoColaboradoresComplete: (state, { payload = {} }) => {
      state.informe_departamento_colaboradores_loading = false;
    },
  },
});

export const evaluacionActions = evaluacionSlice.actions;
export const evaluacionReducer = evaluacionSlice.reducer;
