import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DataTable from "src/modules/common/components/DataTable";
import SearchInput from "src/modules/remuneracion/components/SearchInput";
import { feedbackActions } from "../handlers/redux";

function JefesDirectosCambio({ onSubmit, idFeedback }) {
  const dispatch = useDispatch();

  const jefes_directos_list = useSelector(
    (state) => state.feedback.jefes_directos_list
  );
  const jefes_directos_list_loading = useSelector(
    (state) => state.feedback.jefes_directos_list_loading
  );
  const jefes_directos_list_count = useSelector(
    (state) => state.feedback.jefes_directos_list_count
  );
  const jefes_directos_list_filter = useSelector(
    (state) => state.feedback.jefes_directos_list_filter
  );
  const jefes_directos_list_checked = useSelector(
    (state) => state.feedback.jefes_directos_list_checked
  );

  const { nombre = "" } = jefes_directos_list_filter;

  useEffect(() => {
    if (idFeedback) {
      dispatch(
        feedbackActions.getJefesDirectosList({})
      );
    }
  }, []);

  //
  const datatableConfig = {
    headers: [
      {
        title: "Jefes directos",
        contentClass: "px-2 py-4",
        // onOrder: setOrdering("colaborador__nombre"),
        // accessor: "nombre",
        contentRenderer: (item) => (
          <div className="flex flex-col">
            <span className="">
              {item.nombre} {item.apellido ? item.apellido : ""}
            </span>
            <span className="text-sm">
              {item.cargo ? item.cargo.nombre : "Sin cargo"}
            </span>
          </div>
        ),
      },
    ],
    rowActions: [],
  };
  const onChangeText = (e) => {
    let searchText = e.target.value;
    dispatch(
      feedbackActions.setJefesDirectosListFilter({
        ...jefes_directos_list_filter,
        nombre: searchText,
      })
    );
  };
  const onPreSubmit = () => {
    if (onSubmit) {
      onSubmit(jefes_directos_list_checked);
    }
  };

  const onPaginate = (page, pageSize) => {
    dispatch(
      feedbackActions.setJefesDirectosListFilter({
        ...jefes_directos_list_filter,
        pagination: {
          page: page,
          pageSize: pageSize,
        },
      })
    );
  };

  const [outerPageSize, setOuterPageSize] = useState(1000);
  const onChangePageSize = (e) => {
    let value = e.target.value;
    dispatch(
      feedbackActions.setJefesDirectosListFilter({
        ...jefes_directos_list_filter,
        pagination: {
          pageSize: parseInt(value),
          page: 1,
        },
      })
    );
    setOuterPageSize(parseInt(value));
  };

  const onCheckItem = (item) => (event) => {
    // Para que deje seleccionar una sola opción con el check
    dispatch(feedbackActions.setCheckJefesDirectosList([]));
    dispatch(
      feedbackActions.checkJefesDirectosList({
        jefeDirectoList: item,
        checked: event.target.checked,
      })
    );
  };
  //
  return (
    <div>
      <SearchInput
        placeholder="Buscar por nombre o cargo..."
        value={nombre}
        onChange={onChangeText}
      />
      <div className="custom-scrollbar overflow-auto max-h-[400px] mt-4">
        <DataTable
          containerClass={"my-8x"}
          data={jefes_directos_list}
          loading={jefes_directos_list_loading}
          config={datatableConfig}
          noHeaders={false}
          //
          totalRecords={jefes_directos_list_count}
          onPaginate={onPaginate}
          outerPageSize={outerPageSize}
          //
          checkable={true}
          onCheckAll={false}
          checkedItems={jefes_directos_list_checked}
          onItemCheck={onCheckItem}
        />
      </div>
      <div className="w-full mt-4">
        <button
          onClick={onPreSubmit}
          className="w-full bg-sky-500 hover:bg-sky-400 text-white py-4 px-4 rounded-md text-sm"
        >
          Cambiar
        </button>
      </div>
    </div>
  );
}

export default JefesDirectosCambio;
