function RoundedCard({
  icon,
  title = "",
  children,
  className = "flex flex-col border border-slate-300 rounded-md p-4 w-full",
  extraClass = "",
}) {
  return (
    <div className={className + " " + extraClass}>
      <div className="card-title flex gap-2 items-center">
        {!!icon && <div className="bg-blue-500 p-2 rounded-md">{icon}</div>}
        {/* {!icon && <div className="bg-red-400 rounded-md h-8 w-8"></div>} */}
        <div className="font-bold">{title}</div>
      </div>
      <div className="card-body mt-4">{children}</div>
    </div>
  );
}

export default RoundedCard;
