import SearchInput from "src/modules/remuneracion/components/SearchInput";
import PoliticaLayout from "../components/PoliticaLayout";
import DataTable from "src/modules/common/components/DataTable";
import { Folder2Open, FolderFill, Upload } from "react-bootstrap-icons";
import ReactSelect from "react-select";

function Politicas({
  tipoPoliticaLabel = "Evaluaciones",
  onActionButtonClick,
  data,
  count,
  filter,
  loading,
  onSearch,
  datatableConfig,
  onPaginate,
  checkable = false,
  onCheckAll,
  checkedItems = [],
  onItemCheck,
  reactSelectOptions = [],
  onChangeReactSelect,
}) {
  const { nombre } = filter;
  //
  return (
    <div className="mt-4">
      <div className="flex items-center gap-2 border-b mb-4 pb-2">
        <FolderFill color={"black"} />
        <span className="font-semibold"> Archivos para: </span>
        <span className="text-sky-500 font-semibold">{tipoPoliticaLabel}</span>
      </div>
      {/*  */}
      <div className="flex items-center gap-2">
        <SearchInput
          placeholder="Buscar cargo..."
          value={nombre}
          onChange={onSearch}
        />
        <ReactSelect
          className="w-52 h-10 bg-blue-200 inline-grid"
          value={{
            value: "",
            label: "(" + checkedItems.length + ") seleccionados",
          }}
          options={reactSelectOptions}
          onChange={onChangeReactSelect}
        />
        <button
          onClick={onActionButtonClick}
          className="flex items-center gap-2 bg-sky-500 text-white rounded-md px-4 py-2"
        >
          <Upload size={18} />
          <span className="text-sm">Cargar nuevo archivo</span>
        </button>
      </div>
      <div>
        <DataTable
          containerClass={"my-8"}
          data={data}
          loading={loading}
          config={datatableConfig}
          noHeaders={false}
          // pagination
          totalRecords={count}
          onPaginate={onPaginate}
          // outerPageSize={outerPageSize}
          // // check config
          checkable={checkable}
          onCheckAll={onCheckAll}
          checkedItems={checkedItems}
          // // checkedIdField={"colaborador_id"}
          onItemCheck={onItemCheck}
          // onRowClick={onRowClick}
        />
      </div>
    </div>
  );
}

export default Politicas;
