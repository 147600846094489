import axios from "axios";
const URL_BASE =
  process.env.REACT_APP_API_URL || "http://localhost:8000/api/admin";

export const sucursalApi = (token) => {
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };

  return {
    create: async ({ nombre, descripcion }) => {
      try {
        const sucursal = await axios.post(
          URL_BASE + "/sucursal/",
          { nombre, descripcion },
          { headers }
        );
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log(JSON.stringify(error));
        throw error;
      }
    },
    update: async ({ id, nombre, descripcion }) => {
      try {
        const data = await axios.put(
          URL_BASE + `/sucursal/${id}/`,
          { nombre, descripcion },
          { headers }
        );
        return data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log(JSON.stringify(error));
        throw error;
      }
    },
    delete: async ({ id }) => {
      try {
        const data = await axios.delete(URL_BASE + `/sucursal/${id}/`, {
          headers,
        });
        return data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log(JSON.stringify(error));
        throw error;
      }
    },
    sucursalesDelete: async ({ sucursalesIds }) => {
      try {
        const data = await axios.post(
          URL_BASE + `/sucursal/delete-ids/`,
          { ids: sucursalesIds },
          { headers }
        );
        return data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log(JSON.stringify(error));
        throw error;
      }
    },
    list: async ({ sucursales_filter }) => {
      const { nombre = "", pagination = { page: 1, pageSize: 100 } } =
        sucursales_filter;
      const { page, pageSize } = pagination;
      try {
        const sucursales = await axios.get(
          URL_BASE +
            `/sucursal/?search=${nombre}&page=${page}&page_size=${pageSize}`,
          {
            headers,
          }
        );
        return sucursales.data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log("sucursales error", error);
        throw error;
      }
    },
    //
    importarVerificar: async ({ fileToUpload }) => {
      try {
        const payload = {
          file: fileToUpload,
        };
        const headersForm = {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        };
        !!process.env.REACT_APP_DEBUG && console.info("sucursal importar api");
        const response = await axios.post(
          URL_BASE + `/estructura/importar-sucursales-verificar/`,
          { file: fileToUpload },
          { headers: headersForm }
        );
        !!process.env.REACT_APP_DEBUG && console.info("response", response);
        return response.data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log("sucursales error", error);
        throw error;
      }
    },
    importar: async (data) => {
      try {
        !!process.env.REACT_APP_DEBUG &&
          console.info("sucursal importar api", data);
        const response = await axios.post(
          URL_BASE + `/estructura/importar-sucursales/`,
          { sucursales: data },
          { headers }
        );
        !!process.env.REACT_APP_DEBUG && console.info("response", response);
        return response.data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log("sucursales error", error);
        throw error;
      }
    },
  };
};
