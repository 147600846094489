import axios from "axios";
const URL_BASE =
  process.env.REACT_APP_API_URL || "http://localhost:8000/api/admin";

export const colaboradorLibresApi = (token) => {
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };

  return {
    // Laboral
    getLaboral: async ({ id }) => {
      try {
        const colaborador = await axios.get(
          `${URL_BASE}/empleado/${id}/laboral/`,
          {
            headers,
          }
        );
        return colaborador.data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG &&
          console.log("colaboradores error", error);
        throw error;
      }
    },
    updateLaboral: async ({ id, ...params }) => {
      try {
        const data = await axios.put(
          URL_BASE + `/empleado/${id}/laboral/edit/`,
          { ...params },
          { headers }
        );
        return data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log(JSON.stringify(error));
        throw error;
      }
    },
    colaboradoresLibres: async ({ id, colaboradores_libres_filter }) => {
      const { nombre = "" } = colaboradores_libres_filter;
      try {
        const colaboradores = await axios.get(
          `${URL_BASE}/empleado/${id}/colaboradores-libres/?search=${nombre}`,
          {
            headers,
          }
        );
        return colaboradores.data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG &&
          console.log("colaboradores error", error);
        throw error;
      }
    },
  };
};
