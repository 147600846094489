import React from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactSelect from "react-select";
import { PrimaryButton } from "src/modules/common/components/PrimaryButton";
import { BuscarInput } from "../../../../common/components/BuscarInput";
import { colaboradorLaboralActions } from "../handlers/redux";

export const ColaboradoresLibresToolbar = () => {
  const dispatch = useDispatch();

  const colaboradores_libres_checked = useSelector(
    (state) => state.colaboradorLaboral.colaboradores_libres_checked
  );
  const colaboradores_libres_filter = useSelector(
    (state) => state.colaboradorLaboral.colaboradores_libres_filter
  );
  const colaborador_laboral = useSelector(
    (state) => state.colaboradorLaboral.colaborador_laboral
  );
  const colaboradores_libres = useSelector(
    (state) => state.colaboradorLaboral.colaboradores_libres.results
  );

  const { nombre = "" } = colaboradores_libres_filter;
  //
  const onAgregar = () => {
    const colaboradores = colaboradores_libres.filter((colaborador) =>
      colaboradores_libres_checked.includes(colaborador.id)
    );

    dispatch(
      colaboradorLaboralActions.colaboradorLaboralSet({
        ...colaborador_laboral,
        colaboradores,
      })
    );

    dispatch(
      colaboradorLaboralActions.setColaboradoresLibresSelectModal({
        open: false,
      })
    );
  };
  //
  const onAction = (v) => {
    if (v.value == "cancel") {
      dispatch(colaboradorLaboralActions.resetCheckColaboradoresLibres());
      return;
    }
  };

  const onChangeText = (event) => {
    dispatch(
      colaboradorLaboralActions.setColaboradoresLibresFilter({
        nombre: event.target.value,
      })
    );
  };

  return (
    <div className="flex flex-row gap-2">
      <BuscarInput onChange={onChangeText} value={nombre}></BuscarInput>
      <ReactSelect
        className="w-52 h-10 bg-blue-200 inline-grid"
        value={{
          value: "",
          label: "(" + colaboradores_libres_checked.length + ") seleccionados",
        }}
        options={[{ value: "cancel", label: "Cancelar" }]}
        onChange={onAction}
      ></ReactSelect>
      <PrimaryButton onClick={onAgregar}>Agregar</PrimaryButton>
    </div>
  );
};
