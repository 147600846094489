import axios from "axios";

const URL_BASE =
  process.env.REACT_APP_API_URL || "http://localhost:8000/api/admin";

export const aprendizajeApis = (token) => {
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };
  return {
    listFormaciones: async (formaciones_filter) => {
      const { nombre = "" } = formaciones_filter;
      const url = URL_BASE + `/aprendizaje/formacion/list?search=${nombre}`;
      const response = await axios.get(url, {
        headers,
      });
      return response.data;
    },
    detailFormacion: async (idFormacion) => {
      const url = URL_BASE + `/aprendizaje/formacion/${idFormacion}/detail`;
      const response = await axios.get(url, {
        headers,
      });
      return response.data;
    },
    //
    listTutoriales: async (formaciones_filter) => {
      const { nombre = "" } = formaciones_filter;
      const url = URL_BASE + `/aprendizaje/tutorial/list?search=${nombre}`;
      const response = await axios.get(url, {
        headers,
      });
      return response.data;
    },
    detailTutorial: async (idTutorial) => {
      const url = URL_BASE + `/aprendizaje/tutorial/${idTutorial}/detail`;
      const response = await axios.get(url, {
        headers,
      });
      return response.data;
    },
  };
};
