import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { commonActions } from "src/modules/common/handler/redux";

function PoliticaLayout({ children }) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(commonActions.setTitle("Políticas"));
    dispatch(commonActions.showSidebar(true));
    dispatch(
      commonActions.setTitleMenu([
        {
          name: "Evaluaciones",
          to: "/politica/evaluaciones",
        },
        {
          name: "Remuneraciones",
          to: "/politica/remuneraciones",
        },
        {
          name: "Planes de carrera",
          to: "/politica/planes-carrera",
        },
      ])
    );
    return () => {
      commonActions.setTitleMenu([]);
    };
  }, []);

  return <>{children}</>;
}

export default PoliticaLayout;
