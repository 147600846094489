import React from "react";
import CompetenciasLayout from "../../common/components/CompetenciasLayout";
import { CompetenciaCollapsable } from "../../common/components/CompetenciaCollapsable";
import { Award } from "react-bootstrap-icons";
import { useDispatch, useSelector } from "react-redux";
import Input from "src/modules/common/components/Input";
import { commonActions } from "src/modules/common/handler/redux";
import { useState } from "react";
import CompetenciaModal from "../../common/components/CompetenciaModal";
import { competenciaGeneralActions } from "src/modules/competencia/modules/general/handlers/redux";
import { PrimaryButton } from "src/modules/common/components/PrimaryButton";
import { CompetenciaPieChart } from "src/modules/competencia/modules/common/components/CompetenciaPieChart.";
import CompetenciaLoading from "../../common/components/CompetenciaLoading";
import { empresaActions } from "src/modules/ajuste/handlers/redux";
import PlanAvanzadoAlert from "../../common/components/PlanAvanzadoAlert";

function CompetenciaGeneralPage() {
  const dispatch = useDispatch();
  const empresa = useSelector((state) => state.empresa.empresa);
  const [modalOpen, setModalOpen] = useState(false);
  const [competencia, setCompetencia] = useState({});
  const [aplicarPesos, setAplicarPesos] = useState(false);
  const [sumaPesos, setSumaPesos] = useState(0);
  const [edit, setEdit] = useState(false);

  const [idx, setIdx] = useState(null);
  //
  const competencia_general = useSelector(
    (state) => state.competenciaGeneral.competencia_general
  );
  const competencia_generales = useSelector(
    (state) => state.competenciaGeneral.competencia_generales
  );

  const { results = [] } = competencia_generales;
  const { competencias = [] } = competencia_general;

  //
  const competencia_general_edit = useSelector(
    (state) => state.competenciaGeneral.competencia_general_edit
  );

  const competencia_general_configuracion_loading = useSelector(
    (state) =>
      state.competenciaGeneral.competencia_general_configuracion_loading
  );

  React.useEffect(() => {
    dispatch(commonActions.showSidebar(true));
    dispatch(competenciaGeneralActions.competenciaGenerales());
    dispatch(empresaActions.empresa());
    //
    const sumaPesos = results.reduce((sum, comp) => sum + comp.peso, 0);
    !!process.env.REACT_APP_DEBUG && console.info("sumaPesos", sumaPesos);
    if (sumaPesos > 0) setAplicarPesos(true);
  }, []);

  const tienePlanAvanzado = () => {
    return empresa.tipo_plan === "Avanzado";
  };

  const onEdit = (idx) => (competencia) => {
    setCompetencia({ ...competencia });
    setModalOpen(true);
    setIdx(idx);
  };

  const onDelete = (idx) => (competencia) => {
    let deseaEliminar = window.confirm(
      "¿Desea eliminar la competencia seleccionada?"
    );
    if (!!deseaEliminar) {
      setIdx(idx);

      let competencias_new = competencias.filter((_, cIdx) => idx != cIdx);
      onChange({ ...competencia_general, competencias: competencias_new });
    }
  };

  const renderListaCompetencias = () => {
    const items = competencia_general_edit ? competencias : results;
    return (
      <div className="flex flex-col gap-3">
        {items.length > 0 &&
          items.map((competencia, idx) => (
            <CompetenciaCollapsable
              key={competencia.id}
              competencia={competencia}
              editable={!!competencia_general_edit}
              showInput={!!tienePlanAvanzado() && !!aplicarPesos}
              onEdit={competencia_general_edit ? onEdit(idx) : null}
              onDelete={competencia_general_edit ? onDelete(idx) : null}
              onChange={onChangeCompentencia}
              idx={idx}
            />
          ))}
        {items.length < 1 && (
          <div className="flex items-center justify-center h-20 border border-slate-200 rounded-md">
            No existen Competencias para mostrar.
          </div>
        )}
      </div>
    );
  };

  const onClickAdd = () => {
    setCompetencia({});
    setIdx(null);
    setModalOpen(true);
  };

  const onAplicarPesoCheck = (e) => {
    setAplicarPesos(e.target.checked);
  };

  const onChange = (competencia_general) => {
    dispatch(
      competenciaGeneralActions.competenciaGeneralSet(competencia_general)
    );
  };

  const onSubmitCompetencia = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (!competencia.nombre) return;

    let { conductas = [] } = competencia;
    conductas = conductas.filter((c) => !!c.descripcion);

    onChangeCompentencia({ ...competencia, conductas }, idx);
    setModalOpen(false);
  };

  const onChangeCompentencia = (competencia, idx) => {
    let competencias_new = [...competencias];
    if (idx == null) {
      competencias_new.push(competencia);
    } else {
      competencias_new[idx] = competencia;
    }
    onChange({ ...competencia_general, competencias: competencias_new });
  };

  const onSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    dispatch(competenciaGeneralActions.setAplicarPesosGenerales(aplicarPesos));
    dispatch(competenciaGeneralActions.competenciaGeneralConfiguracion());
  };

  const onClickEdit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    dispatch(
      competenciaGeneralActions.competenciaGeneralSet({
        competencias: [...results],
      })
    );
    dispatch(competenciaGeneralActions.competenciaGeneralEdit(true));
  };

  const onClickEditCancel = (event) => {
    event.preventDefault();
    event.stopPropagation();
    dispatch(competenciaGeneralActions.competenciaGeneralSet({}));
    dispatch(competenciaGeneralActions.competenciaGeneralEdit(false));
  };

  return (
    <CompetenciasLayout>
      <div className="flex flex-col items-center mt-4">
        <form
          onSubmit={onSubmit}
          className="flex flex-col w-full md:w-5/6 gap-3"
        >
          <div className="flex justify-between items-center text-lg font-bold border-b py-2">
            <div>Competencias generales</div>
            {!competencia_general_configuracion_loading && (
              <>
                {!competencia_general_edit && (
                  <PrimaryButton type="button" onClick={onClickEdit}>
                    {results.length > 0 ? "Editar" : "Agregar"}
                  </PrimaryButton>
                )}
                {!!competencia_general_edit && (
                  <div className="flex gap-2">
                    <PrimaryButton type="submit">Guardar</PrimaryButton>

                    <PrimaryButton
                      className="bg-red-500"
                      onClick={onClickEditCancel}
                    >
                      Cancelar
                    </PrimaryButton>
                  </div>
                )}
              </>
            )}
          </div>

          <div className={`flex flex-col gap-3 pr-3`}>
            <div className="flex flex-row gap-2 items-center">
              {!!competencia_general_edit && !!tienePlanAvanzado() && (
                <>
                  <div className="flex items-center">
                    <input
                      type="checkbox"
                      className="m-0 p-0 h-4 w-4 accent-sky-600"
                      checked={aplicarPesos}
                      onChange={onAplicarPesoCheck}
                    />
                  </div>
                  <div className="font-bold text-sm">
                    Aplicar pesos a las competencias
                  </div>
                </>
              )}
            </div>
            {/* GRÁFICO */}
            <div className="flex flex-col items-center gap-2">
              {!!competencia_general_edit &&
                !!tienePlanAvanzado() &&
                !!aplicarPesos && (
                  <div className="">
                    <CompetenciaPieChart
                      competencias={
                        competencia_general_edit ? competencias : results
                      }
                    />
                  </div>
                )}
              {!tienePlanAvanzado() && <PlanAvanzadoAlert></PlanAvanzadoAlert>}
            </div>
            {/* INDICADORES */}
            <div className="flex flex-col gap-2">
              {/* title */}
              <div className="flex flex-row justify-between font-bold">
                <div className="after:content-['*'] after:ml-0.5 after:text-red-500">
                  Competencias a evaluar
                </div>
                {!!tienePlanAvanzado() && !!aplicarPesos && (
                  <div className="w-24 after:content-['*'] after:ml-0.5 after:text-red-500">
                    Peso
                  </div>
                )}
              </div>
              {/* LISTA */}
              {!competencia_general_configuracion_loading &&
                renderListaCompetencias()}
              {/* loading */}
              {!!competencia_general_configuracion_loading &&
                [0, 1, 2].map(() => <CompetenciaLoading></CompetenciaLoading>)}
              {!competencia_general_configuracion_loading &&
                !!competencia_general_edit && (
                  <div
                    className="flex flex-row gap-2 items-center pl-3"
                    onClick={onClickAdd}
                  >
                    <div className="w-5 h-5 rounded-full bg-sky-500 text-white flex items-center justify-center text-2xl">
                      +
                    </div>
                    <div className="text-sky-500 text-sm">
                      Agregar otra competencia a evaluar
                    </div>
                  </div>
                )}
              <CompetenciaModal
                open={modalOpen}
                setOpen={setModalOpen}
                competencia={competencia}
                onChange={setCompetencia}
                onSubmit={onSubmitCompetencia}
                idx={idx}
                especifica={false}
              />
            </div>
          </div>
          {!competencia_general_configuracion_loading && (
            <div className="flex flex-col gap-2 mb-2">
              {!!competencia_general_edit && (
                <PrimaryButton type="submit">
                  Guardar configuración de Competencias
                </PrimaryButton>
              )}
              {!!competencia_general_edit && (
                <PrimaryButton
                  className="bg-red-500"
                  onClick={onClickEditCancel}
                >
                  Cancelar
                </PrimaryButton>
              )}
              {!competencia_general_edit && (
                <PrimaryButton type="button" onClick={onClickEdit}>
                  {results.length > 0 ? "Editar" : "Agregar"}
                </PrimaryButton>
              )}
            </div>
          )}
        </form>
      </div>
    </CompetenciasLayout>
  );
}

export default CompetenciaGeneralPage;
