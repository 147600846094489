import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { areaActions } from "src/modules/estructura/modules/area/handlers/redux";
import { evaluacionActions } from "../handlers/redux";

const useAreas = () => {
  const dispatch = useDispatch();

  const areas_results = useSelector((state) => state.area.areas);
  const { results: areas } = areas_results;

  const areas_filter = useSelector((state) => state.area.areas_filter);
  const { nombre } = areas_filter;

  const filterAreas = (nombre) => {
    dispatch(areaActions.setAreasFilter({ nombre: nombre }));
  };

  return {
    areas,
    nombre,
    filterAreas,
  };
};

export default useAreas;
