import React from "react";
import { FeedbackLayout } from "../components/FeedbackLayout";
import { FeedbackGeneralPage } from "../pages/FeedbackGeneralPage";
import { FeedbackPorJefeDirectoPage } from "../pages/FeedbackPorJefeDirectoPage";
import { isElement } from "react-dom/test-utils";
import { FeedbackConfiguracion } from "../pages/FeedbackConfiguracion";
import { FeedbackInicio } from "../pages/FeedbackInicio";
import { FeedbackJefeDirectoDetalle } from "../pages/FeedbackJefeDirectoDetalle";
import { Route, Routes } from "react-router-dom";
import { FeedbackEtapaJefeDirecto } from "../pages/FeedbackEtapaJefeDirecto";
import { FeedbackEtapaColaborador } from "../pages/FeedbackEtapaColaborador";
import { FeedbackEtapaFinalizado } from "../pages/FeedbackEtapaFinalizado";
import { FeedbackEtapasPage } from "../pages/FeedbackEtapasPage";

export const feedbackRoutes = [
  {
    path: "/feedback/",
    element: <FeedbackGeneralPage />,
  },
  {
    path: "/feedback/por-jefe-directo/",
    element: <FeedbackPorJefeDirectoPage />,
  },
  {
    path: "/feedback/configuracion/",
    element: <FeedbackConfiguracion />,
  },
  {
    path: "/feedback/configuracion/:feedbackId",
    element: <FeedbackConfiguracion />,
  },
  {
    path: "/feedback/inicio/:idFeedback/",
    element: <FeedbackInicio />,
  },
  {
    path: "/feedback/:idFeedback/jefe-directo/:idJefeDirecto/",
    element: <FeedbackJefeDirectoDetalle />,
  },
  {
    path: "/feedback/:idFeedback/jefe-directo/:idJefeDirecto/colaborador/:idColaborador/*",
    element: <FeedbackEtapasPage />,
  },
];

export const feedbackEtapasName = ["jefe-directo", "evaluado", "finalizado"];

export const FeedbackEtapasRoutes = () => {
  const feedbackEtapasRoutes = [
    // Estos path se concatenaran al path de <FeedbackEtapasPage />
    {
      path: feedbackEtapasName[0],
      element: <FeedbackEtapaJefeDirecto />,
    },
    {
      path: feedbackEtapasName[1],
      element: <FeedbackEtapaColaborador />,
    },
    {
      path: feedbackEtapasName[2],
      element: <FeedbackEtapaFinalizado />,
    },
  ];
  return (
    <Routes>
      {feedbackEtapasRoutes.map((route, idx) => (
        <Route key={idx} {...route} />
      ))}
    </Routes>
  );
};
