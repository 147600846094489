import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import backButton from "src/modules/common/components/images/back-arrow.png";
import { PrimaryButton } from "src/modules/common/components/PrimaryButton";
import { commonActions } from "src/modules/common/handler/redux";
import { KpiCard } from "../components/KpiCard";
import { kpiActions } from "../handlers/redux";

function KpiColaboradorDetailPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const { idkpi: idKpi } = params;

  const kpiColaborador = useSelector((state) => state.kpi.kpi_colaborador);
  const kpiColaborador_loading = useSelector(
    (state) => state.kpi.kpi_colaborador_loading
  );
  const colaboradores = useSelector(
    (state) => state?.colaborador?.colaboradores
  );
  const kpiColaboradores = useSelector((state) => state.kpi.kpiColaboradores);
  const kpiColaboradores_checked = useSelector(
    (state) => state.kpi.kpiColaboradores_checked
  );

  useEffect(() => {
    dispatch(commonActions.setTitleMenu([]));
    dispatch(commonActions.setTitle(""));
    dispatch(commonActions.showSidebar(false));
    dispatch(kpiActions.getKpiColaborador({ id: idKpi }));
    // dispatch(kpiActions.kpiColaboradores());
  }, []);

  const onClickEdit = (id) => {
    navigate(`/kpis/kpi-colaborador/${id}/edit`);
  };

  const onClickDelete = (id) => {
    let deseaEliminar = window.confirm("Desea eliminar el KPI?");
    if (deseaEliminar) {
      // TODO
      // dispatch(kpiActions.kpiDelete({ id: id, fromDetail: true }));
    }
  };

  !!process.env.REACT_APP_DEBUG &&
    console.info("kpiColaborador page", kpiColaborador);

  return (
    <>
      {/* HEADER */}
      <div className="flex justify-between items-center bg-zinc-100 py-2.5 px-7 border-b -mx-8">
        <div className="flex items-center">
          <div
            onClick={() =>
              navigate(
                `/kpis/colaboradores/${kpiColaborador.colaborador?.id || ""}`
              )
            }
            className={
              "h-full  flex items-center pr-2 -mr-2 z-10 cursor-pointer"
            }
          >
            <img className="back" src={backButton} alt="Regresar" />
          </div>
          <div className="font-medium px-4 text-lg">
            KPI de{" "}
            <span className="font-bold">
              {kpiColaborador.colaborador
                ? `${kpiColaborador.colaborador.apellido}, ${kpiColaborador.colaborador.nombre}`
                : ""}
            </span>
          </div>
        </div>
        <div className="flex items-center gap-4 button-group">
          <PrimaryButton onClick={() => onClickEdit(kpiColaborador.id)}>
            <span className="font-bold">Editar indicador</span>
          </PrimaryButton>
          <button
            className="text-red-500"
            onClick={() => onClickDelete(kpiColaborador.id)}
          >
            <span className="font-medium text-sm">Eliminar</span>
          </button>
        </div>
      </div>
      {/*  */}
      <div className="flex justify-center mt-8">
        <div className="w-5/5 md:w-4/5">
          <div className="p-1 font-bold">Detalle de KPI</div>
          <div className="border-b border-b-gray-200 mb-3"></div>
          {!!kpiColaborador.nombre && (
            <KpiCard
              kpi={kpiColaborador}
              loading={kpiColaborador_loading}
            ></KpiCard>
          )}
          {/* COLABORADORES */}
        </div>
      </div>
    </>
  );
}

export default KpiColaboradorDetailPage;
