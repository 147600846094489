import React from "react";
import { useDispatch } from "react-redux";
import { DotMenu, DotMenuItem } from "../../../../common/components/DotMenu";
import { sucursalActions } from "../handlers/redux";

export const SucursalTableAccionMenu = ({ item = {} }) => {
  const dispatch = useDispatch();

  const onEdit = () => {
    dispatch(sucursalActions.sucursaleset({ ...item }));
    dispatch(sucursalActions.setSucursalCreateModal({ open: true }));
  };

  const onDelete = () => {
    let deseaEliminar = window.confirm(
      "Desea eliminar la Sucursal seleccionada?"
    );
    if (deseaEliminar) {
      // @ts-ignore
      dispatch(sucursalActions.sucursalDelete({ id: item?.id }));
    }
  };

  return (
    <DotMenu
      dir="left"
      className="w-10 h-6  flex flex-row justify-center items-center"
    >
      <>
        <DotMenuItem onClick={onEdit}>Editar</DotMenuItem>
        <DotMenuItem onClick={onDelete}>Eliminar</DotMenuItem>
      </>
    </DotMenu>
  );
};
