import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { commonActions } from "src/modules/common/handler/redux";

export function AprendizajeLayout({ children }) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(commonActions.setTitle("Aprendizaje"));
    dispatch(commonActions.showSidebar(true));
    dispatch(
      commonActions.setTitleMenu([
        {
          name: "Formación",
          to: "/aprendizaje/formacion",
        },
        {
          name: "Tutoriales",
          to: "/aprendizaje/tutoriales",
        },
      ])
    );
    return () => {
      commonActions.setTitleMenu([]);
    };
  }, []);

  return <>{children}</>;
}
