import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ProfileInfoCard } from "src/modules/common/components/ProfileInfoCard";
import TableCustom from "src/modules/common/components/TableCustom";
import TableRow from "src/modules/common/components/TableRow";
import { evaluacionActions } from "../handlers/redux";

function EvaluadoresColaboradorList() {
  const dispatch = useDispatch();
  const evaluadores_evaluado = useSelector(
    (state) => state.evaluacion.evaluadores_evaluado
  );
  const evaluadores_colaborador = useSelector(
    (state) => state.evaluacion.evaluadores_colaborador
  );
  const evaluadores_colaborador_filter = useSelector(
    (state) => state.evaluacion.evaluadores_colaborador_filter
  );
  const evaluadores_colaborador_loading = useSelector(
    (state) => state.evaluacion.evaluadores_colaborador_loading
  );
  const evaluadores_colaborador_checked = useSelector(
    (state) => state.evaluacion.evaluadores_colaborador_checked
  );
  const { nombre: nombre_cargo } = evaluadores_colaborador_filter;
  //
  useEffect(() => {
    !!process.env.REACT_APP_DEBUG &&
      console.log("evaluadores_evaluado useEffect", evaluadores_evaluado);
    dispatch(
      evaluacionActions.getEvaluadoresColaborador({
        colaboradorId: evaluadores_evaluado.id,
      })
    );
  }, [evaluadores_evaluado]);

  const getPillColor = (relacion) => {
    const colors = {
      Autoevaluación: "bg-black",
      "Jefe directo": "bg-red-400",
      Par: "bg-orange-400",
      Subalterno: "bg-sky-500",
    };
    return colors[relacion] || "bg-slate-100 text-black";
  };

  const ItemCommonComponent = ({ item }) => {
    return (
      <TableRow>
        <div
          className="p-4 flex gap-8 items-center justify-start"
          onClick={() => {}}
        >
          <div className="w-2/3">
            <ProfileInfoCard
              name={`${item.nombre} ${item.apellido}`}
              description={
                item.cargo?.nombre
                  ? `Cargo: ${item.cargo?.nombre}`
                  : "Cargo: Sin especificar"
              }
              image={item.foto_empleado ? item.foto_empleado : ""}
              nameWidth={null}
            ></ProfileInfoCard>
          </div>
          <div className="flex flex-1 items-center justify-center">
            <span
              className={`${getPillColor(
                item.relacion
              )} text-sm text-white rounded-md p-1`}
            >
              {item.relacion}
            </span>
          </div>
        </div>
      </TableRow>
    );
  };
  const onCheckHandler = (event, item) => {
    !!process.env.REACT_APP_DEBUG &&
      console.log("h checkEvaluadorColaborador", item, event.target.checked);
    dispatch(
      evaluacionActions.checkEvaluadorColaborador({
        evaluador: item,
        checked: event.target.checked,
      })
    );
  };

  const onChangeSearch = (e) => {
    !!process.env.REACT_APP_DEBUG &&
      console.info("evaluadores_evaluado", evaluadores_evaluado);
    dispatch(
      evaluacionActions.setEvaluadoresColaboradorFilter({
        colaboradorId: evaluadores_evaluado.id,
        // ...evaluadores_colaborador_filter,
        nombre: e.target.value,
      })
    );
  };

  const onSelectChange = (v, items = []) => {
    if (v.value === "cancel") {
      dispatch(evaluacionActions.resetCheckEvaluadoresColaborador());
    } else {
      // @ts-ignore
      window.alert(JSON.stringify(v));
    }
  };

  const agregarEvaluadores = () => {
    dispatch(
      evaluacionActions.setEvaluadoresAgregar(evaluadores_colaborador_checked)
    );
  };

  const commonProps = {
    ItemComponent: ItemCommonComponent,
    subject: "colaborador",
    subjectGender: "M",
    hasAddBtn: true,
    customAddBtnLabel: "Agregar evaluadores",
    noBottomButtom: true,
    selectOptions: [],
  };
  //
  return (
    <div>
      <div className="mb-4">
        <ProfileInfoCard
          name={`${evaluadores_evaluado.nombre} ${evaluadores_evaluado.apellido}`}
          description={
            evaluadores_evaluado.cargo?.nombre
              ? `Cargo: ${evaluadores_evaluado.cargo?.nombre}`
              : ""
          }
          image={
            evaluadores_evaluado.foto_empleado
              ? evaluadores_evaluado.foto_empleado
              : ""
          }
        ></ProfileInfoCard>
      </div>
      <TableCustom
        {...commonProps}
        tableSecondTitle={"Evaluadores asignados"}
        addBtnClickHandler={agregarEvaluadores}
        onCheckHandler={onCheckHandler}
        data={evaluadores_colaborador}
        searchValue={nombre_cargo}
        onChangeSearch={onChangeSearch}
        itemsChecked={evaluadores_colaborador_checked}
        fixedHeigth={true}
        onSelectChange={onSelectChange}
        selectOptions={[{ value: "cancel", label: "Cancelar" }]}
        loading={evaluadores_colaborador_loading}
      />
    </div>
  );
}

export default EvaluadoresColaboradorList;
