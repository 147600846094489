import { CheckLg, PencilFill, TrashFill, XLg } from "react-bootstrap-icons";

export const CategoriaRow = (
  calificacion,
  idx,
  onEditRow,
  isEditing,
  setIsEditing,
  isEditable = true,
  onDelete,
  disabled = false,
  key = ""
) => {
  const onEdit = () => {
    setIsEditing(true);
  };
  const onSave = () => {
    setIsEditing(false);
  };
  const onCancel = () => {
    setIsEditing(false, true);
  };
  const onChange =
    (field) =>
    ({ target }) => {
      let value = target.value;
      let { nombre, puntaje_desde } = calificacion;
      if (field === "nombre") onEditRow(idx, value, puntaje_desde);
      if (field === "puntaje_desde") onEditRow(idx, nombre, value);
      !!process.env.REACT_APP_DEBUG &&
        console.log("califrow onChange", value, field, typeof field);
      //   setCalificacionLocal({ ...calificacionLocal, [field]: value });
    };
  //
  return (
    <div className="w-full justify-between flex" key={key}>
      {!!isEditable && (
        <>
          <div className="group h-12 flex justify-between items-center gap-2 borderx border-blue-400x w-1/2 font-bold uppercase py-2">
            {!isEditing && <div>{calificacion.nombre}</div>}
            {!!isEditing && (
              <div>
                <input
                  type="text"
                  disabled={disabled}
                  className="border rounded placeholder-zinc-600 border-zinc-200 w-2/3 px-2 py-1"
                  value={calificacion.nombre || ""}
                  onChange={onChange("nombre")}
                />
              </div>
            )}
            {!disabled && (
              <div className="gap-1 mr-8 hidden group-hover:flex">
                {!isEditing && (
                  <button className="border p-2 rounded-md" onClick={onEdit}>
                    <PencilFill></PencilFill>
                  </button>
                )}
                {!!isEditing && (
                  <>
                    <button
                      className="border p-2 rounded-md bg-sky-500"
                      onClick={onSave}
                    >
                      <CheckLg size={20} color={"white"}></CheckLg>
                    </button>
                    <button
                      className="border p-2 rounded-md"
                      onClick={onCancel}
                    >
                      <XLg size={20}></XLg>
                    </button>
                  </>
                )}
              </div>
            )}
          </div>
          <div className="flex flex-1 gap-2 items-center borderx border-blue-500x py-2 w-1/2">
            <div className="flex-1">
              {!isEditing && (
                <>{calificacion.puntaje_desde?.toString().replace(".", ",")}</>
              )}
              {!!isEditing && (
                <input
                  type="text"
                  disabled={disabled}
                  maxlength="5"
                  className="border rounded placeholder-zinc-600 border-zinc-200 w-2/3 px-2 py-1"
                  value={calificacion.puntaje_desde}
                  onChange={onChange("puntaje_desde")}
                />
              )}
            </div>

            <div className="flex-1">
              <div className="flex items-center justify-between gap-2 w-1/2">
                <div>
                  {calificacion.puntaje_hasta?.toString().replace(".", ",")}
                </div>
                {!!isEditing && !!calificacion.isRemovable && (
                  <button className="border p-2 rounded-md" onClick={onDelete}>
                    <TrashFill></TrashFill>
                  </button>
                )}
              </div>
            </div>

            {/* <div>
            <input
              type="number"
              className="border rounded placeholder-zinc-600 border-zinc-200 w-2/3 px-2 py-1"
              value={calificacion.hasta || 0}
            />
          </div> */}
          </div>
        </>
      )}
      {/* ReadOnly Row */}
      {!isEditable && (
        <>
          <div className="group h-12 flex justify-between items-center gap-2 borderx border-blue-400x w-1/2 font-bold uppercase py-2">
            <div>{calificacion.nombre}</div>
          </div>
          <div className="flex flex-1 gap-2 items-center borderx border-blue-500x py-2 w-1/2">
            <div className="flex-1">
              <>{calificacion.puntaje_desde?.toString().replace(".", ",")}</>
            </div>
            {/* <div className="flex-1">{calificacion.puntaje_hasta}</div> */}
          </div>
        </>
      )}
    </div>
  );
};
