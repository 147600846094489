import { push } from "@lagunovsky/redux-react-router";
import { call, put, takeEvery } from "redux-saga/effects";
import { empresaActions } from "src/modules/ajuste/handlers/redux";
import { authApi } from "./api";
import { authActions } from "./redux";

function* loginSaga({ payload }) {
  try {
    const data = yield call(authApi().login, {
      user: payload.user,
      password: payload.password,
      empresa: payload.empresa,
    });
    if (!data.es_admin) {
      // @ts-ignore
      throw new Error("No tiene permiso para acceder", {
        cause: "unauthorized",
      });
    }
    // asignamos el token como valido
    yield put(authActions.setUser({ user: data }));
    localStorage.setItem("user", JSON.stringify(data));
    yield put(empresaActions.empresa());
    yield put(push("/analisis"));
    yield put(authActions.loginComplete());
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
    //
    switch (error.request?.status || error.cause) {
      case 401:
        yield put(authActions.loginError("Usuario y contraseña no válidas."));
        break;
      case 0:
        yield put(
          authActions.loginError(
            "Error de servidor, por favor intente más tarde"
          )
        );
        break;
      case "unauthorized":
        yield put(authActions.loginError("No tiene los permisos necesarios."));
        break;
      default:
        yield put(
          authActions.loginError(
            "Ha ocurrido un error. Favor intente más tarde"
          )
        );
    }
  }
}

function* refreshSaga({ payload }) {
  try {
    const data = yield call(authApi().refresh, {
      refresh: payload.refresh,
    });
    // if (!data.es_admin) {
    //   // @ts-ignore
    //   throw new Error("No tiene permiso para acceder", {
    //     cause: "unauthorized",
    //   });
    // }
    // asignamos el token como valido
    yield put(authActions.setUser({ user: data }));
    localStorage.setItem("user", JSON.stringify(data));
    yield put(empresaActions.empresa());
    yield put(push("/analisis"));
    yield put(authActions.loginComplete());
  } catch (error) {
    //
    switch (error.request?.status || error.cause) {
      case 401:
        yield put(authActions.loginError("Usuario y contraseña no válidas."));
        break;
      case 0:
        yield put(
          authActions.loginError(
            "Error de servidor, por favor intente más tarde"
          )
        );
        break;
      case "unauthorized":
        yield put(authActions.loginError("No tiene los permisos necesarios."));
        break;
      default:
        yield put(
          authActions.loginError(
            "Ha ocurrido un error. Favor intente más tarde"
          )
        );
    }
  }
}

function* getEmpresasSaga({ payload }) {
  try {
    const data = yield call(authApi().getEmpresas, {
      username: payload.username,
    });
    yield put(authActions.setEmpresas(data));
  } catch (error) {
    yield put(
      authActions.loginError("Error de servidor, por favor intente más tarde")
    );
  }
}

export function* authSagas() {
  yield takeEvery(authActions.login.type, loginSaga);
  yield takeEvery(authActions.refresh.type, refreshSaga);
  yield takeEvery(authActions.getEmpresas.type, getEmpresasSaga);
}
