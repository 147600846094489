import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { commonActions } from "src/modules/common/handler/redux";

export function FeedbackLayout({ children }) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(commonActions.setTitle("Feedback"));
    dispatch(commonActions.showSidebar(true));
    dispatch(
      commonActions.setTitleMenu([
        {
          name: "General",
          to: "/feedback/",
        },
        {
          name: "Por jefe directo",
          to: "/feedback/por-jefe-directo/",
        },
      ])
    );
    return () => {
      commonActions.setTitleMenu([]);
    };
  }, []);

  return <>{children}</>;
}
