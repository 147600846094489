import React from "react";
import TableCustom from "src/modules/common/components/TableCustom";
import PlanCarreraLayout from "../components/PlanCarreraLayout";

function PlanCarreraVacancias() {
  return (
    <PlanCarreraLayout>
      <div className="md:w-11/12">
        <TableCustom
          data={[]}
          tableTitle={""}
          subject={"vacante"}
          subjectGender={"F"}
          addBtnClickHandler={null}
          dropdownActions={null}
        />
      </div>
    </PlanCarreraLayout>
  );
}

export default PlanCarreraVacancias;
