import { render } from "@testing-library/react";
import React, { useEffect, useState } from "react";
import ReactSelect from "react-select";
import { BuscarInput } from "./BuscarInput";
import SkeletonLoaderRow from "./SkeletonLoaderRow";
import TableRowCheck from "./TableRowCheck";

function TableCustom({
  data = [],
  tableTitle = "",
  tableSecondTitle = "",
  subject = "item",
  subjectGender = "M",
  addBtnClickHandler = null,
  dropdownActions = null,
  itemKey = "id",
  ItemComponent = null,
  noBottomButtom = false,
  customAddBtnLabel = "",
  addBtnColor = "bg-sky-500",
  checked = [],
  onChangeSearch = undefined,
  searchValue = "",
  onSelectChange = undefined,
  selectOptions = [],
  itemsChecked = [],
  onCheckHandler = undefined,
  hasAddBtn = true,
  hasActionSelect = true,
  hasChecks = true,
  customHeaders = [],
  multiple = true,
  fixedHeigth = false,
  checkType = "checkbox",
  loading = false,
  renderExtraFilters,
  actionSelectDefaulValue = false,
  totalPaginate = 1,
  onPaginate,
}) {
  const [selectedCount, setSelectedCount] = React.useState(0);
  const [activeRowNumberMenu, setActiveRowNumberMenu] = React.useState(null);
  const [checkAll, setCheckAll] = React.useState(false);
  const [checkListValues, setCheckListValues] = React.useState(itemsChecked);
  // Pagination
  const [Page, setPage] = useState(1);
  const [PageSize, setPageSize] = useState(100);
  const [PagesButtonsArray, setPagesButtonsArray] = useState([1]);

  const getPageButtonsArray = (pagesCount) => {
    let pagesArr = [1];
    const pagesCountCeil = Math.ceil(pagesCount);
    for (let index = 2; index <= pagesCountCeil; index++) {
      pagesArr.push(index);
    }
    return pagesArr;
  };

  React.useEffect(() => {
    // si se deselecciona uno item luego de chequear todos, se deschekea el checkAll
    const cantDataTotal = totalPaginate > 1 ? totalPaginate : data.length;
    if (itemsChecked.length !== cantDataTotal) {
      setCheckAll(false);
    } else if (data.length > 0) {
      setCheckAll(true);
    }
  }, [itemsChecked]);

  React.useEffect(() => {
    console.log(
      "### Page, PageSize, totalPaginate",
      Page,
      PageSize,
      totalPaginate
    );
    if (!!onPaginate && !!PageSize && !!Page) {
      onPaginate(Page, PageSize);
      setPagesButtonsArray(getPageButtonsArray(totalPaginate / PageSize));
    }
  }, [Page, PageSize]);

  React.useEffect(() => {
    console.log(
      "### Page, PageSize, totalPaginate",
      Page,
      PageSize,
      totalPaginate
    );
    if (!!onPaginate && !!PageSize && !!Page) {
      // onPaginate(Page, PageSize);
      setPagesButtonsArray(getPageButtonsArray(totalPaginate / PageSize));
    }
  }, [totalPaginate]);

  const onLocalSelectHandler = (rowNumber, isChecked) => {
    let count = selectedCount;
    isChecked ? count++ : (count -= 1);
    setSelectedCount(count);
    let listValues = [...checkListValues];
    listValues[rowNumber] = isChecked;
    setCheckListValues(listValues);
  };
  const activeRowNumberMenuHandler = (rowNumber) => {
    activeRowNumberMenu === rowNumber
      ? setActiveRowNumberMenu(null)
      : setActiveRowNumberMenu(rowNumber);
  };

  const onTableClick = (e) => {
    e.stopPropagation();
    activeRowNumberMenuHandler(null);
  };

  const onSelectAllClick = (e) => {
    let count = !checkAll ? checkListValues.length : 0;
    setCheckAll(!checkAll);
    setCheckListValues(checkListValues.map(() => !checkAll));
    setSelectedCount(count);
    if (data.length > 0) data.map((item, idx) => onCheckHandler(e, item));
  };

  const getButtonLabel = () => {
    let buttonLabel = "";
    if (customAddBtnLabel) return customAddBtnLabel;
    buttonLabel =
      subjectGender === "F" ? `Nueva ${subject}` : `Nuevo ${subject}`;
    return buttonLabel;
  };

  const getCounter = () => {
    if (itemsChecked) {
      return itemsChecked.length;
    }
    return selectedCount;
  };
  // Pagination
  const onChangePageSize = (e) => {
    const value = e.target.value;
    setPageSize(value);
    setPage(1);
  };

  return (
    <div onClick={onTableClick} className="flex flex-col gap-2 w-full">
      {tableTitle && (
        <>
          <div className="text-xl font-bold ">{tableTitle}</div>
          <div className="border-b border-b-gray-200 mb-3"></div>
        </>
      )}
      <div>
        <div className="flex flex-row gap-2 mb-4 items-center">
          <BuscarInput
            onChange={onChangeSearch}
            value={searchValue}
          ></BuscarInput>
          {!!onPaginate && (
            <div className="flex gap-2 items-center">
              <label className="font-semibold" htmlFor="pageSize">
                Mostrar
              </label>
              <select
                name="pageSize"
                id="pageSize"
                className="border rounded-md p-2"
                onChange={onChangePageSize}
              >
                <option value="10">10</option>
                <option value="50">50</option>
                <option value="100" selected>
                  100
                </option>
                <option value="500">500</option>
              </select>
            </div>
          )}
          {!!hasActionSelect && (
            <ReactSelect
              className="w-52 h-10 bg-blue-200 inline-grid rounded-md"
              {...(!actionSelectDefaulValue
                ? {
                    value: {
                      value: "",
                      label: "(" + getCounter() + ") seleccionados",
                    },
                  }
                : {})}
              options={selectOptions}
              onChange={(v) => {
                onSelectChange(v, itemsChecked);
              }}
            ></ReactSelect>
          )}
          {hasAddBtn && subject && (
            <>
              {!!addBtnClickHandler && (
                <button
                  onClick={addBtnClickHandler}
                  className={`h-12 ${addBtnColor} text-white text-sm px-4 rounded-md`}
                >
                  {getButtonLabel()}
                </button>
              )}
              {!addBtnClickHandler && (
                <button
                  disabled={true}
                  className={`h-12 bg-slate-400 text-white text-sm px-4 rounded-md`}
                >
                  {getButtonLabel()}
                </button>
              )}
            </>
          )}
        </div>
        {!!renderExtraFilters && renderExtraFilters()}

        <div className="flex justify-between border-b pb-3">
          {!!tableSecondTitle && customHeaders.length < 1 && (
            <>
              <div className="flex gap-2">
                {!!multiple && (
                  <input
                    className="h-5 w-5"
                    type="checkbox"
                    name="select-all"
                    id="select-all"
                    checked={checkAll}
                    onChange={(e) => onSelectAllClick(e)}
                  />
                )}
                <label className="font-bold text-sm">{tableSecondTitle}</label>
              </div>
              <div className="flex">
                <div className="font-bold text-sm"></div>
                <div className="th-actions w-16"></div>
              </div>
            </>
          )}
          {customHeaders.length > 0 && (
            <div className="flex gap-8 items-center w-full">
              {!!hasChecks && (
                <div className="flex gap-2 flex-1">
                  <input
                    className="h-5 w-5"
                    type="checkbox"
                    name="select-all"
                    id="select-all"
                    checked={checkAll}
                    onChange={(e) => onSelectAllClick(e)}
                  />
                  <span className="font-bold">{tableSecondTitle}</span>
                </div>
              )}
              <div className="flex flex-grow">
                {customHeaders.map((header, idx) => (
                  <div key={`header-${idx}`} className="font-bold flex-1">
                    <div className="">{header}</div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
        {/* List */}
        <div
          className={`flex flex-col ${
            fixedHeigth ? "h-[20rem] custom-scrollbar overflow-y-scroll" : ""
          }`}
        >
          {/* ListItem */}
          {!loading &&
            !!data &&
            data?.length >= 1 &&
            data.map((item, index) => (
              <TableRowCheck
                key={`row-${index}`}
                rowNumber={index}
                itemKey={itemKey}
                item={item}
                ItemComponent={ItemComponent}
                onLocalSelectHandler={onLocalSelectHandler}
                activeRowNumberMenu={activeRowNumberMenu}
                activeRowNumberMenuHandler={activeRowNumberMenuHandler}
                dropdownActions={
                  !!dropdownActions && dropdownActions(item.id, item)
                }
                checkValue={checkListValues[index]}
                isAddButtonRow={undefined}
                hasNoRightIcon={undefined}
                hasCheck={hasChecks}
                itemsChecked={itemsChecked}
                onCheckHandler={onCheckHandler}
                checkType={checkType}
              />
            ))}
          {!loading && data.length < 1 && (
            <div className="w-full flex justify-center items-center h-20">
              <div>No existen elementos para mostrar.</div>
            </div>
          )}
          {!!loading &&
            [1, 2, 3, 4].map((e, i) => (
              <SkeletonLoaderRow key={`sl-${i}`}></SkeletonLoaderRow>
            ))}
        </div>
        {/* agregar */}
        {subject && !noBottomButtom && (
          <div
            className="flex flex-row gap-3 items-center py-3 cursor pl-6"
            onClick={addBtnClickHandler}
          >
            <div className="flex items-center justify-center bg-sky-500 rounded-xl w-16 h-16">
              <div className="text-3xl text-white">+</div>
            </div>
            <div className="flex flex-col gap-1 flex-grow">
              <div className="font-bold">
                {subjectGender === "F"
                  ? `Agregar nueva ${subject}`
                  : `Agregar nuevo ${subject}`}
              </div>
              <div className="">
                {subjectGender === "F"
                  ? `Amplie su organización y habilite una nueva ${subject} disponible`
                  : `Amplie su organización y habilite un nuevo ${subject} disponible`}
              </div>
            </div>
          </div>
        )}
      </div>
      {/* Pagination */}
      {!!onPaginate && (
        <div className="flex items-center gap-2 justify-between border-t-2 border-dashed pt-2">
          <div>{`Mostrando ${Page} de ${PagesButtonsArray.length} de un total de ${totalPaginate} registros`}</div>
          <div className="flex justify-end gap-0 rounded-sm border">
            {PagesButtonsArray.map((b, bidx) => (
              <button
                key={`pagebtn-${bidx}`}
                onClick={() => setPage(b)}
                className={`${
                  b === Page
                    ? "bg-sky-500 text-white"
                    : "hover:bg-sky-500 hover:text-white"
                } py-1 px-2`}
              >
                {b}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default TableCustom;
