import { PeopleFill } from "react-bootstrap-icons";

function StatisticHorizontal({
  title = "No title",
  percentage = 0,
  amount = 0,
  titleDotColor = "red",
}) {
  return (
    <div className="flex flex-col gap-2 w-full">
      <div className="flex items-center justify-between gap-2 w-full">
        <div className="flex items-center gap-1 flex-1">
          <div className={`bg-${titleDotColor}-500 w-2 h-2 rounded-md`}></div>
          <div className="">{title.replace("_", " ").replace("_", " y ")}</div>
        </div>
        <div className="flex-1 flex justify-end">
          <div>{percentage}%</div>
        </div>
        <div className="flex items-center justify-end gap-1 flex-1">
          <PeopleFill color={"lightgrey"}></PeopleFill>
          <div>{amount}</div>
        </div>
      </div>
    </div>
  );
}

export default StatisticHorizontal;
