import { areaReducer } from "../modules/area/handlers/redux";
import { cargoReducer } from "../modules/cargo/handlers/redux";
import { departamentoReducer } from "../modules/departamento/handlers/redux";
import { estructuraReducer } from "../modules/estructura/handlers/redux";
import { sucursalReducer } from "../modules/sucursal/handlers/redux";

export const estructuraRootReducer = {
  cargo: cargoReducer,
  sucursal: sucursalReducer,
  area: areaReducer,
  departamento: departamentoReducer,
  estructura: estructuraReducer,
  // debug: (a, b) => { !!process.env.REACT_APP_DEBUG && console.log(a, b); return {} }
};
