import React from "react";
import { ArrowLeft, XLg } from "react-bootstrap-icons";
import LoadingSpinButton from "src/modules/common/components/LoadingSpinButton";

const LibreriaCompetenciaList = ({
  data = [],
  loading = false,
  onSelect,
  setShowLibrerias,
  setOpen,
}) => {
  const [clickedCompetencia, setClickedCompetencia] = React.useState({});
  const showCompetencia = (competencia) => () => {
    if (competencia.id === clickedCompetencia?.id) {
      setClickedCompetencia({});
      return;
    }
    setClickedCompetencia(competencia);
  };
  const copyCompetencia = () => {
    let clickedCompetenciaCopy = JSON.parse(JSON.stringify(clickedCompetencia));
    // Le ponemos como null a los ids para que tome como nueva competencia al momento de actualizar 
    clickedCompetenciaCopy.id = null;
    clickedCompetenciaCopy.indicadores.forEach((indicador) => {
      indicador.id = null;
    });
    //
    if (onSelect) {
      onSelect(clickedCompetenciaCopy);
    }
    setShowLibrerias(false);
  };
  //
  return (
    <div className="p-4">
      <div className="flex flex-row justify-between text-lg font-bold">
        <div className={`flex items-center gap-2`}>
          <button onClick={() => setShowLibrerias(false)}>
            <ArrowLeft size={24} />
          </button>
          <div className="max-w-md truncate">
            {"Seleccione una competencia"}
          </div>
        </div>
        <div onClick={() => setOpen(false)}>
          <XLg />
        </div>
      </div>
      {/*  */}
      <h3 className="font-semibold text-sm">Competencias disponibles</h3>
      <p>Puede seleccionar una competencia para conocer mas detalles.</p>
      <div className="flex flex-wrap gap-2 mt-2 max-h-80 custom-scrollbar px-3">
        {!loading &&
          data.length > 0 &&
          data.map((competencia, fidx) => (
            <div
              key={`competencia-item-${fidx}`}
              className="flex flex-col items-start"
            >
              <button
                onClick={showCompetencia(competencia)}
                className={`${
                  clickedCompetencia?.id === competencia.id
                    ? "border border-sky-300"
                    : ""
                } bg-slate-100 rounded-xl px-3 py-1 text-sm`}
              >
                {competencia.nombre}
              </button>
              {clickedCompetencia?.id === competencia.id && (
                <div className="text-sm border rounded-md p-4 mt-2">
                  <h3 className="font-semibold mb-2">Descripción</h3>
                  <div className="ml-2">{competencia.descripcion}</div>
                  <h3 className="font-semibold my-2">Indicadores</h3>
                  <ul className="ml-8 flex flex-col gap-1 list-disc">
                    {competencia.indicadores.map((sf, sfix) => (
                      <li key={`sf-li-${sfix}`}>{sf.descripcion}</li>
                    ))}
                  </ul>
                  <div className="w-full flex justify-end">
                    <button
                      onClick={copyCompetencia}
                      className="bg-sky-500 text-white text-sm rounded-md px-2 py-1"
                    >
                      Copiar competencia
                    </button>
                  </div>
                </div>
              )}
            </div>
          ))}
        {!loading && data.length < 0 && (
          <div className="w-full flex justify-center py-4">
            No existen competenciaes para mostrar
          </div>
        )}
        {!!loading && (
          <div className="w-full flex justify-center py-4">
            <LoadingSpinButton />
          </div>
        )}
      </div>
    </div>
  );
};

export default LibreriaCompetenciaList;
