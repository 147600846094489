import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import { planCarreraActions } from "./redux";
import { getToken } from "src/utils/helpers";
import { planCarreraApi } from "./api";
import { SuccessToast } from "src/modules/common/components/SuccessToast";
import { ErrorToast } from "src/modules/common/components/ErrorToast";

function* planesCarreraSaga() {
  const token = getToken();
  const planes_carrera_filter = yield select(
    (state) => state.plan_carrera.planes_carrera_filter
  );
  //
  try {
    const data = yield call(planCarreraApi(token).list, planes_carrera_filter);
    // !!process.env.REACT_APP_DEBUG && console.log("evaluaciones saga", data);
    yield put(planCarreraActions.setPlanesCarrera(data.results));
    yield put(planCarreraActions.setPlanesCarreraCount(data.count));
    yield put(planCarreraActions.setPlanesCarreraComplete());
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
    yield put(planCarreraActions.setPlanesCarreraComplete());
  }
}
function* planCarreraCreateSaga({ payload }) {
  const token = getToken();
  //
  try {
    yield call(planCarreraApi(token).create, payload);
    yield put(planCarreraActions.getPlanesCarrera());
    yield put(planCarreraActions.createPlanCarreraComplete());
    SuccessToast({ message: "Plan de carrera creado" });
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
    yield put(planCarreraActions.createPlanCarreraComplete());
    ErrorToast({ message: "Ocurrió un error" });
  }
}
function* planCarreraUpdateSaga({ payload }) {
  const token = getToken();
  //
  try {
    yield call(planCarreraApi(token).update, payload);
    yield put(planCarreraActions.getPlanesCarrera());
    yield put(planCarreraActions.createPlanCarreraComplete());
    SuccessToast({ message: "Plan de carrera actualizado" });
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
    yield put(planCarreraActions.createPlanCarreraComplete());
    ErrorToast({ message: "Ocurrió un error" });
  }
}
function* planCarreraDetailSaga({ payload }) {
  const token = getToken();
  //
  try {
    const data = yield call(planCarreraApi(token).detail, payload);
    console.log("planCarreraDetailSaga data", data);
    yield put(planCarreraActions.setPlanCarrera(data));
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
  }
}
function* planCarreraDeleteSaga({ payload }) {
  const token = getToken();
  //
  try {
    yield call(planCarreraApi(token).delete, payload);
    yield put(planCarreraActions.getPlanesCarrera());
    SuccessToast({ message: "Plan de carrera eliminado" });
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
    ErrorToast({ message: "Ocurrió un error" });
  }
}
function* planCarreraBulkDeleteSaga({ payload }) {
  const token = getToken();
  const planes_carrera_checked = yield select(
    (state) => state.plan_carrera.planes_carrera_checked
  );
  const planesIds = planes_carrera_checked.map((plan) => plan.id);
  //
  try {
    yield call(planCarreraApi(token).bulkDelete, planesIds);
    yield put(planCarreraActions.resetCheckPlanesCarrera());
    yield put(planCarreraActions.getPlanesCarrera());
    SuccessToast({ message: "Familias eliminadas con éxito" });
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
    ErrorToast({ message: "Ocurrió un error" });
  }
}
// CATEGORIA
function* PlanCarreraAddCategoriaSaga({ payload }) {
  const token = getToken();
  const { id } = payload;
  //
  try {
    // payload = { id, categoria }
    yield call(planCarreraApi(token).addCategoria, payload);
    yield put(planCarreraActions.getPlanCarrera(id));
    SuccessToast({ message: "Categoría agregada" });
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
    ErrorToast({ message: "Ocurrió un error" });
  }
}
function* PlanCarreraDeleteCategoriaSaga({ payload }) {
  const token = getToken();
  const { id, categoriaId } = payload;
  //
  try {
    yield call(planCarreraApi(token).deleteCategoria, payload);
    yield put(planCarreraActions.getPlanCarrera(id));
    SuccessToast({ message: "Categoría eliminada" });
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
    ErrorToast({ message: "Ocurrió un error" });
  }
}
function* PlanCarreraUpdateCategoriaSaga({ payload }) {
  const token = getToken();
  const { id, categoriaId, updatedCategoria } = payload;
  //
  try {
    yield call(planCarreraApi(token).updateCategoria, payload);
    yield put(planCarreraActions.getPlanCarrera(id));
    SuccessToast({ message: "Categoría actualizada" });
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
    ErrorToast({ message: "Ocurrió un error" });
  }
}
function* PlanCarreraUpdateCategoriaCargosSaga({ payload }) {
  const token = getToken();
  const { id, categoriaId, cargosIds } = payload;
  //
  try {
    yield call(planCarreraApi(token).updateCategoriaCargos, payload);
    yield put(planCarreraActions.getPlanCarrera(id));
    SuccessToast({ message: "Cargos actualizados" });
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
    ErrorToast({ message: "Ocurrió un error" });
  }
}
// REQUISITOS
function* getCargosSaga() {
  const token = getToken();
  const cargos_filter = yield select(
    (state) => state.plan_carrera.cargos_filter
  );
  //
  try {
    const data = yield call(planCarreraApi(token).getCargos, cargos_filter);
    yield put(planCarreraActions.setCargos(data.results));
    yield put(planCarreraActions.setCargosCount(data.count));
    yield put(planCarreraActions.getCargosComplete());
  } catch (error) {
    yield put(planCarreraActions.getCargosComplete());
    ErrorToast({ message: "Ocurió un error" });
    !!process.env.REACT_APP_DEBUG && console.log({ error });
  }
}
function* getCargoRequisitos({ payload }) {
  const token = getToken();
  //
  try {
    const data = yield call(planCarreraApi(token).getCargoRequisitos, payload);
    yield put(planCarreraActions.setCargo(data));
    yield put(planCarreraActions.getCargoComplete());
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
    yield put(planCarreraActions.getCargoComplete());
    ErrorToast({ message: "Ocurió un error" });
  }
}
function* borrarRequisitosCargo({ payload }) {
  const token = getToken();
  //
  try {
    const data = yield call(
      planCarreraApi(token).borrarRequisitosCargo,
      payload
    );
    yield put(planCarreraActions.getCargos());
    //
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
    yield put(planCarreraActions.getCargoComplete());
    ErrorToast({ message: "Ocurió un error" });
  }
}
function* bulkBorrarRequisitosCargoSaga({ payload }) {
  const token = getToken();
  //
  try {
    const data = yield call(
      planCarreraApi(token).bulkBorrarRequisitosCargo,
      payload
    );
    yield put(planCarreraActions.resetCargosChecked());
    yield put(planCarreraActions.getCargos());
    //
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
    ErrorToast({ message: "Ocurió un error" });
  }
}
function* updateRequisitosCargoSaga({ payload }) {
  const token = getToken();
  // const  { cargoId, cargo } = payload;
  //
  try {
    const data = yield call(
      planCarreraApi(token).updateRequisitosCargo,
      payload
    );
    yield put(planCarreraActions.getCargoComplete());
    SuccessToast({ message: "Requisitos actualizados" });
    yield put(planCarreraActions.getCargos());
    //
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
    yield put(planCarreraActions.getCargoComplete());
    ErrorToast({ message: "Ocurió un error" });
  }
}
export function* planCarreraSagas() {
  yield takeEvery(planCarreraActions.getPlanesCarrera.type, planesCarreraSaga);
  yield takeLatest(
    planCarreraActions.setPlanesCarreraFilter.type,
    planesCarreraSaga
  );
  yield takeEvery(
    planCarreraActions.createPlanCarrera.type,
    planCarreraCreateSaga
  );
  yield takeEvery(
    planCarreraActions.updatePlanCarrera.type,
    planCarreraUpdateSaga
  );
  yield takeEvery(
    planCarreraActions.getPlanCarrera.type,
    planCarreraDetailSaga
  );
  yield takeEvery(
    planCarreraActions.deletePlanCarrera.type,
    planCarreraDeleteSaga
  );
  yield takeEvery(
    planCarreraActions.bulkDeletePlanesCarrera.type,
    planCarreraBulkDeleteSaga
  );
  // CATEGORIA
  yield takeEvery(
    planCarreraActions.addCategoria.type,
    PlanCarreraAddCategoriaSaga
  );
  yield takeEvery(
    planCarreraActions.deleteCategoria.type,
    PlanCarreraDeleteCategoriaSaga
  );
  yield takeEvery(
    planCarreraActions.updateCategoria.type,
    PlanCarreraUpdateCategoriaSaga
  );
  yield takeEvery(
    planCarreraActions.updateCategoriaCargos.type,
    PlanCarreraUpdateCategoriaCargosSaga
  );
  // REQUISITOS
  yield takeEvery(planCarreraActions.getCargos.type, getCargosSaga);
  yield takeLatest(planCarreraActions.setCargosFilter.type, getCargosSaga);
  yield takeEvery(planCarreraActions.getCargo.type, getCargoRequisitos);
  yield takeEvery(
    planCarreraActions.borrarRequisitosCargo.type,
    borrarRequisitosCargo
  );
  yield takeEvery(
    planCarreraActions.borrarRequisitosCargos.type,
    bulkBorrarRequisitosCargoSaga
  );
  yield takeEvery(
    planCarreraActions.updateRequisitosCargo.type,
    updateRequisitosCargoSaga
  );
}
