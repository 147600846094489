import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { Search } from "react-bootstrap-icons";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ReactSelect from "react-select";
import DataTable from "src/modules/common/components/DataTable";
import { commonActions } from "src/modules/common/handler/redux";
import RemuneracionLayout from "../components/RemuneracionLayout";
import { remuneracionActions } from "../handlers/redux";
import { RutasRemuneracion, remuneracionRoutes } from "../handlers/routes";

const TableActions = ({
  filterState,
  onChangePageSize,
  checkedCount = 0,
  yearsOptions = [],
  actionOptions = [],
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const onSearch = (e) => {
    let searchText = e.target.value;
    dispatch(
      remuneracionActions.setEscalasSalarialesFilter({
        ...filterState,
        nombre: searchText,
      })
    );
  };
  const onChangeYearFilter = (e) => {
    let value = e.target.value;
    dispatch(
      remuneracionActions.setEscalasSalarialesFilter({
        ...filterState,
        year: value,
      })
    );
  };
  let cleanedYearsOptions = yearsOptions.filter((element, index) => {
    return yearsOptions.indexOf(element) === index && !!element;
  });
  const goToConfiguracion = () => {
    // dispatch(remuneracionActions.setEscalaSalarial({}));
    // dispatch(remuneracionActions.setValoracionesCargos([]));
    // dispatch(remuneracionActions.setEscalaConfiguracion({}));
    dispatch(remuneracionActions.resetEscalaData());
    navigate(RutasRemuneracion.ESCALA_CONFIGURACION());
  };
  const onAction = (v) => {
    if (v.value === "delete") {
      const deseaEliminar = window.confirm(
        "Está seguro de eliminar las escalas seleccionadas?"
      );
      if (!!deseaEliminar) {
        v.action();
      }
      return;
    }
  };
  //
  return (
    <div className="flex items-center justify-between gap-4">
      <div className="flex items-center justify-between bg-slate-100 rounded-md p-2 flex-1">
        <input
          className="outline-none bg-slate-100 px-1"
          type="text"
          value={filterState.nombre}
          onChange={onSearch}
        />
        <Search />
      </div>
      {/* Mostrar */}
      <div className="col-span-1">
        <div className="flex justify-center items-center gap-2 w-full">
          <span className="font-bold">Mostrar</span>
          <select
            name="mostrar"
            id="mostrar"
            className="p-2 rounded-md"
            onChange={onChangePageSize}
          >
            <option value="10">10</option>
            <option value="50">50</option>
            <option value="100" selected>
              100
            </option>
          </select>
        </div>
      </div>
      {/*  */}
      <div className="borderx">
        <select
          className="border rounded-md p-2.5 bg-white text-sm"
          name="years"
          id="years"
          onChange={onChangeYearFilter}
        >
          <option value="">Año: Todos</option>
          {cleanedYearsOptions.map((year, yidx) => (
            <option key={`opt-${year}`} value={year}>
              {year}
            </option>
          ))}
        </select>
      </div>
      <div className="text-sm">
        {/* <div>{`(${checkedCount}) seleccionados`}</div> */}
        <ReactSelect
          className="w-52 h-10 bg-blue-200 inline-grid"
          value={{
            value: "",
            label: `(${checkedCount}) seleccionados`,
          }}
          options={actionOptions}
          onChange={onAction}
        ></ReactSelect>
      </div>
      <div>
        <button
          onClick={goToConfiguracion}
          className="bg-sky-500 text-white rounded-md p-3 text-sm"
        >
          Crear nueva escala
        </button>
      </div>
    </div>
  );
};

function RemuneracionEscalaSalarial() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const escalas_salariales = useSelector(
    (state) => state.remuneracion.escalas_salariales
  );
  const { results: escalas = [], count = 0 } = escalas_salariales;
  const escalas_salariales_filter = useSelector(
    (state) => state.remuneracion.escalas_salariales_filter
  );
  const escalas_salariales_loading = useSelector(
    (state) => state.remuneracion.escalas_salariales_loading
  );
  const setOrdering =
    (field) =>
    (order = "") => {
      let { ordering = "" } = escalas_salariales_filter;
      let orderingArray = ordering.split(",") || [];
      let emptyIndex = orderingArray.indexOf("");
      if (emptyIndex) {
        orderingArray.splice(emptyIndex, 1);
      }
      let fieldIndex = orderingArray.indexOf(field);
      fieldIndex =
        fieldIndex === -1 ? orderingArray.indexOf(`-${field}`) : fieldIndex;
      // si ya esta el campo, vemos si se modifica o elimina
      if (fieldIndex > 0) {
        orderingArray.splice(fieldIndex, 1);
        if (order) {
          field = order === "desc" ? `-${field}` : field;
          orderingArray[fieldIndex] = field;
        }
      } else {
        // si no está, lo agregamos
        field = order === "desc" ? `-${field}` : field;
        orderingArray.push(field);
        !!process.env.REACT_APP_DEBUG &&
          console.info("orderingArray 2", orderingArray);
      }
      ordering = orderingArray.join(",");
      //
      dispatch(
        remuneracionActions.setEscalasSalarialesFilter({
          ...escalas_salariales_filter,
          ordering: ordering,
        })
      );
    };
  const getEstadoColor = (estado) => {
    const colorClasses = {
      Vigente: "bg-green-500 text-white",
      Borrador: "bg-yellow-500 text-white",
      "No vigente": "bg-slate-100 text-slate-600",
    };
    return colorClasses[estado] ?? "";
  };
  const datatableConfig = {
    headers: [
      {
        title: "Lista de escalas",
        contentClass: "font-bold",
        onOrder: setOrdering("nombre"),
        description: "fecha_creacion",
        descriptionLabel: "Creada:",
        descriptionRenderer: (item) => (
          <>
            Creada:
            {item.fecha_creacion
              ? dayjs(item.fecha_apertura.slice(0, 10)).format("DD/MM/YYYY")
              : "Sin fecha"}
          </>
        ),
        accessor: "nombre",
      },
      {
        title: "Estado",
        accessor: "estado",
        onOrder: setOrdering("estado"),
        contentRenderer: (item) => (
          <div className="">
            <span
              className={`p-2 rounded-md text-sm ${getEstadoColor(
                item.estado
              )}`}
            >
              {item.estado}
            </span>
          </div>
        ),
      },
    ],
    rowActions: [
      {
        label: "Editar",
        action: (item) => {
          navigate(RutasRemuneracion.ESCALA_CONFIGURACION(item.id));
        },
      },
      {
        label: "Duplicar",
        action: (item) => {
          dispatch(remuneracionActions.duplicarEscalaSalarial(item.id));
        },
      },
    ],
  };
  const onPaginate = (page, pageSize) => {
    dispatch(
      remuneracionActions.setEscalasSalarialesFilter({
        ...escalas_salariales_filter,
        pagination: {
          page: page,
          pageSize: pageSize,
        },
      })
    );
  };
  const [PageSize, setPageSize] = useState(100);
  const onChangePageSize = (e) => {
    const value = e.target.value;
    setPageSize(value);
  };
  const escalas_salariales_checked = useSelector(
    (state) => state.remuneracion.escalas_salariales_checked
  );
  const onItemCheck = (item) => (e) => {
    const checked = e.target.checked;
    dispatch(
      remuneracionActions.checkEscalaSalarial({
        escala: item,
        checked: checked,
      })
    );
  };
  const goToEscalaDetalle = (escala) => {
    if (escala.estado === "Borrador") return;
    navigate(RutasRemuneracion.ESCALA_DETALLE(escala.id));
  };
  const onCheckAll = (e) => {
    const checked = e.target.checked;
    let checkedItems = [];
    if (checked) {
      checkedItems = escalas;
    }
    dispatch(remuneracionActions.setEscalasSalarialesChecked(checkedItems));
  };
  const onBulkDelete = () => {
    const escalasIds = escalas_salariales_checked.map((es, esi) => es.id);
    dispatch(remuneracionActions.setEscalasIdsDelete(escalasIds));
  };
  //
  useEffect(() => {
    dispatch(commonActions.showSidebar(true));
    dispatch(remuneracionActions.getEscalasSalariales());
    dispatch(remuneracionActions.resetEscalasSalarialesChecked());
  }, []);
  //
  return (
    <RemuneracionLayout>
      <div className="md:w-11/12">
        <div className="border-b pb-2 mt-4 flex justify-between items-center mb-4">
          <h2 className="font-semibold">Escala Salarial</h2>
        </div>
        <TableActions
          onChangePageSize={onChangePageSize}
          filterState={escalas_salariales_filter}
          checkedCount={escalas_salariales_checked.length}
          yearsOptions={escalas.map((escala, eidx) =>
            escala.fecha_apertura?.slice(0, 4)
          )}
          actionOptions={[
            {
              value: "delete",
              label: "Eliminar",
              action: onBulkDelete,
            },
          ]}
        />
        <DataTable
          containerClass={"my-8"}
          data={escalas}
          loading={false}
          config={datatableConfig}
          // pagination
          totalRecords={count}
          onPaginate={onPaginate}
          outerPageSize={PageSize}
          // check config
          checkable={true}
          onCheckAll={onCheckAll}
          checkedItems={escalas_salariales_checked}
          onItemCheck={onItemCheck}
          onRowClick={goToEscalaDetalle}
        />
      </div>
    </RemuneracionLayout>
  );
}

export default RemuneracionEscalaSalarial;
