import React from "react";
import backButton from "src/modules/common/components/images/back-arrow.png";

export function ContenidoLayout({
  children,
  title,
  onClickBack,
  actionButton = {},
  BarComponent,
}) {
  return (
    <div>
      <div className="flex justify-between items-center bg-white py-2 px-8 -mx-8 border-b">
        <div className="flex items-center flex-1">
          <div
            onClick={() => {
              if (onClickBack) {
                onClickBack();
              }
            }}
            className={
              "h-full  flex items-center pr-2 -mr-2 z-10 cursor-pointer"
            }
          >
            <img className="back" src={backButton} alt="Regresar" />
          </div>
          <div className="font-semibold px-4 text-2xl">{title}</div>
          {!!BarComponent && <BarComponent />}
        </div>
      </div>
      {children}
    </div>
  );
}
