import React, { useRef } from "react";
import { CaretDownFill, ChevronBarDown, Folder2 } from "react-bootstrap-icons";

function UploadFileInput({
  label = "Importar datos",
  className,
  onSubmit = null,
  showButton = true,
}) {
  const [showInput, setShowInput] = React.useState(!showButton);
  const [name, setName] = React.useState("");
  const [selectedFile, setSelectedFile] = React.useState(null);
  const fileInput = useRef(null);
  const scrollRef = useRef(null);

  const scrollToBottom = () => {
    scrollRef.current?.scrollIntoView({ behavior: "instant" });
  };

  const toggleShow = () => {
    setShowInput(!showInput);
    scrollToBottom();
  };

  const onChangeCaptureHandler = (e) => {
    let file = e.target.files[0];
    setSelectedFile(file);
    setName(file.name);
  };

  const uploadFile = () => {
    onSubmit(selectedFile);
  };

  return (
    <div className={className}>
      <div className=" flex flex-col mt-4">
        <label className="font-bold" htmlFor="file">
          Archivo<span className="text-red-600">*</span>
        </label>
        <div className="flex gap-2 ">
          <input
            className="border-2 rounded-md p-2 flex-1"
            type="text"
            name="filemask"
            id="filemask"
            value={name}
            disabled
          />
          <button
            onClick={(e) => fileInput.current && fileInput.current.click()}
            className="px-2 text-xs flex gap-2 items-center border border-gray-500 rounded-md"
          >
            <Folder2 size={18}></Folder2>
            Seleccionar archivo
          </button>
        </div>

        <input
          className="hidden"
          type="file"
          name="file"
          ref={fileInput}
          onChangeCapture={onChangeCaptureHandler}
        />
        <small className="mt-1 italic text-xs text-slate-500">
          Peso máximo 2 MB. Formatos permitidos: pdf, doc, docx.
        </small>
        <div className=" flex gap-2 my-4">
          {/* SUBMIT */}
          <button
            onClick={uploadFile}
            className="border-1 rounded-md py-1 px-3 bg-black text-white text-sm"
          >
            Subir archivo
          </button>
        </div>

        <div ref={scrollRef}></div>
      </div>
    </div>
  );
}

export default UploadFileInput;
