import { createSlice } from "@reduxjs/toolkit";

const aprendizajeSlice = createSlice({
  name: "aprendizaje",
  initialState: {
    formaciones: [],
    formaciones_loading: false,
    formaciones_count: 0,
    formaciones_filter: {},
    //
    formacion: {},
    formacion_loading: false,
    //
    tutoriales: [],
    tutoriales_loading: false,
    tutoriales_count: 0,
    tutoriales_filter: {},
    //
    tutorial: {},
    tutorial_loading: false,
  },
  reducers: {
    getFormaciones: (state, { payload = {} }) => {
      state.formaciones_loading = true;
    },
    setFormaciones: (state, { payload = {} }) => {
      state.formaciones = payload;
      state.formaciones_loading = false;
    },
    setFormacionesCount: (state, { payload = {} }) => {
      state.formaciones_count = payload;
    },
    setFormacionesFilter: (state, { payload = {} }) => {
      state.formaciones_filter = payload;
    },
    //
    getFormacion: (state, { payload = {} }) => {
      state.formacion_loading = true;
    },
    setFormacion: (state, { payload = {} }) => {
      state.formacion = payload;
      state.formacion_loading = false;
    },
    //
    getTutoriales: (state, { payload = {} }) => {
      state.tutoriales_loading = true;
    },
    setTutoriales: (state, { payload = {} }) => {
      state.tutoriales = payload;
      state.tutoriales_loading = false;
    },
    setTutorialesCount: (state, { payload = {} }) => {
      state.tutoriales_count = payload;
    },
    setTutorialesFilter: (state, { payload = {} }) => {
      state.tutoriales_filter = payload;
    },
    //
    getTutorial: (state, { payload = {} }) => {
      state.tutorial_loading = true;
    },
    setTutorial: (state, { payload = {} }) => {
      state.tutorial = payload;
      state.tutorial_loading = false;
    },
  },
});

export const aprendizajeActions = aprendizajeSlice.actions;
export const aprendizajeReducer = aprendizajeSlice.reducer;
