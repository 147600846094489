import axios from "axios";
const URL_BASE =
  process.env.REACT_APP_API_URL || "http://localhost:8000/api/admin";

export const colaboradorDesempenoApi = (token) => {
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };

  return {
    // Desempeño
    getDesempeno: async ({ id }) => {
      try {
        const response = await axios.get(
          `${URL_BASE}/empleado/${id}/desempeno/`,
          {
            headers,
          }
        );
        return response.data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG &&
          console.log("getDesempeno error", error);
        throw error;
      }
    },
    getEvaluaciones: async ({ id, colaborador_evaluaciones_filter }) => {
      const {
        nombre = "",
        ordering = "",
        anho_finalizacion,
      } = colaborador_evaluaciones_filter;
      let searchOrdering = `?search=${nombre}`;
      if (ordering) {
        searchOrdering += `&ordering=${ordering}`;
      }
      if (anho_finalizacion) {
        searchOrdering += `&anho_finalizacion=${anho_finalizacion}`;
      }
      //
      try {
        const colaborador = await axios.get(
          `${URL_BASE}/empleado/${id}/evaluaciones/${searchOrdering}`,
          {
            headers,
          }
        );
        return colaborador.data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG &&
          console.log("getEvaluaciones api error", error);
        throw error;
      }
    },
  };
};
