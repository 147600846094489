import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import FileInput from "src/modules/common/components/FileInput";
import DepartamentoModal from "src/modules/estructura/modules/departamento/components/DepartamentoModal";
import { getToken } from "src/utils/helpers";
import { DownloadPlantilla } from "../../estructura/handlers/utils";
import { DepartamentoTable } from "../components/DepartamentoTable";
import { DepartamentoToolbar } from "../components/DepartamentoToolbar";
import { departamentoActions } from "../handlers/redux";
import TableCustom from "src/modules/common/components/TableCustom";
import SkeletonLoaderRow from "src/modules/common/components/SkeletonLoaderRow";
import { DepartamentoTableAccionMenu } from "../components/DepartamentoTableAccionMenu";

const DepartamentoListItem = ({ item, idx }) => {
  return (
    <div
      key={idx}
      className="hover:bg-sky-100 flex flex-row gap-2 py-4 align-middle w-full"
    >
      <div className="p-1 flex flex-grow flex-1 flex-col justify-center">
        <div className="font-bold">{item.nombre}</div>
        <div className="text-sm">{item.descripcion}</div>
      </div>
      <div className="p-1 text-center w-48x flex flex-row flex-1 justify-centerx items-center">
        {item.cantidad_colaboradores}
      </div>
      <div className="p-1 w-12 flex flex-row items-center">
        <DepartamentoTableAccionMenu item={item} />
      </div>
    </div>
  );
};

function DepartamentoPage() {
  const dispatch = useDispatch();
  const token = getToken();
  const departamentos = useSelector(
    (state) => state.departamento.departamentos
  );
  const departamentos_checked = useSelector(
    (state) => state.departamento.departamentos_checked
  );
  const departamentos_loading = useSelector(
    (state) => state.departamento.departamentos_loading
  );
  const departamentos_filter = useSelector(
    (state) => state.departamento.departamentos_filter
  );
  const { nombre = "" } = departamentos_filter;
  const { results = [], count: departamentosCount = 0 } = departamentos;

  const uploadFile = (file) => {
    dispatch(departamentoActions.setFileToUpload(file));
  };

  const onPlantilla = () => {
    DownloadPlantilla({ token });
  };
  ///
  const showDepartamentoModal = () => {
    dispatch(departamentoActions.departamentoSet({}));
    dispatch(departamentoActions.setDepartamentoCreateModal({ open: true }));
  };

  const onCheckDepartamentos = (event, item) => {
    dispatch(
      departamentoActions.checkDepartamento({
        departamento: item,
        checked: event.target.checked,
      })
    );
  };

  const onSelectChange = (v) => {
    if (v.value === "cancel") {
      dispatch(departamentoActions.resetCheckDepartamentos());
    } else if (v.value === "eliminar") {
      let deseaEliminar = window.confirm(
        "Desea eliminar las departamentos seleccionadas?"
      );
      if (deseaEliminar)
        dispatch(
          departamentoActions.setDepartamentosDelete(departamentos_checked)
        );
    } else {
      // @ts-ignore
      window.alert(JSON.stringify(v));
    }
  };

  const onChangeSearch = (event) => {
    dispatch(
      departamentoActions.setDepartamentosFilter({
        ...departamentos_filter,
        nombre: event.target.value,
      })
    );
  };

  const onPaginate = (page, pageSize) => {
    dispatch(
      departamentoActions.setDepartamentosFilter({
        ...departamentos_filter,
        pagination: {
          page: page,
          pageSize: pageSize,
        },
      })
    );
  };
  ///
  useEffect(() => {
    dispatch(departamentoActions.departamentos());
  }, []);
  //
  return (
    <div className="md:w-11/12">
      <div className="flex flex-col gap-2">
        <div className="text-xl font-bold ">Departamentos</div>
        <div className="border-b border-b-gray-200 mb-3"></div>
        {!departamentos_loading && (
          <TableCustom
            fixedHeigth={true}
            data={results || []}
            customHeaders={["Colaboradores"]}
            tableTitle={""}
            tableSecondTitle={"Lista de departamentos creados"}
            subject={"departamento"}
            subjectGender={"M"}
            ItemComponent={DepartamentoListItem}
            addBtnClickHandler={showDepartamentoModal}
            itemsChecked={departamentos_checked}
            onCheckHandler={onCheckDepartamentos}
            onSelectChange={onSelectChange}
            selectOptions={[
              { value: "eliminar", label: "Eliminar" },
              { value: "cancel", label: "Cancelar" },
            ]}
            searchValue={nombre}
            onChangeSearch={onChangeSearch}
            noBottomButtom={true}
            totalPaginate={departamentosCount}
            onPaginate={onPaginate}
          />
        )}
        {!!departamentos_loading && (
          <div className="mt-28">
            {[1, 2, 3].map((r, i) => (
              <SkeletonLoaderRow key={i}></SkeletonLoaderRow>
            ))}
          </div>
        )}
        {/* Toolbar */}
        {/* <DepartamentoToolbar /> */}
        {/* Table */}
        {/* <DepartamentoTable></DepartamentoTable> */}
      </div>

      <FileInput
        className={"mt-3"}
        onSubmit={uploadFile}
        onPlantilla={onPlantilla}
      />
      <DepartamentoModal></DepartamentoModal>
    </div>
  );
}

export default DepartamentoPage;
