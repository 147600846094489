import { createSlice } from "@reduxjs/toolkit";

const areaSlice = createSlice({
  name: "Area",
  initialState: {
    area_create_loading: false,
    area_update_loading: false,
    area_delete_loading: false,
    area: {},
    areas: { result: [] },
    areas_checked: [],
    areas_filter: {},
    areas_delete: [],
    areas_loading: false,
    area_create_modal: { open: false },
    //
    fileToUpload: null,
    datosVerificados: {},
    nuevosChecked: [],
    duplicadosChecked: [],
    datosImportar: [],
    importar_loading: false,
  },
  reducers: {
    setFileToUpload: (state, { payload = {} }) => {
      !!process.env.REACT_APP_DEBUG && console.info("setFileToUpload redux");
      state.fileToUpload = payload;
    },
    setDatosVerificados: (state, { payload = {} }) => {
      !!process.env.REACT_APP_DEBUG &&
        console.info("setDatosVerificados redux");
      state.datosVerificados = payload;
    },
    setDatosImportar: (state, { payload = {} }) => {
      !!process.env.REACT_APP_DEBUG && console.info("setDatosImportar redux");
      state.datosImportar = payload;
    },
    checkNuevo: (state, { payload: { item, checked = true } }) => {
      !!process.env.REACT_APP_DEBUG &&
        console.info("checkNuevo redux", item, checked);
      let temp = state.nuevosChecked.filter((nc) => nc.id !== item.id);
      !!process.env.REACT_APP_DEBUG && console.info("temp", temp);
      state.nuevosChecked = temp;
      if (!!checked) {
        state.nuevosChecked.push(item);
      }
      // state.kpiColaboradores_loading = false;
    },
    resetCheckNuevos: (state) => {
      state.nuevosChecked = [];
    },
    checkDuplicado: (state, { payload: { item, checked = true } }) => {
      !!process.env.REACT_APP_DEBUG &&
        console.info("checkDuplicado redux", item, checked);
      let temp = state.duplicadosChecked.filter((dc) => dc.id !== item.id);
      state.duplicadosChecked = temp;
      if (!!checked) {
        state.duplicadosChecked.push(item);
      }
      // state.kpiColaboradores_loading = false;
    },
    resetCheckDuplicados: (state) => {
      state.duplicadosChecked = [];
    },
    importarDatosComplete: (state, { payload = {} }) => {
      state.nuevosChecked = [];
      state.duplicadosChecked = [];
      state.datosVerificados = [];
      state.datosImportar = [];
      state.importar_loading = false;
    },
    //
    areaSet: (state, { payload = {} }) => {
      state.area = payload;
    },
    setAreas: (state, { payload }) => {
      state.areas = payload.areas;
      state.areas_loading = false;
    },
    setAreasFilter: (state, { payload = {} }) => {
      state.areas_filter = payload;
    },
    setAreasDelete: (state, { payload }) => {
      state.areas_delete = payload;
    },
    resetAreasDelete: (state, { payload }) => {
      state.areas_delete = [];
    },
    resetCheckAreas: (state) => {
      state.areas_checked = [];
      state.areas_loading = false;
    },
    checkArea: (state, { payload: { area, checked = true } }) => {
      state.areas_checked = state.areas_checked.filter((id) => id != area.id);
      if (!!checked) {
        state.areas_checked.push(area.id);
      }
      state.areas_loading = false;
    },
    // create
    setAreaCreateModal: (state, { payload = {} }) => {
      !!process.env.REACT_APP_DEBUG && console.log({ payload });
      state.area_create_modal = payload;
    },
    areaCreate: (state, { payload = {} }) => {
      state.area_create_loading = true;
    },
    areaCreateSuccess: (state) => {
      state.area_create_loading = false;
    },
    // update
    areaUpdate: (state, { payload = {} }) => {
      state.area_update_loading = true;
    },
    areaUpdateSuccess: (state) => {
      state.area_update_loading = false;
    },
    // delete
    areaDelete: (state, { payload = {} }) => {
      state.area_delete_loading = true;
    },
    areaDeleteSuccess: (state) => {
      state.area_delete_loading = false;
    },
    // list
    areas: (state, { payload }) => {
      state.areas_loading = true;
    },
  },
});

export const areaActions = areaSlice.actions;
export const areaReducer = areaSlice.reducer;
