import React from "react";
import { Route, Routes } from "react-router-dom";
import ConfiguracionFormularioForm from "../pages/ConfiguracionFormularioForm";
import EvaluacionConfiguracion from "../pages/EvaluacionConfiguracion";
import EvaluacionDetallePage from "../pages/EvaluacionDetallePage";
import EvaluacionInformePage from "../pages/EvaluacionInformePage";
import EvaluacionPage from "../pages/EvaluacionPage";
import EvaluadoInformacionPage from "../pages/EvaluadoInformacionPage";
import FormularioCalibracionPage from "../pages/FormularioCalibracionPage";
import FormularioComentarioPage from "../pages/FormularioComentarioPage";
import FormularioDevolucionPage from "../pages/FormularioDevolucionPage";
import FormularioEvaluacionPage from "../pages/FormularioEvaluacionPage";
import FormularioFinalizadoPage from "../pages/FormularioFinalizadoPage";
import FormularioPage from "../pages/FormularioPage";
import EvaluacionCambiosNomina from "../pages/EvaluacionCambiosNomina";
import EvaluacionCambiosCompetencias from "../pages/EvaluacionCambiosCompetencias";
import { getUserFromCookie } from "src/utils/helpers";

const usuario = getUserFromCookie();
const { es_admin_parcial = false } = usuario;

export const evaluacionRoutes = [
  {
    path: "/evaluaciones",
    element: <EvaluacionPage />,
  },
  {
    path: "/evaluaciones/:idEvaluacion",
    element: <EvaluacionDetallePage />,
  },
  {
    path: "/evaluaciones/:idEvaluacion/informe",
    element: <EvaluacionInformePage />,
  },
  {
    path: "/evaluaciones/:idEvaluacion/cambios-nomina",
    element: <EvaluacionCambiosNomina />,
  },
  {
    path: "/evaluaciones/:idEvaluacion/cambios-competencias",
    element: <EvaluacionCambiosCompetencias />,
  },
  {
    path: "/evaluaciones/:idEvaluacion/configuracion-formulario",
    element: <ConfiguracionFormularioForm />,
  },
  {
    path: "/evaluaciones/:idEvaluacion/configuracion-formulario/:idConfiguracion",
    element: <ConfiguracionFormularioForm />,
  },
  //
  {
    path: "/evaluaciones/configuracion",
    element: <EvaluacionConfiguracion />,
  },
  {
    path: "/evaluaciones/configuracion/:idEvaluacion",
    element: <EvaluacionConfiguracion />,
  },
  {
    path: "/evaluaciones/:idEvaluacion/colaborador/:idColaborador",
    element: <EvaluadoInformacionPage />,
  },
  {
    path: "/evaluaciones/:idEvaluacion/formulario/:idFormulario/*",
    element: !es_admin_parcial ? <FormularioPage /> : <></>,
  },
];

export const FormularioRoutes = () => {
  const formularioRoutes = [
    {
      path: "/evaluacion",
      element: <FormularioEvaluacionPage />,
    },
    {
      path: "/calibracion",
      element: <FormularioCalibracionPage />,
    },
    {
      path: "/devolucion",
      element: <FormularioDevolucionPage />,
    },
    {
      path: "/comentario",
      element: <FormularioComentarioPage />,
    },
    {
      path: "/finalizado",
      element: <FormularioFinalizadoPage />,
    },
  ];
  return (
    <Routes>
      {formularioRoutes.map((route, idx) => (
        <Route key={idx} {...route} />
      ))}
    </Routes>
  );
};
