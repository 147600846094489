import React, { useEffect, useState } from "react";
import { ObjetivosLayout } from "../components/ObjetivosLayout";
import DataTable from "src/modules/common/components/DataTable";
import { ProfileInfoCard } from "src/modules/common/components/ProfileInfoCard";
import { FiletypeXls, Search } from "react-bootstrap-icons";
import MostrarPaginas from "src/modules/common/components/MostrarPaginas";
import ReactSelect from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { objetivoActions } from "../handlers/redux";
import { useNavigate } from "react-router-dom";
import { colaboradorActions } from "src/modules/colaborador/modules/colaborador/handlers/redux";
import SearchInput from "src/modules/remuneracion/components/SearchInput";
import { SuccessToast } from "src/modules/common/components/SuccessToast";
import { ErrorToast } from "src/modules/common/components/ErrorToast";
import { WarningToast } from "src/modules/common/components/WarningToast";
import CollapseCard from "src/modules/common/components/CollapseCard";
import LoadingSpinButton from "src/modules/common/components/LoadingSpinButton";
import { getToken } from "src/utils/helpers";
import { DownloadPlantilla } from "../helpers/helper";
import FileInput from "src/modules/common/components/FileInput";

export const ObjetivosCargosPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const colaboradores = useSelector((state) => state.objetivo.colaboradores);
  const colaboradores_loading = useSelector(
    (state) => state.objetivo.colaboradores_loading
  );
  const colaboradores_count = useSelector(
    (state) => state.objetivo.colaboradores_count
  );
  const colaboradores_filter = useSelector(
    (state) => state.objetivo.colaboradores_filter
  );
  const colaboradores_checked = useSelector(
    (state) => state.objetivo.colaboradores_checked
  );
  const importar_loading = useSelector(
    (state) => state.objetivo.importar_loading
  );
  const errores_importacion = useSelector(
    (state) => state.objetivo.errores_importacion
  );

  const [outerPageSize, setOuterPageSize] = useState(50);
  const { nombre } = colaboradores_filter;

  useEffect(() => {
    dispatch(objetivoActions.getColaboradores());
  }, []);

  const onPaginate = (page, pageSize) => {
    dispatch(
      objetivoActions.setColaboradoresFilter({
        ...colaboradores_filter,
        pagination: {
          page: page,
          pageSize: pageSize,
        },
      })
    );
  };

  const setOrdering =
    (field) =>
    (order = "") => {
      let { ordering = "" } = colaboradores_filter;
      let orderingArray = ordering.split(",") || [];
      let emptyIndex = orderingArray.indexOf("");
      if (emptyIndex) {
        orderingArray.splice(emptyIndex, 1);
      }
      let fieldIndex = orderingArray.indexOf(field);
      fieldIndex =
        fieldIndex === -1 ? orderingArray.indexOf(`-${field}`) : fieldIndex;
      // si ya esta el campo, vemos si se modifica o elimina
      if (fieldIndex > 0) {
        orderingArray.splice(fieldIndex, 1);
        if (order) {
          field = order === "desc" ? `-${field}` : field;
          orderingArray[fieldIndex] = field;
        }
      } else {
        // si no está, lo agregamos
        field = order === "desc" ? `-${field}` : field;
        orderingArray.push(field);
      }
      ordering = orderingArray.join(",");
      //
      dispatch(
        objetivoActions.setColaboradoresFilter({
          ...colaboradores_filter,
          ordering: ordering,
        })
      );
    };

  const onSearch = (e) => {
    let value = e.target.value;
    dispatch(
      objetivoActions.setColaboradoresFilter({
        ...colaboradores_filter,
        nombre: value,
      })
    );
  };

  const onDescargarReporte = () => {
    console.log("onDescargarReporte");
    WarningToast({ message: "En desarrollo" });
  };

  const onChangePageSize = (e) => {
    let value = e.target.value;
    console.log("onChangePageSize", value);
    dispatch(
      objetivoActions.setColaboradoresFilter({
        ...colaboradores_filter,
        pagination: {
          pageSize: parseInt(value),
          page: 1,
        },
      })
    );
    setOuterPageSize(parseInt(value));
  };

  const actionOptions = [
    {
      value: "asignar_objetivos",
      label: "Asignar objetivos",
    },
  ];

  const onAction = (v) => {
    if (v.value === "asignar_objetivos") {
      if (colaboradores_checked.length > 0) {
        navigate("/objetivos/cargos/colaboradores/asignar-objetivos");
        //WarningToast({ message: "En desarrollo" });
      } else {
        ErrorToast({ message: "Debe seleccionar al menos un colaborador" });
      }
      //
    } else {
      // @ts-ignore
      window.alert(JSON.stringify(v));
    }
  };

  const onCheckAll = () => {
    let checkedData = JSON.parse(JSON.stringify(colaboradores));
    if (colaboradores_checked.length > 0) {
      checkedData = [];
    }
    dispatch(objetivoActions.setCheckColaboradores(checkedData));
  };

  const onCheckColaborador = (item) => (event) => {
    dispatch(
      objetivoActions.setCheckColaborador({
        colaborador: item,
        checked: event.target.checked,
      })
    );
  };

  const onRowClick = (item) => {
    navigate(`/objetivos/cargos/${item.id}`);
  };

  const datatableConfig = {
    headers: [
      {
        title: "Lista de integrantes",
        contentClass: "font-bold px-2 py-4",
        onOrder: setOrdering("nombre"),
        // accessor: "nombre",
        contentRenderer: (item) => (
          <div className="flex items-center w-56 relative">
            <ProfileInfoCard
              key={"col-" + item.id}
              image={item.foto_empleado}
              name={
                item.id ? `${item.nombre} ${item.apellido}` : "Sin especificar"
              }
            >
              <div
                title={item.cargo?.nombre || "Sin asignar"}
                className="text-sm w-36 whitespace-nowrap text-ellipsis overflow-hidden"
              >
                {item.cargo?.nombre || "Sin asignar"}
              </div>
            </ProfileInfoCard>
          </div>
        ),
      },
      {
        title: "# Objetivos",
        //accessor: "objetivos",
        onOrder: setOrdering("cantidad_objetivos"),
        contentRenderer: (item) => (
          <div className="">
            <span>{item.cantidad_objetivos}</span>
          </div>
        ),
      },
    ],
    rowActions: [],
  };
  //
  const uploadObjetivosPorColaborador = (file) => {
    dispatch(objetivoActions.setFileToUploadPorColaborador(file));
  };
  const uploadObjetivosPorCargo = (file) => {
    dispatch(objetivoActions.setFileToUploadPorCargo(file));
  };
  const onPlantilla = (plantilla) => {
    const token = getToken();
    DownloadPlantilla({ plantilla, token });
  };

  return (
    <ObjetivosLayout>
      <div className="mt-8"></div>
      <div className="md:w-11/12 flex flex-col gap-3">
        <div className="flex justify-between gap-4 w-full mt-4">
          <SearchInput
            containerClass={`w-1/2`}
            placeholder={"Buscar por nombre o por cargo..."}
            value={nombre}
            onChange={onSearch}
          />
          <MostrarPaginas
            containerClass={"w-1/2x"}
            onChangePageSize={onChangePageSize}
          />
          <div className="text-sm">
            <ReactSelect
              className="w-52 h-10 bg-blue-200 inline-grid"
              value={{
                value: "",
                label: `(${colaboradores_checked.length}) seleccionados`,
              }}
              options={actionOptions}
              onChange={onAction}
            ></ReactSelect>
          </div>
          <button
            onClick={onDescargarReporte}
            className="flex items-center gap-2 border rounded-md py-2 px-3 border-gray-500 text-sm"
          >
            <FiletypeXls size={21} />
            <span className="font-semibold text-sm">Descargar reporte</span>
          </button>
        </div>
        <DataTable
          containerClass={"my-8"}
          height="h-[550px]"
          data={colaboradores}
          loading={colaboradores_loading}
          config={datatableConfig}
          noHeaders={false}
          // pagination
          totalRecords={colaboradores_count}
          onPaginate={onPaginate}
          outerPageSize={outerPageSize}
          // check config
          checkable={true}
          onCheckAll={onCheckAll}
          checkedItems={colaboradores_checked}
          //checkedIdField={"colaborador_id"}
          onItemCheck={onCheckColaborador}
          onRowClick={onRowClick}
        />
        {!importar_loading && (
          <div className="flex w-full mb-4">
            <FileInput
              label={"Importador por colaborador"}
              className={"mt-3 w-3/4"}
              onSubmit={uploadObjetivosPorColaborador}
              onPlantilla={() => onPlantilla("por-colaborador")}
            />
            <FileInput
              label={"Importador por cargo"}
              className={"mt-3 w-3/4"}
              onSubmit={uploadObjetivosPorCargo}
              onPlantilla={() => onPlantilla("por-cargo")}
            />
          </div>
        )}
        {!!importar_loading && (
          <div className="flex items-center justify-center h-20">
            <LoadingSpinButton message="Importando datos, por favor espere..."></LoadingSpinButton>
          </div>
        )}
        {errores_importacion.length > 0 && (
          <CollapseCard
            titulo={"Ver errores"}
            extraClass="mb-8"
            titleClass={"text-red-500 font-semibold"}
            initCollapse={false}
          >
            <div className="flex flex-col gap-2">
              {errores_importacion.map((err, idx) => (
                <div className="p-1 text-red-400">{err}</div>
              ))}
            </div>
          </CollapseCard>
        )}
      </div>
    </ObjetivosLayout>
  );
};
