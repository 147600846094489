import React from "react";
import dayjs from "dayjs";
import { BalloonFill, CalendarHeart, PeopleFill } from "react-bootstrap-icons";
import { useDispatch, useSelector } from "react-redux";
import RoundedCard from "src/modules/common/components/Card";
import Pie from "src/modules/common/components/Pie";
import PieCenterLabel from "src/modules/common/components/PieCenterLabel";
import { ProfileInfoCard } from "src/modules/common/components/ProfileInfoCard";
import { commonActions } from "src/modules/common/handler/redux";
import CardLoading from "../components/CardLoading";
import StatisticHorizontal from "../components/StatisticHorizontal";
import StatisticVertical from "../components/StatisticVertical";
import { analisisActions } from "../handlers/redux";

function Analisis() {
  const dispatch = useDispatch();
  const analisis = useSelector((state) => state.analisis?.analisis);

  React.useEffect(() => {
    dispatch(commonActions.setTitle("Análisis de datos"));
    dispatch(commonActions.showSidebar(true));
    dispatch(analisisActions.analisis());
  }, []);
  const analisisLoading = useSelector(
    (state) => state.analisis.analisis_loading
  );
  const etarioColors = ["red", "blue", "green", "yellow", "orange", "red"];

  return (
    <>
      <div className=" flex">
        <div className="w-full">
          {!analisisLoading && (
            <div className="container mt-12 pb-4">
              {/* ROW */}
              <div className="flex flex-col md:flex-row justify-start gap-4">
                {/*  COLABORADORES */}
                <RoundedCard
                  icon={<PeopleFill color={"white"} size={21}></PeopleFill>}
                  title={"Colaboradores"}
                >
                  <div className="flex justify-between">
                    <PieCenterLabel
                      colorScale={["red", "blue"]}
                      data={[
                        { x: "F", y: analisis.cantidad_mujeres },
                        { x: "M", y: analisis.cantidad_hombres },
                      ]}
                      label={`${analisis.colaboradores_total}`}
                    ></PieCenterLabel>
                    <div className="flex flex-col gap-4 w-28">
                      <StatisticVertical
                        title={"Hombres"}
                        percentage={Math.round(analisis.hombres_porcentaje)}
                        amount={analisis.cantidad_hombres}
                        titleDotColor="blue"
                      ></StatisticVertical>
                      <StatisticVertical
                        title={"Mujeres"}
                        percentage={Math.round(analisis.mujeres_porcentaje)}
                        amount={analisis.cantidad_mujeres}
                        titleDotColor="red"
                      ></StatisticVertical>
                    </div>
                  </div>
                </RoundedCard>
                {/* CUMPLEAÑOS */}
                <RoundedCard
                  icon={
                    <CalendarHeart color={"white"} size={21}></CalendarHeart>
                  }
                  title={"Próximos cumpleaños"}
                >
                  <div className="flex flex-col gap-2">
                    {analisis.cumpleanhos?.length > 0 &&
                      analisis.cumpleanhos?.slice(0, 3).map((item, idx) => (
                        <ProfileInfoCard
                          key={`cumple-${idx}`}
                          name={`${item.nombre} ${item.apellido}`}
                          image={item.foto_empleado ? item.foto_empleado : ""}
                        >
                          <div className="text-sm">
                            Fecha:{" "}
                            {dayjs(item.fecha_nacimiento.slice(0, 10)).format(
                              "DD/MM/YYYY"
                            )}
                          </div>
                        </ProfileInfoCard>
                      ))}
                    {analisis.cumpleanhos?.length < 1 && (
                      <div className="flex justify-center items-center">
                        Sin cumpleaños este mes.
                      </div>
                    )}
                  </div>
                </RoundedCard>
                {/* ANIVERSARIOS */}
                <RoundedCard
                  icon={<BalloonFill color={"white"} size={21}></BalloonFill>}
                  title={"Próximos aniversarios"}
                >
                  <div className="flex flex-col gap-2">
                    {analisis.aniversarios?.length > 0 &&
                      analisis.aniversarios.slice(0, 3).map((item, idx) => (
                        <ProfileInfoCard
                          key={`aniv-${idx}`}
                          name={`${item.nombre} ${item.apellido}`}
                          image={item.foto_empleado ? item.foto_empleado : ""}
                        >
                          <div className="text-sm">
                            Fecha:{" "}
                            {dayjs(item.fecha_ingreso.slice(0, 10)).format(
                              "DD/MM/YYYY"
                            )}
                          </div>
                        </ProfileInfoCard>
                      ))}
                    {analisis.aniversarios?.length < 1 && (
                      <div className="flex justify-center items-center">
                        Sin aniversarios este mes.
                      </div>
                    )}
                  </div>
                </RoundedCard>
              </div>
              {/* ROW */}
              <div className="grid grid-cols-1 md:grid-cols-12 gap-4 mt-4">
                {/* RANGO ETARIO */}
                <RoundedCard
                  title={"Rango etario"}
                  extraClass={"col-span-1 md:col-span-8"}
                >
                  <div className="flex justify-center border-2x">
                    <div className="flex borderx w-1/3">
                      <Pie
                        colorScale={etarioColors}
                        data={
                          analisis.rango_etario &&
                          Object.entries(analisis.rango_etario).map(
                            ([key, value], idx) => {
                              return { x: 1, y: value[1] };
                            }
                          )
                        }
                      ></Pie>
                    </div>
                    <div className="flex flex-col flex-1 gap-4 justify-between">
                      {analisis.rango_etario &&
                        Object.entries(analisis.rango_etario).map(
                          ([key, value], idx) => {
                            return (
                              <StatisticHorizontal
                                key={`sh-${idx}`}
                                title={key}
                                amount={value[0]}
                                percentage={Math.round(value[1])}
                                titleDotColor={etarioColors[idx]}
                              ></StatisticHorizontal>
                            );
                          }
                        )}
                    </div>
                  </div>
                </RoundedCard>
                {/* PROMEDIO ANTIGUEDAD */}
                <RoundedCard
                  title={"Promedio de antiguedad"}
                  extraClass={"col-span-1 md:col-span-4"}
                >
                  <div className="flex flex-col max-h-60">
                    <PieCenterLabel
                      width={450}
                      height={450}
                      innerRadius={150}
                      data={[{ x: "-", y: analisis.promedio_antiguedad }]}
                      label={`${analisis.promedio_antiguedad}`}
                      labelSecondary={`Años`}
                    ></PieCenterLabel>
                  </div>
                </RoundedCard>
              </div>
            </div>
          )}
          {/* SKELETON LOADER */}
          {!!analisisLoading && (
            <div className="flex flex-col gap-4 mt-12">
              <div className="flex gap-4 w-full">
                {[1, 2, 3].map((item, idx) => (
                  <CardLoading key={`cl-${idx}`}></CardLoading>
                ))}
              </div>

              <div className="flex gap-4">
                {[1, 2].map((item, idx) => (
                  <CardLoading key={`cl2-${idx}`}></CardLoading>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default Analisis;
