import axios from "axios";
const URL_BASE =
  process.env.REACT_APP_API_URL || "http://localhost:8000/api/admin";

export const competenciaGeneralApi = (token) => {
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };

  return {
    create: async (params) => {
      try {
        const area = await axios.post(
          URL_BASE + "/competencia/general/create",
          params,
          { headers }
        );
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log(JSON.stringify(error));
        throw error;
      }
    },
    update: async ({ id, ...params }) => {
      try {
        const data = await axios.put(
          URL_BASE + `/competencia/general/${id}/edit`,
          params,
          {
            headers,
          }
        );
        return data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log(JSON.stringify(error));
        throw error;
      }
    },
    configuracion: async ({ id, ...params }) => {
      try {
        const data = await axios.post(
          URL_BASE + `/competencia/general/configuracion`,
          params,
          {
            headers,
          }
        );
        return data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log(JSON.stringify(error));
        throw error;
      }
    },
    delete: async ({ id }) => {
      try {
        const data = await axios.delete(
          URL_BASE + `/competencia/general/${id}/delete`,
          {
            headers,
          }
        );
        return data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log(JSON.stringify(error));
        throw error;
      }
    },
    list: async () => {
      try {
        const competenciasGenerales = await axios.get(
          URL_BASE + "/competencia/general/",
          {
            headers,
          }
        );
        return competenciasGenerales.data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log("areas error", error);
        throw error;
      }
    },
    libreriaGeneral: async (params) => {
      const { nombre = "", pagination = {}, ordering = "" } = params;
      const { page = 1, pageSize = 100 } = pagination;
      try {
        const libreriaGenerales = await axios.get(
          URL_BASE +
            `/competencia/libreria/general/?search=${nombre}&page=${page}&page_size=${pageSize}`,
          {
            headers,
          }
        );
        return libreriaGenerales.data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG &&
          console.log("libreriaGeneral api error", error);
        throw error;
      }
    },
  };
};
