import { createSlice } from "@reduxjs/toolkit";

const competenciaGeneralSlice = createSlice({
  name: "CompetenciaGeneral",
  initialState: {
    competencia_general_create_loading: false,
    competencia_general_update_loading: false,
    competencia_general_configuracion_loading: false,
    competencia_general_delete_loading: false,
    competencia_general: {},
    competencia_general_edit: false,
    competencia_generales: { result: [] },
    competencia_generales_loading: false,
    competencia_general_create_modal: { open: false },
    //
    aplicar_pesos_generales: false,
    //
    libreria_competencias: [],
    libreria_competencias_filter: {},
    libreria_competencias_loading: false,
  },
  reducers: {
    getCompetenciasBase: (state, { payload = {} }) => {
      state.libreria_competencias_loading = true;
    },
    setCompetenciasBase: (state, { payload = {} }) => {
      state.libreria_competencias = payload;
    },
    getCompetenciasBaseComplete: (state, { payload = {} }) => {
      state.libreria_competencias_loading = false;
    },
    //
    competenciaGeneralSet: (state, { payload = {} }) => {
      !!process.env.REACT_APP_DEBUG &&
        console.info("competenciaGeneralSet redux", payload);
      state.competencia_general = payload;
    },
    setCompetenciaGenerales: (state, { payload }) => {
      !!process.env.REACT_APP_DEBUG &&
        console.info("setCompetenciaGenerales redux", payload);
      state.competencia_generales = payload.competencia_generales;
      state.competencia_generales_loading = false;
    },
    setAplicarPesosGenerales: (state, { payload }) => {
      state.aplicar_pesos_generales = payload;
    },
    // create
    setCompetenciaGeneralCreateModal: (state, { payload = {} }) => {
      !!process.env.REACT_APP_DEBUG && console.log({ payload });
      state.competencia_general_create_modal = payload;
    },
    competenciaGeneralCreate: (state, { payload = { conductas: [] } }) => {
      state.competencia_general_create_loading = true;
    },
    competenciaGeneralCreateSuccess: (state) => {
      state.competencia_general_create_loading = false;
    },
    // update
    competenciaGeneralUpdate: (state, { payload = { conductas: [] } }) => {
      state.competencia_general_update_loading = true;
    },
    competenciaGeneralUpdateSuccess: (state) => {
      state.competencia_general_update_loading = false;
    },
    // edit
    competenciaGeneralEdit: (state, { payload }) => {
      state.competencia_general_edit = payload;
    },
    // configuracion
    competenciaGeneralConfiguracion: (
      state,
      { payload = { conductas: [] } }
    ) => {
      state.competencia_general_configuracion_loading = true;
    },
    competenciaGeneralConfiguracionSuccess: (state) => {
      state.competencia_general_configuracion_loading = false;
      state.competencia_general_edit = false;
    },
    competenciaGeneralConfiguracionComplete: (state) => {
      state.competencia_general_configuracion_loading = false;
    },
    // delete
    competenciaGeneralDelete: (state, { payload = {} }) => {
      state.competencia_general_delete_loading = true;
    },
    competenciaGeneralDeleteSuccess: (state) => {
      state.competencia_general_delete_loading = false;
    },
    // list
    competenciaGenerales: (state, { payload }) => {
      state.competencia_generales_loading = true;
    },
  },
});

export const competenciaGeneralActions = competenciaGeneralSlice.actions;
export const competenciaGeneralReducer = competenciaGeneralSlice.reducer;
