import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { departamentoActions } from "src/modules/estructura/modules/departamento/handlers/redux";

const useDepartamentos = () => {
  const dispatch = useDispatch();

  const departamentos_results = useSelector(
    (state) => state.departamento.departamentos
  );
  const { results: departamentos } = departamentos_results;

  const departamentos_filter = useSelector(
    (state) => state.departamento.departamentos_filter
  );
  const { nombre } = departamentos_filter;

  const filterDepartamentos = (nombre) => {
    dispatch(departamentoActions.setDepartamentosFilter({ nombre: nombre }));
  };

  return {
    departamentos,
    nombre,
    filterDepartamentos,
  };
};

export default useDepartamentos;
