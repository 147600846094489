import React, { useEffect, useState } from "react";
import { FeedbackConfiguracionLayout } from "../components/FeedbackConfiguracionLayout";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate, useParams, useLocation } from "react-router-dom";
import { commonActions } from "src/modules/common/handler/redux";
import userDefault from "src/modules/common/components/images/user_default.png";
import { FeedbackEtapasRoutes, feedbackEtapasName } from "../handlers/routes";
import { feedbackActions } from "../handlers/redux";

export function FeedbackEtapasPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const params = useParams();
  const { idFeedback, idJefeDirecto, idColaborador } = params;

  const feedback_nomina = useSelector(
    (state) => state.feedback.feedback_nomina
  );
  const feedback_nomina_loading = useSelector(
    (state) => state.feedback.feedback_nomina_loading
  );
  const jefe_directo = useSelector((state) => state.feedback.jefe_directo);
  const jefe_directo_loading = useSelector(
    (state) => state.feedback.jefe_directo_loading
  );
  const colaborador = useSelector(
    (state) => state.feedback.colaborador_feedback
  );
  const colaborador_loading = useSelector(
    (state) => state.feedback.colaborador_feedback_loading
  );

  const location = useLocation();
  const { pathname } = location;
  const steps = feedbackEtapasName;
  const partsPath = pathname.split("/") || "";
  const currentStep = partsPath[partsPath.length - 1];
  const currenStepIndex = steps.indexOf(currentStep);

  useEffect(() => {
    dispatch(commonActions.setTitle("Admin"));
    dispatch(commonActions.showSidebar(false));
    dispatch(feedbackActions.getJefeDirecto(idJefeDirecto));
    dispatch(feedbackActions.getColaboradorFeedback(idColaborador));
    dispatch(
      feedbackActions.getFeedbackNomina({
        idFeedback,
        idJefeDirecto,
        idColaborador,
      })
    );
  }, []);

  const onClickBack = () => {
    navigate(`/feedback/${idFeedback}/jefe-directo/${idJefeDirecto}/`);
  };

  const getAvailableSteps = () => {
    let stepsCopy = [];
    // Mismos nombres que estan como endpoint en los path de FeedbackEtapasRoutes en routes
    stepsCopy.push("jefe-directo");
    stepsCopy.push("evaluado");
    stepsCopy.push("finalizado");
    //
    return stepsCopy;
  };

  const generateLinks = () => {
    let links = [];
    let stepsCopy = [...steps];
    //
    const baseURL = `/feedback/${idFeedback}/jefe-directo/${idJefeDirecto}/colaborador/${idColaborador}/`;
    links = stepsCopy.map((endpoint, i) => ({
      to: baseURL + endpoint,
      name: endpoint,
      label: endpoint.replace("-", ' '), // Para sacarle el guion a Jefe-Directo
    }));
    return links;
  };

  const getPasosHabilitados = () => {
    const estado = feedback_nomina.estado ? feedback_nomina.estado : 1;
    const pasosDisponibles = [...steps];
    //
    let pasosHabilitados = pasosDisponibles.filter((s, si) => si < estado);
    if (pasosHabilitados.length <= 0) {
      // si está en estado 0 se habilita la primera etapa
      pasosHabilitados = [pasosDisponibles[0]];
    }
    return pasosHabilitados;
  };

  const navLinkClickHandler = (stepIndex) => (e) => {
    const selectedStep = steps[stepIndex];
    const pasosHabilitados = getPasosHabilitados();
    if (!pasosHabilitados.includes(selectedStep)) {
      e.preventDefault();
    }
  };

  return (
    <FeedbackConfiguracionLayout title={"Feedback"} onClickBack={onClickBack}>
      <div className="py-2 px-8 -mx-8">
        <div className="form flex justify-center mt-4">
          <div className="flex flex-col gap-4 w-full md:w-10/12">
            <div className="border-b pb-2">
              <div className="flex flex-row justify-between">
                <h2 className="font-semibold">Jefe directo</h2>
                <h2 className="font-semibold w-1/3">Colaborador</h2>
              </div>
            </div>
            <div className="flex flex-col w-full">
              <div className="flex flex-row justify-between items-center">
                {!jefe_directo_loading && (
                  <div className="flex flex-row gap-x-2 m-4">
                    <img
                      className="object-cover h-12 w-12 rounded-md"
                      src={
                        jefe_directo.foto_empleado
                          ? jefe_directo.foto_empleado
                          : userDefault
                      }
                      alt={`Perfil`}
                    />
                    <div className="flex flex-col gap-1">
                      <span className="font-semibold text-base">
                        {jefe_directo.nombre} {jefe_directo.apellido}
                      </span>
                      <span className="text-base">
                        Cargo:{" "}
                        {jefe_directo.cargo
                          ? jefe_directo.cargo.nombre
                          : "Sin cargo"}
                      </span>
                    </div>
                  </div>
                )}
                {jefe_directo_loading && <div>Cargando...</div>}
                {!colaborador_loading && (
                  <div className="flex flex-row gap-x-2 m-4 w-1/3">
                    <img
                      className="object-cover h-12 w-12 rounded-md"
                      src={
                        colaborador.foto_empleado
                          ? colaborador.foto_empleado
                          : userDefault
                      }
                      alt={`Perfil`}
                    />
                    <div className="flex flex-col gap-1">
                      <span className="font-semibold text-base">
                        {colaborador.nombre} {colaborador.apellido}
                      </span>
                      <span className="text-base">
                        Cargo:{" "}
                        {colaborador.cargo
                          ? colaborador.cargo.nombre
                          : "Sin cargo"}
                      </span>
                    </div>
                  </div>
                )}
                {colaborador_loading && <div>Cargando...</div>}
              </div>
              {/* Links item */}
              {/*{!!loading && (
                <div className="flex w-full justify-center animate-pulse my-8">
                  <div className="h-16 w-full md:w-2/5 bg-slate-200 rounded-md"></div>
                </div>
              )}*/}
              {true && (
                <div className="flex justify-center w-full my-8">
                  <div className="flex justify-center w-full md:w-2/5">
                    {generateLinks().map((link, idx) => (
                      <div
                        key={`div-f-link-${idx}`}
                        className={`borderx flex flex-col ${
                          idx + 1 !== generateLinks().length ? " flex-1" : ""
                        } gap-2`}
                      >
                        <NavLink
                          key={`f-link-${idx}`}
                          onClick={navLinkClickHandler(idx)}
                          to={link.to}
                          className={({ isActive }) =>
                            `${
                              !getPasosHabilitados().includes(link.name)
                                ? "text-slate-300 hover:cursor-not-allowed hover:text-slate-300"
                                : ""
                            } flex flex-col gap-2 ${
                              !!isActive ? "font-bold" : ""
                            }`
                          }
                        >
                          <div className="flex items-center">
                            <div
                              className={`h-5 w-5 ${
                                currentStep === link.name
                                  ? "bg-sky-500"
                                  : idx < currenStepIndex
                                  ? "bg-sky-500"
                                  : "bg-slate-300"
                              } rounded-full`}
                            ></div>
                            {idx + 1 !== generateLinks().length && (
                              <div
                                className={`${
                                  currentStep === link.name
                                    ? "bg-sky-500"
                                    : idx < currenStepIndex
                                    ? "bg-sky-500"
                                    : "bg-slate-300"
                                } h-0.5 flex-1`}
                              ></div>
                            )}
                          </div>
                          <div>
                            <span className="capitalize text-sm">
                              {link.label}
                            </span>
                          </div>
                        </NavLink>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
            <div className="text-sky-500 text-xl font-bold">
              Indicadores a dar Feedback
            </div>
            <FeedbackEtapasRoutes />
          </div>
        </div>
      </div>
    </FeedbackConfiguracionLayout>
  );
}
