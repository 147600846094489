import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { CheckInput } from "src/modules/common/components/CheckInput";
import SkeletonLoaderRow from "src/modules/common/components/SkeletonLoaderRow";
import { competenciaAreaActions } from "../handlers/redux";
import { CompetenciaAreaTableAccionMenu } from "./CompetenciaAreaTableAccionMenu";
import { CompetenciaAreaToolbar } from "./CompetenciaAreaToolbar";
import { useNavigate } from "react-router-dom";

export const CompetenciaAreaTable = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate()

  // @ts-ignore
  const competenciaAreas = useSelector(
    (state) => state.competenciaArea.competencia_areas
  );
  const competenciaAreas_checked = useSelector(
    (state) => state.competenciaArea.competencia_areas_checked
  );
  const competencia_areas_loading = useSelector(
    (state) => state.competenciaArea.competencia_areas_loading
  );
  const { results = [] } = competenciaAreas;

  const selectAllChange = (event) => {
    results.map((item, idx) =>
      dispatch(
        competenciaAreaActions.checkCompetenciaArea({
          competenciaArea: item,
          checked: event.target.checked,
        })
      )
    );
  };

  const renderTableHeader = () => {
    return (
      <div className="border-b flex flex-col border-zinc-100 ">
        <div className="flex flex-row items-center">
          <CheckInput
            checked={
              competenciaAreas_checked.length === results.length &&
              results.length > 0
            }
            onChange={selectAllChange}
          ></CheckInput>
          <div className="p-1 font-bold flex-grow">
            Lista de competencias creadas
          </div>
          <div className="p-1 font-bold text-center w-60  mr-4">Áreas</div>
          <div className=" w-6"></div>
        </div>
      </div>
    );
  };

  const goToDetail = (item) => {
    let { areas = [] } = item;
    dispatch(
      competenciaAreaActions.competenciaAreaSet({
        ...item,
        areas: areas.map((area) => area.id),
      })
    );
    dispatch(competenciaAreaActions.setCompetenciaAreaEditing(false));
    navigate(`/competencias/area/${item.id}/view`);
  }

  const renderTableItem = (item, idx) => {
    return (
      <div
        key={idx}
        className="hover:bg-sky-100 flex flex-row border-b py-4 align-middle "
      >
        <div className="p-1 h-16 align-text-top  flex flex-row items-center">
          <CheckInput
            checked={competenciaAreas_checked.includes(item.id)}
            onChange={(event) => {
              dispatch(
                competenciaAreaActions.checkCompetenciaArea({
                  competenciaArea: item,
                  checked: event.target.checked,
                })
              );
            }}
          />
        </div>
        <div 
          className="p-1 flex flex-grow flex-col justify-center cursor-pointer" 
          onClick={() => goToDetail(item)}
        >
          <div className="font-bold">{item.nombre}</div>
          <div className="text-sm">{item.descripcion}</div>
        </div>
        <div className="p-1 text-center w-48 flex flex-row justify-center items-center">
          {item.areas.length}
        </div>
        <div className="p-1 w-12 flex flex-row items-center">
          <CompetenciaAreaTableAccionMenu item={item} />
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className="flex flex-col w-full">
        {renderTableHeader()}
        <div className="flex flex-col">
          {!!competencia_areas_loading &&
            [1, 2, 3].map((l, i) => (
              <SkeletonLoaderRow key={`sl-${i}`}></SkeletonLoaderRow>
            ))}
          {!competencia_areas_loading &&
            results.length > 0 &&
            results.map(renderTableItem)}
          {!competencia_areas_loading && results.length < 1 && (
            <div className="flex h-20 items-center justify-center">
              <div>No existen registros para mostrar.</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
