import React, { useState } from "react";
import "./css/ToggleSwitch.css";

function ToggleSwitch({ className, checked = false, onChange = null }) {
  return (
    <div className={className}>
      <label className="switch">
        <input type="checkbox" checked={checked} onChange={onChange} />
        <span className="slider round"></span>
      </label>
    </div>
  );
}

export default ToggleSwitch;
