import React from "react";
import { PieChart, Pie, Cell, ResponsiveContainer } from "recharts";
import { getColor, getFillIndex } from "src/modules/common/handler/color";

export const CompetenciaPieChart = ({
  competencias = [
    { nombre: "Group A", peso: 0 },
    { nombre: "Group B", peso: 0 },
  ],
  className = "",
}) => {
  if (!competencias) {
    return;
  }
  let data = competencias.map((c) => {
    c = { ...c };
    c.peso = c.peso || 100 / competencias.length;
    return c;
  });

  let total = data.reduce((total, c) => total + c.peso, 0);

  let diferencia = 100 - total;
  if (total > 100) {
    diferencia = 0;
  }
  data.push({ nombre: "", peso: diferencia, color: "#eeeeee" });

  const aux = data.map((entry, index) => {
    return { entry, index };
  });

  return (
    <div className={` relative ${className} h-48 w-96`}>
      <div className="absolute bottom-0 w-full h-10 pb-2 flex flex-row justify-center items-center">
        {total}%
      </div>
      <ResponsiveContainer width="100%" height="100%">
        <PieChart>
          <Pie
            data={data}
            cx="50%"
            cy="100%"
            startAngle={180}
            endAngle={0}
            innerRadius="120%"
            outerRadius="200%"
            fill="#8884d8"
            paddingAngle={1}
            dataKey="peso"
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                strokeWidth={0}
                className={!entry.color ? getFillIndex(index) : null}
                fill={entry.color || null}
              />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
};
