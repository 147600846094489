import React, { useState } from "react";
import { X, XLg } from "react-bootstrap-icons";
import { useDispatch, useSelector } from "react-redux";
import Input from "src/modules/common/components/Input";
import Modal from "src/modules/common/components/Modal";
import { sucursalActions } from "../handlers/redux";
import CustomAlert from "src/modules/common/components/CustomAlert";

function SucursalModal(props) {
  const dispatch = useDispatch();
  const modal = useSelector((state) => state.sucursal.sucursal_create_modal);
  const sucursal = useSelector((state) => state.sucursal.sucursal);
  const [showAlert, setShowAlert] = useState(false);

  const setOpen = (open) => {
    dispatch(sucursalActions.setSucursalCreateModal({ open }));
  };

  const onChange =
    (name) =>
    ({ target }) => {
      dispatch(
        sucursalActions.sucursaleset({ ...sucursal, [name]: target.value })
      );
    };

  const showEditAlert = (e) => {
    e.preventDefault();
    setShowAlert(true);
  };

  const onAceptarAlert = (e) => {
    setShowAlert(false);
    onSubmit();
  };
  const onCancelarAlert = () => {
    setShowAlert(false);
  };

  const onSubmit = (event) => {
    event?.preventDefault();
    if (!sucursal.nombre) return;
    if (sucursal.id) {
      dispatch(sucursalActions.sucursalUpdate());
      setOpen(false);
      return;
    }
    dispatch(sucursalActions.sucursalCreate());
    setOpen(false);
  };

  return (
    <Modal isOpen={modal.open}>
      <CustomAlert
        message="Atención: este cambio afectará a todos los colaboradores que tengan asignada esta sucursal."
        visible={showAlert}
        onAceptarHandler={onAceptarAlert}
        onCancelarHandler={onCancelarAlert}
      ></CustomAlert>
      <div className=" rounded-lg bg-white w-6/12">
        <form
          onSubmit={sucursal.id ? showEditAlert : onSubmit}
          className="flex flex-col gap-3 p-4"
        >
          <div className="flex flex-row justify-between text-lg font-bold">
            <div>Sucursal</div>
            <div onClick={() => setOpen(false)}>
              <XLg />
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <div className="text-slate-600 text-sm font-bold">Nombre</div>
            <Input
              value={sucursal.nombre || ""}
              onChange={onChange("nombre")}
            ></Input>
          </div>
          <div className="flex flex-col gap-2">
            <div className="text-slate-600 text-sm font-bold">Descripcion</div>
            <Input
              value={sucursal.descripcion || ""}
              onChange={onChange("descripcion")}
            ></Input>
          </div>
          <div className="flex flex-row gap-2">
            <button
              type="submit"
              className="bg-sky-500 text-white rounded-md px-3 py-2 active:bg-gray-200"
            >
              Guardar
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
}

export default SucursalModal;
