import React, { useEffect, useState } from "react";
import { BeneficioConfiguracionLayout } from "../components/BeneficioConfiguracionLayout";
import { useNavigate, useParams } from "react-router-dom";
import { beneficiosRoutesWindow } from "../handlers/routes";
import { useDispatch, useSelector } from "react-redux";
import { commonActions } from "src/modules/common/handler/redux";
import { TipoConfiguracionBeneficioSelect } from "../components/TipoConfiguracionBeneficio";
import {PersonDashFill} from 'react-bootstrap-icons'
import { CategoriaBeneficio } from "../components/CategoriaBeneficio";
import SelectModal from "src/modules/common/components/SelectModal";
import { BeneficioConfiguracionForm } from "./BeneficioConfiguracionForm";
import { PencilFill, TrashFill, X } from "react-bootstrap-icons";
import DataTable from "src/modules/common/components/DataTable";
import SearchInput from "src/modules/remuneracion/components/SearchInput";
import MostrarPaginas from "src/modules/common/components/MostrarPaginas";
import ReactSelect from "react-select";
import { beneficioActions } from "../handlers/redux";
import { BeneficioConfiguracionFormEdit } from "./BeneficioConfiguracionFormEdit";
import CargosHabilitadosList from "../components/CargosHabilitadosList";
import ColaboradoresHabilitadosList from "../components/ColaboradoresHabilitadosList";
import Modal from "src/modules/common/components/Modal";
import { ErrorToast } from "src/modules/common/components/ErrorToast";

export function BeneficioConfiguracionVista() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const params = useParams();
  const { idBeneficio } = params;
  const beneficio = useSelector((state) => state.beneficio.beneficio);
  const beneficio_loading = useSelector(
    (state) => state.beneficio.beneficio_loading
  );
  const cargos_list_checked = useSelector(
    (state) => state.beneficio.cargos_list_checked
  );
  const colaboradores_list_checked = useSelector(
    (state) => state.beneficio.colaboradores_list_checked
  );
  const [openCargosModal, setOpenCargosModal] = useState(false);
  const [excluirChecked, setExcluirChecked] = useState(false);
  const [openColaboradoresModal, setOpenColaboradoresModal] = useState(false);
  const { requisitos = [], cargos = [], colaboradores = [] } = beneficio;
  const colaboradoresExcluidos = colaboradores.map((colaborador) =>
    colaborador.colaborador.beneficio_habilitado === false ? colaborador.id : 0
  );

  useEffect(() => {
    dispatch(commonActions.setTitle("Admin"));
    dispatch(commonActions.showSidebar(false));
    dispatch(beneficioActions.getBeneficio(idBeneficio));
    dispatch(beneficioActions.resetCheckCargosList());
  }, []);

  const onClickBack = () => {
    navigate(beneficiosRoutesWindow.BENEFICIOS);
  };

  const openColaboradores = (e) => {
    e.preventDefault();
    setOpenColaboradoresModal(true);
  };

  const getEstadoColor = (estado) => {
    return estado ? "bg-green-500 text-white" : "bg-red-500 text-white";
  };

  const onEditConfig = () => {
    console.log("Editar configuración");
    dispatch(commonActions.setSelectModal({ open: true }));
  };

  const agregarCargo = () => {
    setOpenCargosModal(true);
  };

  const onDesvincular = (cargoBeneficio) => {
    console.log("Desvincular", cargoBeneficio);
    const deseaEliminar = window.confirm(
      "¿Está seguro de desvincular del beneficio el cargo seleccionado?"
    );
    if (!!deseaEliminar) {
      const payload = {
        beneficioId: idBeneficio,
        cargoBeneficioId: cargoBeneficio.id,
      };
      dispatch(beneficioActions.desvincularCargo(payload));
      dispatch(beneficioActions.resetCheckCargosList());
    }
  };

  const onUpdateBeneficio = (updateBeneficio) => {
    let updateBeneficioCopy = { ...updateBeneficio };
    updateBeneficioCopy.tipo_config_beneficio =
      updateBeneficio.tipo_config_beneficio.id;
    delete updateBeneficioCopy.cargos;
    delete updateBeneficioCopy.colaboradores;
    dispatch(beneficioActions.updateBeneficio(updateBeneficioCopy));
    dispatch(commonActions.setSelectModal({ open: false }));
  };

  const datatableConfigCargos = {
    headers: [
      {
        title: "Vinculados",
        contentClass: "font-bold px-2 py-4",
        onOrder: false,
        // accessor: "nombre",
        contentRenderer: (item) => (
          <div className="flex flex-col">
            <span className="font-semibold">{item.cargo.nombre}</span>
          </div>
        ),
      },
      {
        title: "",
        contentClass: "font-bold px-2 py-4",
        // accessor: "nombre",
        contentRenderer: (item) => (
          <button
            className="flex items-center gap-2 border rounded-md py-2 px-3 border-gray-500 text-sm"
            onClick={() => onDesvincular(item)}
          >
            <span className="text-sm">Desvincular</span>
          </button>
        ),
      },
    ],
    rowActions: [],
  };

  const datatableConfigColaboradores = {
    headers: [
      {
        title: "Excluidos",
        contentClass: "font-bold px-2 py-4",
        onOrder: false,
        // accessor: "nombre",
        contentRenderer: (item) => (
          <div className="flex flex-col">
            <span className="font-semibold">
              {item.colaborador.nombre} {item.colaborador.apellido}
            </span>
          </div>
        ),
      },
      {
        title: "",
        contentClass: "font-bold px-2 py-4",
        // accessor: "nombre",
        contentRenderer: (item) => (
          <button
            className="flex items-center gap-2 border rounded-md py-2 px-3 border-gray-500 text-sm"
            onClick={() => {
              const deseaIncluir = window.confirm(
                "¿Está seguro de incluir al beneficio al colaborador seleccionado?"
              );
              if (deseaIncluir) {
                if (item.colaborador.beneficio_habilitado) {
                  incluirColaboradores([item.id]);
                } else {
                  ErrorToast({
                    message:
                      "El colaborador seleccionado se encuentra excluido de los beneficios en general.",
                  });
                }
              }
            }}
          >
            <span className="text-sm">Incluir</span>
          </button>
        ),
      },
    ],
    rowActions: [],
  };

  const handleOnChangeExcluir = (e) => {
    console.log(e);
    const value = e.target.checked;
    setExcluirChecked(value);
  };

  const vincularCargos = (cargosIds) => {
    console.log(cargosIds);
    // Se eliminan los ids repetidos
    const cargosIdsUnique = cargosIds.filter(
      (elemento, indice) => cargosIds.indexOf(elemento) === indice
    );
    console.log("cargos", cargosIdsUnique);
    console.log("beneficio", idBeneficio);
    const payload = {
      beneficioId: idBeneficio,
      cargosIds: cargosIds,
    };
    dispatch(beneficioActions.vincularCargos(payload));
    dispatch(beneficioActions.resetCheckCargosList());
    setOpenCargosModal(false);
  };

  const desvincularCargosMasivo = () => {
    // Se eliminan los ids repetidos
    const cargosBeneficiosIds = cargos_list_checked.map(
      (cargo_beneficio) => cargo_beneficio.id
    );
    console.log("cargos_beneficios_ids", cargosBeneficiosIds);
    const payload = {
      beneficioId: idBeneficio,
      cargosBeneficiosIds: cargosBeneficiosIds,
    };
    dispatch(beneficioActions.desvincularCargosMasivo(payload));
    dispatch(beneficioActions.resetCheckCargosList());
    setOpenCargosModal(false);
  };

  // Recibe los ids de ColaboradorBeneficios en un array
  const incluirColaboradores = (colaboradoresBeneficiosIds) => {
    console.log(colaboradoresBeneficiosIds);
    const payload = {
      beneficioId: idBeneficio,
      colaboradoresBeneficiosIds: colaboradoresBeneficiosIds,
    };
    dispatch(beneficioActions.incluirColaboradoresBeneficio(payload));
    dispatch(beneficioActions.resetCheckColaboradoresList());
    setOpenColaboradoresModal(false);
  };

  const excluirColaboradores = (colaboradoresIds) => {
    // Se eliminan los ids repetidos
    const colaboradoresIdsUnique = colaboradoresIds.filter(
      (elemento, indice) => colaboradoresIds.indexOf(elemento) === indice
    );
    const payload = {
      beneficiosId: [idBeneficio],
      colaboradoresIds: colaboradoresIdsUnique,
    };
    dispatch(beneficioActions.excluirColaboradoresBeneficio(payload));
    setOpenColaboradoresModal(false);
  };

  const onCheckAllCargo = () => {
    let checkedData = JSON.parse(JSON.stringify(cargos));
    if (cargos_list_checked.length > 0) {
      checkedData = [];
    }
    dispatch(beneficioActions.setCheckCargosList(checkedData));
  };

  const onCheckAllColaborador = () => {
    let checkedData = JSON.parse(JSON.stringify(colaboradores));
    if (colaboradores_list_checked.length > 0) {
      checkedData = [];
    }
    dispatch(beneficioActions.setCheckColaboradoresList(checkedData));
  };

  const onCheckCargo = (item) => (event) => {
    dispatch(
      beneficioActions.checkCargosList({
        cargo_aux: item,
        checked: event.target.checked,
      })
    );
  };

  const onCheckColaborador = (item) => (event) => {
    dispatch(
      beneficioActions.checkColaboradoresList({
        colaborador_aux: item,
        checked: event.target.checked,
      })
    );
  };

  const actionOptionsCargo = [
    {
      value: "desvincular",
      label: "Desvincular",
      // action: () => {},
    },
  ];

  const onActionCargo = (v) => {
    if (v.value === "desvincular") {
      if (cargos_list_checked.length < 1) {
        ErrorToast({ message: "Debe seleccionar al menos un cargo." });
        return;
      }
      const deseaDesvincular = window.confirm(
        "¿Está seguro de desvincular los cargos seleccionados?"
      );
      if (deseaDesvincular) {
        desvincularCargosMasivo();
      }
      return;
    }
  };

  const actionOptionsColaborador = [
    {
      value: "incluir",
      label: "Incluir",
      // action: () => {},
    },
  ];

  const onActionColaborador = (v) => {
    if (v.value === "incluir") {
      if (colaboradores_list_checked.length < 1) {
        ErrorToast({ message: "Debe seleccionar al menos un colaborador." });
        return;
      }
      const deseaIncluir = window.confirm(
        "¿Está seguro de incluir al beneficio a los colaboradores seleccionados?"
      );
      if (deseaIncluir) {
        let colaboradoresBeneficiosIds = colaboradores_list_checked.map(
          (colaborador_beneficio) => colaborador_beneficio.id
        );
        // Se eliminan los ids que existan en colaboradoresExcluidos para no volver a incluirlos
        colaboradoresBeneficiosIds = colaboradoresBeneficiosIds.filter(
          (elemento) => !colaboradoresExcluidos.includes(elemento)
        );
        incluirColaboradores(colaboradoresBeneficiosIds);
      }
      return;
    }
  };

  return (
    <BeneficioConfiguracionLayout
      title="Configuración"
      onClickBack={onClickBack}
    >
      <div className="py-2 px-8 -mx-8">
        <div className="form flex flex-col items-center mt-4">
          <div className="flex flex-col gap-4 w-full md:w-8/12 border rounded-xl p-4">
            <div className="border-b pb-2">
              <div className="flex flex-row justify-between">
                <h2 className="font-semibold text-2xl">Detalles</h2>
                <div
                  className="flex flex-row gap-2 items-center pl-3 cursor-pointer"
                  onClick={onEditConfig}
                >
                  <PencilFill color="#0EA5E9" />
                  <div className="text-sky-500 text-sm font-bold">
                    Editar configuración
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-row justify-between">
              <div className="flex flex-col gap-2">
                <label className="font-bold text-sm" htmlFor="">
                  Nombre
                </label>
                <div className="py-2">{beneficio.nombre}</div>
              </div>
              <div className="flex flex-col gap-2">
                <label className="font-bold text-sm" htmlFor="">
                  Estado
                </label>
                <div className="flex items-center">
                  <span
                    className={`p-2 rounded-md text-sm ${getEstadoColor(
                      beneficio.vigente
                    )}`}
                  >
                    {beneficio.vigente ? "Vigente" : "No vigente"}
                  </span>
                </div>
              </div>
              <span></span>
            </div>
            <div className="flex flex-col gap-2">
              <label className="font-bold text-sm" htmlFor="">
                Descripción
              </label>
              <div className="py-2">{beneficio.descripcion}</div>
            </div>
            <div className="flex flex-col gap-2">
              <div className="flex flex-col gap-2"></div>
              <label className="font-bold text-sm" htmlFor="">
                Requisitos
              </label>
              <div className="text-sm">
                Si se requiere, puede indicar algunos requisitos para obtenerlo
              </div>
              {requisitos.length > 0 &&
                requisitos.map((requisito, idx) => (
                  <div key={idx} className="flex flex-col gap-2 w-full">
                    <span className="text-sm my-2 text-gray-500 ml-3">
                      {requisito.descripcion || ""}
                    </span>
                  </div>
                ))}
              {requisitos.length < 1 && (
                <div className="flex justify-center w-full mt-8">
                  <div className="table-cellx text-sm">
                    No existen registros para mostrar.
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="pb-2 w-full md:w-8/12 mt-6">
            <div className="flex flex-row justify-between">
              <h2 className="font-semibold text-2xl">Cargos</h2>
              <div className="flex flex-row align-middle gap-4">
                <div className="text-sm">
                  <ReactSelect
                    className="w-52 h-10 bg-blue-200 inline-grid"
                    value={{
                      value: "",
                      label: `(${cargos_list_checked.length}) seleccionados`,
                    }}
                    options={actionOptionsCargo}
                    onChange={onActionCargo}
                  ></ReactSelect>
                </div>
                <button
                  onClick={agregarCargo}
                  className="rounded-md bg-sky-500 text-white p-3"
                >
                  Agregar cargo
                </button>
              </div>
            </div>
            {/*<div className="px-4 py-8 w-full md:w-11/12">
              <div className="flex items-center justify-end gap-2">
                <SearchInput
                  containerClass={`w-1/2`}
                  placeHolder={"Buscar por nombre..."}
                />
              <MostrarPaginas containerClass={"w-1/2x"} />
                <div className="text-sm">
                  <ReactSelect
                    className="w-52 h-10 bg-blue-200 inline-grid"
                    value={{
                      value: "",
                      label: `(${cargos_list_checked.length}) seleccionados`,
                    }}
                    options={actionOptionsCargo}
                    onChange={onActionCargo}
                  ></ReactSelect>
                </div>
              </div>
              <div></div>
            </div>*/}
            <DataTable
              containerClass={""}
              config={datatableConfigCargos}
              loading={beneficio_loading}
              data={cargos}
              noHeaders={false}
              //
              totalRecords={cargos.length ? cargos.length : 0}
              //
              onCheckAll={onCheckAllCargo}
              checkedItems={cargos_list_checked}
              onItemCheck={onCheckCargo}
              checkable={true}
            />
          </div>
          <div className="pb-2 w-full md:w-8/12 mt-6">
            <div className="flex flex-row justify-between">
              <h2 className="font-semibold text-2xl">
                Colaboradores excluidos
              </h2>
              <div className="flex flex-row align-middle gap-4">
                <div className="text-sm">
                  <ReactSelect
                    className="w-52 h-10 bg-blue-200 inline-grid"
                    value={{
                      value: "",
                      label: `(${colaboradores_list_checked.length}) seleccionados`,
                    }}
                    options={actionOptionsColaborador}
                    onChange={onActionColaborador}
                  ></ReactSelect>
                </div>
                <button
                  onClick={() => setOpenColaboradoresModal(true)}
                  className="rounded-md bg-sky-500 text-white p-3"
                >
                  Excluir colaborador
                </button>
              </div>
            </div>
            {/*<div className="px-4 py-8 w-full md:w-11/12">
              <div className="flex items-center gap-2">
                <SearchInput
                  containerClass={`w-1/2`}
                  placeHolder={"Buscar por nombre..."}
                />
                <MostrarPaginas containerClass={"w-1/2x"} />
                <div className="text-sm">
                  <ReactSelect
                    className="w-52 h-10 bg-blue-200 inline-grid"
                    value={{
                      value: "",
                      label: `(${colaboradores_list_checked.length}) seleccionados`,
                    }}
                    options={actionOptionsColaborador}
                    onChange={onActionColaborador}
                  ></ReactSelect>
                </div>
              </div>
              <div></div>
                  </div>*/}
            <DataTable
              containerClass={""}
              config={datatableConfigColaboradores}
              loading={beneficio_loading}
              data={colaboradores}
              noHeaders={false}
              //
              totalRecords={colaboradores.length ? colaboradores.length : 0}
              //
              onCheckAll={onCheckAllColaborador}
              checkedItems={colaboradores_list_checked}
              onItemCheck={onCheckColaborador}
              checkable={true}
            />
          </div>
          <SelectModal
            title="Agregar"
            content={
              <BeneficioConfiguracionFormEdit
                beneficio={beneficio}
                onSubmit={onUpdateBeneficio}
              />
            }
          />
          <Modal isOpen={openCargosModal}>
            <div className="rounded-lg bg-white w-10/12 md:w-4/12 p-2 md:p-5 flex flex-col gap-5">
              <div className="modal-title">
                <div className="w-full flex justify-between items-center">
                  <div className="flex items-center gap-4">
                    <h2 className="font-semibold text-xl">
                      Seleccione los cargos
                    </h2>
                  </div>
                  <button
                    onClick={() => {
                      setOpenCargosModal(false);
                    }}
                  >
                    <X size={24} />
                  </button>
                </div>
              </div>
              <div className="modal-body">
                <div className="">
                  <div>
                    <CargosHabilitadosList
                      onSubmit={vincularCargos}
                      categoriaForm={{
                        cargos: [],
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Modal>
          <Modal isOpen={openColaboradoresModal}>
            <div className="rounded-lg bg-white w-10/12 md:w-4/12 p-2 md:p-5 flex flex-col gap-5">
              <div className="modal-title">
                <div className="w-full flex justify-between items-center">
                  <div className="flex items-center gap-4">
                    <h2 className="font-semibold text-xl">
                      Seleccione los colaboradores
                    </h2>
                  </div>
                  <button
                    onClick={() => {
                      setOpenColaboradoresModal(false);
                    }}
                  >
                    <X size={24} />
                  </button>
                </div>
              </div>
              <div className="modal-body">
                <div className="">
                  <div>
                    <ColaboradoresHabilitadosList
                      onSubmit={excluirColaboradores}
                      categoriaForm={{
                        colaboradores: [],
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        </div>
      </div>
    </BeneficioConfiguracionLayout>
  );
}
