import React from "react";
import { FeedbackComentarios } from "../components/FeedbackComentarios";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

export function FeedbackEtapaFinalizado() {
  const params = useParams();
  const { idFeedback, idJefeDirecto, idColaborador } = params;

  const jefe_directo = useSelector((state) => state.feedback.jefe_directo);
  const jefe_directo_loading = useSelector(
    (state) => state.feedback.jefe_directo_loading
  );
  const colaborador = useSelector(
    (state) => state.feedback.colaborador_feedback
  );
  const colaborador_loading = useSelector(
    (state) => state.feedback.colaborador_feedback_loading
  );

  return (
    <div className="my-4">
      <FeedbackComentarios
        jefe_directo={jefe_directo}
        colaborador={colaborador}
        identificadores={{ idFeedback, idJefeDirecto, idColaborador }}
        editJefeDirecto={false}
        editColaborador={false}
        commentColaboradorView={true}
        buttonDeleteView={false}
        canSave={false}
        etapa={3}
      />
    </div>
  );
}
