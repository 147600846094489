import React from "react";
import CompetenciaAreaFormPage from "src/modules/competencia/modules/area/pages/CompetenciaAreaFormPage";
import CompetenciaAreaPage from "src/modules/competencia/modules/area/pages/CompetenciaAreaPage";

export const competenciasAreaRoutes = [
  {
    path: "/competencias/area",
    element: <CompetenciaAreaPage />,
  },
  {
    path: "/competencias/area/create",
    element: <CompetenciaAreaFormPage />,
  },
  {
    path: "/competencias/area/:idCompetenciaArea/edit",
    element: <CompetenciaAreaFormPage />,
  },
  {
    path: "/competencias/area/:idCompetenciaArea/view",
    element: <CompetenciaAreaFormPage />,
  },
];
