import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import SelectModal from "src/modules/common/components/SelectModal";
import { ColaboradorAsignarTable } from "../components/ColaboradorAsignarTable";
import { ColaboradorAsignarToolbar } from "../components/ColaboradorAsignarToolbar";
import KpiLayout from "../components/KpiLayout";
import { KpiTable } from "../components/KpiTable";
import { KpiToolbar } from "../components/KpiToolbar";
import { kpiActions } from "../handlers/redux";

function KpiCreadosPage() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(kpiActions.kpis());
  }, []);

  return (
    <KpiLayout>
      <div className="mt-8"></div>
      <div className="md:w-11/12 flex flex-col gap-3">
        <KpiToolbar />
        <KpiTable></KpiTable>
        <SelectModal
          title={"Lista de colaboradores"}
          content={
            <>
              <ColaboradorAsignarToolbar />
              <ColaboradorAsignarTable />
            </>
          }
        ></SelectModal>
      </div>
    </KpiLayout>
  );
}

export default KpiCreadosPage;
