import React from "react";

import { ReactComponent as User } from "../../common/images/user.svg";

export const ProfileImageSmall = () => {
  return (
    <div className="rounded-lg bg-zinc-100 h-14 w-14 flex justify-center items-center">
      <User />
    </div>
  );
};
