import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { commonActions } from "src/modules/common/handler/redux";


export function ObjetivosLayout({children}) {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(commonActions.setTitle("Objetivos por cargo"))
        dispatch(commonActions.showSidebar(true))
        dispatch(commonActions.setTitleMenu([
            {
                name: "Colaboradores",
                to: "/objetivos/cargos"
            },
        ]))
        return () => {
            commonActions.setTitleMenu([])
        }
    }, [])

    return <>{children}</>
}