import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import backButton from "src/modules/common/components/images/back-arrow.png";
import { PrimaryButton } from "src/modules/common/components/PrimaryButton";
import { ProfileInfoCard } from "src/modules/common/components/ProfileInfoCard";
import SelectModal from "src/modules/common/components/SelectModal";
import TableCustom from "src/modules/common/components/TableCustom";
import TableRow from "src/modules/common/components/TableRow";
import { commonActions } from "src/modules/common/handler/redux";
import { ColaboradoresKPITable } from "../components/ColaboradoresKPITable";
import { ColaboradoresKPIToolbar } from "../components/ColaboradoresKPIToolbar";
import { KpiCard } from "../components/KpiCard";
import { kpiActions } from "../handlers/redux";

function KpiDetailPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const kpi = useSelector((state) => state.kpi.kpi);
  const kpi_loading = useSelector((state) => state.kpi.kpi_loading);
  const colaboradores = useSelector(
    (state) => state?.colaborador?.colaboradores
  );
  const kpiColaboradores = useSelector((state) => state.kpi.kpiColaboradores);
  const kpiColaboradores_checked = useSelector(
    (state) => state.kpi.kpiColaboradores_checked
  );

  useEffect(() => {
    dispatch(commonActions.setTitleMenu([]));
    dispatch(commonActions.setTitle(""));
    dispatch(commonActions.showSidebar(false));
    dispatch(kpiActions.kpiColaboradores());
  }, []);

  const onClickEdit = (id) => {
    !!process.env.REACT_APP_DEBUG && console.info("id", id);
    navigate(`/kpis/configuracion/${id}`);
  };

  const onClickDelete = (id) => {
    let deseaEliminar = window.confirm("Desea eliminar el KPI?");
    if (deseaEliminar) {
      dispatch(kpiActions.kpiDelete({ id: id, fromDetail: true }));
    }
  };

  const ColaboradorItem = ({ item }) => {
    const dispatch = useDispatch();

    return (
      <>
        <TableRow>
          <div
            className="p-4 flex flex-row items-center justify-center cursor-pointer"
            onClick={() => {
              let { colaboradores = [] } = kpi;

              colaboradores = colaboradores.filter((c) => c.id != item.id);

              dispatch(
                kpiActions.kpiSet({
                  ...kpi,
                  colaboradores,
                })
              );

              dispatch(
                kpiActions.setColaboradorAddModal({
                  open: false,
                })
              );
            }}
          >
            <ProfileInfoCard name={item.nombre}>
              <div className="text-sm">
                {/* Cargo: {!!cargo?.nombre ? cargo.nombre : "Sin especificar"} */}
              </div>
            </ProfileInfoCard>
          </div>
        </TableRow>
      </>
    );
  };

  const onClickAdd = () => {
    dispatch(
      kpiActions.setCheckColaboradores(kpiColaboradores.map((c) => c.id))
    );
    dispatch(
      commonActions.setSelectModal({
        open: true,
      })
    );
  };
  // Search Input
  const kpiColaboradores_filter = useSelector(
    (state) => state.kpi.kpiColaboradores_filter
  );
  const { nombre = " " } = kpiColaboradores_filter;
  const onChangeText = (event) => {
    dispatch(
      kpiActions.setKpiColaboradoresFilter({
        nombreColaborador: event.target.value,
        id: kpi.id,
      })
    );
  };

  const onSelectChange = (v, items = []) => {
    if (v.value === "eliminar") {
      let deseaEliminar = window.confirm(
        "Desea eliminar los Colaboradores seleccionados?"
      );
      if (deseaEliminar) {
        dispatch(kpiActions.setKpiColaboradoresDelete(items));
      }
    } else if (v.value === "cancel") {
      dispatch(kpiActions.resetCheckKpiColaboradores());
    } else {
      // @ts-ignore
      window.alert(JSON.stringify(v));
    }
  };

  const onCheckHandler = (event, item) => {
    dispatch(
      kpiActions.checkKpiColaborador({
        colaborador: item,
        checked: event.target.checked,
      })
    );
  };

  !!process.env.REACT_APP_DEBUG &&
    console.info("kpiColaboradores page", kpiColaboradores);

  return (
    <>
      {/* HEADER */}
      <div className="flex justify-between items-center bg-zinc-100 py-2.5 px-7 border-b -mx-8">
        <div className="flex items-center">
          <div
            onClick={() => navigate("/kpis/")}
            className={
              "h-full  flex items-center pr-2 -mr-2 z-10 cursor-pointer"
            }
          >
            <img className="back" src={backButton} alt="Regresar" />
          </div>
          <div className="font-medium px-4 text-lg">KPI creado</div>
        </div>
        <div className="flex items-center gap-4 button-group">
          <PrimaryButton onClick={() => onClickEdit(kpi.id)}>
            <span className="font-bold">Editar indicador</span>
          </PrimaryButton>
          <button
            className="text-red-500"
            onClick={() => onClickDelete(kpi.id)}
          >
            <span className="font-medium text-sm">Eliminar</span>
          </button>
        </div>
      </div>
      {/*  */}
      <div className="flex justify-center mt-8">
        <div className="w-5/5 md:w-4/5">
          <div className="p-1 font-bold">Detalle de KPI</div>
          <div className="border-b border-b-gray-200 mb-3"></div>
          <KpiCard kpi={kpi} loading={kpi_loading}></KpiCard>
          {/* COLABORADORES */}
          {!kpi_loading && (
            <div className="mt-8">
              <h3 className="font-bold">Colaboradores con este KPI</h3>
              <SelectModal
                title={`Asignar colaboradores`}
                content={
                  <>
                    <ColaboradoresKPIToolbar />
                    <ColaboradoresKPITable />
                  </>
                }
              ></SelectModal>
              <TableCustom
                ItemComponent={ColaboradorItem}
                data={kpiColaboradores}
                subject={"colaborador"}
                subjectGender={"M"}
                tableSecondTitle={"Lista de integrantes"}
                addBtnClickHandler={onClickAdd}
                onChangeSearch={onChangeText}
                searchValue={nombre}
                onSelectChange={onSelectChange}
                selectOptions={[
                  { value: "eliminar", label: "Eliminar" },
                  { value: "cancel", label: "Cancelar" },
                ]}
                fixedHeigth={false}
                customAddBtnLabel={"Asignar colaborador"}
                addBtnColor={"bg-black"}
                itemsChecked={kpiColaboradores_checked}
                onCheckHandler={onCheckHandler}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default KpiDetailPage;
