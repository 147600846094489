import React, { useEffect, useState } from "react";
import { BeneficioConfiguracionLayout } from "../components/BeneficioConfiguracionLayout";
import { useNavigate, useParams } from "react-router-dom";
import { beneficiosRoutesWindow } from "../handlers/routes";
import { useDispatch, useSelector } from "react-redux";
import { commonActions } from "src/modules/common/handler/redux";
import { TipoConfiguracionBeneficioSelect } from "../components/TipoConfiguracionBeneficio";
import { PersonDashFill } from "react-bootstrap-icons";
import { CategoriaBeneficio } from "../components/CategoriaBeneficio";
import SelectModal from "src/modules/common/components/SelectModal";
import { BeneficioConfiguracionForm } from "./BeneficioConfiguracionForm";
import { beneficioActions } from "../handlers/redux";
import Modal from "src/modules/common/components/Modal";
import CargosTable from "src/modules/plancarrera/components/CargosTable";
import { X } from "react-bootstrap-icons";
import { ErrorToast } from "src/modules/common/components/ErrorToast";
import CargosHabilitadosList from "../components/CargosHabilitadosList";
import ColaboradoresHabilitadosList from "../components/ColaboradoresHabilitadosList";
import { PrimaryButton } from "src/modules/common/components/PrimaryButton";

const initialBeneficiosBody = {
  beneficios: [],
  cargos: [],
  colaboradores: [],
  tipo_config_beneficio: 0,
};

const initialBeneficio = {
  nombre: "",
  descripcion: "",
  requisitos: [
    {
      descripcion: "",
    },
  ],
};

export function BeneficioConfiguracion() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const beneficios_body = useSelector(
    (state) => state.beneficio.beneficios_body
  );
  const tipos_configuraciones = useSelector(
    (state) => state.beneficio.tipos_configuraciones
  );
  const cargos = useSelector((state) => state.beneficio.cargos_habilitados);
  const cargos_habilitados = cargos.results;
  const mostrar_alcance = useSelector(
    (state) => state.beneficio.mostrar_alcance
  );
  const mostrar_excluidos = useSelector(
    (state) => state.beneficio.mostrar_excluidos
  );
  const onclickback_beneficio_configuracion = useSelector(
    (state) => state.beneficio.onclickback_beneficio_configuracion
  );

  const [openTipoConfigModal, setOpenTipoConfigModal] = useState(false);
  const [openCargosModal, setOpenCargosModal] = useState(false);
  const [openColaboradoresModal, setOpenColaboradoresModal] = useState(false);
  const [beneficioForm, setBeneficioForm] = useState(initialBeneficio);
  const [alcanceSeleccionado, setAlcanceSeleccionado] =
    useState("option-manual");
  const [excluirChecked, setExcluirChecked] = useState(false);
  const [nombreTipoConfig, setNombreTipoConfig] = useState("");
  const [indexBeneficio, setIndexBeneficio] = useState(null);

  const { beneficios = [] } = beneficios_body;

  useEffect(() => {
    dispatch(commonActions.setTitle("Admin"));
    dispatch(commonActions.showSidebar(false));
    dispatch(beneficioActions.resetCheckCargosHabilitados());
    dispatch(beneficioActions.resetCheckColaboradoresHabilitados());
    dispatch(beneficioActions.getTiposConfiguraciones());
    dispatch(beneficioActions.getCargosHabilitados());
  }, []);

  const onClickBack = () => {
    dispatch(beneficioActions.setBeneficiosBody(initialBeneficiosBody));
    navigate(onclickback_beneficio_configuracion);
  };

  const handleOnChangeAlcance = (e) => {
    console.log(e.target.id);
    const option = e.target.id;
    setAlcanceSeleccionado(option);
  };

  const handleOnChangeExcluir = (e) => {
    console.log(e);
    const value = e.target.checked;
    setExcluirChecked(value);
  };

  const onOpenForm = () => {
    setIndexBeneficio(null);
    setBeneficioForm(initialBeneficio);
    dispatch(commonActions.setSelectModal({ open: true }));
  };

  const onAgregarBeneficio = (newBeneficio) => {
    // Se recibe el nuevo beneficio a agregar
    console.log("onAgregarBeneficio--", newBeneficio);
    const { beneficios = [] } = beneficios_body;
    let beneficiosCopy = [...beneficios];
    // Si indexBeneficio tiene un valor, quiere decir que se está editando un beneficio. Si es null, se está agregando
    // Ver métodos onOpenForm (crear) y onEditBeneficio (editar)
    if (indexBeneficio === null) {
      beneficiosCopy.push(newBeneficio);
    } else {
      beneficiosCopy[indexBeneficio] = newBeneficio;
    }
    console.log("beneficios", beneficiosCopy);
    let beneficiosBodyCopy = { ...beneficios_body };
    beneficiosBodyCopy.beneficios = beneficiosCopy;
    console.log("newBeneficio agregado", beneficiosBodyCopy);
    dispatch(beneficioActions.setBeneficiosBody(beneficiosBodyCopy));
    dispatch(commonActions.setSelectModal({ open: false }));
  };

  const [tipoConfiguracion, setTipoConfiguracion] = useState({}); //Aca se guardara lo que se elija en la lista desplegable

  const onChange =
    (field, type = "") =>
    ({ target }) => {
      let value = target.value;
      if (type === "checkbox") {
        value = target.checked;
      }
      if (field === "tipo_config_beneficio") {
        setTipoConfiguracion({ value: value, label: target.label });
      }
      dispatch(
        beneficioActions.setBeneficiosBody({
          ...beneficios_body,
          [field]: value,
        })
      );
    };

  const onChangeTipoConfig = (e) => {
    console.log(e.target.value);
    setNombreTipoConfig(e.target.value);
  };

  const submitConfiguracion = () => {
    let beneficiosBodyCopy = { ...beneficios_body };
    // Verificar y crear los atributos necesarios si no existen
    if (!beneficiosBodyCopy.hasOwnProperty("beneficios")) {
      beneficiosBodyCopy.beneficios = [];
    }
    if (!beneficiosBodyCopy.hasOwnProperty("tipo_config_beneficio")) {
      beneficiosBodyCopy.tipo_config_beneficio = 0;
    }
    if (!beneficiosBodyCopy.hasOwnProperty("cargos")) {
      beneficiosBodyCopy.cargos = [];
    }
    if (!beneficiosBodyCopy.hasOwnProperty("colaboradores")) {
      beneficiosBodyCopy.colaboradores = [];
    }
    if (alcanceSeleccionado === "") {
      ErrorToast({ message: "Debe seleccionar un alcance" });
    } else if (alcanceSeleccionado === "option-todos") {
      // Si se selecciona Todos los cargos
      const cargosIds = cargos_habilitados.map((cargo) => cargo.id);
      beneficiosBodyCopy.cargos = cargosIds;
    }
    if (beneficiosBodyCopy.beneficios.length === 0) {
      ErrorToast({ message: "Debe cargar al menos un beneficio" });
    } else if (beneficiosBodyCopy.tipo_config_beneficio === 0) {
      ErrorToast({ message: "Debe seleccionar un tipo de configuración" });
    } else {
      console.log("Confirmar", beneficiosBodyCopy);
      dispatch(beneficioActions.setBeneficiosBody(beneficiosBodyCopy));
      dispatch(beneficioActions.bulkCreate(beneficiosBodyCopy));
    }
  };

  const renderListaBeneficios = () => {
    return (
      <div className="flex flex-col gap-3">
        {beneficios.map((beneficio, idx) => (
          <CategoriaBeneficio
            name={beneficio.nombre}
            description={beneficio.descripcion}
            onEdit={() => onEditBeneficio(beneficio, idx)}
            onDelete={() => onDeleteBeneficio(beneficio, idx)}
          />
        ))}
      </div>
    );
  };

  const onEditBeneficio = (beneficio, idx) => {
    setIndexBeneficio(idx); // Índice del beneficio que se quiere editar
    setBeneficioForm(beneficio);
    dispatch(commonActions.setSelectModal({ open: true }));
  };

  const onDeleteBeneficio = (beneficio, idx) => {
    const deseaEliminar = window.confirm(
      "¿Esta seguro de eliminar este beneficio?"
    );
    if (!!deseaEliminar) {
      const { beneficios = [] } = beneficios_body;
      const beneficiosCopy = beneficios.filter((beneficio, id) => id !== idx);
      let beneficiosBodyCopy = { ...beneficios_body };
      beneficiosBodyCopy.beneficios = beneficiosCopy;
      console.log("beneficios", beneficiosBodyCopy);
      dispatch(beneficioActions.setBeneficiosBody(beneficiosBodyCopy));
    }
  };

  const cargarCargos = (cargosIds) => {
    // Se eliminan los ids repetidos
    const cargosIdsUnique = cargosIds.filter(
      (elemento, indice) => cargosIds.indexOf(elemento) === indice
    );
    console.log(cargosIdsUnique);
    dispatch(
      beneficioActions.setBeneficiosBody({
        ...beneficios_body,
        cargos: cargosIdsUnique,
      })
    );
    setOpenCargosModal(false);
  };

  const cargarColaboradores = (colaboradoresIds) => {
    // Se eliminan los ids repetidos
    const colaboradoresIdsUnique = colaboradoresIds.filter(
      (elemento, indice) => colaboradoresIds.indexOf(elemento) === indice
    );
    console.log("colaboradoresIdsUnique", colaboradoresIdsUnique);
    dispatch(
      beneficioActions.setBeneficiosBody({
        ...beneficios_body,
        colaboradores: colaboradoresIdsUnique,
      })
    );
    setOpenColaboradoresModal(false);
  };

  const openCargos = (e) => {
    e.preventDefault();
    setOpenCargosModal(true);
  };

  const openColaboradores = (e) => {
    e.preventDefault();
    setOpenColaboradoresModal(true);
  };

  const crearTipoConfigBeneficio = () => {
    if (!nombreTipoConfig.trim()) {
      ErrorToast({
        message: "Debes agregar un nombre para el tipo de configuración",
      });
    }
    const request_body = {
      nombre: nombreTipoConfig,
    };
    dispatch(beneficioActions.createTipoConfiguracion(request_body));
    setOpenTipoConfigModal(false);
  };

  return (
    <BeneficioConfiguracionLayout
      title="Beneficio configuración"
      onClickBack={onClickBack}
    >
      <div className="py-2 px-8 -mx-8">
        <div className="form flex justify-center mt-4">
          <div className="flex flex-col gap-4 w-full md:w-8/12">
            <div className="border-b pb-2">
              <h2 className="font-semibold text-lg">Configuración inicial</h2>
            </div>
            <div>
              <div className="flex flex-col w-full">
                <h2 className="font-semibold text-sm">Tipo de configuración</h2>
                <div className="flex flex-row justify-start items-center">
                  <TipoConfiguracionBeneficioSelect
                    options={tipos_configuraciones}
                    onChange={onChange("tipo_config_beneficio")}
                  />
                  <div
                    className="w-5 h-5 rounded-full bg-sky-500 text-white flex items-center justify-center text-2xl ml-4 cursor-pointer"
                    onClick={() => setOpenTipoConfigModal(true)}
                  >
                    +
                  </div>
                </div>
              </div>
            </div>
            {mostrar_alcance && (
              <div>
                <div className="border-b pb-2">
                  <h2 className="font-semibold text-lg">Alcance</h2>
                </div>
                <div>
                  <form>
                    <div className="flex flex-col items-start mb-4 mt-2">
                      <div>
                        <input
                          type="radio"
                          name="alcance"
                          id="option-todos"
                          onChange={handleOnChangeAlcance}
                        />
                        <label htmlFor="option-todos" className="ml-2">
                          Todos
                        </label>
                      </div>
                      <div className="text-sm text-gray-400 ml-5">
                        {cargos_habilitados ? cargos_habilitados.length : 0}{" "}
                        cargos
                      </div>
                    </div>
                    <div className="flex items-center justify-between">
                      <div className="justify-start">
                        <input
                          type="radio"
                          name="alcance"
                          id="option-manual"
                          onChange={handleOnChangeAlcance}
                          defaultChecked={true}
                        />
                        <label htmlFor="option-manual" className="ml-2">
                          Manual
                        </label>
                        <div className="text-sm text-gray-400 ml-5">
                          {beneficios_body.cargos
                            ? beneficios_body.cargos.length
                            : 0}{" "}
                          cargos seleccionados
                        </div>
                      </div>
                      <button
                        className={`flex items-center gap-2 border rounded-md py-2 px-3 border-gray-500 text-sm ${
                          alcanceSeleccionado !== "option-manual"
                            ? "text-gray-500"
                            : ""
                        }`}
                        onClick={openCargos}
                        disabled={alcanceSeleccionado !== "option-manual"}
                      >
                        <span className="text-sm">Configuración</span>
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            )}
            {mostrar_excluidos && (
              <div>
                <div className="border-b pb-2">
                  <h2 className="font-semibold text-lg">
                    Excluir colaboradores
                  </h2>
                </div>
                <div className="space-y-2 mt-2">
                  <div className="flex items-start">
                    <div>
                      <input
                        className="w-5 h-5 mr-2"
                        type="checkbox"
                        checked={excluirChecked}
                        onChange={handleOnChangeExcluir}
                      />
                    </div>
                    <div className="ml-2">
                      <h3 className="font-bold">
                        No mostrar beneficios por cargos
                      </h3>
                      <div className="text-sm text-gray-400">
                        Excluye la visualización de beneficios por cargo a
                        colaboradores seleccionados
                      </div>
                    </div>
                  </div>
                  <div className="flex items-center justify-between">
                    <div className="flex items-start">
                      <div>
                        <PersonDashFill size={20} />
                      </div>
                      <div className="ml-4">
                        <h3 className="font-bold">Excluidos</h3>
                        <div className="text-sm text-gray-400">
                          {beneficios_body.colaboradores
                            ? beneficios_body.colaboradores.length
                            : 0}{" "}
                          colaboradores
                        </div>
                      </div>
                    </div>
                    <button
                      className={`flex items-center gap-2 border rounded-md py-2 px-3 border-gray-500 text-sm ${
                        !excluirChecked ? "text-gray-500" : ""
                      }`}
                      onClick={openColaboradores}
                      disabled={!excluirChecked}
                    >
                      <span className="text-sm">Configuración</span>
                    </button>
                  </div>
                </div>
              </div>
            )}

            <div className="flex items-center gap-2 border-b pb-2">
              <h2 className="font-semibold text-lg"> Tipo: </h2>
              <h2 className="text-sky-500 font-semibold text-lg">
                {tipoConfiguracion.value > 0 ? tipoConfiguracion.label : ""}
              </h2>
            </div>
            <div className="">{renderListaBeneficios()}</div>
            <div
              className="flex flex-row gap-2 items-center pl-3"
              onClick={onOpenForm}
            >
              <div className="w-5 h-5 rounded-full bg-sky-500 text-white flex items-center justify-center text-2xl">
                +
              </div>
              <div className="text-sky-500 text-sm">{`Agregar nuevo`}</div>
            </div>
            <button
              onClick={submitConfiguracion}
              className="bg-sky-500 text-white text-sm w-full py-3 rounded-md mt-4"
            >
              Guardar
            </button>
            <SelectModal
              title="Agregar"
              content={
                <BeneficioConfiguracionForm
                  beneficio={beneficioForm}
                  onSubmit={onAgregarBeneficio}
                />
              }
            />
            <Modal isOpen={openTipoConfigModal}>
              <div className="rounded-lg bg-white w-10/12 md:w-4/12 p-2 md:p-5 flex flex-col gap-5">
                <div className="modal-title">
                  <div className="w-full flex justify-between items-center">
                    <div className="flex items-center gap-4">
                      <h2 className="font-semibold text-xl">
                        Crear nuevo Tipo de Configuración
                      </h2>
                    </div>
                    <button
                      onClick={() => {
                        setOpenTipoConfigModal(false);
                      }}
                    >
                      <X size={24} />
                    </button>
                  </div>
                </div>
                <div className="modal-body">
                  <div className="">
                    <div>
                      <div className="flex flex-col gap-2 w-full">
                        <label className="font-bold text-sm" htmlFor="">
                          Nombre
                        </label>
                        <input
                          type={"text"}
                          name={"nombre"}
                          onChange={onChangeTipoConfig}
                          placeholder={"Nombre del nuevo tipo de configuración"}
                          className="border rounded placeholder-zinc-600 border-zinc-200 px-2 py-3 text-sm w-full"
                        />
                        <PrimaryButton
                          type="button"
                          onClick={crearTipoConfigBeneficio}
                        >
                          Guardar
                        </PrimaryButton>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Modal>
            <Modal isOpen={openCargosModal}>
              <div className="rounded-lg bg-white w-10/12 md:w-4/12 p-2 md:p-5 flex flex-col gap-5">
                <div className="modal-title">
                  <div className="w-full flex justify-between items-center">
                    <div className="flex items-center gap-4">
                      <h2 className="font-semibold text-xl">
                        Seleccione los cargos
                      </h2>
                    </div>
                    <button
                      onClick={() => {
                        setOpenCargosModal(false);
                      }}
                    >
                      <X size={24} />
                    </button>
                  </div>
                </div>
                <div className="modal-body">
                  <div className="">
                    <div>
                      <CargosHabilitadosList
                        onSubmit={cargarCargos}
                        categoriaForm={{
                          cargos: beneficios_body.cargos
                            ? beneficios_body.cargos
                            : [],
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Modal>
            <Modal isOpen={openColaboradoresModal}>
              <div className="rounded-lg bg-white w-10/12 md:w-4/12 p-2 md:p-5 flex flex-col gap-5">
                <div className="modal-title">
                  <div className="w-full flex justify-between items-center">
                    <div className="flex items-center gap-4">
                      <h2 className="font-semibold text-xl">
                        Seleccione los colaboradores
                      </h2>
                    </div>
                    <button
                      onClick={() => {
                        setOpenColaboradoresModal(false);
                      }}
                    >
                      <X size={24} />
                    </button>
                  </div>
                </div>
                <div className="modal-body">
                  <div className="">
                    <div>
                      <ColaboradoresHabilitadosList
                        onSubmit={cargarColaboradores}
                        categoriaForm={{
                          colaboradores: beneficios_body.colaboradores
                            ? beneficios_body.colaboradores
                            : [],
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Modal>
          </div>
        </div>
      </div>
    </BeneficioConfiguracionLayout>
  );
}
