import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TableCustom from "src/modules/common/components/TableCustom";
import TableRow from "src/modules/common/components/TableRow";
import { areaActions } from "src/modules/estructura/modules/area/handlers/redux";
import { cargoActions } from "src/modules/estructura/modules/cargo/handlers/redux";
import { departamentoActions } from "src/modules/estructura/modules/departamento/handlers/redux";
import { sucursalActions } from "src/modules/estructura/modules/sucursal/handlers/redux";
import { colaboradorActions } from "../handlers/redux";

function ImportacionEstructura({ subject }) {
  const dispatch = useDispatch();
  const [data, setData] = useState({});
  //
  const [nuevos, setNuevos] = useState([]);
  const [duplicados, setDuplicados] = useState([]);
  const [searchNuevos, setSearchNuevos] = useState("");
  const [searchDuplicados, setSearchDuplicados] = useState("");
  //
  const [nuevosChecked, setNuevosChecked] = useState([]);
  const [duplicadosChecked, setDuplicadosChecked] = useState([]);

  useEffect(() => {
    setData(dataState);
    setNuevosChecked(nuevosCheckedState);
    setDuplicadosChecked(duplicadosCheckedState);
  }, []);

  useEffect(() => {
    setNuevos(data.nuevos);
  }, [data.nuevos?.length]);

  useEffect(() => {
    setDuplicados(data.duplicados);
  }, [data.duplicados?.length]);

  const getDataState = () => {
    let dataState = null;
    switch (subject) {
      case "sucursal":
        dataState = (state) => state.sucursal.datosVerificados;
        break;
      case "area":
        dataState = (state) => state.area.datosVerificados;
        break;
      case "cargo":
        dataState = (state) => state.cargo.datosVerificados;
        break;
      case "departamento":
        dataState = (state) => state.departamento.datosVerificados;
        break;
      case "colaborador":
        dataState = (state) => state.colaborador.datosVerificados.colaboradores;
        break;
      default:
        dataState = [];
    }
    return dataState;
  };
  const getNuevosChecked = () => {
    let nuevosChecked = null;
    switch (subject) {
      case "sucursal":
        nuevosChecked = (state) => state.sucursal.nuevosChecked;
        break;
      case "area":
        nuevosChecked = (state) => state.area.nuevosChecked;
        break;
      case "cargo":
        nuevosChecked = (state) => state.cargo.nuevosChecked;
        break;
      case "departamento":
        nuevosChecked = (state) => state.departamento.nuevosChecked;
        break;
      case "colaborador":
        nuevosChecked = (state) => state.colaborador.nuevosChecked;
        break;
      default:
        nuevosChecked = [];
    }
    return nuevosChecked;
  };
  const getDuplicadosChecked = () => {
    let duplicadosChecked = null;
    switch (subject) {
      case "sucursal":
        duplicadosChecked = (state) => state.sucursal.duplicadosChecked;
        break;
      case "area":
        duplicadosChecked = (state) => state.area.duplicadosChecked;
        break;
      case "cargo":
        duplicadosChecked = (state) => state.cargo.duplicadosChecked;
        break;
      case "departamento":
        duplicadosChecked = (state) => state.departamento.duplicadosChecked;
        break;
      case "colaborador":
        duplicadosChecked = (state) => state.colaborador.duplicadosChecked;
        break;
      default:
        duplicadosChecked = [];
    }
    return duplicadosChecked;
  };
  // @ts-ignore
  const dataState = useSelector(getDataState()) || [];
  // @ts-ignore
  const nuevosCheckedState = useSelector(getNuevosChecked());
  // @ts-ignore
  const duplicadosCheckedState = useSelector(getDuplicadosChecked());

  useEffect(() => {
    setNuevosChecked(nuevosCheckedState);
  }, [nuevosCheckedState]);

  useEffect(() => {
    setDuplicadosChecked(duplicadosCheckedState);
  }, [duplicadosCheckedState]);

  function getDifference(array1, array2) {
    return array1.filter((object1) => {
      return !array2.some((object2) => {
        return object1.nombre === object2.nombre;
      });
    });
  }

  const ColaboradorItem = ({ item }) => {
    const renderFecha = (fecha) => {
      let fechaCopy = "";
      if (fecha) {
        fechaCopy = dayjs(fecha.slice(0, 10)).format("DD/MM/YYYY");
      }
      if (fechaCopy === "Invalid Date") {
        fechaCopy = fecha;
      }
      return fechaCopy;
    };
    return (
      <>
        {subject === "colaborador" && (
          <TableRow>
            <div
              className="p-4 flex flex-row justify-between w-full pr-8"
              onClick={() => {}}
            >
              <div>
                <div className="font-bold">{`${item.nombre} ${item.apellido}`}</div>
                <div>CI: {item.ci || "Sin CI"}</div>
              </div>
              <div>
                <div>{renderFecha(item.fecha_ingreso)}</div>
              </div>
              <div>
                <div>{renderFecha(item.fecha_nacimiento)}</div>
              </div>
              <div>
                <div>{item.sexo === "M" ? "Hombre" : "Mujer"}</div>
              </div>
            </div>
          </TableRow>
        )}
        {subject !== "colaborador" && (
          <TableRow>
            <div
              className="p-4 flex flex-col items-start justify-center cursor-pointer"
              onClick={() => {}}
            >
              <div className="font-bold">{item.nombre}</div>
              <div>{item.descripcion || "Sin descripción"}</div>
            </div>
          </TableRow>
        )}
      </>
    );
  };

  const DuplicadoItem = ({ item }) => {
    return (
      <>
        {subject !== "colaborador" && (
          <TableRow>
            <div
              className="p-4 flex gap-8 items-start justify-betweenx cursor-pointer"
              onClick={() => {}}
            >
              <div className="text-slate-400 flex-1">
                <div className="font-bold">{item.anterior.nombre}</div>
                <div>{item.anterior.descripcion || "Sin descripción"}</div>
              </div>
              <div className="flex-1">
                <div className="font-bold">{item.nuevo.nombre}</div>
                <div>{item.nuevo.descripcion || "Sin descripción"}</div>
              </div>
            </div>
          </TableRow>
        )}
        {subject === "colaborador" && (
          <TableRow>
            <div
              className="p-4 flex gap-8 items-start justify-around cursor-pointer"
              onClick={() => {}}
            >
              <div>
                <div className="font-bold">{`${item.nuevo.nombre} ${item.nuevo.apellido}`}</div>
                <div>CI: {item.nuevo.ci || "Sin CI"}</div>
              </div>
              <div>
                <div>{item.nuevo.fecha_ingreso}</div>
              </div>
              <div>
                <div>{item.nuevo.fecha_nacimiento}</div>
              </div>
              <div>
                <div>{item.nuevo.sexo === "M" ? "Hombre" : "Mujer"}</div>
              </div>
            </div>
          </TableRow>
        )}
      </>
    );
  };

  const onCheckHandlerNuevos = (event, item) => {
    let dispatchAction = null;
    switch (subject) {
      case "sucursal":
        dispatchAction = sucursalActions.checkNuevo({
          item: item,
          checked: event.target.checked,
        });
        break;
      case "area":
        dispatchAction = areaActions.checkNuevo({
          item: item,
          checked: event.target.checked,
        });
        break;
      case "cargo":
        dispatchAction = cargoActions.checkNuevo({
          item: item,
          checked: event.target.checked,
        });
        break;
      case "departamento":
        dispatchAction = departamentoActions.checkNuevo({
          item: item,
          checked: event.target.checked,
        });
        break;
      case "colaborador":
        dispatchAction = colaboradorActions.checkNuevo({
          item: item,
          checked: event.target.checked,
        });
        break;
      default:
        dispatchAction = null;
    }
    dispatch(dispatchAction);
  };

  const onResetCheckHandlerNuevos = (event, item) => {
    let dispatchAction = null;
    switch (subject) {
      case "sucursal":
        dispatchAction = sucursalActions.resetCheckNuevos();
        break;
      case "area":
        dispatchAction = areaActions.resetCheckNuevos();
        break;
      case "cargo":
        dispatchAction = cargoActions.resetCheckNuevos();
        break;
      case "departamento":
        dispatchAction = departamentoActions.resetCheckNuevos();
        break;
      case "colaborador":
        dispatchAction = colaboradorActions.resetCheckNuevos();
        break;
      default:
        dispatchAction = null;
    }
    dispatch(dispatchAction);
  };

  const onCheckHandlerDuplicados = (event, item) => {
    const newItem = item; //{ id: item.anterior.id, ...item.nuevo };
    !!process.env.REACT_APP_DEBUG &&
      console.info("newItem", newItem, event.target.checked);
    let dispatchAction = null;
    switch (subject) {
      case "sucursal":
        dispatchAction = sucursalActions.checkDuplicado({
          item: newItem,
          checked: event.target.checked,
        });
        break;
      case "area":
        dispatchAction = areaActions.checkDuplicado({
          item: newItem,
          checked: event.target.checked,
        });
        break;
      case "cargo":
        dispatchAction = cargoActions.checkDuplicado({
          item: newItem,
          checked: event.target.checked,
        });
        break;
      case "departamento":
        dispatchAction = departamentoActions.checkDuplicado({
          item: newItem,
          checked: event.target.checked,
        });
        break;
      case "colaborador":
        dispatchAction = colaboradorActions.checkDuplicado({
          item: newItem,
          checked: event.target.checked,
        });
        break;
      default:
        dispatchAction = null;
    }
    dispatch(dispatchAction);
  };

  const onResetCheckHandlerDuplicados = (event, item) => {
    let dispatchAction = null;
    switch (subject) {
      case "sucursal":
        dispatchAction = sucursalActions.resetCheckDuplicados();
        break;
      case "area":
        dispatchAction = areaActions.resetCheckDuplicados();
        break;
      case "cargo":
        dispatchAction = cargoActions.resetCheckDuplicados();
        break;
      case "departamento":
        dispatchAction = departamentoActions.resetCheckDuplicados();
        break;
      case "colaborador":
        dispatchAction = colaboradorActions.resetCheckDuplicados();
        break;
      default:
        dispatchAction = null;
    }
    dispatch(dispatchAction);
  };

  const onSelectChangeNuevos = (v, items = []) => {
    if (v.value === "eliminar") {
      let confirmar = window.confirm("Desea eliminar los datos seleccionados?");
      if (confirmar) {
        //data.nuevos
        const newNuevos = getDifference(nuevos, nuevosChecked);
        // return;
        setData({
          ...data,
          nuevos: newNuevos,
        });
        onResetCheckHandlerNuevos();
      }
    } else if (v.value === "cancel") {
      onResetCheckHandlerNuevos();
      //
    } else {
      // @ts-ignore
      window.alert(JSON.stringify(v));
    }
  };

  const onSelectChangeDuplicados = (v, items = []) => {
    if (v.value === "eliminar") {
      //
      console.log("eliminar");
      let confirmar = window.confirm("Desea eliminar los datos seleccionados?");
      if (confirmar) {
        // data.duplicados
        const newDuplicados = getDifference(duplicados, duplicadosChecked);
        console.log("newDuplicados", newDuplicados);
        // return;
        setData({
          ...data,
          duplicados: newDuplicados,
        });
        onResetCheckHandlerDuplicados();
      }
    } else if (v.value === "cancel") {
      onResetCheckHandlerDuplicados();
      //
    } else {
      // @ts-ignore
      window.alert(JSON.stringify(v));
    }
  };

  const onSearchNuevos = (e) => {
    let searchText = e.target.value;
    setSearchNuevos(searchText);
    searchText = searchText.toUpperCase();
    const nuevosCopy = data.nuevos?.filter(
      (nuevo, nidx) =>
        nuevo.nombre.toUpperCase().includes(searchText) ||
        nuevo.apellido?.toUpperCase().includes(searchText)
    );
    setNuevos(nuevosCopy);
  };
  const onSearchDuplicados = (e) => {
    let searchText = e.target.value;
    setSearchDuplicados(searchText);
    searchText = searchText.toUpperCase();
    const duplicadosCopy = data.duplicados?.filter(
      (duplicado, nidx) =>
        duplicado.anterior.nombre.toUpperCase().includes(searchText) ||
        duplicado.anterior.apellido?.toUpperCase().includes(searchText)
    );
    setDuplicados(duplicadosCopy);
  };
  //
  return (
    <div className="px-4 pb-4">
      <div className="body flex flex-col gap-8 items-center justify-center mt-8">
        {/* NUEVOS data.nuevos*/}
        {data.nuevos?.length > 0 && (
          <>
            <div className="flex justify-start w-full md:w-2/3x">
              <div className="text-lg font-bold">
                Nuevos registros a importar
              </div>
            </div>
            <div className="flex justify-center w-full md:w-2/3x">
              {/* data.nuevos */}
              <TableCustom
                ItemComponent={ColaboradorItem}
                data={nuevos?.map((item, idx) => {
                  return { id: idx, ...item };
                })}
                tableSecondTitle={"Lista de registros nuevos"}
                subject={"colaborador"}
                subjectGender={"M"}
                hasAddBtn={false}
                noBottomButtom={true}
                onChangeSearch={onSearchNuevos}
                searchValue={searchNuevos}
                onSelectChange={onSelectChangeNuevos}
                selectOptions={[
                  { value: "eliminar", label: "Eliminar" },
                  { value: "cancel", label: "Cancelar" },
                ]}
                itemsChecked={nuevosChecked.map((item) => item.id)}
                onCheckHandler={onCheckHandlerNuevos}
              />
            </div>
          </>
        )}
        {/* DUPLICADOS */}
        {data.duplicados?.length > 0 && (
          <>
            <div className="flex flex-col justify-start w-full md:w-2/3x">
              <div className="text-lg font-bold">Duplicados</div>
              <p className="text-sm">
                Ya existen registros en la base de datos con los mismos datos
                que intenta importar. ¿Quieres reemplazarlos con los que estás
                importando?
              </p>
            </div>
            <div className="flex justify-center w-full md:w-2/3x">
              {/* data.duplicados */}
              <TableCustom
                ItemComponent={DuplicadoItem}
                data={duplicados?.map((item, idx) => {
                  return { id: item.anterior.id, ...item };
                })}
                tableSecondTitle={"Lista de registros duplicados"}
                subject={"colaborador"}
                subjectGender={"M"}
                hasAddBtn={false}
                noBottomButtom={true}
                onChangeSearch={onSearchDuplicados}
                searchValue={searchDuplicados}
                onSelectChange={onSelectChangeDuplicados}
                selectOptions={[
                  { value: "eliminar", label: "Eliminar" },
                  { value: "cancel", label: "Cancelar" },
                ]}
                itemsChecked={duplicadosChecked.map((item) => item.id)}
                onCheckHandler={onCheckHandlerDuplicados}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default ImportacionEstructura;
