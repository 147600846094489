import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ErrorToast } from "src/modules/common/components/ErrorToast";
import { ProfileInfoCard } from "src/modules/common/components/ProfileInfoCard";
import TableCustom from "src/modules/common/components/TableCustom";
import TableRow from "src/modules/common/components/TableRow";
import { evaluacionActions } from "../handlers/redux";
import AreaColaboradorSelect from "./AreaColaboradorSelect";
import DepartamentoColaboradorSelect from "./DepartamentoColaboradorSelect";
import SucursalColaboradorSelect from "./SucursalColaboradorSelect";
import { commonActions } from "src/modules/common/handler/redux";
import SearchInput from "src/modules/remuneracion/components/SearchInput";
import ReactSelect from "react-select";
import MostrarPaginas from "src/modules/common/components/MostrarPaginas";
import { SucursalSelect } from "src/modules/estructura/modules/sucursal/components/SucursalSelect";
import { AreaSelect } from "src/modules/estructura/modules/area/components/AreaSelect";
import { DepartamentoSelect } from "src/modules/estructura/modules/departamento/components/DepartamentoSelect";
import DataTable from "src/modules/common/components/DataTable";
import userDefault from "src/modules/common/components/images/user_default.png";

function ColaboradoresValidosList() {
  const dispatch = useDispatch();
  const colaboradores_validos = useSelector(
    (state) => state.evaluacion.colaboradores_validos
  );
  const colaboradores_validos_count = useSelector(
    (state) => state.evaluacion.colaboradores_validos_count
  );
  const colaboradores_validos_filter = useSelector(
    (state) => state.evaluacion.colaboradores_validos_filter
  );
  const colaboradores_validos_loading = useSelector(
    (state) => state.evaluacion.colaboradores_validos_loading
  );
  const colaboradores_validos_checked = useSelector(
    (state) => state.evaluacion.colaboradores_validos_checked
  );
  const formularios_checked = useSelector(
    (state) => state.evaluacion.formularios_checked
  );

  const {
    nombre = "",
    sucursal,
    area,
    departamento,
  } = colaboradores_validos_filter;

  const [outerPageSize, setOuterPageSize] = useState(50);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    dispatch(evaluacionActions.getColaboradoresValidos());
  }, []);

  const ItemCommonComponent = ({ item }) => {
    return (
      <TableRow>
        <div
          className="p-4 flex gap-8 items-start justify-start cursor-pointer"
          onClick={() => {}}
        >
          <ProfileInfoCard
            name={`${item.nombre} ${item.apellido}`}
            description={
              item.cargo?.nombre
                ? `Cargo: ${item.cargo?.nombre}`
                : "Cargo: Sin especificar"
            }
            image={item.foto_empleado ? item.foto_empleado : ""}
            nameWidth={"auto"}
          ></ProfileInfoCard>
        </div>
      </TableRow>
    );
  };
  const onCheckHandler = (event, item) => {
    dispatch(
      evaluacionActions.checkColaboradorValido({
        colaborador: item,
        checked: event.target.checked,
      })
    );
  };

  const onChangeSearch = (e) => {
    const searchText = e.target.value;
    dispatch(
      evaluacionActions.setColaboradoresValidosFilter({
        ...colaboradores_validos_filter,
        nombre: searchText,
      })
    );
  };

  const onFilter = (field) => (e) => {
    const value = e.target.value;
    dispatch(
      evaluacionActions.setColaboradoresValidosFilter({
        ...colaboradores_validos_filter,
        [field]: value,
      })
    );
  };

  const actionOptions = [{ value: "cancel", label: "Cancelar" }];

  const onSelectChange = (v, items = []) => {
    if (v.value === "cancel") {
      dispatch(evaluacionActions.resetCheckColaboradoresValidos());
    } else {
      // @ts-ignore
      window.alert(JSON.stringify(v));
    }
  };

  const agregarColaboradores = () => {
    if (colaboradores_validos_checked.length < 1) {
      ErrorToast({ message: "Debe seleccionar al menos un colaborador" });
      return;
    }
    dispatch(commonActions.setSelectModal({ open: false }));
    const colaboradores_validos_checked_ids = colaboradores_validos_checked.map(
      (colaborador) => colaborador.id
    );
    dispatch(
      evaluacionActions.setColaboradoresAgregar(
        colaboradores_validos_checked_ids
      )
    );
  };

  const commonProps = {
    ItemComponent: ItemCommonComponent,
    subject: "colaborador",
    subjectGender: "M",
    hasAddBtn: true,
    customAddBtnLabel: "Agregar colaborador",
    noBottomButtom: true,
    selectOptions: [],
  };

  const renderFilters = () => {
    return (
      <div className="flex gap-4 mb-5">
        <AreaColaboradorSelect></AreaColaboradorSelect>
        <DepartamentoColaboradorSelect></DepartamentoColaboradorSelect>
        <SucursalColaboradorSelect></SucursalColaboradorSelect>
      </div>
    );
  };

  const onPaginate = (page, pageSize) => {
    dispatch(
      evaluacionActions.setColaboradoresValidosFilter({
        ...colaboradores_validos_filter,
        pagination: {
          page: page,
          pageSize: pageSize,
        },
      })
    );
    setCurrentPage(page);
  };

  const onChangePageSize = (e) => {
    let value = e.target.value;
    dispatch(
      evaluacionActions.setColaboradoresValidosFilter({
        ...colaboradores_validos_filter,
        pagination: {
          pageSize: parseInt(value),
          page: 1,
        },
      })
    );
    setOuterPageSize(parseInt(value));
    setCurrentPage(1);
  };

  const onCheckAll = () => {
    let checkedData = JSON.parse(JSON.stringify(colaboradores_validos));
    if (colaboradores_validos_checked.length > 0) {
      checkedData = [];
    }
    dispatch(evaluacionActions.setCheckColaboradoresValidos(checkedData));
  };

  const onCheckColaboradorValido = (item) => (event) => {
    console.log(item);
    dispatch(
      evaluacionActions.checkColaboradorValido({
        colaborador: item,
        checked: event.target.checked,
      })
    );
  };

  const datatableConfig = {
    headers: [
      {
        title: "Lista de integrantes",
        contentClass: "font-bold px-2 py-4",
        // onOrder: setOrdering("nombre"),
        // accessor: "nombre",
        contentRenderer: (item) => (
          <div className="flex flex-row gap-x-2 m-4">
            <img
              className="object-cover h-12 w-12 rounded-md"
              src={item.foto_empleado ? item.foto_empleado : userDefault}
              alt={`Perfil`}
            />
            <div className="flex flex-col gap-1">
              <span className="font-semibold text-base">
                {item.nombre} {item.apellido}
              </span>
              <span className="text-base">
                Cargo: {item.cargo ? item.cargo.nombre : "Sin cargo"}
              </span>
            </div>
          </div>
        ),
      },
    ],
    rowActions: [],
  };
  //
  return (
    <div>
      <div className="flex items-center gap-2 mt-4">
        <SearchInput
          containerClass={`w-1/2`}
          placeHolder={"Buscar por nombre o cargo..."}
          value={nombre}
          onChange={onChangeSearch}
        />
        <MostrarPaginas
          containerClass={"w-1/2x"}
          onChangePageSize={onChangePageSize}
          defaultOption={50}
        />
        <div className="text-sm">
          <ReactSelect
            className="w-52 h-10 bg-blue-200 inline-grid"
            value={{
              value: "",
              label: `(${colaboradores_validos_checked.length}) seleccionados`,
            }}
            options={actionOptions}
            onChange={onSelectChange}
          ></ReactSelect>
        </div>
        <div>
          <button
            onClick={agregarColaboradores}
            className="rounded-md bg-sky-500 text-white p-3"
          >
            Agregar colaborador
          </button>
        </div>
      </div>
      <div className="flex flex-row justify-between my-4 gap-4">
        <SucursalSelect
          onChange={onFilter("sucursal")}
          defaultLabel="Sucursal: (Todas)"
          className={"w-full"}
          value={sucursal}
        />
        <AreaSelect
          onChange={onFilter("area")}
          defaultLabel="Áreas: (Todas)"
          className={"w-full"}
          value={area}
        />
        <DepartamentoSelect
          onChange={onFilter("departamento")}
          defaultLabel="Departamentos: (Todos)"
          className={"w-full"}
          value={departamento}
        />
      </div>
      <div className="custom-scrollbar overflow-auto max-h-[350px] mt-4">
        <DataTable
          containerClass={"my-2"}
          height="h-[280px]"
          config={datatableConfig}
          data={colaboradores_validos}
          loading={colaboradores_validos_loading} // colaboradores_agregar_loading
          noHeaders={false}
          //
          totalRecords={colaboradores_validos_count}
          onPaginate={onPaginate}
          outerPageSize={outerPageSize}
          //
          checkable={true}
          onCheckAll={onCheckAll}
          onItemCheck={onCheckColaboradorValido}
          checkedItems={colaboradores_validos_checked}
        />
      </div>
    </div>
  );
}

export default ColaboradoresValidosList;
