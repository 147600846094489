import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  ClockHistory,
  EyeFill,
  FileEarmarkArrowDownFill,
  XLg,
} from "react-bootstrap-icons";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { colaboradorActions } from "src/modules/colaborador/modules/colaborador/handlers/redux";
import CollapseCard from "src/modules/common/components/CollapseCard";
import { ErrorToast } from "src/modules/common/components/ErrorToast";
import { SuccessToast } from "src/modules/common/components/SuccessToast";
import backButton from "src/modules/common/components/images/back-arrow.png";
import { commonActions } from "src/modules/common/handler/redux";
import { formatNumber, getToken } from "src/utils/helpers";
import { PrimaryButton } from "src/modules/common/components/PrimaryButton";
import SelectModal from "src/modules/common/components/SelectModal";
import KpiColaboradorForm from "src/modules/kpi/pages/KpiColaboradorForm";
import userDefault from "../images/user_default.png";
import { objetivoActions } from "../handlers/redux";
import Modal from "src/modules/common/components/Modal";
import ObjetivoCargoHistorial from "../components/ObjetivoCargoHistorial";

const initialObjetivo = {
  id: null,
  nombre: "",
  descripcion: "",
  periodo: null,
  metrica: null,
  meta: null,
  peso: 0.0,
  rangos: [],
};

export function ObjetivosColaboradorDetailPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const { idColaborador } = params; // Obtiene el id que se recibe desde la url

  const colaborador = useSelector((state) => state.colaborador.colaborador);
  const objetivos = useSelector((state) => state.objetivo.colaboradorObjetivos);
  const colaboradorObjetivos_loading = useSelector(
    (state) => state.objetivo.colaboradorObjetivos_loading
  );

  const [isEditing, setIsEditing] = useState(false);
  const [objetivosData, setObjetivosData] = useState([]);
  const [objetivoForm, setObjetivoForm] = useState(initialObjetivo);
  const [objetivoFormIndex, setObjetivoFormIndex] = useState(null);
  const [objetivoHistory, setObjetivoHistory] = useState([]);
  const [showHistoryModal, setShowHistoryModal] = useState(false);
  //
  const [downloadLoading, setDownloadLoading] = useState(false);

  const onSave = () => {
    let sumaPesos = 0;
    objetivosData.forEach((objetivo, kidx) => (sumaPesos += objetivo.peso));
    if (sumaPesos != 0 && sumaPesos != 100) {
      ErrorToast({ message: "La suma de los pesos debe ser de 100%" });
      return;
    }
    //
    const params = { id: idColaborador, objetivos: objetivosData };
    dispatch(objetivoActions.colaboradorUpdateObjetivos(params));
    //
    setIsEditing(false);
  };

  const onCancel = () => {
    setObjetivosData(objetivos?.results);
    setIsEditing(false);
  };

  const showObjetivoMeta = (objetivo) => {
    if (objetivo.metrica_display === "Selección simple")
      return "Logro/No logro";
    return objetivo.meta
      ? `${formatNumber(objetivo.meta).toLocaleString("de-DE")}`
      : objetivo.rangos.length > 0
      ? "Por escala"
      : "Sin definir";
  };

  const showEscala = (objetivo) => {
    const { rangos } = objetivo;
    return (
      <div className="group flex justify-center items-center border rounded-md p-1">
        <EyeFill></EyeFill>
        <div className="border rounded-md p-2 bg-white z-10 hidden group-hover:block group-hover:absolute bottom-0 left-28">
          <div className="flex gap-2 font-bold text-sm">
            <div className="flex-1">Desde</div>
            <div className="flex-1">Hasta</div>
            <div className="flex-1">Resultado</div>
          </div>
          {rangos.map((r, i) => (
            <div className="flex gap-2 text-sm">
              <div className="flex-1">
                {r.desde ? r.desde.toLocaleString("de-DE") : r.desde}
              </div>
              <div className="flex-1">
                {r.hasta ? r.hasta.toLocaleString("de-DE") : r.hasta}
              </div>
              <div className="flex-1">
                {r.resultado
                  ? r.resultado.toLocaleString("de-DE")
                  : r.resultado}
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const renderCreadoPor = (objetivo) => {
    return (
      <div className="flex flex-row gap-1 items-center">
        {!objetivo.creado_por?.foto_empleado && (
          <div className="h-8 w-8 rounded-lg bg-zinc-200"></div>
        )}
        {!!objetivo.creado_por?.foto_empleado && (
          <div className="w-8 bg-slate-200 rounded-md">
            <img
              className="object-cover h-8 w-8 rounded-md"
              src={objetivo.creado_por?.foto_empleado}
              alt={`Foto Jefe de Lider`}
            />
          </div>
        )}
        <div className="font-semibold">
          {objetivo.creado_por
            ? `${objetivo.creado_por.nombre} ${objetivo.creado_por.apellido}`
            : "Sin registro"}
        </div>
      </div>
    );
  };

  const onCloseHistoryModal = () => {
    setObjetivoHistory(null);
    setShowHistoryModal(false);
  };

  useEffect(() => {
    dispatch(commonActions.setTitleMenu([]));
    dispatch(commonActions.setTitle(""));
    dispatch(commonActions.showSidebar(false));
    //
    if (idColaborador) {
      let item = { id: idColaborador };
      dispatch(colaboradorActions.colaboradorGet(item));
      dispatch(objetivoActions.getColaboradorObjetivos(item));
    }
  }, []);
  const { results: kpisResults = [] } = objetivos;

  useEffect(() => {
    setObjetivosData(kpisResults);
  }, [kpisResults]);

  const ObjetivoSkeletons = () => {
    return (
      <div className="flex flex-col gap-3 animate-pulse mt-4">
        <div className="h-20 border rounded-md bg-slate-200"></div>
        <div className="h-20 border rounded-md bg-slate-200"></div>
        <div className="h-20 border rounded-md bg-slate-200"></div>
      </div>
    );
  };

  const onAgregarObjetivo = () => {
    setObjetivoForm(initialObjetivo);
    setObjetivoFormIndex(null);
    dispatch(commonActions.setSelectModal({ open: true }));
  };

  const agregarObjetivo = (index) => (objetivo) => {
    let objetivosDataCopy = JSON.parse(JSON.stringify(objetivosData));
    //
    if (!!index || index === 0) {
      objetivosDataCopy[index] = objetivo;
    } else {
      objetivosDataCopy.push({ ...objetivo });
    }
    setObjetivosData(objetivosDataCopy);
    setObjetivoForm(initialObjetivo);
    dispatch(commonActions.setSelectModal({ open: false }));
    SuccessToast({
      message: !!index ? "Objetivo editado" : "Objetivo agregado al listado",
    });
  };

  const renderObjetivosColaboradorList = () => {
    const onChangePeso = (index) => (e) => {
      const value = Number(e.target.value);
      if (value < 0 || value > 100) {
        return;
      }
      let objetivosDataCopy = JSON.parse(JSON.stringify(objetivosData));
      objetivosDataCopy[index].peso = value;
      setObjetivosData(objetivosDataCopy);
    };
    const onEditar = (objetivo, index) => () => {
      setObjetivoForm(objetivo);
      setObjetivoFormIndex(index);
      dispatch(commonActions.setSelectModal({ open: true }));
    };
    const onDeleteObjetivo = (id) => () => {
      let deseaEliminar = true;
      if (!!deseaEliminar) {
        deseaEliminar = window.confirm(
          "¿Está seguro de eliminar este objetivo?"
        );
      }
      if (!!deseaEliminar) {
        const newObjetivos = objetivosData.filter(
          (objetivo, kidx) => kidx !== id
        );
        setObjetivosData(newObjetivos);
      }
    };
    const onVerHistorial = (objetivo) => () => {
      setObjetivoHistory(objetivo);
      setShowHistoryModal(true);
    };
    //
    return objetivosData.map((objetivo, kidx) => (
      <CollapseCard
        key={`obj-${kidx}-${objetivo.id}`}
        titulo={objetivo.nombre}
        extraClass="mb-8"
        titleClass={"font-semibold"}
        initCollapse={false}
        extraEndContent={
          true ? (
            <div className="flex justify-end items-center gap-2 text-sm">
              <input
                disabled={!isEditing}
                type="number"
                value={objetivo.peso}
                onChange={onChangePeso(kidx)}
                className="bg-slate-300x border border-slate-400 outline-none rounded-md p-2 w-2/3"
              />
              <div>%</div>
            </div>
          ) : (
            ""
          )
        }
      >
        <div className="flex flex-col gap-2">
          <div>{objetivo.descripcion}</div>
          <div className="flex">
            <div className="flex flex-col flex-1">
              <div className="font-semibold">Tiempo</div>
              <div>{objetivo.periodo_display}</div>
            </div>
            <div className="flex flex-col flex-1">
              <div className="font-semibold">Tipo de metrica</div>
              <div>{objetivo.metrica_display}</div>
            </div>
          </div>
          <div className="flex relative">
            <div className="flex flex-col flex-1">
              <div className="font-semibold">Meta</div>
              <div className="flex gap-2">
                {showObjetivoMeta(objetivo)}
                {showObjetivoMeta(objetivo) === "Por escala" && (
                  <div>{showEscala(objetivo)}</div>
                )}
              </div>
            </div>
            <div className="flex flex-col flex-1">
              <div className="font-semibold">Creado por</div>
              {renderCreadoPor(objetivo)}
            </div>
          </div>
        </div>
        {!!isEditing && (
          <div className="flex mt-4 gap-2">
            <button
              type="button"
              className="rounded bg-sky-500 text-white px-3 py-1 text-sm"
              onClick={onEditar(objetivo, kidx)}
            >
              Editar
            </button>
            <button
              type="button"
              className="rounded bg-red-500 text-white px-3 py-1 text-sm"
              onClick={onDeleteObjetivo(kidx)}
            >
              Eliminar
            </button>
          </div>
        )}
        {!isEditing && (
          <div className="flex mt-4 gap-2">
            <button
              onClick={onVerHistorial(objetivo)}
              className="text-sm text-sky-500 flex item-center gap-1"
            >
              <ClockHistory className="mt-1" />
              <span>Ver historial</span>
            </button>
          </div>
        )}
      </CollapseCard>
    ));
  };

  const downloadPDF = () => {
    const token = getToken();
    const URL_BASE =
      process.env.REACT_APP_API_URL || "http://localhost:8000/api/admin";
    setDownloadLoading(true);
    axios({
      url:
        URL_BASE +
        `/objetivo-cargo/objetivo-cargo-colaborador/colaborador/${idColaborador}/exportar`,
      method: "POST",
      responseType: "blob", // Indica que la respuesta será un archivo binario
      headers: {
        Authorization: `Bearer ${token}`, // Agregamos la cabecera de autorización con el token Bearer
      },
    })
      .then((response) => {
        const downloadUrl = window.URL.createObjectURL(
          new Blob([response.data])
        );
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.setAttribute(
          "download",
          `colaborador-${idColaborador}-objetivos.pdf`
        );
        document.body.appendChild(link);
        link.click();
        link.remove();
        setDownloadLoading(false);
      })
      .catch((error) => {
        console.error("Error al descargar el archivo PDF:", error);
        setDownloadLoading(false);
      });
  };

  const discardChanges = () => {
    let deseaSalir = window.confirm("¿Desea salir sin guardar los cambios?");
    return deseaSalir;
  };

  return (
    <>
      {/* HEADER */}
      <div className="flex justify-between items-center bg-zinc-100 -mx-8 py-2.5 px-7 border-b">
        <div className="flex items-center">
          <div
            onClick={() => navigate("/objetivos/cargos")}
            className={
              "h-full flex items-center pr-2 -mr-2 z-10 cursor-pointer"
            }
          >
            <img className="back" src={backButton} alt="Regresar" />
          </div>
          <div className="font-medium px-4 text-xl">
            Objetivos del colaborador
          </div>
        </div>
      </div>
      {/*  */}
      <div className="flex justify-center items-center w-full mt-10">
        <div className="flex flex-col w-full md:w-5/6">
          <div className="grid grid-cols-1 md:grid-cols-12">
            {/* IDENTIFICADOR VISUAL */}
            <div className="col-span-1 md:col-span-4 flex flex-col  mb-4 md:mr-12">
              <span className="font-bold text-lg border-b mb-2 pb-5">
                Identificador del colaborador
              </span>
              {!colaborador.foto_empleado && (
                <div className="my-4">
                  <img src={userDefault} alt="Imagen del colaborador" />
                </div>
              )}
              {!!colaborador.foto_empleado && (
                <div className="w-1/2 bg-slate-200 rounded-md">
                  <img
                    className="object-cover h-full w-full rounded-md"
                    src={colaborador.foto_empleado}
                    alt={`pfp-${colaborador.id}`}
                  />
                </div>
              )}
              <div className="flex flex-col mt-4">
                <label className="font-bold font-md" htmlFor="">
                  {`${colaborador.nombre} ${colaborador.apellido}`}
                </label>
                <div>{`Cargo: ${
                  colaborador.cargo?.nombre || "Sin asignar"
                }`}</div>
              </div>
              {/*  */}
              <div className="flex">
                {!downloadLoading && (
                  <button
                    onClick={downloadPDF}
                    className="bg-sky-500 rounded-md text-white p-2 flex items-center gap-2"
                  >
                    <FileEarmarkArrowDownFill size={18} />
                    <span className="font-semibold">Exportar PDF</span>
                  </button>
                )}
                {!!downloadLoading && (
                  <button className="bg-sky-500 rounded-md text-white p-2 animate-pulse">
                    Generando informe...
                  </button>
                )}
              </div>
            </div>
            {/* Objetivos Asignados */}
            <div className="col-span-1 md:col-span-8 ">
              <div className="flex justify-between items-center border-b mb-2 pb-2">
                <div className="font-bold text-lg">
                  Objetivos por cargo asignados al colaborador
                </div>
                {!colaboradorObjetivos_loading && (
                  <div className="flex gap-2">
                    {!isEditing && (
                      <PrimaryButton
                        type="button"
                        onClick={() => setIsEditing(true)}
                      >
                        {!!objetivos?.results && objetivos?.results?.length > 0
                          ? "Editar"
                          : "Agregar"}
                      </PrimaryButton>
                    )}
                    {!!isEditing && (
                      <>
                        <PrimaryButton type="button" onClick={onSave}>
                          Guardar
                        </PrimaryButton>
                        <PrimaryButton
                          className="bg-red-500"
                          onClick={onCancel}
                        >
                          Cancelar
                        </PrimaryButton>
                      </>
                    )}
                  </div>
                )}
              </div>
              <div className="flex items-center justify-end text-lg mb-2 my-4">
                <div>
                  <span className="font-semibold">Total pesos: </span>
                  {objetivosData.reduce(
                    (acumulador, objeto) => acumulador + objeto.peso,
                    0
                  )}
                  %
                </div>
              </div>
              {/* Objetivos COLABORADOR */}
              {!colaboradorObjetivos_loading &&
                objetivosData.length > 0 &&
                renderObjetivosColaboradorList()}
              {!colaboradorObjetivos_loading && objetivosData.length < 1 && (
                <div className="border rounded-md p-4 text-center my-2">
                  No existen objetivos para mostrar
                </div>
              )}
              {!!colaboradorObjetivos_loading && <ObjetivoSkeletons />}
              {!!isEditing && !colaboradorObjetivos_loading && (
                <div
                  className="flex flex-row gap-2 items-center pl-3"
                  onClick={onAgregarObjetivo}
                >
                  <div className="w-5 h-5 rounded-full bg-sky-500 text-white flex items-center justify-center text-2xl">
                    +
                  </div>
                  <div className="text-sky-500 text-sm">{`Agregar ${
                    objetivos?.results?.length > 0 ? "otro" : ""
                  } objetivo`}</div>
                </div>
              )}
              <SelectModal
                title={`${!!objetivoForm.id ? "Editar" : "Agregar"} objetivo`}
                content={
                  <div className="max-h-[500px] overflow-auto custom-scrollbar">
                    <KpiColaboradorForm
                      kpiColaborador={objetivoForm}
                      onSave={agregarObjetivo(objetivoFormIndex)}
                    />
                  </div>
                }
                onClose={() => discardChanges()}
              />
              {/*  */}
              <Modal isOpen={showHistoryModal}>
                <div className="rounded-md bg-white p-4">
                  <div className="w-100 flex justify-between mb-4">
                    <div className="font-semibold text-xl">
                      Historial de objetivo
                    </div>
                    <div onClick={onCloseHistoryModal}>
                      <XLg />
                    </div>
                  </div>
                  {/*  */}
                  <ObjetivoCargoHistorial {...objetivoHistory} />
                </div>
              </Modal>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
