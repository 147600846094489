import { CheckInput } from "src/modules/common/components/CheckInput";

function PesoInput({
  key,
  title = "Titulo",
  dotColorClass = "bg-yellow-500",
  description = "Descripcion",
  value = 0,
  onChangeInput,
  checked = false,
  onChangeCheck,
  inputIsVisible = true,
}) {
  //
  return (
    <div key={key}>
      <div className="flex flex-col gap-2">
        <div className="flex gap-2">
          <div className="mt-1">
            <CheckInput
              onChange={onChangeCheck}
              checked={checked}
              className="w-5 h-5"
            ></CheckInput>
          </div>
          <div className="border-b pb-4 w-full">
            <div className="flex gap-2 items-center">
              <div className={`${dotColorClass} h-3 w-3 rounded-full`}></div>
              <h4 className="font-semibold">{title}</h4>
            </div>
            <p className="text-sm text-slate-400">{description}</p>
            {!!inputIsVisible && (
              <div className="flex flex-col mt-2">
                <label className="font-semibold">Peso</label>
                <div
                  className={`w-24 border rounded-md pl-1${
                    checked ? "" : " bg-slate-200"
                  }`}
                >
                  <div className="flex items-center">
                    <input
                      type="text"
                      className="block p-2 flex-1x w-2/3 focus:outline-none"
                      value={value}
                      onChange={onChangeInput}
                      disabled={!checked}
                      maxLength={3}
                    />
                    <div>%</div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default PesoInput;
