import {
  CircularProgressbar,
  CircularProgressbarWithChildren,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

function PercentageCircular(props) {
  const {
    width = 150,
    height = 150,
    content,
    strokeColor = "blue",
    value = 0,
  } = props;
  const getStrokeColor = () => {
    let strokeRgba = `rgba(255, 0, 0, 1)`;
    switch (strokeColor) {
      case "blue":
        strokeRgba = `rgba(2, 165, 255, 1)`;
        break;
      case "red":
        strokeRgba = `rgba(223, 58, 51, 1)`;
        break;
      case "yellow":
        strokeRgba = `rgba(255, 168, 0, 1)`;
        break;
      case "green":
        strokeRgba = `rgba(30, 176, 0, 1)`;
        break;
      default:
        strokeRgba = `rgba(255, 0, 0, 1)`;
    }
    return strokeRgba;
  };
  return (
    <div style={{ width: width, height: height }}>
      {!content && (
        <CircularProgressbar
          {...props}
          styles={{
            path: {
              // Path color
              stroke: `${getStrokeColor()}`,
            },
          }}
        />
      )}
      {!!content && (
        <CircularProgressbarWithChildren
          {...props}
          styles={{
            path: {
              // Path color
              stroke: `${getStrokeColor()}`,
            },
          }}
        >
          {content}
        </CircularProgressbarWithChildren>
      )}
    </div>
  );
}

export default PercentageCircular;
