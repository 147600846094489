import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { commonActions } from "src/modules/common/handler/redux";

function PlanCarreraLayout({ children }) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(commonActions.setTitle("Planes de carrera"));
    dispatch(commonActions.showSidebar(true));
    dispatch(
      commonActions.setTitleMenu([
        {
          name: "Familias",
          to: "/plan-carrera/familias",
        },
        {
          name: "Requisitos por cargo",
          to: "/plan-carrera/requisitoscargo",
        },
        // {
        //   name: "Vacancias",
        //   to: "/plan-carrera/vacancias",
        // },
      ])
    );
    return () => {
      commonActions.setTitleMenu([]);
    };
  }, []);

  return <>{children}</>;
}

export default PlanCarreraLayout;
