import { createSlice } from "@reduxjs/toolkit";

const feedbackSlice = createSlice({
  name: "feedback",
  initialState: {
    // Crear o actualizar un feedback
    feedback: {},
    feedback_loading: false,
    // Listar los feedbacks
    feedbacks: [],
    feedbacks_loading: false,
    feedbacks_count: 0,
    feedbacks_filter: {},
    feedbacks_checked: [],
    // Jefes directos existentes en la nómina original
    jefes_directos: [],
    jefes_directos_loading: false,
    jefes_directos_count: 0,
    jefes_directos_filter: {},
    jefes_directos_checked: [],
    // Jefes directos relacionados a un feedback
    jefes_directos_list: [],
    jefes_directos_list_loading: false,
    jefes_directos_list_count: 0,
    jefes_directos_list_filter: {},
    jefes_directos_list_checked: [],
    // Colaboradores de un Jefe Directo
    colaboradores: [],
    colaboradores_loading: false,
    colaboradores_count: 0,
    colaboradores_filter: {},
    colaboradores_checked: [],
    // Datos de un colaborador en específico (ColaboradorFeedback)
    colaborador_feedback: {},
    colaborador_feedback_loading: false,
    // Datos de un jefe directo en específico (ColaboradorFeedback)
    jefe_directo: {},
    jefe_directo_loading: false,
    // Los detalles del feedback que se le hace a un colaborador
    feedback_nomina: {},
    feedback_nomina_loading: false,
    // Los kpis del colaborador que se le hará el feedback para listarlos en el modal en etapa Jefe Directo
    kpis_colaborador: [],
    kpis_colaborador_loading: false,
    //
    competencias_generales: [],
    competencias_generales_loading: false,
    //
    competencias_areas: [],
    competencias_areas_loading: false,
    //
    competencias_cargos: [],
    competencias_cargos_loading: false,
    // Listar los feedbacks por jefe directo
    feedbacks_por_jefe: [],
    feedbacks_por_jefe_loading: false,
    feedbacks_por_jefe_count: 0,
    feedbacks_por_jefe_filter: {},
    feedbacks_por_jefe_checked: [],
    // Para guardar ids recibidos por params en caso que se necesiten
    feedback_id: 0,
    jefe_directo_id: 0,
    colaborador_id: 0,
    //
    cambiar_jefe_directo_body: {},
  },
  reducers: {
    //
    setFeedback: (state, { payload = {} }) => {
      state.feedback = payload;
      state.feedback_loading = false;
    },
    getFeedback: (state, { payload = {} }) => {
      state.feedback_loading = true;
    },
    createFeedback: (state, { payload = {} }) => {},
    updateFeedback: (state, { payload = {} }) => {},
    bulkDeleteFeedback: (state, { payload = {} }) => {},
    //
    setJefesDirectos: (state, { payload = {} }) => {
      state.jefes_directos = payload;
      state.jefes_directos_loading = false;
    },
    getJefesDirectos: (state, { payload = {} }) => {
      state.jefes_directos_loading = true;
    },
    bulkDeleteJefesDirectos: (state, { payload = {} }) => {},
    setJefesDirectosCount: (state, { payload = {} }) => {
      state.jefes_directos_count = payload;
    },
    setJefesDirectosFilter: (state, { payload = {} }) => {
      state.jefes_directos_filter = payload;
    },
    setCheckJefesDirectos: (state, { payload = {} }) => {
      state.jefes_directos_checked = payload;
    },
    resetChecksJefesDirectos: (state, { payload = {} }) => {
      state.jefes_directos_checked = [];
    },
    checkJefesDirectos: (
      state,
      { payload: { jefeDirecto, checked = false } }
    ) => {
      state.jefes_directos_checked = state.jefes_directos_checked.filter(
        (jefeDirectoAux) => jefeDirectoAux.id !== jefeDirecto.id
      );
      if (checked) {
        state.jefes_directos_checked.push(jefeDirecto);
      }
    },
    //
    setJefesDirectosList: (state, { payload = {} }) => {
      state.jefes_directos_list = payload;
      state.jefes_directos_list_loading = false;
    },
    getJefesDirectosList: (state, { payload = {} }) => {
      state.jefes_directos_list_loading = true;
    },
    setJefesDirectosListCount: (state, { payload = {} }) => {
      state.jefes_directos_list_count = payload;
    },
    setJefesDirectosListFilter: (state, { payload = {} }) => {
      state.jefes_directos_list_filter = payload;
    },
    setCheckJefesDirectosList: (state, { payload = {} }) => {
      state.jefes_directos_list_checked = payload;
    },
    resetChecksJefesDirectosList: (state, { payload = {} }) => {
      state.jefes_directos_list_checked = [];
    },
    checkJefesDirectosList: (
      state,
      { payload: { jefeDirectoList, checked = false } }
    ) => {
      state.jefes_directos_list_checked =
        state.jefes_directos_list_checked.filter(
          (jefeDirectoAux) => jefeDirectoAux.id !== jefeDirectoList.id
        );
      if (checked) {
        state.jefes_directos_list_checked.push(jefeDirectoList);
      }
    },
    //
    setFeedbacks: (state, { payload = {} }) => {
      state.feedbacks = payload;
      state.feedbacks_loading = false;
    },
    getFeedbacks: (state, { payload = {} }) => {
      state.feedbacks_loading = true;
    },
    setFeedbacksCount: (state, { payload = {} }) => {
      state.feedbacks_count = payload;
    },
    setFeedbacksFilter: (state, { payload = {} }) => {
      state.feedbacks_filter = payload;
    },
    setCheckFeedbacks: (state, { payload = {} }) => {
      state.feedbacks_checked = payload;
    },
    resetChecksFeedbacks: (state, { payload = {} }) => {
      state.feedbacks_checked = [];
    },
    checkFeedbacks: (state, { payload: { feedback, checked = false } }) => {
      state.feedbacks_checked = state.feedbacks_checked.filter(
        (feedbackAux) => feedbackAux.id !== feedback.id
      );
      if (checked) {
        state.feedbacks_checked.push(feedback);
      }
    },
    //
    setColaboradores: (state, { payload = {} }) => {
      state.colaboradores = payload;
      state.colaboradores_loading = false;
    },
    getColaboradores: (state, { payload = {} }) => {
      state.colaboradores_loading = true;
    },
    bulkDeleteColaboradores: (state, { payload = {} }) => {},
    setColaboradoresCount: (state, { payload = {} }) => {
      state.colaboradores_count = payload;
    },
    setColaboradoresFilter: (state, { payload = {} }) => {
      state.colaboradores_filter = payload;
    },
    setCheckColaboradores: (state, { payload = {} }) => {
      state.colaboradores_checked = payload;
    },
    resetChecksColaboradores: (state, { payload = {} }) => {
      state.colaboradores_checked = [];
    },
    checkColaboradores: (
      state,
      { payload: { colaborador, checked = false } }
    ) => {
      state.colaboradores_checked = state.colaboradores_checked.filter(
        (colaboradorAux) => colaboradorAux.id !== colaborador.id
      );
      if (checked) {
        state.colaboradores_checked.push(colaborador);
      }
    },
    //
    setColaboradorFeedback: (state, { payload = {} }) => {
      state.colaborador_feedback = payload;
      state.colaborador_feedback_loading = false;
    },
    getColaboradorFeedback: (state, { payload = {} }) => {
      state.colaborador_feedback_loading = true;
    },
    //
    setJefeDirecto: (state, { payload = {} }) => {
      state.jefe_directo = payload;
      state.jefe_directo_loading = false;
    },
    getJefeDirecto: (state, { payload = {} }) => {
      state.jefe_directo_loading = true;
    },
    //
    setFeedbackNomina: (state, { payload = {} }) => {
      state.feedback_nomina = payload;
      state.feedback_nomina_loading = false;
    },
    getFeedbackNomina: (state, { payload = {} }) => {
      state.feedback_nomina_loading = true;
    },
    createFeedbackNomina: (state, { payload = {} }) => {},
    //
    setKpisColaborador: (state, { payload = {} }) => {
      state.kpis_colaborador = payload;
      state.kpis_colaborador_loading = false;
    },
    getKpisColaborador: (state, { payload = {} }) => {
      state.kpis_colaborador_loading = true;
    },
    //
    setCompetenciasGenerales: (state, { payload = {} }) => {
      state.competencias_generales = payload;
      state.competencias_generales_loading = false;
    },
    getCompetenciasGenerales: (state, { payload = {} }) => {
      state.competencias_generales_loading = true;
    },
    //
    setCompetenciasAreas: (state, { payload = {} }) => {
      state.competencias_areas = payload;
      state.competencias_areas_loading = false;
    },
    getCompetenciasAreas: (state, { payload = {} }) => {
      state.competencias_areas_loading = true;
    },
    //
    setCompetenciasCargos: (state, { payload = {} }) => {
      state.competencias_cargos = payload;
      state.competencias_cargos_loading = false;
    },
    getCompetenciasCargos: (state, { payload = {} }) => {
      state.competencias_cargos_loading = true;
    },
    //
    setFeedbacksPorJefe: (state, { payload = {} }) => {
      state.feedbacks_por_jefe = payload;
      state.feedbacks_por_jefe_loading = false;
    },
    getFeedbacksPorJefe: (state, { payload = {} }) => {
      state.feedbacks_por_jefe_loading = true;
    },
    setFeedbacksPorJefeCount: (state, { payload = {} }) => {
      state.feedbacks_por_jefe_count = payload;
    },
    setFeedbacksPorJefeFilter: (state, { payload = {} }) => {
      state.feedbacks_por_jefe_filter = payload;
    },
    setCheckFeedbacksPorJefe: (state, { payload = {} }) => {
      state.feedbacks_por_jefe_checked = payload;
    },
    resetChecksFeedbacksPorJefe: (state, { payload = {} }) => {
      state.feedbacks_por_jefe_checked = [];
    },
    checkFeedbacksPorJefe: (
      state,
      { payload: { feedback, checked = false } }
    ) => {
      state.feedbacks_por_jefe_checked =
        state.feedbacks_por_jefe_checked.filter(
          (feedbackAux) => feedbackAux.id !== feedback.id
        );
      if (checked) {
        state.feedbacks_por_jefe_checked.push(feedback);
      }
    },
    //
    setFeedbackId: (state, { payload = {} }) => {
      state.feedback_id = payload;
    },
    setJefeDirectoId: (state, { payload = {} }) => {
      state.jefe_directo_id = payload;
    },
    setColaboradorId: (state, { payload = {} }) => {
      state.colaborador_id = payload;
    },
    //
    cambiarJefeDirecto: (state, { payload = {} }) => {},
    setCambiarJefeDirectoBody: (state, { payload = {} }) => {
      state.cambiar_jefe_directo_body = payload;
    },
  },
});

export const feedbackActions = feedbackSlice.actions;
export const feedbackReducer = feedbackSlice.reducer;
