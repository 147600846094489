import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { commonActions } from "src/modules/common/handler/redux";

function RemuneracionLayout({ children }) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(commonActions.setTitle("Remuneraciones"));
    dispatch(
      commonActions.setTitleMenu([
        // {
        //   name: "Incrementos salariales",
        //   to: "/remuneracion/incrementos",
        // },
        {
          name: "Escala salarial",
          to: "/remuneracion/escala",
        },
        {
          name: "Salarios",
          to: "/remuneracion/salarios",
        },
        {
          name: "Salarios de mercado",
          to: "/remuneracion/salarios-mercado",
        },
      ])
    );
    return () => {
      commonActions.setTitleMenu([]);
    };
  }, []);
  return <>{children}</>;
}

export default RemuneracionLayout;
