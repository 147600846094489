import { all } from "redux-saga/effects";
import { competenciaAreaSagas } from "src/modules/competencia/modules/area/handlers/sagas";
import { competenciaCargoSagas } from "src/modules/competencia/modules/cargo/handlers/sagas";
import { competenciaGeneralSagas } from "src/modules/competencia/modules/general/handlers/sagas";

export function* competenciaRootSagas() {
  yield all([
    competenciaGeneralSagas(),
    competenciaAreaSagas(),
    competenciaCargoSagas(),
  ]);
}
