import { useEffect, useState } from "react";
import { FeedbackConfiguracionLayout } from "../components/FeedbackConfiguracionLayout";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { commonActions } from "src/modules/common/handler/redux";
import { SucursalSelect } from "src/modules/estructura/modules/sucursal/components/SucursalSelect";
import { AreaSelect } from "src/modules/estructura/modules/area/components/AreaSelect";
import { DepartamentoSelect } from "src/modules/estructura/modules/departamento/components/DepartamentoSelect";
import { sucursalActions } from "src/modules/estructura/modules/sucursal/handlers/redux";
import { areaActions } from "src/modules/estructura/modules/area/handlers/redux";
import { departamentoActions } from "src/modules/estructura/modules/departamento/handlers/redux";
import SearchInput from "src/modules/remuneracion/components/SearchInput";
import DataTable from "src/modules/common/components/DataTable";
import userDefault from "src/modules/common/components/images/user_default.png";
import { ChevronRight, X } from "react-bootstrap-icons";
import { feedbackActions } from "../handlers/redux";
import { feedbackEtapasName } from "../handlers/routes";
import ReactSelect from "react-select";
import MostrarPaginas from "src/modules/common/components/MostrarPaginas";
import { ErrorToast } from "src/modules/common/components/ErrorToast";
import Modal from "src/modules/common/components/Modal";
import JefesDirectosCambio from "../components/JefesDirectosCambio";

export function FeedbackJefeDirectoDetalle() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const params = useParams();
  const { idFeedback, idJefeDirecto } = params;

  const jefe_directo = useSelector((state) => state.feedback.jefe_directo);
  const jefe_directo_loading = useSelector(
    (state) => state.feedback.jefe_directo_loading
  );
  const colaboradores = useSelector((state) => state.feedback.colaboradores);
  const colaboradores_loading = useSelector(
    (state) => state.feedback.colaboradores_loading
  );
  const colaboradores_count = useSelector(
    (state) => state.feedback.colaboradores_count
  );
  const colaboradores_filter = useSelector(
    (state) => state.feedback.colaboradores_filter
  );
  const colaboradores_checked = useSelector(
    (state) => state.feedback.colaboradores_checked
  );
  const feedback_id = useSelector((state) => state.feedback.feedback_id);
  const cambiar_jefe_directo_body = useSelector(
    (state) => state.feedback.cambiar_jefe_directo_body
  );

  const { nombre = "", sucursal, area, departamento } = colaboradores_filter;
  const [outerPageSize, setOuterPageSize] = useState(10);
  const [openJefesDirectos, setOpenJefesDirectos] = useState(false);

  useEffect(() => {
    dispatch(commonActions.setTitle("Admin"));
    dispatch(commonActions.showSidebar(false));
    dispatch(sucursalActions.sucursales());
    dispatch(areaActions.areas());
    dispatch(departamentoActions.departamentos());
    dispatch(feedbackActions.getJefeDirecto(idJefeDirecto));
    dispatch(
      feedbackActions.getColaboradores({
        idFeedback,
        idJefeDirecto,
      })
    );
    if (idFeedback) {
      dispatch(feedbackActions.setFeedbackId(idFeedback));
    }
    dispatch(feedbackActions.setCambiarJefeDirectoBody({}));
  }, []);

  const onClickBack = () => {
    navigate("/feedback/inicio/" + idFeedback + "/");
  };

  const onFilter = (field) => (e) => {
    const value = e.target.value;
    dispatch(
      feedbackActions.setColaboradoresFilter({
        ...colaboradores_filter,
        idFeedback: idFeedback,
        idJefeDirecto: idJefeDirecto,
        [field]: value,
      })
    );
  };

  const onCambiarJefeDirecto = (item) => {
    dispatch(
      feedbackActions.setCambiarJefeDirectoBody({
        ...cambiar_jefe_directo_body,
        colaboradores: [item.id],
      })
    );
    setOpenJefesDirectos(true);
  };

  const onDeleteFeedback = (id) => {
    const deseaEliminar = window.confirm(
      "¿Está seguro de eliminar al colaborador seleccionado de este Feedback?"
    );
    if (!!deseaEliminar) {
      const params = {
        feedback_id: idFeedback,
        jefe_directo_id: idJefeDirecto,
        colaboradores_ids: [id],
      };
      dispatch(feedbackActions.bulkDeleteColaboradores(params));
    }
  };

  const goToDetail = (item) => {
    const { id, estado } = item;
    // Se le resta 1 porque los indices del array empiezan en 0 y los estados del feedback desde 1
    const etapa = feedbackEtapasName[estado - 1];
    navigate(
      `/feedback/${idFeedback}/jefe-directo/${idJefeDirecto}/colaborador/${id}/${etapa}`
    );
  };

  const datatableConfig = {
    headers: [
      {
        title: "Colaboradores",
        contentClass: "font-bold px-2 py-4",
        // onOrder: setOrdering("nombre"),
        // accessor: "nombre",
        contentRenderer: (item) => (
          <div className="flex flex-row gap-x-2 m-4">
            <img
              className="object-cover h-12 w-12 rounded-md"
              src={item.foto_empleado ? item.foto_empleado : userDefault}
              alt={`Perfil`}
            />
            <div className="flex flex-col gap-1">
              <span className="font-semibold text-base">
                {item.nombre} {item.apellido}
              </span>
              <span className="text-base">
                Cargo: {item.cargo ? item.cargo.nombre : "Sin cargo"}
              </span>
            </div>
          </div>
        ),
      },
      {
        title: "Sucursal",
        contentClass: "font-bold px-2 py-4",
        // onOrder: setOrdering(""),
        // accessor: "nombre",
        contentRenderer: (item) => (
          <div className="flex flex-col">
            <span className="">
              {item.sucursal ? item.sucursal.nombre : "Sin sucursal"}
            </span>
          </div>
        ),
      },
      {
        title: "Área",
        contentClass: "font-bold px-2 py-4",
        // onOrder: setOrdering(""),
        // accessor: "nombre",
        contentRenderer: (item) => (
          <div className="flex flex-col">
            <span className="">
              {item.area ? item.area.nombre : "Sin área"}
            </span>
          </div>
        ),
      },
      {
        title: "Departamento",
        contentClass: "font-bold px-2 py-4",
        // onOrder: setOrdering(""),
        // accessor: "nombre",
        contentRenderer: (item) => (
          <div className="flex flex-col">
            <span className="">
              {item.departamento
                ? item.departamento.nombre
                : "Sin departamento"}
            </span>
          </div>
        ),
      },
      {
        title: "Avance",
        contentClass: "font-bold px-2 py-4",
        // onOrder: setOrdering(""),
        // accessor: "nombre",
        contentRenderer: (item) => (
          <div className="flex flex-col">
            <div className="flex flex-row align-middle gap-1">
              <span>{item.porcentaje_avance} %</span>
            </div>
          </div>
        ),
      },
      {
        title: "",
        contentClass: "font-bold px-2 py-4",
        // onOrder: setOrdering(""),
        // accessor: "nombre",
        contentRenderer: (item) => (
          <div className="cursor-pointer">
            <ChevronRight
              color="#0ea4e9"
              onClick={() => goToDetail(item)}
            ></ChevronRight>
          </div>
        ),
      },
    ],
    rowActions: [
      {
        label: "Cambiar Jefe Directo Feedback",
        action: (item) => {
          onCambiarJefeDirecto(item);
        },
      },
      {
        label: "Eliminar",
        action: (item) => {
          onDeleteFeedback(item.id);
        },
      },
    ],
  };

  const onPaginate = (page, pageSize) => {
    dispatch(
      feedbackActions.setColaboradoresFilter({
        ...colaboradores_filter,
        pagination: {
          page: page,
          pageSize: pageSize,
        },
        idFeedback: idFeedback,
        idJefeDirecto: idJefeDirecto,
      })
    );
  };

  const onCheckAll = () => {
    let checkedData = JSON.parse(JSON.stringify(colaboradores));
    if (colaboradores_checked.length > 0) {
      checkedData = [];
    }
    dispatch(feedbackActions.setCheckColaboradores(checkedData));
  };

  const onCheckColaborador = (item) => (event) => {
    console.log(item);
    dispatch(
      feedbackActions.checkColaboradores({
        colaborador: item,
        checked: event.target.checked,
      })
    );
  };

  const actionOptions = [
    {
      value: "delete",
      label: "Eliminar",
      // action: () => {},
    },
    {
      value: "cambiarJefeDirecto",
      label: "Cambiar Jefe Directo Feedback",
      // action: () => {},
    },
  ];

  const onAction = (v) => {
    if (v.value === "delete") {
      const deseaEliminar = window.confirm(
        "¿Está seguro de eliminar a los colaboradores seleccionados de este Feedback?"
      );
      if (!!deseaEliminar) {
        const colaboradores_ids = colaboradores_checked.map(
          (colaborador) => colaborador.id
        );
        const params = {
          feedback_id: idFeedback,
          jefe_directo_id: idJefeDirecto,
          colaboradores_ids: colaboradores_ids,
        };
        dispatch(feedbackActions.bulkDeleteColaboradores(params));
      }
      return;
    } else if (v.value === "cambiarJefeDirecto") {
      const colaboradores_ids = colaboradores_checked.map(
        (colaborador) => colaborador.id
      );
      dispatch(
        feedbackActions.setCambiarJefeDirectoBody({
          ...cambiar_jefe_directo_body,
          colaboradores: colaboradores_ids,
        })
      );
      setOpenJefesDirectos(true);
    }
  };

  const onSearchColaboradores = (e) => {
    let searchText = e.target.value;
    dispatch(
      feedbackActions.setColaboradoresFilter({
        ...colaboradores_filter,
        nombre: searchText,
        idFeedback: idFeedback,
        idJefeDirecto: idJefeDirecto,
      })
    );
  };

  const onChangePageSize = (e) => {
    let value = e.target.value;
    dispatch(
      feedbackActions.setColaboradoresFilter({
        ...colaboradores_filter,
        pagination: {
          pageSize: parseInt(value),
          page: 1,
        },
        idFeedback: idFeedback,
        idJefeDirecto: idJefeDirecto,
      })
    );
    setOuterPageSize(parseInt(value));
  };

  const cambiarJefeDirecto = (jefesDirectos) => {
    if (jefesDirectos.length > 1) {
      ErrorToast({ message: "Debes seleccionar solo un Jefe Directo." });
      return;
    } else if (jefesDirectos.length === 0) {
      ErrorToast({ message: "Debes seleccionar un Jefe Directo." });
      return;
    }
    const jefeDirectoId = jefesDirectos.map((jefe) => jefe.id);
    dispatch(
      feedbackActions.setCambiarJefeDirectoBody({
        ...cambiar_jefe_directo_body,
        feedback_id: idFeedback,
        jefe_directo_id: idJefeDirecto,
        nuevo_jefe_directo_id: jefeDirectoId[0],
      })
    );
    if (!cambiar_jefe_directo_body.colaboradores) {
      ErrorToast({
        message: "Debes seleccionar al menos un colaborador a reasignar.",
      });
      return;
    }
    dispatch(feedbackActions.cambiarJefeDirecto());
    setOpenJefesDirectos(false);
    console.log(cambiar_jefe_directo_body);
  };

  return (
    <FeedbackConfiguracionLayout title={"Feedback"} onClickBack={onClickBack}>
      <div className="py-2 px-8 -mx-8">
        <div className="form flex justify-center mt-4">
          <div className="flex flex-col gap-4 w-full md:w-9/12">
            <div className="border-b pb-2">
              <div className="flex flex-row justify-between">
                <h2 className="font-semibold">Jefe directo</h2>
                <h2 className="font-semibold w-1/4">Avance</h2>
              </div>
            </div>
            <div className="flex flex-col w-full">
              <div className="flex flex-row justify-between items-center">
                <div className="flex flex-row gap-x-2 m-4">
                  <img
                    className="object-cover h-12 w-12 rounded-md"
                    src={
                      jefe_directo.foto_empleado
                        ? jefe_directo.foto_empleado
                        : userDefault
                    }
                    alt={`Perfil`}
                  />
                  <div className="flex flex-col gap-1">
                    <span className="font-semibold text-base">
                      {jefe_directo.nombre} {jefe_directo.apellido}
                    </span>
                    <span className="text-base">
                      Cargo:{" "}
                      {jefe_directo.cargo
                        ? jefe_directo.cargo.nombre
                        : "Sin cargo"}
                    </span>
                  </div>
                </div>
                <span className="w-1/4">
                  {jefe_directo.avance_general} % completado
                </span>
              </div>
              <div className="mt-8">
                <div className="flex items-center gap-2 mt-4">
                  <SearchInput
                    containerClass={`w-full`}
                    placeHolder={"Buscar por nombre o cargo..."}
                    value={nombre}
                    onChange={onSearchColaboradores}
                  />
                  <div className="text-sm">
                    <ReactSelect
                      className="w-52 h-10 bg-blue-200 inline-grid"
                      value={{
                        value: "",
                        label: `(${colaboradores_checked.length}) seleccionados`,
                      }}
                      options={actionOptions}
                      onChange={onAction}
                    ></ReactSelect>
                  </div>
                  <MostrarPaginas
                    containerClass={"w-1/2x"}
                    onChangePageSize={onChangePageSize}
                    defaultOption={10}
                  />
                </div>

                <div className="flex flex-row justify-between my-6 gap-4">
                  <SucursalSelect
                    onChange={onFilter("sucursal")}
                    defaultLabel="Sucursal: (Todas)"
                    className={"w-full"}
                    value={sucursal}
                  />
                  <AreaSelect
                    onChange={onFilter("area")}
                    defaultLabel="Áreas: (Todas)"
                    className={"w-full"}
                    value={area}
                  />
                  <DepartamentoSelect
                    onChange={onFilter("departamento")}
                    defaultLabel="Departamentos: (Todos)"
                    className={"w-full"}
                    value={departamento}
                  />
                </div>
                <DataTable
                  containerClass={"my-2"}
                  config={datatableConfig}
                  data={colaboradores}
                  loading={colaboradores_loading}
                  noHeaders={false}
                  //
                  totalRecords={colaboradores_count}
                  onPaginate={onPaginate}
                  outerPageSize={outerPageSize}
                  //
                  checkable={true}
                  onCheckAll={onCheckAll}
                  onItemCheck={onCheckColaborador}
                  checkedItems={colaboradores_checked}
                />
              </div>
            </div>
          </div>
        </div>
        <Modal isOpen={openJefesDirectos}>
          <div className="rounded-lg bg-white w-10/12 md:w-4/12 p-2 md:p-5 flex flex-col gap-5">
            <div className="modal-title">
              <div className="w-full flex justify-between items-center">
                <div className="flex items-center gap-4">
                  <h2 className="font-semibold text-xl">
                    Seleccione los Jefes directos
                  </h2>
                </div>
                <button
                  onClick={() => {
                    setOpenJefesDirectos(false);
                  }}
                >
                  <X size={24} />
                </button>
              </div>
            </div>
            <div className="modal-body">
              <div className="">
                <div>
                  <JefesDirectosCambio
                    onSubmit={cambiarJefeDirecto}
                    idFeedback={feedback_id}
                  />
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </FeedbackConfiguracionLayout>
  );
}
