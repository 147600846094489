import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ToggleSwitch from "src/modules/common/components/ToggleSwitch";
import { kpiActions } from "../handlers/redux";
import { ErrorToast } from "src/modules/common/components/ErrorToast";
import DecimalInput from "src/modules/common/components/DecimalInput";

function KpiColaboradorForm({ kpiColaborador, onSave }) {
  const dispatch = useDispatch();
  const metricas = useSelector((state) => state.kpi.metricas);
  const periodos = useSelector((state) => state.kpi.periodos);
  const [kpiLocal, setKpiLocal] = useState(kpiColaborador);
  const [activarEscalaChecked, setActivarEscalaChecked] = useState(false);
  const metricasConMeta = [1, 2];
  const checkMetaDisplay = (metrica) => {
    if (activarEscalaChecked) {
      return false;
    }
    if (!metrica) return true;
    return metricasConMeta.includes(parseInt(metrica));
  };
  const switchChangeHandler = () => {
    setActivarEscalaChecked(!activarEscalaChecked);
    //
    setKpiLocal({
      ...kpiLocal,
      meta: null,
      rangos: [],
    });
  };

  useEffect(() => {
    dispatch(kpiActions.metricas());
    dispatch(kpiActions.periodos());
    //
    const hayRangos = kpiLocal?.rangos?.length > 0;
    if (hayRangos) {
      setActivarEscalaChecked(kpiLocal?.rangos?.length > 0);
    }
  }, []);

  useEffect(() => {
    checkMetaDisplay(kpiLocal.metrica || 1);
  }, [kpiLocal]);

  const onChange =
    (field) =>
    ({ target }) => {
      let text = "";
      let display = {};
      if (target.options) {
        text = target.options[target.selectedIndex].text;
      }
      if (field === "metrica") {
        display = { metrica_display: text };
      }
      if (field === "periodo") {
        display = { periodo_display: text };
      }
      //
      let value = target.value;
      if (field === "tiene_escala_dinamica") {
        value = target.checked;
      } else if (field === "meta") {
        if (value.split(",").length > 2) {
          return;
        }
      }
      //
      if (field === "meta") {
        if (!value.endsWith(",")) {
          value = (value + "").replace(/\./g, "");
          if (value.includes(",")) {
            const parteEntera = value.slice(0, value.indexOf(","));
            const parteDecimal = value.slice(value.indexOf(","));
            value = Number(parteEntera).toLocaleString("de-DE") + parteDecimal;
          } else {
            value = Number(value).toLocaleString("de-DE");
          }
        }
        // value = (value + "").replace(",", ".");
      }
      //
      setKpiLocal({
        ...kpiLocal,
        [field]: value,
        ...display,
      });
    };

  const onSubmit = (e) => {
    e.preventDefault();
    const {
      nombre,
      descripcion,
      metrica,
      periodo,
      meta,
      rangos,
      tiene_escala_dinamica = false,
    } = kpiLocal;
    let kpiLocalCopy = JSON.parse(JSON.stringify(kpiLocal));
    const fields = ["Nombre", "Descripción", "Métrica", "Período"];
    const values = [nombre, descripcion, metrica, periodo];
    const fieldErrors = [];
    //
    if (!activarEscalaChecked) {
      if (metricasConMeta.includes(parseInt(metrica))) {
        fields.push("Meta");
        let metaAux = 0;
        if (!meta.toString().endsWith(",")) {
          // Le sacamos los separadores de miles (puntos) y las comas de decimales los reemplazamos por puntos
          metaAux = Number((meta + "").replace(/\./g, "").replace(/\,/g, "."));
          values.push(metaAux);
        } else {
          fieldErrors.push("Meta");
        }
        //
        kpiLocalCopy = {
          ...kpiLocalCopy,
          meta: metaAux,
        };
      }
    } else {
      checkRangos();
    }

    values.forEach((val, vidx) => {
      if (!val) {
        fieldErrors.push(fields[vidx]);
      }
    });
    if (fieldErrors.length) {
      ErrorToast({
        message: `Faltan campos obligatorios: ${fieldErrors.toString()}`,
      });
      return;
    }
    if (!!activarEscalaChecked) {
      if (rangos.length < 1) {
        ErrorToast({
          message: "Debe agregar rangos para la escala",
        });
        return;
      }
    }
    //
    if (onSave) {
      onSave(kpiLocalCopy);
    }
  };

  const onChangeRango = (index, field, value) => {
    const tieneRangos = kpiLocal?.rangos?.length > 0;
    let rangos = [];
    let lastRangoIndex = 0;
    if (tieneRangos) {
      rangos = JSON.parse(JSON.stringify(kpiLocal.rangos));
    }
    // rellenamos posibles indexes vacios
    if (rangos.length < 5) {
      lastRangoIndex = rangos.length;
      for (let i = lastRangoIndex; i < 5; i++) {
        rangos[i] = { desde: "", hasta: "", resultado: "" };
      }
    }
    rangos[index][field] = value !== null ? Number(value) : value;
    //
    setKpiLocal({
      ...kpiLocal,
      rangos: rangos,
    });
  };

  const checkRangos = () => {
    const tieneRangos = kpiLocal?.rangos?.length > 0;
    let rangos = [];
    //
    if (tieneRangos) {
      rangos = JSON.parse(JSON.stringify(kpiLocal.rangos));
      for (let i = 0; i < rangos.length; i++) {
        let estaVacio =
          !rangos[i].desde && !rangos[i].hasta && !rangos[i].resultado;
        if (estaVacio) {
          rangos.splice(i, 1);
          i--;
        }
      }
      //
      setKpiLocal({
        ...kpiLocal,
        rangos: rangos,
      });
    }
  };

  const ESTADO_OPTIONS = [
    { value: true, label: "Vigente" },
    { value: false, label: "No Vigente" },
  ];

  const renderInput = (
    field,
    type,
    name,
    placeholder = null,
    description = null,
    options = [],
    readOnly = false,
    noEmpySelectOption = false
  ) => {
    //
    let inputContainerClass = "flex flex-col gap-2";
    if (field === "meta") {
      inputContainerClass = checkMetaDisplay(kpiLocal["metrica"])
        ? inputContainerClass
        : "hidden";
    }
    if (type === "select") {
      return (
        <div className="flex flex-col gap-2">
          <label className="font-bold text-base" htmlFor="">
            {name}
            {!!name && ":"}
            {!name && <span>&nbsp;</span>}
          </label>
          {!readOnly && (
            <select
              name={`select-${field}`}
              id={`select-${field}`}
              value={kpiLocal[field]}
              onChange={onChange(field)}
              className="border rounded placeholder-zinc-600 border-zinc-200 px-2 py-3 text-sm"
            >
              {!noEmpySelectOption && (
                <option key={`opt-${field}-null`} value={null}>
                  Seleccione una opción
                </option>
              )}
              {options.map((opt, idx) => (
                <option key={`opt-${field}-${idx}`} value={opt.value}>
                  {opt.label}
                </option>
              ))}
            </select>
          )}
          {!!readOnly && (
            <div className="py-2">
              <select
                disabled
                name={`select-${field}`}
                id={`select-${field}`}
                value={kpiLocal[field]}
                onChange={onChange(field)}
                className="border rounded placeholder-zinc-600 border-zinc-200 px-2 py-3 text-sm"
              >
                {options.map((opt, idx) => (
                  <option key={`opt-${field}-${idx}`} value={opt.value}>
                    {opt.label}
                  </option>
                ))}
              </select>
            </div>
          )}
          {!!description && <div className="text-sm">{description}</div>}
        </div>
      );
    }
    const maxLengthNombre = field === "nombre" ? 250 : 10000;
    const value =
      field === "meta"
        ? kpiLocal[field]?.toLocaleString("de-DE") || ""
        : kpiLocal[field];
    //
    return (
      <div className={inputContainerClass}>
        <label className="font-bold text-base" htmlFor="">
          {name}
          {!!name && ":"}
          {!name && <span>&nbsp;</span>}
        </label>
        {!readOnly && (
          <div className="w-full flex flex-col">
            <input
              type={type}
              name={field}
              value={value}
              maxLength={maxLengthNombre}
              onChange={onChange(field)}
              placeholder={placeholder || "Sin especificar"}
              className="border rounded placeholder-zinc-600 border-zinc-200 px-2 py-3 text-sm"
            />
            {field === "nombre" && (
              <div className="w-full flex justify-end">
                <span id="cantCharNombreId" className="text-xs text-gray-500">
                  {kpiLocal[field] ? kpiLocal[field].length : 0}/
                  {maxLengthNombre}
                </span>
              </div>
            )}
          </div>
        )}
        {!!readOnly && <div className="py-2">{kpiLocal[field]}</div>}
        {!!description && <div className="text-sm">{description}</div>}
      </div>
    );
  };

  const renderTablaEscala = () => {
    if (!activarEscalaChecked) {
      return <></>;
    }
    const inputClass =
      "col-span-1 border rounded placeholder-zinc-600 border-zinc-200 px-2 py-2 text-sm mr-1 mb-1";
    return (
      <div className="col-span-2">
        <div className="flex flex-col items-start justify-center w-full">
          <label
            className="flex items-center gap-2 font-semibold my-2"
            htmlFor="tiene_escala_dinamica"
          >
            <input
              className="w-4 h-4"
              type="checkbox"
              name="tiene_escala_dinamica"
              id="tiene_escala_dinamica"
              checked={kpiLocal?.tiene_escala_dinamica}
              onChange={onChange("tiene_escala_dinamica")}
            />
            <span>Escala dinámica</span>
          </label>
          <small className="text-xs text-slate-500 hidden md:block">
            Los rangos con Resultado en blanco tomarán el valor ingresado como
            Alcance en la Evaluación de Desempeño
          </small>
        </div>
        <div className="grid grid-cols-3 mt-4">
          <span className="col-span-1 font-bold">Desde</span>
          <span className="col-span-1 font-bold">Hasta</span>
          <span className="col-span-1 font-bold">Resultado</span>
        </div>
        <div className="mr-6 my-2 md:col-span-2">
          {[1, 2, 3, 4, 5].map((rango, index) => (
            <div key={`rango-${rango}`} className="grid grid-cols-3">
              <DecimalInput
                className={inputClass}
                type="text"
                id={`desde-${rango}`}
                key={`desde-${rango}`}
                value={kpiLocal?.rangos ? kpiLocal?.rangos[index]?.desde : ""}
                onChange={(e) => onChangeRango(index, "desde", e)}
              />
              <DecimalInput
                className={inputClass}
                type="text"
                id={`hasta-${rango}`}
                key={`hasta-${rango}`}
                value={kpiLocal?.rangos ? kpiLocal?.rangos[index]?.hasta : ""}
                onChange={(value) => {
                  onChangeRango(index, "hasta", value);
                }}
              />
              <DecimalInput
                className={inputClass}
                type="text"
                id={`resultado-${rango}`}
                key={`resultado-${rango}`}
                value={
                  kpiLocal?.rangos ? kpiLocal?.rangos[index]?.resultado : ""
                }
                onChange={(e) => onChangeRango(index, "resultado", e)}
              />
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="flex justify-center items-center w-full mt-1">
        <div className="flex flex-col w-full md:w-11/12">
          <div className="grid grid-cols-1 md:grid-cols-12">
            {/* DATOS KPI */}
            <div className="col-span-1 md:col-span-12 ">
              <form onSubmit={onSubmit}>
                <div className="grid grid-cols-1 md:grid-cols-3 border-2x">
                  {/* nombre */}
                  <div className="col-span-3 mr-6 my-2 ">
                    {renderInput(
                      "nombre",
                      "text",
                      "Nombre del Indicador a Evaluar",
                      "Depositos a la Vista/Depositos totales",
                      null,
                      [],
                      false
                    )}
                  </div>
                  {/* descripcion */}
                  <div className="col-span-3 mr-6 my-2 ">
                    {renderInput(
                      "descripcion",
                      "text",
                      "Breve descripcion del indicador",
                      "",
                      null,
                      [],
                      false
                    )}
                  </div>
                  {/* metrica */}
                  <div className="col-span-1 mr-6 my-2 ">
                    {renderInput(
                      "metrica",
                      "select",
                      "Metricas a evaluar",
                      "",
                      "",
                      metricas,
                      false
                    )}
                    <div className="flex items-centers mt-4 gap-4">
                      <p>Activar escala</p>
                      <ToggleSwitch
                        className="mr-2"
                        checked={activarEscalaChecked}
                        onChange={switchChangeHandler}
                      />
                    </div>
                  </div>
                  {/* meta */}
                  <div className="col-span-1 mr-6 my-2 ">
                    {renderInput("meta", "text", "Meta")}
                  </div>
                  {/* estado */}
                  {!!kpiLocal.id && (
                    <div className="col-span-1 mr-6 my-2 ">
                      {renderInput(
                        "vigente",
                        "select",
                        "Estado",
                        null,
                        null,
                        ESTADO_OPTIONS,
                        false,
                        true
                      )}
                    </div>
                  )}
                  {/* tabla de escala */}
                  {renderTablaEscala()}
                  {/* periodicidad */}
                  <div className="col-span-3 mr-6 my-2">
                    {renderInput(
                      "periodo",
                      "select",
                      "Período o Frecuencia",
                      "",
                      "",
                      periodos,
                      false
                    )}
                  </div>
                  <div className="col-span-3 mt-4 flex justify-start">
                    <button className="bg-sky-500 border-2 border-sky-500 rounded px-4 py-1 text-white">
                      Guardar indicador
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default KpiColaboradorForm;
