import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import DataTable from "src/modules/common/components/DataTable";
import MostrarPaginas from "src/modules/common/components/MostrarPaginas";
import { commonActions } from "src/modules/common/handler/redux";
import { kpiActions } from "src/modules/kpi/handlers/redux";
import SearchInput from "src/modules/remuneracion/components/SearchInput";

function ColaboradorKpisPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const { idColaborador } = params;
  const kpis = useSelector((state) => state.kpi.colaboradorKpis);
  const colaboradorKpis_loading = useSelector(
    (state) => state.kpi.colaboradorKpis_loading
  );
  const metricas = useSelector((state) => state.kpi?.metricas);
  const periodos = useSelector((state) => state.kpi?.periodos);
  //
  const getPeriodoDisplay = (kpi) => {
    let periodoDisplay = "-";
    let periodosFilter = periodos.filter(
      (periodo, idx) => periodo.value === kpi.periodo
    );
    if (periodosFilter.length) periodoDisplay = periodosFilter[0].label;
    return periodoDisplay;
  };
  const getMetricaDisplay = (kpi) => {
    let metricaDisplay = "-";
    let metricasFilter = metricas.filter(
      (metrica, idx) => metrica.value === kpi.metrica
    );
    if (metricasFilter.length) metricaDisplay = metricasFilter[0].label;
    return metricaDisplay;
  };
  const getMeta = (kpi) => {
    const { meta, rangos, metrica } = kpi;
    if (metrica === 3) {
      //simple
      return "Logo/No Logro";
    }
    if (metrica === 4) {
      //observacion
      return "Observación";
    }
    if (rangos.length > 0) {
      return "Por escala";
    }
    if (meta) {
      return meta.toLocaleString("de-DE");
    }
    return "Sin definir";
  };
  const goToKpis = () => {
    navigate(`/kpis/colaboradores/${idColaborador}`);
  };
  //
  const KpiCardItem = ({ kpi }) => {
    const { nombre, descripcion } = kpi;
    return (
      <div className="flex flex-col gap-3 p-4 border border-zinc-300 rounded">
        {/* title */}
        <div className="flex flex-col gap-1">
          <div className="text-sm  font-bold">{nombre}</div>
          <div className="text-sm">{descripcion}</div>
        </div>
        {/* propiedades */}
        <div className="grid grid-cols-2  gap-3">
          {/* prop item */}
          <div className="">
            <div className="text-xs">Tiempo:</div>
            <div className="text-sm font-semibold">
              {getPeriodoDisplay(kpi)}
            </div>
          </div>
          <div className="">
            <div className="text-xs">Tipo de metrica:</div>
            <div className="text-sm font-semibold">
              {getMetricaDisplay(kpi)}
            </div>
          </div>
          <div className="">
            <div className="text-xs">Meta:</div>
            <div className="flex flex-row gap-2 items-center">
              <div className="text-sm font-semibold">{getMeta(kpi)}</div>
            </div>
          </div>
          {/*  */}
          <div className="">
            <div className="text-xs">Creado por:</div>
            <div className="flex flex-row gap-1 items-center">
              {!!kpi.creado_por && (
                <>
                  <img
                    className="object-cover h-8 w-8 rounded-md"
                    src={kpi.creado_por?.foto_empleado}
                    alt={`Foto Jefe de Lider`}
                  />
                  <div className="text-sm font-semibold">
                    {`${kpi.creado_por?.nombre} ${kpi.creado_por?.apellido}`}
                  </div>
                </>
              )}
              {!kpi.creado_por && (
                <>
                  <div className="w-8 h-8 rounded bg-slate-300"></div>
                  <div className="text-sm font-semibold">Admin</div>
                </>
              )}
            </div>
          </div>

          {/*  */}
        </div>
      </div>
    );
  };
  // HISTORICO
  const [PageSize, setPageSize] = useState(10);
  const kpisNoVigentes = useSelector(
    (state) => state.kpi.colaboradorKpisNoVigentes
  );
  const kpisNoVigentes_count = useSelector(
    (state) => state.kpi.colaboradorKpisNoVigentes_count
  );
  const kpisNoVigentes_loading = useSelector(
    (state) => state.kpi.colaboradorKpisNoVigentes_loading
  );
  const kpisNoVigentes_filter = useSelector(
    (state) => state.kpi.colaboradorKpisNoVigentes_filter
  );
  const datatableConfig = {
    headers: [
      {
        title: "Lista de KPIs",
        contentClass: "px-2 py-4",
        accessor: "nombre",
      },
      {
        title: "Métrica",
        accessor: "metrica_display",
      },
      {
        title: "Frecuencia",
        accessor: "periodo_display",
      },
      {
        title: "Creado",
        accessor: "creado_por.nombre",
        contentRenderer: (item) => (
          <div>
            {!!item.fecha_creacion
              ? dayjs(item.fecha_creacion.slice(0, 10)).format("DD/MM/YYYY")
              : "Sin registro"}
          </div>
        ),
      },
    ],
    rowActions: [],
  };
  const onPaginate = (page, pageSize) => {
    //
    dispatch(
      kpiActions.setColaboradorKpisNoVigentesFilter({
        ...kpisNoVigentes_filter,
        id: idColaborador,
        pagination: {
          page: page,
          pageSize: pageSize,
        },
      })
    );
  };
  const onSearchKPINoVigente = (event) => {
    dispatch(
      kpiActions.setColaboradorKpisNoVigentesFilter({
        ...kpisNoVigentes_filter,
        id: idColaborador,
        nombre: event.target.value,
      })
    );
  };
  const onChangePageSize = (e) => {
    let value = e.target.value;
    //
    dispatch(
      kpiActions.setColaboradorKpisNoVigentesFilter({
        ...kpisNoVigentes_filter,
        id: idColaborador,
        pagination: {
          pageSize: parseInt(value),
          page: 1,
        },
      })
    );
    //
    // setOuterPageSize(parseInt(value));
  };
  //
  useEffect(() => {
    dispatch(
      commonActions.setEditDetailButton({
        label: "Nuevo KPI",
        action: goToKpis,
      })
    );
    if (idColaborador) {
      let item = { id: idColaborador };
      dispatch(kpiActions.colaboradorKpis(item));
      dispatch(kpiActions.metricas());
      dispatch(kpiActions.periodos());
    }
  }, []);
  //
  return (
    <div className="flex justify-center items-center w-full mt-10 mb-4">
      <div className="flex flex-col w-full md:w-5/6 gap-3">
        <div className="border-b pb-2 mb-3">
          <span className="font-bold text-lg">KPIs del colaborador</span>
        </div>
        {/* lista */}
        <div className="grid grid-cols-2 gap-3">
          {/* items */}
          {!colaboradorKpis_loading &&
            kpis?.results?.length > 0 &&
            kpis?.results?.map((kpi, kIdx) => (
              <KpiCardItem kpi={kpi}></KpiCardItem>
            ))}
          {!colaboradorKpis_loading && kpis?.results?.length < 1 && (
            <div className="col-span-2 flex items-center justify-center w-full mt-8">
              <div className="p-2 rounded-md border">
                No existen KPIs para mostrar
              </div>
            </div>
          )}
          {!!colaboradorKpis_loading &&
            [1, 2, 3, 4].map((l, i) => (
              <div
                key={`loading-${i}`}
                className="col-span-1 bg-slate-200 animate-pulse h-52 w-full rounded-md"
              ></div>
            ))}
        </div>
        {/* HISTORICO */}
        <div className="grid grid-cols-12">
          <div className="col-span-1 md:col-span-12 border-b mb-6 mt-4 pb-2">
            <div className="font-bold text-lg">Historial de KPI's</div>
          </div>
          <div className="col-span-1 md:col-span-6 mb-4 flex gap-4">
            <SearchInput
              placeholder="Buscar kpi..."
              value={kpisNoVigentes_filter.nombre}
              onChange={onSearchKPINoVigente}
            />
            <MostrarPaginas
              containerClass={"w-1/2x"}
              onChangePageSize={onChangePageSize}
            />
          </div>
          <div className="col-span-12 border rounded-md bg-white p-2">
            <DataTable
              containerClass={"my-8x"}
              data={kpisNoVigentes}
              loading={kpisNoVigentes_loading}
              config={datatableConfig}
              // pagination
              totalRecords={kpisNoVigentes_count}
              onPaginate={onPaginate}
              outerPageSize={PageSize}
              // check config
              checkable={false}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ColaboradorKpisPage;
