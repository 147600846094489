import axios from "axios";
import { useSelector } from "react-redux";

export function DescargarPlantillaRemuneracion({
  plantilla = "ejemplo",
  token = "",
  setLoading,
}) {
  const URL_BASE =
    process.env.REACT_APP_API_URL || "http://localhost:8000/api/admin";
  const requestURL = URL_BASE + `/remuneracion/descargar-archivo-${plantilla}/`;
  const headers = {
    "Content-Type": "blob",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };
  //
  if (setLoading) {
    setLoading(true);
  }
  axios({
    url: requestURL,
    method: "GET",
    headers: headers,
    responseType: "blob",
  })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${plantilla}.xlsx`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
      //
      if (setLoading) {
        setLoading(false);
      }
    })
    .catch((e) => {
      !!process.env.REACT_APP_DEBUG &&
        console.error("Error al descargar el archivo");
    });
}
