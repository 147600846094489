import { useState, useEffect } from 'react';

/**
 * Custom hook that provides debounced search functionality.
 * 
 * This hook is designed to delay the invocation of a search callback
 * function until after the user has stopped typing for a specified
 * amount of time. It is useful for scenarios where you want to avoid 
 * triggering searches on every keystroke.
 * 
 * @param {string} initialValue - The initial value of the search input.
 * @param {number} delay - The debounce delay in milliseconds.
 * @param {Function} onSearch - The callback function to be invoked with the debounced value.
 * 
 * @returns {Object} - An object containing:
 *  - {string} searchValue: The current value of the search input.
 *  - {Function} handleChange: The function to be called on input change events.
 * 
 * @example
 * const { searchValue, handleChange } = useDebouncedSearch('', 500, (value) => {
 *   // Perform search with the debounced value
 *   console.log('Searching for:', value);
 * });
 * 
 * <input
 *   type="text"
 *   value={searchValue}
 *   onChange={handleChange}
 *   placeholder="Search..."
 * />
 */
const useDebouncedSearch = (initialValue, delay, onSearch) => {
  const [searchValue, setSearchValue] = useState(initialValue);

  useEffect(() => {
    const handler = setTimeout(() => {
      onSearch(searchValue);
    }, delay);

    return () => {
      clearTimeout(handler); // Cleans up the timeout if searchValue or delay changes
    };
  }, [searchValue, delay]);

   /**
   * Handles the change event for the search input.
   * 
   * @param {Object} event - The change event from the input element.
   */
  const handleChange = (event) => {
    setSearchValue(event.target.value);
  };

  return {
    searchValue,
    handleChange,
  };
};

export default useDebouncedSearch;
