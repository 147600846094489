import React, { useRef } from "react";
import { CaretDownFill, ChevronBarDown, Folder2 } from "react-bootstrap-icons";

function ImageUploadAlt({
  className,
  visible = true,
  onSubmit = null,
  onPlantilla = null,
}) {
  const [name, setName] = React.useState("");
  const [selectedFile, setSelectedFile] = React.useState(null);
  const fileInput = useRef(null);

  const onChangeCaptureHandler = (e) => {
    let file = e.target.files[0];
    setSelectedFile(file);
    setName(file.name);
  };

  const uploadFile = () => {
    onSubmit(selectedFile);
  };

  const onClickSeleccionar = (e) => {
    e.stopPropagation();
    fileInput.current && fileInput.current.click();
  };

  return (
    <div className={className}>
      {!!visible && (
        <div className=" flex flex-col mt-4">
          <label className="font-bold" htmlFor="file">
            Imagen<span className="text-red-600">*</span>
          </label>
          <div className="flex gap-2 ">
            <input
              className="border-2 rounded-md p-2 flex-1"
              type="text"
              name="filemask"
              id="filemask"
              value={name}
              disabled
            />
          </div>

          <input
            className="hidden"
            type="file"
            name="file"
            ref={fileInput}
            accept="image/jpeg,image/png,image/gif"
            onChangeCapture={onChangeCaptureHandler}
          />

          <div className=" flex gap-2 my-4">
            {/* SUBMIT */}
            <button
              onClick={onClickSeleccionar}
              className="p-2 text-xs flex gap-2 items-center border border-gray-500 rounded-md"
            >
              <Folder2 size={18}></Folder2>
              Seleccionar imagen
            </button>
            {!!selectedFile && (
              <button
                onClick={uploadFile}
                className="border-1 rounded-md py-1 px-3 bg-black text-white text-sm"
              >
                Subir imagen
              </button>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default ImageUploadAlt;
