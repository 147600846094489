import axios from "axios";
const URL_BASE =
  process.env.REACT_APP_API_URL || "http://localhost:8000/api/admin";

export const planCarreraApi = (token) => {
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };
  return {
    // FAMILIAS
    list: async (planes_filter) => {
      const { nombre = "", pagination = {}, ordering = "" } = planes_filter;
      const { page = 1, pageSize = 50 } = pagination;
      let searchURL = `${URL_BASE}/plan-carrera/?search=${nombre}&page=${page}&page_size=${pageSize}`;
      if (ordering) {
        searchURL = searchURL + `&ordering=${ordering}`;
      }
      //
      try {
        const response = await axios.get(searchURL, {
          headers,
        });
        !!process.env.REACT_APP_DEBUG &&
          console.log("planes list api", response);
        return response.data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log("planes error", error);
        throw error;
      }
    },
    create: async (params) => {
      try {
        const response = await axios.post(
          `${URL_BASE}/plan-carrera/create`,
          params,
          {
            headers,
          }
        );
        !!process.env.REACT_APP_DEBUG &&
          console.log("planes create api", response);
        return response.data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log("planes error", error);
        throw error;
      }
    },
    update: async (params) => {
      try {
        const response = await axios.put(
          `${URL_BASE}/plan-carrera/${params.id}/update`,
          params,
          {
            headers,
          }
        );
        //
        return response.data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log("planes error", error);
        throw error;
      }
    },
    detail: async (id) => {
      try {
        const response = await axios.get(
          `${URL_BASE}/plan-carrera/${id}/detail`,
          {
            headers,
          }
        );
        //
        return response.data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG &&
          console.log("plan detail error", error);
        throw error;
      }
    },
    delete: async (id) => {
      try {
        const response = await axios.delete(
          `${URL_BASE}/plan-carrera/${id}/delete`,
          {
            headers,
          }
        );
        !!process.env.REACT_APP_DEBUG &&
          console.log("planes delete api", response);
        return response.data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log("planes error", error);
        throw error;
      }
    },
    bulkDelete: async (ids) => {
      try {
        const response = await axios.post(
          `${URL_BASE}/plan-carrera/bulk-delete`,
          { planes_carrera_ids: ids },
          {
            headers,
          }
        );
        !!process.env.REACT_APP_DEBUG &&
          console.log("planes bulk delete api", response);
        return response.data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log("planes error", error);
        throw error;
      }
    },
    // CATEGORIAS
    addCategoria: async ({ id, categoria }) => {
      try {
        const response = await axios.post(
          `${URL_BASE}/plan-carrera/${id}/add-categoria`,
          categoria,
          {
            headers,
          }
        );
        !!process.env.REACT_APP_DEBUG &&
          console.log("planes add-categoria api", response);
        return response.data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log("planes error", error);
        throw error;
      }
    },
    deleteCategoria: async ({ id, categoriaId }) => {
      try {
        const response = await axios.post(
          `${URL_BASE}/plan-carrera/${id}/categoria/${categoriaId}/delete`,
          {},
          {
            headers,
          }
        );
        !!process.env.REACT_APP_DEBUG &&
          console.log("delete categoria plan api", response);
        return response.data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log("planes error", error);
        throw error;
      }
    },
    updateCategoria: async ({ id, categoriaId, updatedCategoria }) => {
      try {
        const response = await axios.put(
          `${URL_BASE}/plan-carrera/${id}/categoria/${categoriaId}/update`,
          updatedCategoria,
          {
            headers,
          }
        );
        !!process.env.REACT_APP_DEBUG &&
          console.log("update categoria plan api", response);
        return response.data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log("planes error", error);
        throw error;
      }
    },
    updateCategoriaCargos: async ({ id, categoriaId, cargosIds }) => {
      try {
        const response = await axios.post(
          `${URL_BASE}/plan-carrera/${id}/categoria/${categoriaId}/update-cargos`,
          { cargos_ids: cargosIds },
          {
            headers,
          }
        );
        !!process.env.REACT_APP_DEBUG &&
          console.log("update categoria plan api", response);
        return response.data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log("planes error", error);
        throw error;
      }
    },
    // REQUISITOS
    getCargos: async (cargos_filter) => {
      const { nombre = "", pagination = {} } = cargos_filter;
      const { page = 1, pageSize = 50 } = pagination;
      try {
        const response = await axios.get(
          `${URL_BASE}/plan-carrera/cargos/?search=${nombre}&page=${page}&page_size=${pageSize}`,
          {
            headers,
          }
        );
        return response.data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log("cargos error", error);
        throw error;
      }
    },
    getCargoRequisitos: async (cargoId) => {
      try {
        const response = await axios.get(
          `${URL_BASE}/plan-carrera/cargos/${cargoId}/requisitos`,
          {
            headers,
          }
        );
        //
        return response.data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG &&
          console.log("cargo requisitos error", error);
        throw error;
      }
    },
    borrarRequisitosCargo: async (cargoId) => {
      try {
        const response = await axios.post(
          `${URL_BASE}/plan-carrera/cargos/${cargoId}/borrar-requisitos`,
          {},
          {
            headers,
          }
        );
        //
        return response.data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG &&
          console.log("cargo requisitos error", error);
        throw error;
      }
    },
    bulkBorrarRequisitosCargo: async (cargos_ids) => {
      try {
        const response = await axios.post(
          `${URL_BASE}/plan-carrera/cargos/borrar-requisitos`,
          { cargos_ids: cargos_ids },
          {
            headers,
          }
        );
        //
        return response.data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG &&
          console.log("cargo requisitos error", error);
        throw error;
      }
    },
    updateRequisitosCargo: async (cargo) => {
      try {
        const response = await axios.put(
          `${URL_BASE}/plan-carrera/cargos/${cargo.id}/update-requisitos`,
          { ...cargo },
          {
            headers,
          }
        );
        //
        return response.data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG &&
          console.log("cargo requisitos error", error);
        throw error;
      }
    },
  };
};
