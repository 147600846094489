function MostrarPaginas({
  onChangePageSize,
  options = [10, 50, 100],
  containerClass,
  defaultOption = 50,
  ...props
}) {
  return (
    <div className={`flex gap-2 items-center ${containerClass}`}>
      <label className="font-semibold" htmlFor="pageSize">
        Mostrar
      </label>
      <select
        className="border rounded-md p-2"
        onChange={onChangePageSize}
        {...props}
      >
        {options.map((option) => (
          <option
            value={option}
            key={`option-${option}`}
            selected={option === defaultOption}
          >
            {option}
          </option>
        ))}
      </select>
    </div>
  );
}

export default MostrarPaginas;
