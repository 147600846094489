import React from "react";
import { XLg } from "react-bootstrap-icons";
import { useDispatch, useSelector } from "react-redux";
import { ColaboradoresLibresTable } from "src/modules/colaborador/modules/laboral/components/ColaboradoresLibresTable";
import { ColaboradoresLibresToolbar } from "src/modules/colaborador/modules/laboral/components/ColaboradoresLibresToolbar";
import { colaboradorLaboralActions } from "src/modules/colaborador/modules/laboral/handlers/redux";
import Modal from "src/modules/common/components/Modal";

function ColaboradoresLibresSelectModal({ colaboradores = [] }) {
  const dispatch = useDispatch();
  const modal = useSelector(
    (state) => state.colaboradorLaboral.colaboradores_libres_select_modal
  );

  const setOpen = (open) => {
    dispatch(
      colaboradorLaboralActions.setColaboradoresLibresSelectModal({ open })
    );
  };

  return (
    <Modal isOpen={modal.open}>
      <div className=" rounded-lg bg-white w-6/12">
        <div className="flex flex-col gap-3 p-4">
          <div className="flex flex-row justify-between text-lg font-bold">
            <div>Lista de colaboradores</div>
            <div onClick={() => setOpen(false)}>
              <XLg />
            </div>
          </div>
          <div className="">
            <ColaboradoresLibresToolbar />
            <ColaboradoresLibresTable />
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default ColaboradoresLibresSelectModal;
