import React from "react";
import userDefault from "src/modules/common/components/images/user_default.png";

export function FeedbackItem({
  id,
  type,
  className,
  jefe_directo = {},
  colaborador = {},
  title,
  descripcion,
  commentJefeDirecto = "",
  editJefeDirecto = true,
  commentColaborador = "",
  editColaborador = true,
  commentColaboradorView = true,
  onDelete,
  buttonDeleteView = false,
  onClickJefeDirecto,
  onClickColaborador,
  error = [],
}) {
  const errorJefeDirecto = error.find(
    (e) => e.comentario_tipo === "jefe_directo"
  );
  const errorColaborador = error.find(
    (e) => e.comentario_tipo === "colaborador"
  );
  const maxLengtComentario = 10000;
  const showError = () => {
    return (
      <div className="border border-[#E3C8C6] text-[#9C4B46] bg-[#EEDEDE]-500 p-2 rounded-md md:w-1/2">
        Este campo es requerido
      </div>
    );
  };
  return (
    <div id={id} className="border p-6 rounded-lg">
      <span className={`p-2 rounded-lg text-sm ${className}`}>{type}</span>
      <div className="mt-6">
        {title && (
          <div>
            <div className="text-md font-semibold">{title}</div>
            <div className="text-sm text-gray-600 mt-2 mb-4">{descripcion}</div>
          </div>
        )}
        <div className="flex flex-row gap-4">
          <div className="">
            <img
              className="object-cover h-14 w-14 rounded-md"
              src={
                jefe_directo.foto_empleado
                  ? jefe_directo.foto_empleado
                  : userDefault
              }
              alt={`Perfil`}
            />
          </div>
          <div className="flex flex-col w-full gap-6">
            <div className="flex flex-col w-full gap-2">
              <div className="flex flex-row justify-between">
                <span className="text-sm font-semibold text-gray-500">
                  Comentario del Jefe directo:
                </span>
                {editJefeDirecto && (
                  <span
                    className="text-sky-500 cursor-pointer"
                    onClick={onClickJefeDirecto}
                  >
                    Editar
                  </span>
                )}
              </div>
              <textarea
                className={`w-full h-40 p-2 border-gray-300 rounded-md border ${
                  editJefeDirecto ? "bg-white" : ""
                }`}
                disabled={true}
                value={commentJefeDirecto}
                maxLength={maxLengtComentario}
              />
              <div className="w-full flex justify-end">
                <span className="text-xs text-gray-500">
                  {commentJefeDirecto ? commentJefeDirecto.length : 0}/
                  {maxLengtComentario}
                </span>
              </div>
              {errorJefeDirecto && showError()}
            </div>
            {commentColaboradorView && (
              <div className="flex flex-col w-full gap-2">
                <div className="flex flex-row justify-between">
                  <span className="text-sm font-semibold text-gray-500">
                    Comentario del Colaborador:
                  </span>
                  {editColaborador && (
                    <span
                      className="text-sky-500 cursor-pointer"
                      onClick={onClickColaborador}
                    >
                      Editar
                    </span>
                  )}
                </div>
                <textarea
                  className={`w-full h-40 p-2 border-gray-300 rounded-md border ${
                    editColaborador ? "bg-white" : ""
                  }`}
                  disabled={true}
                  value={commentColaborador}
                  maxLength={maxLengtComentario}
                />
                <div className="w-full flex justify-end">
                  <span className="text-xs text-gray-500">
                    {commentColaborador ? commentColaborador.length : 0}/
                    {maxLengtComentario}
                  </span>
                </div>
                {errorColaborador && showError()}
              </div>
            )}
          </div>
        </div>
      </div>
      {buttonDeleteView && (
        <div className="mt-4">
          <span className="text-red-500 cursor-pointer" onClick={onDelete}>
            Eliminar
          </span>
        </div>
      )}
    </div>
  );
}
