import React, { useState } from "react";
import Cropper from "react-easy-crop";

function ImageCropper({ image, onCropDone, onCropCancel }) {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedArea, setCroppedArea] = useState(null);
  const [aspectRatio, setAspectRatio] = useState(1 / 1);

  const onCropComplete = (croppedAreaPercentage, croppedAreaPixels) => {
    setCroppedArea(croppedAreaPixels);
  };

  return (
    <div className="cropper z-20 relative w-full h-full bg-[#7f7f7f]">
      <div className="border flex justify-center">
        <Cropper
          image={image}
          aspect={aspectRatio}
          crop={crop}
          zoom={zoom}
          onCropChange={setCrop}
          onZoomChange={setZoom}
          onCropComplete={onCropComplete}
          style={{
            containerStyle: {
              width: "80%",
              height: "80%",
              backgroundColor: "#fff",
              display: "block",
              border: "1px solid red;",
              margin: "auto",
            },
          }}
        />
        <div className="flex gap-4 absolute bottom-0">
          <button
            className="p-2 bg-white rounded-md text-black"
            onClick={onCropCancel}
          >
            Cancelar
          </button>

          <button
            className="bg-sky-500 rounded-md text-white p-2"
            onClick={() => {
              onCropDone(croppedArea);
            }}
          >
            Recortar
          </button>
        </div>
      </div>
    </div>
  );
}

export default ImageCropper;
