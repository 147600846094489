import { push } from "@lagunovsky/redux-react-router";
import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import { ErrorToast } from "src/modules/common/components/ErrorToast";
import { SuccessToast } from "src/modules/common/components/SuccessToast";
import { getToken, ObjectToString } from "src/utils/helpers";
import { competenciaAreaApi } from "./api";
import { competenciaAreaActions } from "./redux";

function* competenciaAreaDeleteSaga({ payload }) {
  const token = getToken();
  const competencia_area = payload;

  const params = { ...competencia_area };
  !!process.env.REACT_APP_DEBUG && console.log({ payload });
  try {
    const data = yield call(competenciaAreaApi(token).delete, params);

    yield put(competenciaAreaActions.competenciaAreas());
    yield put(competenciaAreaActions.competenciaAreaDeleteSuccess());
  } catch (error) {
    yield put(competenciaAreaActions.competenciaAreaDeleteSuccess());
    !!process.env.REACT_APP_DEBUG && console.log({ error });
  }
}

function* competenciaAreaUpdateSaga({ payload }) {
  const token = getToken();
  const competencia_area = yield select(
    (state) => state.competenciaArea.competencia_area
  );
  const aplicar_pesos = yield select(
    (state) => state.competenciaArea.aplicar_pesos
  );
  // Verificacion de Pesos
  let { competencias } = competencia_area;
  const sumaPesos = competencias.reduce((sum, c) => sum + c.peso, 0);
  if (!aplicar_pesos) {
    competencias = competencias.map((comp, idx) => ({ ...comp, peso: 0 }));
  } else if (sumaPesos !== 100 && sumaPesos !== 0) {
    ErrorToast({
      message: "La suma de los pesos no son igual a 100%",
    });
    yield put(competenciaAreaActions.competenciaAreaCreateSuccess());
    return;
  }
  const params = { ...competencia_area, competencias: competencias };
  try {
    const data = yield call(competenciaAreaApi(token).update, params);
    //
    yield put(competenciaAreaActions.competenciaAreaUpdateSuccess());
    yield put(push("/competencias/area"));
    SuccessToast({ message: "Competencias actualizadas con exito!" });
    yield put(competenciaAreaActions.competenciaAreas());
  } catch (error) {
    yield put(competenciaAreaActions.competenciaAreaUpdateSuccess());
    ErrorToast({ message: "Error al actualizar competencias." });
    !!process.env.REACT_APP_DEBUG && console.log({ error });
  }
}

function* competenciaAreaCreateSaga({ payload }) {
  const token = getToken();
  const competencia_area = yield select(
    (state) => state.competenciaArea.competencia_area
  );
  const aplicar_pesos = yield select(
    (state) => state.competenciaArea.aplicar_pesos
  );
  // Verificacion de Pesos
  let { competencias } = competencia_area;
  const sumaPesos = competencias.reduce((sum, c) => sum + c.peso, 0);
  if (!aplicar_pesos) {
    competencias = competencias.map((comp, idx) => ({ ...comp, peso: 0 }));
  } else if (sumaPesos !== 100 && sumaPesos !== 0) {
    ErrorToast({
      message: "La suma de los pesos no son igual a 100%",
    });
    yield put(competenciaAreaActions.competenciaAreaCreateSuccess());
    return;
  }
  const params = { ...competencia_area, competencias: competencias };
  // API Call
  try {
    const data = yield call(competenciaAreaApi(token).create, params);
    yield put(competenciaAreaActions.competenciaAreaCreateSuccess());
    yield put(push("/competencias/area"));
    SuccessToast({message: "Grupo de competencias creada con exito!"});
    yield put(competenciaAreaActions.competenciaAreas());
  } catch (error) {
    yield put(competenciaAreaActions.competenciaAreaCreateSuccess());
    ErrorToast({ message: "Error al crear grupo de competencias." });
    !!process.env.REACT_APP_DEBUG && console.log({ error });
  }
}

function* competenciaAreaDetailSaga({ payload }) {
  const token = getToken();
  //
  try {
    const data = yield call(competenciaAreaApi(token).detail, payload);
    //
    yield put(competenciaAreaActions.competenciaAreaSet(data));
    // yield put(competenciaAreaActions.competenciaAreaUpdateSuccess());
    // yield put(push("/competencias/area"));
    // yield put(competenciaAreaActions.competenciaAreas());
  } catch (error) {
    yield put(competenciaAreaActions.competenciaAreaUpdateSuccess());
    !!process.env.REACT_APP_DEBUG && console.log({ error });
  }
}

function* competenciaAreasSaga({ payload }) {
  const token = getToken();
  const competenciaAreas_filter = yield select(
    (state) => state.competenciaArea.competencia_areas_filter
  );
  try {
    const data = yield call(competenciaAreaApi(token).list, {
      competenciaAreas_filter,
    });
    !!process.env.REACT_APP_DEBUG &&
      console.info("saga competenciaAreas data", data);
    yield put(
      competenciaAreaActions.setCompetenciaAreas({
        competencia_areas: data,
      })
    );
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
  }
}

function* competenciaAreasDeleteSaga({ payload }) {
  const token = getToken();
  const competencias_area_ids = yield select(
    (state) => state.competenciaArea.competencia_areas_delete
  );
  const params = { competencias_area_ids: competencias_area_ids };
  try {
    const data = yield call(
      competenciaAreaApi(token).competenciasAreaDelete,
      params
    );
    //
    yield put(competenciaAreaActions.competenciaAreas());
    SuccessToast({ message: "Competencias eliminadas con éxito!" });
  } catch (error) {
    // yield put(kpiActions.colaboradorKpiDeleteSuccess());
    !!process.env.REACT_APP_DEBUG && console.log({ error });
    const errores = error.response.data;
    const message = ObjectToString(errores);
    ErrorToast({ message: message });
  }
}

function* getCompetenciasBaseSaga({ payload }) {
  const token = getToken();
  const params = { ...payload };
  try {
    const data = yield call(
      competenciaAreaApi(token).libreriaEspecifica,
      params
    );
    yield put(competenciaAreaActions.setCompetenciasBase(data.results));
    yield put(competenciaAreaActions.getCompetenciasBaseComplete());
  } catch (error) {
    const errores = error.response.data;
    const message = ObjectToString(errores);
    ErrorToast({ message: message });
    yield put(competenciaAreaActions.getCompetenciasBaseComplete());
  }
}

export function* competenciaAreaSagas() {
  // create
  yield takeEvery(
    competenciaAreaActions.competenciaAreaCreate.type,
    competenciaAreaCreateSaga
  );
  // update
  yield takeEvery(
    competenciaAreaActions.competenciaAreaUpdate.type,
    competenciaAreaUpdateSaga
  );
  // delete
  yield takeEvery(
    competenciaAreaActions.competenciaAreaDelete.type,
    competenciaAreaDeleteSaga
  );
  yield takeEvery(
    competenciaAreaActions.setCompetenciaAreasDelete.type,
    competenciaAreasDeleteSaga
  );
  // list
  yield takeLatest(
    competenciaAreaActions.competenciaAreas.type,
    competenciaAreasSaga
  );
  yield takeLatest(
    competenciaAreaActions.setCompetenciaAreasFilter.type,
    competenciaAreasSaga
  );
  //
  yield takeEvery(
    competenciaAreaActions.getCompetenciaArea.type,
    competenciaAreaDetailSaga
  );

  //
  yield takeEvery(
    competenciaAreaActions.getCompetenciasBase.type,
    getCompetenciasBaseSaga
  );
}
