import React from "react";
import { FormacionPage } from "../pages/FormacionPage";
import { FormacionContenidoPage } from "../pages/FormacionContenidoPage";
import { TutorialPage } from "../pages/TutorialPage";
import { TutorialContenidoPage } from "../pages/TutorialContenidoPage";

export const aprendizajeRoutes = [
  //Formación
  {
    path: "/aprendizaje/formacion",
    // @ts-ignore
    element: <FormacionPage />,
  },
  {
    path: "/aprendizaje/formacion/contenido/:idContenido",
    // @ts-ignore
    element: <FormacionContenidoPage />,
  },
  //Tutoriales
  {
    path: "/aprendizaje/tutoriales",
    // @ts-ignore
    element: <TutorialPage />,
  },
  {
    path: "/aprendizaje/tutorial/contenido/:idContenido",
    // @ts-ignore
    element: <TutorialContenidoPage />,
  },
];
