import React from "react";
import CompetenciaCargoFormPage from "src/modules/competencia/modules/cargo/pages/CompetenciaCargoFormPage";
import CompetenciaCargoPage from "src/modules/competencia/modules/cargo/pages/CompetenciaCargoPage";

export const competenciasCargoRoutes = [
  {
    path: "/competencias/cargo",
    element: <CompetenciaCargoPage />,
  },
  {
    path: "/competencias/cargo/create",
    element: <CompetenciaCargoFormPage />,
  },
  {
    path: "/competencias/cargo/:idCompetenciaCargo/edit",
    element: <CompetenciaCargoFormPage />,
  },
  {
    path: "/competencias/cargo/:idCompetenciaCargo/view",
    element: <CompetenciaCargoFormPage />,
  },
];
