import axios from "axios";
import dayjs from "dayjs";
import React, { useEffect, useRef, useState } from "react";
import {
  ClockHistory,
  EyeFill,
  FileEarmarkArrowDownFill,
  XLg,
} from "react-bootstrap-icons";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import ReactSelect from "react-select";
import { colaboradorActions } from "src/modules/colaborador/modules/colaborador/handlers/redux";
import CollapseCard from "src/modules/common/components/CollapseCard";
import DataTable from "src/modules/common/components/DataTable";
import { ErrorToast } from "src/modules/common/components/ErrorToast";
import backButton from "src/modules/common/components/images/back-arrow.png";
import Modal from "src/modules/common/components/Modal";
import MostrarPaginas from "src/modules/common/components/MostrarPaginas";
import { PrimaryButton } from "src/modules/common/components/PrimaryButton";
import SelectModal from "src/modules/common/components/SelectModal";
import { SuccessToast } from "src/modules/common/components/SuccessToast";
import { commonActions } from "src/modules/common/handler/redux";
import SearchInput from "src/modules/remuneracion/components/SearchInput";
import { formatNumber, getToken } from "src/utils/helpers";
import KPIHistorial from "../components/KPIHistorial";
import { kpiActions } from "../handlers/redux";
import userDefault from "../images/user_default.png";
import KpiColaboradorForm from "./KpiColaboradorForm";

const initialKPI = {
  id: null,
  nombre: "",
  descripcion: "",
  periodo: null,
  metrica: null,
  meta: null,
  peso: 0.0,
  rangos: [],
};

function KpiColaboradoresDetailPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const { idColaborador } = params;
  //
  const colaborador = useSelector((state) => state.colaborador.colaborador);
  const kpis = useSelector((state) => state.kpi.colaboradorKpis);
  const colaboradorKpis_loading = useSelector(
    (state) => state.kpi.colaboradorKpis_loading
  );
  // NO VIGENTES / HISTORICO
  const kpisNoVigentes = useSelector(
    (state) => state.kpi.colaboradorKpisNoVigentes
  );
  const kpisNoVigentesChecked = useSelector(
    (state) => state.kpi.colaboradorKpisNoVigentes_checked
  );
  const kpisNoVigentes_count = useSelector(
    (state) => state.kpi.colaboradorKpisNoVigentes_count
  );
  const kpisNoVigentes_loading = useSelector(
    (state) => state.kpi.colaboradorKpisNoVigentes_loading
  );
  const kpisNoVigentes_filter = useSelector(
    (state) => state.kpi.colaboradorKpisNoVigentes_filter
  );
  //
  const [isEditing, setIsEditing] = useState(false);
  const [kpisData, setKpisData] = useState([]);
  const [kpisArchivados, setKpisArchivados] = useState([]);
  const [kpiForm, setKpiForm] = useState(initialKPI);
  const [kpiFormIndex, setKpiFormIndex] = useState(null);
  const [kpiHistory, setKpiHistory] = useState([]);
  const [showHistoryModal, setShowHistoryModal] = useState(false);
  //
  const [downloadLoading, setDownloadLoading] = useState(false);
  const kpisContainerRef = useRef(null);

  const scrollToComponent = () => {
    kpisContainerRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const onSave = () => {
    let sumaPesos = 0;
    kpisData.forEach((kpi, kidx) => (sumaPesos += kpi.peso));
    if (sumaPesos !== 0 && sumaPesos !== 100) {
      ErrorToast({ message: "La suma de los pesos debe ser de 100%" });
      return;
    }
    //
    const params = {
      id: idColaborador,
      kpis: kpisData,
      archivados: kpisArchivados.map((kpi, i) => kpi.id),
    };
    dispatch(kpiActions.colaboradorUpdateKpis(params));
    //
    setIsEditing(false);
  };

  const onCancel = () => {
    setKpisData(kpis?.results);
    setIsEditing(false);
    undoRestoreKpis();
  };

  const showKpiMeta = (kpi) => {
    if (kpi.metrica_display === "Selección simple") return "Logro/No logro";
    return kpi.meta
      ? `${formatNumber(kpi.meta).toLocaleString("de-DE")}`
      : kpi.rangos.length > 0
      ? "Por escala"
      : "Sin definir";
  };

  const showEscala = (kpi) => {
    const { rangos } = kpi;
    return (
      <div className="group flex justify-center items-center border rounded-md p-1">
        <EyeFill></EyeFill>
        <div className="border rounded-md p-2 bg-white z-10 hidden group-hover:block group-hover:absolute bottom-0 left-28">
          <div className="flex gap-2 font-bold text-sm">
            <div className="flex-1">Desde</div>
            <div className="flex-1">Hasta</div>
            <div className="flex-1">Resultado</div>
          </div>
          {rangos.map((r, i) => (
            <div className="flex gap-2 text-sm">
              <div className="flex-1">
                {r.desde ? r.desde.toLocaleString("de-DE") : r.desde}
              </div>
              <div className="flex-1">
                {r.hasta ? r.hasta.toLocaleString("de-DE") : r.hasta}
              </div>
              <div className="flex-1">
                {r.resultado
                  ? r.resultado.toLocaleString("de-DE")
                  : r.resultado}
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const renderCreadoPor = (kpi) => {
    return (
      <div className="flex flex-row gap-1 items-center">
        {!kpi.creado_por?.foto_empleado && (
          <div className="h-8 w-8 rounded-lg bg-zinc-200"></div>
        )}
        {!!kpi.creado_por?.foto_empleado && (
          <div className="w-8 bg-slate-200 rounded-md">
            <img
              className="object-cover h-8 w-8 rounded-md"
              src={kpi.creado_por?.foto_empleado}
              alt={`Foto Jefe de Lider`}
            />
          </div>
        )}
        <div className="font-semibold">
          {kpi.creado_por
            ? `${kpi.creado_por.nombre} ${kpi.creado_por.apellido}`
            : "Sin registro"}
        </div>
      </div>
    );
  };

  const onCloseHistoryModal = () => {
    setKpiHistory(null);
    setShowHistoryModal(false);
  };

  const restoreKpi = (kpi) => {
    const kpisDataCopy = JSON.parse(JSON.stringify(kpisData));
    kpisDataCopy.push({ ...kpi, aRestaurar: true });
    setKpisData(kpisDataCopy);
    //
    let kpisNoVigentesCopy = JSON.parse(JSON.stringify(kpisNoVigentes));
    kpisNoVigentesCopy = kpisNoVigentesCopy.filter(
      (kpinv, kidx) => kpinv.id !== kpi.id
    );
    const payload = {
      results: kpisNoVigentesCopy,
      count: kpisNoVigentesCopy.length,
    };
    dispatch(kpiActions.setColaboradorKpisNoVigentesSimple(payload));
    //
    setIsEditing(true);
    scrollToComponent();
  };

  const restoreKpis = (kpisToRestore) => {
    const kpisDataCopy = JSON.parse(JSON.stringify(kpisData));
    kpisToRestore.forEach((kpi) => {
      kpisDataCopy.push({ ...kpi, aRestaurar: true });
    });
    setKpisData(kpisDataCopy);
    let kpisNoVigentesCopy = JSON.parse(JSON.stringify(kpisNoVigentes));
    kpisNoVigentesCopy = kpisNoVigentesCopy.filter(
      (kpinv) => !kpisToRestore.some((kpi) => kpinv.id === kpi.id)
    );
    const payload = {
      results: kpisNoVigentesCopy,
      count: kpisNoVigentesCopy.length,
    };
    dispatch(kpiActions.setColaboradorKpisNoVigentesSimple(payload));
    setIsEditing(true);
    scrollToComponent();
  };

  const undoRestoreKpis = () => {
    let kpisDataCopy = JSON.parse(JSON.stringify(kpisData));
    let kpisNoVigentesCopy = JSON.parse(JSON.stringify(kpisNoVigentes));
    //
    kpisDataCopy = kpisDataCopy.filter((kpi) => {
      if (kpi.aRestaurar) {
        const { aRestaurar, ...kpiWithoutARestaurar } = kpi;
        kpisNoVigentesCopy.push(kpiWithoutARestaurar);
        return false;
      }
      return true;
    });
    //
    setKpisData(kpisDataCopy);
    //
    const payload = {
      results: kpisNoVigentesCopy,
      count: kpisNoVigentesCopy.length,
    };
    dispatch(kpiActions.setColaboradorKpisNoVigentesSimple(payload));
    //
    setIsEditing(false);
    // scrollToComponent();
  };

  const datatableConfig = {
    headers: [
      {
        title: "Lista de KPIs",
        contentClass: "px-2 py-4",
        accessor: "nombre",
      },
      {
        title: "Métrica",
        accessor: "metrica_display",
      },
      {
        title: "Frecuencia",
        accessor: "periodo_display",
      },
      {
        title: "Creado",
        accessor: "creado_por.nombre",
        contentRenderer: (item) => (
          <div>
            {!!item.fecha_creacion
              ? dayjs(item.fecha_creacion.slice(0, 10)).format("DD/MM/YYYY")
              : "Sin registro"}
          </div>
        ),
      },
    ],
    rowActions: [
      {
        label: "Restaurar",
        action: (item) => {
          restoreKpi(item);
        },
      },
    ],
  };

  const actionOptions = [
    {
      value: "restaurar",
      label: "Restaurar",
      // action: () => {},
    },
  ];

  const onAction = (v) => {
    if (v.value === "restaurar") {
      if (kpisNoVigentesChecked.length < 1) {
        ErrorToast({ message: "Debe seleccionar al menos un KPI." });
        return;
      }
      const deseaRestaurar = window.confirm(
        "¿Está seguro de restaurar los KPIs seleccionados?"
      );
      if (deseaRestaurar) {
        restoreKpis(kpisNoVigentesChecked);
        dispatch(kpiActions.setKpiNoVigentesChecked([]));
      }
      return;
    }
  };

  const onCheckKpiNoVigente = (item) => (event) => {
    dispatch(
      kpiActions.checkKpiNoVigente({
        kpi: item,
        checked: event.target.checked,
      })
    );
  };

  const onCheckKpiNoVigenteAll = (event) => {
    const checked = event.target.checked;
    dispatch(kpiActions.setKpiNoVigentesChecked(checked ? kpisNoVigentes : []));
  };

  useEffect(() => {
    dispatch(commonActions.setTitleMenu([]));
    dispatch(commonActions.setTitle(""));
    dispatch(commonActions.showSidebar(false));
    //
    if (idColaborador) {
      let item = { id: idColaborador };
      dispatch(colaboradorActions.colaboradorGet(item));
      dispatch(kpiActions.colaboradorKpis(item));
      dispatch(kpiActions.getColaboradorKpisNoVigentes(item));
    }
  }, []);
  const { results: kpisResults = [] } = kpis;

  useEffect(() => {
    setKpisData(kpisResults);
    setKpisArchivados([]);
  }, [kpisResults]);

  useEffect(() => {
    const kpisActualizados = kpisData.filter((kpi) => kpi.vigente === "false");
    if (kpisActualizados.length > 0) {
      setKpisArchivados((prevArchivados) => [
        ...prevArchivados,
        ...kpisActualizados,
      ]);
      setKpisData(kpisData.filter((kpi) => kpi.vigente !== "false"));
    }
  }, [kpisData]);

  useEffect(() => {
    // Verificar cambios en kpisArchivados
    const kpisActualizados = kpisArchivados.filter(
      (kpi) => kpi.vigente === true
    );
    if (kpisActualizados.length > 0) {
      setKpisData((prevData) => [...prevData, ...kpisActualizados]);
      setKpisArchivados((prevArchivados) =>
        prevArchivados.filter((kpi) => kpi.vigente === "false")
      );
    }
  }, [kpisArchivados]);

  const KPISkeletons = () => {
    return (
      <div className="flex flex-col gap-3 animate-pulse mt-4">
        <div className="h-20 border rounded-md bg-slate-200"></div>
        <div className="h-20 border rounded-md bg-slate-200"></div>
        <div className="h-20 border rounded-md bg-slate-200"></div>
      </div>
    );
  };

  const onAgregarKPI = () => {
    setKpiForm(initialKPI);
    setKpiFormIndex(null);
    dispatch(commonActions.setSelectModal({ open: true }));
  };

  const agregarKpi = (index) => (kpi) => {
    let kpisDataCopy = JSON.parse(JSON.stringify(kpisData));
    //
    if (!!index || index === 0) {
      kpisDataCopy[index] = kpi;
    } else {
      kpisDataCopy.push({ ...kpi });
    }
    setKpisData(kpisDataCopy);
    setKpiForm(initialKPI);
    dispatch(commonActions.setSelectModal({ open: false }));
    SuccessToast({
      message: !!index ? "KPI editado" : "KPI agregado al listado",
    });
  };

  const renderKpisColaboradorList = () => {
    const onChangePeso = (index) => (e) => {
      const value = Number(e.target.value);
      if (value < 0 || value > 100) {
        return;
      }
      let kpisDataCopy = JSON.parse(JSON.stringify(kpisData));
      kpisDataCopy[index].peso = value;
      setKpisData(kpisDataCopy);
    };
    const onEditar = (kpi, index) => () => {
      setKpiForm(kpi);
      setKpiFormIndex(index);
      dispatch(commonActions.setSelectModal({ open: true }));
    };

    const onDeleteKPI = (id) => () => {
      let deseaEliminar = true;
      if (!!deseaEliminar) {
        deseaEliminar = window.confirm("¿Está seguro de eliminar este KPI?");
      }
      if (!!deseaEliminar) {
        const newKPIS = kpisData.filter((kpi, kidx) => kidx !== id);
        setKpisData(newKPIS);
      }
    };
    const onVerHistorial = (kpi) => () => {
      setKpiHistory(kpi);
      setShowHistoryModal(true);
    };
    //
    return kpisData.map((kpi, kidx) => (
      <CollapseCard
        key={`kpi-${kidx}-${kpi.id}`}
        titulo={kpi.nombre}
        extraClass="mb-8"
        titleClass={"font-semibold"}
        initCollapse={false}
        extraEndContent={
          true ? (
            <div className="flex justify-end items-center gap-2 text-sm">
              <input
                disabled={!isEditing}
                type="number"
                value={kpi.peso}
                onChange={onChangePeso(kidx)}
                className="bg-slate-300x border border-slate-400 outline-none rounded-md p-2 w-2/3"
              />
              <div>%</div>
            </div>
          ) : (
            ""
          )
        }
      >
        <div className="flex flex-col gap-2">
          <div>{kpi.descripcion}</div>
          <div className="flex">
            <div className="flex flex-col flex-1">
              <div className="font-semibold">Tiempo</div>
              <div>{kpi.periodo_display}</div>
            </div>
            <div className="flex flex-col flex-1">
              <div className="font-semibold">Tipo de metrica</div>
              <div>{kpi.metrica_display}</div>
            </div>
          </div>
          <div className="flex relative">
            <div className="flex flex-col flex-1">
              <div className="font-semibold">Meta</div>
              <div className="flex gap-2">
                {showKpiMeta(kpi)}
                {showKpiMeta(kpi) === "Por escala" && (
                  <div>{showEscala(kpi)}</div>
                )}
              </div>
            </div>
            <div className="flex flex-col flex-1">
              <div className="font-semibold">Creado por</div>
              {renderCreadoPor(kpi)}
            </div>
          </div>
        </div>
        {!!isEditing && (
          <div className="flex mt-4 gap-2">
            <button
              type="button"
              className="rounded bg-sky-500 text-white px-3 py-1 text-sm"
              onClick={onEditar(kpi, kidx)}
            >
              Editar
            </button>
            <button
              type="button"
              className="rounded bg-red-500 text-white px-3 py-1 text-sm"
              onClick={onDeleteKPI(kidx)}
            >
              Eliminar
            </button>
          </div>
        )}
        {!isEditing && (
          <div className="flex mt-4 gap-2">
            <button
              onClick={onVerHistorial(kpi)}
              className="text-sm text-sky-500 flex item-center gap-1"
            >
              <ClockHistory className="mt-1" />
              <span>Ver historial</span>
            </button>
          </div>
        )}
      </CollapseCard>
    ));
  };

  const renderKpisArchivadosList = () => {
    const onVerHistorial = (kpi) => () => {
      setKpiHistory(kpi);
      setShowHistoryModal(true);
    };
    //
    return kpisArchivados.map((kpi, kidx) => (
      <CollapseCard
        key={`kpi-${kidx}-${kpi.id}`}
        titulo={kpi.nombre}
        extraClass="mb-8"
        titleClass={"font-semibold"}
        initCollapse={false}
      >
        <div className="flex flex-col gap-2">
          <div>{kpi.descripcion}</div>
          <div className="flex">
            <div className="flex flex-col flex-1">
              <div className="font-semibold">Tiempo</div>
              <div>{kpi.periodo_display}</div>
            </div>
            <div className="flex flex-col flex-1">
              <div className="font-semibold">Tipo de metrica</div>
              <div>{kpi.metrica_display}</div>
            </div>
          </div>
          <div className="flex relative">
            <div className="flex flex-col flex-1">
              <div className="font-semibold">Meta</div>
              <div className="flex gap-2">
                {showKpiMeta(kpi)}
                {showKpiMeta(kpi) === "Por escala" && (
                  <div>{showEscala(kpi)}</div>
                )}
              </div>
            </div>
            <div className="flex flex-col flex-1">
              <div className="font-semibold">Creado por</div>
              {renderCreadoPor(kpi)}
            </div>
          </div>
        </div>
        <div className="flex mt-4 gap-2">
          <button
            onClick={onVerHistorial(kpi)}
            className="text-sm text-sky-500 flex item-center gap-1"
          >
            <ClockHistory className="mt-1" />
            <span>Ver historial</span>
          </button>
        </div>
      </CollapseCard>
    ));
  };

  const downloadPDF = () => {
    const token = getToken();
    const URL_BASE =
      process.env.REACT_APP_API_URL || "http://localhost:8000/api/admin";
    setDownloadLoading(true);
    axios({
      url:
        URL_BASE + `/kpi/kpi-colaborador/colaborador/${idColaborador}/exportar`,
      method: "POST",
      responseType: "blob", // Indica que la respuesta será un archivo binario
      headers: {
        Authorization: `Bearer ${token}`, // Agregamos la cabecera de autorización con el token Bearer
      },
    })
      .then((response) => {
        const downloadUrl = window.URL.createObjectURL(
          new Blob([response.data])
        );
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.setAttribute("download", `colaborador-${idColaborador}-kpis.pdf`);
        document.body.appendChild(link);
        link.click();
        link.remove();
        setDownloadLoading(false);
      })
      .catch((error) => {
        console.error("Error al descargar el archivo PDF:", error);
        setDownloadLoading(false);
      });
  };

  const discardChanges = () => {
    let deseaSalir = window.confirm("¿Desea salir sin guardar los cambios?");
    return deseaSalir;
  };

  // DATATABLE UTILS
  const [Page, setPage] = useState(1);
  const [PageSize, setPageSize] = useState(10);
  const [outerPageSize, setOuterPageSize] = React.useState(50);

  const onSearchKPINoVigente = (event) => {
    dispatch(
      kpiActions.setColaboradorKpisNoVigentesFilter({
        ...kpisNoVigentes_filter,
        id: idColaborador,
        nombre: event.target.value,
      })
    );
  };

  const onChangePageSize = (e) => {
    let value = e.target.value;
    //
    dispatch(
      kpiActions.setColaboradorKpisNoVigentesFilter({
        ...kpisNoVigentes_filter,
        id: idColaborador,
        pagination: {
          pageSize: parseInt(value),
          page: 1,
        },
      })
    );
    //
    setOuterPageSize(parseInt(value));
  };

  const onPaginate = (page, pageSize) => {
    //
    dispatch(
      kpiActions.setColaboradorKpisNoVigentesFilter({
        ...kpisNoVigentes_filter,
        id: idColaborador,
        pagination: {
          page: page,
          pageSize: pageSize,
        },
      })
    );
  };

  return (
    <>
      {/* HEADER */}
      <div className="flex justify-between items-center bg-zinc-100 -mx-8 py-2.5 px-7 border-b">
        <div className="flex items-center">
          <div
            onClick={() => navigate("/kpis/colaboradores")}
            className={
              "h-full flex items-center pr-2 -mr-2 z-10 cursor-pointer"
            }
          >
            <img className="back" src={backButton} alt="Regresar" />
          </div>
          <div className="font-medium px-4 text-xl">KPIs del colaborador</div>
        </div>
      </div>
      {/*  */}
      <div className="flex justify-center items-center w-full mt-10">
        <div className="flex flex-col w-full md:w-5/6">
          <div className="grid grid-cols-1 md:grid-cols-12">
            {/* IDENTIFICADOR VISUAL */}
            <div className="col-span-1 md:col-span-4 flex flex-col  mb-4 md:mr-12">
              <span className="font-bold text-lg border-b mb-2 pb-5">
                Identificador del colaborador
              </span>
              {!colaborador.foto_empleado && (
                <div className="my-4">
                  <img src={userDefault} alt="Imagen del colaborador" />
                </div>
              )}
              {!!colaborador.foto_empleado && (
                <div className="w-1/2 bg-slate-200 rounded-md">
                  <img
                    className="object-cover h-full w-full rounded-md"
                    src={colaborador.foto_empleado}
                    alt={`pfp-${colaborador.id}`}
                  />
                </div>
              )}
              <div className="flex flex-col mt-4">
                <label className="font-bold font-md" htmlFor="">
                  {`${colaborador.nombre} ${colaborador.apellido}`}
                </label>
                <div>{`Cargo: ${
                  colaborador.cargo?.nombre || "Sin asignar"
                }`}</div>
              </div>
              {/*  */}
              <div className="flex">
                {!downloadLoading && (
                  <button
                    onClick={downloadPDF}
                    className="bg-sky-500 rounded-md text-white p-2 flex items-center gap-2"
                  >
                    <FileEarmarkArrowDownFill size={18} />
                    <span className="font-semibold">Exportar PDF</span>
                  </button>
                )}
                {!!downloadLoading && (
                  <button className="bg-sky-500 rounded-md text-white p-2 animate-pulse">
                    Generando informe...
                  </button>
                )}
              </div>
            </div>
            {/* KPIs Asignados */}
            <div className="col-span-1 md:col-span-8" ref={kpisContainerRef}>
              <div className="flex justify-between items-center border-b mb-2 pb-2">
                <div className="font-bold text-lg">
                  KPIs Asignados al colaborador
                </div>
                {!colaboradorKpis_loading && (
                  <div className="flex gap-2">
                    {!isEditing && (
                      <PrimaryButton
                        type="button"
                        onClick={() => setIsEditing(true)}
                      >
                        {!!kpis?.results && kpis?.results?.length > 0
                          ? "Editar"
                          : "Agregar"}
                      </PrimaryButton>
                    )}
                    {!!isEditing && (
                      <>
                        <PrimaryButton type="button" onClick={onSave}>
                          Guardar
                        </PrimaryButton>
                        <PrimaryButton
                          className="bg-red-500"
                          onClick={onCancel}
                        >
                          Cancelar
                        </PrimaryButton>
                      </>
                    )}
                  </div>
                )}
              </div>
              <div className="flex items-center justify-end text-lg mb-2 my-4">
                <div>
                  <span className="font-semibold">Total pesos: </span>
                  {kpisData.reduce(
                    (acumulador, objeto) => acumulador + objeto.peso,
                    0
                  )}
                  %
                </div>
              </div>
              {/* KPIS COLABORADOR */}
              {/* <ColaboradorKpiToolbar /> */}
              {!colaboradorKpis_loading &&
                kpisData.length > 0 &&
                renderKpisColaboradorList()}

              {!colaboradorKpis_loading && kpisData.length < 1 && (
                <div className="border rounded-md p-4 text-center my-2">
                  No existen KPIs para mostrar
                </div>
              )}

              {!!colaboradorKpis_loading && <KPISkeletons />}
              {!!isEditing && !colaboradorKpis_loading && (
                <div
                  className="flex flex-row gap-2 items-center pl-3"
                  onClick={onAgregarKPI}
                >
                  <div className="w-5 h-5 rounded-full bg-sky-500 text-white flex items-center justify-center text-2xl">
                    +
                  </div>
                  <div className="text-sky-500 text-sm">{`Agregar ${
                    kpis?.results?.length > 0 ? "otro" : ""
                  } KPI`}</div>
                </div>
              )}
              {/*  */}
              {!colaboradorKpis_loading && kpisArchivados.length > 0 && (
                <>
                  <div className="flex justify-between items-center border-b mt-8 mb-8 pb-2">
                    <div className="font-bold text-lg">KPIs No Vigentes</div>
                  </div>
                  {renderKpisArchivadosList()}
                </>
              )}
              <SelectModal
                title={`${!!kpiForm.id ? "Editar" : "Agregar"} KPI`}
                content={
                  <div className="max-h-[500px] overflow-auto custom-scrollbar">
                    <KpiColaboradorForm
                      kpiColaborador={kpiForm}
                      onSave={agregarKpi(kpiFormIndex)}
                    />
                  </div>
                }
                onClose={() => discardChanges()}
              />
              {/*  */}
              <Modal isOpen={showHistoryModal}>
                <div className="rounded-md bg-white p-4">
                  <div className="w-100 flex justify-between mb-4">
                    <div className="font-semibold text-xl">
                      Historial de KPI
                    </div>
                    <div onClick={onCloseHistoryModal}>
                      <XLg />
                    </div>
                  </div>
                  {/*  */}
                  <KPIHistorial {...kpiHistory} />
                </div>
              </Modal>
              {/* <ColaboradorKpiTable></ColaboradorKpiTable> */}
            </div>
          </div>
          {/* ============= KPIS HISTORICO =========== */}
          <div className="grid grid-cols-1 md:grid-cols-12 mb-8 mt-4">
            <div className="col-span-1 md:col-span-12 border-b mb-6 mt-4 pb-2">
              <div className="font-bold text-lg">Historial de KPI's</div>
            </div>
            <div className="col-span-1 md:col-span-6 mb-4 flex gap-4">
              <SearchInput
                placeholder="Buscar kpi..."
                value={kpisNoVigentes_filter.nombre}
                onChange={onSearchKPINoVigente}
              />
              <MostrarPaginas
                containerClass={"w-1/2x"}
                onChangePageSize={onChangePageSize}
              />
              <ReactSelect
                className="w-52 h-10 bg-blue-200 inline-grid"
                value={{
                  value: "",
                  label: `(${kpisNoVigentesChecked.length}) seleccionados`,
                }}
                options={actionOptions}
                onChange={onAction}
              ></ReactSelect>
            </div>
            <div className="col-span-1 md:col-span-12">
              <DataTable
                containerClass={"my-8x"}
                data={kpisNoVigentes}
                loading={kpisNoVigentes_loading}
                config={datatableConfig}
                // pagination
                totalRecords={kpisNoVigentes_count}
                onPaginate={onPaginate}
                outerPageSize={PageSize}
                // check config
                checkable={true}
                onCheckAll={onCheckKpiNoVigenteAll}
                checkedItems={kpisNoVigentesChecked}
                onItemCheck={onCheckKpiNoVigente}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default KpiColaboradoresDetailPage;
