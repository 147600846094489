import axios from "axios";
const URL_BASE =
  process.env.REACT_APP_API_URL || "http://localhost:8000/api/admin";

export const estructuraApi = (token) => {
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };

  return {
    totals: async () => {
      try {
        const totals = await axios.get(
          URL_BASE + "/estructura/estructura-totales/",
          {
            headers,
          }
        );
        !!process.env.REACT_APP_DEBUG &&
          console.info("totals.data", totals.data);
        return totals.data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log("totals error", error);
        throw error;
      }
    },
  };
};
