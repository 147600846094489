import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactSelect from "react-select";
import { Label } from "recharts";
import { colaboradorActions } from "../../colaborador/handlers/redux";

export const TipoContratacionSelect = ({
  value,
  defaultLabel = "Sin asignar",
  ...props
}) => {
  const dispatch = useDispatch();

  const tipos_contrataciones = useSelector(
    (state) => state.colaborador.tipos_contrataciones
  );
  const tipos_contrataciones_loading = useSelector(
    (state) => state.colaborador.tipos_contrataciones_loading
  );

  useEffect(() => {
    dispatch(colaboradorActions.getTiposContrataciones());
  }, []);

  let options = tipos_contrataciones.map((e) => ({
    value: e[0],
    label: e[1],
  }));

  options.unshift({ value: null, label: defaultLabel });

  const selected = options.find((v) => v.value === value);

  return (
    <ReactSelect
      {...props}
      onChange={(item) => props.onChange && props.onChange({ target: item })}
      value={selected}
      options={options}
    />
  );
};
