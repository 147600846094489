import dayjs from "dayjs";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import CollapseCard from "src/modules/common/components/CollapseCard";
import { ErrorToast } from "src/modules/common/components/ErrorToast";
import LoadingSpinButton from "src/modules/common/components/LoadingSpinButton";
import { PrimaryButton } from "src/modules/common/components/PrimaryButton";
import { commonActions } from "src/modules/common/handler/redux";
import { areaActions } from "src/modules/estructura/modules/area/handlers/redux";
import { cargoActions } from "src/modules/estructura/modules/cargo/handlers/redux";
import { departamentoActions } from "src/modules/estructura/modules/departamento/handlers/redux";
import { sucursalActions } from "src/modules/estructura/modules/sucursal/handlers/redux";
import ImportacionEstructura from "../components/ImportadorEstructura";
import { colaboradorActions } from "../handlers/redux";

const CollapseTable = ({ title = "Items", data, children }) => {
  return (
    <div className="flex flex-col justify-start w-full md:w-2/3">
      <CollapseCard
        initCollapse={false}
        titulo={title}
        titleClass={"font-semibold"}
        extraEndContent={
          <div className="flex items-center gap-3 rounded-md p-2 text-sm">
            <div className="flex flex-col items-center">
              <div className="font-bold">{data.totales.nuevos}</div>
              <div className="text-xs">Nuevos</div>
            </div>
            <div className="flex flex-col items-center">
              <div className="font-bold">{data.totales.duplicados}</div>
              <div className="text-xs">Duplicados</div>
            </div>
            <div className="flex flex-col items-center">
              <div className="font-bold">{data.totales.total}</div>
              <div className="text-xs">Total</div>
            </div>
          </div>
        }
      >
        {children}
      </CollapseCard>
    </div>
  );
};

function ImportacionGeneralPage() {
  const dispatch = useDispatch();
  const params = useParams();
  const { subject } = params;
  //
  const sucursalNuevosChecked = useSelector(
    (state) => state.sucursal.nuevosChecked
  );
  const areaNuevosChecked = useSelector((state) => state.area.nuevosChecked);
  const cargoNuevosChecked = useSelector((state) => state.cargo.nuevosChecked);
  const departamentoNuevosChecked = useSelector(
    (state) => state.departamento.nuevosChecked
  );
  const colaboradorNuevosChecked = useSelector(
    (state) => state.colaborador.nuevosChecked
  );
  //
  const sucursalDuplicadosChecked = useSelector(
    (state) => state.sucursal.duplicadosChecked
  );
  const areaDuplicadosChecked = useSelector(
    (state) => state.area.duplicadosChecked
  );
  const cargoDuplicadosChecked = useSelector(
    (state) => state.cargo.duplicadosChecked
  );
  const departamentoDuplicadosChecked = useSelector(
    (state) => state.departamento.duplicadosChecked
  );
  const colaboradorDuplicadosChecked = useSelector(
    (state) => state.colaborador.duplicadosChecked
  );
  const importar_destino = useSelector(
    (state) => state.colaborador.importar_destino
  );
  //
  useEffect(() => {
    dispatch(commonActions.setTitle(`Importador de nómina`));
    dispatch(commonActions.showSidebar(false));
    dispatch(
      commonActions.setSecondBar({
        hasSecondBar: true,
        secondBarTitle: "Datos recolectados",
        secondBarBackRoute: "/nomina",
      })
    );
    //
  }, []);

  // @ts-ignore
  const data = useSelector((state) => state.colaborador.datosVerificados) || {};
  const { sucursales, areas, departamentos, cargos, colaboradores } = data;
  const importar_loading = useSelector(
    (state) => state.colaborador.importar_loading
  );
  //
  useEffect(() => {
    dispatch(cargoActions.setDatosVerificados(cargos));
    dispatch(areaActions.setDatosVerificados(areas));
    dispatch(departamentoActions.setDatosVerificados(departamentos));
    dispatch(sucursalActions.setDatosVerificados(sucursales));
  }, [data]);

  const getImportarDatos = (subject) => {
    const getNuevosChecked = (subject) => {
      let nuevosChecked = [];
      switch (subject) {
        case "sucursal":
          nuevosChecked = JSON.parse(JSON.stringify(sucursalNuevosChecked));
          break;
        case "area":
          nuevosChecked = JSON.parse(JSON.stringify(areaNuevosChecked));
          break;
        case "cargo":
          nuevosChecked = JSON.parse(JSON.stringify(cargoNuevosChecked));
          break;
        case "departamento":
          nuevosChecked = JSON.parse(JSON.stringify(departamentoNuevosChecked));
          break;
        case "colaborador":
          nuevosChecked = JSON.parse(JSON.stringify(colaboradorNuevosChecked));
          break;
        default:
          nuevosChecked = [];
      }
      return nuevosChecked;
    };
    const getDuplicadosChecked = (subject) => {
      let duplicadosChecked = [];
      switch (subject) {
        case "sucursal":
          duplicadosChecked = JSON.parse(
            JSON.stringify(sucursalDuplicadosChecked)
          );
          break;
        case "area":
          duplicadosChecked = JSON.parse(JSON.stringify(areaDuplicadosChecked));
          break;
        case "cargo":
          duplicadosChecked = JSON.parse(
            JSON.stringify(cargoDuplicadosChecked)
          );
          break;
        case "departamento":
          duplicadosChecked = JSON.parse(
            JSON.stringify(departamentoDuplicadosChecked)
          );
          break;
        case "colaborador":
          duplicadosChecked = JSON.parse(
            JSON.stringify(colaboradorDuplicadosChecked)
          );
          break;
        default:
          duplicadosChecked = [];
      }
      return duplicadosChecked;
    };
    let nuevosChecked = getNuevosChecked(subject);
    let duplicadosChecked = getDuplicadosChecked(subject);
    //
    nuevosChecked = nuevosChecked.map((item, idx) => ({ ...item, id: null }));
    duplicadosChecked = duplicadosChecked.map((item, idx) => ({
      id: item.anterior.id,
      ...item.nuevo,
    }));
    //
    let datosImportar = [...nuevosChecked, ...duplicadosChecked];
    if (subject === "colaborador") {
      datosImportar = datosImportar.map((item, idx) => ({
        ...item,
        // La fecha puede venir de varias maneras
        // Cuando la longitud es mayor a 10, la fecha viene en formato "2021-03-05T00:00:00"
        // Cuando la longitud es igual a 10, la fecha viene en formato "16/06/2022"
        fecha_nacimiento:
          item.fecha_nacimiento.length > 10
            ? dayjs(item.fecha_nacimiento.slice(0, 10)).format("DD/MM/YYYY") !==
              "Invalid Date"
              ? dayjs(item.fecha_nacimiento.slice(0, 10)).format("DD/MM/YYYY")
              : item.fecha_nacimiento
            : item.fecha_nacimiento,
        //
        fecha_ingreso:
          item.fecha_ingreso.length > 10
            ? dayjs(item.fecha_ingreso.slice(0, 10)).format("DD/MM/YYYY") !==
              "Invalid Date"
              ? dayjs(item.fecha_ingreso.slice(0, 10)).format("DD/MM/YYYY")
              : item.fecha_ingreso
            : item.fecha_ingreso,
      }));
    }
    //
    return datosImportar;
  };
  //
  function normalizeDate(dateStr) {
    // Normalizar las fechas para asegurarte de que siempre tengan dos dígitos para el día y el mes.
    const [day, month, year] = dateStr
      .split("/")
      .map((num) => num.padStart(2, "0"));
    return `${day}/${month}/${year}`;
  }
  const importarDatos = () => {
    let datosImportar = {};
    const sucursalImportar = getImportarDatos("sucursal");
    const areaImportar = getImportarDatos("area");
    const departamentoImportar = getImportarDatos("departamento");
    const cargoImportar = getImportarDatos("cargo");
    const colaboradorImportar = getImportarDatos("colaborador");
    const hayRegistros =
      sucursalImportar.length +
      areaImportar.length +
      departamentoImportar.length +
      cargoImportar.length +
      colaboradorImportar.length;
    if (hayRegistros < 1) {
      ErrorToast({ message: "No se eligieron datos para importar." });
      return;
    }
    //

    // const colaboradorParsedFechas = colaboradorImportar.map((colab, cidx) => ({
    //   ...colab,
    //   fecha_ingreso: colab.fecha_ingreso || null,
    //   fecha_nacimiento: colab.fecha_nacimiento || null,
    // }));
    //
    datosImportar = {
      sucursales: sucursalImportar,
      areas: areaImportar,
      departamentos: departamentoImportar,
      cargos: cargoImportar,
      colaboradores: colaboradorImportar,
    };
    //
    if (importar_destino === "general") {
      dispatch(colaboradorActions.setDatosImportarGeneral(datosImportar));
    } else if (importar_destino === "general-full") {
      dispatch(colaboradorActions.setDatosImportarGeneralFull(datosImportar));
    }
  };
  //
  return (
    <>
      <div className="body flex flex-col gap-8 items-center justify-center mt-8">
        {!importar_loading && (
          <>
            {!!sucursales && (
              <CollapseTable title="Sucursales" data={sucursales}>
                <ImportacionEstructura
                  subject={"sucursal"}
                ></ImportacionEstructura>
              </CollapseTable>
            )}
            {!!areas && (
              <CollapseTable title="Áreas" data={areas}>
                <ImportacionEstructura subject={"area"}></ImportacionEstructura>
              </CollapseTable>
            )}
            {!!departamentos && (
              <CollapseTable title="Departamentos" data={departamentos}>
                <ImportacionEstructura
                  subject={"departamento"}
                ></ImportacionEstructura>
              </CollapseTable>
            )}
            {!!cargos && (
              <CollapseTable title="Cargos" data={cargos}>
                <ImportacionEstructura
                  subject={"cargo"}
                ></ImportacionEstructura>
              </CollapseTable>
            )}
            {!!colaboradores && (
              <CollapseTable title="Colaboradores" data={colaboradores}>
                <ImportacionEstructura
                  subject={"colaborador"}
                ></ImportacionEstructura>
              </CollapseTable>
            )}
            <PrimaryButton
              className="w-full md:w-2/3 mb-8 bg-sky-500"
              onClick={importarDatos}
            >
              Importar datos
            </PrimaryButton>
          </>
        )}
        {!!importar_loading && (
          <div className="flex items-center justify-center h-80">
            <LoadingSpinButton></LoadingSpinButton>
          </div>
        )}
      </div>
    </>
  );
}

export default ImportacionGeneralPage;
