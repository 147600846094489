import { all } from "redux-saga/effects";
import { colaboradorSagas } from "src/modules/colaborador/modules/colaborador/handlers/sagas";
import { colaboradorLaboralSagas } from "src/modules/colaborador/modules/laboral/handlers/sagas";
import { colaboradorDesempenoSagas } from "../modules/desempeno/handlers/sagas";

export function* colaboradorRootSagas() {
  yield all([
    colaboradorSagas(),
    colaboradorLaboralSagas(),
    colaboradorDesempenoSagas(),
  ]);
}
