import React, { useState } from "react";
import { TrashFill } from "react-bootstrap-icons";
import Input from "src/modules/common/components/Input";
import { PrimaryButton } from "src/modules/common/components/PrimaryButton";
import { beneficioActions } from "../handlers/redux";

export function BeneficioConfiguracionFormEdit(props) {
  const {
    beneficio = {},
    setOpen = () => {},
    open = false,
    onSubmit = () => {},
    idx = null,
  } = props;
  const [beneficioLocal, setBeneficioLocal] = useState({ ...beneficio });
  const [checked, setChecked] = useState(!beneficioLocal.vigente);
  // Los campos de requisitos que iniciaran al mostrar el modal
  const [requisitos, setRequisitos] = useState(beneficioLocal.requisitos);

  const onChange =
    (field) =>
    ({ target }) => {
      let value = target.value;
      const isNumber = !isNaN(field); // Validar si el valor es un número, si es número entonces se está recibiendo el id de un requisito
      if (!isNumber) {
        if (field === "vigente") {
          value = !target.checked;
          setChecked(!value);
        }
        setBeneficioLocal({
          ...beneficioLocal,
          [field]: value,
        });
      } else {
        // Se realiza para guardar el requisito
        let requisitosCopy = [...requisitos];
        // field contiene la posición en el array del requisito que se está agregando
        requisitosCopy[field] = { descripcion: value };
        setRequisitos(requisitosCopy);
        setBeneficioLocal({
          ...beneficioLocal,
          requisitos: requisitosCopy,
        });
      }
      console.log("beneficioLocal", beneficioLocal);
    };

  const onDeleteRequisito = (id) => {
    let requisitosCopy = [...requisitos];
    delete requisitosCopy[id];
    requisitosCopy = requisitosCopy.filter(
      (requisito) => requisito.descripcion !== undefined
    );
    setRequisitos(requisitosCopy);
    setBeneficioLocal({
      ...beneficioLocal,
      requisitos: requisitosCopy,
    });
  };

  const onAgregarRequisito = () => {
    const requisitosCopy = [...requisitos];
    requisitosCopy.push({ descripcion: "" });
    setRequisitos(requisitosCopy);
  };

  return (
    <>
      <div className="flex justify-center items-center w-full mt-1">
        <div className="flex flex-col w-full md:w-11/12">
          <div className="grid grid-cols-1 md:grid-cols-12 w-full">
            <div className="col-span-1 md:col-span-12 w-full">
              <form className="w-full">
                <div className="grid grid-cols-1 w-full">
                  <div className="flex flex-col gap-2 w-full">
                    <label className="font-bold text-sm" htmlFor="">
                      Nombre
                    </label>
                    <input
                      type={"text"}
                      name={"nombre"}
                      defaultValue={beneficioLocal.nombre}
                      onChange={onChange("nombre")}
                      placeholder={"Nombre"}
                      className="border rounded placeholder-zinc-600 border-zinc-200 px-2 py-3 text-sm w-full"
                    />
                  </div>
                </div>
                <div className="grid grid-cols-1 mt-4 w-full">
                  <div className="flex flex-col gap-2 w-full">
                    <label className="font-bold text-sm" htmlFor="">
                      Descripción
                    </label>
                    <input
                      type={"text"}
                      name={"descripcion"}
                      defaultValue={beneficioLocal.descripcion}
                      onChange={onChange("descripcion")}
                      placeholder={"Descripción"}
                      className="border rounded placeholder-zinc-600 border-zinc-200 px-2 py-3 text-sm w-full"
                    />
                  </div>
                </div>
                <div className="flex flex-col gap-2">
                  <div className="text-sm mt-4 font-bold">Requisitos</div>{" "}
                  <div className="text-sm">
                    Si se requiere, puede indicar algunos requisitos para
                    obtenerlo
                  </div>
                  {requisitos.map((requisito, idx) => (
                    <div key={idx} className="flex flex-row gap-2 w-full">
                      <Input
                        className="w-full"
                        onChange={onChange(idx)}
                        value={requisito.descripcion || ""}
                      />
                      <div>
                        <button
                          type="button"
                          onClick={() => onDeleteRequisito(idx)}
                          className="flex items-center justify-center h-10 w-10 rounded border border-zinc-200 hover:bg-zinc-50"
                        >
                          <TrashFill />
                        </button>
                      </div>
                    </div>
                  ))}
                  <div
                    className="flex flex-row gap-2 items-center"
                    onClick={onAgregarRequisito}
                  >
                    <div className="w-5 h-5 rounded-full bg-sky-500 text-white flex items-center justify-center text-2xl cursor-pointer">
                      +
                    </div>
                    <div className="text-sky-500 text-sm cursor-pointer">
                      Agregar otro requisito
                    </div>
                  </div>
                </div>
                <div className="flex flex-col gap-2">
                  <div className="text-sm mt-4 font-bold">Estado</div>{" "}
                  <div className="flex items-start">
                    <div>
                      <input
                        className="w-5 h-5 mr-2"
                        type="checkbox"
                        checked={checked}
                        onChange={onChange("vigente")}
                        disabled={false}
                      />
                    </div>
                    <div className="ml-2">
                      <h3 className="font-bold">No vigente</h3>
                      <div className="text-sm text-gray-400">
                        Active esta opción si desea indicar un estado 'No
                        vigente' para sus colaboradores.
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-row gap-2 mt-2">
                  <PrimaryButton
                    type="button"
                    onClick={() => onSubmit(beneficioLocal)}
                  >
                    Guardar
                  </PrimaryButton>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
