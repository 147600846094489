import { useEffect, useState } from "react";
import { ThreeDotsVertical } from "react-bootstrap-icons";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import TableCustom from "src/modules/common/components/TableCustom";
import TableRow from "src/modules/common/components/TableRow";
import { evaluacionActions } from "../handlers/redux";

function ConfiguracionesAgregarList() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const { idEvaluacion } = params;
  const configuraciones = useSelector(
    (state) => state.evaluacion.configuraciones
  );
  const configuraciones_filter = useSelector(
    (state) => state.evaluacion.configuraciones_filter
  );
  const configuraciones_loading = useSelector(
    (state) => state.evaluacion.configuraciones_loading
  );
  const { nombre = "" } = configuraciones_filter;
  //
  const ItemCommonComponent = ({ item }) => {
    const {
      peso_generales,
      peso_areas,
      peso_cargos,
      peso_kpis,
      peso_objetivos_cargo,
    } = item;
    const asignarConfiguracion = () => {
      dispatch(evaluacionActions.agregarConfiguracion(item.id));
    };
    return (
      <TableRow>
        <div
          className="p-4 flex gap-8 items-center justify-start cursor-pointer"
          onClick={() => {}}
        >
          <div className="w-2/3">
            <div>{item.nombre}</div>
          </div>
          <div className="flex flex-1 gap-2 items-center justify-start">
            {!!peso_generales && (
              <div className="p-1 rounded-md bg-black text-white text-sm">
                Generales {`(${peso_generales}%)`}
              </div>
            )}
            {!!peso_areas && (
              <div className="p-1 rounded-md bg-black text-white text-sm">
                Áreas {`(${peso_areas}%)`}
              </div>
            )}
            {!!peso_cargos && (
              <div className="p-1 rounded-md bg-black text-white text-sm">
                Cargos {`(${peso_cargos}%)`}
              </div>
            )}
            {!!peso_kpis && (
              <div className="p-1 rounded-md bg-black text-white text-sm">
                KPIs {`(${peso_kpis}%)`}
              </div>
            )}
            {!!peso_objetivos_cargo && (
              <div className="p-1 rounded-md bg-black text-white text-sm">
                Objetivos por cargo {`(${peso_objetivos_cargo}%)`}
              </div>
            )}
          </div>
          <div>
            <button
              onClick={asignarConfiguracion}
              className="border border-black p-2 rounded-md"
            >
              Asignar
            </button>
          </div>
        </div>
      </TableRow>
    );
  };
  const commonProps = {
    ItemComponent: ItemCommonComponent,
    subject: "formulario",
    subjectGender: "M",
    hasAddBtn: true,
    customAddBtnLabel: "Nuevo formulario",
    noBottomButtom: true,
    selectOptions: [],
  };
  const onChangeSearch = (e) => {
    dispatch(
      evaluacionActions.setConfiguracionesFilter({
        nombre: e.target.value,
      })
    );
  };
  //
  useEffect(() => {
    dispatch(evaluacionActions.getConfiguraciones());
  }, []);
  //
  return (
    <div>
      <TableCustom
        {...commonProps}
        tableSecondTitle={"Formularios creados"}
        data={configuraciones}
        searchValue={nombre}
        onChangeSearch={onChangeSearch}
        hasAddBtn={false}
        fixedHeigth={true}
        hasActionSelect={false}
        hasChecks={false}
        // onSelectChange={onSelectChange}
        // selectOptions={[{ value: "cancel", label: "Cancelar" }]}
        loading={configuraciones_loading}
        multiple={false}
      />
    </div>
  );
}
export default ConfiguracionesAgregarList;
