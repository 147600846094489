import React from "react";

export const CheckInput = ({
  Ref = null,
  className = "h-4 w-4",
  checkType = "checkbox",
  ...props
}) => {
  return (
    <input
      ref={Ref}
      type={checkType ? checkType : "checkbox"}
      className={`${className}`}
      {...props}
    />
  );
};
