import React from "react";

export function CategoriaBeneficio({ name, description, onEdit, onDelete }) {
  return (
    <div className="border-2 rounded-md p-2">
      <div className="space-y-2">
        <h1 className="font-bold">Titular:</h1>
        <p className="ml-4 text-sm">{name || "Sin especificar"}</p>
      </div>
      <div className="mt-4 space-y-2">
        <h1 className="font-bold">Descripción:</h1>
        <p className="ml-4 text-sm">{description || "Sin especificar"}</p>
      </div>
      <div className="flex mt-4 gap-2">
        <button
          type="button"
          className="rounded bg-sky-500 text-white px-3 py-1 text-sm"
          onClick={onEdit}
        >
          Editar
        </button>
        <button
          type="button"
          onClick={onDelete}
          className="rounded bg-red-500 text-white px-3 py-1 text-sm"
        >
          Eliminar
        </button>
      </div>
    </div>
  );
}
