import { useState } from "react";
import { CaretDownFill, Search } from "react-bootstrap-icons";

function SearchSelect({
  data = [],
  defaultText = "Todos",
  searchValue = "",
  filterFunction,
  placeholder = "Buscar por nombre...",
  valueField = "id",
  labelField = "nombre",
  onSelectItem,
}) {
  const [text, setText] = useState(defaultText);
  const [showMiniModal, setshowMiniModal] = useState(false);
  //
  const onChangeText = (e) => {
    filterFunction(e.target.value);
  };

  const onSelect = (value, label) => () => {
    setText(label);
    setshowMiniModal(false);
    // la funcion que se pase podra manipular el value
    if (onSelectItem) onSelectItem(value);
  };

  const onClickMask = () => {
    if (!showMiniModal) {
      filterFunction("");
    }
    setshowMiniModal(!showMiniModal);
  };

  return (
    <div className="w-full flex flex-col flex-wrap relative">
      {/* mask */}
      <div
        onClick={onClickMask}
        className="flex border justify-between items-center gap-0 rounded-md  border-2x border-red-500x"
      >
        <div className="py-2 px-4 ">{text}</div>
        <div className=" py-3 mr-1">
          <CaretDownFill></CaretDownFill>
        </div>
      </div>
      {/* mini-modal */}
      {!!showMiniModal && (
        <div className="absolute border rounded-md top-12 bg-white z-50">
          {/* search input */}
          <div className="p-3">
            <div className="flex items-center rounded-md bg-slate-100 relative">
              <input
                type="text"
                placeholder={placeholder}
                onChange={onChangeText}
                className="p-2 bg-slate-100 rounded-l-md focus:outline-0"
                value={searchValue}
              />
              <div className="mr-2">
                <Search></Search>
              </div>
            </div>
          </div>
          {/* list */}
          <div className="flex flex-col gap-1 pb-2 border-2x max-h-[240px] custom-scrollbar overflow-y-scroll">
            <div
              className="hover:bg-sky-200 px-3 py-2 rounded-sm"
              onClick={onSelect("", defaultText)}
            >
              {defaultText}
            </div>
            {data.length > 0 &&
              data.map((item, idx) => (
                <div
                  key={`search-select-item-${idx}`}
                  className="hover:bg-sky-200 px-3 py-2 rounded-sm"
                  onClick={onSelect(item[valueField], item[labelField])}
                >
                  {item[labelField]}
                </div>
              ))}
            {data.length < 1 && (
              <div className="p-3 font-semibold">
                No existen elementos para mostrar
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default SearchSelect;
