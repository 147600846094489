import axios from "axios";
const URL_BASE =
  process.env.REACT_APP_API_URL || "http://localhost:8000/api/admin";

export const remuneracionApi = (token) => {
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };
  //
  return {
    getEscalaReporte: async ({ id, filter }) => {
      const { con_extras = false } = filter;
      //
      try {
        const response = await axios.get(
          URL_BASE + `/remuneracion/${id}/reporte?con_extras=${con_extras}`,
          { headers }
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    getColaboradoresEscala: async ({ id, filter }) => {
      const {
        nombre = "",
        salario_extra = "",
        salario_fijo = "",
        salario_total = "",
        rango_escala = "",
        estado_escala = "",
        categoria = "",
        salario_mercado = "",
        rango_mercado = "",
        estado_mercado = "",
        con_extras = false,
        ordering = "",
        pagination = { page: 1, pageSize: 50 },
      } = filter;
      const { page, pageSize } = pagination;
      let searchURL = `/remuneracion/${id}/colaboradores?search=${nombre}&page=${page}&page_size=${pageSize}`;
      if (salario_extra)
        searchURL = searchURL + `&salario_extra=${salario_extra}`;
      if (salario_fijo) searchURL = searchURL + `&salario_fijo=${salario_fijo}`;
      if (salario_total)
        searchURL = searchURL + `&salario_total=${salario_total}`;
      if (rango_escala) searchURL = searchURL + `&rango_escala=${rango_escala}`;
      if (estado_escala)
        searchURL = searchURL + `&estado_escala=${estado_escala}`;
      if (categoria) searchURL = searchURL + `&categoria=${categoria}`;
      if (salario_mercado)
        searchURL = searchURL + `&salario_mercado=${salario_mercado}`;
      if (rango_mercado)
        searchURL = searchURL + `&rango_mercado=${rango_mercado}`;
      if (estado_mercado)
        searchURL = searchURL + `&estado_mercado=${estado_mercado}`;
      if (con_extras) searchURL = searchURL + `&con_extras=${con_extras}`;
      if (ordering) {
        searchURL += `&ordering=${ordering}`;
      }
      //
      try {
        const response = await axios.get(URL_BASE + searchURL, { headers });
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    //
    activarEscalaSalarial: async ({ id }) => {
      try {
        const response = await axios.post(
          URL_BASE + `/remuneracion/${id}/activar`,
          {},
          { headers }
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    //
    getFactoresBase: async (params) => {
      try {
        const response = await axios.get(
          URL_BASE + "/remuneracion/libreria-factores",
          { headers }
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    bulkDeleteEscalaSalarial: async (escalas_salariales_ids) => {
      try {
        const response = await axios.post(
          URL_BASE + "/remuneracion/bulk-delete",
          { escalas_salariales_ids: escalas_salariales_ids },
          { headers }
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    // create y update solo son para primer paso de Configuracion
    createEscalaSalarial: async (params) => {
      try {
        const response = await axios.post(
          URL_BASE + "/remuneracion/factores/crear",
          params,
          { headers }
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    updateEscalaSalarial: async ({ id, params }) => {
      try {
        const response = await axios.put(
          URL_BASE + `/remuneracion/${id}/factores/editar`,
          params,
          { headers }
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    getEscalaSalarial: async (id) => {
      try {
        const response = await axios.get(
          URL_BASE + `/remuneracion/${id}/detail`,
          { headers }
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    duplicarEscalaSalarial: async (id) => {
      try {
        const response = await axios.post(
          URL_BASE + `/remuneracion/${id}/duplicar`,
          {},
          { headers }
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    //
    getSalariosColaboradores: async (salarios_colaboradores_filter) => {
      const {
        nombre = "",
        area = "",
        sucursal = "",
        departamento = "",
        salario_total_desde = "",
        salario_total_hasta = "",
        ordering = "",
        pagination = { page: 1, pageSize: 100 },
      } = salarios_colaboradores_filter;
      const { page, pageSize } = pagination;
      let searchURL = `${URL_BASE}/remuneracion/colaboradores/?search=${nombre}&page=${page}&page_size=${pageSize}`;
      if (area) searchURL = searchURL + `&area_id=${area}`;
      if (sucursal) searchURL = searchURL + `&sucursal_id=${sucursal}`;
      if (departamento)
        searchURL = searchURL + `&departamento_id=${departamento}`;
      if (salario_total_desde)
        searchURL = searchURL + `&salario_total_desde=${salario_total_desde}`;
      if (salario_total_hasta)
        searchURL = searchURL + `&salario_total_hasta=${salario_total_hasta}`;
      if (ordering) {
        searchURL += `&ordering=${ordering}`;
      }
      //
      try {
        const response = await axios.get(searchURL, {
          headers,
        });
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    editSalarioColaborador: async ({ id, salario_fijo, salario_extra }) => {
      const params = {
        salario_fijo,
        salario_extra,
      };
      try {
        const data = await axios.put(
          URL_BASE + `/remuneracion/colaborador/${id}/salario/editar`,
          params,
          {
            headers,
          }
        );
        return data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log(JSON.stringify(error));
        throw error;
      }
    },
    importarSalarios: async ({ fileToImportar }) => {
      try {
        const payload = {
          file: fileToImportar,
        };
        const headersForm = {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        };
        const response = await axios.post(
          URL_BASE + `/remuneracion/importar-salarios/`,
          { file: fileToImportar },
          { headers: headersForm }
        );
        return response.data;
      } catch (error) {
        return error.response.data;
      }
    },
    // SALARIOS MERCADO
    getSalariosMercado: async (salarios_colaboradores_filter) => {
      const {
        nombre = "",
        salario_mercado_desde = "",
        salario_mercado_hasta = "",
        ordering = "",
        pagination = { page: 1, pageSize: 100 },
      } = salarios_colaboradores_filter;
      const orderingClean = ordering.replace(",", "");
      const { page, pageSize } = pagination;
      let searchURL = `${URL_BASE}/remuneracion/salarios-mercado/?search=${nombre}&page=${page}&page_size=${pageSize}`;
      if (salario_mercado_desde)
        searchURL =
          searchURL + `&salario_mercado_desde=${salario_mercado_desde}`;
      if (salario_mercado_hasta)
        searchURL =
          searchURL + `&salario_mercado_hasta=${salario_mercado_hasta}`;
      if (ordering) {
        searchURL += `&ordering=${orderingClean}`;
      }
      //
      try {
        const response = await axios.get(searchURL, {
          headers,
        });
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    editSalarioMercado: async ({ id, ...params }) => {
      //
      try {
        const data = await axios.put(
          URL_BASE + `/remuneracion/salarios-mercado/${id}/editar`,
          params,
          {
            headers,
          }
        );
        return data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log(JSON.stringify(error));
        throw error;
      }
    },
    importarSalariosMercado: async ({ fileToImportar }) => {
      try {
        const payload = {
          file: fileToImportar,
        };
        const headersForm = {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        };
        const response = await axios.post(
          URL_BASE + `/remuneracion/salarios-mercado/importar-salarios/`,
          { file: fileToImportar },
          { headers: headersForm }
        );
        return response.data;
      } catch (error) {
        return error.response.data;
      }
    },
    //
    getEscalasSalariales: async (escalas_salariales_filter) => {
      const {
        nombre = "",
        year,
        ordering = "",
        pagination = { page: 1, pageSize: 100 },
      } = escalas_salariales_filter;
      const { page, pageSize } = pagination;

      let searchURL = `${URL_BASE}/remuneracion/?search=${nombre}&page=${page}&page_size=${pageSize}`;
      if (year) searchURL = searchURL + `&year=${year}`;
      if (ordering) {
        searchURL += `&ordering=${ordering}`;
      }
      //
      try {
        const response = await axios.get(searchURL, {
          headers,
        });
        console.log("getEscalasSalariales response", response);
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    //
    getValoracionesCargos: async ({ id, filter }) => {
      const {
        nombre = "",
        ordering = "",
        pagination = { page: 1, pageSize: 999 },
      } = filter;
      const { page, pageSize } = pagination;
      let searchURL = `${URL_BASE}/remuneracion/${id}/valoracion-cargos/listar?search=${nombre}&page=${page}&page_size=${pageSize}`;
      //
      if (ordering) {
        searchURL += `&ordering=${ordering}`;
      }
      //
      try {
        const response = await axios.get(searchURL, {
          headers,
        });
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    updateValoracionesCargos: async ({ id, valoraciones }) => {
      let putURL = `${URL_BASE}/remuneracion/${id}/valoracion-cargos/`;
      console.log(`updateValoracionesCargos ${id}`, valoraciones);
      //
      try {
        const response = await axios.put(
          putURL,
          { valoraciones: valoraciones },
          { headers }
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    //
    getCategorias: async ({ id, filter }) => {
      const {
        nombre = "",
        ordering = "",
        pagination = { page: 1, pageSize: 50 },
      } = filter;
      const { page, pageSize } = pagination;
      let searchURL = `${URL_BASE}/remuneracion/${id}/categorizacion/listar?search=${nombre}&page=${page}&page_size=${pageSize}`;
      //
      if (ordering) {
        searchURL += `&ordering=${ordering}`;
      }
      //
      try {
        const response = await axios.get(searchURL, {
          headers,
        });
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    createCategorias: async ({ id, categorias }) => {
      try {
        const response = await axios.post(
          `${URL_BASE}/remuneracion/${id}/categorizacion/`,
          { categorias: categorias },
          { headers }
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    //
    getConfiguracionEscala: async ({ id }) => {
      let searchURL = `${URL_BASE}/remuneracion/${id}/configuracion-escala/detail`;
      //
      try {
        const response = await axios.get(searchURL, {
          headers,
        });
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    updateConfiguracionEscala: async ({ id, escala_salarial }) => {
      try {
        const response = await axios.put(
          URL_BASE + `/remuneracion/${id}/configuracion-escala/`,
          escala_salarial,
          { headers }
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
  };
};
