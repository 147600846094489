import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useParams, useLocation, useNavigate } from "react-router-dom";
import backButton from "src/modules/common/components/images/back-arrow.png";
import { remuneracionActions } from "../handlers/redux";

const RemuneracionSteps = ({
  steps = [],
  currentStepId = 0,
  barHeigth = 2,
  containerClass,
  onStepClickHandler,
}) => {
  const onClickLinkHandler = (disabled) => (e) => {
    if (disabled) {
      e.preventDefault();
    }
    return;
  };
  //
  return (
    <div className={`flex flex-col gap-2 w-1/2 ${containerClass}`}>
      <div
        className={`flex items-center w-full bg-slate-300 rounded-md h-${barHeigth}`}
      >
        {steps.map((step, sidx) => (
          <div
            key={`step-${sidx}`}
            className={`${
              sidx <= currentStepId ? "bg-sky-500" : ""
            } h-${barHeigth} ${
              sidx === currentStepId ? "rounded-tr-md rounded-br-md" : ""
            } ${sidx === 0 ? "rounded-tl-md rounded-bl-md" : ""}`}
            style={{ width: `${100 / steps.length}%` }}
          ></div>
        ))}
      </div>
      {/*  */}
      <div className="flex justify-around items-center w-full">
        {steps.map((step, sidx) => (
          <NavLink
            key={`r-link-${sidx}`}
            className={`text-center ${
              !!step.disabled ? "hover:cursor-not-allowed" : ""
            }`}
            to={step.to}
            onClick={onClickLinkHandler(step.disabled)}
          >
            <span
              className={`text-sm ${!!step.disabled ? "text-slate-400" : ""}`}
            >
              {step.label}
            </span>
          </NavLink>
        ))}
      </div>
    </div>
  );
};
//
function RemuneracionConfiguracionLayout({
  children,
  title,
  onClickBack,
  currentStep = 0,
  actionButton = {},
  BarComponent,
}) {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { idEscala = null } = params;
  const {
    actionButtonLabel = "Finalizar",
    actionButtonAction,
    disabled: actionButtonDisabled = false,
  } = actionButton;
  const pasoActual = pathname.split("/")[4];
  //
  const escala_salarial = useSelector(
    (state) => state.remuneracion.escala_salarial
  );
  const { factores = [] } = escala_salarial;
  const valoracionEstaHabilitado = () => {
    // Si tiene todos los factores con peso
    if (factores.length < 1) {
      return false;
    }
    const todosTienenPeso = factores.every(
      (objeto) => typeof objeto.peso === "number" && objeto.peso > 0
    );
    return todosTienenPeso;
  };
  const valoraciones_cargos = useSelector(
    (state) => state.remuneracion.valoraciones_cargos
  );
  const { results: valoracionesCargos = [], count = 0 } = valoraciones_cargos;
  const categorizacionEstaHabilitado = () => {
    // anterior + Si todos los cargos estan valorados
    const todosTienenPuntaje = valoracionesCargos.every(
      (objeto) => typeof objeto.puntaje === "number" && objeto.puntaje > 0
    );
    return !!valoracionEstaHabilitado() && !!todosTienenPuntaje;
  };
  const categorias = useSelector((state) => state.remuneracion.categorias);
  const escalaEstaHabilitado = () => {
    let estaHabilitado = true;
    // anteriores + Si hay categorias creadas
    if (categorias.length < 1) {
      estaHabilitado = false;
    } else if (categorias.length === 1) {
      let primeraCategoria = categorias[0];
      let soloSinCategoria = primeraCategoria.nombre === "Sin categoría";
      if (!!soloSinCategoria) {
        estaHabilitado = false;
      }
    }
    return !!categorizacionEstaHabilitado() && !!estaHabilitado;
  };
  const checkStepAccess = () => {
    const stepAccess = {
      configuracion: true,
      valoracion: valoracionEstaHabilitado(),
      categorizacion: categorizacionEstaHabilitado(),
      escala: escalaEstaHabilitado(),
      detalle: true,
    };
    //
    return stepAccess[pasoActual] ?? false;
  };
  //
  const steps = [
    {
      label: "Configuración",
      to: !!idEscala
        ? `/remuneracion/escala/${idEscala}/configuracion`
        : "/remuneracion/escala/configuracion",
      disabled: false,
    },
    {
      label: "Valoración de cargos",
      to: `/remuneracion/escala/${idEscala}/valoracion`,
      disabled: !valoracionEstaHabilitado(),
    },
    {
      label: "Categorización",
      to: `/remuneracion/escala/${idEscala}/categorizacion`,
      disabled: !categorizacionEstaHabilitado(),
    },
    {
      label: "Escala Salarial",
      to: `/remuneracion/escala/${idEscala}/escala`,
      disabled: !escalaEstaHabilitado(),
    },
  ];
  //
  useEffect(() => {
    if (!escala_salarial.id) {
      dispatch(remuneracionActions.getEscalaSalarial(idEscala));
    }
    //categorias;
    if (categorias?.length < 1) {
      dispatch(remuneracionActions.getCategorias({ id: idEscala }));
    }
    //
    dispatch(remuneracionActions.getValoracionesCargos());
  }, []);
  //
  useEffect(() => {
    if (!checkStepAccess() && !!idEscala) {
      navigate(`/remuneracion/escala/${idEscala}/configuracion`);
    }
  }, [pathname]);
  //
  return (
    <div>
      <div className="flex justify-between items-center bg-white py-2 px-8 -mx-8 border-b">
        <div className="flex items-center flex-1">
          <div
            onClick={() => {
              if (onClickBack) {
                onClickBack();
              }
            }}
            className={
              "h-full  flex items-center pr-2 -mr-2 z-10 cursor-pointer"
            }
          >
            <img className="back" src={backButton} alt="Regresar" />
          </div>
          <div className="font-semibold px-4 text-2xl">{title}</div>
          {!!BarComponent && <BarComponent />}
        </div>
        {/*  */}
        <div className="flex items-center gap-4 flex-1">
          {currentStep > -1 && (
            <RemuneracionSteps
              steps={steps}
              containerClass={"mt-2 flex-1"}
              currentStepId={currentStep}
              barHeigth={2}
            />
          )}
          {!!actionButtonAction && (
            <button
              disabled={actionButtonDisabled}
              onClick={actionButtonAction}
              className={`p-3 bg-sky-500 text-white rounded-md ${
                actionButtonDisabled ? "opacity-50" : ""
              }`}
            >
              {actionButtonLabel}
            </button>
          )}
        </div>
      </div>
      {children}
    </div>
  );
}

export default RemuneracionConfiguracionLayout;
