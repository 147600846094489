import React, { useEffect, useState } from "react";
import { Download } from "react-bootstrap-icons";
import { useDispatch, useSelector } from "react-redux";
import Dropdown from "src/modules/common/components/Dropdown";
import SelectModal from "src/modules/common/components/SelectModal";
import UploadFileInput from "src/modules/common/components/UploadFileInput";
import { commonActions } from "src/modules/common/handler/redux";
import PoliticaLayout from "../components/PoliticaLayout";
import { politicaActions } from "../handlers/redux";
import Politicas from "./Politicas";
import { TIPO_POLITICAS } from "../helpers/constants";
import { ErrorToast } from "src/modules/common/components/ErrorToast";

function PoliticasRemuneracion() {
  const dispatch = useDispatch();
  //
  const politicas_remuneracion = useSelector(
    (state) => state.politica.politicas_remuneracion
  );
  const politicas_remuneracion_count = useSelector(
    (state) => state.politica.politicas_remuneracion_count
  );
  const politicas_remuneracion_loading = useSelector(
    (state) => state.politica.politicas_remuneracion_loading
  );
  const politicas_remuneracion_checked = useSelector(
    (state) => state.politica.politicas_remuneracion_checked
  );
  const politicas_remuneracion_filter = useSelector(
    (state) => state.politica.politicas_remuneracion_filter
  );
  // Para modificar la altura de la fila de la tabla en caso
  const [hRow, setHRow] = useState("");
  //
  const onSearch = (e) => {
    let searchText = e.target.value;
    dispatch(
      politicaActions.setPoliticasRemuneracionFilter({
        ...politicas_remuneracion_filter,
        nombre: searchText,
      })
    );
  };
  const reactSelectOptions = [{ value: "eliminar", label: "Eliminar" }];
  const onChangeReactSelect = (v) => {
    if (v.value === "eliminar") {
      if (politicas_remuneracion_checked.length < 1) {
        window.alert("Debe seleccionar al menos un archivo");
        return;
      }
      const deseaEliminar = window.confirm(
        "Está seguro de eliminar los archivos seleccionados?"
      );
      if (!!deseaEliminar) {
        const politicasids = politicas_remuneracion_checked.map(
          (pol, pidx) => pol.id
        );
        dispatch(politicaActions.bulkDelete(politicasids));
      }
      return;
    }
  };
  const onEliminarPolitica = (id) => {
    const deseaEliminar = window.confirm(
      "Está seguro de eliminar este archivo?"
    );
    if (!!deseaEliminar) {
      dispatch(politicaActions.delete(id));
    }
  };
  const changeStatus = (id, status) => () => {
    let deseaCambiar = window.confirm("Está seguro de cambiar el estado?");
    if (!deseaCambiar) {
      return;
    }
    setHRow("");
    //
    dispatch(politicaActions.changeStatus({ id: id, vigente: status }));
  };
  const [selectedPolitica, setSelectedPolitica] = useState(null);
  const onOpenModalSustituirPolitica = (item) => {
    dispatch(commonActions.setSelectModal({ open: true }));
    setSelectedPolitica(item);
  };
  const datatableConfig = {
    headers: [
      {
        title: "Archivos cargados",
        contentClass: "font-bold px-2 py-4",
        // onOrder: setOrdering("cargo__nombre"),
        // accessor: "nombre",
        contentRenderer: (item) => (
          <div className="flex items-center gap-2 h-20">
            <span className="font-semibold">{item.name}</span>
            <span className="text-xs">({item.archivo_size_label})</span>
          </div>
        ),
      },
      {
        title: "Estado",
        contentClass: "font-bold px-2 py-4",
        // onOrder: setOrdering("cargo__nombre"),
        // accessor: "nombre",
        contentRenderer: (item) => (
          <div
            className={`flex gap-2 items-${
              politicas_remuneracion_count === 1 ? "start" : "center"
            } justify-center ${hRow}`}
          >
            <div
              className={`p-1 rounded-md ${
                !!item.vigente
                  ? "bg-green-500 text-white"
                  : "bg-slate-300 text-black"
              }`}
            >
              {`${item.vigente ? "Vigente" : "No vigente"}`}
            </div>
            {/*  */}
            <Dropdown
              options={[
                { label: "Vigente", action: changeStatus(item.id, true) },
                { label: "No vigente", action: changeStatus(item.id, false) },
              ]}
              aditionalAction={
                politicas_remuneracion_count === 1 ? setHRow : null
              }
            ></Dropdown>
          </div>
        ),
      },
      {
        title: "Descargar",
        contentClass: "font-bold px-2 py-4",
        // onOrder: setOrdering("cargo__nombre"),
        // accessor: "nombre",
        contentRenderer: (item) => (
          <div className="flex gap-2 items-center justify-center">
            <a
              target="_blank"
              href={item.archivo}
              className="flex items-center gap-2 border rounded-md border-black py-1 px-2"
            >
              <Download />
              <span>Descargar</span>
            </a>
          </div>
        ),
      },
    ],
    rowActions: [
      {
        label: "Sustituir",
        action: (item) => {
          console.log("Sustituir action");
          onOpenModalSustituirPolitica(item);
        },
      },
      {
        label: "Eliminar",
        action: (item) => {
          onEliminarPolitica(item.id);
        },
      },
    ],
  };
  const onPaginate = (page, pageSize) => {
    //
    dispatch(
      politicaActions.setPoliticasRemuneracionFilter({
        ...politicas_remuneracion_filter,
        pagination: {
          page: page,
          pageSize: pageSize,
        },
      })
    );
  };
  const onCheckAll = () => {
    let checkedData = JSON.parse(JSON.stringify(politicas_remuneracion));
    if (politicas_remuneracion_checked.length > 0) {
      checkedData = [];
    }
    dispatch(politicaActions.setCheckPoliticaRemuneracion(checkedData));
  };
  const onItemCheck = (item) => (event) => {
    dispatch(
      politicaActions.checkPoliticaRemuneracion({
        politica: item,
        checked: event.target.checked,
      })
    );
  };
  const onActionButtonClick = () => {
    dispatch(commonActions.setSelectModal({ open: true }));
  };
  const uploadFile = (file) => {
    dispatch(
      politicaActions.upload({
        file: file,
        tipo: TIPO_POLITICAS.ESCALA_SALARIAL,
      })
    );
    dispatch(commonActions.setSelectModal({ open: false }));
  };
  const replaceFile = (file) => {
    if (!selectedPolitica) {
      ErrorToast({ message: "Debe seleccionar una política" });
      return;
    }
    dispatch(politicaActions.replace({ id: selectedPolitica.id, file: file }));
    dispatch(commonActions.setSelectModal({ open: false }));
    setSelectedPolitica(null);
  };
  //
  useEffect(() => {
    dispatch(politicaActions.getPoliticasRemuneracion());
  }, []);
  //
  return (
    <PoliticaLayout>
      <Politicas
        tipoPoliticaLabel="Remuneraciones"
        onActionButtonClick={onActionButtonClick}
        data={politicas_remuneracion}
        count={politicas_remuneracion_count}
        filter={politicas_remuneracion_filter}
        loading={politicas_remuneracion_loading}
        onSearch={onSearch}
        datatableConfig={datatableConfig}
        //
        onPaginate={onPaginate}
        //
        checkable={true}
        onCheckAll={onCheckAll}
        checkedItems={politicas_remuneracion_checked}
        onItemCheck={onItemCheck}
        reactSelectOptions={reactSelectOptions}
        onChangeReactSelect={onChangeReactSelect}
      />
      <SelectModal
        title={`${
          !!selectedPolitica
            ? `Sustituir archivo para ${selectedPolitica.name}`
            : "Subir archivo"
        }`}
        extraClass={"w-full md:w-1/3"}
        content={
          <div>
            <UploadFileInput
              label={"Importador parcial"}
              className={"mt-3 w-3/4"}
              onSubmit={!selectedPolitica ? uploadFile : replaceFile}
            />
          </div>
        }
      />
    </PoliticaLayout>
  );
}

export default PoliticasRemuneracion;
