import React from 'react'
import { ObjetivosCargosPage } from '../pages/ObjetivosCargosPage'
import { ObjetivosColaboradorDetailPage } from '../pages/ObjetivosColaboradorDetailPage'
import ObjetivoCargoAsignacionMasivaPage from '../pages/ObjetivoCargoAsignacionMasivaPage'

export const objetivoRoutes = [
    {
        path: "/objetivos/cargos",
        element: <ObjetivosCargosPage/>
    },
    {
        path: "/objetivos/cargos/:idColaborador",
        element: <ObjetivosColaboradorDetailPage/>
    },
    {
        path: "objetivos/cargos/colaboradores/asignar-objetivos",
        element: <ObjetivoCargoAsignacionMasivaPage/>
    }
]