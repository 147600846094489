import React from "react";
import { useSelector } from "react-redux";
import ReactSelect from "react-select";
import { Label } from "recharts";

export const TipoConfiguracionBeneficioSelect = ({
  options,
  value,
  defaultLabel = "Seleccione...",
  ...props
}) => {

  let tipos_configuraciones = options.map((e) => ({
    value: e.id,
    label: e.nombre
  }))

  tipos_configuraciones.unshift({value: 0, label: defaultLabel})

  const selected = tipos_configuraciones.find((v) => v.value == value);

  return (
    <ReactSelect
      {...props}
      onChange={(item) => props.onChange && props.onChange({ target: item })}
      value={selected}
      options={tipos_configuraciones}
      defaultValue={tipos_configuraciones[0]}
      className="w-1/3"
    />
  );
};
