import { Award } from "react-bootstrap-icons";

function PlanAvanzadoAlert({ className = "" }) {
  return (
    <div
      className={`"border-3 rounded p-2 border border-sky-400 bg-sky-100" ${className}`}
    >
      <div className="flex items-center justify-center gap-1 text-sm px-2">
        <Award className="text-2xl" />
        <span>La asignacion de pesos pertenece al PLAN AVANZADO.</span>
      </div>
    </div>
  );
}

export default PlanAvanzadoAlert;
