import axios from "axios";
import dayjs from "dayjs";

export const FloatPattern = /^[0-9]*(,[0-9]{0,2})?$/;
export const FloatConSeparadorPuntoYDecimalComa = /^-?(\d{1,3})(\.\d{3})*(,\d+)?$/;
export const formatDate = (date) => {
  if (!date) {
    return "Sin fecha";
  }
  return dayjs(date.slice(0, 10)).format("DD/MM/YYYY");
};

export const areArraysEqual = (array1, array2) => {
  if (array1.length <= array2.length) {
    return array1.every((element) => {
      if (array2.includes(element)) {
        return true;
      }

      return false;
    });
  }

  return false;
};

/**
 * Agrega la hora, minutos, segundos y la zona horaria de Paraguay (UTC-3:00) a una fecha en formato "yyyy-mm-dd".
 * @param {string} date - La fecha en formato "yyyy-mm-dd" a la que se le agregará la hora y zona horaria.
 * @returns {string} La fecha en formato ISO 8601 con la hora (00:00:00), minutos (00), segundos (00) y la zona horaria de Paraguay (-03:00).
 */
export const addPyTimezoneToISODate = (date) => {
  return date + "T00:00:00.000-03:00";
};

export const setOrderingHelper =
  // (field) =>
  (field, order = "", filterState) => {
    let { ordering = "" } = filterState;
    let orderingArray = ordering.split(",") || [];
    let emptyIndex = orderingArray.indexOf("");
    if (emptyIndex) {
      orderingArray.splice(emptyIndex, 1);
    }
    let fieldIndex = orderingArray.indexOf(field);
    fieldIndex =
      fieldIndex === -1 ? orderingArray.indexOf(`-${field}`) : fieldIndex;
    // si ya esta el campo, vemos si se modifica o elimina
    if (fieldIndex > 0) {
      orderingArray.splice(fieldIndex, 1);
      if (order) {
        field = order === "desc" ? `-${field}` : field;
        orderingArray[fieldIndex] = field;
      }
    } else {
      // si no está, lo agregamos
      field = order === "desc" ? `-${field}` : field;
      orderingArray.push(field);
      !!process.env.REACT_APP_DEBUG &&
        console.info("orderingArray 2", orderingArray);
    }
    ordering = orderingArray.join(",");
    //
    return ordering;
    // dispatch(
    //   remuneracionActions.setSalariosMercadoFilter({
    //     ...salariosMercadoFilter,
    //     ordering: ordering,
    //   })
    // );
  };

/**
 * Inserts an array at a specified index in another array.
 *
 * @param {Array} array - The original array.
 * @param {number} index - The index at which to insert the array.
 * @param {Array} arrayToInsert - The array to insert.
 * @return {Array} - The new array with the inserted array.
 */
export const insertArrayAt = (array, index, arrayToInsert) => {
  let newArray = [...array];
  newArray.splice(index, 0, ...arrayToInsert);
  return newArray;
};

/**
 * Truncates a number to a specified number of decimal places.
 *
 * @param {number} numero - The number to truncate.
 * @param {number} decimales - The number of decimal places to truncate to. Default is 2.
 * @return {number} The truncated number.
 */
export function truncarNumero(numero = 0.0, decimales = 2) {
  // Usar toFixed para redondear y recortar a dos decimales
  var numeroRecortado = parseFloat(numero).toFixed(decimales);
  // Convertir de nuevo a número para devolver el resultado
  return parseFloat(numeroRecortado);
}

export function DownloadReport({
  token = "",
  url = "",
  method = "GET",
  type = "pdf",
  name = "document",
  data = null,
  loadingSetter,
}) {
  const URL_BASE =
    process.env.REACT_APP_API_URL || "http://localhost:8000/api/admin";
  const requestURL = URL_BASE + `${url}`;
  const headers = {
    // "Content-Type": "blob",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };
  let axiosConfig = {
    url: requestURL,
    method: method,
    headers: headers,
    responseType: "blob",
  };
  if (method.toUpperCase() === "POST" && data) {
    axiosConfig.data = data;
  }
  if (loadingSetter) {
    loadingSetter(true);
  }
  //
  axios(axiosConfig)
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${name}.${type}`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
      if (loadingSetter) {
        loadingSetter(false);
      }
    })
    .catch((e) => {
      if (loadingSetter) {
        loadingSetter(false);
      }
      console.info("Error al descargar el archivo");
    });
}
