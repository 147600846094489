import React from "react";

import { ReactComponent as User } from "../../common/images/user-blue.svg";

export const ProfileImageMedium = () => {
  return (
    <div className="rounded-lg bg-zinc-100 h-36 w-36 flex justify-center items-center">
      <User />
    </div>
  );
};
