import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import SearchSelect from "src/modules/common/components/SearchSelect";
import { sucursalActions } from "src/modules/estructura/modules/sucursal/handlers/redux";
import { evaluacionActions } from "../handlers/redux";
import { kpiActions } from "src/modules/kpi/handlers/redux";

function SucursalColaboradorSelect() {
  const dispatch = useDispatch();
  const params = useParams();
  const sucursales_results = useSelector((state) => state.sucursal.sucursales);
  const { results: sucursales } = sucursales_results;
  const sucursales_filter = useSelector(
    (state) => state.sucursal.sucursales_filter
  );
  const { nombre } = sucursales_filter;
  const filterSucursales = (nombre) => {
    dispatch(sucursalActions.setSucursalesFilter({ nombre: nombre }));
  };
  const { idEvaluacion } = params;
  const colaboradores_validos_filter = useSelector(
    (state) => state.evaluacion.colaboradores_validos_filter
  );
  const colaboradores_filter = useSelector(
    (state) => state.kpi.colaboradores_filter
  );
  const onSelectItem = (sucursal) => {
    dispatch(
      evaluacionActions.setColaboradoresValidosFilter({
        ...colaboradores_validos_filter,
        // id: idEvaluacion,
        sucursal: sucursal,
      })
    );
  };
  //
  useEffect(() => {
    dispatch(sucursalActions.sucursales());
  }, []);
  //
  return (
    <SearchSelect
      data={sucursales}
      defaultText={"Sucursales: Todas"}
      searchValue={nombre}
      filterFunction={filterSucursales}
      onSelectItem={onSelectItem}
    ></SearchSelect>
  );
}
export default SucursalColaboradorSelect;
