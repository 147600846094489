import React, { useEffect, useState } from "react";
import { TrashFill } from "react-bootstrap-icons";
import Input from "src/modules/common/components/Input";
import { PrimaryButton } from "src/modules/common/components/PrimaryButton";
import { beneficioActions } from "../handlers/redux";

export function BeneficioFormView(props) {
  const {
    beneficio = {},
    setOpen = () => {},
    open = false,
    onSubmit = () => {},
    idx = null,
  } = props;
  const [beneficioLocal, setBeneficioLocal] = useState(beneficio);
  // Los campos de requisitos que iniciaran al mostrar el modal
  const [requisitos, setRequisitos] = useState(beneficioLocal.requisitos);

  return (
    <>
      <div className="flex justify-center items-center w-full mt-1">
        <div className="flex flex-col w-full md:w-11/12">
          <div className="grid grid-cols-1 md:grid-cols-12 w-full">
            <div className="col-span-1 md:col-span-12 w-full">
              <form className="w-full">
                <div className="grid grid-cols-1 w-full">
                  <div className="flex flex-col gap-2 w-full">
                    <label className="font-bold text-sm" htmlFor="">
                      Nombre
                    </label>
                    <h2 className="text-gray-600 ml-2">{beneficioLocal.nombre}</h2>
                  </div>
                </div>
                <div className="grid grid-cols-1 mt-4 w-full">
                  <div className="flex flex-col gap-2 w-full">
                    <label className="font-bold text-sm" htmlFor="">
                      Descripción
                    </label>
                    <h2 className="text-gray-600 ml-2">{beneficioLocal.descripcion}</h2>
                  </div>
                </div>
                <div className="flex flex-col gap-2">
                  <div className="text-sm mt-4 font-bold">Requisitos</div>
                  {requisitos.map((requisito, idx) => (
                    <div key={idx} className="flex flex-row gap-1 w-full text-gray-600 ml-2">
                      <h2>{requisito.descripcion}</h2>
                    </div>
                  ))}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
