import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "src/modules/common/components/Modal";
import { empresaActions } from "../handlers/redux";

function RubroModal(props) {
  const dispatch = useDispatch();
  const modal = useSelector(
    (state) => state.empresa.empresa_rubro_update_modal
  );
  const empresa = useSelector((state) => state.empresa.empresa);
  const rubros = useSelector((state) => state.empresa.rubros).results;

  React.useEffect(() => {
    dispatch(empresaActions.empresa());
    dispatch(empresaActions.rubros());
  }, []);

  const setOpen = (open) => {
    dispatch(empresaActions.setEmpresaRubroModal({ open }));
  };

  const onChange =
    (name) =>
    ({ target }) => {
      dispatch(empresaActions.empresaSet({ ...empresa, [name]: target.value }));
    };

  const onSubmit = (event) => {
    event.preventDefault();
    dispatch(empresaActions.empresaUpdate());
    setOpen(false);
  };

  return (
    <Modal isOpen={modal.open}>
      <div className=" rounded-lg bg-white w-6/12">
        <form onSubmit={onSubmit} className="flex flex-col gap-3 p-4">
          <div className="flex flex-row justify-between text-lg font-bold">
            <div>Rubro</div>
            <div onClick={() => setOpen(false)}>X</div>
          </div>
          <div className="flex flex-col gap-2">
            <div className="text-slate-600 text-sm font-bold">
              Rubro<span className="text-red-500">*</span>
            </div>
            <select
              onChange={onChange("rubro")}
              value={empresa.rubro?.id}
              name="rubro"
              id="rubro"
              className="bg-white p-2 border-1 border-gray-500 rounded-md"
            >
              {!!rubros &&
                rubros.map((rubro, index) => (
                  <option key={`opt-${index}`} value={rubro.id}>
                    {rubro.nombre}
                  </option>
                ))}
            </select>
          </div>
          <div className="flex flex-row gap-2">
            <button
              type="submit"
              className="bg-sky-500 text-white rounded-md px-3 py-2 active:bg-gray-200"
            >
              Guardar
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
}

export default RubroModal;
