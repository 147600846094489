import RoundedCard from "src/modules/common/components/Card";

function CardLoading() {
  return (
    <RoundedCard extraClass={"animate-pulse"}>
      <div className="flex flex-col w-full gap-4">
        <div className="flex items-center gap-4">
          <div class="h-10 w-10 bg-zinc-200 rounded"></div>
          <div class="h-2 bg-zinc-200 rounded w-1/2"></div>
        </div>
        <div className="flex flex-col gap-4">
          <div class="h-10 bg-zinc-200 rounded w-full"></div>
          <div class="h-10 bg-zinc-200 rounded w-full"></div>
          <div class="h-10 bg-zinc-200 rounded w-full"></div>
        </div>
      </div>
    </RoundedCard>
  );
}

export default CardLoading;
