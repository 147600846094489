import axios from "axios";
const URL_BASE =
  process.env.REACT_APP_API_URL || "http://localhost:8000/api/admin";

export const analisisApi = (token) => {
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };

  return {
    data: async () => {
      try {
        const data = await axios.get(URL_BASE + "/analisis/", { headers });
        return data.data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log("data error", error);
        throw error;
      }
    },
  };
};
