import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Input from "src/modules/common/components/Input";
import LoadingSpinButton from "src/modules/common/components/LoadingSpinButton";
import { PrimaryButton } from "src/modules/common/components/PrimaryButton";

import ToggleSwitch from "src/modules/common/components/ToggleSwitch";
import { commonActions } from "src/modules/common/handler/redux";
import { colaboradorActions } from "../../colaborador/handlers/redux";

function ColaboradorAccesoPage() {
  const dispatch = useDispatch();
  const params = useParams();
  const { idColaborador } = params;
  const [habilitarEliminar, setHabilitarEliminar] = useState(false);
  const [esModoVista, setEsModoVista] = useState(true);
  const colaborador = useSelector((state) => state.colaborador.colaborador);
  const colaborador_acceso = useSelector(
    (state) => state.colaborador.colaborador_acceso
  );
  const colaborador_acceso_invitacion_loading = useSelector(
    (state) => state.colaborador.colaborador_acceso_invitacion_loading
  );
  const rolesResults = useSelector((state) => state.colaborador.roles) || [];
  const { results: roles } = rolesResults || [];

  const onSubmitInvitacion = (event) => {
    event.preventDefault();
    dispatch(colaboradorActions.colaboradorAccesoInvitacion());
  };

  const onToggleChange = (e) => {
    !!process.env.REACT_APP_DEBUG &&
      console.info("e.target.checked", e.target.checked);
    const value = e.target.checked;
    !!process.env.REACT_APP_DEBUG &&
      console.info("onToggleChange", value, typeof value);
    let confirmacion = window.confirm(
      "Está seguro de cambiar el estado del colaborador?"
    );
    if (confirmacion) {
      dispatch(
        colaboradorActions.colaboradorAccesoSet({
          ...colaborador_acceso,
          is_active: !value,
        })
      );
      dispatch(colaboradorActions.colaboradorAccesoUpdate());
    }
    !!process.env.REACT_APP_DEBUG &&
      console.info("colaborador_acceso edited", colaborador_acceso);
  };

  const onChangeEliminar = (e) => {
    setHabilitarEliminar(e.target.checked);
  };

  const eliminarAcceso = () => {
    dispatch(colaboradorActions.colaboradorDelete(colaborador));
  };

  const modoVistaEdit = () => {
    setEsModoVista(false);
    dispatch(commonActions.setEditDetailButton(false));
  };

  const onCancelar = () => {
    setEsModoVista(true);
    dispatch(
      commonActions.setEditDetailButton({
        label: "Editar datos generales",
        action: modoVistaEdit,
      })
    );
  };

  const copiarLinkInvitacion = () => {
    setTimeout(() => {
      dispatch(colaboradorActions.colaboradorSet(colaborador));
      dispatch(colaboradorActions.colaboradorAccesoSet(colaborador));
      dispatch(
        colaboradorActions.colaboradorAccesoInvitacion({
          forzarBienvenida: true,
          retornarLink: true,
        })
      );
    }, 1800);
  };

  const displayUsername = () => {
    const { email, ci } = colaborador;
    const { email: emailAcceso } = colaborador_acceso;
    let username = "Sin asignar";
    if (email) {
      username = email;
      if (email.includes("@null.xyz")) {
        username = ci;
      }
    } else if (!!ci) {
      username = ci;
    }
    return username;
  };

  useEffect(() => {
    dispatch(colaboradorActions.colaboradorGet({ id: idColaborador }));
    dispatch(colaboradorActions.colaboradorAccesoGet({ id: idColaborador }));
    dispatch(colaboradorActions.roles());
    dispatch(
      commonActions.setEditDetailButton({
        label: "Editar datos de acceso",
        action: () => modoVistaEdit(),
      })
    );
  }, []);

  return (
    <>
      {!esModoVista && (
        <div className="flex justify-center w-full mt-10">
          <div className="w-full md:w-1/2">
            <div className="border-b pb-2">
              <span className="font-bold text-lg">Información de acceso</span>
            </div>
            <form
              id="acceso"
              name="acceso"
              className="grid grid-cols-1 md:grid-cols-2 mt-2"
              onSubmit={onSubmitInvitacion}
            >
              <div className="col-span-1 mr-6 my-2 ">
                {/* EMAIL */}
                <div className="flex flex-col">
                  <label className="font-bold text-base" htmlFor="">
                    Usuario:
                  </label>
                  <div className="py-4">{displayUsername()}</div>
                </div>
              </div>
              <div className="col-span-1 mr-6 my-2 ">
                {/* ROL */}
                <div className="flex flex-col">
                  <label className="font-bold text-base" htmlFor="">
                    {colaborador_acceso.rol ? "Rol:" : "Rol a ser asignado:"}
                  </label>
                  <div className="py-2">
                    {colaborador_acceso.rol?.name || "Normal"}
                  </div>
                </div>
              </div>
              {!!colaborador_acceso_invitacion_loading && (
                <div className="col-span-2 mt-4">
                  <LoadingSpinButton
                    className="w-full justify-center"
                    message="Enviando..."
                  ></LoadingSpinButton>
                </div>
              )}
              {!colaborador_acceso_invitacion_loading && (
                <div className="col-span-2 mt-4 flex flex-col gap-2">
                  {!!colaborador.email && (
                    <PrimaryButton className="w-full bg-sky-500">
                      {`Enviar ${
                        colaborador_acceso.id
                          ? "recuperación de contraseña"
                          : "invitación"
                      }`}
                    </PrimaryButton>
                  )}
                  {/* copiarLinkInvitacion */}
                  <PrimaryButton
                    type="button"
                    onClick={copiarLinkInvitacion}
                    className="w-full bg-sky-500"
                  >
                    {`Copiar link de invitación`}
                  </PrimaryButton>
                  <button
                    onClick={onCancelar}
                    className={`bg-white border-black border rounded w-full px-4 py-2 text-black`}
                  >
                    Cancelar
                  </button>
                  {!colaborador.email && !colaborador.ci && (
                    <div className="text-center w-full p-2 bg-zinc-100 border border-black rounded-md">
                      Necesita un correo o cédula para el envío de invitación
                    </div>
                  )}
                </div>
              )}
            </form>

            <div className="grid grid-cols-1 md:grid-cols-2 mt-2">
              {!!colaborador_acceso.id && (
                <div className="col-span-2 my-4 border-b pb-3">
                  <div className="font-bold text-lg">Bloquear acceso</div>
                  <div className="flex items-center">
                    <ToggleSwitch
                      checked={!colaborador_acceso.is_active}
                      onChange={onToggleChange}
                      className="mr-2"
                    />
                    <span>El colaborador no podrá acceder a su cuenta</span>
                  </div>
                </div>
              )}
              {/*  */}
              {!colaborador_acceso_invitacion_loading && (
                <div className="col-span-2 border-b pb-3">
                  <div className="flex items-center">
                    <input
                      type="checkbox"
                      className="w-5 h-5 mr-4"
                      name="eliminar-colaborador"
                      id="eliminar-colaborador"
                      checked={habilitarEliminar}
                      onChange={onChangeEliminar}
                    />
                    <span className="text-red-500 text-base font-bold">
                      Eliminar definitivamente
                    </span>
                  </div>
                  <div className="font-normal text-zinc-400 text-sm md:ml-8">
                    <p className="mb-3">
                      Al eliminar se desvincula completamente todos los datos
                      que este usuario pudiera mantener dentro de la plataforma:
                    </p>
                    <ul className="list-disc p-0 ml-8">
                      <li>
                        Quedarán sin Jefe directo todos aquellos colaboradores
                        asignados a este usuario.
                      </li>
                      <li>
                        Si existen evaluaciones en curso en donde este usuario
                        sea evaluador deberá asignarse uno nuevo.
                      </li>
                      <li>
                        No se eliminará el historial de las evaluaciones en
                        donde este usuario figurara.
                      </li>
                    </ul>
                    <button
                      className={`rounded-lg ml-8 mt-2 p-3 text-white ${
                        habilitarEliminar ? "bg-red-500" : "bg-zinc-300"
                      }`}
                      onClick={eliminarAcceso}
                      disabled={!habilitarEliminar}
                    >
                      Eliminar
                    </button>
                  </div>
                </div>
              )}
              {/*  */}
            </div>
          </div>
        </div>
      )}
      {/*  */}
      {!!esModoVista && (
        <div className="flex justify-center w-full mt-10">
          <div className="w-full md:w-1/2">
            <div className="border-b pb-2">
              <span className="font-bold text-lg">Información de acceso</span>
            </div>
            <div id="acceso" className="grid grid-cols-1 md:grid-cols-2 mt-2">
              <div className="col-span-1 mr-6 my-2 ">
                {/* EMAIL */}
                <div className="flex flex-col">
                  <label className="font-bold text-base" htmlFor="">
                    Usuario:
                  </label>
                  <div className="py-4">{displayUsername()}</div>
                </div>
              </div>
              <div className="col-span-1 mr-6 my-2 ">
                {/* ROL */}
                <div className="flex flex-col">
                  <label className="font-bold text-base" htmlFor="">
                    {colaborador_acceso.rol ? "Rol:" : "Rol a ser asignado:"}
                  </label>
                  <div className="py-2">
                    {colaborador_acceso.rol?.name || "Normal"}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default ColaboradorAccesoPage;
