import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
// import { evaluacionActions } from "../store/evaluacionSlice";

export const useEvaluacionData = (getAction, dataSelector, loadingSelector) => {
  const dispatch = useDispatch();
  const data = useSelector(dataSelector);
  const loading = useSelector(loadingSelector);
  const [selectedItem, setSelectedItem] = useState(null);

  const fetchData = (params) => {
    dispatch(getAction(params));
  };

  //   useEffect(() => {
  //     if (selectedItem) {
  //       fetchData({ id: selectedItem.id });
  //     }
  //   }, [selectedItem]);

  return { data, loading, selectedItem, setSelectedItem, fetchData };
};
