import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import logo from "src/images/logo.svg";
import ChevronCard from "src/modules/common/components/ChevronCard";
import FileInput from "src/modules/common/components/FileInput";
import ImageUpload from "src/modules/common/components/ImageUpload";
import { commonActions } from "src/modules/common/handler/redux";
import NombreModal from "../components/NombreModal";
import RubroModal from "../components/RubroModal";
import { empresaActions } from "../handlers/redux";
import ToggleSwitch from "src/modules/common/components/ToggleSwitch";

function Ajuste() {
  const dispatch = useDispatch();
  const [showImageInput, setShowImageInput] = useState(false);
  const empresa = useSelector((state) => state.empresa.empresa);
  const {
    habilitacion_edicion_propia_kpis = false,
    limitar_visibilidad_plan_carrera = false,
    deshabilitar_edicion_kpis = false,
    habilitar_puntaje_evaluacion_mayor_100 = false,
    permiso_kpi = true,
    permiso_objetivo = true,
    permiso_evaluacion = true,
    permiso_plan_carrera = true,
    permiso_beneficio = true,
    permiso_feedback = true,
    permiso_escala_salarial = true,
  } = empresa;
  const empresaLoading = useSelector((state) => state.empresa.empresa_loading);

  const changeEdicionKpis = () => {
    // setActivarEdicionKpis(!activarEdicionKpis);
    dispatch(
      empresaActions.setHabilitarEdicionKpis(!habilitacion_edicion_propia_kpis)
    );
  };

  const onDeshabilitarCreacionKpis = () => {
    dispatch(
      empresaActions.setDeshabilitarEdicionKpis(!deshabilitar_edicion_kpis)
    );
  };

  const onHabilitarSuperarCalificacionEvaluacion = () => {
    dispatch(
      empresaActions.setHabilitarSuperarPuntajeEvaluacion(
        !habilitar_puntaje_evaluacion_mayor_100
      )
    );
  };

  const changeVisibilidadPlanCarrera = () => {
    // setActivarEdicionKpis(!activarEdicionKpis);
    dispatch(
      empresaActions.setVisibilidadPlanCarrera(
        !limitar_visibilidad_plan_carrera
      )
    );
  };

  const changePermiso = (permiso) => () => {
    dispatch(
      empresaActions.setPermisosEmpresa({
        permiso: permiso.permiso,
        valor: !permiso.value,
      })
    );
  };

  React.useEffect(() => {
    dispatch(commonActions.setTitle("Ajustes"));
    dispatch(commonActions.showSidebar(true));
    dispatch(empresaActions.empresa());
    !!process.env.REACT_APP_DEBUG && console.info("empresa", empresa);
  }, []);

  const showNombreModal = () => {
    dispatch(empresaActions.setEmpresaNombreModal({ open: true }));
  };
  const showRubroModal = () => {
    dispatch(empresaActions.setEmpresaRubroModal({ open: true }));
  };

  const SkeletonLoader = () => {
    return (
      <div className="ml-6 border-b py-4 px-2">
        <div className="flex justify-between items-center py-4 animate-pulse">
          <div className="flex flex-col gap-4 flex-1">
            <div className="h-2 w-1/3 bg-slate-200 rounded-md"></div>
            <div className="h-2 w-2/3 bg-slate-200 rounded-md"></div>
          </div>
          <div className="h-2 w-12 bg-slate-200 rounded-md"></div>
        </div>
      </div>
    );
  };

  const onClickMarca = (e) => {
    e.stopPropagation();
    setShowImageInput(!showImageInput);
  };

  const uploadLogo = (file) => {
    dispatch(empresaActions.empresaSet({ ...empresa, logotipo: file }));
    dispatch(empresaActions.empresaUpdateLogo());
    setShowImageInput(false);
  };

  return (
    <>
      <div className=" flex">
        <div className="w-full">
          <div className="container mt-4 flex flex-col">
            {!empresaLoading && (
              <>
                <div className="md:ml-16 col-span-12 md:col-span-11">
                  <div className="grid grid-cols-1 mb-3">
                    <div className="flex items-start w-100 border-b py-4 mb-3">
                      <h5 className="font-bold text-lg">
                        Perfil de la empresa
                      </h5>
                    </div>
                  </div>
                  {/* NOMBRE */}
                  <div className="grid grid-cols-1 mb-4 ml-4">
                    <div
                      className="ml-6 border-b py-4 px-2 hover:cursor-pointer hover:bg-sky-100"
                      onClick={() => showNombreModal()}
                    >
                      <ChevronCard
                        title={"Nombre de la organización: "}
                        description={
                          "Personaliza Wel y cambia el nombre de tu organización"
                        }
                        data={empresa.nombre}
                      />
                    </div>
                  </div>
                  {/* RUBRO */}
                  <div className="grid grid-cols-1 mb-4 ml-4">
                    <div
                      className="ml-6 border-b py-4 px-2 hover:cursor-pointer hover:bg-sky-100"
                      onClick={() => showRubroModal()}
                    >
                      <ChevronCard
                        title={"Rubro de la organización: "}
                        description={
                          "Identifica la actividad comercial de tu organización"
                        }
                        data={empresa.rubro?.nombre || "Sin Rubro"}
                      />
                    </div>
                  </div>
                  {/* LOGO */}
                  <div className="grid grid-cols-1 ml-4">
                    <div className="ml-6 border-b py-4 px-2 hover:cursor-pointer hover:bg-sky-100">
                      <div onClick={onClickMarca}>
                        <ChevronCard
                          toggleArrow={showImageInput}
                          title={"Marca personalizada"}
                          description={
                            "Carga el logo de tu empresa y personaliza tu organización"
                          }
                          image={empresa.logotipo ? empresa.logotipo : logo}
                        />
                      </div>
                      <div>
                        {/*  */}
                        <ImageUpload
                          visible={showImageInput}
                          className={"mt-3 w-3/4"}
                          onSubmit={uploadLogo}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {/* KPIS  */}
                <div className="md:ml-16 col-span-12 md:col-span-11 mt-8">
                  <div className="grid grid-cols-1 mb-3">
                    <div className="flex items-start w-100 border-b py-4 mb-3">
                      <h5 className="font-bold text-lg">KPIS</h5>
                    </div>
                  </div>
                  {/* ITEM */}
                  <div className="grid grid-cols-1 mb-4 ml-4">
                    <div className="ml-6 border-b py-4 px-2 flex gap-4">
                      <p>
                        Activar edición de KPIS de parte de los mismos
                        colaboradores
                      </p>
                      <ToggleSwitch
                        className="mr-2"
                        checked={habilitacion_edicion_propia_kpis}
                        onChange={changeEdicionKpis}
                      />
                    </div>
                  </div>
                  {/* ITEM */}
                  <div className="grid grid-cols-1 mb-4 ml-4">
                    <div className="ml-6 border-b py-4 px-2 flex gap-4">
                      <p>
                        Deshabilitar creación y modificación de KPIs por parte
                        de Líderes
                      </p>
                      <ToggleSwitch
                        className="mr-2"
                        checked={deshabilitar_edicion_kpis}
                        onChange={onDeshabilitarCreacionKpis}
                      />
                    </div>
                  </div>
                </div>
                {/* EVALUACION DESEMPEÑO */}
                <div className="md:ml-16 col-span-12 md:col-span-11 mt-8">
                  <div className="grid grid-cols-1 mb-3">
                    <div className="flex items-start w-100 border-b py-4 mb-3">
                      <h5 className="font-bold text-lg">
                        Evaluación de Desempeño
                      </h5>
                    </div>
                  </div>
                  {/* SWITCH */}
                  <div className="grid grid-cols-1 mb-4 ml-4">
                    <div className="ml-6 border-b py-4 px-2 flex gap-4">
                      <p>Habilitar superar el 100% en las calificaciones</p>
                      <ToggleSwitch
                        className="mr-2"
                        checked={habilitar_puntaje_evaluacion_mayor_100}
                        onChange={onHabilitarSuperarCalificacionEvaluacion}
                      />
                    </div>
                  </div>
                </div>
                {/* PLAN DE CARRERA */}
                <div className="md:ml-16 col-span-12 md:col-span-11 mt-8">
                  <div className="grid grid-cols-1 mb-3">
                    <div className="flex items-start w-100 border-b py-4 mb-3">
                      <h5 className="font-bold text-lg">Plan de Carrera</h5>
                    </div>
                  </div>
                  {/* SWITCH */}
                  <div className="grid grid-cols-1 mb-4 ml-4">
                    <div className="ml-6 border-b py-4 px-2 flex gap-4">
                      <p>
                        Limitar visibilidad de familias y categorías para los
                        colaboradores
                      </p>
                      <ToggleSwitch
                        className="mr-2"
                        checked={limitar_visibilidad_plan_carrera}
                        onChange={changeVisibilidadPlanCarrera}
                      />
                    </div>
                  </div>
                </div>
                {/* PERMISOS */}
                <div className="md:ml-16 col-span-12 md:col-span-11 mt-8">
                  <div className="grid grid-cols-1 mb-3">
                    <div className="flex items-start w-100 border-b py-4 mb-3">
                      <h5 className="font-bold text-lg">Permisos de módulos</h5>
                    </div>
                  </div>
                  {/* SWITCH */}
                  <div className="grid grid-cols-1 mb-4 ml-4">
                    {[
                      { modulo: "KPI", value: permiso_kpi, permiso: "kpi" },
                      {
                        modulo: "Objetivos",
                        value: permiso_objetivo,
                        permiso: "objetivo",
                      },
                      {
                        modulo: "Evaluación de Desempeño",
                        value: permiso_evaluacion,
                        permiso: "evaluacion",
                      },
                      {
                        modulo: "Plan de Carrera",
                        value: permiso_plan_carrera,
                        permiso: "plan_carrera",
                      },
                      {
                        modulo: "Beneficios",
                        value: permiso_beneficio,
                        permiso: "beneficio",
                      },
                      {
                        modulo: "Feedback",
                        value: permiso_feedback,
                        permiso: "permiso_feedback",
                      },
                      {
                        modulo: "Remuneraciones",
                        value: permiso_escala_salarial,
                        permiso: "escala_salarial",
                      },
                    ].map((permiso, pi) => {
                      return (
                        <div className="ml-6 py-4 px-2 flex justify-between gap-4 w-5/5 md:w-1/3">
                          <p>{permiso.modulo}</p>
                          <ToggleSwitch
                            className="mr-2"
                            checked={permiso.value}
                            onChange={changePermiso(permiso)}
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
              </>
            )}
            {/* SKELETON LOADER */}
            {!!empresaLoading && (
              <div className="flex flex-col mt-4 w-full px-16">
                <div className="flex flex-1 items-start w-full mb-3">
                  <div className="font-bold text-lg py-4 border-b w-full">
                    Perfil de la empresa
                  </div>
                </div>
                {[1, 2, 3].map((sl, sli) => (
                  <SkeletonLoader
                    key={`skeleton-loader-${sli}`}
                  ></SkeletonLoader>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
      <RubroModal></RubroModal>
      <NombreModal></NombreModal>
    </>
  );
}

export default Ajuste;
