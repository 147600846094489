import axios from "axios";
const URL_BASE =
  process.env.REACT_APP_API_URL || "http://localhost:8000/api/admin";

export const kpiApi = (token) => {
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };

  return {
    create: async ({
      nombre,
      descripcion,
      periodo,
      peso,
      metrica,
      meta,
      modifica_copias,
      colaboradores,
      rangos,
    }) => {
      let params = {
        nombre,
        descripcion,
        periodo,
        peso,
        metrica,
        meta,
        modifica_copias,
        colaboradores,
        rangos,
      };
      try {
        const kpi = await axios.post(URL_BASE + "/kpi/create", params, {
          headers,
        });
        return kpi;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log(JSON.stringify(error));
        throw error;
      }
    },
    update: async ({
      id,
      nombre,
      descripcion,
      periodo,
      peso,
      metrica,
      meta,
      modifica_copias,
      colaboradores = [],
      rangos,
    }) => {
      let params = {
        nombre,
        descripcion,
        periodo,
        peso,
        metrica,
        meta,
        modifica_copias,
        rangos,
      };
      if (colaboradores.length) {
        colaboradores = colaboradores.map((c, idx) => c.id || c);
        params = { colaboradores, ...params };
      }
      !!process.env.REACT_APP_DEBUG &&
        console.info("kpi update params", params, colaboradores);
      try {
        const data = await axios.put(URL_BASE + `/kpi/${id}/update`, params, {
          headers,
        });
        return data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log(JSON.stringify(error));
        throw error;
      }
    },
    get: async ({ id }) => {
      try {
        const kpi = await axios.get(URL_BASE + `/kpi/${id}/detail`, {
          headers,
        });
        !!process.env.REACT_APP_DEBUG && console.info("kpi get api", kpi);
        return kpi.data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log("Error", error);
        throw error;
      }
    },
    delete: async ({ id }) => {
      try {
        const data = await axios.delete(URL_BASE + `/kpi/${id}/delete`, {
          headers,
        });
        return data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log(JSON.stringify(error));
        throw error;
      }
    },
    kpiAddColaboradores: async ({ kpiId, colaboradoresIds }) => {
      // Asignar varios Colaboradores por id a un KPI
      !!process.env.REACT_APP_DEBUG && console.info("kpiAddColaboradores api");
      try {
        const data = await axios.post(
          URL_BASE + `/kpi/${kpiId}/colaborador/add`,
          { colaboradores_ids: colaboradoresIds },
          { headers }
        );
        return data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log(JSON.stringify(error));
        throw error;
      }
    },
    asignarKpisColaboradores: async ({ kpisIds, colaboradoresIds }) => {
      // Relaciones varios KPIs x Colaboradores
      !!process.env.REACT_APP_DEBUG &&
        console.info("asignarKpisColaboradores api", {
          kpisIds,
          colaboradoresIds,
        });
      try {
        const data = await axios.post(
          URL_BASE + `/kpi/kpis-colaboradores/asignar`,
          { kpis_ids: kpisIds, colaboradores_ids: colaboradoresIds },
          { headers }
        );
        return data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log(JSON.stringify(error));
        throw error;
      }
    },
    colaboradorKpiDelete: async ({ colaboradorId, kpiId }) => {
      // Borra KPI de un Colaborador
      try {
        const data = await axios.post(
          URL_BASE + `/kpi/colaborador/${colaboradorId}/kpi/${kpiId}/delete`,
          {},
          { headers }
        );
        return data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log(JSON.stringify(error));
        throw error;
      }
    },
    kpiColaboradorDelete: async ({ kpiId, colaboradorId }) => {
      // Borra a un Colaborador de un KPI
      try {
        const data = await axios.post(
          URL_BASE + `/kpi/${kpiId}/colaborador/${colaboradorId}/delete`,
          {},
          { headers }
        );
        return data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log(JSON.stringify(error));
        throw error;
      }
    },
    kpiColaboradoresDelete: async ({ kpiId, colaboradoresId }) => {
      // Borra varios Colaboradores de un KPI
      try {
        const data = await axios.post(
          URL_BASE + `/kpi/${kpiId}/colaboradores/delete`,
          { colaboradores_ids: colaboradoresId },
          { headers }
        );
        return data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log(JSON.stringify(error));
        throw error;
      }
    },
    colaboradorKpisDelete: async ({ colaboradorId, kpisIds }) => {
      // Borra varios KPIs de un Colaborador
      try {
        const data = await axios.post(
          URL_BASE + `/kpi/colaborador/${colaboradorId}/kpis/delete`,
          { kpis_id: kpisIds },
          { headers }
        );
        return data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log(JSON.stringify(error));
        throw error;
      }
    },
    kpisDelete: async ({ kpisIds }) => {
      !!process.env.REACT_APP_DEBUG && console.info("kpisDelete api", kpisIds);
      try {
        const data = await axios.post(
          URL_BASE + `/kpi/kpis/delete`,
          { kpis_id: kpisIds },
          {
            headers,
          }
        );
        return data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log(JSON.stringify(error));
        throw error;
      }
    },
    list: async ({ kpis_filter }) => {
      const { nombre = "" } = kpis_filter;
      try {
        const kpis = await axios.get(URL_BASE + `/kpi/?search=${nombre}`, {
          headers,
        });
        return kpis.data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log("Error", error);
        throw error;
      }
    },
    colaboradoresList: async (colaboradores_filter) => {
      const {
        nombre = "",
        ordering = "",
        area_id,
        sucursal_id,
        departamento_id,
        pagination = { page: 1, pageSize: 50 },
      } = colaboradores_filter;
      const { page, pageSize } = pagination;
      //
      let apiURL = `/kpi/colaboradores?search=${nombre}`;
      if (area_id) {
        apiURL = apiURL + `&area_id=${area_id}`;
      }
      if (sucursal_id) {
        apiURL = apiURL + `&sucursal_id=${sucursal_id}`;
      }
      if (departamento_id) {
        apiURL = apiURL + `&departamento_id=${departamento_id}`;
      }
      if (ordering) {
        apiURL = apiURL + `&ordering=${ordering}`;
      }
      if (page && pageSize) {
        apiURL = apiURL + `&page=${page}&page_size=${pageSize}`;
      }
      //
      try {
        const colaboradores = await axios.get(URL_BASE + apiURL, {
          headers,
        });
        !!process.env.REACT_APP_DEBUG &&
          console.info("colaboradores api", colaboradores);
        return colaboradores.data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log("Error", error);
        throw error;
      }
    },
    kpiColaboradoresList: async ({ id, nombreColaborador = "" }) => {
      !!process.env.REACT_APP_DEBUG && console.info("kpiColaboradoresList", id);
      if (!id) return;
      try {
        const colaboradores = await axios.get(
          URL_BASE + `/kpi/${id}/colaboradores?search=${nombreColaborador}`,
          {
            headers,
          }
        );
        return colaboradores.data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log("Error", error);
        throw error;
      }
    },
    //
    asignarKpisMasivo: async (params) => {
      try {
        const response = await axios.post(
          URL_BASE + "/kpi/colaboradores/asignar-masivo-kpis",
          params,
          {
            headers,
          }
        );
        return response;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log(JSON.stringify(error));
        throw error;
      }
    },
    //
    colaboradorKpisList: async ({ id, colaboradorKpis_filter }) => {
      const { nombre = "" } = colaboradorKpis_filter;
      const endpoint = `/kpi/kpi-colaborador/colaborador/${id}/?search=${nombre}`;
      try {
        const kpis = await axios.get(URL_BASE + endpoint, {
          headers,
        });
        return kpis.data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log("Error", error);
        throw error;
      }
    },
    colaboradorKpisNoVigentesList: async ({ id, colaboradorKpis_filter }) => {
      const {
        nombre = "",
        ordering = "",
        pagination = { page: 1, pageSize: 50 },
      } = colaboradorKpis_filter;
      const { page, pageSize } = pagination;
      const endpoint = `/kpi/kpi-colaborador/colaborador/no-vigente/${id}/?search=${nombre}&ordering=${ordering}
      &page=${page}&page_size=${pageSize}`;
      try {
        const kpis = await axios.get(URL_BASE + endpoint, {
          headers,
        });
        return kpis.data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log("Error", error);
        throw error;
      }
    },
    colaboradorKpisUpdate: async ({ id, kpis, archivados }) => {
      try {
        const response = await axios.post(
          `${URL_BASE}/kpi/kpi-colaborador/colaborador/${id}/update-kpis`,
          { kpis: kpis, archivados: archivados },
          { headers }
        );
        return response;
      } catch (error) {
        throw error;
      }
    },
    kpiColaboradorUpdate: async ({
      id,
      nombre,
      descripcion,
      periodo,
      peso,
      metrica,
      meta,
      colaboradores = [],
      rangos,
    }) => {
      let params = {
        nombre,
        descripcion,
        periodo,
        metrica,
        meta,
        rangos,
      };
      if (colaboradores.length) {
        colaboradores = colaboradores.map((c, idx) => c.id || c);
        params = { colaboradores, ...params };
      }
      !!process.env.REACT_APP_DEBUG &&
        console.info("kpiC update params", params, colaboradores);
      try {
        const data = await axios.put(
          URL_BASE + `/kpi/kpi-colaborador/${id}/update`,
          params,
          {
            headers,
          }
        );
        return data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log(JSON.stringify(error));
        throw error;
      }
    },
    kpiColaboradorDetail: async ({ id }) => {
      try {
        const kpi = await axios.get(
          URL_BASE + `/kpi/kpi-colaborador/${id}/detail`,
          {
            headers,
          }
        );
        !!process.env.REACT_APP_DEBUG && console.info("kpiC detail api", kpi);
        return kpi.data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log("Error", error);
        throw error;
      }
    },
    kpiColaboradorHistorial: async ({ id }) => {
      try {
        const response = await axios.get(
          URL_BASE + `/kpi/kpi-colaborador/${id}/history`,
          {
            headers,
          }
        );
        return response.data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log("Error", error);
        throw error;
      }
    },
    //
    metricaslist: async () => {
      try {
        const metricas = await axios.get(URL_BASE + "/kpi/metricas", {
          headers,
        });
        return metricas.data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log("Error", error);
        throw error;
      }
    },
    periodoslist: async () => {
      try {
        const periodos = await axios.get(URL_BASE + "/kpi/periodos", {
          headers,
        });
        return periodos.data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log("Error", error);
        throw error;
      }
    },
    importarKpisPorColaborador: async ({ fileToUpload }) => {
      const headersForm = {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      };
      const response = await axios.post(
        URL_BASE + `/kpi/importar-kpis-por-colaborador/`,
        { file: fileToUpload },
        { headers: headersForm }
      );
      return response.data;
    },
    importarKpisPorCargo: async ({ fileToUpload }) => {
      const headersForm = {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      };
      const response = await axios.post(
        URL_BASE + `/kpi/importar-kpis-por-cargo/`,
        { file: fileToUpload },
        { headers: headersForm }
      );
      return response.data;
    },
  };
};
