import axios from "axios";
const URL_BASE =
  process.env.REACT_APP_API_URL || "http://localhost:8000/api/admin";

export const areaApi = (token) => {
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };

  return {
    create: async ({ nombre, descripcion }) => {
      try {
        const area = await axios.post(
          URL_BASE + "/area/",
          { nombre, descripcion },
          { headers }
        );
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log(JSON.stringify(error));
        throw error;
      }
    },
    update: async ({ id, nombre, descripcion }) => {
      try {
        const data = await axios.put(
          URL_BASE + `/area/${id}/`,
          { nombre, descripcion },
          { headers }
        );
        return data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log(JSON.stringify(error));
        throw error;
      }
    },
    delete: async ({ id }) => {
      try {
        const data = await axios.delete(URL_BASE + `/area/${id}/`, {
          headers,
        });
        return data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log(JSON.stringify(error));
        throw error;
      }
    },
    areasDelete: async ({ areasIds }) => {
      try {
        const data = await axios.post(
          URL_BASE + `/area/delete-ids/`,
          { ids: areasIds },
          { headers }
        );
        return data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log(JSON.stringify(error));
        throw error;
      }
    },
    list: async ({ areas_filter }) => {
      const { nombre = "", pagination = { page: 1, pageSize: 100 } } =
        areas_filter;
      const { page, pageSize } = pagination;
      try {
        const areas = await axios.get(
          `${URL_BASE}/area/?search=${nombre}&page=${page}&page_size=${pageSize}`,
          {
            headers,
          }
        );
        return areas.data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log("areas error", error);
        throw error;
      }
    },
    //
    importarVerificar: async ({ fileToUpload }) => {
      try {
        const payload = {
          file: fileToUpload,
        };
        const headersForm = {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        };
        !!process.env.REACT_APP_DEBUG && console.info("area importar api");
        const response = await axios.post(
          URL_BASE + `/estructura/importar-areas-verificar/`,
          { file: fileToUpload },
          { headers: headersForm }
        );
        !!process.env.REACT_APP_DEBUG && console.info("response", response);
        return response.data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log("areas error", error);
        throw error;
      }
    },
    importar: async (data) => {
      try {
        !!process.env.REACT_APP_DEBUG &&
          console.info("areas importar api", data);
        const response = await axios.post(
          URL_BASE + `/estructura/importar-areas/`,
          { areas: data },
          { headers }
        );
        !!process.env.REACT_APP_DEBUG && console.info("response", response);
        return response.data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log("areas error", error);
        throw error;
      }
    },
  };
};
