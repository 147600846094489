import { useEffect, useState } from "react";
import { FeedbackConfiguracionLayout } from "../components/FeedbackConfiguracionLayout";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { commonActions } from "src/modules/common/handler/redux";
import { SucursalSelect } from "src/modules/estructura/modules/sucursal/components/SucursalSelect";
import { AreaSelect } from "src/modules/estructura/modules/area/components/AreaSelect";
import { DepartamentoSelect } from "src/modules/estructura/modules/departamento/components/DepartamentoSelect";
import { sucursalActions } from "src/modules/estructura/modules/sucursal/handlers/redux";
import { areaActions } from "src/modules/estructura/modules/area/handlers/redux";
import { departamentoActions } from "src/modules/estructura/modules/departamento/handlers/redux";
import SearchInput from "src/modules/remuneracion/components/SearchInput";
import DataTable from "src/modules/common/components/DataTable";
import userDefault from "src/modules/common/components/images/user_default.png";
import { ChevronRight } from "react-bootstrap-icons";
import { feedbackActions } from "../handlers/redux";
import dayjs from "dayjs";
import { format } from "date-fns";
import ReactSelect from "react-select";
import MostrarPaginas from "src/modules/common/components/MostrarPaginas";

export function FeedbackInicio() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const params = useParams();
  const { idFeedback } = params;

  const feedback = useSelector((state) => state.feedback.feedback);
  const feedback_loading = useSelector(
    (state) => state.feedback.feedback_loading
  );
  const jefes_directos_list = useSelector(
    (state) => state.feedback.jefes_directos_list
  );
  const jefes_directos_list_loading = useSelector(
    (state) => state.feedback.jefes_directos_list_loading
  );
  const jefes_directos_list_count = useSelector(
    (state) => state.feedback.jefes_directos_list_count
  );
  const jefes_directos_list_checked = useSelector(
    (state) => state.feedback.jefes_directos_list_checked
  );
  const jefes_directos_list_filter = useSelector(
    (state) => state.feedback.jefes_directos_list_filter
  );

  const {
    nombre = "",
    sucursal,
    area,
    departamento,
  } = jefes_directos_list_filter;
  const [outerPageSize, setOuterPageSize] = useState(10);

  useEffect(() => {
    dispatch(commonActions.setTitle("Admin"));
    dispatch(commonActions.showSidebar(false));
    dispatch(feedbackActions.getFeedback(idFeedback));
    dispatch(feedbackActions.setJefesDirectosListFilter({}));
    if (idFeedback) {
      dispatch(feedbackActions.setFeedbackId(idFeedback));
    }
    dispatch(feedbackActions.getJefesDirectosList({}));
    dispatch(sucursalActions.sucursales());
    dispatch(areaActions.areas());
    dispatch(departamentoActions.departamentos());
  }, []);

  const onClickBack = () => {
    navigate("/feedback/");
  };

  const onFilter = (field) => (e) => {
    const value = e.target.value;
    dispatch(
      feedbackActions.setJefesDirectosListFilter({
        ...jefes_directos_list_filter,
        // idFeedback: idFeedback,
        [field]: value,
      })
    );
  };

  const onDeleteFeedback = (id) => {
    const deseaEliminar = window.confirm(
      "¿Está seguro de eliminar al jefe directo seleccionado de este feedback?"
    );
    if (!!deseaEliminar) {
      const params = {
        feedback_id: idFeedback,
        jefes_directos_ids: [id],
      };
      dispatch(feedbackActions.bulkDeleteJefesDirectos(params));
    }
  };

  const goToDetail = (idJefeDirecto) => {
    navigate(`/feedback/${idFeedback}/jefe-directo/${idJefeDirecto}/`);
  };

  const cantidadColaboradoresFotos = 5;

  const datatableConfig = {
    headers: [
      {
        title: "Jefes directos",
        contentClass: "font-bold px-2 py-4",
        // onOrder: setOrdering("nombre"),
        // accessor: "nombre",
        contentRenderer: (item) => (
          <div className="flex flex-row gap-x-2 m-4">
            <img
              className="object-cover h-12 w-12 rounded-md"
              src={item.foto_empleado ? item.foto_empleado : userDefault}
              alt={`Perfil`}
            />
            <div className="flex flex-col gap-1">
              <span className="font-semibold text-base">
                {item.nombre} {item.apellido}
              </span>
              <span className="text-base">
                Cargo: {item.cargo ? item.cargo.nombre : "Sin cargo"}
              </span>
            </div>
          </div>
        ),
      },
      {
        title: "Sucursal",
        contentClass: "font-bold px-2 py-4",
        // onOrder: setOrdering(""),
        // accessor: "nombre",
        contentRenderer: (item) => (
          <div className="flex flex-col">
            <span className="">
              {item.sucursal ? item.sucursal.nombre : "Sin sucursal"}
            </span>
          </div>
        ),
      },
      {
        title: "Área",
        contentClass: "font-bold px-2 py-4",
        // onOrder: setOrdering(""),
        // accessor: "nombre",
        contentRenderer: (item) => (
          <div className="flex flex-col">
            <span className="">
              {item.area ? item.area.nombre : "Sin área"}
            </span>
          </div>
        ),
      },
      {
        title: "Departamento",
        contentClass: "font-bold px-2 py-4",
        // onOrder: setOrdering(""),
        // accessor: "nombre",
        contentRenderer: (item) => (
          <div className="flex flex-col">
            <span className="">
              {item.departamento
                ? item.departamento.nombre
                : "Sin departamento"}
            </span>
          </div>
        ),
      },
      {
        title: "Colaboradores",
        contentClass: "font-bold px-2 py-4",
        // onOrder: setOrdering(""),
        // accessor: "nombre",
        contentRenderer: (item) => (
          <div className="flex flex-col">
            <div className="flex flex-row align-middle gap-1">
              {item.colaboradores
                .slice(0, cantidadColaboradoresFotos)
                .map((colaborador, index) => (
                  <div
                    key={index}
                    className="rounded-lg overflow-hidden w-8 h-8 "
                  >
                    <img
                      src={
                        colaborador.foto_empleado
                          ? colaborador.foto_empleado
                          : userDefault
                      }
                      alt="Perfil"
                      title={`${colaborador.nombre} ${
                        colaborador.apellido ? colaborador.apellido : ""
                      }`}
                      className="w-full h-full object-cover"
                    />
                  </div>
                ))}
              {/*Indicador de cuantos jefes más hay, mostrado solo si hay más de la cantidad que indique cantidadJefesFotos*/}
              {item.colaboradores.length > cantidadColaboradoresFotos && (
                <div
                  className="flex justify-center items-center w-8 h-8 rounded-lg bg-gray-200 text-black cursor-default"
                  title={`${
                    item.colaboradores.length - cantidadColaboradoresFotos
                  } ${
                    item.colaboradores.length - cantidadColaboradoresFotos === 1
                      ? "colaborador más"
                      : "colaboradores más"
                  }`}
                >
                  +{item.colaboradores.length - cantidadColaboradoresFotos}
                </div>
              )}
            </div>
          </div>
        ),
      },
      {
        title: "",
        contentClass: "font-bold px-2 py-4",
        // onOrder: setOrdering(""),
        // accessor: "nombre",
        contentRenderer: (item) => (
          <div className="cursor-pointer">
            <ChevronRight
              color="#0ea4e9"
              onClick={() => goToDetail(item.id)}
            ></ChevronRight>
          </div>
        ),
      },
    ],
    rowActions: [
      {
        label: "Eliminar",
        action: (item) => {
          onDeleteFeedback(item.id);
        },
      },
    ],
  };

  const onPaginate = (page, pageSize) => {
    dispatch(
      feedbackActions.setJefesDirectosListFilter({
        ...jefes_directos_list_filter,
        pagination: {
          page: page,
          pageSize: pageSize,
        },
        // idFeedback: idFeedback,
      })
    );
  };

  const onCheckAll = () => {
    let checkedData = JSON.parse(JSON.stringify(jefes_directos_list));
    if (jefes_directos_list_checked.length > 0) {
      checkedData = [];
    }
    dispatch(feedbackActions.setCheckJefesDirectosList(checkedData));
  };

  const onCheckJefeDirecto = (item) => (event) => {
    console.log(item);
    dispatch(
      feedbackActions.checkJefesDirectosList({
        jefeDirectoList: item,
        checked: event.target.checked,
      })
    );
  };

  const actionOptions = [
    {
      value: "delete",
      label: "Eliminar",
      // action: () => {},
    },
  ];

  const onAction = (v) => {
    if (v.value === "delete") {
      const deseaEliminar = window.confirm(
        "¿Está seguro de eliminar a los jefes directos seleccionados de este feedback?"
      );
      if (!!deseaEliminar) {
        const jefes_directos_ids = jefes_directos_list_checked.map(
          (jefe_directo) => jefe_directo.id
        );
        const params = {
          feedback_id: idFeedback,
          jefes_directos_ids: jefes_directos_ids,
        };
        dispatch(feedbackActions.bulkDeleteJefesDirectos(params));
      }
      return;
    }
  };

  const onSearchJefesDirectos = (e) => {
    let searchText = e.target.value;
    dispatch(
      feedbackActions.setJefesDirectosListFilter({
        ...jefes_directos_list_filter,
        nombre: searchText,
        // idFeedback: idFeedback,
      })
    );
  };

  const onChangePageSize = (e) => {
    let value = e.target.value;
    dispatch(
      feedbackActions.setJefesDirectosListFilter({
        ...jefes_directos_list_filter,
        pagination: {
          pageSize: parseInt(value),
          page: 1,
        },
        // idFeedback: idFeedback,
      })
    );
    setOuterPageSize(parseInt(value));
  };

  return (
    <FeedbackConfiguracionLayout title={"Feedback"} onClickBack={onClickBack}>
      <div className="py-2 px-8 -mx-8">
        <div className="form flex justify-center mt-4">
          <div className="flex flex-col gap-4 w-full md:w-9/12">
            <div className="border-b pb-2">
              <h2 className="font-semibold text-xl">Configuración inicial</h2>
            </div>
            <div className="flex flex-col w-full">
              <div>
                <h2 className="font-semibold">Nombre:</h2>
                <div className="py-2">{feedback.nombre}</div>
              </div>
              <div className="flex flex-row justify-between gap-8 mt-6">
                <div className="flex flex-col w-1/2">
                  <label className="font-bold text-base" htmlFor="">
                    Fecha inicio
                  </label>
                  {feedback.fecha_inicio &&
                    typeof feedback.fecha_inicio === "string" && (
                      <div className="py-2">
                        {dayjs(feedback.fecha_inicio.slice(0, 10)).format(
                          "DD/MM/YYYY"
                        )}
                      </div>
                    )}
                </div>
                <div className="flex flex-col w-1/2">
                  <label className="font-bold text-base" htmlFor="">
                    Fecha de finalización
                  </label>
                  {feedback.fecha_fin &&
                    typeof feedback.fecha_fin === "string" && (
                      <div className="py-2">
                        {dayjs(feedback.fecha_fin.slice(0, 10)).format(
                          "DD/MM/YYYY"
                        )}
                      </div>
                    )}
                </div>
              </div>
              <div className="border-b pb-2 my-6">
                <h2 className="font-semibold text-lg">Feedback</h2>
              </div>
              <div>
                <div className="flex items-center gap-2 mt-4">
                  <SearchInput
                    containerClass={`w-full`}
                    placeHolder={"Buscar por nombre o cargo..."}
                    value={nombre}
                    onChange={onSearchJefesDirectos}
                  />
                  <div className="text-sm">
                    <ReactSelect
                      className="w-52 h-10 bg-blue-200 inline-grid"
                      value={{
                        value: "",
                        label: `(${jefes_directos_list_checked.length}) seleccionados`,
                      }}
                      options={actionOptions}
                      onChange={onAction}
                    ></ReactSelect>
                  </div>
                  <MostrarPaginas
                    containerClass={"w-1/2x"}
                    onChangePageSize={onChangePageSize}
                    defaultOption={10}
                  />
                </div>

                <div className="flex flex-row justify-between my-6 gap-4">
                  <SucursalSelect
                    onChange={onFilter("sucursal")}
                    defaultLabel="Sucursal: (Todas)"
                    className={"w-full"}
                    value={sucursal}
                  />
                  <AreaSelect
                    onChange={onFilter("area")}
                    defaultLabel="Áreas: (Todas)"
                    className={"w-full"}
                    value={area}
                  />
                  <DepartamentoSelect
                    onChange={onFilter("departamento")}
                    defaultLabel="Departamentos: (Todos)"
                    className={"w-full"}
                    value={departamento}
                  />
                </div>
                <DataTable
                  containerClass={"my-2"}
                  config={datatableConfig}
                  data={jefes_directos_list}
                  loading={jefes_directos_list_loading}
                  noHeaders={false}
                  //
                  totalRecords={jefes_directos_list_count}
                  onPaginate={onPaginate}
                  outerPageSize={outerPageSize}
                  //
                  checkable={true}
                  onCheckAll={onCheckAll}
                  onItemCheck={onCheckJefeDirecto}
                  checkedItems={jefes_directos_list_checked}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </FeedbackConfiguracionLayout>
  );
}
