import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { sucursalActions } from "src/modules/estructura/modules/sucursal/handlers/redux";

const useSucursales = () => {
  const dispatch = useDispatch();

  const sucursales_results = useSelector((state) => state.sucursal.sucursales);
  const { results: sucursales } = sucursales_results;

  const sucursales_filter = useSelector(
    (state) => state.sucursal.sucursales_filter
  );
  const { nombre } = sucursales_filter;

  const filterSucursales = (nombre) => {
    dispatch(sucursalActions.setSucursalesFilter({ nombre: nombre }));
  };

  return {
    sucursales,
    nombre,
    filterSucursales,
  };
};

export default useSucursales;
