import React from "react";
import { ArrowLeft, XLg } from "react-bootstrap-icons";
import { useDispatch, useSelector } from "react-redux";
import Modal from "src/modules/common/components/Modal";
import { commonActions } from "../handler/redux";

function SelectModal({
  content = <></>,
  title = "",
  titleClass = "",
  extraClass = "w-6/12",
  goBackFn = "",
  onClose,
}) {
  const dispatch = useDispatch();
  const modal = useSelector((state) => state.common.select_modal);
  const setOpen = (open) => {
    dispatch(commonActions.setSelectModal({ open }));
  };
  const OnCloseLocal = () => {
    setOpen(false);
    if (!!onClose && typeof onClose === "function") {
      let shouldClose = onClose(); //igual se ejecutará onClose por más que no retorne nada
      if(shouldClose === false){ //que solo se ejecute cuando es false y no cuando es undefined
        setOpen(true)
      }
    }
  };

  return (
    <Modal isOpen={modal.open}>
      <div className={`rounded-lg bg-white ${extraClass}`}>
        <div className="flex flex-col gap-3 p-4">
          <div className="flex flex-row justify-between text-lg font-bold">
            <div className={titleClass + `flex items-center gap-2`}>
              {!!goBackFn && (
                <button onClick={goBackFn}>
                  <ArrowLeft size={24} />
                </button>
              )}
              <div className="max-w-md truncate">{title}</div>
            </div>
            <div onClick={OnCloseLocal}>
              <XLg />
            </div>
          </div>
          <div className="">{content}</div>
        </div>
      </div>
    </Modal>
  );
}

export default SelectModal;
