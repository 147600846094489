import { push } from "@lagunovsky/redux-react-router";
import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import { ErrorToast } from "src/modules/common/components/ErrorToast";
import { SuccessToast } from "src/modules/common/components/SuccessToast";
import { getToken, ObjectToString } from "src/utils/helpers";
import { sucursalApi } from "./api";
import { sucursalActions } from "./redux";

function* sucursalDeleteSaga({ payload }) {
  const token = getToken();
  const sucursal = payload;

  const params = { ...sucursal };
  !!process.env.REACT_APP_DEBUG && console.log({ payload });
  try {
    const data = yield call(sucursalApi(token).delete, params);
    yield put(sucursalActions.sucursales());
    yield put(sucursalActions.sucursalDeleteSuccess());
    SuccessToast({ message: "Sucursal eliminada con éxito!" });
  } catch (error) {
    const errores = error.response.data;
    const message = ObjectToString(errores);
    ErrorToast({ message: message });
    yield put(sucursalActions.sucursalDeleteSuccess());
    !!process.env.REACT_APP_DEBUG && console.log({ error });
  }
}

function* sucursalUpdateSaga({ payload }) {
  const token = getToken();
  const sucursal = yield select((state) => state.sucursal.sucursal);

  const params = { ...sucursal };
  try {
    const data = yield call(sucursalApi(token).update, params);
    yield put(sucursalActions.sucursalUpdateSuccess());
    yield put(push("/estructura/sucursales"));
    yield put(sucursalActions.sucursales());
    yield put(sucursalActions.setSucursalCreateModal({ open: false }));
    SuccessToast({ message: "Sucursal editada con éxito!" });
  } catch (error) {
    const errores = error.response.data;
    const message = ObjectToString(errores);
    ErrorToast({ message: message });
    yield put(sucursalActions.sucursalUpdateSuccess());
    !!process.env.REACT_APP_DEBUG && console.log({ error });
  }
}

function* sucursalCreateSaga({ payload }) {
  const token = getToken();
  const sucursal = yield select((state) => state.sucursal.sucursal);

  const params = { ...sucursal };
  try {
    const data = yield call(sucursalApi(token).create, params);
    yield put(sucursalActions.sucursalCreateSuccess());
    yield put(push("/estructura/sucursales"));
    yield put(sucursalActions.setSucursalCreateModal({ open: false }));
    yield put(sucursalActions.sucursales());
    SuccessToast({ message: "Sucursal creada con éxito!" });
  } catch (error) {
    const errores = error.response.data;
    const message = ObjectToString(errores);
    ErrorToast({ message: message });
    yield put(sucursalActions.sucursalCreateSuccess());
    !!process.env.REACT_APP_DEBUG && console.log({ error });
  }
}

function* sucursalesSaga({ payload }) {
  const token = getToken();
  const sucursales_filter = yield select(
    (state) => state.sucursal.sucursales_filter
  );
  try {
    const data = yield call(sucursalApi(token).list, { sucursales_filter });
    yield put(sucursalActions.setSucursales({ sucursales: data }));
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
  }
}

function* sucursalesDeleteSaga({ payload }) {
  const token = getToken();
  const sucursalesDelete = payload;
  !!process.env.REACT_APP_DEBUG && console.log({ payload });
  try {
    const data = yield call(sucursalApi(token).sucursalesDelete, {
      sucursalesIds: sucursalesDelete,
    });
    yield put(sucursalActions.sucursales());
    yield put(sucursalActions.resetCheckSucursales());
    SuccessToast({ message: "Sucursales eliminadas con éxito!" });
  } catch (error) {
    const errores = error.response.data;
    const message = ObjectToString(errores);
    ErrorToast({ message: message });
    !!process.env.REACT_APP_DEBUG && console.log({ error });
  }
}

function* sucursalesImportarVerificarSaga({ payload }) {
  const token = getToken();
  const fileToUpload = yield select((state) => state.sucursal.fileToUpload);
  try {
    const data = yield call(sucursalApi(token).importarVerificar, {
      fileToUpload,
    });
    yield put(sucursalActions.setDatosVerificados(data));
    yield put(push("/importador/sucursal"));
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
  }
}

function* sucursalesImportarSaga({ payload }) {
  const token = getToken();
  const datos = yield select((state) => state.sucursal.datosImportar);
  try {
    const data = yield call(sucursalApi(token).importar, datos);
    yield put(sucursalActions.importarDatosComplete());
    SuccessToast({ message: "Sucursales importadas con éxito!" });
    yield put(push("/estructura/sucursales"));
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
  }
}

export function* sucursalSagas() {
  yield takeEvery(
    sucursalActions.setFileToUpload.type,
    sucursalesImportarVerificarSaga
  );
  yield takeEvery(
    sucursalActions.setDatosImportar.type,
    sucursalesImportarSaga
  );
  // create
  yield takeEvery(sucursalActions.sucursalCreate.type, sucursalCreateSaga);
  // update
  yield takeEvery(sucursalActions.sucursalUpdate.type, sucursalUpdateSaga);
  // delete
  yield takeEvery(sucursalActions.sucursalDelete.type, sucursalDeleteSaga);
  yield takeEvery(
    sucursalActions.setSucursalesDelete.type,
    sucursalesDeleteSaga
  );
  // list
  yield takeLatest(sucursalActions.sucursales.type, sucursalesSaga);
  yield takeLatest(sucursalActions.setSucursalesFilter.type, sucursalesSaga);
}
