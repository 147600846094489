import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import FileInput from "src/modules/common/components/FileInput";
import SkeletonLoaderRow from "src/modules/common/components/SkeletonLoaderRow";
import TableCustom from "src/modules/common/components/TableCustom";
import CargoModal from "src/modules/estructura/modules/cargo/components/CargoModal";
import { getToken } from "src/utils/helpers";
import { DownloadPlantilla } from "../../estructura/handlers/utils";
import { CargoTableAccionMenu } from "../components/CargoTableAccionMenu";
import { cargoActions } from "../handlers/redux";
//
const CargoListItem = ({ item, idx }) => {
  return (
    <div
      key={idx}
      className="hover:bg-sky-100 flex flex-row gap-2 py-4 align-middle w-full"
    >
      <div className="p-1 flex flex-grow flex-1 flex-col justify-center">
        <div className="font-bold">{item.nombre}</div>
        <div className="text-sm">{item.descripcion}</div>
      </div>
      <div className="p-1 text-center w-48x flex flex-row flex-1 justify-centerx items-center">
        {item.cantidad_colaboradores}
      </div>
      <div className="p-1 w-12 flex flex-row items-center">
        <CargoTableAccionMenu item={item} />
      </div>
    </div>
  );
};

function CargoPage() {
  const dispatch = useDispatch();
  const token = getToken();
  const cargos = useSelector((state) => state.cargo.cargos);
  const cargos_checked = useSelector((state) => state.cargo.cargos_checked);
  const cargos_loading = useSelector((state) => state.cargo.cargos_loading);
  const cargos_filter = useSelector((state) => state.cargo.cargos_filter);
  const { nombre = "" } = cargos_filter;
  const { results = [], count: cargosCount = 0 } = cargos;
  //
  const uploadFile = (file) => {
    dispatch(cargoActions.setFileToUpload(file));
  };

  const onPlantilla = () => {
    DownloadPlantilla({ token });
  };

  const showCargoModal = () => {
    dispatch(cargoActions.cargoSet({}));
    dispatch(cargoActions.setCargoCreateModal({ open: true }));
  };

  const onCheckCargos = (event, item) => {
    dispatch(
      cargoActions.checkCargo({
        cargo: item,
        checked: event.target.checked,
      })
    );
  };

  const onSelectChange = (v) => {
    if (v.value === "cancel") {
      dispatch(cargoActions.resetCheckCargos());
    } else if (v.value === "eliminar") {
      let deseaEliminar = window.confirm(
        "Desea eliminar los cargos seleccionados?"
      );
      if (deseaEliminar) dispatch(cargoActions.setCargosDelete(cargos_checked));
    } else {
      // @ts-ignore
      window.alert(JSON.stringify(v));
    }
  };

  const onChangeSearch = (event) => {
    dispatch(
      cargoActions.setCargosFilter({
        ...cargos_filter,
        nombre: event.target.value,
      })
    );
  };

  const onPaginate = (page, pageSize) => {
    dispatch(
      cargoActions.setCargosFilter({
        ...cargos_filter,
        pagination: {
          page: page,
          pageSize: pageSize,
        },
      })
    );
  };

  useEffect(() => {
    dispatch(cargoActions.cargos());
  }, []);

  return (
    <div className="md:w-11/12">
      <div className="flex flex-col gap-2">
        <div className="text-xl font-bold ">Cargos</div>
        <div className="border-b border-b-gray-200 mb-3"></div>
        {!cargos_loading && (
          <TableCustom
            fixedHeigth={true}
            data={results || []}
            customHeaders={["Colaboradores"]}
            tableTitle={""}
            tableSecondTitle={"Lista cargos creados"}
            subject={"cargo"}
            subjectGender={"M"}
            ItemComponent={CargoListItem}
            addBtnClickHandler={showCargoModal}
            itemsChecked={cargos_checked}
            onCheckHandler={onCheckCargos}
            onSelectChange={onSelectChange}
            selectOptions={[
              { value: "eliminar", label: "Eliminar" },
              { value: "cancel", label: "Cancelar" },
            ]}
            searchValue={nombre}
            onChangeSearch={onChangeSearch}
            noBottomButtom={true}
            //
            totalPaginate={cargosCount}
            onPaginate={onPaginate}
          />
        )}
        {!!cargos_loading && (
          <div className="mt-28">
            {[1, 2, 3].map((r, i) => (
              <SkeletonLoaderRow key={i}></SkeletonLoaderRow>
            ))}
          </div>
        )}
      </div>

      <FileInput
        className={"mt-3 mb-8"}
        onSubmit={uploadFile}
        onPlantilla={onPlantilla}
      />
      <CargoModal></CargoModal>
    </div>
  );
}

export default CargoPage;
