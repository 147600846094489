import { createSlice } from "@reduxjs/toolkit";

const colaboradoreslice = createSlice({
  name: "Colaborador",
  initialState: {
    colaborador_create_loading: false,
    colaborador_update_loading: false,
    colaborador_delete_loading: false,
    colaborador: {},
    colaborador_loading: false,
    colaboradores: { results: [] },
    colaboradores_loading: false,
    jefes: { results: [] },
    jefes_loading: false,
    jefes_filter: {},
    jefes_checked: [],
    colaboradores_libres: { results: [] },
    colaboradores_libres_loading: false,
    colaborador_select_modal: { open: false },
    colaboradores_filter: {},
    colaboradores_checked: [],
    colaboradores_delete: [],
    asignacion_loading: false,
    //
    modalTableConfig: {},
    // acceso
    colaborador_acceso: {},
    colaborador_acceso_loading: false,
    colaborador_acceso_update_loading: false,
    colaborador_acceso_invitacion_loading: false,
    roles: { results: [] },
    roles_loading: false,
    roles_checked: [],
    // laboral
    colaborador_laboral: {},
    colaborador_laboral_loading: false,
    colaborador_laboral_update_loading: false,
    //
    fileToUpload: null,
    datosVerificados: {},
    nuevosChecked: [],
    duplicadosChecked: [],
    datosImportar: [],
    datosImportarGeneral: {},
    datosImportarGeneralFull: {},
    importar_loading: false,
    importar_destino: "", // para saber si el botón de Importar datos debe hacer una importación parcial o general
    errores_importacion: [],
    link_invitacion: null,
    //
    hasChanged: false,
    esModoVista: true,
    //
    tipos_contrataciones: [],
    tipos_contrataciones_loading: false,
  },
  reducers: {
    setEsModoVista: (state, { payload = [] }) => {
      state.esModoVista = payload;
    },
    setHasChanged: (state, { payload = [] }) => {
      state.hasChanged = payload;
    },
    // colaborador
    colaboradorGet: (state, { payload = {} }) => {
      state.colaborador = payload;
      state.colaborador_loading = true;
    },
    colaboradorSet: (state, { payload = {} }) => {
      state.colaborador = payload;
      state.colaborador_loading = false;
    },
    setColaboradoresFilter: (state, { payload = {} }) => {
      state.colaboradores_filter = payload;
    },
    // acciones masivas
    asignarSucursal: (state, { payload = {} }) => {
      state.asignacion_loading = true;
    },
    asignarArea: (state, { payload = {} }) => {
      state.asignacion_loading = true;
    },
    asignarDepartamento: (state, { payload = {} }) => {
      state.asignacion_loading = true;
    },
    asignarCargo: (state, { payload = {} }) => {
      state.asignacion_loading = true;
    },
    asignarJefe: (state, { payload = {} }) => {
      state.asignacion_loading = true;
    },
    asignarRol: (state, { payload = {} }) => {
      state.asignacion_loading = true;
    },
    enviarInvitaciones: (state, { payload = {} }) => {
      state.asignacion_loading = true;
    },
    setColaboradoresDelete: (state, { payload = {} }) => {
      state.colaboradores_delete = payload;
    },
    asignacionComplete: (state, { payload = {} }) => {
      state.asignacion_loading = false;
    },
    //
    resetCheckColaboradores: (state) => {
      state.colaboradores_checked = [];
    },
    checkColaborador: (state, { payload: { colaborador, checked = true } }) => {
      state.colaboradores_checked = state.colaboradores_checked.filter(
        (id) => id !== colaborador.id
      );
      if (!!checked) {
        state.colaboradores_checked.push(colaborador.id);
      }
      // state.kpis_loading = false;
    },
    ////
    setModalTableConfig: (state, { payload = {} }) => {
      state.modalTableConfig = payload;
    },
    // update
    colaboradorUpdate: (state, { payload = {} }) => {
      state.colaborador_update_loading = true;
    },
    colaboradorUpdateSuccess: (state) => {
      state.colaborador_update_loading = false;
      state.esModoVista = true;
      state.hasChanged = false;
    },
    // delete
    colaboradorDelete: (state, { payload = {} }) => {
      state.colaborador_delete_loading = true;
    },
    colaboradorDeleteSuccess: (state) => {
      state.colaborador_delete_loading = false;
    },
    // select
    setColaboradorSelectModal: (state, { payload = {} }) => {
      !!process.env.REACT_APP_DEBUG && console.log({ payload });
      state.colaborador_select_modal = payload;
    },
    colaboradorCreate: (state, { payload = {} }) => {
      state.colaborador_create_loading = true;
    },
    colaboradorCreateSuccess: (state) => {
      state.colaborador_create_loading = false;
    },
    // LABORAL
    // get
    colaboradorLaboralGet: (state, { payload = {} }) => {
      state.colaborador_laboral = {};
      state.colaborador_laboral_loading = true;
    },
    colaboradorLaboralSet: (state, { payload = {} }) => {
      state.colaborador_laboral = payload;
      state.colaborador_laboral_loading = false;
    },
    // update
    colaboradorLaboralUpdate: (state, { payload = {} }) => {
      state.colaborador_laboral_update_loading = true;
    },
    colaboradorLaboralUpdateSuccess: (state) => {
      state.colaborador_laboral_update_loading = false;
      state.esModoVista = true;
      state.hasChanged = false;
    },
    // ACCESO
    // get
    colaboradorAccesoGet: (state, { payload = {} }) => {
      state.colaborador_acceso = {};
      state.colaborador_acceso_loading = true;
    },
    colaboradorAccesoSet: (state, { payload = {} }) => {
      state.colaborador_acceso = payload;
      state.colaborador_acceso_loading = false;
    },
    colaboradorAccesoUpdate: (state, { payload = {} }) => {
      state.colaborador_acceso_update_loading = true;
    },
    colaboradorAccesoUpdateSuccess: (state, { payload = {} }) => {
      state.colaborador_acceso_update_loading = false;
    },
    /**/
    roles: (state, { payload = {} }) => {
      state.roles_loading = false;
    },
    setRoles: (state, { payload = {} }) => {
      state.roles = payload;
      state.roles_loading = false;
    },
    resetCheckRoles: (state) => {
      state.roles_checked = [];
    },
    checkRol: (state, { payload: { rol, checked = true } }) => {
      state.roles_checked = state.roles_checked.filter((id) => id !== rol.id);
      if (!!checked) {
        state.roles_checked.push(rol.id);
      }
      // state.kpis_loading = false;
    },
    // invitacion
    colaboradorAccesoInvitacion: (state, { payload = { password: "" } }) => {
      state.colaborador_acceso_invitacion_loading = true;
    },
    colaboradorAccesoInvitacionSuccess: (state) => {
      state.colaborador_acceso_invitacion_loading = false;
    },
    // COLABORADORES
    // list
    colaboradores: (state, { payload }) => {
      !!process.env.REACT_APP_DEBUG && console.info("colaboradores list");
      state.colaboradores_loading = true;
    },
    setColaboradores: (state, { payload }) => {
      !!process.env.REACT_APP_DEBUG &&
        console.info("setColaboradores", payload);
      state.colaboradores = payload.colaboradores;
      state.colaboradores_loading = false;
    },
    // Jefes
    jefes: (state, { payload }) => {
      state.jefes_loading = true;
    },
    setJefes: (state, { payload }) => {
      state.jefes = payload.jefes;
      state.jefes_loading = false;
    },
    setJefesFilter: (state, { payload = {} }) => {
      state.jefes_filter = payload;
    },
    resetCheckJefes: (state) => {
      state.jefes_checked = [];
    },
    checkJefe: (state, { payload: { colaborador, checked = true } }) => {
      state.jefes_checked = state.jefes_checked.filter(
        (id) => id !== colaborador.id
      );
      if (!!checked) {
        state.jefes_checked.push(colaborador.id);
      }
      // state.kpis_loading = false;
    },
    // list libres
    colaboradoresLibres: (state, { payload }) => {
      !!process.env.REACT_APP_DEBUG && console.info("colaboradores list");
      state.colaboradores_libres_loading = true;
    },
    setColaboradoresLibres: (state, { payload }) => {
      !!process.env.REACT_APP_DEBUG &&
        console.info("setColaboradores", payload);
      state.colaboradores_libres = payload.colaboradores_libres;
      state.colaboradores_libres_loading = false;
    },
    // IMPORTACION
    setFileToUpload: (state, { payload = {} }) => {
      state.fileToUpload = payload;
      state.importar_loading = true;
    },
    setFileToUploadGeneral: (state, { payload = {} }) => {
      state.fileToUpload = payload;
      state.importar_loading = true;
    },
    setFileToUploadGeneralFull: (state, { payload = {} }) => {
      state.fileToUpload = payload;
      state.importar_loading = true;
    },
    setErroresImportacion: (state, { payload = {} }) => {
      state.errores_importacion = payload;
      state.importar_loading = false;
    },
    setDatosVerificados: (state, { payload = {} }) => {
      state.datosVerificados = payload;
      state.importar_loading = false;
    },
    setDatosImportar: (state, { payload = {} }) => {
      state.datosImportar = payload;
    },
    setDatosImportarGeneral: (state, { payload = {} }) => {
      state.datosImportarGeneral = payload;
      state.importar_loading = true;
    },
    setDatosImportarGeneralFull: (state, { payload = {} }) => {
      state.datosImportarGeneralFull = payload;
      state.importar_loading = true;
    },
    setImportarDestino: (state, { payload = {} }) => {
      state.importar_destino = payload;
    },
    checkNuevo: (state, { payload: { item, checked = true } }) => {
      let temp = state.nuevosChecked.filter(
        (colaborador) => colaborador.id !== item.id
      );
      state.nuevosChecked = temp;
      if (!!checked) {
        state.nuevosChecked.push(item);
      }
    },
    checkDuplicado: (state, { payload: { item, checked = true } }) => {
      let temp = state.duplicadosChecked.filter(
        (colab, id) => colab.id !== item.id
      );
      state.duplicadosChecked = temp;
      if (!!checked) {
        state.duplicadosChecked.push(item);
      }
    },
    importarDatosComplete: (state, { payload = {} }) => {
      state.nuevosChecked = [];
      state.duplicadosChecked = [];
      state.datosVerificados = [];
      state.datosImportar = [];
      state.importar_loading = false;
    },
    importarDatosError: (state, { payload = {} }) => {
      state.importar_loading = false;
    },
    resetCheckNuevos: (state) => {
      state.nuevosChecked = [];
    },
    resetCheckDuplicados: (state) => {
      state.duplicadosChecked = [];
    },
    //
    setLinkInvitacion: (state, { payload = "" }) => {
      state.link_invitacion = payload;
    },
    //
    getTiposContrataciones: (state, { payload = [] }) => {
      state.tipos_contrataciones_loading = true;
    },
    setTiposContrataciones: (state, { payload = [] }) => {
      state.tipos_contrataciones = payload;
      state.tipos_contrataciones_loading = false;
    },
  },
});

export const colaboradorActions = colaboradoreslice.actions;
export const colaboradorReducer = colaboradoreslice.reducer;
