import React, { useEffect, useState } from "react";
import { BeneficiosLayout } from "../components/BeneficiosLayout";
import SearchInput from "src/modules/remuneracion/components/SearchInput";
import MostrarPaginas from "src/modules/common/components/MostrarPaginas";
import ReactSelect from "react-select";
import DataTable from "src/modules/common/components/DataTable";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { beneficioActions } from "../handlers/redux";
import { ErrorToast } from "src/modules/common/components/ErrorToast";

export function BeneficiosColaboradoresPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const colaboradores_list = useSelector(
    (state) => state.beneficio.colaboradores_list
  );
  const colaboradores_list_count = useSelector(
    (state) => state.beneficio.colaboradores_list_count
  );
  const colaboradores_list_filter = useSelector(
    (state) => state.beneficio.colaboradores_list_filter
  );
  const colaboradores_list_checked = useSelector(
    (state) => state.beneficio.colaboradores_list_checked
  );
  const colaboradores_list_loading = useSelector(
    (state) => state.beneficio.colaboradores_list_loading
  );

  const { nombre } = colaboradores_list_filter;

  const [outerPageSize, setOuterPageSize] = useState(50);

  useEffect(() => {
    dispatch(beneficioActions.getColaboradoresList());
  }, []);

  const onPaginate = (page, pageSize) => {
    //
    dispatch(
      beneficioActions.setColaboradoresListFilter({
        ...colaboradores_list_filter,
        pagination: {
          page: page,
          pageSize: pageSize,
        },
      })
    );
  };

  const onCheckAll = () => {
    let checkedData = JSON.parse(JSON.stringify(colaboradores_list));
    if (colaboradores_list_checked.length > 0) {
      checkedData = [];
    }
    dispatch(beneficioActions.setCheckColaboradoresList(checkedData));
  };

  const onCheckColaborador = (item) => (event) => {
    dispatch(
      beneficioActions.checkColaboradoresList({
        colaborador_aux: item,
        checked: event.target.checked,
      })
    );
  };

  const onSearchColaboradores = (e) => {
    let searchText = e.target.value;
    dispatch(
      beneficioActions.setColaboradoresListFilter({
        ...colaboradores_list_filter,
        nombre: searchText,
      })
    );
  };

  const onChangePageSize = (e) => {
    let value = e.target.value;
    dispatch(
      beneficioActions.setColaboradoresListFilter({
        ...colaboradores_list_filter,
        pagination: {
          pageSize: parseInt(value),
          page: 1,
        },
      })
    );
    setOuterPageSize(parseInt(value));
  };

  const eliminarBeneficiosColaboradores = (colaboradoresIds) => {
    console.log(colaboradoresIds);
    const request = { colaboradoresIds };
    dispatch(beneficioActions.excluirBeneficiosTotalColaboradores(request));
  };

  const actionOptions = [
    {
      value: "delete",
      label: "Eliminar",
      // action: () => {},
    },
  ];

  const onAction = (v) => {
    if (v.value === "delete") {
      if (colaboradores_list_checked.length < 1) {
        ErrorToast({ message: "Debe seleccionar al menos un colaborador." });
        return;
      }
      const deseaEliminar = window.confirm(
        "¿Está seguro de eliminar los beneficios de los colaboradores seleccionados?"
      );
      if (!!deseaEliminar) {
        const colaboradoresIds = colaboradores_list_checked.map(
          (cargo) => cargo.id
        );
        eliminarBeneficiosColaboradores(colaboradoresIds);
      }
      return;
    }
  };

  const goToDetail = (item) => {
    console.log("Ver detalles", item);
    navigate("/beneficios/colaboradores/" + item.id);
  };
  const getEstadoColor = (cantidad) => {
    return cantidad === 0 ? "bg-red-500 text-white" : "";
  };
  const datatableConfig = {
    headers: [
      {
        title: "Nómina",
        contentClass: "font-bold px-2 py-4",
        onOrder: false,
        // accessor: "nombre",
        contentRenderer: (item) => (
          <div className="flex flex-col">
            <span className="font-semibold">
              {item.nombre} {item.apellido}
            </span>
          </div>
        ),
      },
      {
        title: "Excluido",
        contentClass: "font-bold px-2 py-4",
        onOrder: false,
        // accessor: "nombre",
        contentRenderer: (item) => (
          <div className="flex flex-col">
            <span className="font-bold">
              {item.beneficio_habilitado ? "NO" : "SI"}
            </span>
          </div>
        ),
      },
      {
        title: "# Beneficios",
        contentClass: "font-bold px-2 py-4",
        onOrder: false,
        // accessor: "nombre",
        contentRenderer: (item) => (
          <div className="flex items-center">
            <span
              className={`p-2 rounded-md text-sm ${getEstadoColor(
                item.cantidad_beneficios
              )}`}
            >
              {item.cantidad_beneficios === 0
                ? "Sin beneficios"
                : item.cantidad_beneficios}
            </span>
          </div>
        ),
      },
      {
        title: "Configuración",
        contentClass: "font-bold px-2 py-4",
        // accessor: "nombre",
        contentRenderer: (item) => (
          <button
            className="flex items-center gap-2 border rounded-md py-2 px-3 border-gray-500 text-sm"
            onClick={() => goToDetail(item)}
          >
            <span className="text-sm">Ver detalles</span>
          </button>
        ),
      },
    ],
    rowActions: [
      // {
      //   label: "Editar",
      //   action: (item) => {
      //     console.log(item);
      //   },
      // },
      {
        label: "Eliminar",
        action: (item) => {
          const deseaEliminar = window.confirm(
            "¿Está seguro de eliminar los beneficios del colaborador seleccionado?"
          );
          if (!!deseaEliminar) {
            const colaboradorId = [item.id];
            eliminarBeneficiosColaboradores(colaboradorId);
          }
        },
      },
    ],
  };
  const goToConfiguracion = () => {
    console.log("Ir a configuración");
  };
  return (
    <BeneficiosLayout>
      <div className="border-b pb-2 mt-4 flex justify-between items-center mb-4">
        <h2 className="font-semibold text-xl">Colaboradores</h2>
      </div>
      <div className="px-4 py-2 w-full md:w-11/12">
        <div className="flex items-center gap-2 mt-4">
          <SearchInput
            containerClass={`w-1/2`}
            placeHolder={"Buscar por nombre..."}
            value={nombre}
            onChange={onSearchColaboradores}
          />
          <MostrarPaginas
            containerClass={"w-1/2x"}
            defaultOption={50}
            onChangePageSize={onChangePageSize}
          />
          <div className="text-sm">
            <ReactSelect
              className="w-52 h-10 bg-blue-200 inline-grid"
              value={{
                value: "",
                label: `(${colaboradores_list_checked.length}) seleccionados`,
              }}
              options={actionOptions}
              onChange={onAction}
            ></ReactSelect>
          </div>
        </div>
        <div></div>
      </div>
      <DataTable
        containerClass={"my-2"}
        config={datatableConfig}
        data={colaboradores_list}
        loading={colaboradores_list_loading}
        noHeaders={false}
        //
        totalRecords={colaboradores_list_count}
        onPaginate={onPaginate}
        outerPageSize={outerPageSize}
        //
        checkable={true}
        onCheckAll={onCheckAll}
        checkedItems={colaboradores_list_checked}
        onItemCheck={onCheckColaborador}
      />
    </BeneficiosLayout>
  );
}
