import axios from "axios";

/**
 * Convierte un número de columna de Excel en las letras de columna correspondientes.
 * @param {number} numeroColumna - El número de columna de Excel (1 o superior).
 * @returns {string} - Las letras de columna correspondientes.
 */
export const obtenerLetrasColumna = (numeroColumna) => {
  let letras = "";
  while (numeroColumna > 0) {
    const modulo = (numeroColumna - 1) % 26; // Calcula el módulo 26
    letras = String.fromCharCode(65 + modulo) + letras; // Convierte a letra y agrega al inicio
    numeroColumna = Math.floor((numeroColumna - 1) / 26); // Divide y redondea hacia abajo
  }
  return letras;
};

export const getEstadoColor = (estado) => {
  const colorClasses = {
    Vigente: "bg-green-500 text-white",
    Borrador: "bg-yellow-500 text-white",
    "No vigente": "bg-slate-100 text-slate-600",
  };
  return colorClasses[estado] ?? "";
};

const ESTADOS_COLABORADOR = ["Por encima", "Dentro", "Por debajo"];

export const getEstadoColaboradorColor = (estado) => {
  const colorClasses = {
    [ESTADOS_COLABORADOR[0]]: "text-green-500",
    [ESTADOS_COLABORADOR[1]]: "text-sky-500",
    [ESTADOS_COLABORADOR[2]]: "text-red-500",
  };
  return colorClasses[estado] ?? "";
};

export function generarArrayConSalto(inicio, final, salto) {
  const resultado = [];
  for (let i = inicio; i <= final; i += salto) {
    resultado.push(i);
  }
  return resultado;
}

export function DescargarValoracionesCargos(escalaId, token = "", onComplete = null) {
  console.log("Descargar reporte de valoraciones");
  const URL_BASE =
    process.env.REACT_APP_API_URL || "http://localhost:8000/api/admin";
  const requestURL = URL_BASE + `/remuneracion/${escalaId}/valoracion-cargos/descargar-reporte`;
  const headers = {
    "Content-Type": "blob",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };
  axios({
    url: requestURL,
    method: "GET",
    headers: headers,
    responseType: "blob",
  })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `lista-cargos-valoraciones.xlsx`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
      //
      if (!!onComplete) {
        onComplete();
      }
    })
    .catch((e) => {
      console.info("Error al descargar el reporte de valoraciones de cargos", e.message);
      if (!!onComplete) {
        onComplete();
      }
    });
}

// workaround provisorio para finetech para admins que no tiene que ver salarios
// es el colaborador_id de la cookie
export const USUARIOS_IDS_BLOQUEADOS_REMUNERACION = [5015];
