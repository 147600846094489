import React from "react";
import { VictoryPie } from "victory";

function Pie({
  data = [],
  amount = 0,
  width = 450,
  height = 450,
  innerRadius = 20,
  colorScale = ["orange", "blue"],
  labels = [],
}) {
  return (
    <svg viewBox={`0 0 ${width} ${height}`}>
      <VictoryPie
        standalone={false}
        width={width}
        height={height}
        data={data}
        colorScale={colorScale}
        innerRadius={innerRadius}
        labelRadius={0}
        labels={labels}
      />
    </svg>
  );
}

export default Pie;
