import { createSlice } from "@reduxjs/toolkit";

const competenciaCargoSlice = createSlice({
  name: "CompetenciaCargo",
  initialState: {
    competencia_cargo_create_loading: false,
    competencia_cargo_update_loading: false,
    competencia_cargo_delete_loading: false,
    competencia_cargo: {},
    competencia_cargo_loading: false,
    competencia_cargos: { result: [] },
    competencia_cargos_filter: {},
    competencia_cargos_checked: [],
    competencia_cargos_delete: [],
    competencia_cargos_loading: false,
    competencia_cargo_create_modal: { open: false },
    aplicar_pesos: false,
    competencia_cargo_editing: true,
    //
    libreria_competencias: [],
    libreria_competencias_filter: {},
    libreria_competencias_loading: false,
  },
  reducers: {
    getCompetenciasBase: (state, { payload = {} }) => {
      state.libreria_competencias_loading = true;
    },
    setCompetenciasBase: (state, { payload = {} }) => {
      state.libreria_competencias = payload;
    },
    getCompetenciasBaseComplete: (state, { payload = {} }) => {
      state.libreria_competencias_loading = false;
    },
    //
    getCompetenciaCargo: (state, { payload = {} }) => {
      state.competencia_cargo_loading = true;
    },
    competenciaCargoSet: (state, { payload = {} }) => {
      state.competencia_cargo = payload;
      state.competencia_cargo_loading = false;
    },
    setCompetenciaCargos: (state, { payload }) => {
      state.competencia_cargos = payload.competencia_cargos;
      state.competencia_cargos_loading = false;
    },
    setCompetenciaCargoEditing: (state, { payload = true }) => {
      state.competencia_cargo_editing = payload;
    },
    setCompetenciaCargosFilter: (state, { payload = {} }) => {
      state.competencia_cargos_filter = payload;
    },
    setCompetenciaCargosDelete: (state, { payload = {} }) => {
      state.competencia_cargos_delete = payload;
    },
    setAplicarPesos: (state, { payload }) => {
      state.aplicar_pesos = payload;
    },
    //
    checkCompetenciaCargo: (
      state,
      { payload: { competenciaCargo, checked = true } }
    ) => {
      state.competencia_cargos_checked =
        state.competencia_cargos_checked.filter(
          (id) => id !== competenciaCargo.id
        );
      if (!!checked) {
        state.competencia_cargos_checked.push(competenciaCargo.id);
      }
      state.competencia_cargos_loading = false;
    },
    resetCheckCompetenciaCargo: (state) => {
      state.competencia_cargos_checked = [];
      state.competencia_cargos_loading = false;
    },
    // create
    setCompetenciaCargoCreateModal: (state, { payload = {} }) => {
      !!process.env.REACT_APP_DEBUG && console.log({ payload });
      state.competencia_cargo_create_modal = payload;
    },
    competenciaCargoCreate: (state, { payload = { conductas: [] } }) => {
      state.competencia_cargo_create_loading = true;
    },
    competenciaCargoCreateSuccess: (state) => {
      state.competencia_cargo_create_loading = false;
    },
    // update
    competenciaCargoUpdate: (state, { payload = { conductas: [] } }) => {
      state.competencia_cargo_update_loading = true;
    },
    competenciaCargoUpdateSuccess: (state) => {
      state.competencia_cargo_update_loading = false;
    },
    // delete
    competenciaCargoDelete: (state, { payload = {} }) => {
      state.competencia_cargo_delete_loading = true;
    },
    competenciaCargoDeleteSuccess: (state) => {
      state.competencia_cargo_delete_loading = false;
    },
    // list
    competenciaCargos: (state, { payload }) => {
      state.competencia_cargos_checked = [];
      state.competencia_cargos_loading = true;
    },
  },
});

export const competenciaCargoActions = competenciaCargoSlice.actions;
export const competenciaCargoReducer = competenciaCargoSlice.reducer;
