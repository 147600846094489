import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ProfileInfoCard } from "src/modules/common/components/ProfileInfoCard";
import TableCustom from "src/modules/common/components/TableCustom";
import TableRow from "src/modules/common/components/TableRow";
import { evaluacionActions } from "../handlers/redux";
import AreaColaboradorSelect from "./AreaColaboradorSelect";
import DepartamentoColaboradorSelect from "./DepartamentoColaboradorSelect";
import SucursalColaboradorSelect from "./SucursalColaboradorSelect";

function CalibradoresList() {
  const dispatch = useDispatch();
  const colaboradores_validos = useSelector(
    (state) => state.evaluacion.colaboradores_validos
  );
  const colaboradores_validos_count = useSelector(
    (state) => state.evaluacion.colaboradores_validos_count
  );
  const colaboradores_validos_filter = useSelector(
    (state) => state.evaluacion.colaboradores_validos_filter
  );
  const colaboradores_validos_loading = useSelector(
    (state) => state.evaluacion.colaboradores_validos_loading
  );
  const colaboradores_validos_checked = useSelector(
    (state) => state.evaluacion.colaboradores_validos_checked
  );
  const { nombre: nombre_cargo } = colaboradores_validos_filter;
  useEffect(() => {
    // dispatch(evaluacionActions.getColaboradoresValidos());
    dispatch(
      evaluacionActions.setColaboradoresValidosFilter({
        ...colaboradores_validos_filter,
        pagination: {
          page: 1,
          pageSize: 100,
        },
      })
    );
  }, []);

  const ItemCommonComponent = ({ item }) => {
    return (
      <TableRow>
        <div
          className="p-4 flex gap-8 items-start justify-start cursor-pointer"
          onClick={() => {}}
        >
          <ProfileInfoCard
            name={`${item.nombre} ${item.apellido}`}
            description={
              item.cargo?.nombre
                ? `Cargo: ${item.cargo?.nombre}`
                : "Cargo: Sin espeficiar"
            }
            image={item.foto_empleado ? item.foto_empleado : ""}
          ></ProfileInfoCard>
        </div>
      </TableRow>
    );
  };
  const onCheckHandler = (event, item) => {
    dispatch(evaluacionActions.resetCheckColaboradoresValidos());
    dispatch(
      evaluacionActions.checkColaboradorValido({
        colaborador: item.id,
        checked: event.target.checked,
      })
    );
  };

  const onChangeSearch = (e) => {
    !!process.env.REACT_APP_DEBUG && console.info("change s", e.target.value);
    dispatch(
      evaluacionActions.setColaboradoresValidosFilter({
        ...colaboradores_validos_filter,
        nombre: e.target.value,
      })
    );
  };

  const onSelectChange = (v, items = []) => {
    if (v.value === "cancel") {
      dispatch(evaluacionActions.resetCheckColaboradoresValidos());
    } else {
      // @ts-ignore
      window.alert(JSON.stringify(v));
    }
  };

  const agregarColaboradores = () => {
    // setColaboradoresAgregar
    dispatch(evaluacionActions.agregarCalibrador());
  };

  const commonProps = {
    ItemComponent: ItemCommonComponent,
    subject: "colaborador",
    subjectGender: "M",
    hasAddBtn: true,
    customAddBtnLabel: "Agregar calibrador",
    noBottomButtom: true,
    selectOptions: [],
  };

  const renderFilters = () => {
    return (
      <div className="flex gap-4 mb-5">
        <AreaColaboradorSelect></AreaColaboradorSelect>
        <DepartamentoColaboradorSelect></DepartamentoColaboradorSelect>
        <SucursalColaboradorSelect></SucursalColaboradorSelect>
      </div>
    );
  };

  const onPaginate = (page, pageSize) => {
    dispatch(
      evaluacionActions.setColaboradoresValidosFilter({
        ...colaboradores_validos_filter,
        pagination: {
          page: page,
          pageSize: pageSize,
        },
      })
    );
  };
  //
  return (
    <div>
      <TableCustom
        {...commonProps}
        tableSecondTitle={"Lista de integrantes"}
        addBtnClickHandler={agregarColaboradores}
        onCheckHandler={onCheckHandler}
        data={colaboradores_validos}
        searchValue={nombre_cargo}
        onChangeSearch={onChangeSearch}
        itemsChecked={colaboradores_validos_checked}
        fixedHeigth={true}
        onSelectChange={onSelectChange}
        selectOptions={[{ value: "cancel", label: "Cancelar" }]}
        renderExtraFilters={renderFilters}
        loading={colaboradores_validos_loading}
        checkType={"radio"}
        multiple={false}
        onPaginate={onPaginate}
        totalPaginate={colaboradores_validos_count}
      />
    </div>
  );
}

export default CalibradoresList;
