import React from "react";
import { useSelector } from "react-redux";
import ReactSelect from "react-select";

export const DepartamentoSelect = ({
  value,
  defaultLabel = "Todos los departamentos",
  ...props
}) => {
  let departamentos = useSelector(
    (state) => state.departamento.departamentos || {}
  );
  const { results = [] } = departamentos;
  departamentos = results.map((s) => ({
    value: s.id,
    label: s.nombre,
  }));
  departamentos.unshift({ value: null, label: defaultLabel });

  const selected = departamentos.find((v) => v.value == value);

  return (
    <ReactSelect
      {...props}
      onChange={(item) => props.onChange && props.onChange({ target: item })}
      value={selected}
      options={departamentos}
    />
  );
};
