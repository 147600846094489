import React from "react";
import { useDispatch } from "react-redux";
import TableCustom from "src/modules/common/components/TableCustom";
import { commonActions } from "src/modules/common/handler/redux";
import CompetenciaAreaModal from "src/modules/competencia/modules/area/components/CompetenciaAreaModal";
import { CompetenciaAreaTable } from "src/modules/competencia/modules/area/components/CompetenciaAreaTable";
import { CompetenciaAreaToolbar } from "src/modules/competencia/modules/area/components/CompetenciaAreaToolbar";
import { competenciaAreaActions } from "src/modules/competencia/modules/area/handlers/redux";
import { areaActions } from "src/modules/estructura/modules/area/handlers/redux";
import { evaluacionActions } from "src/modules/evaluacion/handlers/redux";
import CompetenciasLayout from "../../common/components/CompetenciasLayout";

function CompetenciaAreaPage() {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(areaActions.areas());
    dispatch(competenciaAreaActions.competenciaAreas());
    dispatch(commonActions.showSidebar(true));
    dispatch(
      commonActions.setSecondBar({
        hasSecondBar: false,
        secondBarTitle: "",
        secondBarBackRoute: "",
      })
    );
  }, []);

  return (
    <CompetenciasLayout>
      <div className="flex flex-col items-center mt-4">
        <div className="flex flex-col w-full md:w-5/6 gap-3">
          <div className="text-lg font-bold ">Competencias por áreas</div>
          <div className="border-b border-b-gray-200 mb-3"></div>
          {/* Toolbar */}
          <CompetenciaAreaToolbar />
          {/* Table */}
          <CompetenciaAreaTable></CompetenciaAreaTable>
        </div>
        <CompetenciaAreaModal></CompetenciaAreaModal>
      </div>
    </CompetenciasLayout>
  );
}

export default CompetenciaAreaPage;
