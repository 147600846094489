import { ExclamationCircleFill } from "react-bootstrap-icons";
import React from 'react'

function CustomAlert({
  message = "Lorem, ipsum dolor sit amet consectetur adipisicing elit.",
  visible = true,
  onAceptarHandler,
  onCancelarHandler,
}) {
  const onAceptarClick = () => {
    if (onAceptarHandler) onAceptarHandler();
  };
  const onCancelarClick = () => {
    if (onCancelarHandler) onCancelarHandler();
  };
  const containerClass =
    "border-2 border-slate-400 rounded-md h-1/3 w-1/3 flex flex-col gap-4 items-center justify-center z-50 bg-white absolute top-2/4 left-2/4 -translate-x-1/2 -translate-y-1/2";
  return (
    <>
      {!!visible && (
        <div className={containerClass}>
          <div className="">
            <ExclamationCircleFill
              size={42}
              color={"#F49C17"}
            ></ExclamationCircleFill>
          </div>
          <div className="flex justify-center text-center px-8">{message}</div>
          <div className="flex gap-6 pb-8">
            <button
              className="rounded-md bg-sky-500 text-white py-2 px-3"
              onClick={onAceptarClick}
            >
              Aceptar
            </button>
            <button
              className="rounded-md border border-black p-2"
              onClick={onCancelarClick}
            >
              Cancelar
            </button>
          </div>
        </div>
      )}
    </>
  );
}
export default CustomAlert;
