import React, { useRef } from "react";
import { CaretDownFill, ChevronBarDown, Folder2 } from "react-bootstrap-icons";
import { WarningToast } from "./WarningToast";

function FileInput({
  label = "Importar datos",
  className,
  onSubmit = null,
  onPlantilla = null,
  showButton = true,
}) {
  const [showInput, setShowInput] = React.useState(!showButton);
  const [name, setName] = React.useState("");
  const [selectedFile, setSelectedFile] = React.useState(null);
  const fileInput = useRef(null);
  const scrollRef = useRef(null);

  const scrollToBottom = () => {
    scrollRef.current?.scrollIntoView({ behavior: "instant" });
  };

  const toggleShow = () => {
    setShowInput(!showInput);
    scrollToBottom();
  };

  const onChangeCaptureHandler = (e) => {
    let file = e.target.files[0];
    setSelectedFile(file);
    setName(file.name);
  };

  const uploadFile = () => {
    if(selectedFile){
      console.log("Importacion de datos - Estrucuta")
      onSubmit(selectedFile);
    } else {
      console.log("No se selecciono ningun archivo")
      WarningToast({ message: "Es necesario agregar un archivo para Importar Datos" });
    }    
  };

  return (
    <div className={className}>
      {!!showButton && (
        <div className="flex flex-row gap-2">
          <button
            onClick={toggleShow}
            className="mr-4 flex items-center justify-between border border-black rounded px-4 py-2 bg-white"
          >
            <ChevronBarDown size={12} />
            <span className="mx-2 text-sm">{label}</span>
            <CaretDownFill size={9} />
          </button>
        </div>
      )}
      {!!showInput && (
        <div className=" flex flex-col mt-4">
          <label className="font-bold" htmlFor="file">
            Archivo<span className="text-red-600">*</span>
          </label>
          <div className="flex gap-2 mr-2">
            <input
              className="border-2 rounded-md p-2 flex-1"
              type="text"
              name="filemask"
              id="filemask"
              value={name}
              disabled
            />
            <button
              onClick={(e) => fileInput.current && fileInput.current.click()}
              className="px-2 text-xs flex gap-2 items-center border border-gray-500 rounded-md"
            >
              <Folder2 size={18}></Folder2>
              Seleccionar archivo
            </button>
          </div>

          <input
            className="hidden"
            type="file"
            name="file"
            ref={fileInput}
            onChangeCapture={onChangeCaptureHandler}
          />

          <div className=" flex gap-2 my-4">
            {!!onPlantilla && (
              <button
                onClick={onPlantilla}
                className="border rounded-md py-1 px-3 border-gray-500 text-sm"
              >
                Descargar plantilla Excel
              </button>
            )}
            {/* SUBMIT */}
            <button
              onClick={uploadFile}
              className="border-1 rounded-md py-1 px-3 bg-black text-white text-sm"
            >
              Importar datos
            </button>
          </div>

          <div ref={scrollRef}></div>
        </div>
      )}
    </div>
  );
}

export default FileInput;
