import React, { useEffect, useState } from "react";
import {
  ChevronDown,
  FiletypeXls,
  ThreeDotsVertical,
} from "react-bootstrap-icons";
import Dropdown from "react-multilevel-dropdown";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { BuscarInput } from "src/modules/common/components/BuscarInput";
import { CheckInput } from "src/modules/common/components/CheckInput";
import { commonActions } from "src/modules/common/handler/redux";
import { areaActions } from "src/modules/estructura/modules/area/handlers/redux";
import { cargoActions } from "src/modules/estructura/modules/cargo/handlers/redux";
import { departamentoActions } from "src/modules/estructura/modules/departamento/handlers/redux";
import { sucursalActions } from "src/modules/estructura/modules/sucursal/handlers/redux";
import { ProfileInfoCard } from "../../common/components/ProfileInfoCard";
import { colaboradorActions } from "../modules/colaborador/handlers/redux";
import NominaTableHeader from "./NominaTableHeader";
import { getToken } from "src/utils/helpers";
import { DescargarColaboradoresInforme } from "../helpers/helpers";

function NominaFixTable({
  data = [],
  headers = [],
  actionButtonHandler = null,
  extraClass = "",
  dropdownActions,
  onPaginate,
  totalPaginate = 0,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [checkAll, setCheckAll] = useState(false);
  const [activeRowNumberMenu, setActiveRowNumberMenu] = useState(null);
  const [Page, setPage] = useState(1);
  const [PageSize, setPageSize] = useState(50);
  const [PagesButtonsArray, setPagesButtonsArray] = useState([1]);
  //
  const colaboradores_filter = useSelector(
    (state) => state.colaborador.colaboradores_filter
  );
  const { nombre = "" } = colaboradores_filter;

  const activeRowNumberMenuHandler = (rowNumber) => {
    activeRowNumberMenu === rowNumber
      ? setActiveRowNumberMenu(null)
      : setActiveRowNumberMenu(rowNumber);
  };
  // ========== //
  const asignarSucursal = (quitar = false) => {
    if (!!quitar) {
      dispatch(colaboradorActions.asignarSucursal({ quitar: true }));
      return;
    }
    dispatch(commonActions.setSelectModal({ open: true }));
    dispatch(
      colaboradorActions.setModalTableConfig({
        tableSecondTitle: "Sucursales",
        addBtnClickHandler: () =>
          dispatch(colaboradorActions.asignarSucursal()),
        checkedState: "sucursales",
        onCheckHandler: (event, item) => {
          dispatch(sucursalActions.resetCheckSucursales());
          dispatch(
            sucursalActions.checkSucursal({
              sucursal: item,
              checked: event.target.checked,
            })
          );
        },
      })
    );
  };
  const asignarArea = (quitar = false) => {
    if (!!quitar) {
      dispatch(colaboradorActions.asignarArea({ quitar: true }));
      return;
    }
    dispatch(commonActions.setSelectModal({ open: true }));
    dispatch(
      colaboradorActions.setModalTableConfig({
        tableSecondTitle: "Áreas",
        addBtnClickHandler: () => dispatch(colaboradorActions.asignarArea()),
        checkedState: "areas",
        onCheckHandler: (event, item) => {
          dispatch(areaActions.resetCheckAreas());
          dispatch(
            areaActions.checkArea({
              area: item,
              checked: event.target.checked,
            })
          );
        },
      })
    );
  };
  const asignarDepartamento = (quitar = false) => {
    if (!!quitar) {
      dispatch(colaboradorActions.asignarDepartamento({ quitar: true }));
      return;
    }
    dispatch(commonActions.setSelectModal({ open: true }));
    dispatch(
      colaboradorActions.setModalTableConfig({
        tableSecondTitle: "Departamentos",
        addBtnClickHandler: () =>
          dispatch(colaboradorActions.asignarDepartamento()),
        checkedState: "departamentos",
        onCheckHandler: (event, item) => {
          dispatch(departamentoActions.resetCheckDepartamentos());
          dispatch(
            departamentoActions.checkDepartamento({
              departamento: item,
              checked: event.target.checked,
            })
          );
        },
      })
    );
  };
  const asignarCargo = (quitar = false) => {
    if (!!quitar) {
      dispatch(colaboradorActions.asignarCargo({ quitar: true }));
      return;
    }
    dispatch(commonActions.setSelectModal({ open: true }));
    dispatch(
      colaboradorActions.setModalTableConfig({
        tableSecondTitle: "Cargos",
        addBtnClickHandler: () => dispatch(colaboradorActions.asignarCargo()),
        checkedState: "cargos",
        onCheckHandler: (event, item) => {
          dispatch(cargoActions.resetCheckCargos());
          dispatch(
            cargoActions.checkCargo({
              cargo: item,
              checked: event.target.checked,
            })
          );
        },
      })
    );
  };
  const asignarRol = (quitar = false) => {
    if (!!quitar) {
      dispatch(colaboradorActions.asignarRol({ quitar: true }));
      return;
    }
    dispatch(commonActions.setSelectModal({ open: true }));
    dispatch(
      colaboradorActions.setModalTableConfig({
        tableSecondTitle: "Roles",
        addBtnClickHandler: () => dispatch(colaboradorActions.asignarRol()),
        checkedState: "roles",
        onCheckHandler: (event, item) => {
          dispatch(colaboradorActions.resetCheckRoles());
          dispatch(
            colaboradorActions.checkRol({
              rol: item,
              checked: event.target.checked,
            })
          );
        },
      })
    );
  };
  const asignarJefe = (quitar = false) => {
    if (!!quitar) {
      dispatch(colaboradorActions.asignarJefe({ quitar: true }));
      return;
    }
    dispatch(commonActions.setSelectModal({ open: true }));
    dispatch(
      colaboradorActions.setModalTableConfig({
        tableSecondTitle: "Jefes directos",
        addBtnClickHandler: () => dispatch(colaboradorActions.asignarJefe()),
        checkedState: "jefes",
        onCheckHandler: (event, item) => {
          dispatch(colaboradorActions.resetCheckJefes());
          dispatch(
            colaboradorActions.checkJefe({
              colaborador: item,
              checked: event.target.checked,
            })
          );
        },
      })
    );
  };
  // ========== //
  const onAction = (v) => {
    switch (v.value) {
      case "exportar-seleccionados":
        if (colaboradores_checked.length > 1)
          onDescargarInformeSeleccionados(colaboradores_checked);
        break;
      case "asignar-sucursal":
        asignarSucursal();
        break;
      case "quitar-sucursal":
        asignarSucursal(true);
        break;
      case "asignar-area":
        asignarArea();
        break;
      case "quitar-area":
        asignarArea(true);
        break;
      case "asignar-departamento":
        asignarDepartamento();
        break;
      case "quitar-departamento":
        asignarDepartamento(true);
        break;
      case "asignar-cargo":
        asignarCargo();
        break;
      case "quitar-cargo":
        asignarCargo(true);
        break;
      case "asignar-rol":
        asignarRol();
        break;
      case "quitar-rol":
        asignarRol(true);
        break;
      case "asignar-jefe":
        asignarJefe();
        break;
      case "quitar-jefe":
        asignarJefe(true);
        break;
      case "enviar":
        let deseaEnviar = window.confirm(
          "Desea enviar la invitación a los Colaboradores seleccionados?"
        );
        if (deseaEnviar) {
          dispatch(
            colaboradorActions.enviarInvitaciones({ forzarBienvenida: true })
          );
        }
        break;
      case "eliminar":
        let deseaEliminar = window.confirm(
          "Desea eliminar los Colaboradores seleccionados?"
        );
        if (deseaEliminar) {
          dispatch(
            colaboradorActions.setColaboradoresDelete(colaboradores_checked)
          );
        }
        break;
      case "cancel":
        dispatch(colaboradorActions.resetCheckColaboradores());
        break;
      default:
        window.alert(JSON.stringify(v));
        break;
    }
  };
  const hideDropDownMenuHandler = (rowNumber) => (e) => {
    e.stopPropagation();
    activeRowNumberMenuHandler(rowNumber);
  };
  const onTableClick = (e) => {
    !!process.env.REACT_APP_DEBUG && console.info("onTableClick");
    e.stopPropagation();
    activeRowNumberMenuHandler(null);
  };
  const colaboradores_checked = useSelector(
    (state) => state.colaborador.colaboradores_checked
  );
  const enviarInvitacionIndividual = (colaborador) => {
    let deseaEnviar = window.confirm(
      "Desea enviar la invitación para el Colaborador seleccionado?"
    );
    if (deseaEnviar) {
      dispatch(colaboradorActions.colaboradorSet(colaborador));
      dispatch(colaboradorActions.colaboradorAccesoSet(colaborador));
      dispatch(
        colaboradorActions.colaboradorAccesoInvitacion({
          forzarBienvenida: true,
        })
      );
    }
  };
  const goToDetail = (colaborador) => {
    !!process.env.REACT_APP_DEBUG && console.info("GO TO DETAIL ====");
    dispatch(colaboradorActions.colaboradorSet(colaborador));
    navigate(`/nomina/colaborador/${colaborador.id}`);
  };
  const ColaboradorRowItem = ({ item, className = "" }) => {
    return (
      <div
        className={
          "table-row hover:bg-sky-100 hover:cursor-pointer " + className
        }
        onClick={() => goToDetail(item)}
      >
        {/* FIXED COL: Colaborador */}
        <div className="table-cell hover:bg-sky-100 hover:cursor-pointer align-middle w-80 bg-white sticky left-0 border-red-400x p-4">
          <div className="flex items-center gap-2">
            <CheckInput
              checked={colaboradores_checked.includes(item.id)}
              onClick={(event) => event.stopPropagation()}
              onChange={(event) => {
                dispatch(
                  colaboradorActions.checkColaborador({
                    colaborador: item,
                    checked: event.target.checked,
                  })
                );
              }}
            />
            <div className="flex items-center w-56 relative">
              <ProfileInfoCard
                key={"col-" + item.id}
                image={item.foto_empleado}
                name={
                  item.id
                    ? `${item.nombre} ${item.apellido}`
                    : "Sin especificar"
                }
              >
                <div
                  title={item.cargo?.nombre || "Sin asignar"}
                  className="text-sm w-36 whitespace-nowrap text-ellipsis overflow-hidden"
                >
                  {item.cargo?.nombre || "Sin asignar"}
                </div>
              </ProfileInfoCard>
              <div className="w-6 flex justify-center">
                <button onClick={hideDropDownMenuHandler(item.id)}>
                  <ThreeDotsVertical size={18} />
                </button>
              </div>
            </div>
          </div>
          {/* dropdown menu */}
          {!isNaN(item.id) && item.id === activeRowNumberMenu && (
            <div className="ddmenu flex content-center absolute top-10 right-[-209px] border border-slate-300 rounded-md bg-white z-20">
              <ul className="flex flex-col content-center items-start text-left m-0 gap-2 text-md font-semibold">
                {!!dropdownActions &&
                  dropdownActions(item.id, item).map((item, idx) => (
                    <li
                      key={`li-${idx}`}
                      className={`${
                        item.className || ""
                      } hover:bg-blue-200 px-4 py-2 w-full`}
                    >
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          item.action();
                        }}
                      >
                        {item.label}
                      </button>
                    </li>
                  ))}
              </ul>
            </div>
          )}
        </div>
        {/* JEFE */}
        <div className="table-cell align-middle p-4 relativex">
          <div className="w-48 flex items-center relativex">
            {!!item.jefe_directo && (
              <ProfileInfoCard
                key={"jefe-" + item.jefe_directo.id}
                image={item.jefe_directo.foto_empleado}
                name={
                  item.jefe_directo?.id
                    ? `${item.jefe_directo.nombre} ${item.jefe_directo?.apellido}`
                    : "Sin especificar"
                }
              >
                <div
                  title={item.jefe_directo?.cargo?.nombre || "Sin asignar"}
                  className="text-sm w-36 whitespace-nowrap text-ellipsis overflow-hidden"
                >
                  {item.jefe_directo?.cargo?.nombre || "Sin asignar"}
                </div>
              </ProfileInfoCard>
            )}
            {!item.jefe_directo && (
              <div className="flex justify-center items-center w-full">
                Sin asignar
              </div>
            )}
          </div>
        </div>
        {/* ALL THE REST */}
        <div className="table-cell borderx p-4 align-middle text-center min-w-[150px]">
          <div className="w-52 p-4 text-left">
            {item.group || "Sin asignar"}
          </div>
        </div>
        <div className="table-cell borderx p-4 align-middle text-center min-w-[150px]">
          <div className="w-52 p-4 text-left">{item.ci || "Sin asignar"}</div>
        </div>
        <div className="table-cell borderx p-4 align-middle text-center min-w-[150px]">
          <div
            title={item.email}
            className="w-52 p-4 text-left overflow-hidden text-ellipsis"
          >
            {item.email || "Sin asignar"}
          </div>
        </div>
        <div className="table-cell borderx p-4 align-middle text-center">
          <div className="w-52 p-4 text-left">
            {item.sucursal?.nombre || "Sin asignar"}
          </div>
        </div>
        <div className="table-cell borderx p-4 align-middle text-center">
          <div className="w-52 p-4 text-left">
            {item.area?.nombre || "Sin asignar"}
          </div>
        </div>
        <div className="table-cell borderx p-4 align-middle text-center">
          <div className="w-52 p-4 text-left">
            {item.departamento?.nombre || "Sin asignar"}
          </div>
        </div>
        <div className="table-cell borderx p-4 align-middle text-center">
          <button
            onClick={(e) => {
              e.stopPropagation();
              enviarInvitacionIndividual(item);
            }}
            className="py-1 px-2 border rounded-md border-black text-sm"
          >
            Enviar
          </button>
        </div>
      </div>
    );
  };
  const onChangeText = (event) => {
    dispatch(
      colaboradorActions.setColaboradoresFilter({ nombre: event.target.value })
    );
  };
  const selectAllChange = (event) => {
    data.map((item, idx) =>
      dispatch(
        colaboradorActions.checkColaborador({
          colaborador: item,
          checked: event.target.checked,
        })
      )
    );
  };

  useEffect(() => {
    let isAllChecked =
      colaboradores_checked.length === data.length && data.length > 0;
    setCheckAll(isAllChecked);
  }, [colaboradores_checked]);

  const dropDownMenuItems = [
    { value: "enviar", label: "Enviar invitación" },
    {
      value: "asignar",
      label: "Asignar/cambiar",
      items: [
        { value: "asignar-rol", label: "Rol" },
        { value: "asignar-sucursal", label: "Sucursal" },
        { value: "asignar-area", label: "Área" },
        {
          value: "asignar-departamento",
          label: "Departamento",
        },
        { value: "asignar-cargo", label: "Cargo" },
        { value: "asignar-jefe", label: "Jefe directo" },
      ],
    },
    { value: "exportar-seleccionados", label: "Exportar seleccionados" },
    {
      value: "quitar",
      label: "Quitar",
      items: [
        { value: "quitar-rol", label: "Rol" },
        { value: "quitar-sucursal", label: "Sucursal" },
        { value: "quitar-area", label: "Área" },
        {
          value: "quitar-departamento",
          label: "Departamento",
        },
        { value: "quitar-cargo", label: "Cargo" },
        { value: "quitar-jefe", label: "Jefe directo" },
      ],
    },
    { value: "eliminar", label: "Eliminar" },
    { value: "cancel", label: "Cancelar" },
  ];

  const DropDownMenu = ({ title }) => {
    return (
      <div className="flex items-center gap-1 border rounded-md p-2">
        <Dropdown title={title} className="inline-grid z-40">
          {dropDownMenuItems.map((item, idx) => {
            const onClickHandler = (e) => {
              if (!item.items) {
                onAction(item);
              }
            };
            return (
              <Dropdown.Item
                key={`ddi-${idx}`}
                className="text-lgx"
                onClick={onClickHandler}
              >
                <div className="relative w-full">
                  <span>{item.label}</span>
                  {item.items?.length > 0 && (
                    <span className="absolute right-0">{">"}</span>
                  )}
                </div>
                {item.items?.length > 0 && (
                  <Dropdown.Submenu>
                    {item.items?.map((subItem, sIdx) => (
                      <Dropdown.Item
                        key={`ddsi-${idx}-${sIdx}`}
                        onClick={() => onAction(subItem)}
                      >
                        {subItem.label}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Submenu>
                )}
              </Dropdown.Item>
            );
          })}
        </Dropdown>
        <ChevronDown size={12}></ChevronDown>
      </div>
    );
  };

  const onChangePageSize = (e) => {
    const value = e.target.value;
    setPageSize(value);
    setPage(1);
  };

  const getPageButtonsArray = (pagesCount) => {
    let pagesArr = [1];
    const pagesCountCeil = Math.ceil(pagesCount);
    for (let index = 2; index <= pagesCountCeil; index++) {
      pagesArr.push(index);
    }
    return pagesArr;
  };
  const [descargarInformeLoading, setDescargarInformeLoading] = useState(false);
  const token = getToken();
  const onFinishDescargarLoading = () => {
    setDescargarInformeLoading(false);
  };
  const onDescargarInforme = () => {
    setDescargarInformeLoading(true);
    DescargarColaboradoresInforme(token, onFinishDescargarLoading);
  };
  const onDescargarInformeSeleccionados = (colaboradoresIds) => {
    setDescargarInformeLoading(true);
    DescargarColaboradoresInforme(
      token,
      onFinishDescargarLoading,
      colaboradoresIds
    );
  };
  const [isFirstPagination, setIsFirstPagination] = useState(true)
  useEffect(() => {
    if (!!isFirstPagination) {
      setIsFirstPagination(false)
    }
    if (Page && PageSize && !isFirstPagination) {
      onPaginate(Page, PageSize);
    }    
    setPagesButtonsArray(getPageButtonsArray(totalPaginate / PageSize));
  }, [Page, PageSize, isFirstPagination]);

  return (
    <div className={`flex flex-col gap-4 ${extraClass}`} onClick={onTableClick}>
      {/* SEARCH BAR AND ACTIONS */}
      <div>
        <div className="flex items-center gap-4">
          <BuscarInput
            onChange={onChangeText}
            value={nombre}
            placeholder={"Buscar por nombre o cargo del colaborador"}
          ></BuscarInput>
          <div className="flex gap-2 items-center">
            <label className="font-semibold" htmlFor="pageSize">
              Mostrar
            </label>
            <select
              name="pageSize"
              id="pageSize"
              className="border rounded-md p-2"
              onChange={onChangePageSize}
            >
              <option value="10">10</option>
              <option value="50" selected>
                50
              </option>
              <option value="100">100</option>
            </select>
          </div>
          <div>
            <DropDownMenu
              title={"(" + colaboradores_checked.length + ") seleccionados"}
            ></DropDownMenu>
          </div>
          <div className="flex items-center gap-4">
            {!descargarInformeLoading && (
              <button
                onClick={onDescargarInforme}
                className="flex items-center gap-2 text-black p-2 border border-black rounded"
              >
                <FiletypeXls size={21} color={"black"} />
                <span className="text-sm">Exportar</span>
              </button>
            )}
            {!!descargarInformeLoading && (
              <button
                disabled={true}
                className="flex items-center gap-2 bg-sky-400 text-white px-3 py-2 rounded animated-pulse"
              >
                <span className="font-semibold text-sm">Descargando...</span>
              </button>
            )}
            <button
              onClick={actionButtonHandler}
              className="h-10 bg-sky-500 text-white text-sm px-4 rounded "
            >
              {`Agregar`}
            </button>
          </div>
        </div>
      </div>
      {/* TABLE */}
      <div className="table-container border rounded-md relativex custom-scrollbar overflow-y-scrollx overflow-auto max-h-[780px]">
        <div
          className={`${
            data.length < 1 ? "" : "table"
          } h-1/2 overflow-x-scrollx overflow-autox w-fullx`}
        >
          <div className="table-header-group font-bold text-sm">
            {/* HEADERS */}
            {headers.length > 0 && (
              <div className="table-row">
                <div className="table-cell w-80 bg-white z-40 sticky top-0 left-0 border-b border-red-400x py-3 px-4">
                  <div className="flex items-center gap-2">
                    <CheckInput checked={checkAll} onChange={selectAllChange} />
                    <NominaTableHeader
                      text={headers[0].text}
                      onOrder={headers[0].onOrder}
                    />
                  </div>
                </div>
                {headers.map(
                  (header, idx) =>
                    idx !== 0 && (
                      <div
                        key={`header-${idx}`}
                        className="table-cell border-b w-80 bg-white z-30 sticky top-0 py-3 px-4"
                      >
                        <NominaTableHeader
                          text={header.text}
                          onOrder={header.onOrder}
                          onFilter={header.onFilter}
                        ></NominaTableHeader>
                      </div>
                    )
                )}
              </div>
            )}
          </div>
          {/* DATA */}
          {data.length > 0 && (
            <div className="table-row-group">
              {data.map((item, idx) => (
                <ColaboradorRowItem
                  item={item}
                  key={`colab-${idx}`}
                ></ColaboradorRowItem>
              ))}
              {/* workaround provisorio para que no se pierda height de las rows */}
              {data.length < 4 &&
                [1, 2, 3, 4, 5, 6, 7, 8].map((r, i) => (
                  <div key={`temp-${i}`} className="table-row opacity-0">
                    {r}
                  </div>
                ))}
            </div>
          )}
          {data.length < 1 && (
            <div className="flex justify-center w-full mt-8">
              <div className="table-cellx">
                No existen registros para mostrar.
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="flex items-center gap-2 justify-between">
        <div>{`Mostrando ${Page} de ${PagesButtonsArray.length} de un total de ${totalPaginate} registros`}</div>
        <div className="flex justify-end gap-0 rounded-sm border">
          {PagesButtonsArray.map((b, bidx) => (
            <button
              key={`page-${bidx}`}
              onClick={() => setPage(b)}
              className={`${
                b === Page
                  ? "bg-sky-500 text-white"
                  : "hover:bg-sky-500 hover:text-white"
              } py-1 px-2`}
            >
              {b}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
}
export default NominaFixTable;
