import React from "react";
import PlanCarreraFamilias from "../pages/PlanCarreraFamilias";
import PlanCarreraRequisitosCargo from "../pages/PlanCarreraRequisitosCargo";
import PlanCarreraVacancias from "../pages/PlanCarreraVacancias";
import PlanCarreraDetalle from "../pages/PlanCarreraDetalle";
export const planCarreraRoutes = [
  {
    path: "/plan-carrera/familias",
    element: <PlanCarreraFamilias />,
  },
  {
    path: "/plan-carrera/familias/:planCarreraId",
    element: <PlanCarreraDetalle />,
  },
  {
    path: "/plan-carrera/requisitoscargo",
    element: <PlanCarreraRequisitosCargo />,
  },
  // {
  //   path: "/plan-carrera/vacancias",
  //   element: <PlanCarreraVacancias />,
  // },
];
