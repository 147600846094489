import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import FileInput from "src/modules/common/components/FileInput";
import SkeletonLoaderRow from "src/modules/common/components/SkeletonLoaderRow";
import TableCustom from "src/modules/common/components/TableCustom";
import AreaModal from "src/modules/estructura/modules/area/components/AreaModal";
import { getToken } from "src/utils/helpers";
import { DownloadPlantilla } from "../../estructura/handlers/utils";
import { AreaTableAccionMenu } from "../components/AreaTableAccionMenu";
import { areaActions } from "../handlers/redux";

const AreaListItem = ({ item, idx }) => {
  return (
    <div
      key={idx}
      className="hover:bg-sky-100 flex flex-row gap-2 py-4 align-middle w-full"
    >
      <div className="p-1 flex flex-grow flex-1 flex-col justify-center">
        <div className="font-bold">{item.nombre}</div>
        <div className="text-sm">{item.descripcion}</div>
      </div>
      <div className="p-1 text-center w-48x flex flex-row flex-1 justify-centerx items-center">
        {item.cantidad_colaboradores}
      </div>
      <div className="p-1 w-12 flex flex-row items-center">
        <AreaTableAccionMenu item={item} />
      </div>
    </div>
  );
};

function AreaPage() {
  const dispatch = useDispatch();
  const token = getToken();
  const areas = useSelector((state) => state.area.areas);
  const areas_checked = useSelector((state) => state.area.areas_checked);
  const areas_loading = useSelector((state) => state.area.areas_loading);
  const areas_filter = useSelector((state) => state.area.areas_filter);
  const { nombre = "" } = areas_filter;

  const { results = [], count: areasCount = 0 } = areas;

  const showAreaModal = () => {
    dispatch(areaActions.areaSet({}));
    dispatch(areaActions.setAreaCreateModal({ open: true }));
  };

  const onCheckAreas = (event, item) => {
    dispatch(
      areaActions.checkArea({
        area: item,
        checked: event.target.checked,
      })
    );
  };

  const onSelectChange = (v) => {
    if (v.value === "cancel") {
      dispatch(areaActions.resetCheckAreas());
    } else if (v.value === "eliminar") {
      let deseaEliminar = window.confirm(
        "Desea eliminar las áreas seleccionadas?"
      );
      if (deseaEliminar) dispatch(areaActions.setAreasDelete(areas_checked));
    } else {
      // @ts-ignore
      window.alert(JSON.stringify(v));
    }
  };

  const onChangeSearch = (event) => {
    dispatch(
      areaActions.setAreasFilter({
        ...areas_filter,
        nombre: event.target.value,
      })
    );
  };

  const onPaginate = (page, pageSize) => {
    dispatch(
      areaActions.setAreasFilter({
        ...areas_filter,
        pagination: {
          page: page,
          pageSize: pageSize,
        },
      })
    );
  };

  const uploadFile = (file) => {
    dispatch(areaActions.setFileToUpload(file));
  };

  const onPlantilla = () => {
    DownloadPlantilla({ token });
  };

  useEffect(() => {
    dispatch(areaActions.areas());
  }, []);
  //
  return (
    <div className="md:w-11/12">
      <div className="flex flex-col gap-2">
        <div className="text-xl font-bold ">Áreas</div>
        <div className="border-b border-b-gray-200 mb-3"></div>
        {!areas_loading && (
          <TableCustom
            fixedHeigth={true}
            data={results || []}
            customHeaders={["Colaboradores"]}
            tableTitle={""}
            tableSecondTitle={"Lista de áreas creadas"}
            subject={"área"}
            subjectGender={"F"}
            ItemComponent={AreaListItem}
            addBtnClickHandler={showAreaModal}
            itemsChecked={areas_checked}
            onCheckHandler={onCheckAreas}
            onSelectChange={onSelectChange}
            selectOptions={[
              { value: "eliminar", label: "Eliminar" },
              { value: "cancel", label: "Cancelar" },
            ]}
            searchValue={nombre}
            onChangeSearch={onChangeSearch}
            noBottomButtom={true}
            totalPaginate={areasCount}
            onPaginate={onPaginate}
          />
        )}
        {!!areas_loading && (
          <div className="mt-28">
            {[1, 2, 3].map((r, i) => (
              <SkeletonLoaderRow key={i}></SkeletonLoaderRow>
            ))}
          </div>
        )}
      </div>
      <FileInput
        className={"mt-3"}
        onSubmit={uploadFile}
        onPlantilla={onPlantilla}
      />
      <AreaModal></AreaModal>
    </div>
  );
}

export default AreaPage;
