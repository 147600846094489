import React, { useState } from "react";
import { useEffect } from "react";
import { ChevronDown, ChevronRight, ChevronUp } from "react-bootstrap-icons";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { colaboradorActions } from "src/modules/colaborador/modules/colaborador/handlers/redux";
import { CheckInput } from "src/modules/common/components/CheckInput";
import { ProfileInfoCard } from "src/modules/common/components/ProfileInfoCard";
import SkeletonLoaderRow from "src/modules/common/components/SkeletonLoaderRow";
import { kpiActions } from "../handlers/redux";

const TableHr = ({ title = "Title", onOrder }) => {
  const [orderingLocal, setOrderingLocal] = useState("");
  //
  const onOrderingClickLocal = (e) => {
    e.stopPropagation();
    switch (orderingLocal) {
      case "asc":
        setOrderingLocal("desc");
        onOrder("desc");
        break;
      case "desc":
        setOrderingLocal("");
        onOrder("");
        break;
      default:
        setOrderingLocal("asc");
        onOrder("asc");
        break;
    }
  };
  //
  return (
    <>
      <span>{title}</span>
      <span className="hover:cursor-pointer" onClick={onOrderingClickLocal}>
        <ChevronUp
          color={orderingLocal === "asc" || !orderingLocal ? "black" : "gray"}
          size={12}
        ></ChevronUp>
        <ChevronDown
          color={orderingLocal === "desc" || !orderingLocal ? "black" : "gray"}
          size={12}
        ></ChevronDown>
      </span>
    </>
  );
};

const TableHeader = ({ onCheckAll, checkedAll = false, onSetOrdering }) => {
  //
  return (
    <div className="border-b flex flex-col border-zinc-100 ">
      <div className="flex flex-row">
        <div className="p-1 font-bold flex-grow flex items-center gap-2">
          <input
            className="h-5 w-5"
            type="checkbox"
            name="select-all"
            id="select-all"
            checked={checkedAll}
            onChange={(e) => {
              const checked = e.target.checked;
              onCheckAll(checked);
            }}
          />
          <TableHr
            title={"Lista de integrantes"}
            onOrder={onSetOrdering("nombre")}
          />
        </div>
        <div className="p-1 font-bold text-center w-60  mr-4 flex justify-center items-center gap-2">
          <TableHr title={"# KPIs"} onOrder={onSetOrdering("cantidad_kpis")} />
        </div>
        <div className=" w-6"></div>
      </div>
    </div>
  );
};

export const ColaboradorTable = ({
  Page,
  setPage,
  PageSize,
  setPageSize,
  PagesButtonsArray,
  setPagesButtonsArray,
  setPagination,
  totalPaginate,
  getPageButtonsArray,
  //
  checkedIdField = "id",
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // @ts-ignore
  const colaboradores = useSelector((state) => state.kpi.colaboradores || {});
  const colaboradoresLoading = useSelector(
    (state) => state.kpi.colaboradores_loading
  );
  const colaboradores_filter = useSelector(
    (state) => state.kpi.colaboradores_filter
  );
  const colaboradores_checked = useSelector(
    (state) => state.kpi.colaboradores_checked
  );
  const { results = [] } = colaboradores;

  useEffect(() => {
    dispatch(kpiActions.resetCheckColaboradores());
  }, []);

  useEffect(() => {
    setPagination(Page, PageSize);
    setPagesButtonsArray(getPageButtonsArray(totalPaginate / PageSize));
  }, [Page, PageSize]);

  const onSetOrdering =
    (field) =>
    (order = "") => {
      let { ordering = "" } = colaboradores_filter;
      let orderingArray = ordering.split(",") || [];
      let emptyIndex = orderingArray.indexOf("");
      if (emptyIndex) {
        orderingArray.splice(emptyIndex, 1);
      }
      let fieldIndex = orderingArray.indexOf(field);
      fieldIndex =
        fieldIndex === -1 ? orderingArray.indexOf(`-${field}`) : fieldIndex;
      // si ya esta el campo, vemos si se modifica o elimina
      if (fieldIndex > 0) {
        orderingArray.splice(fieldIndex, 1);
        if (order) {
          field = order === "desc" ? `-${field}` : field;
          orderingArray[fieldIndex] = field;
        }
      } else {
        // si no está, lo agregamos
        field = order === "desc" ? `-${field}` : field;
        orderingArray.push(field);
      }
      ordering = orderingArray.join(",");
      //
      dispatch(
        kpiActions.setColaboradoresFilter({
          ...colaboradores_filter,
          ordering: ordering,
        })
      );
    };

  const onRowClick = (id) => {
    navigate(`/kpis/colaboradores/${id}`);
  };

  const onItemCheck = (colaborador, checked = false) => {
    console.log("onItemCheck", colaborador, checked);
    dispatch(
      kpiActions.checkColaborador({
        colaborador: colaborador,
        checked: checked,
      })
    );
  };

  const renderTableItem = (item, idx) => {
    return (
      <div
        key={idx}
        className="hover:bg-sky-100 hover:cursor-pointer flex items-center flex-row border-b align-middle p-4 gap-2"
      >
        <input
          className="h-5 w-5"
          type="checkbox"
          name={`select-${idx}`}
          id={`select-${idx}`}
          checked={
            !!colaboradores_checked.find(
              (citem, iidx) => citem[checkedIdField] === item.id
            )
          }
          onChange={(e) => {
            const checked = e.target.checked;
            console.log("checked", checked);
            console.log("colaboradores_checked", colaboradores_checked);
            console.log(
              "onChange ==",
              !!colaboradores_checked.find(
                (citem, iidx) => citem[checkedIdField] === item.id
              )
            );
            //colaboradores_checked
            e.stopPropagation();
            onItemCheck(item, checked);
          }}
        />
        <div
          className="flex flex-1"
          onClick={(e) => {
            onRowClick(item.id);
          }}
        >
          <ProfileInfoCard
            key={"col-" + item.id}
            image={item.foto_empleado}
            name={
              item.id ? `${item.nombre} ${item.apellido}` : "Sin especificar"
            }
          >
            <div className="text-sm">{item.cargo?.nombre || "Sin asignar"}</div>
          </ProfileInfoCard>
        </div>
        <div
          onClick={(e) => {
            onRowClick(item.id);
          }}
          className="p-1 text-center w-48 flex flex-row justify-center items-center"
        >
          {item.cantidad_kpis}
        </div>
        <div
          onClick={(e) => {
            onRowClick(item.id);
          }}
          className="p-1 w-12 flex flex-row items-center"
        >
          <ChevronRight color="#2196F3" size={18} />
        </div>
      </div>
    );
  };

  const onCheckAll = (checked) => {
    if (!!checked) {
      dispatch(kpiActions.setCheckColaboradores(results));
    } else {
      dispatch(kpiActions.setCheckColaboradores([]));
    }
  };

  return (
    <div>
      <div className="flex flex-col gap-2 w-full">
        {
          <TableHeader
            onSetOrdering={onSetOrdering}
            onCheckAll={onCheckAll}
            checkedAll={colaboradores_checked.length === results.length}
          />
        }
        <div className="h-[80vh] overflow-auto custom-scrollbar">
          {!colaboradoresLoading && results.length > 0 && (
            <div className="flex flex-col">{results.map(renderTableItem)}</div>
          )}
          {!colaboradoresLoading && results.length < 1 && (
            <div className="flex h-20 items-center justify-center">
              <div>No existen registros para mostrar.</div>
            </div>
          )}
          {!!colaboradoresLoading &&
            [0, 1, 2, 3, 4].map((item, idx) => (
              <SkeletonLoaderRow key={`sl-${idx}`}></SkeletonLoaderRow>
            ))}
        </div>
        {/*  */}
        <div className="flex items-center gap-2 justify-between my-2">
          <div>{`Mostrando ${Page} de ${PagesButtonsArray.length} de un total de ${totalPaginate} registros`}</div>
          <div className="flex justify-end gap-0 rounded-sm border">
            {PagesButtonsArray.map((b, bidx) => (
              <button
                key={`pagebtn-${bidx}`}
                onClick={() => setPage(b)}
                className={`${
                  b === Page
                    ? "bg-sky-500 text-white"
                    : "hover:bg-sky-500 hover:text-white"
                } py-1 px-2`}
              >
                {b}
              </button>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
