import React from "react";
import { ReactComponent as User } from "../../common/images/user.svg";

export const ProfileImage = ({ className = "", title = "" }) => {
  return (
    <div
      className={`rounded-lg bg-zinc-100 flex justify-center items-center ${className}`}
    >
      <User className="w-full h-full" title={title} />
    </div>
  );
};
