import axios from "axios";
const URL_BASE =
  process.env.REACT_APP_API_URL || "http://localhost:8000/api/admin";

export const competenciaAreaApi = (token) => {
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };

  return {
    create: async (params) => {
      try {
        const area = await axios.post(
          URL_BASE + "/competencia/area/create",
          params,
          { headers }
        );
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log(JSON.stringify(error));
        throw error;
      }
    },
    update: async ({ id, ...params }) => {
      try {
        const data = await axios.put(
          URL_BASE + `/competencia/area/${id}/edit`,
          params,
          {
            headers,
          }
        );
        return data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log(JSON.stringify(error));
        throw error;
      }
    },
    detail: async ({ id }) => {
      try {
        const competencia = await axios.get(
          URL_BASE + `/competencia/area/${id}/detail`,
          {
            headers,
          }
        );
        return competencia.data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log(JSON.stringify(error));
        throw error;
      }
    },
    delete: async ({ id }) => {
      try {
        const data = await axios.delete(
          URL_BASE + `/competencia/area/${id}/delete`,
          {
            headers,
          }
        );
        return data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log(JSON.stringify(error));
        throw error;
      }
    },
    competenciasAreaDelete: async ({ competencias_area_ids }) => {
      // Borra varias Comeptencias
      try {
        const data = await axios.post(
          URL_BASE + `/competencia/area/areas/delete`,
          { competencias_area_ids: competencias_area_ids },
          { headers }
        );
        return data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log(JSON.stringify(error));
        throw error;
      }
    },
    list: async ({ competenciaAreas_filter }) => {
      const { nombre = "" } = competenciaAreas_filter;
      try {
        const areas = await axios.get(
          `${URL_BASE}/competencia/area/?search=${nombre}`,
          {
            headers,
          }
        );
        return areas.data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log("areas error", error);
        throw error;
      }
    },
    libreriaEspecifica: async (params) => {
      const { nombre = "", pagination = {}, ordering = "" } = params;
      const { page = 1, pageSize = 100 } = pagination;
      try {
        const libreria = await axios.get(
          URL_BASE +
            `/competencia/libreria/especifica/?search=${nombre}&page=${page}&page_size=${pageSize}`,
          {
            headers,
          }
        );
        return libreria.data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG &&
          console.log("libreria api error", error);
        throw error;
      }
    },
  };
};
