import { getToken } from "src/utils/helpers";
import { beneficiosApis } from "./apis";
import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import { beneficioActions } from "./redux";
import { SuccessToast } from "src/modules/common/components/SuccessToast";
import { ErrorToast } from "src/modules/common/components/ErrorToast";
import { push } from "@lagunovsky/redux-react-router";

function* listBeneficiosSaga() {
  const token = getToken();
  const beneficios_filter = yield select(
    (state) => state.beneficio.beneficios_filter
  );
  try {
    const data = yield call(beneficiosApis(token).list, beneficios_filter);
    yield put(beneficioActions.setBeneficios(data.results));
    yield put(beneficioActions.setBeneficiosCount(data.count));
    yield put(beneficioActions.setBeneficiosComplete());
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
    yield put(beneficioActions.setBeneficiosComplete());
  }
}

function* beneficioDetailSaga({ payload }) {
  const token = getToken();
  try {
    const data = yield call(beneficiosApis(token).detail, payload);
    yield put(beneficioActions.setBeneficio(data));
    yield put(beneficioActions.setBeneficioComplete());
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
    yield put(beneficioActions.setBeneficioComplete());
  }
}

function* tiposConfiguracionesSaga() {
  const token = getToken();
  try {
    const data = yield call(beneficiosApis(token).listTipoConfig);
    yield put(beneficioActions.setTiposConfiguraciones(data));
    yield put(beneficioActions.setTiposConfiguracionesComplete());
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
    yield put(beneficioActions.setTiposConfiguracionesComplete());
  }
}

function* createTipoConfigSaga({ payload }) {
  const token = getToken();
  try {
    const data = yield call(beneficiosApis(token).createTipoConfig, payload);
    yield put(beneficioActions.getTiposConfiguraciones());
    SuccessToast({ message: "Tipo de configuración creado con éxito" });
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
    ErrorToast({
      message: "Ocurrió un error al crear el tipo de configuración.",
    });
  }
}

function* bulkCreateSaga({ payload }) {
  const token = getToken();
  try {
    const data = yield call(beneficiosApis(token).bulkCreate, payload);
    yield put(beneficioActions.bulkCreateComplete());
    yield put(push("/beneficios/"));
    SuccessToast({ message: "Beneficios creados con éxito" });
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
    yield put(beneficioActions.bulkCreateComplete());
  }
}

function* updateBeneficio({ payload }) {
  const token = getToken();
  try {
    const data = yield call(beneficiosApis(token).update, payload);
    yield put(beneficioActions.updateBeneficioComplete());
    yield put(beneficioActions.getBeneficio(payload.id));
    yield put(
      beneficioActions.getBeneficiosColaborador({ idColaborador: payload.id })
    );
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
    yield put(beneficioActions.updateBeneficioComplete());
  }
}

function* deleteBeneficio({ payload }) {
  const token = getToken();
  try {
    const data = yield call(beneficiosApis(token).delete, payload);
    yield put(beneficioActions.getBeneficios());
    SuccessToast({ message: "Beneficio eliminado" });
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
    ErrorToast({ message: "Ocurrió un error" });
  }
}

function* bulkDeleteBeneficioSaga() {
  const token = getToken();
  const beneficios_checked = yield select(
    (state) => state.beneficio.beneficios_checked
  );
  const beneficiosIds = beneficios_checked.map((beneficio) => beneficio.id);
  try {
    const data = yield call(beneficiosApis(token).bulkDelete, beneficiosIds);
    yield put(beneficioActions.resetCheckBeneficios());
    yield put(beneficioActions.getBeneficios());
    SuccessToast({ message: "Beneficios eliminados con éxito" });
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
    ErrorToast({ message: "Ocurrió un error" });
  }
}

function* cargosHabilitadosSaga() {
  const token = getToken();
  const cargos_habilitados_filter = yield select(
    (state) => state.beneficio.cargos_habilitados_filter
  );
  try {
    const data = yield call(
      beneficiosApis(token).cargosHabilitados,
      cargos_habilitados_filter
    );
    yield put(beneficioActions.setCargosHabilitados(data));
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
    yield put(beneficioActions.setCargosHabilitados([]));
  }
}

function* colaboradoresHabilitadosSaga() {
  const token = getToken();
  const colaboradores_habilitados_filter = yield select(
    (state) => state.beneficio.colaboradores_habilitados_filter
  );
  try {
    const data = yield call(
      beneficiosApis(token).colaboradoresHabilitados,
      colaboradores_habilitados_filter
    );
    yield put(beneficioActions.setColaboradoresHabilitados(data));
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
    yield put(beneficioActions.setColaboradoresHabilitados([]));
  }
}

function* vincularCargosSaga({ payload }) {
  const token = getToken();
  const { beneficioId } = payload;
  try {
    const data = yield call(beneficiosApis(token).vincularCargos, payload);
    yield put(beneficioActions.getBeneficio(beneficioId));
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
    ErrorToast({
      message: "Ocurrió un error al intentar vincular los cargos",
    });
  }
}

function* desvincularCargosMasivoSaga({ payload }) {
  const token = getToken();
  const { beneficioId } = payload;
  try {
    const data = yield call(
      beneficiosApis(token).desvincularCargosMasivo,
      payload
    );
    SuccessToast({ message: "Cargos desvinculados" });
    yield put(beneficioActions.getBeneficio(beneficioId));
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
    ErrorToast({
      message: "Ocurrió un error al intentar desvincular los cargos",
    });
  }
}

function* desvincularBeneficiosCargoSaga({ payload }) {
  const token = getToken();
  try {
    const data = yield call(
      beneficiosApis(token).desvincularBeneficiosCargo,
      payload
    );
    SuccessToast({ message: "Beneficios desvinculados" });
    yield put(beneficioActions.getCargosList());
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
    ErrorToast({
      message: "Ocurrió un error al intentar desvincular los beneficios.",
    });
  }
}

function* desvincularCargoSaga({ payload }) {
  // Recibe el id del CargoBeneficio a eliminar
  const token = getToken();
  const { beneficioId, cargoBeneficioId } = payload;
  try {
    const data = yield call(
      beneficiosApis(token).desvincularCargo,
      cargoBeneficioId
    );
    yield put(beneficioActions.getBeneficio(beneficioId));
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
    ErrorToast({
      message: "Ocurrió un error al intentar desvincular el cargo",
    });
  }
}

function* cargosListSaga({ payload }) {
  const token = getToken();
  const cargos_list_filter = yield select(
    (state) => state.beneficio.cargos_list_filter
  );
  try {
    const data = yield call(
      beneficiosApis(token).cargosList,
      cargos_list_filter
    );
    yield put(beneficioActions.setCargosList(data.results));
    yield put(beneficioActions.setCargosListCount(data.count));
    yield put(beneficioActions.setCargosListComplete());
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
    yield put(beneficioActions.setCargosListComplete());
  }
}

function* beneficiosCargoSaga({ payload }) {
  const token = getToken();
  try {
    const data = yield call(beneficiosApis(token).beneficiosCargo, payload);
    yield put(beneficioActions.setBeneficiosCargo(data.results));
    yield put(beneficioActions.setBeneficiosCargoCount(data.count));
    yield put(beneficioActions.setBeneficiosCargoComplete());
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
    yield put(beneficioActions.setBeneficiosCargoComplete());
  }
}

function* colaboradoresListSaga({ payload }) {
  const token = getToken();
  const colaboradores_list_filter = yield select(
    (state) => state.beneficio.colaboradores_list_filter
  );
  try {
    const data = yield call(
      beneficiosApis(token).colaboradoresList,
      colaboradores_list_filter
    );
    yield put(beneficioActions.setColaboradoresList(data.results));
    yield put(beneficioActions.setColaboradoresListCount(data.count));
    yield put(beneficioActions.setColaboradoresListComplete());
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
    yield put(beneficioActions.setColaboradoresListComplete());
  }
}

function* colaboradorSaga({ payload }) {
  const token = getToken();
  const { idColaborador } = payload;
  try {
    const data = yield call(beneficiosApis(token).colaborador, idColaborador);
    yield put(beneficioActions.setColaborador(data));
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
    yield put(beneficioActions.setColaborador({}));
  }
}

function* deleteColaboradoresBeneficioSaga({ payload }) {
  const token = getToken();
  const { beneficioId } = payload;
  try {
    const data = yield call(
      beneficiosApis(token).deleteColaboradoresBeneficio,
      payload
    );
    SuccessToast({ message: "Colaboradores incluidos" });
    yield put(beneficioActions.getBeneficio(beneficioId));
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
    ErrorToast({
      message: "Ocurrió un error al intentar incluir a los colaboradores.",
    });
  }
}

function* createColaboradoresBeneficioSaga({ payload }) {
  const token = getToken();
  const { beneficiosId, colaboradoresIds } = payload;
  try {
    const data = yield call(
      beneficiosApis(token).createColaboradoresBeneficio,
      payload
    );
    SuccessToast({ message: "Colaboradores excluidos" });
    /* Si se llama a esta función desde los detalles de un beneficio (Pestaña Creados), se debe volver a cargar
       los datos del beneficio. Desde ahí, siempre se le pasa solo un id del beneficio, por eso pongo 
       beneficiosId[0] y solo en esta pestaña es correspondiente llamar a getBeneficio(beneficiosId[0]).
       Si se llama a esta función desde los detalles de un colaborador (pestaña Colaboradores) ahí sí se le pasa
       varios ids de beneficios, y no es necesario llamar a getBeneficio(beneficiosId[0]). La validación que 
       pongo para ver si se está invocando desde Creados o desde Colaboradores, es que si tiene más de un elemento
       en beneficiosId, no se invoque a getBeneficio(beneficiosId[0]). */
    if (beneficiosId.length === 1) {
      yield put(beneficioActions.getBeneficio(beneficiosId[0]));
    }
    /* Para este lo mismo, valida si se le llama desde pestaña Colaboradores, si es así sí se ejecuta. */
    if (colaboradoresIds.length === 1) {
      const idColaborador = colaboradoresIds[0];
      yield put(beneficioActions.getBeneficiosColaborador({ idColaborador }));
    }
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
    ErrorToast({
      message: "Ocurrió un error al intentar excluir a los colaboradores.",
    });
  }
}

function* cambiarBeneficioHabilitadoColaboradorSaga({ payload }) {
  const token = getToken();
  try {
    const data = yield call(
      beneficiosApis(token).cambiarBeneficioHabilitadoColaborador,
      payload
    );
    yield put(beneficioActions.getColaborador({ idColaborador: payload }));
    SuccessToast({ message: "Vigencia cambiada correctamente" });
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
    ErrorToast({
      message: "Ocurrió un error al cambiar la vigencia del colaborador.",
    });
  }
}

function* beneficiosColaboradorSaga({ payload }) {
  const token = getToken();
  const { idColaborador } = payload;
  const beneficios_colaborador_filter = yield select(
    (state) => state.beneficio.beneficios_colaborador_filter
  );
  try {
    const data = yield call(beneficiosApis(token).beneficiosColaborador, {
      idColaborador,
      beneficios_colaborador_filter,
    });
    yield put(beneficioActions.setBeneficiosColaborador(data.results));
    yield put(beneficioActions.setBeneficiosColaboradorCount(data.count));
    yield put(beneficioActions.setBeneficiosColaboradorComplete());
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
    yield put(beneficioActions.setBeneficiosColaboradorComplete());
  }
}

function* excluirBeneficiosTotalColaboradoresSaga({ payload }) {
  const token = getToken();
  try {
    const data = yield call(
      beneficiosApis(token).excluirBeneficiosTotalColaboradores,
      payload
    );
    SuccessToast({ message: "Colaboradores excluidos de sus beneficios" });
    yield put(beneficioActions.getColaboradoresList());
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
    ErrorToast({
      message: "Ocurrió un error al intentar excluir a los colaboradores.",
    });
  }
}

function* cargoSaga({ payload }) {
  const token = getToken();
  try {
    const data = yield call(beneficiosApis(token).cargo, payload);
    yield put(beneficioActions.setCargo(data));
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
    yield put(beneficioActions.setCargo({}));
  }
}

export function* beneficioSagas() {
  yield takeLatest(beneficioActions.getBeneficios.type, listBeneficiosSaga);
  yield takeLatest(
    beneficioActions.setBeneficiosFilter.type,
    listBeneficiosSaga
  );

  yield takeEvery(beneficioActions.getBeneficio.type, beneficioDetailSaga);

  yield takeEvery(
    beneficioActions.getTiposConfiguraciones.type,
    tiposConfiguracionesSaga
  );

  yield takeEvery(beneficioActions.bulkCreate.type, bulkCreateSaga);

  yield takeEvery(beneficioActions.updateBeneficio.type, updateBeneficio);

  yield takeLatest(
    beneficioActions.getCargosHabilitados.type,
    cargosHabilitadosSaga
  );
  yield takeLatest(
    beneficioActions.setCargosHabilitadosFilter.type,
    cargosHabilitadosSaga
  );

  yield takeEvery(beneficioActions.deleteBeneficio.type, deleteBeneficio);

  yield takeLatest(
    beneficioActions.getColaboradoresHabilitados.type,
    colaboradoresHabilitadosSaga
  );
  yield takeLatest(
    beneficioActions.setColaboradoresHabilitadosFilter.type,
    colaboradoresHabilitadosSaga
  );

  yield takeEvery(beneficioActions.bulkDelete.type, bulkDeleteBeneficioSaga);

  yield takeEvery(beneficioActions.desvincularCargo.type, desvincularCargoSaga);

  yield takeEvery(beneficioActions.vincularCargos.type, vincularCargosSaga);

  yield takeLatest(beneficioActions.getCargosList.type, cargosListSaga);
  yield takeLatest(beneficioActions.setCargosListFilter.type, cargosListSaga);

  yield takeEvery(
    beneficioActions.getColaboradoresList.type,
    colaboradoresListSaga
  );
  yield takeLatest(
    beneficioActions.setColaboradoresListFilter.type,
    colaboradoresListSaga
  );

  yield takeEvery(
    beneficioActions.getBeneficiosCargo.type,
    beneficiosCargoSaga
  );

  yield takeEvery(beneficioActions.getColaborador.type, colaboradorSaga);

  yield takeEvery(
    beneficioActions.createTipoConfiguracion.type,
    createTipoConfigSaga
  );

  yield takeEvery(
    beneficioActions.desvincularCargosMasivo.type,
    desvincularCargosMasivoSaga
  );

  yield takeEvery(
    beneficioActions.desvincularBeneficiosCargo.type,
    desvincularBeneficiosCargoSaga
  );

  yield takeEvery(
    beneficioActions.incluirColaboradoresBeneficio.type,
    deleteColaboradoresBeneficioSaga
  );

  yield takeEvery(
    beneficioActions.excluirColaboradoresBeneficio.type,
    createColaboradoresBeneficioSaga
  );

  yield takeEvery(
    beneficioActions.cambiarBeneficioHabilitadoColaborador.type,
    cambiarBeneficioHabilitadoColaboradorSaga
  );

  yield takeEvery(
    beneficioActions.getBeneficiosColaborador.type,
    beneficiosColaboradorSaga
  );

  yield takeEvery(
    beneficioActions.excluirBeneficiosTotalColaboradores.type,
    excluirBeneficiosTotalColaboradoresSaga
  );

  yield takeEvery(beneficioActions.getCargo.type, cargoSaga);
}
