import React, { useEffect } from "react";
import DataTable from "src/modules/common/components/DataTable";
import portadaDefault from "src/modules/common/components/images/modulo_aprendizaje_wel.png";
import SearchInput from "src/modules/remuneracion/components/SearchInput";
import { AprendizajeLayout } from "../components/AprendizajeLayout";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { aprendizajeActions } from "../handlers/redux";
import dayjs from "dayjs";

export function FormacionPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // @ts-ignore
  const formaciones = useSelector((state) => state.aprendizaje.formaciones);
  const formaciones_count = useSelector(
    // @ts-ignore
    (state) => state.aprendizaje.formaciones_count
  );
  const formaciones_loading = useSelector(
    // @ts-ignore
    (state) => state.aprendizaje.formaciones_loading
  );
  const formaciones_filter = useSelector(
    // @ts-ignore
    (state) => state.aprendizaje.formaciones_filter
  );
  const { nombre = "" } = formaciones_filter;

  useEffect(() => {
    dispatch(aprendizajeActions.getFormaciones());
  }, []);

  const verContenido = (id) => {
    navigate(`/aprendizaje/formacion/contenido/${id}`);
  };
  const datatableConfig = {
    headers: [
      {
        title: "Videos",
        contentRenderer: (item) => (
          <div className="flex flex-row gap-2">
            <div className="">
              <img
                src={item.portada || portadaDefault}
                className="object-cover h-24 w-40 rounded-md"
              />
            </div>
            <div className="flex flex-col">
              <span className="font-semibold mb-2">{item.nombre}</span>
              {item.publicado && (
                <span>
                  {`Publicado: `}
                  {dayjs(item.publicado.slice(0, 10)).format("DD/MM/YYYY")}
                </span>
              )}
            </div>
          </div>
        ),
      },
      {
        title: "Adjuntos",
        accessor: "adjuntos",
        contentClass: "",
      },
      {
        title: "",
        contentRenderer: (item) => (
          <div className="">
            <button
              className="border rounded-md py-2 px-3 border-gray-500 text-sm"
              onClick={() => verContenido(item.id)}
            >
              Ver contenido
            </button>
          </div>
        ),
      },
    ],
    rowActions: [],
  };

  const onSearch = (e) => {
    const value = e.target.value;
    dispatch(
      aprendizajeActions.setFormacionesFilter({
        ...formaciones_filter,
        nombre: value,
      })
    );
  };

  return (
    // @ts-ignore
    <AprendizajeLayout>
      <div className="flex flex-col my-8">
        <div className="flex flex-row border w-1/2">
          {/* @ts-ignore */}
          <SearchInput
            placeholder="Buscar contenido..."
            value={nombre}
            onChange={onSearch}
          />
        </div>
        {/* @ts-ignore */}
        <DataTable
          containerClass={""}
          config={datatableConfig}
          data={formaciones}
          loading={formaciones_loading}
          noHeaders={false}
          //
          totalRecords={formaciones_count}
          //
          checkable={false}
        />
      </div>
    </AprendizajeLayout>
  );
}
