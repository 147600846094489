import React from "react";
import { ThreeDotsVertical, X } from "react-bootstrap-icons";
import { CheckInput } from "./CheckInput";

function TableRowCheck({
  itemKey = "id",
  item,
  ItemComponent,
  isAddButtonRow,
  hasCheck = true,
  hasNoRightIcon,
  onSelectHandler,
  rowNumber,
  activeRowNumberMenu,
  activeRowNumberMenuHandler,
  dropdownActions = null,
  checkValue = false,
  itemsChecked = [],
  onCheckHandler = undefined,
  checkType = "checkbox",
}) {
  const hideDropDownMenuHandler = (e) => {
    e.stopPropagation();
    activeRowNumberMenuHandler(rowNumber);
  };

  const renderItem = () => {
    if (!!ItemComponent) {
      return <ItemComponent item={item} />;
    }

    return (
      <div className="flex flex-row flex-grow">
        <div className="flex flex-col items-start flex-grow">
          <h6 className="font-bold text-base">{item?.title}</h6>
          <p className="font-weight-400 text-base">{item?.description}</p>
        </div>
        <span className="text-base font-weight-400 mr-3">{item?.info}</span>
      </div>
    );
  };

  const getCheckValue = (item) => {
    !!process.env.REACT_APP_DEBUG &&
      console.info("1.itemsChecked 2.tRowCheck", itemsChecked, item);
    if (itemsChecked.length > 0) {
      return (
        itemsChecked.includes(item.id) ||
        itemsChecked.map((item, iidx) => item.id).includes(item.id)
      );
    }
    return checkValue;
  };

  const getCheckHandler = (e) => {
    if (onCheckHandler) return onCheckHandler(e, item);
    if (onSelectHandler) return onSelectHandler(rowNumber, e.target.checked);
  };

  return (
    <>
      <div className={"flex py-2 border-b items-center w-full"}>
        <div className="flex ">
          <div className="mr-3 align-self-center ">
            {!!hasCheck && (
              <CheckInput
                checkType={checkType}
                checked={getCheckValue(item)}
                onChange={(e) => {
                  getCheckHandler(e);
                }}
              />
            )}
            {!!isAddButtonRow && (
              <div className="background-color-primary rounded-2xl p-4">
                <X color="#FFF" size={28} className={"rotate-45"} />
              </div>
            )}
          </div>
        </div>
        <div className="flex flex-grow">{renderItem(item)}</div>

        {!!dropdownActions && (
          <div className="relative flex">
            {!hasNoRightIcon && (
              <div className="w-16 flex justify-center">
                <button onClick={hideDropDownMenuHandler}>
                  <ThreeDotsVertical size={18} />
                </button>
              </div>
            )}
            {!isNaN(rowNumber) && rowNumber === activeRowNumberMenu && (
              <div className="border flex content-center absolute top-1 right-8 border-1 border-gray-300 rounded-md bg-white z-10">
                <ul className="flex flex-col content-center items-start text-center m-0">
                  {!!dropdownActions &&
                    dropdownActions.map((item, idx) => (
                      <li
                        key={`li-${idx}`}
                        className={`hover:bg-sky-100 ${item.className}`}
                      >
                        <button className="p-2" onClick={() => item.action()}>
                          {item.label}
                        </button>
                      </li>
                    ))}
                </ul>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
}

export default TableRowCheck;
