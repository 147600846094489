import { useEffect, useState } from "react";
import { ThreeDotsVertical } from "react-bootstrap-icons";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import TableCustom from "src/modules/common/components/TableCustom";
import TableRow from "src/modules/common/components/TableRow";
import { evaluacionActions } from "../handlers/redux";
import { ESTADOS_EVALUACION } from "../helpers/helpers";

const ItemCommonComponent = ({ item }) => {
  const {
    peso_generales,
    peso_areas,
    peso_cargos,
    peso_kpis,
    peso_objetivos_cargo,
  } = item;
  return (
    <TableRow>
      <div
        className="p-4 flex gap-8 items-center justify-start cursor-pointer"
        onClick={() => {}}
      >
        <div className="w-2/3">
          <div>{item.nombre}</div>
        </div>
        <div className="flex flex-1 gap-2 items-center justify-start">
          {!!peso_generales && (
            <div className="p-1 rounded-md bg-black text-white text-sm text-center">
              Generales {`(${peso_generales}%)`}
            </div>
          )}
          {!!peso_areas && (
            <div className="p-1 rounded-md bg-black text-white text-sm text-center">
              Áreas {`(${peso_areas}%)`}
            </div>
          )}
          {!!peso_cargos && (
            <div className="p-1 rounded-md bg-black text-white text-sm text-center">
              Cargos {`(${peso_cargos}%)`}
            </div>
          )}
          {!!peso_kpis && (
            <div className="p-1 rounded-md bg-black text-white text-sm text-center">
              KPIs {`(${peso_kpis}%)`}
            </div>
          )}
          {!!peso_objetivos_cargo && (
            <div className="p-1 rounded-md bg-black text-white text-sm text-center">
              Objetivos por cargo {`(${peso_objetivos_cargo}%)`}
            </div>
          )}
        </div>
      </div>
    </TableRow>
  );
};

function ConfiguracionesList() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const { idEvaluacion } = params;
  const evaluacion = useSelector((state) => state.evaluacion.evaluacion);
  const { estado: estadoEvaluacion } = evaluacion;
  const configuraciones = useSelector(
    (state) => state.evaluacion.configuraciones
  );
  const configuraciones_filter = useSelector(
    (state) => state.evaluacion.configuraciones_filter
  );
  const configuraciones_loading = useSelector(
    (state) => state.evaluacion.configuraciones_loading
  );
  const configuraciones_checked = useSelector(
    (state) => state.evaluacion.configuraciones_checked
  );
  const { nombre = "" } = configuraciones_filter;
  //
  const onEditar = (item) => {
    dispatch(evaluacionActions.setConfiguracion(item));
    navigate(
      `/evaluaciones/${idEvaluacion}/configuracion-formulario/${item.id}`
    );
  };
  const onEliminar = (item) => {
    let deseaEliminar = window.confirm("Desea eliminar el formulario?");
    if (deseaEliminar) {
      dispatch(evaluacionActions.configuracionDelete(item));
    }
  };
  //

  const commonProps = {
    ItemComponent: ItemCommonComponent,
    subject: "formulario",
    subjectGender: "M",
    hasAddBtn: true,
    customAddBtnLabel: "Nuevo formulario",
    noBottomButtom: true,
    selectOptions: [],
  };
  const onChangeSearch = (e) => {
    dispatch(
      evaluacionActions.setConfiguracionesFilter({
        nombre: e.target.value,
      })
    );
  };
  const onCheckHandler = (event, item) => {
    dispatch(
      evaluacionActions.checkConfiguracion({
        configuracion: item,
        checked: event.target.checked,
      })
    );
  };
  const crearConfiguracion = () => {
    dispatch(evaluacionActions.setConfiguracion({}));
    navigate(`/evaluaciones/${idEvaluacion}/configuracion-formulario`);
  };
  const dropdownActions = (id, item = {}) => {
    if (!evaluacionEstaAbierta()) {
      return [];
    }
    return [
      {
        label: "Editar",
        action: () => onEditar(item),
        // className: "text-red-500",
      },
      {
        label: "Eliminar",
        action: () => onEliminar(item),
        className: "text-red-500",
      },
    ];
  };
  const evaluacionEstaAbierta = () => {
    return estadoEvaluacion?.value === ESTADOS_EVALUACION.EN_CURSO;
  };
  //
  useEffect(() => {
    dispatch(evaluacionActions.getConfiguraciones());
  }, []);
  //
  return (
    <div>
      <TableCustom
        {...commonProps}
        tableSecondTitle={"Formularios creados"}
        addBtnClickHandler={
          !!evaluacionEstaAbierta() ? crearConfiguracion : null
        }
        onCheckHandler={onCheckHandler}
        data={configuraciones}
        searchValue={nombre}
        onChangeSearch={onChangeSearch}
        itemsChecked={configuraciones_checked}
        fixedHeigth={true}
        // onSelectChange={onSelectChange}
        dropdownActions={dropdownActions}
        selectOptions={[{ value: "cancel", label: "Cancelar" }]}
        loading={configuraciones_loading}
      />
    </div>
  );
}
export default ConfiguracionesList;
