import axios from "axios";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { deleteCookie, getToken, getUserFromCookie } from "src/utils/helpers";
import { WarningToast } from "./WarningToast";

function Screen({ children }) {
  const location = useLocation();
  const navigate = useNavigate();
  const usuario = getUserFromCookie();
  const token = getToken();

  // axios.defaults.withCredentials = true;

  const tokenInterceptor = () =>
    axios.interceptors.response.use(
      function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response;
      },
      function (err) {
        const originalConfig = err.config;
        if (err.response) {
          const responseStatus = err.response.status;
          //
          if (responseStatus === 403 && !originalConfig._retry) {
            originalConfig._retry = true;
            if (
              err.response.data &&
              err.response.data.code === "token_not_valid"
            ) {
              // Token expired
              deleteCookie("token", "/", ".wel.com.py");
              navigate("/auth/login");
              WarningToast({ message: "Su sesión ha expirado" });
            } else {
              navigate(-1);
            }
          }
          //
          if (err.response.status === 403 && err.response.data) {
            return Promise.reject(err.response.data);
          }
        }
        //
        return Promise.reject(err);
      }
    );

  useEffect(() => {
    if (!token || !usuario.es_admin) {
      navigate("/auth/login");
    }

    if (!!token && location.pathname === "/") {
      navigate("/analisis");
    }
  }, []);

  useEffect(() => {
    tokenInterceptor();
  }, []);

  return <>{children}</>;
}

export default Screen;
