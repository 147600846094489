import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { commonActions } from "src/modules/common/handler/redux";
import { RutasRemuneracion } from "../handlers/routes";
import "./css/styles.css";

import dayjs from "dayjs";
import {
  CalendarFill,
  FileEarmarkExcel,
  GearFill,
  Plus,
  PlusLg,
  TrashFill,
  X,
} from "react-bootstrap-icons";
import { colaboradorActions } from "src/modules/colaborador/modules/colaborador/handlers/redux";
import CollapseCard from "src/modules/common/components/CollapseCard";
import DataTable from "src/modules/common/components/DataTable";
import LoadingSpinButton from "src/modules/common/components/LoadingSpinButton";
import { ProfileInfoCard } from "src/modules/common/components/ProfileInfoCard";
import SelectModal from "src/modules/common/components/SelectModal";
import { addPyTimezoneToISODate } from "src/modules/common/helpers/helpers";
import RemuneracionConfiguracionLayout from "../components/RemuneracionConfiguracionLayout";
import SearchInput from "../components/SearchInput";
import { remuneracionActions } from "../handlers/redux";

const FormInput = ({
  type = "text",
  label,
  required = false,
  // value,
  // onChange,
  containerClass,
  Icon,
  error = false,
  ...props
}) => {
  return (
    <div className={`flex flex-col gap-2 ${containerClass}`}>
      <label className="font-semibold" htmlFor="">
        {label} {!!required && <span className="text-red-500">*</span>}
      </label>
      <div className="flex justify-between items-center border rounded-md p-2 w-full">
        {type !== "date" && type !== "textarea" && (
          <input type={type} className="outline-none w-full" {...props} />
        )}
        {/*  */}
        {type === "textarea" && (
          <textarea className="outline-none w-full" {...props}>
            {props.value}
          </textarea>
        )}
        {/*  */}
        {type === "date" && (
          <DatePicker
            className="placeholder-zinc-600 border-zinc-200 outline-none w-full"
            locale="es"
            {...props}
            dateFormat="dd/MM/yyyy"
          />
        )}
        {/*  */}
        {!!Icon && Icon}
      </div>
      {!!error && <div className="text-red-400 text-sm">{error}</div>}
    </div>
  );
};

const SubFactoresList = ({
  subfactores = [],
  editSubfactorFn,
  addSubfactorFn,
  rmSubfactorFn,
  errores = [],
}) => {
  const sfactorAux = { descripcion: "", relevancia: 1 };
  const onChangeSubFactor = (sfactor, sfidx, field) => (e) => {
    const value = e.target.value;
    let sfactorCopy = { ...sfactor };
    sfactorCopy[field] = value;
    if (!!editSubfactorFn) {
      editSubfactorFn(sfactorCopy, sfidx);
    }
  };
  //
  return (
    <div className="rows flex flex-col gap-4 mt-4">
      <div className="flex flex-col gap-2 border-2x custom-scrollbar max-h-36">
        {subfactores.length > 0 &&
          subfactores.map((sfactor, sfidx) => (
            <div key={`sfactor-item-${sfidx}`}>
              <div className="row flex items-center justify-between gap-2">
                {/* col 1 */}
                <div className="w-full">
                  <input
                    type="text"
                    className="w-full border rounded-md p-1"
                    value={sfactor.descripcion}
                    onChange={onChangeSubFactor(sfactor, sfidx, "descripcion")}
                  />
                </div>
                {/* col 2 */}
                <div className="w-2/12 flex items-center gap-2">
                  <select
                    name={`relevancia-${sfidx}`}
                    id={`relevancia-${sfidx}`}
                    value={sfactor.relevancia}
                    onChange={onChangeSubFactor(sfactor, sfidx, "relevancia")}
                    className="w-full border rounded-md bg-white p-1"
                  >
                    {subfactores.map((sf, sfi) => (
                      <option key={`rel-${sfidx}-opt-${sfi}`} value={sfi + 1}>
                        {sfi + 1}
                      </option>
                    ))}
                  </select>
                  {sfidx > 1 && (
                    <button
                      onClick={rmSubfactorFn(sfidx)}
                      className="border rounded-md p-2"
                    >
                      <TrashFill />
                    </button>
                  )}
                  {sfidx <= 1 && (
                    <button disabled={true} className="border rounded-md p-2">
                      <TrashFill color={"lightgrey"} />
                    </button>
                  )}
                </div>
              </div>
              {!!errores[sfidx] && (
                <div className="text-xs text-red-500">{errores[sfidx]}</div>
              )}
            </div>
          ))}
      </div>
      {subfactores.length < 15 && (
        <div>
          <button onClick={addSubfactorFn} className="flex items-center gap-2">
            <GearFill color={"#0EA5E9"} />
            <span className="text-sky-500">Agregar nuevo subfactor</span>
          </button>
        </div>
      )}
    </div>
  );
};

const libreriaFactoresTemp = [
  {
    id: 1,
    nombre: "Manejo de Idiomas",
    descripcion: "Manejo de Idiomas - Castellano, Inglés y Portugués",
    subfactores: [
      {
        descripcion: "Habla y lee castellano con fluidez.",
        relevancia: 1,
      },
      {
        descripcion:
          "Habla, escribe y lee castellano con fluidez y cuenta con un inglés intermedio en lectura, escritura y conversación.",
        relevancia: 2,
      },
      {
        descripcion:
          "Maneja el castellano y el inglés con total fluidez en lectura, escritura y conversación y cuenta con un portugués avanzado.",
        relevancia: 3,
      },
    ],
  },
  {
    id: 2,
    nombre: "Conocimiento",
    descripcion:
      "El nivel de conocimiento medido en educación formal, requeridos normalmente para un desempeño satisfactorio del cargo",
    subfactores: [
      {
        descripcion: "Educación media concluida (Bachiller)",
        relevancia: 1,
      },
      {
        descripcion: "Formación técnica especializada",
        relevancia: 2,
      },
      {
        descripcion: "Maestrías, especializaciones o postgrados",
        relevancia: 3,
      },
    ],
  },
  {
    id: 3,
    nombre: "Experiencia",
    descripcion:
      "El tiempo de desempeño previo en funciones afines, necesario para que una persona con los conocimientos necesarios, pueda desempeñarse adecuadamente.",
    subfactores: [
      {
        descripcion: "Mayor a 5 años",
        relevancia: 1,
      },
      {
        descripcion: "De 1 año a 3 años",
        relevancia: 2,
      },
      {
        descripcion: "Sin experiencia",
        relevancia: 3,
      },
    ],
  },
];

const FactorForm = ({ factor, onSubmit, setShowLibrerias }) => {
  const dispatch = useDispatch();
  const [factorLocal, setFactorLocal] = useState(factor);
  const [erroresFactor, setErroresFactor] = useState({});
  let { id, nombre = "", descripcion = "", subfactores = [] } = factorLocal;
  const onChangeFactor =
    (field) =>
    ({ target }) => {
      setFactorLocal({ ...factorLocal, [field]: target.value });
    };
  const validateFactor = () => {
    let errores = {};
    const requiredMsg = "Este campo es obligatorio.";
    if (!nombre) {
      errores["nombre"] = requiredMsg;
    }
    if (!descripcion) {
      errores["descripcion"] = requiredMsg;
    }
    if (subfactores.length < 2) {
      errores["subfactores"] = "Debe agregar al menos 2 subfactores";
    } else {
      const erroresSubfactores = [];
      //
      let relevanciasArr = [];
      //
      subfactores.forEach((subfactor, subfidx) => {
        let yaExisteRelevancia = relevanciasArr.includes(
          Number(subfactor.relevancia)
        );
        if (yaExisteRelevancia) {
          erroresSubfactores[subfidx] = "No pueden repetirse relevancias.";
        }
        relevanciasArr.push(Number(subfactor.relevancia));
        //
        if (!subfactor.descripcion) {
          erroresSubfactores[subfidx] = "Debe ingresar una descripción.";
        }
      });
      if (erroresSubfactores.length > 0) {
        errores["subfactoresList"] = erroresSubfactores;
      }
    }
    return errores;
  };
  const onGuardarFactor = () => {
    const erroresCopy = validateFactor();
    //
    if (Object.keys(erroresCopy).length > 0) {
      setErroresFactor(erroresCopy);
      return;
    }
    if (!!onSubmit) {
      onSubmit(factorLocal);
    }
  };
  const editSubfactorFn = (sfactor, sfidx) => {
    const { subfactores = [] } = factorLocal;
    let subfactoresCopy = [...subfactores];
    //
    const findex = subfactoresCopy.findIndex((item, index) => index === sfidx);
    //
    subfactoresCopy[findex] = sfactor;
    //
    setFactorLocal({
      ...factorLocal,
      subfactores: subfactoresCopy,
    });
  };
  const addSubfactorFn = () => {
    const { subfactores = [] } = factorLocal;
    let subfactoresCopy = [...subfactores];
    subfactoresCopy.push({
      descripcion: "",
      relevancia: subfactores.length + 1,
    });
    //
    setFactorLocal({
      ...factorLocal,
      subfactores: subfactoresCopy,
    });
  };
  const rmSubfactorFn = (id) => (e) => {
    const { subfactores = [] } = factorLocal;
    let subfactoresCopy = [...subfactores].filter((sf, sfi) => sfi !== id);
    //
    subfactoresCopy = subfactoresCopy.map((sf, sfi) => ({
      ...sf,
      relevancia: sfi + 1,
    }));
    //
    setFactorLocal({
      ...factorLocal,
      subfactores: subfactoresCopy,
    });
  };
  const onShowLibreriasModal = () => {
    setShowLibrerias(true);
  };
  //
  return (
    <div>
      <FormInput
        label={"Nombre del factor a evaluar"}
        required={true}
        value={nombre}
        onChange={onChangeFactor("nombre")}
        error={erroresFactor?.nombre}
      />
      <button
        onClick={onShowLibreriasModal}
        className="flex items-center gap-2 mt-2"
      >
        <div className="bg-sky-500 rounded-full p-1">
          <PlusLg color={"white"} size={15} />
        </div>
        <span className="text-sky-500 text-sm">
          Seleccionar desde la librería
        </span>
      </button>
      {/*  */}
      <FormInput
        type="textarea"
        containerClass={"mt-4"}
        label={"Descripción"}
        required={true}
        value={descripcion}
        onChange={onChangeFactor("descripcion")}
        error={erroresFactor?.descripcion}
      />
      {/*  */}
      <div className="mt-4">
        <label htmlFor="subfactores" className="font-semibold">
          Subfactores
        </label>
        <div className="text-xs">
          Indique como valor 1 el subfactor con mejor relevancia y un valor
          mayor para aquellos con menor importancia
        </div>
        <SubFactoresList
          subfactores={subfactores}
          editSubfactorFn={editSubfactorFn}
          addSubfactorFn={addSubfactorFn}
          rmSubfactorFn={rmSubfactorFn}
          errores={erroresFactor?.subfactoresList}
        />
        {!!erroresFactor?.subfactores && (
          <div className="text-sm text-red-400">
            {erroresFactor?.subfactores}
          </div>
        )}
      </div>
      <div className="flex mt-4">
        <button
          onClick={onGuardarFactor}
          className="bg-sky-500 text-white rounded-md p-2"
        >
          Guardar factor
        </button>
      </div>
    </div>
  );
};

const LibreriaFactorList = ({
  data = [],
  loading = false,
  selectedFactor,
  setSelectedFactor,
  setShowLibrerias,
}) => {
  const [clickedFactor, setClickedFactor] = useState({});
  const showFactor = (factor) => () => {
    if (factor.id === clickedFactor?.id) {
      setClickedFactor({});
      return;
    }
    setClickedFactor(factor);
  };
  const copyFactor = () => {
    console.log("clickedFactor", clickedFactor);
    const { subfactores = [] } = clickedFactor;
    let subfactoresCopy = JSON.parse(JSON.stringify(subfactores));
    subfactoresCopy = subfactoresCopy
      .map(({ id, ...keepAttrs }, idx) => keepAttrs)
      .map((nitem, nidx) => ({
        ...nitem,
        relevancia: subfactoresCopy.length - nidx,
      }));
    console.log("subfactoresCopy", subfactoresCopy);
    setSelectedFactor({
      ...clickedFactor,
      subfactores: subfactoresCopy,
      peso: 0,
      index: selectedFactor.index,
      nuevo: true,
    });
    setShowLibrerias(false);
  };
  //
  return (
    <div>
      <h3 className="font-semibold text-sm">Competencias disponibles</h3>
      <p>Puede seleccionar una competencia para conocer mas detalles.</p>
      <div className="flex flex-wrap gap-2 mt-2 max-h-80 custom-scrollbar px-3">
        {!loading &&
          data.length > 0 &&
          data.map((factor, fidx) => (
            <div
              key={`factor-item-${fidx}`}
              className="flex flex-col items-start"
            >
              <button
                onClick={showFactor(factor)}
                className={`${
                  clickedFactor?.id === factor.id ? "border border-sky-300" : ""
                } bg-slate-100 rounded-xl px-3 py-1 text-sm`}
              >
                {factor.nombre}
              </button>
              {clickedFactor?.id === factor.id && (
                <div className="text-sm border rounded-md p-4 mt-2">
                  <h3 className="font-semibold mb-2">Descripción</h3>
                  <div className="ml-2">{factor.descripcion}</div>
                  <h3 className="font-semibold my-2">Indicadores</h3>
                  <ul className="ml-8 flex flex-col gap-1 list-disc">
                    {factor.subfactores.map((sf, sfix) => (
                      <li key={`sf-li-${sfix}`}>{sf.descripcion}</li>
                    ))}
                  </ul>
                  <div className="w-full flex justify-end">
                    <button
                      onClick={copyFactor}
                      className="bg-sky-500 text-white text-sm rounded-md px-2 py-1"
                    >
                      Copiar factor
                    </button>
                  </div>
                </div>
              )}
            </div>
          ))}
        {!loading && data.length < 0 && (
          <div className="w-full flex justify-center py-4">
            No existen factores para mostrar
          </div>
        )}
        {!!loading && (
          <div className="w-full flex justify-center py-4">
            <LoadingSpinButton />
          </div>
        )}
      </div>
    </div>
  );
};

function RemuneracionConfiguracion() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const [hasChanged, setHasChanged] = useState(false);
  //
  const escala_salarial = useSelector(
    (state) => state.remuneracion.escala_salarial
  );
  const escala_salarial_loading = useSelector(
    (state) => state.remuneracion.escala_salarial_loading
  );
  const {
    nombre = "",
    fecha_apertura,
    factores = [],
    colaboradores_excluidos = [],
  } = escala_salarial;
  //
  const { idEscala = null } = params;
  const [selectedFactor, setSelectedFactor] = useState(null);
  const [factoresLocal, setFactoresLocal] = useState([]);
  const [errores, setErrores] = useState({});
  //
  const onChange =
    (field) =>
    ({ target }) => {
      dispatch(
        remuneracionActions.setEscalaSalarial({
          ...escala_salarial,
          [field]: target.value,
        })
      );
      setHasChanged(true);
    };

  const onChangeDate = (field) => (date) => {
    //
    let parsedDate = Date.parse(date);
    parsedDate = dayjs(parsedDate).format("YYYY-MM-DD");
    //
    dispatch(
      remuneracionActions.setEscalaSalarial({
        ...escala_salarial,
        [field]: parsedDate, // date,
        fecha_apertura: parsedDate,
      })
    );
    setHasChanged(true);
  };
  //
  const onClickBack = () => {
    let deseaSalir = true;
    if (!!hasChanged) {
      deseaSalir = window.confirm("Desea salir sin guardar los cambios?");
    }
    if (!!deseaSalir) {
      navigate(RutasRemuneracion.ESCALA);
    }
  };
  const onAddFactor = (e) => {
    setSelectedFactor({ peso: 0 });
    dispatch(commonActions.setSelectModal({ open: true }));
  };
  const onEdit = (factor) => (e) => {
    setSelectedFactor(factor);
    dispatch(commonActions.setSelectModal({ open: true }));
  };
  const onDelete = (factor) => {
    let factoresLocalCopy = [...factoresLocal];
    factoresLocalCopy = factoresLocalCopy.filter(
      (item, idx) => idx !== factor.index
    );
    //
    setFactoresLocal(factoresLocalCopy);
  };
  const onChangePeso = (index) => (e) => {
    const value = e.target.value;
    let factoresLocalCopy = JSON.parse(JSON.stringify(factoresLocal));
    factoresLocalCopy[index].peso = Number(value);
    setFactoresLocal(factoresLocalCopy);
  };
  const renderFactoresList = () => {
    return (
      <div>
        {factoresLocal.length > 0 &&
          factoresLocal.map((factor, fidx) => (
            <CollapseCard
              key={`factor-${fidx}`}
              titulo={factor.nombre}
              descripcion={factor.descripcion}
              extraClass={`${fidx !== factoresLocal.length - 1 ? "mb-8" : ""}`}
              titleClass={"font-semibold"}
              initCollapse={false}
              extraEndContent={
                true ? (
                  <div className="flex justify-end items-center gap-2 text-sm">
                    <input
                      // disabled={!isEditing}
                      type="number"
                      value={factor.peso}
                      onChange={onChangePeso(fidx)}
                      className="bg-slate-300x border border-slate-400 outline-none rounded-md p-2 w-7/12"
                    />
                    <div>%</div>
                  </div>
                ) : (
                  ""
                )
              }
            >
              <div>
                <h3 className="font-semibold">Subfactores</h3>
                <ul className="list-disc ml-8 flex flex-col gap-3 my-4">
                  {factor.subfactores.map((sfactor, sfidx) => (
                    <li key={`fsf-li-${sfidx}`}>{sfactor.descripcion}</li>
                  ))}
                </ul>
              </div>
              {/*  */}
              <div className="flex flex-row gap-3">
                <button
                  type="button"
                  className="rounded bg-sky-500 text-white px-3 py-1 text-sm"
                  onClick={onEdit({ ...factor, index: fidx })}
                >
                  Editar
                </button>
                <button
                  type="button"
                  className="rounded bg-red-500 text-white px-3 py-1  text-sm"
                  onClick={() => onDelete({ ...factor, index: fidx })}
                >
                  Eliminar
                </button>
              </div>
            </CollapseCard>
          ))}
        {factoresLocal.length < 1 && (
          <div className="border flex flex-col gap-4 items-center justify-center py-4">
            <FileEarmarkExcel size={36} color={"gray"} />
            <span className="text-sm text-slate-500">
              No existen factores para mostrar
            </span>
          </div>
        )}
      </div>
    );
  };
  const getTotalPesoFactores = () => {
    let total = factoresLocal.reduce((acumulador, factor) => {
      return acumulador + Number(factor.peso);
    }, 0);
    return total;
  };
  const validateForm = () => {
    let erroresCopy = {};
    const requiredMsg = "Este campo es obligatorio.";
    if (!nombre) {
      erroresCopy["nombre"] = requiredMsg;
    }
    if (!fecha_apertura) {
      erroresCopy["fecha_apertura"] = requiredMsg;
    }
    if (factoresLocal.length < 1) {
      erroresCopy["factores"] = "Debe ingresar al menos un factor.";
    } else if (getTotalPesoFactores() !== 100) {
      erroresCopy["factores"] =
        "La suma de los pesos de los factores debe ser igual a 100.";
    }
    return erroresCopy;
  };
  const submitConfiguracion = () => {
    const erroresCopy = validateForm();
    //
    if (Object.keys(erroresCopy).length > 0) {
      setErrores(erroresCopy);
      return;
    }
    //
    const factoresSubmit = factoresLocal.map((fl, fli) => ({
      ...fl,
      id: !!fl.nuevo ? 0 : fl.id,
    }));
    //
    dispatch(
      remuneracionActions.setEscalaSalarial({
        ...escala_salarial,
        factores: factoresSubmit,
        colaboradores_excluidos: excluidosLocal,
      })
    );
    // create or update if ID
    if (!!idEscala) {
      dispatch(remuneracionActions.updateEscalaSalarial());
      setHasChanged(false);
      return;
    }
    dispatch(remuneracionActions.createEscalaSalarial());
    setHasChanged(false);
  };
  const onSaveFactor = (factor) => {
    let factoresLocalCopy = [...factoresLocal];
    if (factor.index >= 0) {
      factoresLocalCopy[factor.index] = factor;
    } else {
      factoresLocalCopy.push(factor);
    }
    dispatch(commonActions.setSelectModal({ open: false }));
    setFactoresLocal(factoresLocalCopy);
    //
    dispatch(
      remuneracionActions.setEscalaSalarial({
        ...escala_salarial,
        factores: factoresLocalCopy,
      })
    );
    setHasChanged(true);
  };
  const [showLibrerias, setShowLibrerias] = useState(false);
  //
  useEffect(() => {
    dispatch(commonActions.setTitle("Admin"));
    dispatch(commonActions.showSidebar(false));
    //
    if (!!idEscala) {
      dispatch(remuneracionActions.getEscalaSalarial(idEscala));
    } else {
      dispatch(remuneracionActions.setEscalaSalarial({}));
    }
    //
    setFactoresLocal(escala_salarial?.factores ?? []);
  }, []);
  useEffect(() => {
    if (!!escala_salarial?.factores) {
      setFactoresLocal(escala_salarial?.factores ?? []);
    }
  }, [escala_salarial?.factores]);
  useEffect(() => {
    if (!!escala_salarial.fecha_apertura) {
      let parsedDate = Date.parse(escala_salarial.fecha_apertura);
      parsedDate = dayjs(parsedDate).format("YYYY-MM-DD");
      dispatch(
        remuneracionActions.setEscalaSalarial({
          ...escala_salarial,
          fecha_apertura_aux: parsedDate,
        })
      );
    }
  }, [escala_salarial?.id]);
  //
  // EXCLUIR COLABORADORES //
  //
  const colaboradores = useSelector(
    (state) => state?.colaborador?.colaboradores
  );
  const colaboradores_filter = useSelector(
    (state) => state?.colaborador?.colaboradores_filter
  );
  const colaboradores_loading = useSelector(
    (state) => state.colaborador.colaboradores_loading
  );
  const { nombre: colaboradorNombre } = colaboradores_filter;
  const { results: colaboradoresResults, count = 0 } = colaboradores;
  const libreriaFactores = useSelector(
    (state) => state.remuneracion.libreria_factores
  );
  const libreriaFactoresLoading = useSelector(
    (state) => state.remuneracion.libreria_factores_loading
  );
  const [excluirColaboradores, setExcluirColaboradores] = useState(false);
  const [excluidosLocal, setExcluidosLocal] = useState([]);
  const [Page, setPage] = useState(1);
  const [PageSize, setPageSize] = useState(10);
  const datatableConfig = {
    headers: [
      {
        title: "Lista de colaboradores",
        contentClass: "font-bold",
        accessor: "nombre",
        contentRenderer: (item) => {
          return (
            <ProfileInfoCard
              image={item.foto_empleado}
              name={
                item.id ? `${item.nombre} ${item.apellido}` : "Sin especificar"
              }
            >
              <div
                title={item.cargo?.nombre || "Sin asignar"}
                className="text-sm w-36 whitespace-nowrap text-ellipsis overflow-hidden"
              >
                {item.cargo?.nombre || "Sin asignar"}
              </div>
            </ProfileInfoCard>
          );
        },
      },
    ],
    rowActions: [
      // {
      //   label: "Editar",
      //   action: (item) => {
      //     navigate(RutasRemuneracion.ESCALA_CONFIGURACION(item.id));
      //   },
      // },
    ],
  };
  const checkExcluirColaboradores = (e) => {
    const checked = e.target.checked;
    setExcluirColaboradores(checked);
    if (!checked) {
      setExcluidosLocal([]);
    }
  };
  const removeExcluido = (index) => () => {
    let excluidosCopy = JSON.parse(JSON.stringify(excluidosLocal));
    excluidosCopy = excluidosCopy.filter((exc, eidx) => eidx !== index);
    setExcluidosLocal(excluidosCopy);
  };
  const onChangePageSize = (e) => {
    const value = e.target.value;
    setPageSize(value);
    setPage(1);
  };
  const onPaginate = (page, pageSize) => {
    //
    dispatch(
      colaboradorActions.setColaboradoresFilter({
        ...colaboradores_filter,
        pagination: {
          page: page,
          pageSize: pageSize,
        },
      })
    );
  };
  const onSearchColaborador = (event) => {
    dispatch(
      colaboradorActions.setColaboradoresFilter({ nombre: event.target.value })
    );
  };
  const onCheckColaborador = (colaborador) => (e) => {
    const { nombre = "", apellido = "" } = colaborador;
    const isChecked = e.target.checked;
    let excluidosCopy = JSON.parse(JSON.stringify(excluidosLocal));
    excluidosCopy = excluidosCopy.filter(
      (ei, eidx) => ei.colaborador_id !== colaborador.id
    );
    //
    if (!!isChecked) {
      excluidosCopy.push({
        ...colaborador,
        colaborador_id: colaborador.id,
        nombre: `${nombre} ${apellido}`,
      });
    }
    setExcluidosLocal(excluidosCopy);
  };
  useEffect(() => {
    // TODO comprobar si hay excluidos en escala al ingresar para set true (chequear) excluirColaboradores
    // se puede pasar a otro useEffect
    if (colaboradores_excluidos.length > 0) {
      setExcluirColaboradores(true);
      setExcluidosLocal(
        colaboradores_excluidos.map((ce, cei) => ({
          ...ce,
          // colaborador_id: ce.id,
        }))
      );
    }
  }, [colaboradores_excluidos]);
  useEffect(() => {
    if (!!excluirColaboradores) {
      if (colaboradoresResults.length < 1) {
        dispatch(
          colaboradorActions.setColaboradoresFilter({
            ...colaboradores_filter,
            pagination: {
              page: 1,
              pageSize: 10,
            },
          })
        );
      }
    }
  }, [excluirColaboradores]);
  useEffect(() => {
    if (!!showLibrerias && libreriaFactores.length < 1) {
      dispatch(remuneracionActions.getFactoresBase());
    }
  }, [showLibrerias]);
  //
  return (
    <RemuneracionConfiguracionLayout
      title={"Valoración por cargos"}
      onClickBack={onClickBack}
      currentStep={0}
    >
      <div className="py-2 px-8 -mx-8">
        {!escala_salarial_loading && (
          <div className="form flex justify-center mt-4">
            <div className="flex flex-col gap-4 w-full md:w-8/12">
              <div className="border-b pb-2">
                <h2 className="font-semibold text-lg">Configuración inicial</h2>
              </div>
              <div></div>
              <FormInput
                label={"Nombre de la escala:"}
                onChange={onChange("nombre")}
                value={nombre}
                error={errores?.nombre}
              />
              <FormInput
                type={"date"}
                selected={Date.parse(
                  addPyTimezoneToISODate(escala_salarial?.fecha_apertura_aux)
                )}
                onChange={onChangeDate("fecha_apertura_aux")}
                containerClass={"w-full md:w-1/2"}
                label={" Activa desde:"}
                Icon={<CalendarFill />}
                error={errores?.fecha_apertura}
              />
              <div className="border-b pb-2 mt-8">
                <h2 className="font-semibold text-lg">
                  Factores a ser evaluados en cargos
                </h2>
              </div>
              {/* FACTORES */}
              {renderFactoresList()}
              <button onClick={onAddFactor} className="flex items-center gap-2">
                <span className="bg-sky-500 p-0.5 rounded-full">
                  <Plus color={"white"} size={18} />
                </span>
                <span className="text-sky-500 text-sm">
                  Agregar nuevo factor
                </span>
              </button>
              {/* EXCLUIR COLABORADORES */}
              <div className="border rounded-md p-4">
                <div className="py-2 border-b">
                  <h2 className="font-bold">
                    Excluir colaboradores de la escala salarial
                  </h2>
                </div>
                <div className="mt-4">
                  <div>
                    <label
                      className="flex gap-2"
                      htmlFor="excluir-colaboradores"
                    >
                      <input
                        className="w-5 h-5 mt-1"
                        type="checkbox"
                        name="excluir-colaboradores"
                        id="excluir-colaboradores"
                        checked={excluirColaboradores}
                        onClick={checkExcluirColaboradores}
                      />
                      <div>
                        <span className="font-semibold text-sm">
                          Excluir colaboradores
                        </span>
                        <p className="text-slate-500 text-sm">
                          Al ser excluidos, estos colaboradores no se mostrarán
                          en la tabla de reporte comparativo.
                        </p>
                      </div>
                    </label>
                  </div>
                </div>
                {!!excluirColaboradores && (
                  <>
                    <div className="mt-8">
                      <h2 className="font-bold mb-4">
                        Colaboradores Seleccionados
                      </h2>
                      {/* PILLS DE COLABORADORES EXCLUIDOS */}
                      <div className="flex flex-wrap gap-2 mt-2">
                        {excluidosLocal.length > 0 &&
                          excluidosLocal.map((colaborador, cidx) => (
                            <div
                              key={`pill-${cidx}`}
                              className="flex items-center gap-3 py-1 px-3 bg-slate-900 rounded-2xl"
                            >
                              <span className="text-white text-xs">
                                {colaborador.nombre}
                              </span>
                              <button onClick={removeExcluido(cidx)}>
                                <X size={24} color={"white"} />
                              </button>
                            </div>
                          ))}
                        {excluidosLocal.length < 1 && (
                          <div className="text-sm p-2 bg-slate-100 rounded-md">
                            No se han seleccionado colaboradores.
                          </div>
                        )}
                      </div>
                    </div>
                    {/* FILTROS TABLA */}
                    <div className="mt-4 flex items-center gap-4">
                      <SearchInput
                        value={colaboradorNombre}
                        onChange={onSearchColaborador}
                      />
                      <div className="flex gap-2 items-center">
                        <label className="font-semibold" htmlFor="pageSize">
                          Mostrar
                        </label>
                        <select
                          name="pageSize"
                          id="pageSize"
                          className="border rounded-md p-2"
                          onChange={onChangePageSize}
                        >
                          <option value="10" selected>
                            10
                          </option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                        </select>
                      </div>
                    </div>
                    {/* TABLA COLABORADRES */}
                    <div className="mt-4">
                      <div className="border-b pb-2">
                        <h2 className="font-bold">Lista de colaboradores</h2>
                      </div>
                      <div>
                        <DataTable
                          containerClass={"my-8x"}
                          data={colaboradoresResults}
                          loading={colaboradores_loading}
                          config={datatableConfig}
                          noHeaders={true}
                          // pagination
                          totalRecords={count}
                          onPaginate={onPaginate}
                          outerPageSize={PageSize}
                          // check config
                          checkable={true}
                          // onCheckAll={onCheckAll}
                          checkedItems={excluidosLocal}
                          checkedIdField={"colaborador_id"}
                          onItemCheck={onCheckColaborador}
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>
              {/* total y errores */}
              <div className="flex flex-col gap-8">
                {!!errores?.factores && (
                  <div className="text-sm flex border border-red-300 px-2 py-3 text-red-400 bg-pink-100 rounded-md">
                    {errores?.factores}
                  </div>
                )}
                <div className="flex items-center justify-end">
                  <span className="font-semibold text-xl">
                    Total peso: {`${getTotalPesoFactores()}/100`}
                  </span>
                </div>
              </div>
              {/*  */}
              <button
                onClick={submitConfiguracion}
                className="bg-sky-500 text-white text-sm w-full py-3 rounded-md mt-4"
              >
                Iniciar valoración de cargos
              </button>
            </div>
          </div>
        )}
        {!!escala_salarial_loading && (
          <div className="flex justify-center items-center w-full h-80">
            <LoadingSpinButton
              className="justify-center"
              message="Enviando, por favor espere..."
            ></LoadingSpinButton>
          </div>
        )}
        {/*  */}
        <SelectModal
          title={"Factor a evaluar"}
          extraClass={"w-6/12 px-4"}
          goBackFn={!!showLibrerias ? () => setShowLibrerias(false) : null}
          onClose={() => setShowLibrerias(false)}
          content={
            !!showLibrerias ? (
              <LibreriaFactorList
                data={
                  libreriaFactores.length > 0
                    ? libreriaFactores
                    : libreriaFactoresTemp
                }
                loading={libreriaFactoresLoading}
                selectedFactor={selectedFactor}
                setSelectedFactor={setSelectedFactor}
                setShowLibrerias={setShowLibrerias}
              />
            ) : (
              <FactorForm
                factor={selectedFactor}
                onSubmit={onSaveFactor}
                setShowLibrerias={setShowLibrerias}
              />
            )
          }
        ></SelectModal>
      </div>
    </RemuneracionConfiguracionLayout>
  );
}

export default RemuneracionConfiguracion;
