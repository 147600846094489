import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { DotMenu, DotMenuItem } from "src/modules/common/components/DotMenu";
import { kpiActions } from "../handlers/redux";

export const KpiTableAccionMenu = ({ item = {} }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const kpis = useSelector((state) => state?.kpi?.kpis);

  const onEdit = (id) => {
    let item = kpis.results.find((d) => d.id == id);
    dispatch(kpiActions.kpiGet(item));
    navigate(`/kpis/configuracion/${item.id}`);
  };

  const onDelete = () => {
    let deseaEliminar = window.confirm("Desea eliminar el KPI seleccionado?");
    if (deseaEliminar) {
      // @ts-ignore
      dispatch(kpiActions.kpiDelete({ id: item?.id }));
    }
  };

  return (
    <DotMenu
      dir="left"
      className="w-10 h-6  flex flex-row justify-center items-center"
    >
      <>
        <DotMenuItem onClick={() => onEdit(item?.id)}>Editar</DotMenuItem>
        <DotMenuItem onClick={onDelete}>Eliminar</DotMenuItem>
      </>
    </DotMenu>
  );
};
