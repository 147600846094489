import React from "react";
import { BeneficiosLayout } from "../components/BeneficiosLayout";
import {BeneficiosPage} from '../pages/BeneficiosPage'
import { BeneficioConfiguracion } from "../pages/BeneficioConfiguracion";
import { BeneficioConfiguracionVista } from "../pages/BeneficioConfiguracionVista";
import { BeneficiosCargosPage } from "../pages/BeneficiosCargosPage";
import { BeneficiosColaboradoresPage } from "../pages/BeneficiosColaboradoresPage";
import { BeneficiosCargoList } from "../pages/BeneficiosCargoList";
import { BeneficioColaboradorDetail } from "../pages/BeneficioColaboradorDetail";

export const beneficiosRoutes = [
    {
        path: "/beneficios/",
        element: <BeneficiosPage/> //[0]
    },
    {
        path: "/beneficios/configuracion/",
        element: <BeneficioConfiguracion/> //[1]
    },
    {
        path: "/beneficios/configuracion/:idBeneficio/",
        element: <BeneficioConfiguracion/>  //[2]
    },
    {
        path: "/beneficios/view/:idBeneficio/",
        element: <BeneficioConfiguracionVista/> //[3]
    },
    {
        path: "/beneficios/cargos/",
        element: <BeneficiosCargosPage/> //[4]
    },
    {
        path: "/beneficios/cargos/:idCargo",
        element: <BeneficiosCargoList/> //[5]
    },
    {
        path: "/beneficios/colaboradores/",
        element: <BeneficiosColaboradoresPage/> //[6]
    },
    {
        path: "/beneficios/colaboradores/:idColaborador/",
        element: <BeneficioColaboradorDetail/> //[7]
    },
]

export const beneficiosRoutesWindow = {
    BENEFICIOS: beneficiosRoutes[0].path,
    CONFIG: beneficiosRoutes[1].path,
    CARGOS: beneficiosRoutes[4].path,
    COLABORADORES: beneficiosRoutes[6].path,
}