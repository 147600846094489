import React from "react";
import { Route, Routes } from "react-router-dom";
import ColaboradorAccesoPage from "../modules/acceso/pages/ColaboradorAccesoPage";
import ColaboradorGeneralPage from "../modules/colaborador/pages/ColaboradorGeneralPage";
import ColaboradorListPage from "../modules/colaborador/pages/ColaboradorListPage";
import ColaboradorPage from "../modules/colaborador/pages/ColaboradorPage";
import ImportacionPage from "../modules/colaborador/pages/ImportacionPage";
import ImportacionGeneralPage from "../modules/colaborador/pages/ImportacionGeneralPage";
import ColaboradorDesempenoPage from "../modules/desempeno/pages/ColaboradorDesempenoPage";
import ColaboradorKpisPage from "../modules/kpi/pages/ColaboradorKpisPage";
import ColaboradorLaboralPage from "../modules/laboral/pages/ColaboradorLaboralPage";
import ColaboradorPlanCarreraPage from "../modules/plan/pages/ColaboradorPlanCarreraPage";
import ColaboradorRemuneracionPage from "../modules/remuneracion/pages/ColaboradorRemuneracionPage";
import { ColaboradorObjetivosPage } from "../modules/objetivos/pages/ColaboradorObjetivosPage";

export const colaboradorRoutes = [
  {
    path: "/nomina",
    element: <ColaboradorListPage />,
  },
  {
    path: "/nomina/colaborador/*",
    element: <ColaboradorPage />,
  },
  {
    path: "/nomina/colaborador/:idColaborador/*",
    element: <ColaboradorPage />,
  },
  {
    path: "/nomina/importador",
    element: <ImportacionPage />,
  },
  {
    path: "/nomina/importador-general",
    element: <ImportacionGeneralPage />,
  },
];

export const ColaboradorRootRoutes = () => {
  return (
    <Routes>
      <Route path={"/"} element={<ColaboradorGeneralPage />} />
      <Route path={"/laboral"} element={<ColaboradorLaboralPage />} />
      <Route path={"/acceso"} element={<ColaboradorAccesoPage />} />
      <Route path={"/kpis"} element={<ColaboradorKpisPage />} />
      <Route path={"/objetivos"} element={<ColaboradorObjetivosPage />} />
      <Route path={"/desempeno"} element={<ColaboradorDesempenoPage />} />
      <Route path={"/plancarrera"} element={<ColaboradorPlanCarreraPage />} />
      <Route path={"/remuneracion"} element={<ColaboradorRemuneracionPage />} />
    </Routes>
  );
};
