import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ReactSelect from "react-select";
import { BuscarInput } from "src/modules/common/components/BuscarInput";
import { PrimaryButton } from "src/modules/common/components/PrimaryButton";
import { kpiActions } from "../handlers/redux";

export const ColaboradorAsignarToolbar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const colaboradores_checked = useSelector(
    (state) => state.kpi.colaboradores_checked
  );

  const kpis_checked = useSelector((state) => state.kpi.kpis_checked);

  const onClickNew = () => {
    dispatch(
      kpiActions.setAsignarKpisColaboradores({
        kpis_ids: kpis_checked,
        colaboradores_ids: colaboradores_checked,
      })
    );
  };

  const onChange = (v) => {
    if (v.value == "cancel") {
      dispatch(kpiActions.resetCheckColaboradores());
    } else {
      // @ts-ignore
      window.alert(JSON.stringify(v));
    }
  };
  const colaboradores_filter = useSelector(
    (state) => state.kpi.colaboradores_filter
  );
  const { nombre = "" } = colaboradores_filter;
  const onChangeText = (event) => {
    !!process.env.REACT_APP_DEBUG &&
      console.info("onChangeText", event.target.value);
    dispatch(
      kpiActions.setColaboradoresFilter({
        nombre: event.target.value,
      })
    );
  };

  return (
    <>
      <div className="flex flex-row gap-2">
        <BuscarInput onChange={onChangeText} value={nombre}></BuscarInput>
        <ReactSelect
          className="w-52 h-10 bg-blue-200 inline-grid"
          value={{
            value: "",
            label: "(" + colaboradores_checked.length + ") seleccionados",
          }}
          options={[{ value: "cancel", label: "Cancelar" }]}
          onChange={onChange}
        ></ReactSelect>
        <PrimaryButton onClick={onClickNew}>Agregar</PrimaryButton>
      </div>
    </>
  );
};
