import { createSlice } from "@reduxjs/toolkit";

const departamentoSlice = createSlice({
  name: "Departamento",
  initialState: {
    departamento_create_loading: false,
    departamento_update_loading: false,
    departamento_delete_loading: false,
    departamento: {},
    departamentos: { result: [] },
    departamentos_checked: [],
    departamentos_filter: {},
    departamentos_loading: false,
    departamentos_delete: [],
    departamento_create_modal: { open: false },
    //
    fileToUpload: null,
    datosVerificados: {},
    nuevosChecked: [],
    duplicadosChecked: [],
    datosImportar: [],
    importar_loading: false,
  },
  reducers: {
    setFileToUpload: (state, { payload = {} }) => {
      !!process.env.REACT_APP_DEBUG && console.info("setFileToUpload redux");
      state.fileToUpload = payload;
    },
    setDatosVerificados: (state, { payload = {} }) => {
      !!process.env.REACT_APP_DEBUG &&
        console.info("setDatosVerificados redux");
      state.datosVerificados = payload;
    },
    setDatosImportar: (state, { payload = {} }) => {
      !!process.env.REACT_APP_DEBUG && console.info("setDatosImportar redux");
      state.datosImportar = payload;
    },
    checkNuevo: (state, { payload: { item, checked = true } }) => {
      !!process.env.REACT_APP_DEBUG &&
        console.info("checkNuevo redux", item, checked);
      let temp = state.nuevosChecked.filter((nc) => nc.id !== item.id);
      !!process.env.REACT_APP_DEBUG && console.info("temp", temp);
      state.nuevosChecked = temp;
      if (!!checked) {
        state.nuevosChecked.push(item);
      }
      // state.kpiColaboradores_loading = false;
    },
    resetCheckNuevos: (state) => {
      state.nuevosChecked = [];
    },
    checkDuplicado: (state, { payload: { item, checked = true } }) => {
      !!process.env.REACT_APP_DEBUG &&
        console.info("checkDuplicado redux", item, checked);
      let temp = state.duplicadosChecked.filter((dc) => dc.id !== item.id);
      state.duplicadosChecked = temp;
      if (!!checked) {
        state.duplicadosChecked.push(item);
      }
      // state.kpiColaboradores_loading = false;
    },
    resetCheckDuplicados: (state) => {
      state.duplicadosChecked = [];
    },
    importarDatosComplete: (state, { payload = {} }) => {
      state.nuevosChecked = [];
      state.duplicadosChecked = [];
      state.datosVerificados = [];
      state.datosImportar = [];
      state.importar_loading = false;
    },
    //
    departamentoSet: (state, { payload = {} }) => {
      state.departamento = payload;
    },
    setDepartamentos: (state, { payload }) => {
      state.departamentos = payload.departamentos;
      state.departamentos_loading = false;
    },
    setDepartamentosFilter: (state, { payload = {} }) => {
      state.departamentos_filter = payload;
    },
    setDepartamentosDelete: (state, { payload }) => {
      state.departamentos_delete = payload;
    },
    resetDepartamentosDelete: (state, { payload }) => {
      state.departamentos_delete = [];
    },
    //
    resetCheckDepartamentos: (state) => {
      state.departamentos_checked = [];
      state.departamentos_loading = false;
    },
    checkDepartamento: (
      state,
      { payload: { departamento, checked = true } }
    ) => {
      state.departamentos_checked = state.departamentos_checked.filter(
        (id) => id != departamento.id
      );
      if (!!checked) {
        state.departamentos_checked.push(departamento.id);
      }
      state.departamentos_loading = false;
    },
    // create
    setDepartamentoCreateModal: (state, { payload = {} }) => {
      !!process.env.REACT_APP_DEBUG && console.log({ payload });
      state.departamento_create_modal = payload;
    },
    departamentoCreate: (state, { payload = {} }) => {
      state.departamento_create_loading = true;
    },
    departamentoCreateSuccess: (state) => {
      state.departamento_create_loading = false;
    },
    // update
    departamentoUpdate: (state, { payload = {} }) => {
      state.departamento_update_loading = true;
    },
    departamentoUpdateSuccess: (state) => {
      state.departamento_update_loading = false;
    },
    // delete
    departamentoDelete: (state, { payload = {} }) => {
      state.departamento_delete_loading = true;
    },
    departamentoDeleteSuccess: (state) => {
      state.departamento_delete_loading = false;
    },
    // list
    departamentos: (state, { payload }) => {
      state.departamentos_loading = true;
    },
  },
});

export const departamentoActions = departamentoSlice.actions;
export const departamentoReducer = departamentoSlice.reducer;
