import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import DataTable from "src/modules/common/components/DataTable";
import { cargoActions } from "src/modules/estructura/modules/cargo/handlers/redux";
import SearchInput from "src/modules/remuneracion/components/SearchInput";

function CargosTable({ onSubmit, categoriaForm }) {
  const dispatch = useDispatch();

  // @ts-ignore
  const cargos = useSelector((state) => state.cargo.cargos);
  const cargos_filter = useSelector((state) => state.cargo.cargos_filter);
  const { nombre = "" } = cargos_filter;
  const loading = useSelector((state) => state.cargo.cargos_loading);
  const cargos_checked = useSelector((state) => state.cargo.cargos_checked);
  const { results = [] } = cargos;
  //
  const datatableConfig = {
    headers: [
      {
        title: "Lista de cargos",
        contentClass: "px-2 py-4",
        // onOrder: setOrdering("cargo__nombre"),
        accessor: "nombre",
      },
    ],
    rowActions: [
      //   {
      //     label: "Eliminar",
      //     action: (item) => {
      //     },
      //   },
    ],
  };
  const onChangeText = (event) => {
    dispatch(cargoActions.setCargosFilter({ nombre: event.target.value }));
  };
  const onPreSubmit = () => {
    let alreadyChecked = categoriaForm.cargos.map(
      (carg, caridx) => carg.cargo.id
    );
    const newCargos = [...alreadyChecked, ...cargos_checked];
    // return;
    if (!!onSubmit) {
      onSubmit(newCargos);
      dispatch(cargoActions.resetCheckCargos());
    }
  };
  const onCheckAll = () => {
    if (cargos_checked.length > 0) {
      dispatch(cargoActions.resetCheckCargos());
    } else {
      dispatch(cargoActions.setCheckCargos(results.map((r, ri) => r.id)));
    }
  };
  //
  useEffect(() => {
    if (results.length < 1) {
      dispatch(cargoActions.cargos());
    }
  }, []);
  //
  return (
    <div>
      <SearchInput
        placeholder="Buscar cargo..."
        value={nombre}
        onChange={onChangeText}
      />
      <div className="custom-scrollbar overflow-auto max-h-[400px] mt-4">
        <DataTable
          containerClass={"my-8x"}
          data={results}
          loading={false}
          config={datatableConfig}
          noHeaders={false}
          totalRecords={results.length}
          outerPageSize={999}
          checkedItems={cargos_checked.map((cc, cci) => ({ id: cc }))}
          onItemCheck={(item) => (e) => {
            dispatch(
              cargoActions.checkCargo({
                cargo: item,
                checked: e.target.checked,
              })
            );
          }}
          onCheckAll={onCheckAll}
        />
      </div>
      <div className="w-full mt-4">
        <button
          onClick={onPreSubmit}
          className="w-full bg-sky-500 hover:bg-sky-400 text-white py-4 px-4 rounded-md text-sm"
        >
          Agregar cargos
        </button>
      </div>
    </div>
  );
}

export default CargosTable;
