import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import SearchSelect from "src/modules/common/components/SearchSelect";
import { departamentoActions } from "src/modules/estructura/modules/departamento/handlers/redux";
import { evaluacionActions } from "../handlers/redux";

function DepartamentoEvaluacionSelect() {
  const dispatch = useDispatch();
  const params = useParams();

  const departamentos_results = useSelector(
    (state) => state.departamento.departamentos
  );
  const { results: departamentos } = departamentos_results;
  const departamentos_filter = useSelector(
    (state) => state.departamento.departamentos_filter
  );
  const { nombre } = departamentos_filter;
  const filterDepartamentos = (nombre) => {
    dispatch(departamentoActions.setDepartamentosFilter({ nombre: nombre }));
  };
  const { idEvaluacion } = params;
  const formularios_filter = useSelector(
    (state) => state.evaluacion.formularios_filter
  );
  const onSelectItem = (departamento) => {
    dispatch(
      evaluacionActions.setFormulariosFilter({
        ...formularios_filter,
        id: idEvaluacion,
        departamento: departamento,
      })
    );
  };
  //
  useEffect(() => {
    // dispatch(departamentoActions.departamentos());
  }, []);
  //
  return (
    <SearchSelect
      data={departamentos}
      defaultText={"Departamentos: Todos"}
      searchValue={nombre}
      filterFunction={filterDepartamentos}
      onSelectItem={onSelectItem}
    ></SearchSelect>
  );
}
export default DepartamentoEvaluacionSelect;
