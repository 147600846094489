import { createSlice } from "@reduxjs/toolkit";

const planCarreraSlice = createSlice({
  name: "plan_carrera",
  initialState: {
    planes_carrera: [],
    planes_carrera_count: 0,
    planes_carrera_loading: false,
    planes_carrera_filter: {},
    planes_carrera_checked: [],
    //
    plan_carrera: {},
    plan_carrera_loading: false,
    // Requisitos
    cargos: [],
    cargos_count: 0,
    cargos_loading: false,
    cargos_filter: {},
    cargos_checked: [],
    //
    cargo: {},
    cargo_loading: false,
  },
  reducers: {
    getPlanesCarrera: (state, { payload = {} }) => {
      state.planes_carrera_loading = true;
    },
    setPlanesCarrera: (state, { payload = {} }) => {
      state.planes_carrera = payload;
    },
    setPlanesCarreraCount: (state, { payload = {} }) => {
      state.planes_carrera_count = payload;
    },
    setPlanesCarreraFilter: (state, { payload = {} }) => {
      state.planes_carrera_filter = payload;
    },
    setPlanesCarreraComplete: (state, { payload = {} }) => {
      state.planes_carrera_loading = false;
    },
    setCheckPlanesCarrera: (state, { payload = {} }) => {
      state.planes_carrera_checked = payload;
    },
    resetCheckPlanesCarrera: (state) => {
      state.planes_carrera_checked = [];
    },
    checkPlanesCarrera: (
      state,
      { payload: { planCarrera, checked = true } }
    ) => {
      state.planes_carrera_checked = state.planes_carrera_checked.filter(
        (plan) => plan.id !== planCarrera.id
      );
      if (!!checked) {
        state.planes_carrera_checked.push(planCarrera);
      }
    },
    //
    getPlanCarrera: (state, { payload = {} }) => {
      state.plan_carrera_loading = true;
    },
    setPlanCarrera: (state, { payload = {} }) => {
      state.plan_carrera = payload;
    },
    createPlanCarrera: (state, { payload = {} }) => {
      state.plan_carrera_loading = true;
    },
    updatePlanCarrera: (state, { payload = {} }) => {
      state.plan_carrera_loading = true;
    },
    createPlanCarreraComplete: (state, { payload = {} }) => {
      state.plan_carrera_loading = false;
    },
    //
    deletePlanCarrera: (state, { payload = {} }) => {},
    bulkDeletePlanesCarrera: (state, { payload = {} }) => {},
    // CATEGORIA
    addCategoria: (state, { payload = {} }) => {
      // { id, categoria }
    },
    deleteCategoria: (state, { payload = {} }) => {
      // { id, categoriaId }
    },
    updateCategoria: (state, { payload = {} }) => {
      // { id, categoriaId, updatedCategoria }
    },
    updateCategoriaCargos: (state, { payload = {} }) => {
      // { id, categoriaId, cargosIds }
    },
    // REQUISITOS
    getCargos: (state, { payload = {} }) => {
      state.cargos_loading = true;
    },
    setCargos: (state, { payload = {} }) => {
      state.cargos = payload;
    },
    getCargosComplete: (state, { payload = {} }) => {
      state.cargos_loading = false;
    },
    setCargosCount: (state, { payload = {} }) => {
      state.cargos_count = payload;
    },
    setCargosFilter: (state, { payload = {} }) => {
      state.cargos_filter = payload;
    },
    setCargosChecked: (state, { payload = {} }) => {
      console.log("setCargosChecked", payload);
      state.cargos_checked = payload;
    },
    resetCargosChecked: (state) => {
      state.cargos_checked = [];
    },
    checkCargos: (state, { payload: { cargo, checked = true } }) => {
      state.cargos_checked = state.cargos_checked.filter(
        (c) => c.id !== cargo.id
      );
      if (!!checked) {
        state.cargos_checked.push(cargo);
      }
    },
    getCargo: (state, { payload = {} }) => {
      state.cargo_loading = true;
    },
    setCargo: (state, { payload = {} }) => {
      state.cargo = payload;
    },
    getCargoComplete: (state, { payload = {} }) => {
      state.cargo_loading = false;
    },
    //
    borrarRequisitosCargo: (state, { payload = {} }) => {},
    borrarRequisitosCargos: (state, { payload = {} }) => {},
    updateRequisitosCargo: (state, { payload = {} }) => {
      state.cargo_loading = true;
    },
  },
});

export const planCarreraActions = planCarreraSlice.actions;
export const planCarreraReducer = planCarreraSlice.reducer;
