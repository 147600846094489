import { createSlice } from "@reduxjs/toolkit";

const remuneracionSlice = createSlice({
  name: "remuneracion",
  initialState: {
    escala_reporte: {},
    escala_reporte_loading: false,
    escala_reporte_filter: {},
    //
    colaboradores_escala: {},
    colaboradores_escala_loading: false,
    colaboradores_escala_filter: {},
    // /
    colaboradores_comparativo: {},
    colaboradores_comparativo_loading: false,
    colaboradores_comparativo_filter: {},
    //
    libreria_factores: [],
    libreria_factores_filter: {},
    libreria_factores_loading: false,
    //
    escala_salarial: {},
    escala_salarial_loading: false,
    escala_configuracion: {},
    escala_configuracion_loading: false,
    escalas_salariales_ids: [],
    //
    escalas_salariales: [],
    escalas_salariales_checked: [],
    escalas_salariales_filter: {},
    escalas_salariales_loading: false,
    //
    valoraciones_cargos: [],
    valoraciones_cargos_filter: {},
    valoraciones_cargos_loading: false,
    //
    categorias: [],
    categorias_filter: {},
    categorias_loading: false,
    //
    salarios_colaboradores: {},
    salarios_colaboradores_filter: {},
    salarios_colaboradores_loading: false,
    salario_colaborador_editar_loading: false,
    //
    salarios_mercado: [],
    salarios_mercado_filter: {},
    salarios_mercado_loading: false,
    salario_mercado_editar_loading: false,
    //
    fileToImportar: null,
    fileToImportarResponse: null,
    fileToImportarMercadoResponse: null,
    importar_loading: false,
    //
    activar_escala_loading: false,
  },
  reducers: {
    getEscalaReporte: (state, { payload = {} }) => {
      state.escala_reporte_loading = true;
    },
    setEscalaReporte: (state, { payload = {} }) => {
      state.escala_reporte = payload;
      state.escala_reporte_loading = false;
    },
    setEscalaReporteFilter: (state, { payload = {} }) => {
      state.escala_reporte_filter = payload;
      state.escala_reporte_loading = true;
    },
    setEscalaReporteComplete: (state, { payload = {} }) => {
      state.escala_reporte_loading = false;
    },
    //
    getColaboradoresEscala: (state, { payload = {} }) => {
      state.colaboradores_escala_loading = true;
    },
    setColaboradoresEscala: (state, { payload = {} }) => {
      state.colaboradores_escala = payload;
      state.colaboradores_escala_loading = false;
    },
    setColaboradoresEscalaFilter: (state, { payload = {} }) => {
      state.colaboradores_escala_filter = payload;
      state.colaboradores_escala_loading = true;
    },
    setColaboradoresEscalaComplete: (state, { payload = {} }) => {
      state.colaboradores_escala_loading = false;
    },
    // /
    getColaboradoresComparativo: (state, { payload = {} }) => {
      state.colaboradores_comparativo_loading = true;
    },
    setColaboradoresComparativo: (state, { payload = {} }) => {
      state.colaboradores_comparativo = payload;
      state.colaboradores_comparativo_loading = false;
    },
    setColaboradoresComparativoFilter: (state, { payload = {} }) => {
      state.colaboradores_comparativo_filter = payload;
      state.colaboradores_comparativo_loading = true;
    },
    setColaboradoresComparativoComplete: (state, { payload = {} }) => {
      state.colaboradores_comparativo_loading = false;
    },
    //
    activarEscalaSalarial: (state, { payload = {} }) => {
      state.activar_escala_loading = true;
    },
    activarEscalaSalarialComplete: (state, { payload = {} }) => {
      state.activar_escala_loading = false;
    },
    getFactoresBase: (state, { payload = {} }) => {
      state.libreria_factores_loading = true;
    },
    setFactoresBase: (state, { payload = {} }) => {
      state.libreria_factores = payload;
    },
    getFactoresBaseComplete: (state, { payload = {} }) => {
      state.libreria_factores_loading = false;
    },
    resetEscalaData: (state, { payload = {} }) => {
      state.escala_salarial = {};
      state.escala_configuracion = {};
      state.valoraciones_cargos = [];
      state.categorias = [];
    },
    setEscalasIdsDelete: (state, { payload = {} }) => {
      state.escalas_salariales_ids = payload;
    },
    setEscalaSalarial: (state, { payload = {} }) => {
      state.escala_salarial = payload;
      state.escala_salarial_loading = false;
    },
    getEscalaSalarial: (state, { payload = {} }) => {
      state.escala_salarial_loading = true;
    },
    createEscalaSalarial: (state, { payload = {} }) => {
      state.escala_salarial_loading = true;
    },
    updateEscalaSalarial: (state, { payload = {} }) => {
      state.escala_salarial_loading = true;
    },
    createEscalaSalarialComplete: (state, { payload = {} }) => {
      state.escala_salarial_loading = false;
    },
    updateEscalaSalarialComplete: (state, { payload = {} }) => {
      state.escala_salarial_loading = false;
    },
    //
    getEscalaConfiguracion: (state, { payload = {} }) => {
      state.escala_configuracion_loading = true;
    },
    setEscalaConfiguracion: (state, { payload = {} }) => {
      state.escala_configuracion = payload;
      state.escala_configuracion_loading = false;
    },
    updateEscalaConfiguracion: (state, { payload = {} }) => {
      state.escala_configuracion_loading = true;
    },
    updateEscalaConfiguracionComplete: (state, { payload = {} }) => {
      state.escala_configuracion_loading = false;
    },
    //
    getEscalasSalariales: (state, { payload = {} }) => {
      state.escalas_salariales_loading = true;
    },
    setEscalasSalarialesFilter: (state, { payload = {} }) => {
      state.escalas_salariales_filter = payload;
      state.escalas_salariales_loading = true;
    },
    setEscalasSalariales: (state, { payload = {} }) => {
      state.escalas_salariales = payload;
      state.escalas_salariales_loading = false;
      state.escalas_salariales_ids = [];
    },
    resetEscalasSalarialesChecked: (state) => {
      state.escalas_salariales_checked = [];
    },
    setEscalasSalarialesChecked: (state, { payload = {} }) => {
      state.escalas_salariales_checked = payload;
    },
    checkEscalaSalarial: (state, { payload: { escala, checked = true } }) => {
      state.escalas_salariales_checked =
        state.escalas_salariales_checked.filter((esc) => esc.id !== escala.id);
      if (!!checked) {
        state.escalas_salariales_checked.push(escala);
      }
      // state.kpis_loading = false;
    },
    duplicarEscalaSalarial: (state, { payload = {} }) => {},
    // valoracion cargos
    getValoracionesCargos: (state, { payload = {} }) => {
      state.valoraciones_cargos_loading = true;
    },
    filterValoracionesCargos: (state, { payload = {} }) => {
      state.valoraciones_cargos_filter = payload;
      state.escalas_salariales_loading = true;
      state.valoraciones_cargos_loading = true;
    },
    setValoracionesCargos: (state, { payload = {} }) => {
      state.valoraciones_cargos = payload;
    },
    setValoracionesCargosComplete: (state, { payload = {} }) => {
      state.valoraciones_cargos_loading = false;
    },
    updateValoracionesCargos: (state, { payload = {} }) => {
      state.valoraciones_cargos_loading = true;
    },
    updateValoracionesCargosComplete: (state, { payload = {} }) => {
      state.valoraciones_cargos_loading = false;
    },
    //
    getCategorias: (state, { payload = {} }) => {
      state.categorias_loading = true;
    },
    setCategorias: (state, { payload = {} }) => {
      state.categorias = payload;
    },
    setCategoriasComplete: (state, { payload = {} }) => {
      state.categorias_loading = false;
    },
    filterCategorias: (state, { payload = {} }) => {
      state.categorias_filter = payload;
      state.categorias_loading = true;
    },
    createCategorias: (state, { payload = {} }) => {
      state.categorias_loading = true;
    },
    createCategoriasComplete: (state, { payload = {} }) => {
      state.categorias_loading = false;
    },
    //
    getSalariosColaboradores: (state, { payload = {} }) => {
      state.salarios_colaboradores_loading = true;
    },
    setSalariosColaboradoresFilter: (state, { payload }) => {
      state.salarios_colaboradores_filter = payload;
    },
    setSalariosColaboradores: (state, { payload }) => {
      state.salarios_colaboradores = payload;
      state.salarios_colaboradores_loading = false;
    },
    setSalarioColaboradorEditar: (state, { payload }) => {
      state.salario_colaborador_editar_loading = true;
    },
    salarioColaboradorEditarComplete: (state, { payload }) => {
      state.salario_colaborador_editar_loading = false;
    },
    // MERCADO
    getSalariosMercado: (state, { payload = {} }) => {
      state.salarios_mercado_loading = true;
    },
    setSalariosMercadoFilter: (state, { payload }) => {
      state.salarios_mercado_filter = payload;
    },
    setSalariosMercado: (state, { payload }) => {
      state.salarios_mercado = payload;
      state.salarios_mercado_loading = false;
    },
    setSalarioMercadoEditar: (state, { payload }) => {
      state.salario_mercado_editar_loading = true;
    },
    salarioMercadoEditarComplete: (state, { payload }) => {
      state.salario_mercado_editar_loading = false;
    },
    //
    setFileToUploadImportar: (state, { payload = {} }) => {
      state.fileToImportar = payload;
      state.importar_loading = true;
    },
    setFileToUploadImportarResponse: (state, { payload = {} }) => {
      state.fileToImportarResponse = payload;
      state.importar_loading = false;
    },
    //
    setFileToUploadImportarMercado: (state, { payload = {} }) => {
      state.fileToImportar = payload;
      state.importar_loading = true;
    },
    setFileToUploadImportarMercadoResponse: (state, { payload = {} }) => {
      state.fileToImportarMercadoResponse = payload;
      state.importar_loading = false;
    },
  },
});

export const remuneracionActions = remuneracionSlice.actions;
export const remuneracionReducer = remuneracionSlice.reducer;
