import React from "react";
import { Window } from "react-bootstrap-icons";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ReactSelect from "react-select";
import { BuscarInput } from "src/modules/common/components/BuscarInput";
import { PrimaryButton } from "src/modules/common/components/PrimaryButton";
import { commonActions } from "src/modules/common/handler/redux";
import { kpiActions } from "../handlers/redux";

export const KpiToolbar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const kpis_filter = useSelector((state) => state.kpi.kpis_filter);

  const kpis_checked = useSelector((state) => state.kpi.kpis_checked);
  const { nombre = "" } = kpis_filter;

  const onClickNew = () => {
    dispatch(kpiActions.kpiSet({}));
    //dispatch(kpiActions.setKpiCreateModal({ open: true }));
    navigate("/kpis/configuracion");
  };

  const onChange = (v) => {
    if (v.value == "cancel") {
      dispatch(kpiActions.resetCheckKpis());
    } else if (v.value == "asignar") {
      dispatch(commonActions.setSelectModal({ open: true }));
    } else if (v.value == "eliminar") {
      let deseaEliminar = window.confirm(
        "Desea eliminar los KPIs seleccionados?"
      );
      if (deseaEliminar) {
        dispatch(kpiActions.setKpisDelete(kpis_checked));
      }
    } else {
      // @ts-ignore
      window.alert(JSON.stringify(v));
    }
  };

  const onChangeText = (event) => {
    dispatch(
      kpiActions.setKpisFilter({
        nombre: event.target.value,
      })
    );
  };

  return (
    <div className="flex flex-row gap-2">
      <BuscarInput onChange={onChangeText} value={nombre}></BuscarInput>
      <ReactSelect
        className="w-52 h-10 bg-blue-200 inline-grid"
        value={{
          value: "",
          label: "(" + kpis_checked.length + ") seleccionados",
        }}
        options={[
          { value: "asignar", label: "Asignar a colaboradores" },
          { value: "eliminar", label: "Eliminar KPI" },
          { value: "cancel", label: "Cancel" },
        ]}
        onChange={onChange}
      ></ReactSelect>
      <PrimaryButton onClick={onClickNew}>Crear nuevo KPI</PrimaryButton>
    </div>
  );
};
