import React, { useEffect, useState } from "react";
import { VictoryAnimation, VictoryLabel, VictoryPie } from "victory";

function PieCenterLabel({
  data = [],
  amount = 0,
  label = "",
  labelSecondary = "",
  colorScale = ["orange", "blue"],
  width = 450,
  height = 450,
  innerRadius = 150,
}) {
  return (
    <svg viewBox={`0 0 ${width} ${height}`}>
      <VictoryPie
        standalone={false}
        width={width}
        height={height}
        data={data}
        colorScale={colorScale}
        innerRadius={innerRadius}
        labelRadius={155}
        style={{ labels: { fontSize: 20, fill: "white" } }}
      />
      <VictoryLabel
        textAnchor="middle"
        style={{ fontSize: 54 }}
        x={225}
        y={220}
        text={label}
      />
      <VictoryLabel
        textAnchor="middle"
        style={{ fontSize: 24 }}
        x={225}
        y={260}
        text={labelSecondary}
      />
    </svg>
  );
}

export default PieCenterLabel;
