import axios from "axios";
const URL_BASE =
  process.env.REACT_APP_API_URL || "http://localhost:8000/api/admin";

export const departamentoApi = (token) => {
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };

  return {
    create: async ({ nombre, descripcion }) => {
      try {
        const departamento = await axios.post(
          URL_BASE + "/departamento/",
          { nombre, descripcion },
          { headers }
        );
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log(JSON.stringify(error));
        throw error;
      }
    },
    update: async ({ id, nombre, descripcion }) => {
      try {
        const data = await axios.put(
          URL_BASE + `/departamento/${id}/`,
          { nombre, descripcion },
          { headers }
        );
        return data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log(JSON.stringify(error));
        throw error;
      }
    },
    delete: async ({ id }) => {
      try {
        const data = await axios.delete(URL_BASE + `/departamento/${id}/`, {
          headers,
        });
        return data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log(JSON.stringify(error));
        throw error;
      }
    },
    departamentosDelete: async ({ departamentosIds }) => {
      try {
        const data = await axios.post(
          URL_BASE + `/departamento/delete-ids/`,
          { ids: departamentosIds },
          { headers }
        );
        return data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log(JSON.stringify(error));
        throw error;
      }
    },
    list: async ({ departamentos_filter }) => {
      const { nombre = "", pagination = { page: 1, pageSize: 100 } } =
        departamentos_filter;
      const { page, pageSize } = pagination;
      try {
        const departamentos = await axios.get(
          URL_BASE +
            `/departamento/?search=${nombre}&page=${page}&page_size=${pageSize}`,
          {
            headers,
          }
        );
        return departamentos.data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG &&
          console.log("departamentos error", error);
        throw error;
      }
    },
    importarVerificar: async ({ fileToUpload }) => {
      try {
        const payload = {
          file: fileToUpload,
        };
        const headersForm = {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        };
        !!process.env.REACT_APP_DEBUG &&
          console.info("departamentos importar api");
        const response = await axios.post(
          URL_BASE + `/estructura/importar-departamentos-verificar/`,
          { file: fileToUpload },
          { headers: headersForm }
        );
        !!process.env.REACT_APP_DEBUG && console.info("response", response);
        return response.data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG &&
          console.log("departamentos error", error);
        throw error;
      }
    },
    importar: async (data) => {
      try {
        !!process.env.REACT_APP_DEBUG &&
          console.info("departamentos importar api", data);
        const response = await axios.post(
          URL_BASE + `/estructura/importar-departamentos/`,
          { departamentos: data },
          { headers }
        );
        !!process.env.REACT_APP_DEBUG && console.info("response", response);
        return response.data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG &&
          console.log("departamentos error", error);
        throw error;
      }
    },
  };
};
