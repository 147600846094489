import React from "react";
import ImportacionPage from "../../estructura/pages/ImportacionPage";
import SucursalPage from "../pages/SucursalPage";

export const sucursalRoutes = [
  {
    path: "/sucursales",
    element: <SucursalPage />,
  },
];
