import { push } from "@lagunovsky/redux-react-router";
import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import { ErrorToast } from "src/modules/common/components/ErrorToast";
import { SuccessToast } from "src/modules/common/components/SuccessToast";
import { ObjectToString, getToken } from "src/utils/helpers";
import { competenciaGeneralApi } from "./api";
import { competenciaGeneralActions } from "./redux";

function* competenciaGeneralDeleteSaga({ payload }) {
  const token = getToken();
  const competencia_general = payload;

  const params = { ...competencia_general };
  !!process.env.REACT_APP_DEBUG && console.log({ payload });
  try {
    const data = yield call(competenciaGeneralApi(token).delete, params);

    yield put(competenciaGeneralActions.competenciaGenerales());
    yield put(competenciaGeneralActions.competenciaGeneralDeleteSuccess());
  } catch (error) {
    yield put(competenciaGeneralActions.competenciaGeneralDeleteSuccess());
    !!process.env.REACT_APP_DEBUG && console.log({ error });
  }
}

function* competenciaGeneralUpdateSaga({ payload }) {
  const token = getToken();
  const competencia_general = yield select(
    (state) => state.competenciaGeneral.competencia_general
  );

  const params = { ...competencia_general };
  try {
    const data = yield call(competenciaGeneralApi(token).update, params);

    yield put(competenciaGeneralActions.competenciaGeneralUpdateSuccess());

    yield put(push("/competencias/general"));

    yield put(competenciaGeneralActions.competenciaGenerales());
  } catch (error) {
    yield put(competenciaGeneralActions.competenciaGeneralUpdateSuccess());
    !!process.env.REACT_APP_DEBUG && console.log({ error });
  }
}

function* competenciaGeneralConfiguracionSaga({ payload }) {
  const token = getToken();
  const competencia_general = yield select(
    (state) => state.competenciaGeneral.competencia_general
  );
  const aplicar_pesos = yield select(
    (state) => state.competenciaGeneral.aplicar_pesos_generales
  );
  // Verificacion de Pesos
  let { competencias } = competencia_general;
  const sumaPesos = competencias.reduce((sum, c) => sum + c.peso, 0);
  if (!aplicar_pesos) {
    competencias = competencias.map((comp, idx) => ({ ...comp, peso: 0 }));
  } else if (sumaPesos !== 100 && sumaPesos !== 0) {
    ErrorToast({
      message: "La suma de los pesos no son igual a 100%",
    });
    yield put(
      competenciaGeneralActions.competenciaGeneralConfiguracionComplete()
    );
    return;
  }
  const params = { ...competencia_general, competencias: competencias };
  // API CALL
  try {
    const data = yield call(competenciaGeneralApi(token).configuracion, params);
    yield put(
      competenciaGeneralActions.competenciaGeneralConfiguracionSuccess()
    );
    yield put(push("/competencias/general"));
    yield put(competenciaGeneralActions.competenciaGenerales());
    SuccessToast({ message: "Competencias actualizadas con éxito!" });
  } catch (error) {
    yield put(
      competenciaGeneralActions.competenciaGeneralConfiguracionComplete()
    );
    !!process.env.REACT_APP_DEBUG && console.log({ error });
    ErrorToast({
      message: "Ocurrió un error al intentar guardar las competencias",
    });
  }
}

function* competenciaGeneralCreateSaga({ payload }) {
  const token = getToken();
  const competencia_general = yield select(
    (state) => state.competenciaGeneral.competencia_general
  );

  const params = { ...competencia_general };
  try {
    const data = yield call(competenciaGeneralApi(token).create, params);

    yield put(competenciaGeneralActions.competenciaGeneralCreateSuccess());

    yield put(push("/competencias/general"));

    yield put(competenciaGeneralActions.competenciaGenerales());
  } catch (error) {
    yield put(competenciaGeneralActions.competenciaGeneralCreateSuccess());
    !!process.env.REACT_APP_DEBUG && console.log({ error });
  }
}

function* competenciaGeneralesSaga({ payload }) {
  const token = getToken();
  try {
    const data = yield call(competenciaGeneralApi(token).list);
    !!process.env.REACT_APP_DEBUG &&
      console.info("saga competenciaGenerales data", data);
    yield put(
      competenciaGeneralActions.setCompetenciaGenerales({
        competencia_generales: data,
      })
    );
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
  }
}

function* getCompetenciasBaseSaga({ payload }) {
  const token = getToken();
  const params = { ...payload };
  try {
    const data = yield call(
      competenciaGeneralApi(token).libreriaGeneral,
      params
    );
    yield put(competenciaGeneralActions.setCompetenciasBase(data.results));
    yield put(competenciaGeneralActions.getCompetenciasBaseComplete());
  } catch (error) {
    const errores = error.response.data;
    const message = ObjectToString(errores);
    ErrorToast({ message: message });
    yield put(competenciaGeneralActions.getCompetenciasBaseComplete());
  }
}

export function* competenciaGeneralSagas() {
  // create
  yield takeEvery(
    competenciaGeneralActions.competenciaGeneralCreate.type,
    competenciaGeneralCreateSaga
  );
  // update
  yield takeEvery(
    competenciaGeneralActions.competenciaGeneralUpdate.type,
    competenciaGeneralUpdateSaga
  );
  // configuracion
  yield takeEvery(
    competenciaGeneralActions.competenciaGeneralConfiguracion.type,
    competenciaGeneralConfiguracionSaga
  );
  // delete
  yield takeEvery(
    competenciaGeneralActions.competenciaGeneralDelete.type,
    competenciaGeneralDeleteSaga
  );
  // list
  yield takeLatest(
    competenciaGeneralActions.competenciaGenerales.type,
    competenciaGeneralesSaga
  );
  //
  yield takeLatest(
    competenciaGeneralActions.getCompetenciasBase.type,
    getCompetenciasBaseSaga
  );
}
