import { push } from "@lagunovsky/redux-react-router";
import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import { ErrorToast } from "src/modules/common/components/ErrorToast";
import { SuccessToast } from "src/modules/common/components/SuccessToast";
import { getToken, ObjectToString } from "src/utils/helpers";
import { cargoApi } from "./api";
import { cargoActions } from "./redux";

function* cargoDeleteSaga({ payload }) {
  const token = getToken();
  const cargo = payload;

  const params = { ...cargo };
  !!process.env.REACT_APP_DEBUG && console.log({ payload });
  try {
    const data = yield call(cargoApi(token).delete, params);

    yield put(cargoActions.cargos());
    yield put(cargoActions.cargoDeleteSuccess());
    SuccessToast({ message: "Cargo eliminado con éxito!" });
  } catch (error) {
    const errores = error.response.data;
    const message = ObjectToString(errores);
    ErrorToast({ message: message });
    yield put(cargoActions.cargoDeleteSuccess());
    !!process.env.REACT_APP_DEBUG && console.log({ error });
  }
}

function* cargoUpdateSaga({ payload }) {
  const token = getToken();
  const cargo = yield select((state) => state.cargo.cargo);

  const params = { ...cargo };
  try {
    const data = yield call(cargoApi(token).update, params);
    yield put(cargoActions.cargoUpdateSuccess());
    yield put(push("/estructura/cargos"));
    yield put(cargoActions.cargos());
    yield put(cargoActions.setCargoCreateModal({ open: false }));
    SuccessToast({ message: "Cargo editado con éxito!" });
  } catch (error) {
    const errores = error.response.data;
    const message = ObjectToString(errores);
    ErrorToast({ message: message });
    yield put(cargoActions.cargoUpdateSuccess());
  }
}

function* cargoCreateSaga({ payload }) {
  const token = getToken();
  const cargo = yield select((state) => state.cargo.cargo);

  const params = { ...cargo };
  try {
    const data = yield call(cargoApi(token).create, params);
    yield put(cargoActions.cargoCreateSuccess());
    yield put(push("/estructura/cargos"));
    yield put(cargoActions.cargos());
    yield put(cargoActions.setCargoCreateModal({ open: false }));
    SuccessToast({ message: "Cargo creado con éxito!" });
  } catch (error) {
    const errores = error.response.data;
    const message = ObjectToString(errores);
    ErrorToast({ message: message });
    yield put(cargoActions.cargoCreateSuccess());
  }
}

function* cargosSaga({ payload }) {
  const token = getToken();
  const cargos_filter = yield select((state) => state.cargo.cargos_filter);
  try {
    const data = yield call(cargoApi(token).list, { cargos_filter });
    yield put(cargoActions.setCargos({ cargos: data }));
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
  }
}

function* cargosDeleteSaga({ payload }) {
  const token = getToken();
  const cargosDelete = payload;
  !!process.env.REACT_APP_DEBUG && console.log({ payload });
  try {
    const data = yield call(cargoApi(token).cargosDelete, {
      cargosIds: cargosDelete,
    });
    yield put(cargoActions.cargos());
    yield put(cargoActions.resetCheckCargos());
    SuccessToast({ message: "Cargos eliminados con éxito!" });
  } catch (error) {
    const errores = error.response.data;
    const message = ObjectToString(errores);
    ErrorToast({ message: message });
    !!process.env.REACT_APP_DEBUG && console.log({ error });
  }
}

function* cargosImportarVerificarSaga({ payload }) {
  const token = getToken();
  const fileToUpload = yield select((state) => state.cargo.fileToUpload);
  try {
    const data = yield call(cargoApi(token).importarVerificar, {
      fileToUpload,
    });
    yield put(cargoActions.setDatosVerificados(data));
    yield put(push("/importador/cargo"));
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
  }
}

function* cargosImportarSaga({ payload }) {
  const token = getToken();
  const datos = yield select((state) => state.cargo.datosImportar);
  try {
    const data = yield call(cargoApi(token).importar, datos);
    yield put(cargoActions.importarDatosComplete());
    SuccessToast({ message: "Cargos importados con éxito!" });
    yield put(push("/estructura/cargos"));
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
  }
}

export function* cargoSagas() {
  yield takeEvery(
    cargoActions.setFileToUpload.type,
    cargosImportarVerificarSaga
  );
  yield takeEvery(cargoActions.setDatosImportar.type, cargosImportarSaga);
  // create
  yield takeEvery(cargoActions.cargoCreate.type, cargoCreateSaga);
  // update
  yield takeEvery(cargoActions.cargoUpdate.type, cargoUpdateSaga);
  // delete
  yield takeEvery(cargoActions.cargoDelete.type, cargoDeleteSaga);
  yield takeEvery(cargoActions.setCargosDelete.type, cargosDeleteSaga);
  // list
  yield takeLatest(cargoActions.cargos.type, cargosSaga);
  yield takeLatest(cargoActions.setCargosFilter.type, cargosSaga);
}
