import { useNavigate } from "react-router-dom";
import backButton from "src/modules/common/components/images/back-arrow.png";

function HeaderGoBack({ title, route, RightMenu }) {
  const navigate = useNavigate();
  //
  return (
    <div className="flex justify-between items-center bg-zinc-100 -mx-8 py-2.5 px-7 border-b">
      <div className="flex items-center flex-1">
        <div
          onClick={() => navigate(route)}
          className={"h-full flex items-center pr-2 -mr-2 z-10 cursor-pointer"}
        >
          <img className="back" src={backButton} alt="Regresar" />
        </div>
        <div className="font-medium px-4 text-xl">{title}</div>
      </div>
      {!!RightMenu && (
        <div className="flex flex-1 items-center justify-end">{RightMenu}</div>
      )}
    </div>
  );
}

export default HeaderGoBack;
