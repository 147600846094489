import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CheckInput } from "src/modules/common/components/CheckInput";
import SkeletonLoaderRow from "src/modules/common/components/SkeletonLoaderRow";
import { kpiActions } from "../handlers/redux";
import { KpiTableAccionMenu } from "./KpiTableAccionMenu";

export const KpiTable = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // @ts-ignore
  const kpis = useSelector((state) => state.kpi.kpis);
  const kpisLoading = useSelector((state) => state.kpi.kpis_loading);
  const kpis_checked = useSelector((state) => state.kpi.kpis_checked);
  const { results = [] } = kpis;

  const selectAllChange = (event) => {
    results.map((item, idx) =>
      dispatch(
        kpiActions.checkKpi({
          kpi: item,
          checked: event.target.checked,
        })
      )
    );
  };

  const renderTableHeader = () => {
    return (
      <div className="border-b flex flex-col border-zinc-100 ">
        <div className="flex flex-row items-center">
          <CheckInput
            checked={
              kpis_checked.length === results.length && results.length > 0
            }
            onChange={selectAllChange}
          ></CheckInput>
          <div className="p-1 font-bold flex-grow">Lista de KPIs creados</div>
          <div className="p-1 font-bold text-center w-60  mr-4">
            Colaboradores
          </div>
          <div className=" w-6"></div>
        </div>
      </div>
    );
  };
  const onRowClick = (id) => {
    let item = kpis.results.find((d) => d.id == id);
    dispatch(kpiActions.kpiGet(item));
    dispatch(kpiActions.kpiColaboradores(item));
    navigate(`/kpis/detalle/${id}`);
  };
  const renderTableItem = (item, idx) => {
    return (
      <div
        key={idx}
        className="hover:bg-sky-100 flex flex-row gap-2 border-b align-middle py-2"
      >
        <div className="p-1 h-16 align-text-top  flex flex-row items-center">
          <CheckInput
            checked={kpis_checked.includes(item.id)}
            onChange={(event) => {
              dispatch(
                kpiActions.checkKpi({
                  kpi: item,
                  checked: event.target.checked,
                })
              );
            }}
          />
        </div>
        <div
          className="p-1 flex flex-grow flex-col justify-center hover:cursor-pointer"
          onClick={() => onRowClick(item.id)}
        >
          <div className="font-bold">{item.nombre}</div>
          <div className="text-sm">{item.descripcion}</div>
        </div>
        <div className="p-1 text-center w-48 flex flex-row justify-center items-center">
          {item.cantidad_colaboradores}
        </div>
        <div className="p-1 w-12 flex flex-row items-center">
          <KpiTableAccionMenu item={item} />
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className="flex flex-col w-full">
        {renderTableHeader()}
        <div className="flex flex-col">
          {!kpisLoading && results.length > 0 && results.map(renderTableItem)}
          {!kpisLoading && results.length < 1 && (
            <div className="flex h-20 items-center justify-center">
              <div>No existen registros para mostrar.</div>
            </div>
          )}
          {!!kpisLoading &&
            [0, 1, 2, 3, 4].map((item, idx) => (
              <SkeletonLoaderRow></SkeletonLoaderRow>
            ))}
        </div>
      </div>
    </div>
  );
};
