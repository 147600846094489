import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ReactSelect from "react-select";
import { BuscarInput } from "src/modules/common/components/BuscarInput";
import { PrimaryButton } from "src/modules/common/components/PrimaryButton";
import { kpiActions } from "../handlers/redux";
import AreaColaboradorSelect from "./AreaColaboradorSelect";
import DepartamentoColaboradorSelect from "./DepartamentoColaboradorSelect";
import SucursalColaboradorSelect from "src/modules/evaluacion/components/SucursalColaboradorSelect";
import { ErrorToast } from "src/modules/common/components/ErrorToast";
import { DescargarColaboradoresKpisInforme } from "../helpers/helpers";
import { getToken } from "src/utils/helpers";
import { FiletypeXls } from "react-bootstrap-icons";

export const ColaboradorToolbar = ({
  Page,
  setPage,
  PageSize,
  setPageSize,
  PagesButtonsArray,
  setPagesButtonsArray,
  setPagination,
  totalPaginate,
  getPageButtonsArray,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const colaboradores_checked = useSelector(
    (state) => state.kpi.colaboradores_checked
  );
  const colaboradores_filter = useSelector(
    (state) => state.kpi.colaboradores_filter
  );
  const { nombre = "" } = colaboradores_filter;

  const onChangeText = (event) => {
    dispatch(
      kpiActions.setColaboradoresFilter({
        ...colaboradores_filter,
        nombre: event.target.value,
      })
    );
  };

  const onChangeSelect = (v) => {
    if (v.value === "asignar") {
      if (colaboradores_checked.length > 0) {
        navigate("/kpis/colaboradores/asignar-kpis");
      } else {
        ErrorToast({ message: "Debe seleccionar al menos un colaborador" });
      }
      //
    } else {
      // @ts-ignore
      window.alert(JSON.stringify(v));
    }
  };

  const onChangePageSize = (e) => {
    const value = e.target.value;
    setPageSize(value);
    setPage(1);
  };
  const token = getToken();
  const [descargarInformeLoading, setDescargarInformeLoading] = useState(false);
  const onFinishDescargarLoading = () => {
    setDescargarInformeLoading(false);
  };
  const onDescargarInforme = () => {
    setDescargarInformeLoading(true);
    DescargarColaboradoresKpisInforme(token, onFinishDescargarLoading);
  };

  useEffect(() => {
    setPagination(Page, PageSize);
    setPagesButtonsArray(getPageButtonsArray(totalPaginate / PageSize));
  }, [Page, PageSize]);

  return (
    <div className="flex flex-col gap-4">
      <div className="flex gap-2">
        <BuscarInput
          placeholder={"Buscar por nombre o por cargo..."}
          onChange={onChangeText}
          value={nombre}
        ></BuscarInput>
        {/*  */}
        <ReactSelect
          className="w-52 h-10 bg-blue-200 inline-grid"
          value={{
            value: "",
            label: "(" + colaboradores_checked.length + ") seleccionados",
          }}
          options={[{ value: "asignar", label: "Asignar KPIs" }]}
          onChange={onChangeSelect}
        ></ReactSelect>
        {/*  */}
        <div className="flex gap-2 items-center">
          <label className="font-semibold" htmlFor="pageSize">
            Mostrar
          </label>
          <select
            name="pageSize"
            id="pageSize"
            className="border rounded-md p-2"
            onChange={onChangePageSize}
          >
            <option value="10">10</option>
            <option value="50" selected>
              50
            </option>
            <option value="100">100</option>
          </select>
        </div>
        {/*  */}
        <div>
          {!descargarInformeLoading && (
            <button
              onClick={onDescargarInforme}
              className="flex items-center gap-2 bg-sky-500 text-white p-2 rounded"
            >
              <FiletypeXls size={21} />
              <span className="font-semibold text-sm">Descargar reporte</span>
            </button>
          )}
          {!!descargarInformeLoading && (
            <button
              disabled={true}
              className="flex items-center gap-2 bg-sky-400 text-white px-3 py-2 rounded animated-pulse"
            >
              <span className="font-semibold text-sm">Descargando...</span>
            </button>
          )}
        </div>
      </div>
      <div className="flex flex-col md:flex-row gap-2">
        <AreaColaboradorSelect />
        <DepartamentoColaboradorSelect />
        <SucursalColaboradorSelect />
      </div>
    </div>
  );
};
