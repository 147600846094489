import React, { useState } from "react";
import {
  ChevronDown,
  ChevronUp,
  Search,
  ThreeDotsVertical,
  X,
} from "react-bootstrap-icons";

function NominaTableHeader({ text, onOrder, onFilter }) {
  const [showSearchBar, setShowSearchBar] = useState(false);
  const [ordering, setOrdering] = useState("");
  const [inputValue, setInputValue] = useState("");

  const onShowSearchBar = () => {
    setShowSearchBar(true);
  };
  const onHideSearchBar = () => {
    setShowSearchBar(false);
    setInputValue("");
    onFilter("");
  };
  const onOrderingClick = (e) => {
    e.stopPropagation();
    switch (ordering) {
      case "asc":
        setOrdering("desc");
        onOrder("desc");
        break;
      case "desc":
        setOrdering("");
        onOrder("");
        break;
      default:
        setOrdering("asc");
        onOrder("asc");
        break;
    }
  };

  const onChangeText = (e) => {
    const inputText = e.target.value;
    onFilter(e.target.value);
    setInputValue(inputText);
  };
  return (
    <div className="flex items-center gap-4">
      {!showSearchBar && <span>{text}</span>}

      {!!showSearchBar && (
        <input
          value={inputValue}
          onChange={onChangeText}
          className="border rounded-md bg-slate-100 h-8 p-2"
          type="text"
        />
      )}

      {!!onOrder && (
        <span className="hover:cursor-pointer" onClick={onOrderingClick}>
          <ChevronUp
            color={ordering === "asc" || !ordering ? "black" : "gray"}
            size={12}
          ></ChevronUp>
          <ChevronDown
            color={ordering === "desc" || !ordering ? "black" : "gray"}
            size={12}
          ></ChevronDown>
        </span>
      )}
      {!!onFilter && (
        <span className="hover:cursor-pointer">
          {!showSearchBar && (
            <Search onClick={onShowSearchBar} size={18}></Search>
          )}
          {!!showSearchBar && <X onClick={onHideSearchBar} size={24}></X>}
        </span>
      )}
    </div>
  );
}

export default NominaTableHeader;
