import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import SearchSelect from "src/modules/common/components/SearchSelect";
import { areaActions } from "src/modules/estructura/modules/area/handlers/redux";
import { evaluacionActions } from "../handlers/redux";

function AreaEvaluacionSelect() {
  const dispatch = useDispatch();
  const params = useParams();
  const areas_results = useSelector((state) => state.area.areas);
  const { results: areas } = areas_results;
  const areas_filter = useSelector((state) => state.area.areas_filter);
  const { nombre } = areas_filter;
  const filterAreas = (nombre) => {
    dispatch(areaActions.setAreasFilter({ nombre: nombre }));
  };
  const { idEvaluacion } = params;
  const formularios_filter = useSelector(
    (state) => state.evaluacion.formularios_filter
  );
  const onSelectItem = (area) => {
    dispatch(
      evaluacionActions.setFormulariosFilter({
        ...formularios_filter,
        id: idEvaluacion,
        area: area,
      })
    );
  };
  //
  useEffect(() => {
    // dispatch(areaActions.areas());
  }, []);
  //
  return (
    <SearchSelect
      data={areas}
      defaultText={"Áreas: Todas"}
      searchValue={nombre}
      filterFunction={filterAreas}
      onSelectItem={onSelectItem}
    ></SearchSelect>
  );
}
export default AreaEvaluacionSelect;
