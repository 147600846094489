import { call, put, takeEvery } from "redux-saga/effects";
import { getToken } from "src/utils/helpers";
import { analisisApi } from "./api";
import { analisisActions } from "./redux";

function* analisisSaga({ payload }) {
  const token = getToken();
  try {
    const data = yield call(analisisApi(token).data);
    yield put(analisisActions.setAnalisis(data));
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
  }
}

export function* analisisSagas() {
  // list
  yield takeEvery(analisisActions.analisis.type, analisisSaga);
}
