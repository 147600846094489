import React from "react";
import { ChevronDown, ChevronRight } from "react-bootstrap-icons";

function ChevronCard({ title, description, data, image, toggleArrow = false }) {
  return (
    <>
      <div className="flex flex-col md:flex-row justify-between items-center border-bottom-1">
        <div className="flex items-start">
          {!!image && (
            <div className="mr-3 mb-3">
              <img src={image} alt="Logo Empresa" width={63} height={63} />
            </div>
          )}
          <div className="flex flex-col items-start">
            <h6 className="font-bold text-base">{title}</h6>
            <p className="font-weight-400 text-base mb-3">{description}</p>
          </div>
        </div>
        <div className="flex">
          <h6 className="font-bold text-base mr-3">{data}</h6>
          {!toggleArrow && <ChevronRight color="#2196F3" size={18} />}
          {!!toggleArrow && <ChevronDown color="#2196F3" size={18} />}
        </div>
      </div>
    </>
  );
}

export default ChevronCard;
