import { useNavigate, useParams } from "react-router-dom";
import { BeneficioConfiguracionLayout } from "../components/BeneficioConfiguracionLayout";
import { beneficiosRoutesWindow } from "../handlers/routes";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { commonActions } from "src/modules/common/handler/redux";
import DataTable from "src/modules/common/components/DataTable";
import { PencilFill, X } from "react-bootstrap-icons";
import { BeneficioFormView } from "./BeneficioFormView";
import Modal from "src/modules/common/components/Modal";
import SelectModal from "src/modules/common/components/SelectModal";
import { BeneficioConfiguracionFormEdit } from "./BeneficioConfiguracionFormEdit";
import { beneficioActions } from "../handlers/redux";

const initialBeneficio = {
  nombre: "",
  descripcion: "",
  requisitos: [],
};

export function BeneficiosCargoList() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const params = useParams();
  const { idCargo } = params;
  const cargo = useSelector((state) => state.beneficio.cargo);
  const beneficios_cargo = useSelector(
    (state) => state.beneficio.beneficios_cargo
  );
  const beneficios_cargo_count = useSelector(
    (state) => state.beneficio.beneficios_cargo_count
  );
  const beneficios_cargo_loading = useSelector(
    (state) => state.beneficio.beneficios_cargo_loading
  );

  const [openDetailModal, setOpenDetailModal] = useState(false);
  const [beneficio, setBeneficio] = useState(initialBeneficio);

  const onClickBack = () => {
    navigate(beneficiosRoutesWindow.CARGOS);
  };

  useEffect(() => {
    dispatch(commonActions.setTitle("Admin"));
    dispatch(commonActions.showSidebar(false));
    dispatch(beneficioActions.getCargo(idCargo));
    dispatch(beneficioActions.getBeneficiosCargo(idCargo));
  }, []);

  const getEstadoColor = (estado) => {
    return estado ? "bg-green-500 text-white" : "bg-red-500 text-white";
  };

  const onOpenDetailModal = (beneficio) => {
    console.log("openDetailModal", beneficio);
    setBeneficio(beneficio);
    setOpenDetailModal(true);
  };

  const onOpeneEditModal = (beneficioCopy) => {
    console.log("openeEditModal", beneficio);
    setBeneficio(beneficioCopy);
    dispatch(commonActions.setSelectModal({ open: true }));
  };

  const datatableConfig = {
    headers: [
      {
        title: "Disponibles",
        contentClass: "font-bold px-2 py-4",
        // onOrder: setOrdering(""),
        // accessor: "nombre",
        contentRenderer: (item) => (
          <div className="flex flex-col">
            <span className="font-semibold">{item.nombre}</span>
            <span className="text-sm">{item.descripcion}</span>
          </div>
        ),
      },
      {
        title: "Estado",
        contentClass: "font-bold px-2 py-4",
        // onOrder: setOrdering(""),
        // accessor: "nombre",
        contentRenderer: (item) => (
          <div className="flex items-center">
            <span
              className={`p-2 rounded-md text-sm ${getEstadoColor(
                item.vigente
              )}`}
            >
              {item.vigente ? "Vigente" : "No vigente"}
            </span>
          </div>
        ),
      },
      {
        title: "Requisitos",
        contentClass: "font-bold px-2 py-4",
        // accessor: "nombre",
        contentRenderer: (item) => (
          <div className="flex flex-row">
            <button
              className="flex items-center gap-2 border rounded-md py-2 px-3 border-gray-500 text-sm"
              onClick={() => onOpenDetailModal(item)}
            >
              <span className="text-sm">Ver requisitos</span>
            </button>
            <div
              className="flex flex-row gap-2 items-center pl-3 cursor-pointer ml-2"
              onClick={() => onOpeneEditModal(item)}
            >
              <PencilFill color="#0EA5E9" />
            </div>
          </div>
        ),
      },
    ],
    rowActions: [
      //   {
      //     label: "Eliminar",
      //     action: (item) => {
      //       onDeleteBeneficio(item.id);
      //     },
      //   },
    ],
  };

  const agregarBeneficio = () => {
    dispatch(beneficioActions.setMostrarAlcance(false));
    dispatch(beneficioActions.setMostrarExcluidos(true));
    dispatch(
      beneficioActions.setBeneficiosBody({
        cargos: [idCargo],
      })
    );
    dispatch(
      beneficioActions.setOnClickBackBeneficioConfiguracion(
        beneficiosRoutesWindow.CARGOS
      )
    );
    navigate(beneficiosRoutesWindow.CONFIG);
  };

  const onUpdateBeneficio = (updateBeneficio) => {
    let updateBeneficioCopy = { ...updateBeneficio };
    updateBeneficioCopy.tipo_config_beneficio =
      updateBeneficio.tipo_config_beneficio.id;
    delete updateBeneficioCopy.cargos;
    delete updateBeneficioCopy.colaboradores;
    dispatch(beneficioActions.updateBeneficio(updateBeneficioCopy));
    dispatch(beneficioActions.getBeneficiosCargo(idCargo));
    dispatch(commonActions.setSelectModal({ open: false }));
  };

  return (
    <BeneficioConfiguracionLayout
      title={cargo.nombre ? cargo.nombre : ""}
      onClickBack={onClickBack}
    >
      <div className="py-2 px-8 -mx-8">
        <div className="form flex flex-col items-center mt-4">
          <div className="flex flex-col gap-4 w-full md:w-8/12 p-4">
            <div className="flex flex-col justify-between">
              <div className="flex flex-row justify-between">
                <h2 className="font-semibold text-2xl">
                  Beneficios e Incentivos para el cargo
                </h2>
                <button
                  onClick={agregarBeneficio}
                  className="rounded-md bg-sky-500 text-white p-3"
                >
                  Agregar nuevo beneficio
                </button>
              </div>
              <h2 className="font-semibold text-xl text-sky-500 mt-6">
                Beneficios
              </h2>
              <DataTable
                containerClass={"my-2"}
                config={datatableConfig}
                data={beneficios_cargo}
                loading={beneficios_cargo_loading}
                totalRecords={beneficios_cargo_count}
                noHeaders={false}
                checkable={false}
              />
            </div>
          </div>
          <SelectModal
            title="Agregar"
            content={
              <BeneficioConfiguracionFormEdit
                beneficio={beneficio}
                onSubmit={onUpdateBeneficio}
              />
            }
          />
          <Modal isOpen={openDetailModal}>
            <div className="rounded-lg bg-white w-10/12 md:w-6/12 p-2 md:p-5 flex flex-col gap-5">
              <div className="modal-title">
                <div className="w-full flex justify-between items-center">
                  <div className="flex items-center gap-4">
                    <h2 className="font-semibold text-xl">Beneficio</h2>
                  </div>
                  <button
                    onClick={() => {
                      setOpenDetailModal(false);
                    }}
                  >
                    <X size={24} />
                  </button>
                </div>
              </div>
              <div className="modal-body">
                <div className="">
                  <div>
                    <BeneficioFormView beneficio={beneficio} />
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        </div>
      </div>
    </BeneficioConfiguracionLayout>
  );
}
