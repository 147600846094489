import { getToken } from "src/utils/helpers";
import { aprendizajeApis } from "./apis";
import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import { aprendizajeActions } from "./redux";
import { ErrorToast } from "src/modules/common/components/ErrorToast";

function* listFormacionesSaga() {
  const token = getToken();
  const formaciones_filter = yield select(
    (state) => state.aprendizaje.formaciones_filter
  );
  try {
    const data = yield call(
      aprendizajeApis(token).listFormaciones,
      formaciones_filter
    );
    yield put(aprendizajeActions.setFormaciones(data.results));
    yield put(aprendizajeActions.setFormacionesCount(data.count));
  } catch (error) {
    console.log(error);
    ErrorToast({ message: "Ocurrio un error." });
    yield put(aprendizajeActions.setFormaciones([]));
    yield put(aprendizajeActions.setFormacionesCount(0));
  }
}

function* detailFormacionSaga({ payload }) {
  const token = getToken();
  try {
    const data = yield call(aprendizajeApis(token).detailFormacion, payload);
    yield put(aprendizajeActions.setFormacion(data));
  } catch (error) {
    console.log(error);
    ErrorToast({ message: "Ocurrio un error." });
    yield put(aprendizajeActions.setFormacion({}));
  }
}

function* listTutorialesSaga() {
  const token = getToken();
  const tutoriales_filter = yield select(
    (state) => state.aprendizaje.tutoriales_filter
  );
  try {
    const data = yield call(
      aprendizajeApis(token).listTutoriales,
      tutoriales_filter
    );
    yield put(aprendizajeActions.setTutoriales(data.results));
    yield put(aprendizajeActions.setTutorialesCount(data.count));
  } catch (error) {
    console.log(error);
    ErrorToast({ message: "Ocurrio un error." });
    yield put(aprendizajeActions.setTutoriales([]));
    yield put(aprendizajeActions.setTutorialesCount(0));
  }
}

function* detailTutorialSaga({ payload }) {
  const token = getToken();
  try {
    const data = yield call(aprendizajeApis(token).detailTutorial, payload);
    yield put(aprendizajeActions.setTutorial(data));
  } catch (error) {
    console.log(error);
    ErrorToast({ message: "Ocurrio un error." });
    yield put(aprendizajeActions.setTutorial({}));
  }
}

export function* aprendizajeSagas() {
  yield takeEvery(aprendizajeActions.getFormaciones.type, listFormacionesSaga);
  yield takeLatest(
    aprendizajeActions.setFormacionesFilter.type,
    listFormacionesSaga
  );

  yield takeEvery(aprendizajeActions.getFormacion.type, detailFormacionSaga);

  yield takeEvery(aprendizajeActions.getTutoriales.type, listTutorialesSaga);
  yield takeLatest(
    aprendizajeActions.setTutorialesFilter.type,
    listTutorialesSaga
  );

  yield takeEvery(aprendizajeActions.getTutorial.type, detailTutorialSaga);
}
