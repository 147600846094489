import { useEffect, useState } from "react";
import { FeedbackConfiguracionLayout } from "../components/FeedbackConfiguracionLayout";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { commonActions } from "src/modules/common/handler/redux";
import DatePicker from "react-datepicker";
import { PrimaryButton } from "src/modules/common/components/PrimaryButton";
import Modal from "src/modules/common/components/Modal";
import { X } from "react-bootstrap-icons";
import JefesDirectosList from "../components/JefesDirectosList";
import { SucursalSelect } from "src/modules/estructura/modules/sucursal/components/SucursalSelect";
import { AreaSelect } from "src/modules/estructura/modules/area/components/AreaSelect";
import { DepartamentoSelect } from "src/modules/estructura/modules/departamento/components/DepartamentoSelect";
import { sucursalActions } from "src/modules/estructura/modules/sucursal/handlers/redux";
import { areaActions } from "src/modules/estructura/modules/area/handlers/redux";
import { departamentoActions } from "src/modules/estructura/modules/departamento/handlers/redux";
import { feedbackActions } from "../handlers/redux";
import { ErrorToast } from "src/modules/common/components/ErrorToast";

export function FeedbackConfiguracion() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const { feedbackId } = params;
  const feedback = useSelector((state) => state.feedback.feedback);
  const jefes_directos = useSelector((state) => state.feedback.jefes_directos);
  const jefes_directos_filter = useSelector(
    (state) => state.feedback.jefes_directos_filter
  );
  const jefes_directos_checked = useSelector(
    (state) => state.feedback.jefes_directos_checked
  );

  const [alcanceSeleccionado, setAlcanceSeleccionado] =
    useState("option-manual");
  const [openJefesDirectos, setOpenJefesDirectos] = useState(false);
  const [openConfigJefesDirectos, setOpenConfigJefesDirectos] = useState(false);
  const [configJefesDirectos, setConfigJefesDirectos] = useState({});

  useEffect(() => {
    dispatch(commonActions.setTitle("Admin"));
    dispatch(commonActions.showSidebar(false));
    dispatch(sucursalActions.sucursales());
    dispatch(areaActions.areas());
    dispatch(departamentoActions.departamentos());
    dispatch(feedbackActions.getJefesDirectos());
    if (feedbackId) {
      dispatch(feedbackActions.getFeedback(feedbackId));
    }
  }, []);

  const onClickBack = () => {
    navigate("/feedback/");
  };

  const onChange = (field) => (e) => {
    let value = "";
    if (field.includes("fecha")) {
      value = e;
    } else {
      value = e.target.value;
    }
    console.log(field, value);
    dispatch(
      feedbackActions.setFeedback({
        ...feedback,
        [field]: value,
      })
    );
  };

  const handleOnChangeAlcance = (e) => {
    console.log(e.target.id);
    const option = e.target.id;
    setAlcanceSeleccionado(option);
  };

  const onOpenJefesDirectos = () => {
    dispatch(feedbackActions.setJefesDirectosFilter({}));
    setOpenJefesDirectos(true);
  };

  const onOpenConfigJefesDirectos = () => {
    setOpenConfigJefesDirectos(true);
  };

  const onSubmit = () => {
    if (!feedback.nombre) {
      ErrorToast({ message: "Debes agregar un nombre" });
      return;
    }
    if (!feedback.fecha_inicio || !feedback.fecha_fin) {
      ErrorToast({ message: "Debes agregar las fechas de inicio y fin" });
      return;
    }
    // Si tiene feedbackId es una actualización, si no tiene es una creación
    if (feedbackId) {
      dispatch(feedbackActions.updateFeedback());
    } else {
      if (alcanceSeleccionado === "option-todos") {
        const jefesDirectosIds = jefes_directos.map((jefe) => jefe.id);
        console.log(jefesDirectosIds);
        dispatch(
          feedbackActions.setFeedback({
            ...feedback,
            jefes_directos: jefesDirectosIds,
            por_jefe_directo: false,
          })
        );
      } else {
        if (!feedback.jefes_directos) {
          ErrorToast({
            message:
              "Debes seleccionar al menos un jefe directo que realizará el feedback",
          });
          return;
        }
        dispatch(
          feedbackActions.setFeedback({
            ...feedback,
            por_jefe_directo: false,
          })
        );
      }
      dispatch(feedbackActions.createFeedback());
    }

    console.log("onSubmit", feedback);
  };

  const cargarJefesDirectos = (jefesDirectos) => {
    const jefesDirectosIds = jefesDirectos.map((jefe) => jefe.id);
    dispatch(
      feedbackActions.setFeedback({
        ...feedback,
        jefes_directos: jefesDirectosIds,
      })
    );
    setOpenJefesDirectos(false);
  };

  const onFilterJefesDirectos = (field) => (e) => {
    const value = e.target.value;
    console.log(field, value);
    setConfigJefesDirectos({
      ...configJefesDirectos,
      [field]: value,
    });
  };

  const onSaveConfig = () => {
    console.log({
      ...jefes_directos_filter,
      ...configJefesDirectos,
    });
    dispatch(
      feedbackActions.setJefesDirectosFilter({
        ...jefes_directos_filter,
        ...configJefesDirectos,
      })
    );
    setOpenConfigJefesDirectos(false);
  };

  return (
    <FeedbackConfiguracionLayout
      title={"Configuración de Feedback"}
      onClickBack={onClickBack}
    >
      <div className="py-2 px-8 -mx-8">
        <div className="form flex justify-center mt-4">
          <div className="flex flex-col gap-4 w-full md:w-6/12">
            <div className="border-b pb-2">
              <h2 className="font-semibold text-xl">Configuración inicial</h2>
            </div>
            <div className="flex flex-col w-full">
              <div>
                <h2 className="font-semibold">Nombre:</h2>
                <div className="flex justify-start">
                  <input
                    type={"text"}
                    name={"nombre"}
                    value={feedback.nombre ? feedback.nombre : ""}
                    onChange={onChange("nombre")}
                    placeholder={"Sin especificar..."}
                    className="border rounded placeholder-zinc-600 border-zinc-200 px-2 py-3 w-full"
                  />
                </div>
              </div>
              <div className="flex flex-row justify-between gap-8 mt-6">
                <div className="flex flex-col w-1/2">
                  <label className="font-bold text-base" htmlFor="">
                    Fecha inicio
                  </label>
                  <DatePicker
                    className="border rounded placeholder-zinc-600 border-zinc-200 px-2 py-2 w-full"
                    locale="es"
                    selected={Date.parse(feedback?.fecha_inicio)}
                    onChange={onChange("fecha_inicio")}
                    dateFormat="dd/MM/yyyy"
                  />
                </div>
                <div className="flex flex-col w-1/2">
                  <label className="font-bold text-base" htmlFor="">
                    Fecha fin
                  </label>
                  <DatePicker
                    className="border rounded placeholder-zinc-600 border-zinc-200 px-2 py-2 w-full"
                    locale="es"
                    selected={Date.parse(feedback?.fecha_fin)}
                    onChange={onChange("fecha_fin")}
                    dateFormat="dd/MM/yyyy"
                  />
                </div>
              </div>
              {!feedbackId && (
                <div>
                  <div className="border-b pb-2 my-6">
                    <h2 className="font-semibold text-lg">Alcance</h2>
                    <div className="text-sm text-gray-400 my-2">
                      Se enviará a todos los líderes con colaboradores a su
                      cargo.
                    </div>
                  </div>
                  <div className="flex flex-col gap-y-6">
                    <div className="flex items-center justify-between p-2 border-b">
                      <div className="justify-start">
                        <input
                          type="radio"
                          name="alcance"
                          id="option-todos"
                          onChange={handleOnChangeAlcance}
                          checked={alcanceSeleccionado === "option-todos"}
                        />
                        <label htmlFor="option-todos" className="ml-2">
                          Enviar a toda la nómina actual
                        </label>
                        <div className="text-sm text-gray-400 ml-5">
                          {`Todos: ${jefes_directos.length} jefes directos`}
                        </div>
                      </div>
                      <button
                        className={`flex items-center gap-2 border rounded-md py-2 px-3 border-gray-500 text-sm ${
                          alcanceSeleccionado !== "option-todos"
                            ? "text-gray-500"
                            : ""
                        }`}
                        onClick={onOpenConfigJefesDirectos}
                        disabled={alcanceSeleccionado !== "option-todos"}
                      >
                        <span className="text-sm">Configurar</span>
                      </button>
                    </div>
                    <div className="flex items-center justify-between p-2 border-b">
                      <div className="justify-start">
                        <input
                          type="radio"
                          name="alcance"
                          id="option-manual"
                          onChange={handleOnChangeAlcance}
                          checked={alcanceSeleccionado === "option-manual"}
                        />
                        <label htmlFor="option-manual" className="ml-2">
                          Carga manual de Jefes directos
                        </label>
                        <div className="text-sm text-gray-400 ml-5">
                          {`Seleccionados: ${jefes_directos_checked.length}`}
                        </div>
                      </div>
                      <button
                        className={`flex items-center gap-2 border rounded-md py-2 px-3 border-gray-500 text-sm ${
                          alcanceSeleccionado !== "option-manual"
                            ? "text-gray-500"
                            : ""
                        }`}
                        onClick={onOpenJefesDirectos}
                        disabled={alcanceSeleccionado !== "option-manual"}
                      >
                        <span className="text-sm">Agregar</span>
                      </button>
                    </div>
                  </div>
                </div>
              )}
              <div className="mt-10 mb-4">
                <PrimaryButton
                  type="button"
                  onClick={onSubmit}
                  className="w-full bg-sky-500"
                >
                  Continuar
                </PrimaryButton>
              </div>
            </div>
          </div>
          <Modal isOpen={openJefesDirectos}>
            <div className="rounded-lg bg-white w-10/12 md:w-4/12 p-2 md:p-5 flex flex-col gap-5">
              <div className="modal-title">
                <div className="w-full flex justify-between items-center">
                  <div className="flex items-center gap-4">
                    <h2 className="font-semibold text-xl">
                      Seleccione los Jefes directos
                    </h2>
                  </div>
                  <button
                    onClick={() => {
                      setOpenJefesDirectos(false);
                    }}
                  >
                    <X size={24} />
                  </button>
                </div>
              </div>
              <div className="modal-body">
                <div className="">
                  <div>
                    <JefesDirectosList
                      onSubmit={cargarJefesDirectos}
                      jefesForm={null}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Modal>
          <Modal isOpen={openConfigJefesDirectos}>
            <div className="rounded-lg bg-white w-10/12 md:w-6/12 p-2 md:p-5 flex flex-col gap-5">
              <div className="modal-title">
                <div className="w-full flex justify-between items-center">
                  <div className="flex items-center gap-4">
                    <h2 className="font-semibold text-xl">Carga de nómina</h2>
                  </div>
                  <button
                    onClick={() => {
                      setOpenConfigJefesDirectos(false);
                    }}
                  >
                    <X size={24} />
                  </button>
                </div>
              </div>
              <div className="modal-body">
                <div className="">
                  <div>
                    <div className="flex flex-col mb-3 mt-2">
                      <label className="font-bold text-md" htmlFor="">
                        Sucursal:
                      </label>
                      <SucursalSelect
                        value={
                          configJefesDirectos.sucursal
                            ? configJefesDirectos.sucursal
                            : null
                        }
                        onChange={onFilterJefesDirectos("sucursal")}
                      />
                    </div>
                    <div className="flex flex-col mb-3">
                      <label className="font-bold text-md" htmlFor="">
                        Área:
                      </label>
                      <AreaSelect
                        value={
                          configJefesDirectos.area
                            ? configJefesDirectos.area
                            : null
                        }
                        onChange={onFilterJefesDirectos("area")}
                      />
                    </div>
                    <div className="flex flex-col mb-3">
                      <label className="font-bold text-md" htmlFor="">
                        Departamento:
                      </label>
                      <DepartamentoSelect
                        value={
                          configJefesDirectos.departamento
                            ? configJefesDirectos.departamento
                            : null
                        }
                        onChange={onFilterJefesDirectos("departamento")}
                      />
                    </div>
                  </div>
                  <div className="w-full mt-8">
                    <button
                      className="w-full bg-sky-500 hover:bg-sky-400 text-white py-4 px-4 rounded-md text-sm"
                      onClick={onSaveConfig}
                    >
                      Guardar configuración
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        </div>
      </div>
    </FeedbackConfiguracionLayout>
  );
}
