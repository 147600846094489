import React, { useState } from "react";
import { X, XLg } from "react-bootstrap-icons";
import { useDispatch, useSelector } from "react-redux";
import Input from "src/modules/common/components/Input";
import Modal from "src/modules/common/components/Modal";
import { cargoActions } from "../handlers/redux";
import CustomAlert from "src/modules/common/components/CustomAlert";

function CargoModal(props) {
  const dispatch = useDispatch();
  const modal = useSelector((state) => state.cargo.cargo_create_modal);
  const cargo = useSelector((state) => state.cargo.cargo);
  const [showAlert, setShowAlert] = useState(false);

  const setOpen = (open) => {
    dispatch(cargoActions.setCargoCreateModal({ open }));
  };

  const onChange =
    (name) =>
    ({ target }) => {
      dispatch(cargoActions.cargoSet({ ...cargo, [name]: target.value }));
    };

  const showEditAlert = (e) => {
    e.preventDefault();
    setShowAlert(true);
  };

  const onAceptarAlert = (e) => {
    setShowAlert(false);
    onSubmit();
  };
  const onCancelarAlert = () => {
    setShowAlert(false);
  };

  const onSubmit = (event) => {
    event?.preventDefault();
    //
    if (cargo.id) {
      dispatch(cargoActions.cargoUpdate());
      // setOpen(false);
      return;
    }
    dispatch(cargoActions.cargoCreate());
    // setOpen(false);
  };

  return (
    <Modal isOpen={modal.open}>
      <CustomAlert
        message="Atención: este cambio afectará a todos los colaboradores que tengan asignado este cargo."
        visible={showAlert}
        onAceptarHandler={onAceptarAlert}
        onCancelarHandler={onCancelarAlert}
      ></CustomAlert>
      <div className=" rounded-lg bg-white w-6/12">
        <form
          onSubmit={cargo.id ? showEditAlert : onSubmit}
          className="flex flex-col gap-3 p-4"
        >
          <div className="flex flex-row justify-between text-lg font-bold">
            <div>Cargo</div>
            <div onClick={() => setOpen(false)}>
              <XLg />
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <div className="text-slate-600 text-sm font-bold">Nombre</div>
            <Input
              value={cargo.nombre || ""}
              onChange={onChange("nombre")}
            ></Input>
          </div>
          <div className="flex flex-col gap-2">
            <div className="text-slate-600 text-sm font-bold">Descripcion</div>
            <Input
              value={cargo.descripcion || ""}
              onChange={onChange("descripcion")}
            ></Input>
          </div>
          <div className="flex flex-row gap-2">
            <button
              type="submit"
              className="bg-sky-500 text-white rounded-md px-3 py-2 active:bg-gray-200"
            >
              Guardar
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
}

export default CargoModal;
