import { PeopleFill } from "react-bootstrap-icons";

function StatisticVertical({
  title = "No title",
  percentage = 0,
  amount = 0,
  titleDotColor = "red",
}) {
  return (
    <div className="flex flex-col gap-2 w-full">
      <div className="flex items-center gap-2 w-full">
        <div className={`bg-${titleDotColor}-500 w-2 h-2 rounded-md`}></div>
        <div>{title}</div>
      </div>
      <div className="flex items-center justify-around">
        <div>{percentage}%</div>
        <div className="flex items-center gap-1">
          <PeopleFill color={"lightgrey"}></PeopleFill>
          <div>{amount}</div>
        </div>
      </div>
    </div>
  );
}

export default StatisticVertical;
