import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CheckInput } from "src/modules/common/components/CheckInput";
import CustomAlert from "src/modules/common/components/CustomAlert";
import { ErrorToast } from "src/modules/common/components/ErrorToast";
import { evaluacionActions } from "../handlers/redux";

function EvaluadoresDefectoMasivo() {
  const dispatch = useDispatch();
  const formularios_checked = useSelector(
    (state) => state.evaluacion.formularios_checked
  );
  const initOptions = {
    jefe: false,
    paresChecked: false,
    pares: 0,
    subalternosChecked: false,
    subalternos: 0,
    autoevaluacion: false,
  };
  const [options, setOptions] = useState(initOptions);

  const onCheck =
    (field) =>
    ({ target }) => {
      let optionsCopy = { ...options, [field]: target.checked };
      //
      if (!optionsCopy.paresChecked) {
        optionsCopy.pares = 0;
      }
      if (!optionsCopy.subalternosChecked) {
        optionsCopy.subalternos = 0;
      }
      //
      setOptions(optionsCopy);
    };

  const onChange =
    (field) =>
    ({ target }) => {
      let optionsCopy = { ...options, [field]: target.value };
      setOptions(optionsCopy);
    };

  const onSubmit = () => {
    !!process.env.REACT_APP_DEBUG && console.log(options);
    if (formularios_checked.length < 1) {
      ErrorToast({ message: "Debe seleccionar al menos un formulario." });
      return;
    }
    const { jefe, autoevaluacion, paresChecked, subalternosChecked } = options;
    if (!jefe && !autoevaluacion && !paresChecked && !subalternosChecked) {
      ErrorToast({ message: "Debe seleccionar al menos una opción." });
      return;
    }
    dispatch(evaluacionActions.agregarEvaluadoresDefault(options));
  };
  //
  const [showAlert, setShowAlert] = useState(false);
  const onAceptarAlert = () => {
    onSubmit();
    setShowAlert(false);
  };
  const onCancelarAlert = () => {
    setShowAlert(false);
  };
  const mostrarAlerta = () => {
    setShowAlert(true);
  };
  //
  return (
    <div className="flex flex-col gap-6 p-2">
      <CustomAlert
        message="Solo se procesarán los datos que cumplan con los parámetros de asignación."
        visible={showAlert}
        onAceptarHandler={onAceptarAlert}
        onCancelarHandler={onCancelarAlert}
      ></CustomAlert>
      <div className="flex items-start gap-4">
        <CheckInput
          checked={options.jefe}
          onChange={onCheck("jefe")}
        ></CheckInput>
        <div>
          <div>Jefe directo</div>
          <div>
            Añade por defecto a los jefes directos de los colaboradores
            seleccionados
          </div>
        </div>
      </div>
      <div className="flex items-start gap-4">
        <CheckInput
          checked={options.paresChecked}
          onChange={onCheck("paresChecked")}
        ></CheckInput>
        <div>
          <div>Pares</div>
          <div>
            Añade por defecto a los pares de los colaboradores seleccionados
          </div>
          <div>
            Hasta{" "}
            <input
              type="number"
              className={`${
                !options.paresChecked ? "bg-slate-200 " : ""
              }border border-slate-300 rounded-md p-2 w-20`}
              value={options.pares}
              onChange={onChange("pares")}
              disabled={!options.paresChecked}
            />{" "}
            pares
          </div>
        </div>
      </div>
      <div className="flex items-start gap-4">
        <CheckInput
          checked={options.subalternosChecked}
          onChange={onCheck("subalternosChecked")}
        ></CheckInput>
        <div>
          <div>Subalternos</div>
          <div>
            Añade por defecto a los subalternos de los colaboradores
            seleccionados
          </div>
          <div>
            Hasta{" "}
            <input
              type="number"
              onChange={onChange("subalternos")}
              className={`${
                !options.subalternosChecked ? "bg-slate-200 " : ""
              }border border-slate-300 rounded-md p-2 w-20`}
              value={options.subalternos}
              disabled={!options.subalternosChecked}
            />{" "}
            subalternos
          </div>
        </div>
      </div>
      <div className="flex items-start gap-4">
        <CheckInput
          checked={options.autoevaluacion}
          onChange={onCheck("autoevaluacion")}
        ></CheckInput>
        <div>
          <div>Autoevaluación</div>
          <div>
            Añade por defecto un formulario de autoevalución a los colaboradores
            seleccionados
          </div>
        </div>
      </div>
      <div>
        <button
          onClick={mostrarAlerta}
          className="bg-sky-500 p-2 text-white w-full rounded-md"
        >
          Aceptar
        </button>
      </div>
    </div>
  );
}

export default EvaluadoresDefectoMasivo;
