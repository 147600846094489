import axios from "axios";

export function DescargarColaboradoresKpisInforme(
  token = "",
  onComplete = null
) {
  const URL_BASE =
    process.env.REACT_APP_API_URL || "http://localhost:8000/api/admin";
  const requestURL = URL_BASE + `/kpi/descargar-listado-colaboradores`;
  const headers = {
    "Content-Type": "blob",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };
  //
  axios({
    url: requestURL,
    method: "GET",
    headers: headers,
    responseType: "blob",
  })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `listado-colaboradores-kpis.xlsx`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
      //
      if (!!onComplete) {
        onComplete();
      }
    })
    .catch((e) => {
      console.info("Error al descargar el archivo");
      if (!!onComplete) {
        onComplete();
      }
    });
}

export function DownloadPlantilla({ plantilla, token = "" }) {
  !!process.env.REACT_APP_DEBUG && console.info("PLANTILLA", plantilla);
  const URL_BASE =
    process.env.REACT_APP_API_URL || "http://localhost:8000/api/admin";
  const requestURL = URL_BASE + `/kpi/descargar-plantilla-${plantilla}/`;
  const headers = {
    "Content-Type": "blob",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };
  //
  axios({
    url: requestURL,
    method: "GET",
    headers: headers,
    responseType: "blob",
  })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      // La general de antes ahora se volvió parcial y el general ahora es uno con más datos
      const nameFile = `Plantilla para importacion de KPIs ${
        plantilla === "por-colaborador" ? " (por colaborador)" : "(por cargo)"
      }`;
      link.setAttribute("download", `${nameFile}.xlsx`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    })
    .catch(
      (e) =>
        !!process.env.REACT_APP_DEBUG &&
        console.info("Error al descargar el archivo")
    );
}
