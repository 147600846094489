import React from "react";
import { X, XLg } from "react-bootstrap-icons";
import { useDispatch, useSelector } from "react-redux";
import Input from "src/modules/common/components/Input";
import Modal from "src/modules/common/components/Modal";
import { competenciaCargoActions } from "../handlers/redux";

function CompetenciaCargoModal(props) {
  const dispatch = useDispatch();
  const modal = useSelector(
    (state) => state.competenciaCargo.competencia_cargo_create_modal
  );
  const competenciaCargo = useSelector(
    (state) => state.competenciaCargo.competencia_cargo
  );

  const setOpen = (open) => {
    dispatch(competenciaCargoActions.setCompetenciaCargoCreateModal({ open }));
  };

  const onChange =
    (name) =>
    ({ target }) => {
      dispatch(
        competenciaCargoActions.competenciaCargoSet({
          ...competenciaCargo,
          [name]: target.value,
        })
      );
    };

  const onSubmit = (event) => {
    event.preventDefault();
    if (!competenciaCargo.nombre || !competenciaCargo.descripcion) return;
    if (competenciaCargo.id) {
      dispatch(competenciaCargoActions.competenciaCargoUpdate());
      setOpen(false);
      return;
    }
    dispatch(competenciaCargoActions.competenciaCargoCreate());
    setOpen(false);
  };

  return (
    <Modal isOpen={modal.open}>
      <div className=" rounded-lg bg-white w-6/12">
        <form onSubmit={onSubmit} className="flex flex-col gap-3 p-4">
          <div className="flex flex-row justify-between text-lg font-bold">
            <div>CompetenciaCargo</div>
            <div onClick={() => setOpen(false)}>
              <XLg />
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <div className="text-slate-600 text-sm font-bold">Nombre</div>
            <Input
              value={competenciaCargo.nombre || ""}
              onChange={onChange("nombre")}
            ></Input>
          </div>
          <div className="flex flex-col gap-2">
            <div className="text-slate-600 text-sm font-bold">Descripcion</div>
            <Input
              value={competenciaCargo.descripcion || ""}
              onChange={onChange("descripcion")}
            ></Input>
          </div>
          <div className="flex flex-row gap-2">
            <button
              type="submit"
              className="bg-sky-500 text-white rounded-md px-3 py-2 active:bg-gray-200"
            >
              Guardar
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
}

export default CompetenciaCargoModal;
