import axios from "axios";
const URL_BASE =
  process.env.REACT_APP_API_URL || "http://localhost:8000/api/admin";

export const evaluacionApi = (token) => {
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };

  return {
    list: async ({ evaluaciones_filter }) => {
      const { nombre = "", pagination = { page: 1, pageSize: 100 } } =
        evaluaciones_filter;
      const { page, pageSize } = pagination;
      try {
        const evaluaciones = await axios.get(
          `${URL_BASE}/evaluacion/?search=${nombre}&page=${page}&page_size=${pageSize}`,
          {
            headers,
          }
        );
        !!process.env.REACT_APP_DEBUG &&
          console.log("evaluacion list api", evaluaciones);
        return evaluaciones.data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG &&
          console.log("evaluaciones error", error);
        throw error;
      }
    },
    detail: async ({ id }) => {
      try {
        const evaluacion = await axios.get(
          URL_BASE + `/evaluacion/${id}/detail`,
          {
            headers,
          }
        );
        return evaluacion.data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log(JSON.stringify(error));
        throw error;
      }
    },
    cambios: async ({ id }) => {
      try {
        const response = await axios.get(
          URL_BASE + `/evaluacion/${id}/cambios`,
          {
            headers,
          }
        );
        return response.data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log(JSON.stringify(error));
        throw error;
      }
    },
    formularios: async (formularios_filter) => {
      const {
        id,
        nombre = "",
        evaluador = "",
        calibrador = "",
        area = "",
        sucursal = "",
        departamento = "",
        relacion = "",
        estado = "",
        puntaje = "",
        ordering = "",
        pagination = { page: 1, pageSize: 100 },
      } = formularios_filter;
      if (!id) {
        return;
      }
      const { page, pageSize } = pagination;
      console.log("formularios api", formularios_filter);
      //
      let searchURL = `formularios/?search=${nombre}`;
      if (evaluador) searchURL = searchURL + `&evaluador_nombre=${evaluador}`;
      //
      if (calibrador)
        searchURL = searchURL + `&calibrador_nombre=${calibrador}`;
      if (area) searchURL = searchURL + `&area_id=${area}`;
      if (sucursal) searchURL = searchURL + `&sucursal_id=${sucursal}`;
      if (departamento)
        searchURL = searchURL + `&departamento_id=${departamento}`;
      if (relacion) searchURL = searchURL + `&relacion=${relacion}`;
      if (estado) searchURL = searchURL + `&estado=${estado}`;
      if (puntaje) searchURL = searchURL + `&puntaje=${puntaje}`;
      //
      let orderingC = ordering.replace(",", "");
      if (ordering) searchURL = searchURL + `&ordering=${orderingC}`;
      try {
        const formularios = await axios.get(
          `${URL_BASE}/evaluacion/${id}/${searchURL}&page=${page}&page_size=${pageSize}`,
          {
            headers,
          }
        );
        !!process.env.REACT_APP_DEBUG &&
          console.log("formularios list api", formularios);
        return formularios.data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG &&
          console.log("formularios error", error);
        throw error;
      }
    },
    create: async (params) => {
      try {
        const evaluacion = await axios.post(
          URL_BASE + "/evaluacion/create",
          params,
          { headers }
        );
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log(JSON.stringify(error));
        throw error;
      }
    },
    update: async ({ id, ...params }) => {
      try {
        const evaluacion = await axios.put(
          URL_BASE + `/evaluacion/${id}/update`,
          params,
          {
            headers,
          }
        );
        return evaluacion;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log(JSON.stringify(error));
        throw error;
      }
    },
    delete: async ({ id }) => {
      try {
        const data = await axios.delete(URL_BASE + `/evaluacion/${id}/delete`, {
          headers,
        });
        return data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log(JSON.stringify(error));
        throw error;
      }
    },
    bulkDelete: async (evaluacion_ids) => {
      const params = { evaluacion_ids: evaluacion_ids };
      try {
        const response = await axios.post(
          URL_BASE + `/evaluacion/bulk-delete`,
          params,
          {
            headers,
          }
        );
        return response;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log(JSON.stringify(error));
        throw error;
      }
    },
    reabrir: async ({ id }) => {
      try {
        const data = await axios.post(
          URL_BASE + `/evaluacion/${id}/reabrir`,
          {},
          {
            headers,
          }
        );
        return data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log(JSON.stringify(error));
        throw error;
      }
    },
    finalizar: async ({ id }) => {
      !!process.env.REACT_APP_DEBUG && console.log("finalizar api", headers);
      // return;
      try {
        const data = await axios.post(
          URL_BASE + `/evaluacion/${id}/finalizar`,
          {},
          {
            headers,
          }
        );
        return data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log(JSON.stringify(error));
        throw error;
      }
    },
    descargarPdf: async ({ evaluacion, formulario }) => {
      try {
        const data = await axios.post(
          URL_BASE +
            `/evaluacion/${evaluacion.id}/formulario/${formulario.id}/exportar`,
          {},
          {
            headers,
          }
        );
        return data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log(JSON.stringify(error));
        throw error;
      }
    },
    // crear/editar formularios
    formularioDetalle: async (params) => {
      const { id, formularioId } = params;
      try {
        const formulario = await axios.get(
          `${URL_BASE}/evaluacion/${id}/formulario/${formularioId}/detail`,
          {
            headers,
          }
        );
        !!process.env.REACT_APP_DEBUG &&
          console.log("formularioDetalle detail api", formulario);
        return formulario.data;
      } catch (error) {
        throw !!process.env.REACT_APP_DEBUG && console.error();
      }
    },
    eliminarFormulario: async ({ id, formularioId }) => {
      try {
        const data = await axios.delete(
          URL_BASE + `/evaluacion/${id}/formulario/${formularioId}/delete`,
          {
            headers,
          }
        );
        return data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log(JSON.stringify(error));
        throw error;
      }
    },
    colaboradoresValidos: async (colaboradores_validos_filter) => {
      const {
        id,
        nombre = "",
        area,
        departamento,
        sucursal,
        pagination = {},
      } = colaboradores_validos_filter;
      const { page = 1, pageSize = 50 } = pagination;
      let searchURL = `colaboradores-seleccionables?search=${nombre}&page=${page}&page_size=${pageSize}`;
      if (area) {
        searchURL = searchURL + `&area_id=${area}`;
      }
      if (departamento) {
        searchURL = searchURL + `&departamento_id=${departamento}`;
      }
      if (sucursal) {
        searchURL = searchURL + `&sucursal_id=${sucursal}`;
      }
      try {
        const colaboradores = await axios.get(
          `${URL_BASE}/evaluacion/${id}/${searchURL}`,
          {
            headers,
          }
        );
        !!process.env.REACT_APP_DEBUG &&
          console.log("colaboradores v list api", colaboradores);
        return colaboradores.data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG &&
          console.log("colaboradores v error", error);
        throw error;
      }
    },
    agregarColaborador: async (params) => {
      const { id, colaboradores_ids } = params;
      try {
        const formularios = await axios.post(
          URL_BASE + `/evaluacion/${id}/formulario/crear-por-colaborador/`,
          { colaboradores: colaboradores_ids },
          { headers }
        );
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log(JSON.stringify(error));
        throw error;
      }
    },
    evaluadoresColaborador: async (evaluadores_filter) => {
      const { id, colaboradorId, nombre = "" } = evaluadores_filter;
      !!process.env.REACT_APP_DEBUG &&
        console.log("evaluadoresColaborador", evaluadores_filter);
      try {
        const evaluaciones = await axios.get(
          `${URL_BASE}/evaluacion/${id}/colaborador/${colaboradorId}/evaluadores?search=${nombre}`,
          {
            headers,
          }
        );
        //
        return evaluaciones.data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG &&
          console.log("evaluaciones error", error);
        throw error;
      }
    },
    agregarEvaluador: async (params) => {
      const { id, formularioId, evaluadores_ids, relacion } = params;
      try {
        const formularios = await axios.post(
          URL_BASE +
            `/evaluacion/${id}/formulario/${formularioId}/add-evaluadores`,
          { evaluadores: evaluadores_ids, relacion: relacion },
          { headers }
        );
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log(JSON.stringify(error));
        throw error;
      }
    },
    agregarEvaluadorDefecto: async (params) => {
      !!process.env.REACT_APP_DEBUG &&
        console.log("agregarEvaluadorDefecto api params", params);
      const { id, formularioId, opciones_por_defecto } = params;
      try {
        const formularios = await axios.post(
          URL_BASE +
            `/evaluacion/${id}/formulario/${formularioId}/add-evaluadores-default`,
          { ...opciones_por_defecto },
          { headers }
        );
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log(JSON.stringify(error));
        throw error;
      }
    },
    ////
    agregarEvaluadoresDefecto: async (params) => {
      const { id, formulariosIds, opciones_por_defecto } = params;
      try {
        !!process.env.REACT_APP_DEBUG &&
          console.log("agregarEvaluadoresDefecto api args", {
            formularios_ids: formulariosIds,
            ...opciones_por_defecto,
          });
        const response = await axios.post(
          URL_BASE + `/evaluacion/${id}/formularios/add-evaluadores-default`,
          { formularios_ids: formulariosIds, ...opciones_por_defecto },
          { headers }
        );
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log(JSON.stringify(error));
        throw error;
      }
    },
    agregarCalibradores: async (params) => {
      !!process.env.REACT_APP_DEBUG &&
        console.log("agregarCalibradores api params", params);
      const {
        id,
        formulariosIds,
        calibradorId,
        ignorar_errores = true,
      } = params;
      try {
        const formularios = await axios.post(
          URL_BASE + `/evaluacion/${id}/formularios/add-calibrador`,
          {
            formularios_ids: formulariosIds,
            calibrador_id: calibradorId,
            ignorar_errores: ignorar_errores,
          },
          { headers }
        );
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log(JSON.stringify(error));
        throw error;
      }
    },
    agregarCalibradoresDefecto: async (params) => {
      !!process.env.REACT_APP_DEBUG &&
        console.log("agregarCalibradoresDefecto api params", params);
      const { id, formulariosIds, ignorar_errores = true } = params;
      try {
        const response = await axios.post(
          URL_BASE + `/evaluacion/${id}/formularios/add-calibrador-default`,
          { formularios_ids: formulariosIds, ignorar_errores: ignorar_errores },
          { headers }
        );
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log(JSON.stringify(error));
        throw error;
      }
    },
    agregarConfiguraciones: async (params) => {
      const {
        id,
        formulariosIds,
        configuracionId,
        ignorar_errores = true,
      } = params;
      try {
        const formulario = await axios.post(
          URL_BASE + `/evaluacion/${id}/formularios/add-configuracion`,
          {
            formularios_ids: formulariosIds,
            configuracion_id: configuracionId,
            ignorar_errores: ignorar_errores,
          },
          { headers }
        );
      } catch (error) {
        throw error;
      }
    },
    // /
    agregarCalibrador: async (params) => {
      !!process.env.REACT_APP_DEBUG &&
        console.log("agregarCalibrador api params", params);
      const { id, formularioId, calibradorId } = params;
      try {
        const formularios = await axios.put(
          URL_BASE +
            `/evaluacion/${id}/formulario/${formularioId}/add-calibrador`,
          { calibrador: calibradorId },
          { headers }
        );
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log(JSON.stringify(error));
        throw error;
      }
    },
    agregarCalibradorDefecto: async (params) => {
      !!process.env.REACT_APP_DEBUG &&
        console.log("agregarCalibradorDefecto api params", params);
      const { id, formularioId } = params;
      try {
        const response = await axios.post(
          URL_BASE +
            `/evaluacion/${id}/formulario/${formularioId}/add-calibrador-default`,
          {},
          { headers }
        );
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log(JSON.stringify(error));
        throw error;
      }
    },
    agregarConfiguracion: async (params) => {
      const { id, formularioId, configuracionId } = params;
      try {
        const formulario = await axios.put(
          URL_BASE +
            `/evaluacion/${id}/formulario/${formularioId}/add-configuracion`,
          { configuracion: configuracionId },
          { headers }
        );
      } catch (error) {
        throw error;
      }
    },
    //
    quitarEvaluador: async (params) => {
      const { id, formulariosIds } = params;
      try {
        const response = await axios.post(
          URL_BASE + `/evaluacion/${id}/formularios/quitar-evaluador`,
          {
            formularios_ids: formulariosIds,
          },
          { headers }
        );
      } catch (error) {
        throw error;
      }
    },
    quitarCalibrador: async (params) => {
      const { id, formulariosIds } = params;
      try {
        const response = await axios.post(
          URL_BASE + `/evaluacion/${id}/formularios/quitar-calibrador`,
          {
            formularios_ids: formulariosIds,
          },
          { headers }
        );
      } catch (error) {
        throw error;
      }
    },
    quitarConfiguracion: async (params) => {
      const { id, formulariosIds } = params;
      try {
        const response = await axios.post(
          URL_BASE + `/evaluacion/${id}/formularios/quitar-configuracion`,
          {
            formularios_ids: formulariosIds,
          },
          { headers }
        );
      } catch (error) {
        throw error;
      }
    },
    //
    eliminarFormularios: async (params) => {
      !!process.env.REACT_APP_DEBUG &&
        console.log("eliminarFormularios api params", params);
      const { id, formulariosIds } = params;
      try {
        const response = await axios.post(
          URL_BASE + `/evaluacion/${id}/formulario/formularios/eliminar`,
          {
            formularios_ids: formulariosIds,
          },
          { headers }
        );
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log(JSON.stringify(error));
        throw error;
      }
    },
    // envio de formularios
    enviarFormulario: async (params) => {
      !!process.env.REACT_APP_DEBUG &&
        console.log("enviarFormulario api params", params);
      const { id, formularioId } = params;
      try {
        const response = await axios.post(
          URL_BASE + `/evaluacion/${id}/formulario/${formularioId}/enviar`,
          {},
          { headers }
        );
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log(JSON.stringify(error));
        throw error;
      }
    },
    enviarFormularios: async (params) => {
      !!process.env.REACT_APP_DEBUG &&
        console.log("enviarFormularios api params", params);
      const { id, formulariosIds } = params;
      try {
        const response = await axios.post(
          URL_BASE + `/evaluacion/${id}/formulario/formularios/enviar`,
          {
            formularios_ids: formulariosIds,
          },
          { headers }
        );
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log(JSON.stringify(error));
        throw error;
      }
    },
    // configuracion de formularios
    listConfiguraciones: async (configuraciones_filter) => {
      !!process.env.REACT_APP_DEBUG && console.log("listConfiguraciones");
      const { id, nombre = "" } = configuraciones_filter;
      try {
        const configuraciones = await axios.get(
          `${URL_BASE}/evaluacion/${id}/configuracion-formulario?search=${nombre}`,
          {
            headers,
          }
        );
        !!process.env.REACT_APP_DEBUG &&
          console.log("configuraciones list api", configuraciones);
        return configuraciones.data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG &&
          console.log("configuraciones error", error);
        throw error;
      }
    },
    createConfiguracion: async (params) => {
      const { evaluacionId } = params;
      try {
        const configuracion = await axios.post(
          URL_BASE +
            `/evaluacion/${evaluacionId}/configuracion-formulario/create`,
          params,
          { headers }
        );
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log(JSON.stringify(error));
        throw error;
      }
    },
    updateConfiguracion: async (params) => {
      const { evaluacionId, configuracionId } = params;
      try {
        const configuracion = await axios.put(
          URL_BASE +
            `/evaluacion/${evaluacionId}/configuracion-formulario/${configuracionId}/update`,
          params,
          { headers }
        );
        return configuracion.data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log(JSON.stringify(error));
        throw error;
      }
    },
    deleteConfiguracion: async ({ evaluacionId, configuracionId }) => {
      try {
        const data = await axios.delete(
          URL_BASE +
            `/evaluacion/${evaluacionId}/configuracion-formulario/${configuracionId}/delete`,
          {
            headers,
          }
        );
        return data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log(JSON.stringify(error));
        throw error;
      }
    },
    // procesos de evaluacion
    evaluar: async (params) => {
      const { id, formularioId, data } = params;
      try {
        const response = await axios.put(
          `${URL_BASE}/evaluacion/${id}/formulario/${formularioId}/evaluar`,
          { ...data },
          {
            headers,
          }
        );
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log(JSON.stringify(error));
        throw error;
      }
    },
    calibrar: async (params) => {
      const { id, formularioId, data } = params;
      try {
        const response = await axios.put(
          `${URL_BASE}/evaluacion/${id}/formulario/${formularioId}/calibrar`,
          { ...data },
          {
            headers,
          }
        );
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log(JSON.stringify(error));
        throw error;
      }
    },
    devolver: async (params) => {
      const { id, formularioId, data } = params;
      try {
        const response = await axios.put(
          `${URL_BASE}/evaluacion/${id}/formulario/${formularioId}/devolver`,
          { ...data },
          {
            headers,
          }
        );
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log(JSON.stringify(error));
        throw error;
      }
    },
    comentar: async (params) => {
      const { id, formularioId, data } = params;
      try {
        const response = await axios.put(
          `${URL_BASE}/evaluacion/${id}/formulario/${formularioId}/comentar`,
          { ...data },
          {
            headers,
          }
        );
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log(JSON.stringify(error));
        throw error;
      }
    },
    //
    reiniciarFormulario: async (params) => {
      const { id, formularioId, data } = params;
      //
      try {
        const response = await axios.post(
          `${URL_BASE}/evaluacion/${id}/formulario/${formularioId}/reiniciar`,
          { ...data },
          {
            headers,
          }
        );
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log(JSON.stringify(error));
        throw error;
      }
    },
    reiniciarFormularios: async (params) => {
      const { id, data } = params;
      !!process.env.REACT_APP_DEBUG &&
        console.log("reiniciarFormularios api params", params);
      // return;
      try {
        const response = await axios.post(
          `${URL_BASE}/evaluacion/${id}/formulario/formularios/reiniciar`,
          { ...data },
          {
            headers,
          }
        );
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log(JSON.stringify(error));
        throw error;
      }
    },
    //==
    cambiosNomina: async (id) => {
      try {
        const response = await axios.get(
          `${URL_BASE}/evaluacion/${id}/cambios-nomina`,
          {
            headers,
          }
        );
        !!process.env.REACT_APP_DEBUG &&
          console.log("cambiosNomina api", response);
        return response.data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG &&
          console.log("cambiosNomina error", error);
        throw error;
      }
    },
    actualizarNomina: async (params) => {
      const { id, colaboradores } = params;
      try {
        const response = await axios.post(
          `${URL_BASE}/evaluacion/${id}/actualizar-nomina`,
          { colaboradores: colaboradores },
          { headers }
        );
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log(JSON.stringify(error));
        throw error;
      }
    },
    //==
    cambiosCompetencias: async (id) => {
      try {
        const response = await axios.get(
          `${URL_BASE}/evaluacion/${id}/cambios-competencias`,
          {
            headers,
          }
        );
        !!process.env.REACT_APP_DEBUG &&
          console.log("cambiosCompetencias api", response);
        return response.data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG &&
          console.log("cambiosCompetencias error", error);
        throw error;
      }
    },
    actualizarCompetencias: async (params) => {
      const { id, competencias } = params;
      try {
        const response = await axios.post(
          `${URL_BASE}/evaluacion/${id}/actualizar-competencias`,
          { competencias: competencias },
          { headers }
        );
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log(JSON.stringify(error));
        throw error;
      }
    },
    //==
    // Informes
    informeEvaluacion: async ({ id, ...params }) => {
      const { sucursal = "", departamento = "", area = "" } = params;
      try {
        const response = await axios.get(
          URL_BASE +
            `/evaluacion/${id}/informe?sucursal=${sucursal}&departamento=${departamento}&area=${area}`,
          {
            headers,
          }
        );
        return response.data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log(JSON.stringify(error));
        throw error;
      }
    },
    //
    informeEvaluacionConductas: async ({ id, ...params }) => {
      const { sucursal = "", departamento = "", area = "" } = params;
      try {
        const response = await axios.get(
          URL_BASE +
            `/evaluacion/${id}/informe-conductas?sucursal=${sucursal}&departamento=${departamento}&area=${area}`,
          {
            headers,
          }
        );
        return response.data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log(JSON.stringify(error));
        throw error;
      }
    },
    //
    informeColaborador: async ({ id, colaboradorId }) => {
      try {
        const response = await axios.get(
          URL_BASE + `/evaluacion/${id}/colaborador/${colaboradorId}/informe`,
          {
            headers,
          }
        );
        return response.data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log(JSON.stringify(error));
        throw error;
      }
    },
    colaboradoresPromedios: async (params) => {
      const {
        id,
        nombre = "",
        ordering = "",
        pagination = { page: 1, pageSize: 50 },
      } = params;
      let searchURL = `?search=${nombre}`;
      const { page, pageSize } = pagination;
      // return;
      try {
        const colaboradores = await axios.get(
          `${URL_BASE}/evaluacion/${id}/colaboradores/promedios${searchURL}&page=${page}&page_size=${pageSize}&ordering=${ordering}`,
          {
            headers,
          }
        );
        !!process.env.REACT_APP_DEBUG &&
          console.log("colaboradoresPromedios api", colaboradores);
        return colaboradores.data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG &&
          console.log("colaboradoresPromedios error", error);
        throw error;
      }
    },
    areasPromedios: async (params) => {
      const { id, nombre = "" } = params;
      let searchURL = `?search=${nombre}`;

      try {
        const colaboradores = await axios.get(
          `${URL_BASE}/evaluacion/${id}/areas/promedios${searchURL}`,
          {
            headers,
          }
        );
        !!process.env.REACT_APP_DEBUG &&
          console.log("colaboradoresPromediosAreas api", colaboradores);
        return colaboradores.data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG &&
          console.log("colaboradoresPromediosAreas error", error);
        throw error;
      }
    },
    sucursalesPromedios: async (params) => {
      const { id, nombre = "" } = params;
      let searchURL = `?search=${nombre}`;

      try {
        const colaboradores = await axios.get(
          `${URL_BASE}/evaluacion/${id}/sucursales/promedios${searchURL}`,
          {
            headers,
          }
        );
        !!process.env.REACT_APP_DEBUG &&
          console.log("colaboradoresPromediosSucursales api", colaboradores);
        return colaboradores.data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG &&
          console.log("colaboradoresPromediosSucursales error", error);
        throw error;
      }
    },
    departamentosPromedios: async (params) => {
      const { id, nombre = "" } = params;
      let searchURL = `?search=${nombre}`;

      try {
        const colaboradores = await axios.get(
          `${URL_BASE}/evaluacion/${id}/departamentos/promedios${searchURL}`,
          {
            headers,
          }
        );
        !!process.env.REACT_APP_DEBUG &&
          console.log("colaboradoresPromediosDepartamentos api", colaboradores);
        return colaboradores.data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG &&
          console.log("colaboradoresPromediosDepartamentos error", error);
        throw error;
      }
    },
    //
    getAreaColaboradores: async (params) => {
      const {
        id,
        areaId,
        nombre = "",
        ordering = "",
        pagination = { page: 1, pageSize: 50 },
      } = params;
      let searchURL = `?search=${nombre}`;
      const { page, pageSize } = pagination;
      //
      try {
        const colaboradores = await axios.get(
          `${URL_BASE}/evaluacion/${id}/areas/${areaId}/colaboradores${searchURL}&page=${page}&page_size=${pageSize}&ordering=${ordering}`,
          {
            headers,
          }
        );
        //
        console.log("### getAreaColaboradores API", colaboradores);
        return colaboradores.data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG &&
          console.error("getAreaColaboradores error", error);
        throw error;
      }
    },
    // Función para obtener colaboradores de una sucursal
    getSucursalColaboradores: async (params) => {
      const {
        id,
        sucursalId,
        nombre = "",
        ordering = "",
        pagination = { page: 1, pageSize: 50 },
      } = params;
      let searchURL = `?search=${nombre}`;
      const { page, pageSize } = pagination;
      //
      try {
        const colaboradores = await axios.get(
          `${URL_BASE}/evaluacion/${id}/sucursales/${sucursalId}/colaboradores${searchURL}&page=${page}&page_size=${pageSize}&ordering=${ordering}`,
          {
            headers,
          }
        );
        //
        console.log("### getSucursalColaboradores API", colaboradores);
        return colaboradores.data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG &&
          console.error("getSucursalColaboradores error", error);
        throw error;
      }
    },
    // Función para obtener colaboradores de un departamento
    getDepartamentoColaboradores: async (params) => {
      const {
        id,
        departamentoId,
        nombre = "",
        ordering = "",
        pagination = { page: 1, pageSize: 50 },
      } = params;
      let searchURL = `?search=${nombre}`;
      const { page, pageSize } = pagination;
      //
      try {
        const colaboradores = await axios.get(
          `${URL_BASE}/evaluacion/${id}/departamentos/${departamentoId}/colaboradores${searchURL}&page=${page}&page_size=${pageSize}&ordering=${ordering}`,
          {
            headers,
          }
        );
        //
        console.log("### getDepartamentoColaboradores API", colaboradores);
        return colaboradores.data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG &&
          console.error("getDepartamentoColaboradores error", error);
        throw error;
      }
    },
  };
};
