function CompetenciaLoading() {
  return (
    <div className="flex flex-col w-full h-60 border rounded-md animate-pulse">
      <div className="w-full h-14 bg-zinc-200"></div>
      <div className="flex flex-col gap-6 p-8">
        <div className="w-40 h-2 bg-slate-200 rounded-md"></div>
        <ul className="flex flex-col gap-6">
          <li className="h-1 w-32 bg-slate-200 rounded-md"></li>
          <li className="h-1 w-32 bg-slate-200 rounded-md"></li>
          <li className="h-1 w-32 bg-slate-200 rounded-md"></li>
        </ul>
      </div>
    </div>
  );
}

export default CompetenciaLoading;
