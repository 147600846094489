import { createRouterMiddleware } from "@lagunovsky/redux-react-router";
import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { createBrowserHistory } from "history";
import createSagaMiddleware from "redux-saga";
import { rootReducer } from "src/root/rootReducer";
import { rootSagas } from "src/root/rootSagas";

export const browserHistory = createBrowserHistory();

// middleware
const routerMiddleware = createRouterMiddleware(browserHistory);
let sagaMiddleware = createSagaMiddleware();

const middleware = [
  ...getDefaultMiddleware({
    thunk: false,
    serializableCheck: false,
    // serializableCheck: {
    //   ignoredActions: ["navigate/setNavigate"],
    //   ignoredPaths: ["navigate"],
    // },
  }),
  routerMiddleware,
  sagaMiddleware,
];

//
const store = configureStore({
  reducer: rootReducer(browserHistory),
  middleware,
});

sagaMiddleware.run(rootSagas);

export const rootStore = store;
