import React from "react";
import Estructura from "../pages/AppPage";
import ImportacionPage from "../pages/ImportacionPage";

export const estructuraRoutes = [
  {
    path: "/estructura",
    element: <Estructura />,
  },
];
