const Circulo = ({ title = "", count = 0, active = false }) => {
  let border = "border-blue-400";
  let background = "bg-zinc-100";
  let textColor = "text-zinc-400";

  if (active) {
    border = "border-sky-500";
    background = "";
    textColor = "";
  }

  return (
    <div
      className={`flex flex-col items-center justify-center rounded-full w-36 h-36 border-4 ${border}  ${background}`}
    >
      <div className={`${textColor} font-bold`}>{title}</div>
      <div className={`${textColor}`}>{count}</div>
    </div>
  );
};

export default Circulo;
