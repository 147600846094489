import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import {commonActions} from 'src/modules/common/handler/redux'

export function BeneficiosLayout({children}) {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(commonActions.setTitle("Beneficios"))
        dispatch(commonActions.showSidebar(true))
        dispatch(commonActions.setTitleMenu([
            {
                name: "Creados",
                to: "/beneficios/"
            },
            {
                name: "Cargos",
                to: "/beneficios/cargos/"
            },
            {
                name: "Colaboradores",
                to: "/beneficios/colaboradores/"
            }
        ]))
        return () => {
            commonActions.setTitleMenu([])
        }
    }, []);
    
    return <>{children}</>;
}