import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import FileInput from "src/modules/common/components/FileInput";
import SucursalModal from "src/modules/estructura/modules/sucursal/components/SucursalModal";
import { getToken } from "src/utils/helpers";
import { DownloadPlantilla } from "../../estructura/handlers/utils";
import { SucursalTable } from "../components/SucursalTable";
import { SucursalToolbar } from "../components/SucursalToolbar";
import { sucursalActions } from "../handlers/redux";
import SkeletonLoaderRow from "src/modules/common/components/SkeletonLoaderRow";
import TableCustom from "src/modules/common/components/TableCustom";
import { SucursalTableAccionMenu } from "../components/SucursalTableAccionMenu";

const SucursalListItem = ({ item, idx }) => {
  return (
    <div
      key={idx}
      className="hover:bg-sky-100 flex flex-row gap-2 py-4 align-middle w-full"
    >
      <div className="p-1 flex flex-grow flex-1 flex-col justify-center">
        <div className="font-bold">{item.nombre}</div>
        <div className="text-sm">{item.descripcion}</div>
      </div>
      <div className="p-1 text-center w-48x flex flex-row flex-1 justify-centerx items-center">
        {item.cantidad_colaboradores}
      </div>
      <div className="p-1 w-12 flex flex-row items-center">
        <SucursalTableAccionMenu item={item} />
      </div>
    </div>
  );
};

function SucursalPage() {
  const dispatch = useDispatch();
  const token = getToken();

  useEffect(() => {
    dispatch(sucursalActions.sucursales());
  }, []);

  const sucursales = useSelector((state) => state.sucursal.sucursales);
  const sucursales_checked = useSelector(
    (state) => state.sucursal.sucursales_checked
  );
  const sucursales_loading = useSelector(
    (state) => state.sucursal.sucursales_loading
  );
  const sucursales_filter = useSelector(
    (state) => state.sucursal.sucursales_filter
  );
  const { nombre = "" } = sucursales_filter;

  const { results = [], count: sucursalesCount = 0 } = sucursales;

  const uploadFile = (file) => {
    dispatch(sucursalActions.setFileToUpload(file));
  };

  const onPlantilla = () => {
    DownloadPlantilla({ token });
  };

  const showSucursalModal = () => {
    dispatch(sucursalActions.sucursaleset({}));
    dispatch(sucursalActions.setSucursalCreateModal({ open: true }));
  };

  const onCheckSucursales = (event, item) => {
    dispatch(
      sucursalActions.checkSucursal({
        sucursal: item,
        checked: event.target.checked,
      })
    );
  };

  const onSelectChange = (v) => {
    if (v.value === "cancel") {
      dispatch(sucursalActions.resetCheckSucursales());
    } else if (v.value === "eliminar") {
      let deseaEliminar = window.confirm(
        "Desea eliminar las sucursales seleccionadas?"
      );
      if (deseaEliminar)
        dispatch(sucursalActions.setSucursalesDelete(sucursales_checked));
    } else {
      // @ts-ignore
      window.alert(JSON.stringify(v));
    }
  };

  const onChangeSearch = (event) => {
    dispatch(
      sucursalActions.setSucursalesFilter({
        ...sucursales_filter,
        nombre: event.target.value,
      })
    );
  };

  const onPaginate = (page, pageSize) => {
    dispatch(
      sucursalActions.setSucursalesFilter({
        ...sucursales_filter,
        pagination: {
          page: page,
          pageSize: pageSize,
        },
      })
    );
  };

  return (
    <div className="md:w-11/12">
      <div className="flex flex-col gap-2">
        <div className="text-xl font-bold ">Sucursales</div>
        <div className="border-b border-b-gray-200 mb-3"></div>
        {!sucursales_loading && (
          <TableCustom
            fixedHeigth={true}
            data={results || []}
            customHeaders={["Colaboradores"]}
            tableTitle={""}
            tableSecondTitle={"Lista de sucursales creadas"}
            subject={"sucursal"}
            subjectGender={"F"}
            ItemComponent={SucursalListItem}
            addBtnClickHandler={showSucursalModal}
            itemsChecked={sucursales_checked}
            onCheckHandler={onCheckSucursales}
            onSelectChange={onSelectChange}
            selectOptions={[
              { value: "eliminar", label: "Eliminar" },
              { value: "cancel", label: "Cancelar" },
            ]}
            searchValue={nombre}
            onChangeSearch={onChangeSearch}
            noBottomButtom={true}
            totalPaginate={sucursalesCount}
            onPaginate={onPaginate}
          />
        )}
        {!!sucursales_loading && (
          <div className="mt-28">
            {[1, 2, 3].map((r, i) => (
              <SkeletonLoaderRow key={i}></SkeletonLoaderRow>
            ))}
          </div>
        )}
      </div>
      <FileInput
        className={"mt-3"}
        onSubmit={uploadFile}
        onPlantilla={onPlantilla}
      />
      <SucursalModal></SucursalModal>
    </div>
  );
}

export default SucursalPage;
