import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CheckInput } from "src/modules/common/components/CheckInput";
import { ProfileInfoCard } from "src/modules/common/components/ProfileInfoCard";
import { kpiActions } from "../handlers/redux";

export const ColaboradoresKPITable = () => {
  const dispatch = useDispatch();

  // @ts-ignore
  const colaboradores = useSelector(
    (state) => state.kpi.colaboradores.results || []
  );

  const cargos = useSelector((state) => state.cargo?.cargos?.results || []);

  const colaboradores_checked = useSelector(
    (state) => state.kpi.colaboradores_checked
  );
  // const { results = [] } = colaboradores;

  useEffect(() => {
    dispatch(kpiActions.colaboradores());
    !!process.env.REACT_APP_DEBUG && console.info("ckpitable", colaboradores);
  }, []);

  const renderTableHeader = () => {
    return (
      <div className="border-b flex flex-col border-zinc-100 ">
        <div className="flex flex-row">
          <div className="p-1 font-bold flex-grow">Colaboradores</div>

          <div className=" w-6"></div>
        </div>
      </div>
    );
  };

  const renderTableItem = (item, idx) => {
    let { cargo } = item;
    cargo = cargos.find((v) => v.id == cargo) || {};

    return (
      <div
        key={idx}
        className="hover:bg-sky-100 flex flex-row border-b align-middle "
      >
        <div className="p-1 h-16 align-text-top  flex flex-row items-center">
          <CheckInput
            checked={colaboradores_checked.includes(item.id)}
            onChange={(event) => {
              dispatch(
                kpiActions.checkColaborador({
                  colaborador: item,
                  checked: event.target.checked,
                })
              );
            }}
          />
        </div>
        <div className="p-1 flex flex-grow flex-col justify-center">
          <ProfileInfoCard name={item.nombre}>
            <div className="text-sm">
              Cargo: {!!cargo?.nombre ? cargo.nombre : "Sin especificar"}
            </div>
          </ProfileInfoCard>
        </div>
        <div className="p-1 w-12 flex flex-row items-center"></div>
      </div>
    );
  };

  return (
    <div>
      <div className="flex flex-col w-full">
        {renderTableHeader()}
        <div className="flex flex-col  h-72 overflow-y-scroll">
          {colaboradores.map(renderTableItem)}
        </div>
      </div>
    </div>
  );
};
