import React from "react";
import RemuneracionEscalaSalarial from "../pages/RemuneracionEscalaSalarial";
import RemuneracionIncrementosSalariales from "../pages/RemuneracionIncrementosSalariales";
import RemuneracionSalarios from "../pages/RemuneracionSalarios";
import RemuneracionConfiguracion from "../pages/ARemuneracionConfiguracion";
import RemuneracionValoracion from "../pages/BRemuneracionValoracion";
import RemuneracionCategorizacion from "../pages/CRemuneracionCategorizacion";
import RemuneracionEscala from "../pages/DRemuneracionEscala";
import RemuneracionEscalaDetalle from "../pages/RemuneracionEscalaDetalle";
import RemuneracionSalariosMercado from "../pages/RemuneracionSalariosMercado";

export const remuneracionRoutes = [
  {
    path: "/remuneracion/incrementos", // [0]
    element: <RemuneracionIncrementosSalariales />,
  },
  // ESCALA
  {
    path: "/remuneracion/escala", // [1]
    element: <RemuneracionEscalaSalarial />,
  },
  {
    // create
    path: "/remuneracion/escala/configuracion", // [2]
    element: <RemuneracionConfiguracion />,
  },
  {
    // edit
    path: "/remuneracion/escala/:idEscala/configuracion", // [3]
    element: <RemuneracionConfiguracion />,
  },
  {
    path: "/remuneracion/escala/:idEscala/valoracion", // [4]
    element: <RemuneracionValoracion />,
  },
  {
    path: "/remuneracion/escala/:idEscala/categorizacion", // [5]
    element: <RemuneracionCategorizacion />,
  },
  {
    path: "/remuneracion/escala/:idEscala/escala", // [6]
    element: <RemuneracionEscala />,
  },
  {
    path: "/remuneracion/escala/:idEscala/detalle", // [7]
    element: <RemuneracionEscalaDetalle />,
  },
  // SALARIOS
  {
    path: "/remuneracion/salarios", // [8]
    element: <RemuneracionSalarios />,
  },
  {
    path: "/remuneracion/salarios-mercado", // [9]
    element: <RemuneracionSalariosMercado />,
  },
];

export const RutasRemuneracion = {
  INCREMENTOS: remuneracionRoutes[0].path,
  //
  ESCALA: remuneracionRoutes[1].path,
  ESCALA_CONFIGURACION: (id) =>
    !!id
      ? remuneracionRoutes[3].path.replace(":idEscala", id)
      : remuneracionRoutes[2].path,
  ESCALA_VALORACION: (id) =>
    remuneracionRoutes[4].path.replace(":idEscala", id),
  ESCALA_CATEGORIZACION: (id) =>
    remuneracionRoutes[5].path.replace(":idEscala", id),
  ESCALA_ESCALA: (id) => remuneracionRoutes[6].path.replace(":idEscala", id),
  ESCALA_DETALLE: (id) => remuneracionRoutes[7].path.replace(":idEscala", id),
  //
  SALARIOS: remuneracionRoutes[8].path,
  SALARIOS_MERCADO: remuneracionRoutes[9].path,
};
