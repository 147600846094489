import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import { ObjectToString, getToken } from "src/utils/helpers";
import { SuccessToast } from "src/modules/common/components/SuccessToast";
import { ErrorToast } from "src/modules/common/components/ErrorToast";
import { politicaApi } from "./api";
import { politicaActions } from "./redux";

function* getPoliticasEvaluacionSaga({ payload }) {
  const token = getToken();
  const politicas_evaluacion_filter = yield select(
    (state) => state.politica.politicas_evaluacion_filter
  );
  //
  try {
    const data = yield call(
      politicaApi(token).evaluacion,
      politicas_evaluacion_filter
    );
    yield put(politicaActions.setPoliticasEvaluacion(data.results));
    yield put(politicaActions.setPoliticasEvaluacionCount(data.count));
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
    ErrorToast({ message: "Ocurrió un error" });
    yield put(politicaActions.setPoliticasEvaluacion([]));
    yield put(politicaActions.setPoliticasEvaluacionCount(0));
  }
}
function* getPoliticasRemuneracionSaga({ payload }) {
  const token = getToken();
  const politicas_remuneracion_filter = yield select(
    (state) => state.politica.politicas_remuneracion_filter
  );
  //
  try {
    const data = yield call(
      politicaApi(token).remuneracion,
      politicas_remuneracion_filter
    );
    yield put(politicaActions.setPoliticasRemuneracion(data.results));
    yield put(politicaActions.setPoliticasRemuneracionCount(data.count));
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
    ErrorToast({ message: "Ocurió un error" });
    yield put(politicaActions.setPoliticasRemuneracion([]));
    yield put(politicaActions.setPoliticasRemuneracionCount(0));
  }
}
function* getPoliticasPlanCarreraSaga({ payload }) {
  const token = getToken();
  const politicas_plan_carrera_filter = yield select(
    (state) => state.politica.politicas_plan_carrera_filter
  );
  //
  try {
    const data = yield call(
      politicaApi(token).planCarrera,
      politicas_plan_carrera_filter
    );
    yield put(politicaActions.setPoliticasPlanCarrera(data.results));
    yield put(politicaActions.setPoliticasPlanCarreraCount(data.count));
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
    ErrorToast({ message: "Ocurió un error" });
    yield put(politicaActions.setPoliticasPlanCarrera([]));
    yield put(politicaActions.setPoliticasPlanCarreraCount(0));
  }
}
// ACCIONES GENERALES
function* deletePoliticaSaga({ payload }) {
  const token = getToken();
  //
  try {
    const data = yield call(politicaApi(token).delete, payload);
    yield put(politicaActions.getPoliticasEvaluacion());
    yield put(politicaActions.getPoliticasRemuneracion());
    yield put(politicaActions.getPoliticasPlanCarrera());
    SuccessToast({ message: "Archivo eliminado con éxito" });
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
    ErrorToast({ message: "Ocurrió un error" });
  }
}
function* bulkDeletePoliticaSaga({ payload }) {
  const token = getToken();
  //
  try {
    const data = yield call(politicaApi(token).bulkDelete, payload);
    yield put(politicaActions.getPoliticasEvaluacion());
    yield put(politicaActions.getPoliticasRemuneracion());
    yield put(politicaActions.getPoliticasPlanCarrera());
    yield put(politicaActions.resetCheckPoliticaEvaluacion());
    yield put(politicaActions.resetCheckPoliticaRemuneracion());
    yield put(politicaActions.resetCheckPoliticaPlanCarrera());
    SuccessToast({ message: "Archivos eliminados con éxito" });
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
    ErrorToast({ message: "Ocurrió un error" });
  }
}
function* changeStatusPoliticaSaga({ payload }) {
  const token = getToken();
  //
  try {
    const data = yield call(politicaApi(token).changeStatus, payload);
    yield put(politicaActions.getPoliticasEvaluacion());
    yield put(politicaActions.getPoliticasRemuneracion());
    yield put(politicaActions.getPoliticasPlanCarrera());
    SuccessToast({ message: "Cambio de estado exitoso" });
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
    ErrorToast({ message: "Ocurrió un error" });
  }
}
function* uploadPoliticaSaga({ payload }) {
  const token = getToken();
  //
  try {
    const data = yield call(politicaApi(token).upload, payload);
    yield put(politicaActions.getPoliticasEvaluacion());
    yield put(politicaActions.getPoliticasRemuneracion());
    yield put(politicaActions.getPoliticasPlanCarrera());
    SuccessToast({ message: "Carga exitosa" });
  } catch (error) {
    const errores = error.response.data;
    const message = ObjectToString(errores);
    ErrorToast({ message: message });
  }
}
function* replacePoliticaSaga({ payload }) {
  const token = getToken();
  //
  try {
    const data = yield call(politicaApi(token).replace, payload);
    yield put(politicaActions.getPoliticasEvaluacion());
    yield put(politicaActions.getPoliticasRemuneracion());
    yield put(politicaActions.getPoliticasPlanCarrera());
    SuccessToast({ message: "Carga exitosa" });
  } catch (error) {
    const errores = error.response.data;
    const message = ObjectToString(errores);
    ErrorToast({ message: message });
  }
}

export function* politicaSagas() {
  yield takeEvery(
    politicaActions.getPoliticasEvaluacion.type,
    getPoliticasEvaluacionSaga
  );
  yield takeLatest(
    politicaActions.setPoliticasEvaluacionFilter.type,
    getPoliticasEvaluacionSaga
  );
  //
  yield takeEvery(
    politicaActions.getPoliticasRemuneracion.type,
    getPoliticasRemuneracionSaga
  );
  yield takeLatest(
    politicaActions.setPoliticasRemuneracionFilter.type,
    getPoliticasRemuneracionSaga
  );
  //
  yield takeEvery(
    politicaActions.getPoliticasPlanCarrera.type,
    getPoliticasPlanCarreraSaga
  );
  yield takeLatest(
    politicaActions.setPoliticasPlanCarreraFilter.type,
    getPoliticasPlanCarreraSaga
  );
  //
  yield takeEvery(politicaActions.delete.type, deletePoliticaSaga);
  yield takeEvery(politicaActions.bulkDelete.type, bulkDeletePoliticaSaga);
  yield takeEvery(politicaActions.changeStatus.type, changeStatusPoliticaSaga);
  //
  yield takeEvery(politicaActions.upload.type, uploadPoliticaSaga);
  yield takeEvery(politicaActions.replace.type, replacePoliticaSaga);
}
