import React, { useEffect, useState } from "react";
import { BeneficiosLayout } from "../components/BeneficiosLayout";
import SearchInput from "src/modules/remuneracion/components/SearchInput";
import MostrarPaginas from "src/modules/common/components/MostrarPaginas";
import ReactSelect from "react-select";
import DataTable from "src/modules/common/components/DataTable";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { beneficioActions } from "../handlers/redux";
import { ErrorToast } from "src/modules/common/components/ErrorToast";

export function BeneficiosCargosPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const cargos_list = useSelector((state) => state.beneficio.cargos_list);
  const cargos_list_count = useSelector(
    (state) => state.beneficio.cargos_list_count
  );
  const cargos_list_filter = useSelector(
    (state) => state.beneficio.cargos_list_filter
  );
  const cargos_list_checked = useSelector(
    (state) => state.beneficio.cargos_list_checked
  );
  const cargos_list_loading = useSelector(
    (state) => state.beneficio.cargos_list_loading
  );

  const { nombre } = cargos_list_filter;

  const [outerPageSize, setOuterPageSize] = useState(50);

  useEffect(() => {
    dispatch(beneficioActions.getCargosList());
  }, []);

  const onChangePageSize = (e) => {
    let value = e.target.value;
    dispatch(
      beneficioActions.setCargosListFilter({
        ...cargos_list_filter,
        pagination: {
          pageSize: parseInt(value),
          page: 1,
        },
      })
    );
    setOuterPageSize(parseInt(value));
  };

  const onPaginate = (page, pageSize) => {
    //
    dispatch(
      beneficioActions.setCargosListFilter({
        ...cargos_list_filter,
        pagination: {
          page: page,
          pageSize: pageSize,
        },
      })
    );
  };

  const onCheckAll = () => {
    let checkedData = JSON.parse(JSON.stringify(cargos_list));
    if (cargos_list_checked.length > 0) {
      checkedData = [];
    }
    dispatch(beneficioActions.setCheckCargosList(checkedData));
  };

  const onCheckCargo = (item) => (event) => {
    dispatch(
      beneficioActions.checkCargosList({
        cargo_aux: item,
        checked: event.target.checked,
      })
    );
  };

  const onSearchCargos = (e) => {
    let searchText = e.target.value;
    dispatch(
      beneficioActions.setCargosListFilter({
        ...cargos_list_filter,
        nombre: searchText,
      })
    );
  };

  const eliminarBeneficiosCargos = (cargosIds) => {
    const request = { cargosIds };
    dispatch(beneficioActions.desvincularBeneficiosCargo(request));
  };

  const actionOptions = [
    {
      value: "delete",
      label: "Eliminar",
      // action: () => {},
    },
  ];

  const onAction = (v) => {
    if (v.value === "delete") {
      if (cargos_list_checked.length < 1) {
        ErrorToast({ message: "Debe seleccionar al menos un cargo." });
        return;
      }
      const deseaEliminar = window.confirm(
        "¿Está seguro de eliminar los beneficios de los cargos seleccionados?"
      );
      if (!!deseaEliminar) {
        const cargosIds = cargos_list_checked.map((cargo) => cargo.id);
        eliminarBeneficiosCargos(cargosIds);
      }
      return;
    }
  };

  const goToDetail = (item) => {
    console.log("Ver detalles", item);
    navigate("/beneficios/cargos/" + item.id);
  };
  const getEstadoColor = (cantidad) => {
    return cantidad === 0 ? "bg-red-500 text-white" : "";
  };
  const datatableConfig = {
    headers: [
      {
        title: "Cargos",
        contentClass: "font-bold px-2 py-4",
        onOrder: false,
        // accessor: "nombre",
        contentRenderer: (item) => (
          <div className="flex flex-col">
            <span className="font-semibold">{item.nombre}</span>
          </div>
        ),
      },
      {
        title: "# Beneficios",
        contentClass: "font-bold px-2 py-4",
        onOrder: false,
        // accessor: "nombre",
        contentRenderer: (item) => (
          <div className="flex items-center">
            <span
              className={`p-2 rounded-md text-sm ${getEstadoColor(
                item.cantidad_beneficios
              )}`}
            >
              {item.cantidad_beneficios === 0
                ? "Sin beneficios"
                : item.cantidad_beneficios}
            </span>
          </div>
        ),
      },
      {
        title: "Configuración",
        contentClass: "font-bold px-2 py-4",
        // accessor: "nombre",
        contentRenderer: (item) => (
          <button
            className="flex items-center gap-2 border rounded-md py-2 px-3 border-gray-500 text-sm"
            onClick={() => goToDetail(item)}
          >
            <span className="text-sm">Ver detalles</span>
          </button>
        ),
      },
    ],
    rowActions: [
      // {
      //   label: "Editar",
      //   action: (item) => {
      //     console.log(item);
      //   },
      // },
      {
        label: "Eliminar",
        action: (item) => {
          const deseaEliminar = window.confirm(
            "¿Está seguro de eliminar los beneficios del cargo seleccionado?"
          );
          if (!!deseaEliminar) {
            const cargoId = [item.id];
            eliminarBeneficiosCargos(cargoId);
          }
        },
      },
    ],
  };
  const goToConfiguracion = () => {
    console.log("Ir a configuración");
  };
  return (
    <BeneficiosLayout>
      <div className="border-b pb-2 mt-4 flex justify-between items-center mb-4">
        <h2 className="font-semibold text-xl">Cargos</h2>
      </div>
      <div className="px-4 py-2 w-full md:w-11/12">
        <div className="flex items-center gap-2 mt-4">
          <SearchInput
            containerClass={`w-1/2`}
            placeHolder={"Buscar por nombre..."}
            value={nombre}
            onChange={onSearchCargos}
          />
          <MostrarPaginas
            containerClass={"w-1/2x"}
            defaultOption={50}
            onChangePageSize={onChangePageSize}
          />
          <div className="text-sm">
            <ReactSelect
              className="w-52 h-10 bg-blue-200 inline-grid"
              value={{
                value: "",
                label: `(${cargos_list_checked.length}) seleccionados`,
              }}
              options={actionOptions}
              onChange={onAction}
            ></ReactSelect>
          </div>
        </div>
        <div></div>
      </div>
      <DataTable
        containerClass={"my-2"}
        config={datatableConfig}
        data={cargos_list}
        loading={cargos_list_loading}
        noHeaders={false}
        //
        totalRecords={cargos_list_count}
        onPaginate={onPaginate}
        outerPageSize={outerPageSize}
        //
        checkable={true}
        onCheckAll={onCheckAll}
        checkedItems={cargos_list_checked}
        onItemCheck={onCheckCargo}
      />
    </BeneficiosLayout>
  );
}
