import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import backButton from "src/modules/common/components/images/back-arrow.png";
import { PrimaryButton } from "src/modules/common/components/PrimaryButton";
import { commonActions } from "src/modules/common/handler/redux";
import { ColaboradorRootRoutes } from "../../../root/rootRoutes";
import { colaboradorLaboralActions } from "../../laboral/handlers/redux";
import { colaboradorActions } from "../handlers/redux";

function ColaboradorPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const editDetailButton = useSelector(
    (state) => state.common.editDetailButton
  );
  const hasChangedGeneral = useSelector(
    (state) => state.colaborador.hasChanged
  );
  const hasChangedLaboral = useSelector(
    (state) => state.colaboradorLaboral.hasChanged
  );
  //
  const setHasChangedGeneral = (hasChanged) => {
    dispatch(colaboradorActions.setHasChanged(hasChanged));
  };
  const setHasChangedLaboral = (hasChanged) => {
    dispatch(colaboradorLaboralActions.setHasChanged(hasChanged));
  };
  //
  let idColaborador = params["idColaborador"];

  const controlChanges = () => {
    const hasChanged = hasChangedLaboral || hasChangedGeneral;
    let response = true;
    if (hasChanged) {
      let deseaSalir = window.confirm(
        "Desea salir sin guardar los últimos cambios?"
      );
      response = deseaSalir;
    }
    if (response) {
      setHasChangedGeneral(false);
      setHasChangedLaboral(false);
    }
    return response;
  };

  useEffect(() => {
    dispatch(commonActions.setTitle(""));
    dispatch(commonActions.showSidebar(false));
  }, []);

  const menu = [
    { name: "General", to: "/nomina/colaborador/" + idColaborador + "" },
    {
      name: "Laboral",
      to: "/nomina/colaborador/" + idColaborador + "/laboral",
    },
    { name: "Acceso", to: "/nomina/colaborador/" + idColaborador + "/acceso" },
    { name: "KPIs", to: "/nomina/colaborador/" + idColaborador + "/kpis" },
    {
      name: "Objetivos",
      to: "/nomina/colaborador/" + idColaborador + "/objetivos",
    },
    {
      name: "Desempeño",
      to: "/nomina/colaborador/" + idColaborador + "/desempeno",
    },
  ];

  return (
    <>
      <div className="flex items-center bg-white py-2 px-8 -mx-8 border-b">
        <div
          onClick={() => {
            if (controlChanges()) {
              navigate("/nomina");
            }
          }}
          className={"h-full  flex items-center pr-2 -mr-2 z-10 cursor-pointer"}
        >
          <img className="back" src={backButton} alt="Regresar" />
        </div>
        <div className="font-medium px-4 text-lg">Colaborador</div>
        {!!idColaborador && (
          <div className="flex items-center justify-between w-full button-group">
            <div className="flex gap-2">
              {menu.map((item) => (
                <NavLink
                  onClick={(e) => {
                    if (!controlChanges()) {
                      e.preventDefault();
                    }
                  }}
                  key={item.to}
                  to={item.to}
                  end
                  className={({ isActive }) =>
                    isActive
                      ? "text-sm rounded px-2 py-1 bg-zinc-100 border border-sky-500 active"
                      : "text-sm rounded px-2 py-1 bg-zinc-100 border border-zinc-100"
                  }
                >
                  {item.name}
                </NavLink>
              ))}
            </div>
            {!!editDetailButton && (
              <PrimaryButton onClick={editDetailButton.action}>
                <span className="text-white text-md font-semibold bg-sky-500">
                  {editDetailButton.label}
                </span>
              </PrimaryButton>
            )}
          </div>
        )}
      </div>
      <ColaboradorRootRoutes />
    </>
  );
}

export default ColaboradorPage;
