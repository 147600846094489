import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { commonActions } from "src/modules/common/handler/redux";

function KpiLayout({ children }) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(commonActions.setTitle("KPIs"));
    dispatch(commonActions.showSidebar(true));

    dispatch(
      commonActions.setTitleMenu([
        // {
        //   name: "Creados",
        //   to: "/kpis/",
        // },
        {
          name: "Colaboradores",
          to: "/kpis/colaboradores",
        },
      ])
    );
    return () => {
      commonActions.setTitleMenu([]);
    };
  }, []);

  return <>{children}</>;
}

export default KpiLayout;
