import { ReduxRouter } from "@lagunovsky/redux-react-router";
import React from "react";
import { Provider } from "react-redux";
import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import AppPage from "src/modules/app/pages/AppPage";
import { authRoutes } from "src/modules/auth/handlers/routes";
import Screen from "src/modules/common/components/Screen";
import { browserHistory, rootStore } from "src/root/rootStore";

const routes = [...authRoutes];

function App() {
  return (
    <>
      <Provider store={rootStore}>
        <ReduxRouter
          history={browserHistory}
          store={rootStore}
          children={
            <Screen>
              <Routes>
                {routes.map((route, idx) => (
                  <Route key={idx} {...route} />
                ))}
                <Route path={"/*"} element={<AppPage />} />
              </Routes>
            </Screen>
          }
        />
        <ToastContainer />
      </Provider>
    </>
  );
}

export default App;
