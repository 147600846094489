import React from "react";
import TableCustom from "src/modules/common/components/TableCustom";
import RemuneracionLayout from "../components/RemuneracionLayout";
import DataTable from "src/modules/common/components/DataTable";

function RemuneracionIncrementosSalariales() {
  const incrementos = [
    {
      id: 1,
      nombre: "Incremento 1",
      antiguedad: 6,
      evaluaciones: "80-100",
      cumplen: 8,
      estado: "En curso",
    },
    {
      id: 2,
      nombre: "Incremento 2",
      antiguedad: 2,
      evaluaciones: "70-100",
      cumplen: 24,
      estado: "Finalizado",
    },
    {
      id: 3,
      nombre: "Incremento 3",
      antiguedad: 3,
      evaluaciones: "70-100",
      cumplen: 36,
      estado: "Finalizado",
    },
    {
      id: 4,
      nombre: "Incremento 1",
      antiguedad: 4,
      evaluaciones: "80-100",
      cumplen: 4,
      estado: "En curso",
    },
    {
      id: 5,
      nombre: "Incremento 2",
      antiguedad: 5,
      evaluaciones: "70-100",
      cumplen: 24,
      estado: "Finalizado",
    },
    {
      id: 6,
      nombre: "Incremento 3",
      antiguedad: 6,
      evaluaciones: "70-100",
      cumplen: 36,
      estado: "Finalizado",
    },
  ];
  const datatableConfig = {
    headers: [
      { title: "Lista de incrementos", accessor: "nombre" },
      { title: "Antiguedad", accessor: "antiguedad" },
      { title: "Evaluación", accessor: "evaluaciones" },
      { title: "Cumplen", accessor: "cumplen" },
      { title: "Estado", accessor: "estado" },
    ],
    rowActions: [
      {
        label: "Editar",
        action: (item) => {
          console.log("item", item);
        },
      },
    ],
  };
  return (
    <RemuneracionLayout>
      <div className="md:w-11/12">
        <DataTable
          containerClass={"my-8"}
          data={incrementos}
          loading={false}
          config={datatableConfig}
        />
        {/* <TableCustom
          data={incrementos}
          customHeaders={[
            // "Lista",
            "Antiguedad",
            "Evaluación",
            "Cumplen",
            "Estado",
          ]}
          tableTitle={""}
          tableSecondTitle={"Lista"}
          subject={"incremento"}
          subjectGender={"F"}
          addBtnClickHandler={null}
          dropdownActions={null}
        /> */}
      </div>
    </RemuneracionLayout>
  );
}

export default RemuneracionIncrementosSalariales;
