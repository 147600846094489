import React, { useEffect } from "react";
import YoutubePlayer from "react-player/youtube";
import { ContenidoLayout } from "../components/ContenidoLayout";
import { useDispatch, useSelector } from "react-redux";
import { commonActions } from "src/modules/common/handler/redux";
import { useNavigate, useParams } from "react-router-dom";
import { aprendizajeActions } from "../handlers/redux";
import LoadingSpinButton from "src/modules/common/components/LoadingSpinButton";
import { Download } from "react-bootstrap-icons";

export function TutorialContenidoPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const { idContenido } = params;

  // @ts-ignore
  const tutorial = useSelector((state) => state.aprendizaje.tutorial);
  const tutorial_loading = useSelector(
    // @ts-ignore
    (state) => state.aprendizaje.tutorial_loading
  );

  useEffect(() => {
    dispatch(commonActions.showSidebar(false));
    dispatch(commonActions.setTitleMenu([]));
    if (idContenido) {
      dispatch(aprendizajeActions.getTutorial(idContenido));
    }
  }, []);

  const goBack = () => {
    navigate("/aprendizaje/tutoriales");
  };

  return (
    // @ts-ignore
    <ContenidoLayout
      title={tutorial_loading ? "Cargando..." : tutorial.modulo}
      onClickBack={goBack}
    >
      {tutorial_loading && (
        <div className="flex justify-center mt-8">
          {/* @ts-ignore */}
          <LoadingSpinButton message={"Cargando, por favor espere"} />
        </div>
      )}
      {!tutorial_loading && (
        <div className="py-2 px-8 -mx-8">
          <div className="form flex justify-center mt-4">
            <div className="flex flex-col gap-4 w-full md:w-8/12">
              <div className="flex justify-center font-semibold text-xl w-full border-b">
                Video tutorial
              </div>
              <div className="flex flex-col justify-center items-center p-6 w-full border rounded-md gap-4 mb-4">
                {!!tutorial.url && (
                  <div>
                    {/* @ts-ignore */}
                    <YoutubePlayer url={tutorial.url} controls />
                  </div>
                )}
                {!tutorial.url && <div>Sin video asignado</div>}
                <div className="flex flex-col w-full mt-2">
                  <span className="font-semibold">Titular:</span>
                  <span>{tutorial.nombre}</span>
                </div>
                <div className="flex flex-col w-full">
                  <span className="font-semibold">Descripción:</span>
                  <span>{tutorial.descripcion || "Sin descripción"}</span>
                </div>
                <div className="flex flex-col w-full">
                  <span className="font-semibold">{`Adjuntos (${
                    tutorial.archivos ? tutorial.archivos.length : 0
                  }):`}</span>
                  {!!tutorial.archivos && tutorial.archivos.length > 0 && (
                    <div className="flex flex-col gap-2 mt-2">
                      {tutorial.archivos.map((item, index) => (
                        <div className="flex justify-between items-center border rounded-md p-2">
                          <span>{item.nombre}</span>
                          <div>
                            <a
                              target="_blank"
                              href={item.url}
                              download
                              className="flex flex-row items-center gap-2 border border-sky-500 rounded-md py-1 px-2 text-sky-500 cursor-pointer"
                            >
                              {/* @ts-ignore */}
                              <Download />
                              Descargar
                            </a>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                  {!!tutorial.archivos && tutorial.archivos.length === 0 && (
                    <span>Sin adjuntos</span>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </ContenidoLayout>
  );
}
