import { colaboradorReducer } from "src/modules/colaborador/modules/colaborador/handlers/redux";
import { colaboradorLaboralReducer } from "src/modules/colaborador/modules/laboral/handlers/redux";
import { colaboradorDesempenoReducer } from "../modules/desempeno/handlers/redux";

export const colaboradorRootReducer = {
  colaborador: colaboradorReducer,
  colaboradorLaboral: colaboradorLaboralReducer,
  colaboradorDesempeno: colaboradorDesempenoReducer,
  // debug: (a, b) => { !!process.env.REACT_APP_DEBUG && console.log(a, b); return {} }
};
