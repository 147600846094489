import React, { useEffect, useState } from "react";
import {
  ArrowLeft,
  PlusLg,
  Trash,
  TrashFill,
  X,
  XLg,
} from "react-bootstrap-icons";
import { useDispatch, useSelector } from "react-redux";
import Input from "src/modules/common/components/Input";
import Modal from "src/modules/common/components/Modal";
import { PrimaryButton } from "src/modules/common/components/PrimaryButton";
import { competenciaGeneralActions } from "../../general/handlers/redux";
import LibreriaCompetenciaList from "./LibreriaCompetenciaList";
import { competenciaAreaActions } from "../../area/handlers/redux";

function CompetenciaModal(props) {
  const {
    competencia = {},
    setOpen = () => {},
    open = false,
    onChange = () => {},
    onSubmit = () => {},
    idx = null,
    especifica = true,
  } = props;
  const dispatch = useDispatch();
  const [showLibrerias, setShowLibrerias] = useState(false);
  const { conductas = [{}, {}, {}, {}] } = competencia;
  const libreriaCompetenciasGenerales = useSelector(
    (state) => state.competenciaGeneral.libreria_competencias
  );
  const libreriaCompetenciasEspecificas = useSelector(
    (state) => state.competenciaArea.libreria_competencias
  );
  const onChangeInput =
    (name) =>
    ({ target }) => {
      onChange(
        {
          ...competencia,
          [name]: target.value,
        },
        idx
      );
    };
  const modalSelection = (competencia) => {
    onChange(
      {
        ...competencia,
        conductas: competencia.indicadores,
      },
      idx
    );
  };
  const setConductas = (conductas = []) => {
    onChangeInput("conductas")({ target: { value: conductas } });
  };

  useEffect(() => {
    setShowLibrerias(false);
    setConductas(competencia?.conductas || [{}, {}, {}, {}]);
  }, [open]);

  useEffect(() => {
    if (!!showLibrerias) {
      if (!especifica) {
        if (libreriaCompetenciasGenerales.length > 0) return;
        dispatch(competenciaGeneralActions.getCompetenciasBase());
      } else {
        if (libreriaCompetenciasEspecificas.length > 0) return;
        dispatch(competenciaAreaActions.getCompetenciasBase());
      }
    }
  }, [showLibrerias]);

  const onChangeConducta = (idx, name) => (event) => {
    let conducta = { ...(conductas[idx] || {}) };

    let conductas_new = [...conductas];
    conducta[name] = event.target.value || "";
    conductas_new[idx] = conducta;

    setConductas(conductas_new);
  };

  const onAddConducta = (event) => {
    let conductas_new = [...conductas];
    conductas_new.push({ descripcion: "" });

    setConductas(conductas_new);
  };

  const onDeleteConducta = (conducta_idx) => (event) => {
    event.preventDefault();
    event.stopPropagation();
    let conductas_new = conductas.filter((_, idx) => idx != conducta_idx);
    setConductas(conductas_new);
  };

  return (
    <Modal isOpen={open}>
      <div className=" rounded-lg bg-white w-6/12">
        {!showLibrerias && (
          <form onSubmit={onSubmit} className="flex flex-col gap-3 p-4">
            <div className="flex flex-row justify-between text-lg font-bold">
              <div>Competencia</div>
              <div onClick={() => setOpen(false)}>
                <XLg />
              </div>
            </div>
            <div className="flex flex-col gap-2">
              <div className="text-sm font-bold after:content-['*'] after:ml-0.5 after:text-red-500">
                Nombre de la competencia
              </div>
              <Input
                value={competencia.nombre || ""}
                onChange={onChangeInput("nombre")}
              ></Input>
              <button
                onClick={() => setShowLibrerias(true)}
                className="flex items-center gap-2 mt-2"
              >
                <div className="bg-sky-500 rounded-full p-1">
                  <PlusLg color={"white"} size={15} />
                </div>
                <span className="text-sky-500 text-sm">
                  Seleccionar desde la librería
                </span>
              </button>
            </div>
            <div className="flex flex-col gap-2">
              <div className="text-sm font-bold">Descripción</div>
              <Input
                value={competencia.descripcion || ""}
                onChange={onChangeInput("descripcion")}
              ></Input>
            </div>
            <div className="flex flex-col gap-2">
              <div className="text-sm font-bold after:content-['*'] after:ml-0.5 after:text-red-500">
                Conductas que se esperan observar
              </div>{" "}
              {conductas.map((conducta, idx) => (
                <div key={idx} className="flex flex-row gap-2 w-full">
                  <Input
                    className="w-full"
                    value={conducta.descripcion || ""}
                    onChange={onChangeConducta(idx, "descripcion")}
                  />
                  <div>
                    <button
                      onClick={onDeleteConducta(idx)}
                      className="flex items-center justify-center h-10 w-10 rounded border border-zinc-200 hover:bg-zinc-50"
                    >
                      <TrashFill />
                    </button>
                  </div>
                </div>
              ))}
              <div
                className="flex flex-row gap-2 items-center"
                onClick={onAddConducta}
              >
                <div className="w-5 h-5 rounded-full bg-sky-500 text-white flex items-center justify-center text-2xl">
                  +
                </div>
                <div className="text-sky-500 text-sm">
                  Agregar otra conducta
                </div>
              </div>
            </div>
            <div className="flex flex-row gap-2">
              <PrimaryButton type="submit">Guardar</PrimaryButton>
            </div>
          </form>
        )}
        {!!showLibrerias && (
          <LibreriaCompetenciaList
            data={
              !especifica
                ? libreriaCompetenciasGenerales
                : libreriaCompetenciasEspecificas
            }
            loading={false}
            onSelect={modalSelection}
            setShowLibrerias={setShowLibrerias}
            setOpen={setOpen}
          />
        )}
      </div>

      {/*  */}
    </Modal>
  );
}

export default CompetenciaModal;
