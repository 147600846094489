import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import { getToken } from "src/utils/helpers";
import { colaboradorDesempenoApi } from "./api";
import { colaboradorDesempenoActions } from "./redux";

function* colaboradorDesempenoSaga({ payload }) {
  const token = getToken();
  const params = { id: payload };
  //
  try {
    const data = yield call(
      colaboradorDesempenoApi(token).getDesempeno,
      params
    );
    yield put(colaboradorDesempenoActions.setColaboradorDesempeno(data));
    yield put(colaboradorDesempenoActions.setColaboradorDesempenoComplete());
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
    yield put(colaboradorDesempenoActions.setColaboradorDesempenoComplete());
  }
}

function* colaboradorEvaluacionesSaga({ payload }) {
  const token = getToken();
  const colaborador_evaluaciones_filter = yield select(
    (state) => state.colaboradorDesempeno.colaborador_evaluaciones_filter
  );
  const params = {
    id: payload.id,
    colaborador_evaluaciones_filter: colaborador_evaluaciones_filter,
  };
  //
  try {
    const data = yield call(
      colaboradorDesempenoApi(token).getEvaluaciones,
      params
    );
    yield put(
      colaboradorDesempenoActions.setColaboradorEvaluaciones(data.results)
    );
    yield put(colaboradorDesempenoActions.setColaboradorEvaluacionesComplete());
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
    yield put(colaboradorDesempenoActions.setColaboradorEvaluacionesComplete());
  }
}

export function* colaboradorDesempenoSagas() {
  yield takeEvery(
    colaboradorDesempenoActions.getColaboradorDesempeno.type,
    colaboradorDesempenoSaga
  );
  yield takeLatest(
    colaboradorDesempenoActions.getColaboradorEvaluaciones.type,
    colaboradorEvaluacionesSaga
  );
  yield takeLatest(
    colaboradorDesempenoActions.setColaboradorEvaluacionesFilter.type,
    colaboradorEvaluacionesSaga
  );
}
