import React from "react";
import { X, XLg } from "react-bootstrap-icons";
import { useDispatch, useSelector } from "react-redux";
import Input from "src/modules/common/components/Input";
import Modal from "src/modules/common/components/Modal";
import { competenciaAreaActions } from "../handlers/redux";

function CompetenciaAreaModal(props) {
  const dispatch = useDispatch();
  const modal = useSelector(
    (state) => state.competenciaArea.competencia_area_create_modal
  );
  const competenciaArea = useSelector(
    (state) => state.competenciaArea.competencia_area
  );

  const setOpen = (open) => {
    dispatch(competenciaAreaActions.setCompetenciaAreaCreateModal({ open }));
  };

  const onChange =
    (name) =>
    ({ target }) => {
      dispatch(
        competenciaAreaActions.competenciaAreaSet({
          ...competenciaArea,
          [name]: target.value,
        })
      );
    };

  const onSubmit = (event) => {
    event.preventDefault();
    if (!competenciaArea.nombre || !competenciaArea.descripcion) return;
    if (competenciaArea.id) {
      dispatch(competenciaAreaActions.competenciaAreaUpdate());
      setOpen(false);
      return;
    }
    dispatch(competenciaAreaActions.competenciaAreaCreate());
    setOpen(false);
  };

  return (
    <Modal isOpen={modal.open}>
      <div className=" rounded-lg bg-white w-6/12">
        <form onSubmit={onSubmit} className="flex flex-col gap-3 p-4">
          <div className="flex flex-row justify-between text-lg font-bold">
            <div>CompetenciaArea</div>
            <div onClick={() => setOpen(false)}>
              <XLg />
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <div className="text-slate-600 text-sm font-bold">Nombre</div>
            <Input
              value={competenciaArea.nombre || ""}
              onChange={onChange("nombre")}
            ></Input>
          </div>
          <div className="flex flex-col gap-2">
            <div className="text-slate-600 text-sm font-bold">Descripcion</div>
            <Input
              value={competenciaArea.descripcion || ""}
              onChange={onChange("descripcion")}
            ></Input>
          </div>
          <div className="flex flex-row gap-2">
            <button
              type="submit"
              className="bg-sky-500 text-white rounded-md px-3 py-2 active:bg-gray-200"
            >
              Guardar
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
}

export default CompetenciaAreaModal;
