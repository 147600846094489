import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ReactSelect from "react-select";
import DataTable from "src/modules/common/components/DataTable";
import { ErrorToast } from "src/modules/common/components/ErrorToast";
import MostrarPaginas from "src/modules/common/components/MostrarPaginas";
import SelectModal from "src/modules/common/components/SelectModal";
import { commonActions } from "src/modules/common/handler/redux";
import SearchInput from "src/modules/remuneracion/components/SearchInput";
import PlanCarreraForm from "../components/PlanCarreraForm";
import PlanCarreraLayout from "../components/PlanCarreraLayout";
import { planCarreraActions } from "../handlers/redux";

function PlanCarreraFamilias() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const planes_carrera = useSelector(
    (state) => state.plan_carrera.planes_carrera
  );
  const planes_carrera_count = useSelector(
    (state) => state.plan_carrera.planes_carrera_count
  );
  const planes_carrera_loading = useSelector(
    (state) => state.plan_carrera.planes_carrera_loading
  );
  const planes_carrera_checked = useSelector(
    (state) => state.plan_carrera.planes_carrera_checked
  );
  const planes_carrera_filter = useSelector(
    (state) => state.plan_carrera.planes_carrera_filter
  );

  const { nombre } = planes_carrera_filter;
  //

  const onEliminarPlan = (id) => {
    const deseaEliminar = window.confirm(
      "Está seguro de eliminar las familias seleccionadas?"
    );
    if (!!deseaEliminar) {
      dispatch(planCarreraActions.deletePlanCarrera(id));
    }
  };

  const setOrdering =
    (field) =>
    (order = "") => {
      let { ordering = "" } = planes_carrera_filter;
      let orderingArray = ordering.split(",") || [];
      let emptyIndex = orderingArray.indexOf("");
      if (emptyIndex) {
        orderingArray.splice(emptyIndex, 1);
      }
      let fieldIndex = orderingArray.indexOf(field);
      fieldIndex =
        fieldIndex === -1 ? orderingArray.indexOf(`-${field}`) : fieldIndex;
      // si ya esta el campo, vemos si se modifica o elimina
      if (fieldIndex > 0) {
        orderingArray.splice(fieldIndex, 1);
        if (order) {
          field = order === "desc" ? `-${field}` : field;
          orderingArray[fieldIndex] = field;
        }
      } else {
        // si no está, lo agregamos
        field = order === "desc" ? `-${field}` : field;
        orderingArray.push(field);
        !!process.env.REACT_APP_DEBUG &&
          console.info("orderingArray 2", orderingArray);
      }
      ordering = orderingArray.join(",");
      //
      dispatch(
        planCarreraActions.setPlanesCarreraFilter({
          ...planes_carrera_filter,
          ordering: ordering,
        })
      );
    };
  const datatableConfig = {
    headers: [
      {
        title: "Familias creadas",
        contentClass: "font-bold px-2 py-4",
        onOrder: setOrdering("nombre"),
        // accessor: "nombre",
        contentRenderer: (item) => (
          <div className="flex flex-col">
            <span className="font-semibold">{item.nombre}</span>
            <span>{item.descripcion}</span>
          </div>
        ),
      },
    ],
    rowActions: [
      {
        label: "Editar",
        action: (item) => {
          onEditarPlan(item);
        },
      },
      {
        label: "Eliminar",
        action: (item) => {
          onEliminarPlan(item.id);
        },
      },
    ],
  };
  const onSearchPlanCarrera = (e) => {
    let searchText = e.target.value;
    dispatch(
      planCarreraActions.setPlanesCarreraFilter({
        ...planes_carrera_filter,
        nombre: searchText,
      })
    );
  };
  const [outerPageSize, setOuterPageSize] = React.useState(50);
  const onChangePageSize = (e) => {
    let value = e.target.value;
    console.log("onChangePageSize", value);
    dispatch(
      planCarreraActions.setPlanesCarreraFilter({
        ...planes_carrera_filter,
        pagination: {
          pageSize: parseInt(value),
          page: 1,
        },
      })
    );
    //
    setOuterPageSize(parseInt(value));
  };
  const actionOptions = [
    {
      value: "delete",
      label: "Eliminar",
      // action: () => {},
    },
  ];
  const onBulkDelete = () => {
    if (planes_carrera_checked.length < 1) {
      ErrorToast({ message: "Debe seleccionar al menos una familia" });
      return;
    }
    dispatch(planCarreraActions.bulkDeletePlanesCarrera());
  };
  const onAction = (v) => {
    if (v.value === "delete") {
      const deseaEliminar = window.confirm(
        "Está seguro de eliminar las familias seleccionadas?"
      );
      if (!!deseaEliminar) {
        onBulkDelete();
      }
      return;
    }
  };
  const onCheckAll = () => {
    let checkedData = JSON.parse(JSON.stringify(planes_carrera));
    if (planes_carrera_checked.length > 0) {
      checkedData = [];
    }
    dispatch(planCarreraActions.setCheckPlanesCarrera(checkedData));
  };
  const onCheckPlanCarrera = (item) => (event) => {
    dispatch(
      planCarreraActions.checkPlanesCarrera({
        planCarrera: item,
        checked: event.target.checked,
      })
    );
  };
  const onPaginate = (page, pageSize) => {
    //
    dispatch(
      planCarreraActions.setPlanesCarreraFilter({
        ...planes_carrera_filter,
        pagination: {
          page: page,
          pageSize: pageSize,
        },
      })
    );
  };
  const onRowClick = (item) => {
    navigate(`/plan-carrera/familias/${item.id}`);
  };
  const showPlanFormModal = () => {
    dispatch(commonActions.setSelectModal({ open: true }));
  };
  //
  const onCreatePlanCarrera = (data) => {
    dispatch(planCarreraActions.createPlanCarrera(data));
    dispatch(commonActions.setSelectModal({ open: false }));
  };
  const onUpdatePlanCarrera = (data) => {
    dispatch(planCarreraActions.updatePlanCarrera(data));
    dispatch(commonActions.setSelectModal({ open: false }));
  };
  const [planCarreraForm, setPlanCarreraForm] = useState({});
  const onEditarPlan = (data) => {
    setPlanCarreraForm(data);
    dispatch(commonActions.setSelectModal({ open: true }));
  };
  //
  useEffect(() => {
    dispatch(planCarreraActions.getPlanesCarrera());
  }, []);
  //
  return (
    <PlanCarreraLayout>
      <div className="px-4 py-8 w-full md:w-11/12">
        <div className="flex items-center gap-2">
          <SearchInput
            containerClass={`w-1/2`}
            placeholder={"Buscar por nombre..."}
            value={nombre}
            onChange={onSearchPlanCarrera}
          />
          <MostrarPaginas
            containerClass={"w-1/2x"}
            onChangePageSize={onChangePageSize}
          />
          <div className="text-sm">
            <ReactSelect
              className="w-52 h-10 bg-blue-200 inline-grid"
              value={{
                value: "",
                label: `(${planes_carrera_checked.length}) seleccionados`,
              }}
              options={actionOptions}
              onChange={onAction}
            ></ReactSelect>
          </div>
          <div>
            <button
              onClick={showPlanFormModal}
              className="rounded-md bg-sky-500 text-white p-3"
            >
              Nueva familia
            </button>
          </div>
        </div>
        {/*  */}
        <DataTable
          containerClass={"my-8"}
          data={planes_carrera}
          loading={planes_carrera_loading}
          config={datatableConfig}
          noHeaders={false}
          // pagination
          totalRecords={planes_carrera_count}
          onPaginate={onPaginate}
          outerPageSize={outerPageSize}
          // check config
          checkable={true}
          onCheckAll={onCheckAll}
          checkedItems={planes_carrera_checked}
          // checkedIdField={"colaborador_id"}
          onItemCheck={onCheckPlanCarrera}
          onRowClick={onRowClick}
        />
      </div>
      <SelectModal
        title={"Crear nueva familia"}
        content={
          <PlanCarreraForm
            planCarrera={planCarreraForm}
            onSubmit={
              !planCarreraForm.id ? onCreatePlanCarrera : onUpdatePlanCarrera
            }
          />
        }
      />
    </PlanCarreraLayout>
  );
}

export default PlanCarreraFamilias;
