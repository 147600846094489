import React, { useEffect, useState } from "react";

const FloatInput = ({ value = "", onChange, ...props }) => {
  const [inputValue, setInputValue] = useState((value + "").replace(".", ","));

  const handleInputChange = (e) => {
    // Validar que solo se ingresen números y una coma
    const value = e.target.value;
    const regex = /^[0-9]*([,][0-9]{0,2})?$/;

    if (regex.test(value)) {
      setInputValue(value);
      if (!!onChange) onChange(value.replace(",", "."));
    }
  };

  useEffect(() => {
    setInputValue((value + "").replace(".", ","));
  }, [value]);

  return (
    <input
      type="text"
      value={inputValue}
      onChange={handleInputChange}
      {...props}
    />
  );
};

export default FloatInput;
