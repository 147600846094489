import { useEffect, useRef, useState } from "react";
import { FiletypeXls, Search } from "react-bootstrap-icons";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import CollapseCard from "src/modules/common/components/CollapseCard";
import DataTable from "src/modules/common/components/DataTable";
import SelectModal from "src/modules/common/components/SelectModal";
import { commonActions } from "src/modules/common/handler/redux";
import RemuneracionConfiguracionLayout from "../components/RemuneracionConfiguracionLayout";
import { remuneracionActions } from "../handlers/redux";
import { RutasRemuneracion } from "../handlers/routes";
import "./css/styles.css";
import MostrarPaginas from "src/modules/common/components/MostrarPaginas";
import { truncarNumero } from "src/modules/common/helpers/helpers";
import { DescargarValoracionesCargos } from "../helpers/helpers";
import { getToken } from "src/utils/helpers";

const ValorarCargoForm = ({
  cargo,
  factores,
  onSubmit,
  disableFinalize,
  onFinalize,
  progressBarIndex = 0,
  progressBarTotal = 1,
}) => {
  const dispatch = useDispatch();
  const FormRef = useRef(null);
  const [puntajeObtenido, setPuntajeObtenido] = useState(cargo.puntaje);
  const subPuntajesInit = factores.map((factor, fidx) => ({
    id: null,
    factor: factor.id,
    puntaje: 0,
    subfactor: null,
  }));
  const [subpuntajes, setSubpuntajes] = useState(
    cargo.valoraciones_subfactores?.length > 0
      ? cargo.valoraciones_subfactores
      : subPuntajesInit
  );
  const isAllChecked = () => {
    return !subpuntajes.find((sp, spi) => !sp.subfactor);
  };
  //
  const executeScroll = () => {
    FormRef.current.scrollIntoView({ behavior: "auto" });
  };
  const calcularFactorPuntaje = (factor, sfactor) => (e) => {
    const { peso = 0, subfactores = [] } = factor;
    const { relevancia } = sfactor;
    //
    const relevancias = subfactores.map((item, idx) => item.relevancia) ?? [];
    const relevanciaIndex = relevancias.findIndex(
      (item, idx) => item === relevancia
    );
    //
    const coefs = relevancias.map((item, idx) => relevancias.length + 1 - item);
    const coefRelevancia = coefs[relevanciaIndex];
    const mayor = Math.max(...relevancias);
    let puntajeCalculo = (coefRelevancia * peso) / mayor;
    puntajeCalculo = Number(puntajeCalculo.toFixed(2));
    //
    let subpuntajesCopy = JSON.parse(JSON.stringify(subpuntajes));
    const subpuntajeIndex = subpuntajesCopy.findIndex(
      (sp, spi) => sp.factor === factor.id
    );
    subpuntajesCopy[subpuntajeIndex].puntaje = puntajeCalculo;
    subpuntajesCopy[subpuntajeIndex].subfactor = sfactor.id;
    //
    setSubpuntajes(subpuntajesCopy);
  };
  const saveAndNext = () => {
    if (!!onSubmit) {
      onSubmit(cargo, truncarNumero(puntajeObtenido), subpuntajes);
    }
  };
  const onPreFinalize = () => {
    dispatch(commonActions.setSelectModal({ open: false }));
    // if (!!onFinalize) {
    //   onFinalize();
    // }
  };
  useEffect(() => {
    executeScroll();
    let cargoPuntaje = cargo.puntaje ?? 0;
    cargoPuntaje = parseFloat(cargoPuntaje.toFixed(2));
    setPuntajeObtenido(cargoPuntaje);
    //
    let factoresNuevos = [];
    if (cargo.valoraciones_subfactores?.length > 0) {
      factores.forEach((factor) => {
        const sf_idx = cargo.valoraciones_subfactores.findIndex(
          (vs, vsi) => vs.factor === factor.id
        );
        if (sf_idx < 0) {
          factoresNuevos.push({
            id: factor.id,
            factor: factor.id,
            puntaje: 0,
            subfactor: null,
          });
        }
      });
    }
    //
    setSubpuntajes(
      cargo.valoraciones_subfactores?.length > 0
        ? [...cargo.valoraciones_subfactores, ...factoresNuevos]
        : subPuntajesInit
    );
  }, [cargo.id]);
  useEffect(() => {
    let sumaPuntajes = subpuntajes.reduce(
      (sumador, subpuntaje) => sumador + subpuntaje.puntaje,
      0
    );
    sumaPuntajes = parseFloat(sumaPuntajes.toFixed(2));
    setPuntajeObtenido(sumaPuntajes);
  }, [subpuntajes]);
  const currentProgress = parseInt(
    ((progressBarIndex + 1) * 100) / progressBarTotal
  );
  //
  return (
    <div className="">
      <div className=" w-full h-2 bg-slate-300 rounded-md mb-2">
        <div
          style={{
            width: `${currentProgress}%`,
          }}
          className="h-2 bg-sky-500 rounded-md"
        ></div>
      </div>
      {/* <div>Puntaje cargo: {cargo.puntaje}</div> */}
      <h3 className="text-sm font-semibold mb-2">Factores a evaluar:</h3>
      {/*  */}
      <div className="h-80 overflow-y-scroll custom-scrollbar mb-4">
        <div ref={FormRef}></div>
        {factores.map((factor, fidx) => (
          <CollapseCard
            key={`factor-${fidx}`}
            titulo={factor.nombre}
            extraClass="mb-8"
            titleClass={"font-semibold"}
            initCollapse={true}
            extraEndContent={
              true ? (
                <div className="flex justify-end items-center gap-2 text-sm">
                  <input
                    disabled={true}
                    type="number"
                    value={factor.peso}
                    className="bg-slate-300x border border-slate-400 outline-none rounded-md p-2 w-1/3"
                  />
                  <div>%</div>
                </div>
              ) : (
                ""
              )
            }
          >
            <div className="flex flex-col gap-4">
              <div className="flex flex-col gap-2">
                <h3 className="font-semibold">Descripción:</h3>
                <div className="ml-2">{factor.descripcion}</div>
              </div>
              <div className="flex flex-col gap-2">
                <h3 className="font-semibold">Conductas:</h3>
                <ul className="flex flex-col gap-3 list-none">
                  {factor.subfactores.map((sfactor, sfidx) => (
                    // <li>{sfactor.descripcion}</li>
                    <li key={`f-${fidx}-sf-${sfidx}`}>
                      <label>
                        <input
                          type="radio"
                          name={`subfactores-${fidx}`}
                          // value={sfidx}
                          className="mr-2"
                          checked={
                            !!subpuntajes.find(
                              (sp, spi) =>
                                sp.factor === factor.id &&
                                sp.subfactor === sfactor.id
                            )
                          }
                          onChange={calcularFactorPuntaje(factor, sfactor)}
                        />
                        {sfactor.descripcion}
                      </label>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </CollapseCard>
        ))}
      </div>
      {/*  */}
      <div className="flex flex-col gap-2">
        <h3 className="font-semibold text-right text-lg">
          Puntaje obtenido: {puntajeObtenido}
        </h3>
        {
          <button
            disabled={!isAllChecked()}
            onClick={saveAndNext}
            className={`${
              !isAllChecked()
                ? "border-slate-400 text-slate-400"
                : "border-black"
            } border  rounded-md p-2`}
          >
            {/* currentProgress < 100 */}
            {` Guardar ${
              currentProgress < 100 ? "y continuar al siguiente cargo" : ""
            }`}
          </button>
        }
        <button
          disabled={disableFinalize}
          onClick={onPreFinalize}
          className={`${
            disableFinalize ? "bg-sky-200" : "bg-sky-500"
          } text-white p-2 rounded-md`}
        >
          Finalizar valoración
        </button>
      </div>
    </div>
  );
};

function RemuneracionValoracion() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const { idEscala = null } = params;
  const escala_salarial = useSelector(
    (state) => state.remuneracion.escala_salarial
  );
  const valoraciones_cargos = useSelector(
    (state) => state.remuneracion.valoraciones_cargos
  );
  const valoraciones_cargos_filter = useSelector(
    (state) => state.remuneracion.valoraciones_cargos_filter
  );
  const valoraciones_cargos_loading = useSelector(
    (state) => state.remuneracion.valoraciones_cargos_loading
  );
  const [selectedCargo, setSelectedCargo] = useState(null);
  const [selectedCargoIndex, setSelectedCargoIndex] = useState(0);
  const [valoracionesLocal, setValoracionesLocal] = useState([]);
  const { factores } = escala_salarial;
  const [hasChanged, setHasChanged] = useState(false);
  //
  const onClickBack = () => {
    let deseaRetroceder = true;
    if (!!hasChanged) {
      deseaRetroceder = window.confirm(
        "Desea volver atrás sin guardar los cambios?"
      );
    }
    if (!!deseaRetroceder) {
      navigate(RutasRemuneracion.ESCALA_CONFIGURACION(idEscala));
    }
  };
  const [Page, setPage] = useState(1);
  const [PageSize, setPageSize] = useState(999);
  const onPaginate = (page, pageSize) => {
    dispatch(
      remuneracionActions.filterValoracionesCargos({
        ...valoraciones_cargos_filter,
        pagination: {
          page: page,
          pageSize: pageSize,
        },
      })
    );
  };
  const onChangePageSize = (e) => {
    const value = e.target.value;
    setPageSize(value);
    setPage(1);
    //colaboradoresCount
  };
  const setOrdering =
    (field) =>
    (order = "") => {
      let { ordering = "" } = valoraciones_cargos_filter;
      let orderingArray = ordering.split(",") || [];
      let emptyIndex = orderingArray.indexOf("");
      if (emptyIndex) {
        orderingArray.splice(emptyIndex, 1);
      }
      let fieldIndex = orderingArray.indexOf(field);
      fieldIndex =
        fieldIndex === -1 ? orderingArray.indexOf(`-${field}`) : fieldIndex;
      // si ya esta el campo, vemos si se modifica o elimina
      if (fieldIndex > 0) {
        orderingArray.splice(fieldIndex, 1);
        if (order) {
          field = order === "desc" ? `-${field}` : field;
          orderingArray[fieldIndex] = field;
        }
      } else {
        // si no está, lo agregamos
        field = order === "desc" ? `-${field}` : field;
        orderingArray.push(field);
      }
      ordering = orderingArray.join(",");
      //
      dispatch(
        remuneracionActions.filterValoracionesCargos({
          ...valoraciones_cargos_filter,
          ordering: ordering,
        })
      );
    };
  const datatableConfig = {
    headers: [
      {
        title: "Lista de cargos",
        contentClass: "font-bold",
        onOrder: setOrdering("cargo__nombre"),
        accessor: "cargo.nombre",
      },
      {
        title: "Puntaje",
        accessor: "puntaje",
        onOrder: setOrdering("puntaje"),
        contentRenderer: (item) => (
          <div className="">
            <span>
              {truncarNumero(
                valoracionesLocal?.find((vc, vci) => vc.id === item.id)
                  ?.puntaje ?? 0
              )}
            </span>
          </div>
        ),
      },
    ],
    rowActions: [],
  };
  const data = {
    results: [
      { id: 1, nombre: "Administrador", puntaje: 0 },
      { id: 2, nombre: "Contador", puntaje: 0 },
      { id: 3, nombre: "Gerente", puntaje: 0 },
    ],
    count: 3,
  };
  const { results: cargos = [], count = 0 } = valoraciones_cargos;
  //
  const onClickCargo = (cargo) => {
    const findCargoFn = (vl, vli) => vl.id === cargo.id;
    const cargoLocal = valoracionesLocal.find(findCargoFn);
    const cargoLocalIndex = valoracionesLocal.findIndex(findCargoFn);
    setSelectedCargo({ ...cargo, ...cargoLocal });
    setSelectedCargoIndex(cargoLocalIndex);
    dispatch(commonActions.setSelectModal({ open: true }));
  };
  const onSearch = (e) => {
    let value = e.target.value;
    dispatch(
      remuneracionActions.filterValoracionesCargos({
        ...valoraciones_cargos_filter,
        nombre: value,
      })
    );
  };
  const onGuardarValoracion = (cargo, puntaje, valoraciones = []) => {
    let valoracionesLocalCopy = JSON.parse(JSON.stringify(valoracionesLocal));
    const valoracionIndex = valoracionesLocalCopy.findIndex(
      (item, idx) => item.id === cargo.id
    );
    //
    valoracionesLocalCopy[valoracionIndex].puntaje = puntaje;
    valoracionesLocalCopy[valoracionIndex]["valoraciones_subfactores"] =
      valoraciones;
    setValoracionesLocal(valoracionesLocalCopy);
    //
    const cargoIdx = cargos.findIndex((c, cidx) => c.id === cargo.id);
    let nextCargoIdx = 0;
    if (!!cargos[cargoIdx + 1]) {
      nextCargoIdx = cargoIdx + 1;
    }
    //
    if (!!nextCargoIdx) {
      const nextCargo = cargos[nextCargoIdx];
      const nextCargoLocal = valoracionesLocal.find(
        (vl, vli) => vl.id === nextCargo.id
      );
      setSelectedCargo({ ...nextCargo, ...nextCargoLocal });
      setSelectedCargoIndex(nextCargoIdx);
    }
    //
    setHasChanged(true);
  };
  const validateValoraciones = () => {
    let isValid = true;
    // todo controlar subfactores chequeados too...
    valoracionesLocal.forEach((vl, vli) => {
      if (vl.puntaje <= 0) {
        isValid = false;
      }
      // controlamos si existe valoracion para cada factor
      if (vl.valoraciones_subfactores.length !== factores.length) {
        isValid = false;
      } else {
        // de haber valoracion por cada factor controlamos si estan valorados
        vl.valoraciones_subfactores.forEach((vsf) => {
          if (vsf.puntaje <= 0) {
            isValid = false;
          }
        });
      }
    });
    //
    return isValid;
  };
  const onFinalizarValoracion = (e) => {
    dispatch(
      remuneracionActions.updateValoracionesCargos({
        id: idEscala,
        valoraciones: valoracionesLocal,
      })
    );
    setHasChanged(false);
    //
    if (!!validateValoraciones()) {
      const time = (valoracionesLocal.length / 20) * 1001;
      setTimeout(() => {
        navigate(RutasRemuneracion.ESCALA_CATEGORIZACION(idEscala));
      }, time);
    }
  };
  //
  const token = getToken()
  const onDescargarValoraciones = () => {
    DescargarValoracionesCargos(idEscala, token, null)
  }
  //
  useEffect(() => {
    dispatch(commonActions.showSidebar(false));
    if (!escala_salarial.id) {
      dispatch(remuneracionActions.getEscalaSalarial(idEscala));
    }
    dispatch(remuneracionActions.setValoracionesCargos([]));
  }, []);
  useEffect(() => {
    if (valoracionesLocal?.length < 1) {
      setValoracionesLocal(
        cargos?.map((cargo, cidx) => ({
          id: cargo.id,
          puntaje: cargo.puntaje ?? 0,
          valoraciones_subfactores: cargo.valoraciones_subfactores,
        }))
      );
    }
  }, [cargos.length]); //cargos.length
  //
  useEffect(() => {
    if (!!idEscala) {
      dispatch(remuneracionActions.getValoracionesCargos());
    }
  }, [escala_salarial.id]);
  //
  return (
    <RemuneracionConfiguracionLayout
      title={"Valoración por cargos"}
      onClickBack={onClickBack}
      currentStep={1}
    >
      <div className="py-2 px-8 -mx-8 w-full flex justify-center">
        <div className="flex flex-col justify-center border-2x w-full md:w-8/12">
          {/*  */}
          <div className="flex justify-between gap-4 w-full mt-4">
            <div className="w-1/2 flex items-center rounded-md bg-slate-100 px-2">
              <input
                type="text"
                className="bg-slate-100 outline-none w-full"
                placeholder="Buscar por nombre..."
                onChange={onSearch}
              />
              <Search />
            </div>
            {/* <MostrarPaginas
                containerClass={"w-1/2x"}
                onChangePageSize={onChangePageSize}
                options={[10, 50, 100, 200]}
                defaultOption={200}
              /> */}
            <button
              // disabled={!validateValoraciones()}
              onClick={onFinalizarValoracion}
              className={`bg-sky-500 w-3/12  py-3 px-4 text-white text-sm font-semibold rounded-md`}
            >
              {`${
                !validateValoraciones()
                  ? "Guardar y continuar"
                  : "Finalizar valoración"
              }`}
            </button>
            <button
                  onClick={onDescargarValoraciones}
                  className="flex items-center gap-2 border rounded-md py-2 px-3 border-gray-500 text-sm"
                >
                  <FiletypeXls size={21} />
                  <span className="font-semibold text-sm">
                    Descargar reporte
                  </span>
                </button>
          </div>
          {/*  */}
          <DataTable
            containerClass={"my-8 w-full"}
            rowClass={"hover:bg-slate-200 hover:cursor-pointer rounded-row"}
            data={cargos}
            loading={!!valoraciones_cargos_loading}
            config={datatableConfig}
            // pagination
            totalRecords={count}
            onPaginate={onPaginate}
            outerPageSize={PageSize}
            //
            checkable={false}
            onRowClick={onClickCargo}
          />
        </div>
      </div>
      <SelectModal
        title={selectedCargo?.cargo?.nombre ?? "Nombre del cargo"}
        extraClass={"w-6/12 px-4"}
        content={
          <ValorarCargoForm
            cargo={selectedCargo}
            factores={factores}
            disableFinalize={!validateValoraciones()}
            onSubmit={onGuardarValoracion}
            onFinalize={onFinalizarValoracion}
            progressBarIndex={selectedCargoIndex}
            progressBarTotal={valoracionesLocal.length}
          />
        }
      />
    </RemuneracionConfiguracionLayout>
  );
}
export default RemuneracionValoracion;
