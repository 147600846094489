import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { getToken } from "src/utils/helpers";
import { areaActions } from "../../area/handlers/redux";
import { cargoActions } from "../../cargo/handlers/redux";
import { departamentoActions } from "../../departamento/handlers/redux";
import { sucursalActions } from "../../sucursal/handlers/redux";
import { estructuraApi } from "./api";
import { estructuraActions } from "./redux";

function* totalsSaga({ payload }) {
  const token = getToken();
  try {
    const data = yield call(estructuraApi(token).totals);
    yield put(estructuraActions.setTotals({ totals: data }));
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
  }
}

export function* estructuraSagas() {
  yield takeLatest(estructuraActions.totals.type, totalsSaga);
  yield takeLatest(areaActions.setAreas.type, totalsSaga);
  yield takeLatest(cargoActions.setCargos.type, totalsSaga);
  yield takeLatest(departamentoActions.setDepartamentos.type, totalsSaga);
  yield takeLatest(sucursalActions.setSucursales.type, totalsSaga);
}
