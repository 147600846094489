import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactSelect from "react-select";
import DataTable from "src/modules/common/components/DataTable";
import { ErrorToast } from "src/modules/common/components/ErrorToast";
import MostrarPaginas from "src/modules/common/components/MostrarPaginas";
import SelectModal from "src/modules/common/components/SelectModal";
import { commonActions } from "src/modules/common/handler/redux";
import SearchInput from "src/modules/remuneracion/components/SearchInput";
import PlanCarreraLayout from "../components/PlanCarreraLayout";
import RequisitoForm from "../components/RequisitoForm";
import { planCarreraActions } from "../handlers/redux";
import { getToken } from "src/utils/helpers";
import axios from "axios";
import { CloudSlash } from "react-bootstrap-icons";

function PlanCarreraRequisitosCargo() {
  const dispatch = useDispatch();
  const cargos = useSelector((state) => state.plan_carrera.cargos);
  const cargos_count = useSelector((state) => state.plan_carrera.cargos_count);
  const cargos_loading = useSelector(
    (state) => state.plan_carrera.cargos_loading
  );
  const cargos_filter = useSelector(
    (state) => state.plan_carrera.cargos_filter
  );
  const cargos_checked = useSelector(
    (state) => state.plan_carrera.cargos_checked
  );
  const { nombre } = cargos_filter;
  //
  const [cargoForm, setCargoForm] = React.useState({});
  const [downloadLoading, setDownloadLoading] = useState(false);
  const openRequisitosModal = (cargo) => () => {
    setCargoForm(cargo);
    dispatch(commonActions.setSelectModal({ open: true }));
  };
  const setNextCargo = (cargo) => {
    let cargoIndex = cargos.findIndex((c) => c.id === cargo.id);
    let nextCargoIndex = 0;
    if (cargoIndex > -1 && cargoIndex < cargos.length - 1) {
      nextCargoIndex = cargoIndex + 1;
    }
    //
    setCargoForm(cargos[nextCargoIndex]);
  };
  const closeRequisitosModal = () => {
    setCargoForm({});
    dispatch(commonActions.setSelectModal({ open: false }));
  };
  const datatableConfig = {
    headers: [
      {
        title: "Lista de cargos",
        contentClass: "font-bold px-2 py-4",
        // onOrder: setOrdering("cargo__nombre"),
        accessor: "nombre",
      },
      {
        title: "Requisitos",
        contentClass: "font-bold px-2 py-4",
        // onOrder: setOrdering("cargo__nombre"),
        contentRenderer: (item) => {
          return (
            <div>
              {item.cantidad_requisitos > 0 && (
                <button
                  onClick={openRequisitosModal(item)}
                  className="border border-black rounded-md px-2 py-1"
                >
                  Ver requisitos
                </button>
              )}
              {item.cantidad_requisitos < 1 && (
                <button
                  onClick={openRequisitosModal(item)}
                  className="bg-red-500 text-white rounded-md p-1"
                >
                  Sin definir
                </button>
              )}
            </div>
          );
        },
      },
    ],
    rowActions: [
      {
        label: "Borrar requisitos",
        action: (item) => {
          borrarRequisitosCargo(item.id);
        },
      },
      {
        label: "Descargar",
        action: (item) => {
          descargarRequisitosCargo(item.id);
        },
      },
    ],
  };
  const [outerPageSize, setOuterPageSize] = React.useState(50);
  const onPaginate = (page, pageSize) => {
    //
    dispatch(
      planCarreraActions.setCargosFilter({
        ...cargos_filter,
        pagination: {
          page: page,
          pageSize: pageSize,
        },
      })
    );
  };
  const onCheckAll = () => {
    let checkedData = JSON.parse(JSON.stringify(cargos));
    if (cargos_checked.length > 0) {
      checkedData = [];
    }
    dispatch(planCarreraActions.setCargosChecked(checkedData));
  };
  const onCheckCargo = (item) => (event) => {
    dispatch(
      planCarreraActions.checkCargos({
        cargo: item,
        checked: event.target.checked,
      })
    );
  };
  const onSearchCargo = (e) => {
    let searchText = e.target.value;
    dispatch(
      planCarreraActions.setCargosFilter({
        ...cargos_filter,
        nombre: searchText,
      })
    );
  };
  const onChangePageSize = (e) => {
    let value = e.target.value;
    //
    dispatch(
      planCarreraActions.setCargosFilter({
        ...cargos_filter,
        pagination: {
          pageSize: parseInt(value),
          page: 1,
        },
      })
    );
    //
    setOuterPageSize(parseInt(value));
  };
  const actionOptions = [
    {
      value: "borrar-requisito",
      label: "Borrar requisitos",
      // action: () => {},
    },
  ];
  const borrarRequisitosCargo = (cargoId) => {
    let deseaEliminar = window.confirm(
      "Está seguro de eliminar los requisitos de este cargo?"
    );
    if (!deseaEliminar) return;
    //
    dispatch(planCarreraActions.borrarRequisitosCargo(cargoId));
  };
  const descargarRequisitosCargo = (cargoId) => {
    if (cargoId) {
      const token = getToken();
      const URL_BASE =
        process.env.REACT_APP_API_URL || "http://localhost:8000/api/admin";
      setDownloadLoading(true);
      axios({
        url: `${URL_BASE}/plan-carrera/${cargoId}/exportar/cargo`,
        method: "POST",
        responseType: "blob", // Indica que la respuesta será un archivo binario
        headers: {
          Authorization: `Bearer ${token}`, // Agregamos la cabecera de autorización con el token Bearer
        },
      })
        .then((response) => {
          const downloadUrl = window.URL.createObjectURL(
            new Blob([response.data])
          );
          const link = document.createElement("a");
          link.href = downloadUrl;
          link.setAttribute("download", `requisitos-cargo-${cargoId}.pdf`);
          document.body.appendChild(link);
          link.click();
          link.remove();
          setDownloadLoading(false);
        })
        .catch((error) => {
          console.error("Error al descargar el archivo PDF:", error);
          setDownloadLoading(false);
        });
    }
  };
  const onBulkBorrarRequisitos = () => {
    if (cargos_checked.length < 1) {
      ErrorToast({ message: "Debe seleccionar al menos un cargo" });
      return;
    }
    dispatch(
      planCarreraActions.borrarRequisitosCargos(
        cargos_checked.map((c, cidx) => c.id)
      )
    );
  };
  const onAction = (v) => {
    if (v.value === "borrar-requisito") {
      const deseaEliminar = window.confirm(
        "Está seguro de eliminar limpiar los requisitos de los cargos seleccionados?"
      );
      if (!!deseaEliminar) {
        onBulkBorrarRequisitos();
      }
      return;
    }
  };
  //
  useEffect(() => {
    dispatch(planCarreraActions.getCargos());
  }, []);
  return (
    <PlanCarreraLayout>
      <div className="px-4 py-8 w-full md:w-11/12">
        <div className="flex items-center gap-2">
          <SearchInput
            containerClass={`w-1/2`}
            placeholder={"Buscar por nombre..."}
            value={nombre}
            onChange={onSearchCargo}
          />
          <MostrarPaginas
            containerClass={"w-1/2x"}
            onChangePageSize={onChangePageSize}
          />
          <div className="text-sm">
            <ReactSelect
              className="w-52 h-10 bg-blue-200 inline-grid"
              value={{
                value: "",
                label: `(${cargos_checked.length}) seleccionados`,
              }}
              options={actionOptions}
              onChange={onAction}
            ></ReactSelect>
          </div>
        </div>
        {/*  */}
        <DataTable
          containerClass={"my-8"}
          data={cargos}
          loading={cargos_loading || downloadLoading}
          config={datatableConfig}
          noHeaders={false}
          // pagination
          totalRecords={cargos_count}
          onPaginate={onPaginate}
          outerPageSize={outerPageSize}
          // check config
          checkable={true}
          onCheckAll={onCheckAll}
          checkedItems={cargos_checked}
          onItemCheck={onCheckCargo}
          // onRowClick={onRowClick}
        />
      </div>
      {/*  */}
      <SelectModal
        extraClass={`w-11/12 md:w-7/12`}
        title={`${cargoForm.nombre}`}
        content={
          <RequisitoForm
            cargo={cargoForm}
            onFinalizar={closeRequisitosModal}
            afterSubmit={setNextCargo}
          />
        }
      />
    </PlanCarreraLayout>
  );
}

export default PlanCarreraRequisitosCargo;
