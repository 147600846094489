import { all } from "redux-saga/effects";
import { areaSagas } from "../modules/area/handlers/sagas";
import { cargoSagas } from "../modules/cargo/handlers/sagas";
import { departamentoSagas } from "../modules/departamento/handlers/sagas";
import { estructuraSagas } from "../modules/estructura/handlers/sagas";
import { sucursalSagas } from "../modules/sucursal/handlers/sagas";

export function* estructuraRootSagas() {
  yield all([
    cargoSagas(),
    areaSagas(),
    sucursalSagas(),
    departamentoSagas(),
    estructuraSagas(),
  ]);
}
