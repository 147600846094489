function SkeletonLoaderRow() {
  return (
    <div className="ml-6 border-b py-4 px-2">
      <div className="flex flex-1 justify-between items-center py-4 animate-pulse">
        <div className="flex flex-col gap-4 flex-1">
          <div className="h-2 w-2/5 bg-slate-200 rounded-md"></div>
          <div className="h-2 w-1/5 bg-slate-200 rounded-md"></div>
        </div>
        <div className="flex gap-24">
          <div className="h-2 w-6 bg-slate-200 rounded-md"></div>
          <div className="h-2 w-6 bg-slate-200 rounded-md"></div>
        </div>
      </div>
    </div>
  );
}

export default SkeletonLoaderRow;
