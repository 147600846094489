import React from "react";
import { DotMenu } from "./DotMenu";

export const AccionMenu = ({ children }) => {
  return (
    <DotMenu
      dir="left"
      className="w-10 h-6 flex flex-row justify-center items-center"
    >
      <>{children}</>
    </DotMenu>
  );
};
