import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ReactSelect from "react-select";
import { BuscarInput } from "src/modules/common/components/BuscarInput";
import { PrimaryButton } from "src/modules/common/components/PrimaryButton";
// import { BuscarInput } from "../../../../common/components/BuscarInput";
import { kpiActions } from "../handlers/redux";

export const ColaboradoresKPIToolbar = () => {
  const dispatch = useDispatch();

  const colaboradores_checked = useSelector(
    (state) => state.kpi.colaboradores_checked
  );
  const colaboradores_filter = useSelector(
    (state) => state.kpi.colaboradores_filter
  );
  const kpi = useSelector((state) => state.kpi.kpi);

  const colaboradores = useSelector(
    (state) => state.colaborador.colaboradores.results || []
  );

  const { nombre = "" } = colaboradores_filter;

  const onAction = (v) => {
    if (v.value === "cancel") {
      dispatch(kpiActions.resetCheckKpiColaboradores());
      return;
    }
  };

  const onChangeText = (event) => {
    !!process.env.REACT_APP_DEBUG &&
      console.info("onChangeText", event.target.value);
    dispatch(
      kpiActions.setColaboradoresFilter({
        nombre: event.target.value,
      })
    );
  };

  const onClickNew = () => {
    !!process.env.REACT_APP_DEBUG &&
      console.info("onClickNew", colaboradores_checked);
    dispatch(
      kpiActions.kpiSet({
        ...kpi,
        colaboradores: colaboradores_checked,
      })
    );
    //
    dispatch(kpiActions.kpiUpdate({ fromModal: true }));
    //
    dispatch(
      kpiActions.setColaboradorAddModal({
        open: false,
      })
    );
  };

  return (
    <div className="flex flex-row gap-2">
      <BuscarInput onChange={onChangeText} value={nombre}></BuscarInput>
      <ReactSelect
        className="w-52 h-10 bg-blue-200 inline-grid"
        value={{
          value: "",
          label: "(" + colaboradores_checked.length + ") seleccionados",
        }}
        options={[{ value: "cancel", label: "Cancelar" }]}
        onChange={onAction}
      ></ReactSelect>
      <PrimaryButton onClick={onClickNew}>Agregar</PrimaryButton>
    </div>
  );
};
