import { createSlice } from "@reduxjs/toolkit";

const politicaSlice = createSlice({
  name: "politica",
  initialState: {
    politicas_evaluacion: {},
    politicas_evaluacion_checked: [],
    politicas_evaluacion_count: 0,
    politicas_evaluacion_loading: false,
    politicas_evaluacion_filter: {},
    //
    politicas_remuneracion: {},
    politicas_remuneracion_checked: [],
    politicas_remuneracion_count: 0,
    politicas_remuneracion_loading: false,
    politicas_remuneracion_filter: {},
    //
    politicas_plan_carrera: {},
    politicas_plan_carrera_checked: [],
    politicas_plan_carrera_count: 0,
    politicas_plan_carrera_loading: false,
    politicas_plan_carrera_filter: {},
  },
  reducers: {
    getPoliticasEvaluacion: (state, { payload = {} }) => {
      state.politicas_evaluacion_loading = true;
    },
    setPoliticasEvaluacion: (state, { payload = {} }) => {
      state.politicas_evaluacion = payload;
      state.politicas_evaluacion_loading = false;
    },
    setPoliticasEvaluacionCount: (state, { payload = 0 }) => {
      state.politicas_evaluacion_count = payload;
    },
    setPoliticasEvaluacionFilter: (state, { payload = {} }) => {
      state.politicas_evaluacion_filter = payload;
    },
    // / checked
    resetCheckPoliticaEvaluacion: (state, { payload = {} }) => {
      state.politicas_evaluacion_checked = [];
    },
    setCheckPoliticaEvaluacion: (state, { payload = {} }) => {
      state.politicas_evaluacion_checked = payload;
    },
    checkPoliticaEvaluacion: (
      state,
      { payload: { politica, checked = true } }
    ) => {
      state.politicas_evaluacion_checked =
        state.politicas_evaluacion_checked.filter(
          (pol, pidx) => pol.id !== politica.id
        );
      if (!!checked) {
        state.politicas_evaluacion_checked.push(politica);
      }
    },
    // REMUNERACION
    getPoliticasRemuneracion: (state, { payload = {} }) => {
      state.politicas_remuneracion_loading = true;
    },
    setPoliticasRemuneracion: (state, { payload = {} }) => {
      state.politicas_remuneracion = payload;
      state.politicas_remuneracion_loading = false;
    },
    setPoliticasRemuneracionCount: (state, { payload = 0 }) => {
      state.politicas_remuneracion_count = payload;
    },
    setPoliticasRemuneracionFilter: (state, { payload = {} }) => {
      state.politicas_remuneracion_filter = payload;
    },
    // / checked
    resetCheckPoliticaRemuneracion: (state, { payload = {} }) => {
      state.politicas_remuneracion_checked = [];
    },
    setCheckPoliticaRemuneracion: (state, { payload = {} }) => {
      state.politicas_remuneracion_checked = payload;
    },
    checkPoliticaRemuneracion: (
      state,
      { payload: { politica, checked = true } }
    ) => {
      state.politicas_remuneracion_checked =
        state.politicas_remuneracion_checked.filter(
          (pol, pidx) => pol.id !== politica.id
        );
      if (!!checked) {
        state.politicas_remuneracion_checked.push(politica);
      }
    },
    // PLAN CARRERA
    getPoliticasPlanCarrera: (state, { payload = {} }) => {
      state.politicas_plan_carrera_loading = true;
    },
    setPoliticasPlanCarrera: (state, { payload = {} }) => {
      state.politicas_plan_carrera = payload;
      state.politicas_plan_carrera_loading = false;
    },
    setPoliticasPlanCarreraCount: (state, { payload = 0 }) => {
      state.politicas_plan_carrera_count = payload;
    },
    setPoliticasPlanCarreraFilter: (state, { payload = {} }) => {
      state.politicas_plan_carrera_filter = payload;
    },
    // / checked
    resetCheckPoliticaPlanCarrera: (state, { payload = {} }) => {
      state.politicas_plan_carrera_checked = [];
    },
    setCheckPoliticaPlanCarrera: (state, { payload = {} }) => {
      state.politicas_plan_carrera_checked = payload;
    },
    checkPoliticaPlanCarrera: (
      state,
      { payload: { politica, checked = true } }
    ) => {
      state.politicas_plan_carrera_checked =
        state.politicas_plan_carrera_checked.filter(
          (pol, pidx) => pol.id !== politica.id
        );
      if (!!checked) {
        state.politicas_plan_carrera_checked.push(politica);
      }
    },
    // ACCIONES GRALES
    delete: (state, { payload = {} }) => {},
    bulkDelete: (state, { payload = [] }) => {},
    changeStatus: (state, { payload = {} }) => {},
    upload: (state, { payload = {} }) => {},
    replace: (state, { payload = {} }) => {},
  },
});

export const politicaActions = politicaSlice.actions;
export const politicaReducer = politicaSlice.reducer;
