import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { DotMenu, DotMenuItem } from "src/modules/common/components/DotMenu";
import { competenciaAreaActions } from "../handlers/redux";

export const CompetenciaAreaTableAccionMenu = ({ item = {} }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onEdit = () => {
    let { areas = [] } = item;
    dispatch(
      competenciaAreaActions.competenciaAreaSet({
        ...item,
        areas: areas.map((area) => area.id),
      })
    );
    dispatch(competenciaAreaActions.setCompetenciaAreaEditing(true));
    navigate(`/competencias/area/${item.id}/edit`);
  };

  const onDelete = () => {
    let deseaEliminar = window.confirm(
      "¿Desea eliminar el grupo de competencias seleccionado?"
    );
    if (!!deseaEliminar) {
      // @ts-ignore
      dispatch(
        competenciaAreaActions.competenciaAreaDelete({
          id: item?.id,
        })
      );
    }
  };

  return (
    <DotMenu
      dir="left"
      className="w-10 h-6  flex flex-row justify-center items-center"
    >
      <>
        <DotMenuItem onClick={onEdit}>Editar</DotMenuItem>
        <DotMenuItem onClick={onDelete}>Eliminar</DotMenuItem>
      </>
    </DotMenu>
  );
};
