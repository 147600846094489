import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactSelect from "react-select";
import { sucursalActions } from "../handlers/redux";

export const SucursalSelect = ({
  value,
  defaultLabel = "Todas las sucursales",
  ...props
}) => {
  const dispatch = useDispatch();
  let sucursales = useSelector((state) => state.sucursal.sucursales || {});
  const { results = [] } = sucursales;
  sucursales = results.map((s) => ({
    value: s.id,
    label: s.nombre,
  }));
  sucursales.unshift({ value: null, label: defaultLabel });

  const selected = sucursales.find((v) => v.value == value);

  useEffect(() => {
    dispatch(sucursalActions.setSucursalesFilter({ nombre: "", pagination: { page: 1, pageSize: 200 }}));
  }, []);

  return (
    <ReactSelect
      {...props}
      onChange={(item) => props.onChange && props.onChange({ target: item })}
      value={selected}
      options={sucursales}
    />
  );
};
