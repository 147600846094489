import React, { useState, useEffect } from "react";
import { formatNumberOnTyping } from "src/utils/helpers";

const DecimalInput = ({ value: propValue, onChange, ...props }) => {
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    // Cuando el valor de la prop cambia, actualiza el estado interno
    setInputValue(formatValueForInput(propValue));
  }, [propValue]);

  const formatValueForInput = (value) => {
    console.log("formatValueForInput", typeof value, value);
    // Formatear el valor para que tenga comas como separador decimal y puntos en la parte entera
    let formattedValue = "";
    if (
      value !== null &&
      value !== undefined &&
      value !== "" &&
      !isNaN(value)
    ) {
      formattedValue = formatNumberOnTyping(
        value.toFixed(2).replace(/\./g, ",")
      );
      console.log("here 1", formattedValue);
      if (formattedValue.endsWith(",00")) {
        formattedValue = formatNumberOnTyping(
          value.toFixed(0).replace(/\./g, ",")
        );
        console.log("here 2", formattedValue);
      }
    }
    return formattedValue;
  };

  const handleChange = (e) => {
    let value = e.target.value;

    // Permitir un campo vacío
    if (value === "") {
      setInputValue("");
      onChange && onChange(null); // Puedes ajustar esto según tus necesidades
      return;
    }

    // Reemplazar comas por puntos para la representación numérica
    value = value.replace(/[^\d,]/g, "");

    // Validar para permitir solo una coma y hasta 2 decimales
    const matches = value.match(/^(\d+)(,\d{0,2})?$/);

    if (matches) {
      value = matches[1] + (matches[2] || ""); // Conservar hasta 2 decimales
      setInputValue(formatNumberOnTyping(value));

      // Pasar el valor formateado al componente padre a través de la prop onChange
      onChange && onChange(value.replace(/,/g, "."));
    }
  };

  const handleBlur = () => {
    // Convertir la representación numérica a un número y enviar al backend
    const numericValue = parseFloat(inputValue.replace(/,/g, "."));

    // Puedes hacer la llamada al backend aquí usando numericValue
    console.log("Valor numérico:", numericValue);
  };

  return (
    <input
      type="text"
      value={inputValue}
      onChange={handleChange}
      onBlur={handleBlur}
      {...props}
    />
  );
};

export default DecimalInput;
