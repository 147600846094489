import React, { useEffect, useState } from "react";
import { ArrowRight, ArrowUp, QuestionCircle, X } from "react-bootstrap-icons";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import LoadingSpinButton from "src/modules/common/components/LoadingSpinButton";
import SelectModal from "src/modules/common/components/SelectModal";
import { commonActions } from "src/modules/common/handler/redux";
import { formatNumber } from "src/utils/helpers";
import RemuneracionConfiguracionLayout from "../components/RemuneracionConfiguracionLayout";
import { remuneracionActions } from "../handlers/redux";
import { RutasRemuneracion } from "../handlers/routes";
import { obtenerLetrasColumna } from "../helpers/helpers";

const SALARIO_MINIMO = 2680373;

const CategoriaRow = ({ categoria, onChange, errores = [] }) => {
  const [showInput, setShowInput] = useState(false);
  const { paso_nivel = 0, paso_categoria = 0, monto_minimo = 0 } = categoria;
  const [pasoNivel, setPasoNivel] = useState(paso_nivel);
  const [pasoCategoria, setPasoCategoria] = useState(paso_categoria);
  const [montoMinimo, setMontoMinimo] = useState(monto_minimo);
  const toggleShowInput = () => {
    if (!!showInput) {
      setMontoMinimo("");
      onChange(categoria, "monto_minimo")({ target: { value: "" } });
    }
    setShowInput(!showInput);
  };
  useEffect(() => {
    if (categoria.monto_minimo > 0) {
      setShowInput(true);
    }
  }, []);
  useEffect(() => {
    setPasoNivel(formatNumber(paso_nivel ?? 0));
    setPasoCategoria(formatNumber(paso_categoria ?? 0));
    setMontoMinimo(formatNumber(monto_minimo ?? 0));
  }, [paso_nivel, paso_categoria, monto_minimo]);
  //
  return (
    <tr className="bg-white rounded-md">
      <td className="p-2">{categoria.nombre}</td>
      <td className="p-2">
        <input
          type="text"
          className={`border rounded-md p-2 ${
            !!errores[0] ? "border-red-500" : ""
          }`}
          maxlength="5"
          value={pasoNivel}
          onChange={onChange(categoria, "paso_nivel")}
        />
      </td>
      <td className="p-2">
        <input
          type="text"
          className={`border rounded-md p-2 ${
            !!errores[1] ? "border-red-500" : ""
          }`}
          maxlength="5"
          value={pasoCategoria}
          onChange={onChange(categoria, "paso_categoria")}
        />
      </td>
      <td className="p-2 w-full">
        {!!showInput && (
          <div className="flex items-center border rounded-md p-2 w-full">
            <input
              type="text"
              className="outline-none w-full"
              value={montoMinimo}
              onChange={onChange(categoria, "monto_minimo")}
            />
            <button onClick={toggleShowInput}>
              <X size={24} />
            </button>
          </div>
        )}
        {!showInput && (
          <div className="p-2 w-full">
            <label htmlFor="" className="flex items-center text-sky-400">
              <input
                type="checkbox"
                className="w-4 h-4"
                name={`si-${categoria.id}`}
                id={`si-${categoria.id}`}
                checked={!!showInput}
                onClick={toggleShowInput}
              />
              <span className="ml-2">Activar monto base</span>
            </label>
          </div>
        )}
      </td>
    </tr>
  );
};

const ParametrosCategoria = ({
  cantidad_niveles = 4,
  salario_primer_nivel = 2680373,
  categorias,
  onChangeEscala,
  onChange,
  onSubmit,
}) => {
  const emptyErrores = categorias.map((cat, cidx) => []);
  const [erroresEscala, setErroresEscala] = useState({});
  const [erroresCategoria, setErroresCategoria] = useState(emptyErrores);
  //
  const validate = () => {
    let huboError = false;
    let erroresCopy = emptyErrores;
    let erroresEscalaCopy = {};
    setErroresEscala({});
    //
    const montoMinimoCategorias =
      categorias[categorias.length - 1].monto_minimo;
    if (!salario_primer_nivel && !montoMinimoCategorias) {
      erroresEscalaCopy = {
        ...erroresEscalaCopy,
        salario_primer_nivel:
          "Debe ingresar salario o un monto mínimo en la última categoría",
      };
      huboError = true;
    }
    //
    for (let index = 0; index < categorias.length; index++) {
      const categoria = categorias[index];
      if (!categoria.paso_nivel && categoria.paso_nivel !== 0) {
        erroresCopy[index][0] = true;
        huboError = true;
      }
      if (!categoria.paso_categoria && categoria.paso_categoria !== 0) {
        erroresCopy[index][1] = true;
        huboError = true;
      }
      setErroresCategoria(erroresCopy);
    }
    if (!!huboError) {
      setErroresEscala({
        ...erroresEscalaCopy,
        categorias: "Favor verificar los datos.",
      });
    }
    //
    return !huboError;
  };
  const onBeforeSubmit = () => {
    const isValid = validate();
    if (isValid && !!onSubmit) {
      onSubmit();
    }
  };
  //
  return (
    <div className="mt-2">
      <div className="flex items-center gap-4">
        <div className="flex flex-col">
          <label htmlFor="niveles" className="font-semibold">
            Cantidad de niveles <span className="text-red-500">*</span>
          </label>
          <select
            name="niveles"
            id="niveles"
            className="rounded-md bg-white border p-2"
            value={cantidad_niveles}
            onChange={onChangeEscala("cantidad_niveles")}
          >
            {"123456789012345".split("").map((n, i) => (
              <option key={`nivel-${i}`} value={i + 2}>
                {i + 2}
              </option>
            ))}
          </select>
        </div>
        {/*  */}
        <div>
          <label htmlFor="salario_primer_nivel" className="font-semibold">
            Salario primer nivel
          </label>
          <div className="bg-white border rounded-md p-2 flex items-center">
            <div>Gs. </div>
            <input
              type="text"
              className="w-full outline-none"
              value={formatNumber(salario_primer_nivel ?? SALARIO_MINIMO)}
              onChange={onChangeEscala("salario_primer_nivel")}
            />
          </div>
          {!!erroresEscala["salario_primer_nivel"] && (
            <div className="text-xs text-red-500">
              {erroresEscala["salario_primer_nivel"]}
            </div>
          )}
        </div>
      </div>
      {/* TABLA CATEGORIAS */}
      <div className="mt-4 max-h-80 custom-scrollbar overflow-auto">
        <table className="w-full">
          <thead>
            <tr className="text-left">
              <th className="p-2">Categorías</th>
              <th className="p-2 ">
                <div className="flex items-center gap-1">
                  <ArrowRight size={18} /> <span>% entre niveles</span>
                </div>
              </th>
              <th className="p-2">
                <div className="flex items-center gap-1">
                  <ArrowUp size={18} /> <span>% entre categorías</span>
                </div>
              </th>
              <th className="p-2">Monto Inicial</th>
            </tr>
          </thead>
          <tbody>
            {categorias.map((categoria, cidx) => (
              <CategoriaRow
                categoria={categoria}
                onChange={onChange}
                key={`cat-tr-${cidx}`}
                errores={erroresCategoria[cidx]}
              />
            ))}
          </tbody>
        </table>
      </div>
      {/*  */}
      {!!erroresEscala["categorias"] && (
        <div className="mt-2 w-full bg-red-100 border border-red-400 rounded-md py-2 px-2 text-sm text-red-400">
          {erroresEscala["categorias"]}
        </div>
      )}
      {/*  */}
      <div className="w-full mt-4">
        <button
          onClick={onBeforeSubmit}
          className="w-full bg-sky-500 py-3 text-white rounded-md"
        >
          Guardar configuración de escala
        </button>
      </div>
    </div>
  );
};

const FinalizarConfiguracion = ({ onSecondaryClick, onPrimaryClick }) => {
  //
  return (
    <div>
      <h3 className="text-center text-lg font-semibold">
        ¿Desea activar esta escala?
      </h3>
      <div className="w-full flex justify-center my-4  #0EA5E9">
        <div className="bg-yellow-400 p-2 rounded-md">
          <QuestionCircle size={48} color={"white"} />
        </div>
      </div>
      <p className="text-center text-sm my-2">
        De activar esta escala, la anterior quedará como "No vigente".
      </p>
      <div className="flex items-center gap-2 mt-4">
        <button
          onClick={onSecondaryClick}
          className="w-full border border-black rounded-md py-3"
        >
          Sólo guardar
        </button>
        <button
          onClick={onPrimaryClick}
          className="w-full border border-sky-500 bg-sky-500 py-3 text-white rounded-md"
        >
          Activar ahora
        </button>
      </div>
    </div>
  );
};

function RemuneracionEscala() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const { idEscala = null } = params;
  const escala_configuracion = useSelector(
    (state) => state.remuneracion.escala_configuracion
  );
  const escala_configuracion_loading = useSelector(
    (state) => state.remuneracion.escala_configuracion_loading
  );
  //
  const [escalaConfiguracionLocal, setEscalaConfiguracionLocal] = useState({
    ...escala_configuracion,
    salario_primer_nivel: SALARIO_MINIMO,
  });
  const activar_escala_loading = useSelector(
    (state) => state.remuneracion.activar_escala_loading
  );
  const [grillaLocal, setGrillaLocal] = useState([]);
  const [hasChanged, setHasChanged] = useState(false);
  const [showFinalizar, setShowFinalizar] = useState(false);
  //
  const onClickBack = () => {
    let deseaRetroceder = true;
    if (!!hasChanged) {
      deseaRetroceder = window.confirm(
        "Desea volver atrás sin guardar los cambios?"
      );
    }
    if (!!deseaRetroceder) {
      navigate(RutasRemuneracion.ESCALA_CATEGORIZACION(idEscala));
    }
  };
  const openParametrosModal = () => {
    setShowFinalizar(false);
    dispatch(commonActions.setSelectModal({ open: true }));
  };
  const onChangeEscala = (field) => (e) => {
    let value = e.target.value;
    if (field === "salario_primer_nivel") {
      value = value.replace(/[^0-9,.]/g, "");
      value = value.replaceAll(".", "").replace(",", ".");
      if (value !== "") value = formatNumber(Number(value));
    }
    //
    setEscalaConfiguracionLocal({
      ...escalaConfiguracionLocal,
      [field]: value,
    });
    setHasChanged(true);
  };
  const CategoriaBigIntFields = ["monto_minimo"];
  const onChangeCategoria = (categoria, field) => (e) => {
    let value = e.target.value;
    // value = value.replace(/[^0-9,.]/g, "");
    value = value.replace(/[^-0-9,.]/g, "");
    if (CategoriaBigIntFields.includes(field)) {
      value = value.replaceAll(".", "").replace(",", ".");
      if (value !== "") value = formatNumber(Number(value));
    }
    const { categorias = [] } = escalaConfiguracionLocal;
    let categoriasCopy = JSON.parse(JSON.stringify(categorias));
    //
    const categoriaIndex = categoriasCopy.findIndex(
      (cat, cidx) => cat.id === categoria.id
    );
    //
    if (categoriaIndex < 0) {
      return;
    }
    let categoriaCopy = { ...categoriasCopy[categoriaIndex] };
    categoriaCopy[field] = value;
    categoriasCopy[categoriaIndex] = categoriaCopy;
    //
    setEscalaConfiguracionLocal({
      ...escalaConfiguracionLocal,
      categorias: categoriasCopy,
    });
    setHasChanged(true);
  };
  const sanitizeNumber = (number = "") => {
    let numberStr = number + "";
    return Number(numberStr.replaceAll(".", "").replace(",", "."));
  };
  const sanitizeCategoria = (categoria, index) => {
    const { monto_minimo, paso_categoria = 0, paso_nivel = 0 } = categoria;
    let monto_minimo_clean = !!monto_minimo
      ? (monto_minimo + "").replaceAll(".", "").replace(",", ".")
      : null;
    let paso_categoria_clean = (paso_categoria + "")
      .replaceAll(".", "")
      .replace(",", ".");
    let paso_nivel_clean = (paso_nivel + "")
      .replaceAll(".", "")
      .replace(",", ".");
    //
    return {
      ...categoria,
      monto_minimo: !!monto_minimo_clean ? Number(monto_minimo_clean) : null,
      paso_categoria: Number(paso_categoria_clean),
      paso_nivel: Number(paso_nivel_clean),
    };
  };
  const onSubmitConfiguracion = () => {
    const { categorias = [], salario_primer_nivel } = escalaConfiguracionLocal;
    let categoriasCleaned = JSON.parse(JSON.stringify(categorias));
    categoriasCleaned = categoriasCleaned.map(sanitizeCategoria);
    //
    dispatch(
      remuneracionActions.setEscalaConfiguracion({
        ...escalaConfiguracionLocal,
        salario_primer_nivel: sanitizeNumber(salario_primer_nivel),
        categorias: categoriasCleaned,
      })
    );

    dispatch(commonActions.setSelectModal({ open: false }));
    dispatch(remuneracionActions.updateEscalaConfiguracion());
    setHasChanged(false);
  };
  const onFinalizar = () => {
    setShowFinalizar(true);
    dispatch(commonActions.setSelectModal({ open: true }));
  };
  const onMantenerEscala = () => {
    setShowFinalizar(false);
    dispatch(commonActions.setSelectModal({ open: false }));
    navigate(RutasRemuneracion.ESCALA);
  };
  const onActualizarEscala = () => {
    dispatch(remuneracionActions.activarEscalaSalarial({ id: idEscala }));
    //
    dispatch(commonActions.setSelectModal({ open: false }));
    setShowFinalizar(false);
  };
  const escalaConSalarios = () => {
    const { grilla = [] } = escala_configuracion;
    const todosTienenSalarios = grilla.every(
      (categoria) =>
        Array.isArray(categoria.salarios) && categoria.salarios.length > 0
    );
    return todosTienenSalarios;
  };
  const finalizarButtonConfig = {
    actionButtonLabel: "Finalizar configuración",
    actionButtonAction: onFinalizar,
    disabled: !escalaConSalarios(),
  };
  useEffect(() => {
    dispatch(commonActions.setTitle("Admin"));
    dispatch(commonActions.showSidebar(false));
    if (!!idEscala) {
      dispatch(remuneracionActions.getEscalaConfiguracion({ id: idEscala }));
    }
  }, []);
  //
  useEffect(() => {
    const esNuevaEscala = escala_configuracion.categorias?.every(
      (categoria) =>
        categoria.paso_nivel === 0 && categoria.paso_categoria === 0
    );
    // RELLENAMOS CON DATOS POR DEFECTO
    if (esNuevaEscala) {
      const categoriasAux = escala_configuracion.categorias?.map(
        (cat, cidx) => {
          return {
            ...cat,
            paso_nivel: 10,
            paso_categoria:
              (escala_configuracion.categorias.length - cidx) * 10,
          };
        }
      );
      setEscalaConfiguracionLocal({
        ...escala_configuracion,
        cantidad_niveles: 4,
        categorias: categoriasAux,
        salario_primer_nivel: SALARIO_MINIMO,
      });
      //
      dispatch(
        remuneracionActions.setEscalaConfiguracion({
          ...escala_configuracion,
          salario_primer_nivel: SALARIO_MINIMO,
        })
      );
      //
      return;
    }
    setEscalaConfiguracionLocal(escala_configuracion);
  }, [escala_configuracion.id, escala_configuracion.grilla?.length]);
  //
  useEffect(() => {
    let grillaCopy = JSON.parse(
      JSON.stringify(escala_configuracion.grilla ?? [])
    );
    grillaCopy.sort((a, b) => a.id - b.id);
    //
    const salariosEstanVacios = grillaCopy.every(
      (objeto) => Array.isArray(objeto.salarios) && objeto.salarios.length === 0
    );
    if (!!salariosEstanVacios) {
      grillaCopy = grillaCopy.map((item, idx) => ({
        ...item,
        salarios: [1, 2, 3, 4].map(
          (n, ni) =>
            (grillaCopy.length - idx + 2) * 1000000 + (n * 1000000) / 10
        ),
      }));
    }
    //
    setGrillaLocal(grillaCopy);
  }, [escala_configuracion.grilla]);
  //
  return (
    <RemuneracionConfiguracionLayout
      title={"Escala"}
      onClickBack={onClickBack}
      currentStep={3}
      actionButton={finalizarButtonConfig}
    >
      {!activar_escala_loading && !escala_configuracion_loading && (
        <>
          <div className="w-full md:w-3/5 flex justify-between items-center bg-slate-100 rounded-md py-3 my-8 px-4">
            <h2 className="font-semibold text-3xl">
              Parámetros de las categorías
            </h2>
            <button
              onClick={openParametrosModal}
              className="bg-sky-500 text-white rounded-md py-3 px-4"
            >
              Configuración de escala
            </button>
          </div>
          {/*  */}
          <div className="py-2 px-8 -mx-8">
            <h1 className="font-semibold text-2xl">Escala Salarial</h1>
            {/*  */}
            <div className="table-container custom-scrollbar overflow-auto mb-4">
              <table className="w-full">
                <thead>
                  <tr>
                    <th className="text-left p-4">Categorías</th>
                    {!!grillaLocal &&
                      grillaLocal[0]?.salarios?.length > 0 &&
                      grillaLocal[0]?.salarios?.map((salario, nidx) => (
                        <th key={`cat-th-${nidx}`} className="text-left p-4">
                          {obtenerLetrasColumna(nidx + 1)}
                        </th>
                      ))}
                  </tr>
                </thead>
                <tbody>
                  {grillaLocal.length > 0 &&
                    grillaLocal.map((categoria, cidx) => (
                      <tr
                        key={`e-cat-tr-${cidx}`}
                        className="odd:bg-slate-200 even:bg-white rounded-md"
                      >
                        <td className="text-left p-4 rounded-tl-md rounded-bl-md">
                          {categoria.nombre}
                        </td>
                        {categoria.salarios?.map((salario, sidx) => (
                          <td
                            key={`salario-td-${sidx}`}
                            className={`text-left p-4 ${
                              sidx === categoria.salarios.length - 1
                                ? "rounded-tr-md rounded-br-md"
                                : ""
                            }`}
                          >
                            {formatNumber(salario)}
                          </td>
                        ))}
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </>
      )}
      {(!!activar_escala_loading || !!escala_configuracion_loading) && (
        <div className="w-full flex justify-center items-center h-96">
          <LoadingSpinButton />
        </div>
      )}
      <SelectModal
        title={
          !showFinalizar ? "Paramétros de las categorías" : "Finalizar escala"
        }
        titleClass={"text-2xl"}
        extraClass={`${!!showFinalizar ? "w-6/12" : "w-8/12"} px-4`}
        content={
          !showFinalizar ? (
            <ParametrosCategoria
              {...escalaConfiguracionLocal}
              onChangeEscala={onChangeEscala}
              onChange={onChangeCategoria}
              onSubmit={onSubmitConfiguracion}
            />
          ) : (
            <FinalizarConfiguracion
              onSecondaryClick={onMantenerEscala}
              onPrimaryClick={onActualizarEscala}
            />
          )
        }
      />
    </RemuneracionConfiguracionLayout>
  );
}
export default RemuneracionEscala;
