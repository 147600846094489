import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TableCustom from "src/modules/common/components/TableCustom";
import { ColaboradorTable } from "../components/ColaboradorTable";
import { ColaboradorToolbar } from "../components/ColaboradorToolbar";
import KpiLayout from "../components/KpiLayout";
import { kpiActions } from "../handlers/redux";
import FileInput from "src/modules/common/components/FileInput";
import LoadingSpinButton from "src/modules/common/components/LoadingSpinButton";
import { DownloadPlantilla } from "../helpers/helpers";
import { getToken } from "src/utils/helpers";
import CollapseCard from "src/modules/common/components/CollapseCard";

function KpiColaboradoresPage() {
  const dispatch = useDispatch();

  const importar_loading = useSelector((state) => state.kpi.importar_loading);
  const errores_importacion = useSelector(
    (state) => state.kpi.errores_importacion
  );

  const [Page, setPage] = useState(1);
  const [PageSize, setPageSize] = useState(50);
  const [PagesButtonsArray, setPagesButtonsArray] = useState([1]);

  useEffect(() => {
    dispatch(kpiActions.colaboradores());
    dispatch(kpiActions.setErroresImportacion([]));
  }, []);

  const colaboradores = useSelector((state) => state.kpi.colaboradores || {});
  const { count: totalPaginate = 0 } = colaboradores;
  const colaboradores_filter = useSelector(
    (state) => state.kpi.colaboradores_filter
  );

  const setPagination = (page, pageSize) => {
    //
    dispatch(
      kpiActions.setColaboradoresFilter({
        ...colaboradores_filter,
        pagination: {
          page: page,
          pageSize: pageSize,
        },
      })
    );
  };

  const getPageButtonsArray = (pagesCount) => {
    let pagesArr = [1];
    const pagesCountCeil = Math.ceil(pagesCount);
    for (let index = 2; index <= pagesCountCeil; index++) {
      pagesArr.push(index);
    }
    return pagesArr;
  };
  //
  const uploadKpisPorColaborador = (file) => {
    dispatch(kpiActions.setFileToUploadPorColaborador(file));
  };
  const uploadKpisPorCargo = (file) => {
    dispatch(kpiActions.setFileToUploadPorCargo(file));
  };
  const onPlantilla = (plantilla) => {
    const token = getToken();
    DownloadPlantilla({ plantilla, token });
  };
  //
  return (
    <KpiLayout>
      <div className="mt-8"></div>
      <div className="md:w-11/12 flex flex-col gap-3">
        <ColaboradorToolbar
          {...{
            totalPaginate,
            getPageButtonsArray,
            Page,
            setPage,
            PageSize,
            setPageSize,
            PagesButtonsArray,
            setPagesButtonsArray,
            setPagination,
          }}
        />
        <ColaboradorTable
          {...{
            totalPaginate,
            getPageButtonsArray,
            Page,
            setPage,
            PageSize,
            setPageSize,
            PagesButtonsArray,
            setPagesButtonsArray,
            setPagination,
          }}
        />
        {!importar_loading && (
          <div className="flex w-full mb-4">
            <FileInput
              label={"Importador por colaborador"}
              className={"mt-3 w-3/4"}
              onSubmit={uploadKpisPorColaborador}
              onPlantilla={() => onPlantilla("por-colaborador")}
            />
            <FileInput
              label={"Importador por cargo"}
              className={"mt-3 w-3/4"}
              onSubmit={uploadKpisPorCargo}
              onPlantilla={() => onPlantilla("por-cargo")}
            />
          </div>
        )}
        {!!importar_loading && (
          <div className="flex items-center justify-center h-20">
            <LoadingSpinButton message="Importando datos, por favor espere..."></LoadingSpinButton>
          </div>
        )}
        {errores_importacion.length > 0 && (
          <CollapseCard
            titulo={"Ver errores"}
            extraClass="mb-8"
            titleClass={"text-red-500 font-semibold"}
            initCollapse={false}
          >
            <div className="flex flex-col gap-2">
              {errores_importacion.map((err, idx) => (
                <div className="p-1 text-red-400">{err}</div>
              ))}
            </div>
          </CollapseCard>
        )}
      </div>
    </KpiLayout>
  );
}

export default KpiColaboradoresPage;
