import React from "react";
import { Route } from "react-router-dom";
import LoginPage from "src/modules/auth/pages/LoginPage";

export const authRoutes = [
  {
    path: "/auth/login",
    element: <LoginPage />,
  },
  {
    path: "/login",
    element: <LoginPage />,
  },
];
