import { useState } from "react";
import ToggleSwitch from "src/modules/common/components/ToggleSwitch";

function PlanCarreraForm({ planCarrera = {}, onSubmit }) {
  const [usarCategoriasChecked, setUsarCategoriasChecked] = useState(false);
  const [errores, setErrores] = useState({});
  const [planCarreraLocal, setPlanCarreraLocal] = useState({ ...planCarrera });
  const {
    nombre = "",
    descripcion = "",
    usar_categorias_escala = false,
  } = planCarreraLocal;
  const onChangeUsarCategorias = () => {
    setUsarCategoriasChecked(!usarCategoriasChecked);
    setPlanCarreraLocal({
      ...planCarreraLocal,
      usar_categorias_escala: !usarCategoriasChecked,
    });
  };
  const formIsValid = () => {
    let isValid = true;
    if (!nombre) {
      setErrores({
        ...errores,
        nombre: "Este campo es obligatorio",
      });
      isValid = false;
    } else {
      setErrores({
        ...errores,
        nombre: "",
      });
    }
    //
    return isValid;
  };
  const onSubmitLocal = () => {
    if (formIsValid()) {
      if (!!onSubmit) {
        onSubmit(planCarreraLocal);
      }
    }
  };
  const onChange = (key) => (event) => {
    setPlanCarreraLocal({
      ...planCarreraLocal,
      [key]: event.target.value,
    });
  };
  useState(() => {
    setPlanCarreraLocal(planCarrera);
  }, [planCarrera]);
  //
  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col">
        <label className="font-semibold" htmlFor="">
          Nombre de la familia <span className="text-red-500">*</span>
        </label>
        <input
          type="text"
          value={nombre}
          className="p-2 border rounded-md"
          onChange={onChange("nombre")}
        />
        {!!errores.nombre && (
          <span className="text-sm text-red-500 mt-1">{errores.nombre}</span>
        )}
      </div>
      {/*  */}
      <div className="flex flex-col">
        <label className="font-semibold" htmlFor="">
          Breve descripción
        </label>
        <textarea
          className="border rounded-md p-2"
          cols="30"
          rows="3"
          value={descripcion}
          onChange={onChange("descripcion")}
        ></textarea>
      </div>
      {/*  */}
      {!planCarrera.id && (
        <div>
          <label className="font-semibold" htmlFor="">
            Usar categorías creadas en Escala Salarial
          </label>
          <div className="flex gap-2 p-4">
            <label htmlFor="">Activar categorías de escala salarial</label>
            <ToggleSwitch
              className="mr-2"
              checked={usarCategoriasChecked}
              onChange={onChangeUsarCategorias}
            />
          </div>
        </div>
      )}
      {/*  */}
      <div className="w-full">
        <button
          onClick={onSubmitLocal}
          className="w-full rounded-md bg-sky-500 text-white p-3"
        >
          {`${!planCarrera.id ? "Crear nueva familia" : "Actualizar familia"}`}
        </button>
      </div>
    </div>
  );
}
export default PlanCarreraForm;
