import axios from "axios";

const API_URL =
  process.env.REACT_APP_API_URL || "http://localhost:8000/api/admin";

export const authApi = () => {
  return {
    login: async ({ user, password, empresa }) => {
      try {
        let response = await axios.post(
          API_URL + "/../token/",
          {
            username: user,
            empresa_id: empresa,
            password,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
            withCredentials: true, // default
          }
        );
        return response.data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log(error);
        throw error;
      }
    },
    refresh: async ({ refresh }) => {
      try {
        let response = await axios.post(
          API_URL + "/../token/refresh/",
          {
            refresh: refresh,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
            withCredentials: true, // default
          }
        );
        return response.data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log(error);
        throw error;
      }
    },
    getEmpresas: async ({ username }) => {
      try {
        let response = await axios.post(
          API_URL + "/../get-empresas/",
          {
            username: username,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
            withCredentials: true, // default
          }
        );
        return response.data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log(error);
        throw error;
      }
    },
  };
};
