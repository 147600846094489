import axios from "axios";
const URL_BASE =
  process.env.REACT_APP_API_URL || "http://localhost:8000/api/admin";

export const competenciaCargoApi = (token) => {
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };

  return {
    create: async (params) => {
      try {
        const cargo = await axios.post(
          URL_BASE + "/competencia/cargo/create",
          params,
          { headers }
        );
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log(JSON.stringify(error));
        throw error;
      }
    },
    update: async ({ id, ...params }) => {
      try {
        const data = await axios.put(
          URL_BASE + `/competencia/cargo/${id}/edit`,
          params,
          {
            headers,
          }
        );
        return data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log(JSON.stringify(error));
        throw error;
      }
    },
    detail: async ({ id }) => {
      try {
        const competencia = await axios.get(
          URL_BASE + `/competencia/cargo/${id}/detail`,
          {
            headers,
          }
        );
        return competencia.data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log(JSON.stringify(error));
        throw error;
      }
    },
    delete: async ({ id }) => {
      try {
        const data = await axios.delete(
          URL_BASE + `/competencia/cargo/${id}/delete`,
          {
            headers,
          }
        );
        return data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log(JSON.stringify(error));
        throw error;
      }
    },
    competenciasCargoDelete: async ({ competencias_cargo_ids }) => {
      // Borra varias Comeptencias
      try {
        const data = await axios.post(
          URL_BASE + `/competencia/cargo/cargos/delete`,
          { competencias_cargo_ids: competencias_cargo_ids },
          { headers }
        );
        return data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log(JSON.stringify(error));
        throw error;
      }
    },
    list: async ({ competenciaCargos_filter }) => {
      const { nombre = "" } = competenciaCargos_filter;
      //
      try {
        const cargos = await axios.get(
          `${URL_BASE}/competencia/cargo/?search=${nombre}`,
          {
            headers,
          }
        );
        return cargos.data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log("cargos error", error);
        throw error;
      }
    },
    libreriaEspecifica: async (params) => {
      const { nombre = "", pagination = {}, ordering = "" } = params;
      const { page = 1, pageSize = 100 } = pagination;
      try {
        const libreria = await axios.get(
          URL_BASE +
            `/competencia/libreria/especifica/?search=${nombre}&page=${page}&page_size=${pageSize}`,
          {
            headers,
          }
        );
        return libreria.data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG &&
          console.log("libreria api error", error);
        throw error;
      }
    },
  };
};
