import { createSlice } from "@reduxjs/toolkit";

const analisisSlice = createSlice({
  name: "Analisis",
  initialState: {
    analisis: {},
    analisis_loading: false,
  },
  reducers: {
    analisis: (state, { payload = {} }) => {
      state.analisis = payload;
      state.analisis_loading = true;
    },
    setAnalisis: (state, { payload }) => {
      !!process.env.REACT_APP_DEBUG &&
        console.info("setAnalisis redux", payload);
      state.analisis = payload;
      state.analisis_loading = false;
    },
  },
});

export const analisisActions = analisisSlice.actions;
export const analisisReducer = analisisSlice.reducer;
