import { createSlice } from "@reduxjs/toolkit";

const cargoSlice = createSlice({
  name: "Cargo",
  initialState: {
    cargo_create_loading: false,
    cargo_update_loading: false,
    cargo_delete_loading: false,
    cargo: {},
    cargos: { result: [] },
    cargos_checked: [],
    cargos_filter: {},
    cargos_delete: [],
    cargos_loading: false,
    cargo_create_modal: { open: false },
    //
    fileToUpload: null,
    datosVerificados: {},
    nuevosChecked: [],
    duplicadosChecked: [],
    datosImportar: [],
    importar_loading: false,
  },
  reducers: {
    setFileToUpload: (state, { payload = {} }) => {
      !!process.env.REACT_APP_DEBUG && console.info("setFileToUpload redux");
      state.fileToUpload = payload;
    },
    setDatosVerificados: (state, { payload = {} }) => {
      !!process.env.REACT_APP_DEBUG &&
        console.info("setDatosVerificados redux");
      state.datosVerificados = payload;
    },
    setDatosImportar: (state, { payload = {} }) => {
      !!process.env.REACT_APP_DEBUG && console.info("setDatosImportar redux");
      state.datosImportar = payload;
    },
    checkNuevo: (state, { payload: { item, checked = true } }) => {
      !!process.env.REACT_APP_DEBUG &&
        console.info("checkNuevo redux", item, checked);
      let temp = state.nuevosChecked.filter((nc) => nc.id !== item.id);
      !!process.env.REACT_APP_DEBUG && console.info("temp", temp);
      state.nuevosChecked = temp;
      if (!!checked) {
        state.nuevosChecked.push(item);
      }
      // state.kpiColaboradores_loading = false;
    },
    resetCheckNuevos: (state) => {
      state.nuevosChecked = [];
    },
    checkDuplicado: (state, { payload: { item, checked = true } }) => {
      !!process.env.REACT_APP_DEBUG &&
        console.info("checkDuplicado redux", item, checked);
      let temp = state.duplicadosChecked.filter((dc) => dc.id !== item.id);
      state.duplicadosChecked = temp;
      if (!!checked) {
        state.duplicadosChecked.push(item);
      }
      // state.kpiColaboradores_loading = false;
    },
    resetCheckDuplicados: (state) => {
      state.duplicadosChecked = [];
    },
    importarDatosComplete: (state, { payload = {} }) => {
      state.nuevosChecked = [];
      state.duplicadosChecked = [];
      state.datosVerificados = [];
      state.datosImportar = [];
      state.importar_loading = false;
    },
    //
    cargoSet: (state, { payload = {} }) => {
      state.cargo = payload;
    },
    setCargos: (state, { payload }) => {
      state.cargos = payload.cargos;
      state.cargos_loading = false;
    },
    setCargosFilter: (state, { payload = {} }) => {
      state.cargos_filter = payload;
    },
    setCargosDelete: (state, { payload }) => {
      state.cargos_delete = payload;
    },
    resetCargosDelete: (state, { payload }) => {
      state.cargos_delete = [];
    },
    //
    resetCheckCargos: (state) => {
      state.cargos_checked = [];
      state.cargos_loading = false;
    },
    setCheckCargos: (state, { payload }) => {
      state.cargos_checked = payload;
    },
    checkCargo: (state, { payload: { cargo, checked = true } }) => {
      state.cargos_checked = state.cargos_checked.filter(
        (id) => id != cargo.id
      );
      if (!!checked) {
        state.cargos_checked.push(cargo.id);
      }
      state.cargos_loading = false;
    },
    // create
    setCargoCreateModal: (state, { payload = {} }) => {
      !!process.env.REACT_APP_DEBUG && console.log({ payload });
      state.cargo_create_modal = payload;
    },
    cargoCreate: (state, { payload = {} }) => {
      state.cargo_create_loading = true;
    },
    cargoCreateSuccess: (state) => {
      state.cargo_create_loading = false;
    },
    // update
    cargoUpdate: (state, { payload = {} }) => {
      state.cargo_update_loading = true;
    },
    cargoUpdateSuccess: (state) => {
      state.cargo_update_loading = false;
    },
    // delete
    cargoDelete: (state, { payload = {} }) => {
      state.cargo_delete_loading = true;
    },
    cargoDeleteSuccess: (state) => {
      state.cargo_delete_loading = false;
    },
    // list
    cargos: (state, { payload }) => {
      state.cargos_loading = true;
    },
  },
});

export const cargoActions = cargoSlice.actions;
export const cargoReducer = cargoSlice.reducer;
