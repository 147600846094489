import React from "react";
import TableCustom from "src/modules/common/components/TableCustom";

function ColaboradorRemuneracionPage() {
  return (
    <div className="flex justify-center items-center w-full mt-10 pb-4">
      <div className="flex flex-col w-full md:w-5/6 gap-3">
        <div className="border-b pb-2">
          <span className="font-bold text-lg">Resumen de remuneraciones</span>
        </div>
        {/* graficos */}
        <div className="grid grid-cols-4 gap-4">
          <div className="col-span-2 flex flex-col justify-between border border-zinc-200 rounded h-64 p-4">
            <div className="text-md font-bold">Salario actual</div>
            <div className="text-4xl font-bold">
              <span className="font-normal text-2xl">Gs.</span>00.000.000
            </div>
            <div className="">
              <div className="text-xs">Ultimo ajuste salarial:</div>
              <div className="text-sm">26 Julio 2022</div>
            </div>
          </div>
          <div className="col-span-2 border border-zinc-200 rounded h-64 p-4">
            <div className="text-md font-bold">Historial de ajustes</div>
          </div>
        </div>
        {/* tabla */}
        <div className="flex flex-col gap-3 border border-zinc-200 rounded p-4">
          <span className="font-bold text-md">
            Escala salarial de la organizcion
          </span>
          {/* graficos */}
          <div className="grid grid-cols-3 gap-4">
            <div className="border border-zinc-200 rounded p-4 flex flex-col gap-2">
              <div className="text-sm font-bold">
                Su cargo se encuentra enla categoria
              </div>
              <div className="text-2xl font-bold flex-grow flex items-center">
                Coordinadores
              </div>
            </div>
            <div className="border border-zinc-200 rounded p-4 flex flex-col gap-2">
              <div className="text-sm font-bold">
                Rango salarial de tu categoria
              </div>
              <div className="flex flex-row gap-3 items-center">
                {/* categoria item */}
                <div className="flex-grow flex flex-col gap-2">
                  <div className="text-xs">Nivel A</div>
                  <div className="text-sm text-center py-3 rounded bg-zinc-100">
                    18.728.000
                  </div>
                </div>
                <div className="h-4 w-4 bg-zinc-100"></div>
                <div className="flex-grow flex flex-col gap-2 ">
                  <div className="text-xs">Nivel A</div>
                  <div className="text-sm text-center py-3 rounded bg-zinc-100">
                    18.728.000
                  </div>
                </div>
              </div>
            </div>
            <div className="border border-zinc-200 rounded p-4 flex flex-col justify-between gap-2">
              <div className="text-sm font-bold">Salario actual</div>
              <div className="text-3xl font-bold">
                <div className="">
                  <span className="text-xl font-normal">Gs.</span>00.000.000
                </div>
              </div>
              <div className="text-md">Por debajo del rango</div>
            </div>
          </div>
          {/* lista */}
          <div className="flex flex-col gap-2">
            {/* item */}

            <div className="grid grid-cols-6">
              {/* titulo */}
              <>
                <div className="text-md p-3 font-bold">Categorias</div>
                <div className="text-md p-3 font-bold">A</div>
                <div className="text-md p-3 font-bold">B</div>
                <div className="text-md p-3 font-bold">C</div>
                <div className="text-md p-3 font-bold">D</div>
                <div className="text-md p-3 font-bold">E</div>
              </>
              {/* item */}
              <>
                <>
                  <div className="bg-sky-100 bg-opacity-50 p-2">Gerentes</div>
                  <div className="bg-sky-100 flex items-center bg-opacity-50 p-2">
                    <div className="w-2/3 h-3 rounded-lg bg-zinc-200"></div>
                  </div>
                  <div className="bg-sky-100 flex items-center bg-opacity-50 p-2">
                    <div className="w-2/3 h-3 rounded-lg bg-zinc-200"></div>
                  </div>
                  <div className="bg-sky-100 flex items-center bg-opacity-50 p-2">
                    <div className="w-2/3 h-3 rounded-lg bg-zinc-200"></div>
                  </div>
                  <div className="bg-sky-100 flex items-center bg-opacity-50 p-2">
                    <div className="w-2/3 h-3 rounded-lg bg-zinc-200"></div>
                  </div>
                  <div className="bg-sky-100 flex items-center bg-opacity-50 p-2">
                    <div className="w-2/3 h-3 rounded-lg bg-zinc-200"></div>
                  </div>
                </>
                <>
                  <div className="  p-2">Jefes</div>
                  <div className=" flex items-center  p-2">
                    <div className="w-2/3 h-3 rounded-lg bg-zinc-200"></div>
                  </div>
                  <div className=" flex items-center  p-2">
                    <div className="w-2/3 h-3 rounded-lg bg-zinc-200"></div>
                  </div>
                  <div className=" flex items-center  p-2">
                    <div className="w-2/3 h-3 rounded-lg bg-zinc-200"></div>
                  </div>
                  <div className=" flex items-center  p-2">
                    <div className="w-2/3 h-3 rounded-lg bg-zinc-200"></div>
                  </div>
                  <div className=" flex items-center  p-2">
                    <div className="w-2/3 h-3 rounded-lg bg-zinc-200"></div>
                  </div>
                </>
                <>
                  <div className="bg-sky-100 bg-opacity-50 p-2">
                    Coordinadores
                  </div>
                  <div className="bg-sky-100 flex items-center bg-opacity-50 p-2">
                    4.800.000
                  </div>
                  <div className="bg-sky-100 flex items-center bg-opacity-50 p-2">
                    5.280.000
                  </div>
                  <div className="bg-sky-100 flex items-center bg-opacity-50 p-2">
                    5.808.000
                  </div>
                  <div className="bg-sky-100 flex items-center bg-opacity-50 p-2">
                    6.389.000
                  </div>
                  <div className="bg-sky-100 flex items-center bg-opacity-50 p-2">
                    7.028.000
                  </div>
                </>
                <>
                  <div className="  p-2">Auxiliares</div>
                  <div className=" flex items-center  p-2">
                    <div className="w-2/3 h-3 rounded-lg bg-zinc-200"></div>
                  </div>
                  <div className=" flex items-center  p-2">
                    <div className="w-2/3 h-3 rounded-lg bg-zinc-200"></div>
                  </div>
                  <div className=" flex items-center  p-2">
                    <div className="w-2/3 h-3 rounded-lg bg-zinc-200"></div>
                  </div>
                  <div className=" flex items-center  p-2">
                    <div className="w-2/3 h-3 rounded-lg bg-zinc-200"></div>
                  </div>
                  <div className=" flex items-center  p-2">
                    <div className="w-2/3 h-3 rounded-lg bg-zinc-200"></div>
                  </div>
                </>
              </>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ColaboradorRemuneracionPage;
