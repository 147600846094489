import axios from "axios";

export function DescargarColaboradoresInforme(
  token = "",
  onComplete = null,
  requestData
) {
  const URL_BASE =
    process.env.REACT_APP_API_URL || "http://localhost:8000/api/admin";
  const requestURL = URL_BASE + `/empleado/descargar-listado-colaboradores`;
  const headers = {
    // "Content-Type": "blob",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };
  //
  axios({
    url: requestURL,
    method: "POST",
    headers: headers,
    responseType: "blob",
    data: requestData,
  })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      // Obtener la fecha actual
      const today = new Date();
      const day = String(today.getDate()).padStart(2, "0");
      const month = String(today.getMonth() + 1).padStart(2, "0"); // Enero es 0!
      const year = today.getFullYear();

      // Formatear la fecha como dd-mm-yyyy
      const formattedDate = `${day}-${month}-${year}`;

      link.setAttribute(
        "download",
        `Nomina_Colaboradores_${formattedDate}.xlsx`
      );
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
      //
      if (!!onComplete) {
        onComplete();
      }
    })
    .catch((e) => {
      console.info("Error al descargar el archivo");
      if (!!onComplete) {
        onComplete();
      }
    });
}
