const FILL_CLASS = [
  "fill-sky-500",
  "fill-green-500",
  "fill-red-500",
  "fill-yellow-500",
  "fill-orange-500"
];

const BG_CLASS = ["bg-sky-500", "bg-green-500", "bg-red-500", "bg-yellow-500", "bg-orange-500"];

export const getFillIndex = (index) => {
  return FILL_CLASS[index % FILL_CLASS.length];
};

export const getBGIndex = (index) => {
  return BG_CLASS[index % BG_CLASS.length];
};
