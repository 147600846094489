import React from "react";
import KpiColaboradorDetailPage from "../pages/KpiColaboradorDetailPage";
import KpiColaboradoresDetailPage from "../pages/KpiColaboradoresDetailPage";
import KpiColaboradoresPage from "../pages/KpiColaboradoresPage";
import KpiColaboradorFormularioPage from "../pages/KpiColaboradorFormularioPage";
import KpiCreadosPage from "../pages/KpiCreadosPage";
import KpiDetailPage from "../pages/KpiDetailPage";
import KpiFormularioPage from "../pages/KpiFormularioPage";
import KPIAsignacionMasivaPage from "../pages/KPIAsignacionMasivaPage";

export const kpisRoutes = [
  {
    path: "/kpis/",
    element: <KpiCreadosPage />,
  },
  {
    path: "/kpis/colaboradores",
    element: <KpiColaboradoresPage />,
  },
  {
    path: "/kpis/colaboradores/asignar-kpis",
    element: <KPIAsignacionMasivaPage />,
  },
  {
    path: "/kpis/detalle/:idkpi",
    element: <KpiDetailPage />,
  },
  {
    path: "/kpis/colaboradores/:idColaborador",
    element: <KpiColaboradoresDetailPage />,
  },
  {
    path: "/kpis/configuracion",
    element: <KpiFormularioPage />,
  },
  {
    path: "/kpis/configuracion/:idkpi",
    element: <KpiFormularioPage />,
  },
  {
    path: "/kpis/kpi-colaborador/:idkpi",
    element: <KpiColaboradorDetailPage />,
  },
  {
    path: "/kpis/kpi-colaborador/:idkpi/edit",
    element: <KpiColaboradorFormularioPage />,
  },
];
