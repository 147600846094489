import { push } from "@lagunovsky/redux-react-router";
import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import { ErrorToast } from "src/modules/common/components/ErrorToast";
import { SuccessToast } from "src/modules/common/components/SuccessToast";
import { getToken, ObjectToString } from "src/utils/helpers";
import { areaApi } from "./api";
import { areaActions } from "./redux";

function* areaDeleteSaga({ payload }) {
  const token = getToken();
  const area = payload;

  const params = { ...area };
  !!process.env.REACT_APP_DEBUG && console.log({ payload });
  try {
    const data = yield call(areaApi(token).delete, params);

    yield put(areaActions.areas());
    yield put(areaActions.areaDeleteSuccess());
    SuccessToast({ message: "Área eliminada con éxito!" });
  } catch (error) {
    const errores = error.response.data;
    const message = ObjectToString(errores);
    ErrorToast({ message: message });
    yield put(areaActions.areaDeleteSuccess());
    !!process.env.REACT_APP_DEBUG && console.log({ error });
  }
}

function* areaUpdateSaga({ payload }) {
  const token = getToken();
  const area = yield select((state) => state.area.area);

  const params = { ...area };
  try {
    const data = yield call(areaApi(token).update, params);
    yield put(areaActions.areaUpdateSuccess());
    yield put(push("/estructura/areas"));
    yield put(areaActions.setAreaCreateModal({ open: false }));
    yield put(areaActions.areas());
    SuccessToast({ message: "Área editada con éxito!" });
  } catch (error) {
    const errores = error.response.data;
    const message = ObjectToString(errores);
    ErrorToast({ message: message });
    yield put(areaActions.areaUpdateSuccess());
    !!process.env.REACT_APP_DEBUG && console.log({ error });
  }
}

function* areaCreateSaga({ payload }) {
  const token = getToken();
  const area = yield select((state) => state.area.area);

  const params = { ...area };
  try {
    const data = yield call(areaApi(token).create, params);
    yield put(areaActions.areaCreateSuccess());
    yield put(push("/estructura/areas"));
    yield put(areaActions.areas());
    yield put(areaActions.setAreaCreateModal({ open: false }));
    SuccessToast({ message: "Área creada con éxito!" });
  } catch (error) {
    const errores = error.response.data;
    const message = ObjectToString(errores);
    ErrorToast({ message: message });
    !!process.env.REACT_APP_DEBUG && console.log({ error });
  }
}

function* areasSaga({ payload }) {
  const token = getToken();
  const areas_filter = yield select((state) => state.area.areas_filter);
  try {
    const data = yield call(areaApi(token).list, { areas_filter });
    yield put(areaActions.setAreas({ areas: data }));
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
  }
}

function* areasDeleteSaga({ payload }) {
  const token = getToken();
  const areasDelete = payload;
  !!process.env.REACT_APP_DEBUG && console.log({ payload });
  try {
    const data = yield call(areaApi(token).areasDelete, {
      areasIds: areasDelete,
    });
    yield put(areaActions.resetCheckAreas());
    yield put(areaActions.areas());
    SuccessToast({ message: "Áreas eliminadas con éxito!" });
  } catch (error) {
    const errores = error.response.data;
    const message = ObjectToString(errores);
    ErrorToast({ message: message });
    !!process.env.REACT_APP_DEBUG && console.log({ error });
  }
}

function* areasImportarVerificarSaga({ payload }) {
  const token = getToken();
  const fileToUpload = yield select((state) => state.area.fileToUpload);
  try {
    const data = yield call(areaApi(token).importarVerificar, {
      fileToUpload,
    });
    yield put(areaActions.setDatosVerificados(data));
    yield put(push("/importador/area"));
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
  }
}

function* areasImportarSaga({ payload }) {
  const token = getToken();
  const datos = yield select((state) => state.area.datosImportar);
  try {
    const data = yield call(areaApi(token).importar, datos);
    yield put(areaActions.importarDatosComplete());
    SuccessToast({ message: "Áreas importadas con éxito!" });
    yield put(push("/estructura/areas"));
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
  }
}

export function* areaSagas() {
  yield takeEvery(areaActions.setFileToUpload.type, areasImportarVerificarSaga);
  yield takeEvery(areaActions.setDatosImportar.type, areasImportarSaga);
  // create
  yield takeEvery(areaActions.areaCreate.type, areaCreateSaga);
  // update
  yield takeEvery(areaActions.areaUpdate.type, areaUpdateSaga);
  // delete
  yield takeEvery(areaActions.areaDelete.type, areaDeleteSaga);
  yield takeEvery(areaActions.setAreasDelete.type, areasDeleteSaga);
  // list
  yield takeLatest(areaActions.areas.type, areasSaga);
  yield takeLatest(areaActions.setAreasFilter.type, areasSaga);
}
