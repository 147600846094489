import SearchInput from "src/modules/remuneracion/components/SearchInput";
import { FeedbackLayout } from "../components/FeedbackLayout";
import { SucursalSelect } from "src/modules/estructura/modules/sucursal/components/SucursalSelect";
import { AreaSelect } from "src/modules/estructura/modules/area/components/AreaSelect";
import { DepartamentoSelect } from "src/modules/estructura/modules/departamento/components/DepartamentoSelect";
import DataTable from "src/modules/common/components/DataTable";
import { ChevronRight } from "react-bootstrap-icons";
import userDefault from "src/modules/common/components/images/user_default.png";
import { useNavigate } from "react-router-dom";
import MostrarPaginas from "src/modules/common/components/MostrarPaginas";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { feedbackActions } from "../handlers/redux";
import ReactSelect from "react-select";

const feedback = {
  id: 1,
  nombre: "Feedback 1",
  fecha_inicio: "14/02/2024",
  fecha_fin: "14/03/2024",
  porcentaje_avance_general: 50,
};

export function FeedbackPorJefeDirectoPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const feedbacks_por_jefe = useSelector(
    (state) => state.feedback.feedbacks_por_jefe
  );
  const feedbacks_por_jefe_loading = useSelector(
    (state) => state.feedback.feedbacks_por_jefe_loading
  );
  const feedbacks_por_jefe_count = useSelector(
    (state) => state.feedback.feedbacks_por_jefe_count
  );
  const feedbacks_por_jefe_filter = useSelector(
    (state) => state.feedback.feedbacks_por_jefe_filter
  );
  const feedbacks_por_jefe_checked = useSelector(
    (state) => state.feedback.feedbacks_por_jefe_checked
  );

  const { nombre = "" } = feedbacks_por_jefe_filter;

  const [outerPageSize, setOuterPageSize] = useState(50);

  useEffect(() => {
    dispatch(feedbackActions.getFeedbacksPorJefe());
  }, []);

  const onFilter = (field) => (value) => {
    console.log(field, value);
  };

  const goToDetail = (item) => {
    navigate(`/feedback/${item.id}/jefe-directo/${item.jefe_directo.id}/`);
  };

  const onDeleteFeedback = (feedbackId) => {
    const deseaEliminar = window.confirm(
      "¿Está seguro de eliminar el feedback seleccionado?"
    );
    if (!!deseaEliminar) {
      const param = { ids: [feedbackId] };
      dispatch(feedbackActions.bulkDeleteFeedback(param));
    }
  };

  const cantidadColaboradoresFotos = 4;

  const datatableConfig = {
    headers: [
      {
        title: "Feedback",
        contentClass: "font-bold px-2 py-4",
        // onOrder: setOrdering("nombre"),
        // accessor: "nombre",
        contentRenderer: (item) => (
          <div className="flex flex-col gap-1">
            <span className="font-semibold text-base">{item.nombre}</span>
          </div>
        ),
      },
      {
        title: "Jefe Directo",
        contentClass: "font-bold px-2 py-4",
        // onOrder: setOrdering("nombre"),
        // accessor: "nombre",
        contentRenderer: (item) => (
          <div className="flex flex-row gap-x-2 m-4">
            <img
              className="object-cover h-12 w-12 rounded-md"
              src={
                item.jefe_directo.foto_empleado
                  ? item.jefe_directo.foto_empleado
                  : userDefault
              }
              alt={`Perfil`}
            />
            <div className="flex flex-col gap-1">
              <span className="font-semibold text-base">
                {item.jefe_directo.nombre} {item.jefe_directo.apellido}
              </span>
              <span className="text-base">
                Cargo:{" "}
                {item.jefe_directo.cargo
                  ? item.jefe_directo.cargo.nombre
                  : "Sin cargo"}
              </span>
            </div>
          </div>
        ),
      },
      {
        title: "Sucursal",
        contentClass: "font-bold px-2 py-4",
        // onOrder: setOrdering(""),
        // accessor: "nombre",
        contentRenderer: (item) => (
          <div className="flex flex-col">
            <span className="">
              {item.jefe_directo.sucursal
                ? item.jefe_directo.sucursal.nombre
                : "Sin sucursal"}
            </span>
          </div>
        ),
      },
      {
        title: "Área",
        contentClass: "font-bold px-2 py-4",
        // onOrder: setOrdering(""),
        // accessor: "nombre",
        contentRenderer: (item) => (
          <div className="flex flex-col">
            <span className="">
              {item.jefe_directo.area
                ? item.jefe_directo.area.nombre
                : "Sin área"}
            </span>
          </div>
        ),
      },
      {
        title: "Departamento",
        contentClass: "font-bold px-2 py-4",
        // onOrder: setOrdering(""),
        // accessor: "nombre",
        contentRenderer: (item) => (
          <div className="flex flex-col">
            <span className="">
              {item.jefe_directo.departamento
                ? item.jefe_directo.departamento.nombre
                : "Sin departamento"}
            </span>
          </div>
        ),
      },
      {
        title: "Colaboradores",
        contentClass: "font-bold px-2 py-4",
        // onOrder: setOrdering(""),
        // accessor: "nombre",
        contentRenderer: (item) => (
          <div className="flex flex-col">
            <div className="flex flex-row align-middle gap-1">
              {item.colaboradores
                .slice(0, cantidadColaboradoresFotos)
                .map((colaborador, index) => (
                  <div
                    key={index}
                    className="rounded-lg overflow-hidden w-8 h-8 "
                  >
                    <img
                      src={
                        colaborador.foto_empleado
                          ? colaborador.foto_empleado
                          : userDefault
                      }
                      alt="Perfil"
                      title={`${colaborador.nombre} ${
                        colaborador.apellido ? colaborador.apellido : ""
                      }`}
                      className="w-full h-full object-cover"
                    />
                  </div>
                ))}
              {/*Indicador de cuantos jefes más hay, mostrado solo si hay más de la cantidad que indique cantidadJefesFotos*/}
              {item.colaboradores.length > cantidadColaboradoresFotos && (
                <div
                  className="flex justify-center items-center w-8 h-8 rounded-lg bg-gray-200 text-black cursor-default"
                  title={`${
                    item.colaboradores.length - cantidadColaboradoresFotos
                  } ${
                    item.colaboradores.length - cantidadColaboradoresFotos === 1
                      ? "colaborador más"
                      : "colaboradores más"
                  }`}
                >
                  +{item.colaboradores.length - cantidadColaboradoresFotos}
                </div>
              )}
              {item.colaboradores.length === 0 && <div>Sin colaboradores</div>}
            </div>
          </div>
        ),
      },
      {
        title: "",
        contentClass: "font-bold px-2 py-4",
        // onOrder: setOrdering(""),
        // accessor: "nombre",
        contentRenderer: (item) => (
          <div className="cursor-pointer">
            <ChevronRight
              color="#0ea4e9"
              onClick={() => goToDetail(item)}
            ></ChevronRight>
          </div>
        ),
      },
    ],
    rowActions: [
      {
        label: "Eliminar",
        action: (item) => {
          onDeleteFeedback(item.id);
        },
      },
    ],
  };

  const onPaginate = (page, pageSize) => {
    dispatch(
      feedbackActions.setFeedbacksPorJefeFilter({
        ...feedbacks_por_jefe_filter,
        pagination: {
          page: page,
          pageSize: pageSize,
        },
      })
    );
  };

  const onCheckAll = () => {
    let checkedData = JSON.parse(JSON.stringify(feedbacks_por_jefe));
    if (feedbacks_por_jefe_checked.length > 0) {
      checkedData = [];
    }
    dispatch(feedbackActions.setCheckFeedbacksPorJefe(checkedData));
  };

  const onCheckFeedbackPorJefe = (item) => (event) => {
    console.log(item);
    dispatch(
      feedbackActions.checkFeedbacksPorJefe({
        feedback: item,
        checked: event.target.checked,
      })
    );
  };

  const onSearchFeedback = (e) => {
    let searchText = e.target.value;
    dispatch(
      feedbackActions.setFeedbacksPorJefeFilter({
        ...feedbacks_por_jefe_filter,
        nombre: searchText,
      })
    );
  };

  const onChangePageSize = (e) => {
    let value = e.target.value;
    dispatch(
      feedbackActions.setFeedbacksPorJefeFilter({
        ...feedbacks_por_jefe_filter,
        pagination: {
          pageSize: parseInt(value),
          page: 1,
        },
      })
    );
    setOuterPageSize(parseInt(value));
  };

  const actionOptions = [
    {
      value: "delete",
      label: "Eliminar",
      // action: () => {},
    },
  ];

  const onAction = (v) => {
    if (v.value === "delete") {
      const deseaEliminar = window.confirm(
        "¿Está seguro de eliminar los feedbacks seleccionados?"
      );
      if (!!deseaEliminar) {
        const ids = feedbacks_por_jefe_checked.map((feedback) => feedback.id);
        console.log(ids);
        dispatch(feedbackActions.bulkDeleteFeedback({ ids: ids }));
      }
      return;
    }
  };

  return (
    <FeedbackLayout>
      <div className="m-6 p-4">
        <div className="flex flex-row justify-between gap-4">
          <SearchInput
            containerClass={`w-1/2x`}
            placeHolder={"Buscar por nombre..."}
            value={nombre}
            onChange={onSearchFeedback}
          />
          <div className="text-sm">
            <ReactSelect
              className="w-52 h-10 bg-blue-200 inline-grid"
              value={{
                value: "",
                label: `(${feedbacks_por_jefe_checked.length}) seleccionados`,
              }}
              options={actionOptions}
              onChange={onAction}
            ></ReactSelect>
          </div>
          <MostrarPaginas
            containerClass={"w-1/2x"}
            onChangePageSize={onChangePageSize}
            defaultOption={50}
          />
        </div>
        <div className="flex flex-row justify-between my-6 gap-4">
          <SucursalSelect
            className="w-full"
            onChange={onFilter("sucursal")}
            defaultLabel="Sucursal: (Todas)"
          />
          <AreaSelect
            className="w-full"
            onChange={onFilter("area")}
            defaultLabel="Áreas: (Todas)"
          />
          <DepartamentoSelect
            className="w-full"
            onChange={onFilter("departamento")}
            defaultLabel="Departamentos: (Todos)"
          />
        </div>
        <DataTable
          containerClass={"my-2"}
          config={datatableConfig}
          data={feedbacks_por_jefe}
          loading={feedbacks_por_jefe_loading}
          noHeaders={false}
          //
          totalRecords={feedbacks_por_jefe_count}
          onPaginate={onPaginate}
          outerPageSize={outerPageSize}
          //
          checkable={true}
          onCheckAll={onCheckAll}
          checkedItems={feedbacks_por_jefe_checked}
          onItemCheck={onCheckFeedbackPorJefe}
        />
      </div>
    </FeedbackLayout>
  );
}
