import React from "react";
import CompetenciasLayout from "../../common/components/CompetenciasLayout";
import { CompetenciaCollapsable } from "../../common/components/CompetenciaCollapsable";
import { Award } from "react-bootstrap-icons";
import { useDispatch, useSelector } from "react-redux";
import Input from "src/modules/common/components/Input";
import { commonActions } from "src/modules/common/handler/redux";
import { CargoSelect } from "src/modules/estructura/modules/cargo/components/CargoSelect";
import { useState } from "react";
import CompetenciaModal from "../../common/components/CompetenciaModal";
import { competenciaCargoActions } from "src/modules/competencia/modules/cargo/handlers/redux";
import { PrimaryButton } from "src/modules/common/components/PrimaryButton";
import { CompetenciaPieChart } from "src/modules/competencia/modules/common/components/CompetenciaPieChart.";
import { empresaActions } from "src/modules/ajuste/handlers/redux";
import PlanAvanzadoAlert from "../../common/components/PlanAvanzadoAlert";
import { WarningToast } from "src/modules/common/components/WarningToast";
import { Navigate, useNavigate, useParams } from "react-router-dom";

function CompetenciaCargoFormPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const { idCompetenciaCargo } = params;
  const empresa = useSelector((state) => state.empresa.empresa);
  const [modalOpen, setModalOpen] = useState(false);
  const [competencia, setCompetencia] = useState({});
  const [aplicarPesos, setAplicarPesos] = useState(false);
  const [idx, setIdx] = useState(null);

  const competencia_cargo = useSelector(
    (state) => state.competenciaCargo.competencia_cargo
  );
  const competencia_cargo_create_loading = useSelector(
    (state) => state.competenciaCargo.competencia_cargo_create_loading
  );
  const is_editing_cargo = useSelector(
    (state) => state.competenciaCargo.competencia_cargo_editing
  );

  const { competencias = [], cargos = [] } = competencia_cargo;

  React.useEffect(() => {
    if (idCompetenciaCargo) {
      dispatch(
        competenciaCargoActions.getCompetenciaCargo({ id: idCompetenciaCargo })
      );
    }
    dispatch(empresaActions.empresa());
    dispatch(commonActions.showSidebar(false));
    dispatch(commonActions.setTitle("Admin"));
    dispatch(commonActions.setTitleMenu([]));
    dispatch(
      commonActions.setSecondBar({
        hasSecondBar: true,
        secondBarTitle: "Competencias",
        secondBarBackRoute: "/competencias/cargo",
      })
    );
    //
    const sumaPesos = competencias.reduce((sum, comp) => sum + comp.peso, 0);
    !!process.env.REACT_APP_DEBUG && console.info("sumaPesos", sumaPesos);
    if (sumaPesos > 0) setAplicarPesos(true);
  }, []);

  const tienePlanAvanzado = () => {
    return empresa.tipo_plan === "Avanzado";
  };

  const onEdit = (idx) => (competencia) => {
    setCompetencia({ ...competencia });
    setModalOpen(true);
    setIdx(idx);
  };

  const onDelete = (idx) => (competencia) => {
    let deseaEliminar = window.confirm(
      "¿Desea eliminar la competencia seleccionada?"
    );
    if (!!deseaEliminar) {
      setIdx(idx);

      let competencias_new = competencias.filter((_, cIdx) => idx != cIdx);
      onChange({ ...competencia_cargo, competencias: competencias_new });
    }
  };

  const renderListaCompetencias = () => {
    return (
      <div className="flex flex-col gap-3">
        {/* item */}

        {competencias.map((competencia, idx) => (
          <CompetenciaCollapsable
            key={competencia.id}
            competencia={competencia}
            editable={
              !!tienePlanAvanzado() && !!aplicarPesos && is_editing_cargo
            }
            onEdit={is_editing_cargo ? onEdit(idx) : null}
            onDelete={is_editing_cargo ? onDelete(idx) : null}
            onChange={onChangeCompentencia}
            idx={idx}
          />
        ))}
      </div>
    );
  };

  const onClickAdd = () => {
    setCompetencia({});
    setIdx(null);
    setModalOpen(true);
  };

  const onAplicarPesoCheck = (e) => {
    setAplicarPesos(e.target.checked);
  };

  const onChangeInput =
    (name) =>
    ({ target }) => {
      onChange({
        ...competencia_cargo,
        [name]: target.value,
      });
    };

  const onChange = (competencia_cargo) => {
    dispatch(competenciaCargoActions.competenciaCargoSet(competencia_cargo));
  };

  const onSubmitCompetencia = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (!competencia.nombre) {
      WarningToast({ message: "Debe ingresar un nombre" });
      return;
    }

    let { conductas = [] } = competencia;
    conductas = conductas.filter((c) => !!c.descripcion);

    if (conductas.length < 1) {
      WarningToast({ message: "Debe agregar al menos una conduta" });
      return;
    }
    onChangeCompentencia({ ...competencia, conductas }, idx);
    setModalOpen(false);
  };

  const onChangeCompentencia = (competencia, idx) => {
    let competencias_new = [...competencias];
    if (idx == null) {
      competencias_new.push(competencia);
    } else {
      competencias_new[idx] = competencia;
    }
    onChange({ ...competencia_cargo, competencias: competencias_new });
  };

  const onChangeCargos = ({ target }) => {
    !!process.env.REACT_APP_DEBUG && console.log({ cargos: target.value });
    onChange({ ...competencia_cargo, cargos: target.value });
  };

  const onSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    let hayErrores = false;
    //
    if (!competencia_cargo.nombre) {
      WarningToast({ message: "Debe ingresar un nombre" });
      hayErrores = true;
    }
    let { cargos = [] } = competencia_cargo;
    if (cargos?.length < 1) {
      WarningToast({ message: "Debe ingresar al menos un cargo" });
      hayErrores = true;
    }
    let { competencias = [] } = competencia_cargo;
    if (competencias?.length < 1) {
      WarningToast({ message: "Debes agregar al menos una competencia" });
      hayErrores = true;
    }
    if (hayErrores) {
      return;
    }
    //
    dispatch(competenciaCargoActions.setAplicarPesos(aplicarPesos));
    if (competencia_cargo.id) {
      dispatch(competenciaCargoActions.competenciaCargoUpdate());
      return;
    }
    dispatch(competenciaCargoActions.competenciaCargoCreate());
  };

  const goToEdition = (item) => {
    dispatch(competenciaCargoActions.setCompetenciaCargoEditing(true));
    console.log("ID: " + item.id);
    navigate(`/competencias/cargo/${item.id}/edit`);
  };

  return (
    <CompetenciasLayout>
      <div className="flex flex-col items-center">
        <form
          onSubmit={onSubmit}
          className="flex flex-col w-full md:w-5/6 gap-3"
        >
          <div className=""></div>
          <div className="flex flex-row justify-between">
            <h1 className="text-xl font-bold">
              Configuración de competencia de cargos
            </h1>
            {!is_editing_cargo && (
              <button
                onClick={() => goToEdition(competencia_cargo)}
                className="rounded-md bg-sky-500 text-white px-4 py-1"
              >
                Editar
              </button>
            )}
          </div>
          <div className="flex flex-col gap-2">
            <div className="flex flex-col gap-1">
              <div className="text-sm font-bold after:content-['*'] after:ml-0.5 after:text-red-500">
                Nombre de la agrupación de competencias
              </div>
              <div className="">
                <Input
                  value={competencia_cargo.nombre || ""}
                  onChange={onChangeInput("nombre")}
                  className="w-full"
                  disabled={!is_editing_cargo}
                />
              </div>
            </div>
            <div className="flex flex-col gap-1">
              <div className="text-sm font-bold">Descripción</div>
              <div className="">
                <Input
                  value={competencia_cargo.descripcion || ""}
                  onChange={onChangeInput("descripcion")}
                  className="w-full"
                  disabled={!is_editing_cargo}
                />
              </div>
            </div>
            <div className="flex flex-col gap-1">
              <div className="text-sm font-bold after:content-['*'] after:ml-0.5 after:text-red-500">
                Cargos
              </div>
              <div className="">
                <CargoSelect
                  value={cargos}
                  onChange={onChangeCargos}
                  isMulti={true}
                  noDefaultValue={true}
                  placeholder={"Seleccione los cargos..."}
                  disabled={!is_editing_cargo}
                />
              </div>
            </div>
          </div>
          <div className="text-xl font-bold ">Competencias a evaluar</div>
          <div className="flex flex-col gap-3 pr-3">
            {!!tienePlanAvanzado() && (
              <>
                <div className="flex flex-row gap-2 items-center">
                  <div className="">
                    <input
                      type="checkbox"
                      className="m-0 p-0 h-4 w-4 accent-sky-600"
                      checked={aplicarPesos}
                      onChange={onAplicarPesoCheck}
                      disabled={!is_editing_cargo}
                    />
                  </div>
                  <div className="">Aplicar pesos a las competencias</div>
                </div>
                {/* ==================== GRAFICO ==================== */}
                {!!aplicarPesos && (
                  <div className="flex flex-col items-center gap-2">
                    <div className="">
                      <CompetenciaPieChart competencias={competencias} />
                    </div>
                  </div>
                )}
              </>
            )}
            {!tienePlanAvanzado() && (
              <div className="flex items-center justify-center">
                <PlanAvanzadoAlert></PlanAvanzadoAlert>
              </div>
            )}
            {/* ==================== INDICADORES ==================== */}
            <div className="flex flex-col gap-2">
              {/* title */}
              <div className="flex flex-row justify-between font-bold">
                <div className="after:content-['*'] after:ml-0.5 after:text-red-500">
                  Competencias a evaluar
                </div>
                {!!aplicarPesos && (
                  <div className="w-24 after:content-['*'] after:ml-0.5 after:text-red-500">
                    Peso
                  </div>
                )}
              </div>
              {/* lista */}
              {renderListaCompetencias()}
              <div className="mb-2" />
              {!!is_editing_cargo && (
                <div className="flex flex-row gap-2 items-center pl-3">
                  <div
                    className="w-5 h-5 rounded-full bg-sky-500 text-white flex items-center justify-center text-2xl cursor-pointer"
                    onClick={onClickAdd}
                  >
                    +
                  </div>
                  <div
                    className="text-sky-500 text-sm cursor-pointer"
                    onClick={onClickAdd}
                  >
                    Agregar otra competencia a evaluar
                  </div>
                </div>
              )}
              <CompetenciaModal
                open={modalOpen}
                setOpen={setModalOpen}
                competencia={competencia}
                onChange={setCompetencia}
                onSubmit={onSubmitCompetencia}
                idx={idx}
              />
            </div>
          </div>
          {!!is_editing_cargo && (
            <PrimaryButton
              className="mb-4 bg-sky-500"
              disabled={competencia_cargo_create_loading}
            >
              Guardar configuración de Competencias
            </PrimaryButton>
          )}
        </form>
      </div>
    </CompetenciasLayout>
  );
}

export default CompetenciaCargoFormPage;
