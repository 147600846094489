import { createSlice } from "@reduxjs/toolkit";

const sucursaleslice = createSlice({
  name: "Sucursal",
  initialState: {
    sucursal_create_loading: false,
    sucursal_update_loading: false,
    sucursal_delete_loading: false,
    sucursal: {},
    sucursales: [],
    sucursales_checked: [],
    sucursales_filter: {},
    sucursales_delete: [],
    sucursales_loading: false,
    sucursal_create_modal: { open: false },
    //
    fileToUpload: null,
    datosVerificados: {},
    nuevosChecked: [],
    duplicadosChecked: [],
    datosImportar: [],
    importar_loading: false,
  },
  reducers: {
    setFileToUpload: (state, { payload = {} }) => {
      !!process.env.REACT_APP_DEBUG && console.info("setFileToUpload redux");
      state.fileToUpload = payload;
    },
    setDatosVerificados: (state, { payload = {} }) => {
      !!process.env.REACT_APP_DEBUG &&
        console.info("setDatosVerificados redux");
      state.datosVerificados = payload;
    },
    setDatosImportar: (state, { payload = {} }) => {
      !!process.env.REACT_APP_DEBUG && console.info("setDatosImportar redux");
      state.datosImportar = payload;
    },
    checkNuevo: (state, { payload: { item, checked = true } }) => {
      !!process.env.REACT_APP_DEBUG &&
        console.info("checkNuevo redux", item, checked);
      let temp = state.nuevosChecked.filter((nc) => nc.id !== item.id);
      !!process.env.REACT_APP_DEBUG && console.info("temp", temp);
      state.nuevosChecked = temp;
      if (!!checked) {
        state.nuevosChecked.push(item);
      }
      // state.kpiColaboradores_loading = false;
    },
    resetCheckNuevos: (state) => {
      state.nuevosChecked = [];
    },
    checkDuplicado: (state, { payload: { item, checked = true } }) => {
      !!process.env.REACT_APP_DEBUG &&
        console.info("checkDuplicado redux", item, checked);
      let temp = state.duplicadosChecked.filter((dc) => dc.id !== item.id);
      state.duplicadosChecked = temp;
      if (!!checked) {
        state.duplicadosChecked.push(item);
      }
      // state.kpiColaboradores_loading = false;
    },
    resetCheckDuplicados: (state) => {
      state.duplicadosChecked = [];
    },
    importarDatosComplete: (state, { payload = {} }) => {
      state.nuevosChecked = [];
      state.duplicadosChecked = [];
      state.datosVerificados = [];
      state.datosImportar = [];
      state.importar_loading = false;
    },
    //
    sucursaleset: (state, { payload = {} }) => {
      state.sucursal = payload;
    },
    setSucursales: (state, { payload }) => {
      state.sucursales = payload.sucursales;
      state.sucursales_loading = false;
    },
    setSucursalesFilter: (state, { payload = {} }) => {
      state.sucursales_filter = payload;
    },
    setSucursalesDelete: (state, { payload }) => {
      state.sucursales_delete = payload;
    },
    resetSucursalesDelete: (state, { payload }) => {
      state.sucursales_delete = [];
    },
    //
    resetCheckSucursales: (state) => {
      state.sucursales_checked = [];
      state.sucursales_loading = false;
    },
    checkSucursal: (state, { payload: { sucursal, checked = true } }) => {
      state.sucursales_checked = state.sucursales_checked.filter(
        (id) => id != sucursal.id
      );
      if (!!checked) {
        state.sucursales_checked.push(sucursal.id);
      }
      state.sucursales_loading = false;
    },
    // create
    setSucursalCreateModal: (state, { payload = {} }) => {
      !!process.env.REACT_APP_DEBUG && console.log({ payload });
      state.sucursal_create_modal = payload;
    },
    sucursalCreate: (state, { payload = {} }) => {
      state.sucursal_create_loading = true;
    },
    sucursalCreateSuccess: (state) => {
      state.sucursal_create_loading = false;
    },
    // update
    sucursalUpdate: (state, { payload = {} }) => {
      state.sucursal_update_loading = true;
    },
    sucursalUpdateSuccess: (state) => {
      state.sucursal_update_loading = false;
    },
    // delete
    sucursalDelete: (state, { payload = {} }) => {
      state.sucursal_delete_loading = true;
    },
    sucursalDeleteSuccess: (state) => {
      state.sucursal_delete_loading = false;
    },
    // list
    sucursales: (state, { payload }) => {
      state.sucursales_loading = true;
    },
  },
});

export const sucursalActions = sucursaleslice.actions;
export const sucursalReducer = sucursaleslice.reducer;
