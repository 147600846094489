import { useEffect, useState } from "react";
import { ChatLeftTextFill } from "react-bootstrap-icons";

function IndicadorFormularioItem({
  title = "Lorem, ipsum dolor.",
  value,
  disabled = false,
  previousValue,
  showPrevious = false,
  name = "radio-group",
  comment = "",
  onRadioCheck,
  onChangeComentario,
  error = "",
  puntuacionesCustom = [],
  mostrarPesosIndicadores = false,
}) {
  const [hasComment, setHasComment] = useState(false);
  const puntuaciones = [
    { label: "Siempre", value: 100 },
    { label: "Constantemente", value: 75 },
    { label: "Frecuentemente", value: 50 },
    { label: "En Ocasiones", value: 25 },
    { label: "Nunca", value: 0 },
    { label: "No aplica", value: -1 },
  ];
  const [puntuacionesLocal, setPuntuacionesLocal] = useState(
    puntuacionesCustom.length > 0 ? puntuacionesCustom : puntuaciones
  );
  const lastIndex = puntuacionesLocal.length - 1;

  const onCheck = (puntuacion) => (e) => {
    const puntaje = puntuacion.value;
    onRadioCheck(puntaje);
  };
  const onNoAplicaClick = (e) => {
    const checked = e.target.checked;
    if (checked) {
      onRadioCheck(-1);
    } else {
      onRadioCheck(null);
    }
  };
  const RadioItem = ({
    name = "",
    label = "radio label",
    checked = false,
    onChange,
    disabled = false,
    peso = 0,
    mostrarPeso = false,
  }) => {
    return (
      <div className="flex gap-2 items-center">
        <input
          type="radio"
          className="h-5 w-5"
          name={name}
          disabled={disabled}
          checked={checked}
          onChange={!disabled ? onChange : null}
        />

        <div>{label}</div>
        {!!mostrarPeso && (
          <div className="bg-slate-300 py-1 px-2 rounded-md">{peso}%</div>
        )}
      </div>
    );
  };
  const toggleHasComment = () => {
    const newValue = !hasComment;
    setHasComment(newValue);
    if (!newValue) {
      onChangeComentario("");
    }
  };
  const onChangeComentarioLocal = (e) => {
    const comentario = e.target?.value;
    onChangeComentario(comentario);
  };
  //
  useEffect(() => {
    if (comment) {
      setHasComment(true);
    }
  }, [comment]);
  //
  return (
    <div className="flex flex-col gap-4 mb-4">
      <div className="font-bold text-sm">{title}</div>
      {/* previous radios */}
      {!!showPrevious && (
        <div className="rounded-md bg-slate-100 py-1 px-4">
          <div className="flex justify-between">
            <div className="flex gap-4">
              {puntuacionesLocal.map((puntuacion, idx) =>
                idx !== lastIndex ? (
                  <RadioItem
                    name={name + "-previos"}
                    label={puntuacion.label}
                    checked={puntuacion.value === previousValue}
                    disabled={true}
                    peso={puntuacion.value}
                    mostrarPeso={mostrarPesosIndicadores}
                  ></RadioItem>
                ) : (
                  ""
                )
              )}
            </div>
            <div className="flex gap-2">
              <input
                checked={puntuacionesLocal[lastIndex].value === previousValue}
                disabled={true}
                type="checkbox"
                className="h-5 w-5"
                name=""
                id=""
              />
              <div>{puntuacionesLocal[lastIndex].label}</div>
            </div>
          </div>
        </div>
      )}
      {/* radios */}
      <div
        className={`${
          !!showPrevious
            ? "border-2 border-dotted border-[#FED074] bg-[#FEF8EA] rounded-md py-1 px-4"
            : ""
        }`}
      >
        <div className="flex justify-between">
          <div className="flex gap-4">
            {puntuacionesLocal.map((puntuacion, idx) =>
              idx !== lastIndex ? (
                <RadioItem
                  name={name}
                  label={puntuacion.label}
                  checked={puntuacion.value === value}
                  onChange={!disabled ? onCheck(puntuacion) : null}
                  disabled={disabled}
                  peso={puntuacion.value}
                  mostrarPeso={mostrarPesosIndicadores}
                ></RadioItem>
              ) : (
                ""
              )
            )}
          </div>
          <div className="flex gap-2">
            <input
              onClick={!disabled ? onNoAplicaClick : null}
              checked={puntuacionesLocal[lastIndex].value === value}
              disabled={disabled}
              type="checkbox"
              className="h-5 w-5"
              name=""
              id=""
            />
            <div>{puntuacionesLocal[lastIndex].label}</div>
          </div>
        </div>
      </div>
      {!!error && (
        <div className="border border-[#E3C8C6] text-[#9C4B46] bg-[#EEDEDE]-500 p-2 rounded-md md:w-1/2">
          {error}
        </div>
      )}
      {/* comentario */}
      <div>
        {!hasComment && (
          <button
            onClick={toggleHasComment}
            className="flex items-center gap-2"
          >
            <ChatLeftTextFill></ChatLeftTextFill>
            <div className="underline">Dejar comentario</div>
          </button>
        )}
        {!!hasComment && (
          <div className="flex flex-col gap-2">
            <h3 className="font-bold text-slate-500">Comentarios</h3>
            <div className="flex items-center gap-4">
              <textarea
                className="border rounded-md p-2"
                cols="30"
                rows="2"
                value={comment}
                disabled={disabled}
                onChange={!disabled ? onChangeComentarioLocal : null}
              ></textarea>
              <button onClick={toggleHasComment} className="underline">
                No dejar comentario
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
export default IndicadorFormularioItem;
