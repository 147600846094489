// @ts-nocheck
import { React, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { kpiActions } from "../handlers/redux";

export const KpiCard = ({ kpi, loading = false }) => {
  const dispatch = useDispatch();

  const metricas = useSelector((state) => state.kpi.metricas);
  const periodos = useSelector((state) => state.kpi.periodos);

  useEffect(() => {
    dispatch(kpiActions.metricas());
    dispatch(kpiActions.periodos());
  }, []);

  !!process.env.REACT_APP_DEBUG && console.info("kpi card", kpi);
  const commonStyle = " p-2";
  const borderOdd = (idx) =>
    idx % 2 ? `${commonStyle} bg-sky-100` : commonStyle;
  const getPeriodoDisplay = () => {
    let periodoDisplay = "-";
    let periodosFilter = periodos.filter(
      (periodo, idx) => periodo.value === kpi.periodo
    );
    if (periodosFilter.length) periodoDisplay = periodosFilter[0].label;
    return periodoDisplay;
  };

  const getMetricaDisplay = () => {
    let metricaDisplay = "-";
    let metricasFilter = metricas.filter(
      (metrica, idx) => metrica.value === kpi.metrica
    );
    if (metricasFilter.length) metricaDisplay = metricasFilter[0].label;
    return metricaDisplay;
  };

  return (
    <div className="border border-zinc-200  rounded">
      {!loading && (
        <>
          {/* collapse header */}
          <div className="flex flex-row items-center  bg-opacity-50 p-3 gap-3">
            <div className="flex-grow bg-zinc-200 p-4 rounded-md">
              <div className="text-lg font-semibold">{kpi.nombre}</div>
              <div className="text-sm">{kpi.descripcion}</div>
            </div>
          </div>
          {/* body */}
          <div className="flex flex-col gap-4 px-6 py-4">
            <div className="flex flex-col gap-3">
              {/*  */}
              <div className="grid grid-cols-1 md:grid-cols-3">
                <div className="col-span-1 mr-6 my-2">
                  <label className="font-bold">Tiempo:</label>
                  <div>{getPeriodoDisplay()}</div>
                </div>
                <div className="col-span-1 mr-6 my-2">
                  <label className="font-bold">Tipo de métrica:</label>
                  <div>{getMetricaDisplay()}</div>
                </div>
                <div className="col-span-1 mr-6 my-2">
                  <label className="font-bold">Meta:</label>
                  {kpi.rangos.length > 0 && (
                    <div>{kpi.meta || "Por Escala"}</div>
                  )}
                  {kpi.rangos.length < 1 && (
                    <div>{kpi.meta || "Sin asignar/No aplica"}</div>
                  )}
                </div>
              </div>
              {/* ESCALAS  */}
              {kpi.rangos?.length > 0 && (
                <>
                  <h3 className="font-bold">Escala de evaluación</h3>
                  <div className="grid grid-cols-1 md:grid-cols-3">
                    <div className="col-span-1">
                      <label className="font-bold">Desde</label>
                    </div>
                    <div className="col-span-1">
                      <label className="font-bold">Hasta</label>
                    </div>
                    <div className="col-span-1">
                      <label className="font-bold">Resultado</label>
                    </div>
                  </div>
                  <div className="grid grid-cols-1 md:grid-cols-3">
                    {kpi.rangos.map((rango, idx) => (
                      <>
                        <div
                          key={`desde-${idx}`}
                          className={"col-span-1" + borderOdd(idx)}
                        >
                          <span>{rango.desde}</span>
                        </div>
                        <div
                          key={`hasta-${idx}`}
                          className={"col-span-1" + borderOdd(idx)}
                        >
                          <span>{rango.hasta}</span>
                        </div>
                        <div
                          key={`resultado-${idx}`}
                          className={"col-span-1" + borderOdd(idx)}
                        >
                          <span>{rango.resultado}</span>
                        </div>
                      </>
                    ))}
                  </div>
                </>
              )}
            </div>
          </div>
        </>
      )}
      {!!loading && (
        <div className="flex flex-col gap-4 w-full p-4 animate-pulse">
          <div className="h-20 w-full bg-slate-200 border-slate-100 rounded-md"></div>
          <div className="h-80 w-full bg-slate-200 border-slate-100 rounded-md"></div>
        </div>
      )}
    </div>
  );
};
