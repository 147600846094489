import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { empresaActions } from "src/modules/ajuste/handlers/redux";
import { commonActions } from "src/modules/common/handler/redux";
import { RootRoutes } from "src/modules/estructura/root/rootRoutes";
import { estructuraActions } from "../handlers/redux";
import ribbon from "../images/blue-ribbon.png";

const Circle = ({ title = "", count = 0, active = false }) => {
  let border = "border-zinc-400";
  let background = "bg-zinc-100";
  let textColor = "text-zinc-400";

  if (active) {
    border = "border-sky-500";
    background = "";
    textColor = "";
  }

  return (
    <div
      className={`flex flex-col items-center justify-center rounded-full w-36 h-36 border-4 ${border}  ${background}`}
    >
      <div className={`${textColor} font-bold`}>{title}</div>
      <div className={`${textColor}`}>{count}</div>
    </div>
  );
};

function AppPage() {
  const dispatch = useDispatch();
  const totals = useSelector((state) => state.estructura.totals);
  const empresa = useSelector((state) => state.empresa.empresa);

  useEffect(() => {
    dispatch(empresaActions.empresa());
    dispatch(commonActions.setTitle("Estructura Organizacional"));
    dispatch(commonActions.showSidebar(true));
    dispatch(estructuraActions.totals());
    dispatch(
      commonActions.setSecondBar({
        hasSecondBar: false,
        secondBarTitle: "",
        secondBarBackRoute: null,
      })
    );
  }, []);

  const tienePlanAvanzado = () => {
    return empresa.tipo_plan === "Avanzado";
  };

  const navLinkClickHandler = (e) => {
    if (!tienePlanAvanzado()) e.preventDefault();
  };

  const activeClass =
    "text-xs no-underline text-black bg-gray-100 px-2 py-1 rounded-md border border-sky-500 hover:text-black";
  const inActiveClass =
    "text-xs no-underline text-gray-400 bg-gray-100 px-2 py-1 rounded-md border border-gray-100 hover:text-black";
  const disabledClass =
    "text-xs no-underline text-gray-400 bg-gray-100 px-2 py-1 rounded-md border border-gray-100 hover:cursor-not-allowed";

  return (
    <div className="flex flex-col gap-4 pt-4">
      <div className="flex items-center justify-center">
        <div className="flex flex-row items-center gap-2 flex-wrap justify-center">
          <div
            className={`flex flex-row gap-2 p-2 px-4 relative ${
              !tienePlanAvanzado() ? "border-2 border-dashed rounded-lg" : ""
            }`}
          >
            <div className="absolute top-2 left-2 group w-3/5">
              {!tienePlanAvanzado() && (
                <img className="ribbon" src={ribbon} alt="ribbon" />
              )}
              <div className="hidden absolute top-2 left-2 group-hover:flex flex-col rounded-lg border-2 border-sky-500 bg-white p-2">
                <span>
                  Estructura organizacional pertenece al PLAN AVANZADO.
                </span>
              </div>
            </div>
            <Circle
              title={"Sucursales"}
              count={totals.sucursales}
              active={tienePlanAvanzado()}
            />
            <Circle
              title={"Áreas"}
              count={totals.areas}
              active={tienePlanAvanzado()}
            />
            <Circle
              title={"Departamentos"}
              count={totals.departamentos}
              active={tienePlanAvanzado()}
            />
          </div>
          <Circle title={"Cargos"} count={totals.cargos} active={true} />
        </div>
      </div>
      <div className="flex flex-row gap-2">
        <NavLink
          onClick={navLinkClickHandler}
          to="/estructura/sucursales"
          className={({ isActive }) =>
            tienePlanAvanzado()
              ? isActive
                ? activeClass
                : inActiveClass
              : disabledClass
          }
        >
          SUCURSALES
        </NavLink>
        <NavLink
          onClick={navLinkClickHandler}
          to="/estructura/areas"
          className={({ isActive }) =>
            tienePlanAvanzado()
              ? isActive
                ? activeClass
                : inActiveClass
              : disabledClass
          }
        >
          ÁREAS
        </NavLink>
        <NavLink
          onClick={navLinkClickHandler}
          to="/estructura/departamentos"
          className={({ isActive }) =>
            tienePlanAvanzado()
              ? isActive
                ? activeClass
                : inActiveClass
              : disabledClass
          }
        >
          DEPARTAMENTOS
        </NavLink>
        <NavLink
          to="/estructura/cargos"
          className={({ isActive }) => (isActive ? activeClass : inActiveClass)}
        >
          CARGOS
        </NavLink>
      </div>
      <div className="border-b border-b-gray-200"></div>
      <RootRoutes></RootRoutes>
    </div>
  );
}

export default AppPage;
