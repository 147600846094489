import { competenciaAreaReducer } from "src/modules/competencia/modules/area/handlers/redux";
import { competenciaCargoReducer } from "src/modules/competencia/modules/cargo/handlers/redux";
import { competenciaGeneralReducer } from "src/modules/competencia/modules/general/handlers/redux";

export const competenciaRootReducer = {
  competenciaGeneral: competenciaGeneralReducer,
  competenciaArea: competenciaAreaReducer,
  competenciaCargo: competenciaCargoReducer,
  // debug: (a, b) => { !!process.env.REACT_APP_DEBUG && console.log(a, b); return {} }
};
