import React from "react";

export const BuscarInput = (props = {}) => {
  return (
    <input
      type="search"
      placeholder="Buscar por nombre "
      className="px-2 py-2 h-10 bg-gray-100 placeholder-gray-400 flex-grow rounded-md"
      {...props}
    />
  );
};
