import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import Circulo from "src/modules/common/components/Circulo";
import { ErrorToast } from "src/modules/common/components/ErrorToast";
import backButton from "src/modules/common/components/images/back-arrow.png";
import { PrimaryButton } from "src/modules/common/components/PrimaryButton";
import TableCustom from "src/modules/common/components/TableCustom";
import TableRow from "src/modules/common/components/TableRow";
import { commonActions } from "src/modules/common/handler/redux";
import { areaActions } from "../../area/handlers/redux";
import { cargoActions } from "../../cargo/handlers/redux";
import { departamentoActions } from "../../departamento/handlers/redux";
import { sucursalActions } from "../../sucursal/handlers/redux";

function ImportacionPage() {
  const dispatch = useDispatch();
  const params = useParams();
  const { subject } = params;
  const [data, setData] = useState({});
  //
  const [nuevos, setNuevos] = useState([]);
  const [duplicados, setDuplicados] = useState([]);
  const [searchNuevos, setSearchNuevos] = useState("");
  const [searchDuplicados, setSearchDuplicados] = useState("");
  //
  const [nuevosChecked, setNuevosChecked] = useState([]);
  const [duplicadosChecked, setDuplicadosChecked] = useState([]);

  useEffect(() => {
    dispatch(commonActions.setTitle(`Importador ${subject}`));
    dispatch(commonActions.showSidebar(false));
    dispatch(
      commonActions.setSecondBar({
        hasSecondBar: true,
        secondBarTitle: "Datos recolectados",
        secondBarBackRoute: "/estructura",
      })
    );
    setData(dataState);
    setNuevosChecked(nuevosCheckedState);
    setDuplicadosChecked(duplicadosCheckedState);
  }, []);

  useEffect(() => {
    setNuevos(data.nuevos);
  }, [data.nuevos?.length]);

  useEffect(() => {
    setDuplicados(data.duplicados);
  }, [data.duplicados?.length]);

  const getDataState = () => {
    let dataState = null;
    switch (subject) {
      case "sucursal":
        dataState = (state) => state.sucursal.datosVerificados;
        break;
      case "area":
        dataState = (state) => state.area.datosVerificados;
        break;
      case "cargo":
        dataState = (state) => state.cargo.datosVerificados;
        break;
      case "departamento":
        dataState = (state) => state.departamento.datosVerificados;
        break;
      default:
        dataState = [];
    }
    return dataState;
  };
  const getNuevosChecked = () => {
    let nuevosChecked = null;
    switch (subject) {
      case "sucursal":
        nuevosChecked = (state) => state.sucursal.nuevosChecked;
        break;
      case "area":
        nuevosChecked = (state) => state.area.nuevosChecked;
        break;
      case "cargo":
        nuevosChecked = (state) => state.cargo.nuevosChecked;
        break;
      case "departamento":
        nuevosChecked = (state) => state.departamento.nuevosChecked;
        break;
      default:
        nuevosChecked = [];
    }
    return nuevosChecked;
  };

  const getDuplicadosChecked = () => {
    let duplicadosChecked = null;
    switch (subject) {
      case "sucursal":
        duplicadosChecked = (state) => state.sucursal.duplicadosChecked;
        break;
      case "area":
        duplicadosChecked = (state) => state.area.duplicadosChecked;
        break;
      case "cargo":
        duplicadosChecked = (state) => state.cargo.duplicadosChecked;
        break;
      case "departamento":
        duplicadosChecked = (state) => state.departamento.duplicadosChecked;
        break;
      default:
        duplicadosChecked = [];
    }
    return duplicadosChecked;
  };
  // @ts-ignore
  const dataState = useSelector(getDataState()) || [];
  // @ts-ignore
  const nuevosCheckedState = useSelector(getNuevosChecked());
  // @ts-ignore
  const duplicadosCheckedState = useSelector(getDuplicadosChecked());

  useEffect(() => {
    setNuevosChecked(nuevosCheckedState);
  }, [nuevosCheckedState]);

  useEffect(() => {
    setDuplicadosChecked(duplicadosCheckedState);
  }, [duplicadosCheckedState]);

  function getDifference(array1, array2) {
    return array1.filter((object1) => {
      return !array2.some((object2) => {
        return object1.nombre === object2.nombre;
      });
    });
  }

  const ColaboradorItem = ({ item }) => {
    return (
      <>
        <TableRow>
          <div
            className="p-4 flex flex-col items-start justify-center cursor-pointer"
            onClick={() => {}}
          >
            <div className="font-bold">{item.nombre}</div>
            <div>{item.descripcion || "Sin descripción"}</div>
          </div>
        </TableRow>
      </>
    );
  };

  const DuplicadoItem = ({ item }) => {
    return (
      <>
        <TableRow>
          <div
            className="p-4 flex gap-8 items-start justify-betweenx cursor-pointer"
            onClick={() => {}}
          >
            <div className="text-slate-400 flex-1">
              <div className="font-bold">{item.anterior.nombre}</div>
              <div>{item.anterior.descripcion || "Sin descripción"}</div>
            </div>
            <div className="flex-1">
              <div className="font-bold">{item.nuevo.nombre}</div>
              <div>{item.nuevo.descripcion || "Sin descripción"}</div>
            </div>
          </div>
        </TableRow>
      </>
    );
  };

  const onCheckHandlerNuevos = (event, item) => {
    let dispatchAction = null;
    switch (subject) {
      case "sucursal":
        dispatchAction = sucursalActions.checkNuevo({
          item: item,
          checked: event.target.checked,
        });
        break;
      case "area":
        dispatchAction = areaActions.checkNuevo({
          item: item,
          checked: event.target.checked,
        });
        break;
      case "cargo":
        dispatchAction = cargoActions.checkNuevo({
          item: item,
          checked: event.target.checked,
        });
        break;
      case "departamento":
        dispatchAction = departamentoActions.checkNuevo({
          item: item,
          checked: event.target.checked,
        });
        break;
      default:
        dispatchAction = null;
    }
    dispatch(dispatchAction);
  };

  const onResetCheckHandlerNuevos = (event, item) => {
    let dispatchAction = null;
    switch (subject) {
      case "sucursal":
        dispatchAction = sucursalActions.resetCheckNuevos();
        break;
      case "area":
        dispatchAction = areaActions.resetCheckNuevos();
        break;
      case "cargo":
        dispatchAction = cargoActions.resetCheckNuevos();
        break;
      case "departamento":
        dispatchAction = departamentoActions.resetCheckNuevos();
        break;
      default:
        dispatchAction = null;
    }
    dispatch(dispatchAction);
  };

  const onCheckHandlerDuplicados = (event, item) => {
    const newItem = item; //{ id: item.anterior.id, ...item.nuevo };
    !!process.env.REACT_APP_DEBUG &&
      console.info("newItem", newItem, event.target.checked);
    let dispatchAction = null;
    switch (subject) {
      case "sucursal":
        dispatchAction = sucursalActions.checkDuplicado({
          item: newItem,
          checked: event.target.checked,
        });
        break;
      case "area":
        dispatchAction = areaActions.checkDuplicado({
          item: newItem,
          checked: event.target.checked,
        });
        break;
      case "cargo":
        dispatchAction = cargoActions.checkDuplicado({
          item: newItem,
          checked: event.target.checked,
        });
        break;
      case "departamento":
        dispatchAction = departamentoActions.checkDuplicado({
          item: newItem,
          checked: event.target.checked,
        });
        break;
      default:
        dispatchAction = null;
    }
    dispatch(dispatchAction);
  };

  const onResetCheckHandlerDuplicados = (event, item) => {
    let dispatchAction = null;
    switch (subject) {
      case "sucursal":
        dispatchAction = sucursalActions.resetCheckDuplicados();
        break;
      case "area":
        dispatchAction = areaActions.resetCheckDuplicados();
        break;
      case "cargo":
        dispatchAction = cargoActions.resetCheckDuplicados();
        break;
      case "departamento":
        dispatchAction = departamentoActions.resetCheckDuplicados();
        break;
      default:
        dispatchAction = null;
    }
    dispatch(dispatchAction);
  };

  const onSelectChangeNuevos = (v, items = []) => {
    if (v.value === "eliminar") {
      let confirmar = window.confirm("Desea eliminar los datos seleccionados?");
      if (confirmar) {
        //data.nuevos
        const newNuevos = getDifference(nuevos, nuevosChecked);
        // return;
        setData({
          ...data,
          nuevos: newNuevos,
        });
        onResetCheckHandlerNuevos();
      }
    } else if (v.value === "cancel") {
      onResetCheckHandlerNuevos();
      //
    } else {
      // @ts-ignore
      window.alert(JSON.stringify(v));
    }
  };

  const onSelectChangeDuplicados = (v, items = []) => {
    if (v.value === "eliminar") {
      //
      console.log("eliminar");
      let confirmar = window.confirm("Desea eliminar los datos seleccionados?");
      if (confirmar) {
        // data.duplicados
        const newDuplicados = getDifference(duplicados, duplicadosChecked);
        console.log("newDuplicados", newDuplicados);
        // return;
        setData({
          ...data,
          duplicados: newDuplicados,
        });
        onResetCheckHandlerDuplicados();
      }
    } else if (v.value === "cancel") {
      onResetCheckHandlerDuplicados();
      //
    } else {
      // @ts-ignore
      window.alert(JSON.stringify(v));
    }
  };

  const onSearchNuevos = (e) => {
    let searchText = e.target.value;
    setSearchNuevos(searchText);
    searchText = searchText.toUpperCase();
    const nuevosCopy = data.nuevos?.filter((nuevo, nidx) =>
      nuevo.nombre.toUpperCase().includes(searchText)
    );
    setNuevos(nuevosCopy);
  };
  const onSearchDuplicados = (e) => {
    let searchText = e.target.value;
    setSearchDuplicados(searchText);
    searchText = searchText.toUpperCase();
    const duplicadosCopy = data.duplicados?.filter((duplicado, nidx) =>
      duplicado.anterior.nombre.toUpperCase().includes(searchText)
    );
    setDuplicados(duplicadosCopy);
  };

  const importarDatos = () => {
    !!process.env.REACT_APP_DEBUG &&
      console.info("importarDatos", nuevosChecked, duplicadosChecked);
    let nuevos = nuevosChecked.map((nc, nci) => {
      let { id, ...props } = nc;
      return props;
    });
    //
    let duplicados = duplicadosChecked.map((dc, dci) => {
      let { id, ...props } = dc;
      return props;
    });
    //
    duplicados = duplicados.map((item, idx) => ({
      id: item.anterior.id,
      ...item.nuevo,
    }));
    console.log("nuevos, duplicados", nuevos, duplicados);
    const datosImportar = [...nuevos, ...duplicados];
    if (datosImportar.length < 1) {
      ErrorToast({ message: "No se eligieron datos para importar" });
      return;
    }
    //
    let dispatchAction = null;
    switch (subject) {
      case "sucursal":
        dispatchAction = sucursalActions.setDatosImportar(datosImportar);
        break;
      case "area":
        dispatchAction = areaActions.setDatosImportar(datosImportar);
        break;
      case "cargo":
        dispatchAction = cargoActions.setDatosImportar(datosImportar);
        break;
      case "departamento":
        dispatchAction = departamentoActions.setDatosImportar(datosImportar);
        break;
      default:
        dispatchAction = null;
    }
    dispatch(dispatchAction);
  };

  return (
    <>
      <div className="flex justify-center gap-8 mt-8 w-full">
        <Circulo title={"Total"} count={data.totales?.total} active={true} />
        <Circulo title={"Nuevos"} count={data.totales?.nuevos} active={true} />
        <Circulo
          title={"Duplicados"}
          count={data.totales?.duplicados}
          active={true}
        />
      </div>
      {/*  */}
      <div className="body flex flex-col gap-8 items-center justify-center mt-8">
        {/* NUEVOS data.nuevos*/}
        {data.nuevos?.length > 0 && (
          <>
            <div className="flex justify-start w-full md:w-2/3">
              <div className="text-lg font-bold">
                Nuevos registros a importar
              </div>
            </div>
            <div className="flex justify-center w-full md:w-2/3">
              {/* data.nuevos */}
              <TableCustom
                ItemComponent={ColaboradorItem}
                data={nuevos?.map((item, idx) => {
                  return { id: idx, ...item };
                })}
                tableSecondTitle={"Lista de registros nuevos"}
                subject={"colaborador"}
                subjectGender={"M"}
                hasAddBtn={false}
                noBottomButtom={true}
                onChangeSearch={onSearchNuevos}
                searchValue={searchNuevos}
                onSelectChange={onSelectChangeNuevos}
                selectOptions={[
                  { value: "eliminar", label: "Eliminar" },
                  { value: "cancel", label: "Cancelar" },
                ]}
                itemsChecked={nuevosChecked.map((item) => item.id)}
                onCheckHandler={onCheckHandlerNuevos}
              />
            </div>
          </>
        )}
        {/* DUPLICADOS */}
        {data.duplicados?.length > 0 && (
          <>
            <div className="flex flex-col justify-start w-full md:w-2/3">
              <div className="text-lg font-bold">Duplicados</div>
              <p className="text-sm">
                Ya existen registros en la base de datos con los mismos datos
                que intenta importar. ¿Quieres reemplazarlos con los que estás
                importando?
              </p>
            </div>
            <div className="flex justify-center w-full md:w-2/3">
              {/* data.duplicados */}
              <TableCustom
                ItemComponent={DuplicadoItem}
                data={duplicados?.map((item, idx) => {
                  return { id: item.anterior.id, ...item };
                })}
                tableSecondTitle={"Lista de registros duplicados"}
                subject={"colaborador"}
                subjectGender={"M"}
                hasAddBtn={false}
                noBottomButtom={true}
                onChangeSearch={onSearchDuplicados}
                searchValue={searchDuplicados}
                onSelectChange={onSelectChangeDuplicados}
                selectOptions={[
                  { value: "eliminar", label: "Eliminar" },
                  { value: "cancel", label: "Cancelar" },
                ]}
                itemsChecked={duplicadosChecked.map((item) => item.id)}
                onCheckHandler={onCheckHandlerDuplicados}
              />
            </div>
          </>
        )}
        {/*  */}
        <PrimaryButton className="w-full md:w-2/3 mb-8 bg-sky-500" onClick={importarDatos}>
          Importar datos
        </PrimaryButton>
      </div>
    </>
  );
}

export default ImportacionPage;
