import React from "react";
import { useEffect, useState } from "react";
import { DistributeVertical } from "react-bootstrap-icons";
import LoadingSpinButton from "src/modules/common/components/LoadingSpinButton";
import { ProfileInfoCard } from "src/modules/common/components/ProfileInfoCard";
import { formatNumber } from "src/utils/helpers";

function ComparativoColaborador({
  colaboradores = [],
  rangos = [],
  salto = 1000000,
  salarioField = "salario",
  showMercados = true,
  loading,
  totalRecords,
  onPaginate,
  outerPageSize,
}) {
  const getPageButtonsArray = (pagesCount) => {
    let pagesArr = [1];
    //const pagesCount = totalPaginate / PageSize;
    for (let index = 2; index <= pagesCount + 1; index++) {
      pagesArr.push(index);
    }
    return pagesArr;
  };
  // Pagination
  const [Page, setPage] = useState(1);
  // const [PageSize, setPageSize] = useState(outerPageSize);
  const [PagesButtonsArray, setPagesButtonsArray] = useState(
    getPageButtonsArray(totalRecords / outerPageSize)
  );
  //
  const getBlocksBetweenRanges = () => {
    let blocks = 1;
    if (rangos.length > 1) {
      const firstElement = rangos[0];
      const secondElement = rangos[1];
      return (secondElement - firstElement) / salto;
    }
    return blocks;
  };
  const formatRangos = () => {
    let newRangos = [];
    for (let i = 0; i <= rangos.length; i += 1) {
      let e = rangos[i];
      if (e >= 1000000) {
        // Cambiar los últimos 6 ceros por una "M" mayúscula
        const numeroConM = (e / 1000000).toFixed(0) + "M";
        newRangos.push(numeroConM);
      } else {
        newRangos.push(e);
      }
    }
    return newRangos;
  };
  const renderBlocks = (
    amount = 0,
    paintStart = -1,
    paintEnd = -1,
    color = "sky",
    blockHeight = 5,
    dotPosition,
    dotTooltipText = "insert tooltip here",
    rango = [0, 0]
  ) => {
    const divs = [];
    for (let i = 0; i < amount; i++) {
      divs.push(
        <div
          className={`borderx flex items-center text-white bg-opacity-50 ${
            !!blockHeight ? `h-${blockHeight}` : ""
          } flex-1 ${
            i >= paintStart && i <= paintEnd
              ? `bg-${color}-500 text-${color}-500`
              : ""
          } ${
            i === paintStart
              ? "rounded-l-md"
              : i === paintEnd
              ? "rounded-r-md"
              : ""
          }`}
        >
          {i >= paintStart && i <= paintEnd && i !== dotPosition && (
            <div className="relative group">
              <div className="w-44 border border-blue-500 rounded-md bg-sky-100 p-1 flex items-center justify-center hidden group-hover:block absolute bottom-0 left-0">
                <div className="text-center text-black text-sm">{` ${formatNumber(
                  rango[0]
                )} - ${formatNumber(rango[1])}`}</div>
              </div>
              <div
                className={`rounded-full bg-${color}-500 bg-opacity-0 h-3 w-3 z-10 `}
              ></div>
            </div>
          )}
          {/* <span>.</span> */}
          {!!dotPosition && i === dotPosition && (
            // <div className="rounded-full bg-blue-500 h-3 w-3 z-10"></div>
            <div className="relative group">
              <div className="border border-blue-500 rounded-md bg-sky-100 p-1 flex items-center justify-center hidden group-hover:block absolute bottom-0 left-0">
                <div className="text-black">{dotTooltipText}</div>
              </div>
              <div className="rounded-full bg-blue-500 h-3 w-3 z-10"></div>
            </div>
          )}
        </div>
      );
    }
    return divs;
  };
  function obtenerNumeroEnteroTruncado(salario, razon = 1000000) {
    const numeroEntero = Math.floor(salario / razon);
    return numeroEntero;
  }
  //
  useEffect(() => {
    if (Page && outerPageSize) {
      setPage(1);
      setPagesButtonsArray(getPageButtonsArray(totalRecords / outerPageSize));
    }
  }, [outerPageSize]);
  useEffect(() => {
    if (!!onPaginate && Page && outerPageSize) {
      onPaginate(Page, outerPageSize);
    }
  }, [Page]);
  useEffect(() => {
    if (totalRecords) {
      setPagesButtonsArray(getPageButtonsArray(totalRecords / outerPageSize));
    }
  }, [totalRecords]);
  //
  const renderPageButtons = () => {
    const buttonRender = (b, bidx) => (
      <button
        key={`pagebtn-${b}`}
        onClick={() => setPage(b)}
        className={`rounded-md ${
          b === Page
            ? "bg-sky-500 text-white"
            : "hover:bg-sky-500 hover:text-white"
        } py-1 px-2`}
      >
        {b}
      </button>
    );
    //-----------------------------------------------------------------------------------
    // Si hay menos de 7 botones, se renderizan todos juntos
    const totalButtons = PagesButtonsArray.length;
    const maxVisibleButtons = 6;

    if (totalButtons <= maxVisibleButtons) {
      return PagesButtonsArray.map((b, bidx) => buttonRender(b, bidx));
    }
    //-----------------------------------------------------------------------------------
    // Se hace dinámico los botones de paginación. Ej: 1 2 3 ... 17 18 19 ... 100 101 102
    const primerasPaginas = PagesButtonsArray.slice(0, 3);
    const ultimasPaginas = PagesButtonsArray.slice(-3);
    //Se eliminan los botones de primerasPaginas y de ultimasPaginas de la parte que se mostrará en medio
    const pageIndex = PagesButtonsArray.indexOf(Page);
    const paginasVariables = PagesButtonsArray.slice(
      pageIndex - 2,
      pageIndex + 3
    ).filter(
      (element) =>
        !primerasPaginas.includes(element) && !ultimasPaginas.includes(element)
    );
    const mostrarPaginasVariable = () => {
      // Retorna falso si la página seleccionada es la primer, la segunda, la penultuma o la ultima
      return (
        Page !== PagesButtonsArray[0] &&
        Page !== PagesButtonsArray[1] &&
        Page !== PagesButtonsArray[totalButtons - 1] &&
        Page !== PagesButtonsArray[totalButtons - 2]
      );
    };

    return (
      <>
        {primerasPaginas.map((b, bidx) => buttonRender(b, bidx))}
        {mostrarPaginasVariable() && (
          <>
            <span>...</span>
            {paginasVariables.map((b, bidx) => buttonRender(b, bidx))}
          </>
        )}
        <span>...</span>
        {ultimasPaginas.map((b, bidx) => buttonRender(b, bidx))}
      </>
    );
  };
  //
  return (
    <div className="flex flex-col border-4x border-red-500x border rounded-md custom-scrollbar overflow-y-scroll">
      {!!loading && (
        <div className="w-full flex justify-center items-center h-20">
          <LoadingSpinButton className="" message="Cargando, favor espere..." />
        </div>
      )}
      {/*  */}
      {!loading && (
        <>
          <div className="flex w-full border-2x border-blue-200x bg-slate-100 px-2 py-3">
            <div className="font-semibold w-1/3 px-4">
              Lista de colaboradores
            </div>
            <div className="w-2/3 flex justify-between">
              {formatRangos().map((r, ri) => (
                <div className="text-center">{r}</div>
              ))}
            </div>
          </div>
          {/*  */}
          {colaboradores.map((colaborador, ci) => (
            <div
              className={`flex items-center w-full border-2x border-green-200x ${
                ci !== colaboradores.length - 1 ? "border-b" : ""
              }`}
            >
              <div className="w-1/3 border-r px-4 py-4">
                <ProfileInfoCard
                  name={`${colaborador.apellido}, ${colaborador.nombre}`}
                  nameWidth={48}
                  image={
                    colaborador.foto_empleado ? colaborador.foto_empleado : ""
                  }
                >
                  <div
                    title={colaborador.cargo?.nombre || "Sin asignar"}
                    className="text-sm w-36 whitespace-nowrap text-ellipsis overflow-hidden"
                  >
                    Cargo: {colaborador.cargo?.nombre || "Sin asignar"}
                  </div>
                </ProfileInfoCard>
              </div>
              <div className="w-2/3 flex flex-col gap-4 my-2">
                <div className="w-full flex justify-between items-center">
                  {/* SALARIO BAR */}
                  {renderBlocks(
                    getBlocksBetweenRanges() * rangos.length,
                    obtenerNumeroEnteroTruncado(colaborador.rango_escala[0]),
                    obtenerNumeroEnteroTruncado(colaborador.rango_escala[1]),
                    "sky",
                    6,
                    obtenerNumeroEnteroTruncado(colaborador[salarioField]),
                    formatNumber(colaborador[salarioField]),
                    [colaborador.rango_escala[0], colaborador.rango_escala[1]]
                  )}
                </div>
                {!!showMercados && (
                  <div className="w-full flex justify-between items-center">
                    {/* MERCADO BAR */}
                    {renderBlocks(
                      getBlocksBetweenRanges() * rangos.length,
                      obtenerNumeroEnteroTruncado(colaborador.rango_mercado[0]),
                      obtenerNumeroEnteroTruncado(colaborador.rango_mercado[1]),
                      "yellow",
                      3,
                      null,
                      null,
                      [
                        colaborador.rango_mercado[0],
                        colaborador.rango_mercado[1],
                      ]
                    )}
                  </div>
                )}
              </div>
            </div>
          ))}
          {/*  */}
          {/* PAGINATION FOOTER */}
          <div className="flex items-center gap-2 justify-between border-t border-dashed px-3 py-2 mt-1">
            <div>{`Mostrando ${Page} de ${PagesButtonsArray.length} de un total de ${totalRecords} registros`}</div>
            <div className="flex justify-end gap-0 border rounded-md">
              {renderPageButtons()}
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default ComparativoColaborador;
