import { push } from "@lagunovsky/redux-react-router";
import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import { ErrorToast } from "src/modules/common/components/ErrorToast";
import { SuccessToast } from "src/modules/common/components/SuccessToast";
import { getToken, ObjectToString } from "src/utils/helpers";
import { competenciaCargoApi } from "./api";
import { competenciaCargoActions } from "./redux";

function* competenciaCargoDeleteSaga({ payload }) {
  const token = getToken();
  const competencia_cargo = payload;

  const params = { ...competencia_cargo };
  !!process.env.REACT_APP_DEBUG && console.log({ payload });
  try {
    const data = yield call(competenciaCargoApi(token).delete, params);

    yield put(competenciaCargoActions.competenciaCargos());
    yield put(competenciaCargoActions.competenciaCargoDeleteSuccess());
  } catch (error) {
    yield put(competenciaCargoActions.competenciaCargoDeleteSuccess());
    !!process.env.REACT_APP_DEBUG && console.log({ error });
  }
}

function* competenciaCargoUpdateSaga({ payload }) {
  const token = getToken();
  const competencia_cargo = yield select(
    (state) => state.competenciaCargo.competencia_cargo
  );
  const aplicar_pesos = yield select(
    (state) => state.competenciaCargo.aplicar_pesos
  );
  // Verificacion de Pesos
  let { competencias } = competencia_cargo;
  const sumaPesos = competencias.reduce((sum, c) => sum + c.peso, 0);
  if (!aplicar_pesos) {
    competencias = competencias.map((comp, idx) => ({ ...comp, peso: 0 }));
  } else if (sumaPesos !== 100 && sumaPesos !== 0) {
    ErrorToast({
      message: "La suma de los pesos no son igual a 100%",
    });
    yield put(competenciaCargoActions.competenciaCargoCreateSuccess());
    return;
  }
  const params = { ...competencia_cargo, competencias: competencias };
  //
  try {
    const data = yield call(competenciaCargoApi(token).update, params);
    //
    yield put(competenciaCargoActions.competenciaCargoUpdateSuccess());
    yield put(push("/competencias/cargo"));
    SuccessToast({ message: "Competencias actualizadas con exito!" });
    yield put(competenciaCargoActions.competenciaCargos());
  } catch (error) {
    yield put(competenciaCargoActions.competenciaCargoUpdateSuccess());
    ErrorToast({ message: "Error al actualizar competencias." });
    !!process.env.REACT_APP_DEBUG && console.log({ error });
  }
}

function* competenciaCargoCreateSaga({ payload }) {
  const token = getToken();
  const competencia_cargo = yield select(
    (state) => state.competenciaCargo.competencia_cargo
  );
  const aplicar_pesos = yield select(
    (state) => state.competenciaArea.aplicar_pesos
  );
  // Verificacion de Pesos
  let { competencias } = competencia_cargo;
  const sumaPesos = competencias.reduce((sum, c) => sum + c.peso, 0);
  if (!aplicar_pesos) {
    competencias = competencias.map((comp, idx) => ({ ...comp, peso: 0 }));
  } else if (sumaPesos !== 100 && sumaPesos !== 0) {
    ErrorToast({
      message: "La suma de los pesos no son igual a 100%",
    });
    yield put(competenciaCargoActions.competenciaCargoCreateSuccess());
    return;
  }
  const params = { ...competencia_cargo, competencias: competencias };
  //
  try {
    const data = yield call(competenciaCargoApi(token).create, params);
    //
    yield put(competenciaCargoActions.competenciaCargoCreateSuccess());
    yield put(push("/competencias/cargo"));
    SuccessToast({message: "Grupo de competencias creada con exito!"});
    yield put(competenciaCargoActions.competenciaCargos());
  } catch (error) {
    yield put(competenciaCargoActions.competenciaCargoCreateSuccess());
    ErrorToast({ message: "Error al crear grupo de competencias." });
    !!process.env.REACT_APP_DEBUG && console.log({ error });
  }
}

function* competenciaCargosSaga({ payload }) {
  const token = getToken();
  const competenciaCargos_filter = yield select(
    (state) => state.competenciaCargo.competencia_cargos_filter
  );
  try {
    const data = yield call(competenciaCargoApi(token).list, {
      competenciaCargos_filter,
    });
    !!process.env.REACT_APP_DEBUG &&
      console.info("saga competenciaCargos data", data);
    yield put(
      competenciaCargoActions.setCompetenciaCargos({
        competencia_cargos: data,
      })
    );
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
  }
}

function* competenciaCargoDetailSaga({ payload }) {
  const token = getToken();
  //
  try {
    const data = yield call(competenciaCargoApi(token).detail, payload);
    //
    yield put(competenciaCargoActions.competenciaCargoSet(data));
  } catch (error) {
    yield put(competenciaCargoActions.competenciaCargoUpdateSuccess());
    !!process.env.REACT_APP_DEBUG && console.log({ error });
  }
}

function* competenciaCargosDeleteSaga({ payload }) {
  const token = getToken();
  const competencia_cargos_delete = yield select(
    (state) => state.competenciaCargo.competencia_cargos_delete
  );
  const params = { competencias_cargo_ids: competencia_cargos_delete };
  try {
    const data = yield call(
      competenciaCargoApi(token).competenciasCargoDelete,
      params
    );
    //
    yield put(competenciaCargoActions.competenciaCargos());
    SuccessToast({ message: "Competencias eliminadas con éxito!" });
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
    const errores = error.response.data;
    const message = ObjectToString(errores);
    ErrorToast({ message: message });
  }
}

function* getCompetenciasBaseSaga({ payload }) {
  const token = getToken();
  const params = { ...payload };
  try {
    const data = yield call(
      competenciaCargoApi(token).libreriaEspecifica,
      params
    );
    yield put(competenciaCargoActions.setCompetenciasBase(data.results));
    yield put(competenciaCargoActions.getCompetenciasBaseComplete());
  } catch (error) {
    const errores = error.response.data;
    const message = ObjectToString(errores);
    ErrorToast({ message: message });
    yield put(competenciaCargoActions.getCompetenciasBaseComplete());
  }
}

export function* competenciaCargoSagas() {
  // create
  yield takeEvery(
    competenciaCargoActions.competenciaCargoCreate.type,
    competenciaCargoCreateSaga
  );
  // update
  yield takeEvery(
    competenciaCargoActions.competenciaCargoUpdate.type,
    competenciaCargoUpdateSaga
  );
  // delete
  yield takeEvery(
    competenciaCargoActions.competenciaCargoDelete.type,
    competenciaCargoDeleteSaga
  );
  yield takeEvery(
    competenciaCargoActions.setCompetenciaCargosDelete.type,
    competenciaCargosDeleteSaga
  );
  // list
  yield takeLatest(
    competenciaCargoActions.competenciaCargos.type,
    competenciaCargosSaga
  );
  yield takeLatest(
    competenciaCargoActions.setCompetenciaCargosFilter.type,
    competenciaCargosSaga
  );
  //
  yield takeEvery(
    competenciaCargoActions.getCompetenciaCargo.type,
    competenciaCargoDetailSaga
  );
  //
  yield takeEvery(
    competenciaCargoActions.getCompetenciasBase.type,
    getCompetenciasBaseSaga
  );
}
