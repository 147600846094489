import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DataTable from "src/modules/common/components/DataTable";
import SearchInput from "src/modules/remuneracion/components/SearchInput";
import { feedbackActions } from "../handlers/redux";

function JefesDirectosList({ onSubmit, jefesForm }) {
  const dispatch = useDispatch();

  const jefes_directos = useSelector((state) => state.feedback.jefes_directos);
  const jefes_directos_loading = useSelector(
    (state) => state.feedback.jefes_directos_loading
  );
  const jefes_directos_count = useSelector(
    (state) => state.feedback.jefes_directos_count
  );
  const jefes_directos_filter = useSelector(
    (state) => state.feedback.jefes_directos_filter
  );
  const jefes_directos_checked = useSelector(
    (state) => state.feedback.jefes_directos_checked
  );

  const { nombre = "" } = jefes_directos_filter;

  //
  const datatableConfig = {
    headers: [
      {
        title: "Jefes directos",
        contentClass: "px-2 py-4",
        // onOrder: setOrdering("colaborador__nombre"),
        // accessor: "nombre",
        contentRenderer: (item) => (
          <div className="flex flex-col">
            <span className="">
              {item.nombre} {item.apellido ? item.apellido : ""}
            </span>
            <span className="text-sm">
              {item.cargo ? item.cargo.nombre : "Sin cargo"}
            </span>
          </div>
        ),
      },
    ],
    rowActions: [],
  };
  const onChangeText = (e) => {
    let searchText = e.target.value;
    dispatch(
      feedbackActions.setJefesDirectosFilter({
        ...jefes_directos_filter,
        nombre: searchText,
      })
    );
  };
  const onPreSubmit = () => {
    if (onSubmit) {
      onSubmit(jefes_directos_checked);
    }
  };

  const onPaginate = (page, pageSize) => {
    dispatch(
      feedbackActions.setJefesDirectosFilter({
        ...jefes_directos_filter,
        pagination: {
          page: page,
          pageSize: pageSize,
        },
      })
    );
  };

  const [outerPageSize, setOuterPageSize] = useState(1000);
  const onChangePageSize = (e) => {
    let value = e.target.value;
    dispatch(
      feedbackActions.setJefesDirectosFilter({
        ...jefes_directos_filter,
        pagination: {
          pageSize: parseInt(value),
          page: 1,
        },
      })
    );
    setOuterPageSize(parseInt(value));
  };

  const onCheckAll = () => {
    let checkedData = JSON.parse(JSON.stringify(jefes_directos));
    if (jefes_directos_checked.length > 0) {
      checkedData = [];
    }
    dispatch(feedbackActions.setCheckJefesDirectos(checkedData));
  };

  const onCheckItem = (item) => (event) => {
    dispatch(
      feedbackActions.checkJefesDirectos({
        jefeDirecto: item,
        checked: event.target.checked,
      })
    );
  };
  //
  return (
    <div>
      <SearchInput
        placeholder="Buscar por nombre o cargo..."
        value={nombre}
        onChange={onChangeText}
      />
      <div className="custom-scrollbar overflow-auto max-h-[400px] mt-4">
        <DataTable
          containerClass={"my-8x"}
          data={jefes_directos}
          loading={jefes_directos_loading}
          config={datatableConfig}
          noHeaders={false}
          //
          totalRecords={jefes_directos_count}
          onPaginate={onPaginate}
          outerPageSize={outerPageSize}
          //
          checkable={true}
          onCheckAll={onCheckAll}
          checkedItems={jefes_directos_checked}
          onItemCheck={onCheckItem}
        />
      </div>
      <div className="w-full mt-4">
        <button
          onClick={onPreSubmit}
          className="w-full bg-sky-500 hover:bg-sky-400 text-white py-4 px-4 rounded-md text-sm"
        >
          Agregar
        </button>
      </div>
    </div>
  );
}

export default JefesDirectosList;
