import React from "react";
import { Route, Routes } from "react-router-dom";
import { areaRoutes } from "../modules/area/handlers/routes";
import { cargoRoutes } from "../modules/cargo/handlers/routes";
import { departamentoRoutes } from "../modules/departamento/handlers/routes";
import { estructuraRoutes } from "../modules/estructura/handlers/routes";
import AppPage from "../modules/estructura/pages/AppPage";
import ImportacionPage from "../modules/estructura/pages/ImportacionPage";
import { sucursalRoutes } from "../modules/sucursal/handlers/routes";

const routes = [
  ...estructuraRoutes,
  ...cargoRoutes,
  ...areaRoutes,
  ...departamentoRoutes,
  ...sucursalRoutes,
];

export const RootRoutes = () => {
  return (
    <Routes>
      {routes.map((route, idx) => (
        <Route key={idx} {...route} />
      ))}
    </Routes>
  );
};

export const estructuraRootRoutes = [
  {
    path: "/estructura/*",
    element: <AppPage />,
  },
  {
    path: "/importador/:subject",
    element: <ImportacionPage />,
  },
];
