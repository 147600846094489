import React from "react";
import TableCustom from "src/modules/common/components/TableCustom";

const PlanCarretaListItem = ({ current = false }) => {
  return (
    <div className="flex flex-row justify-between items-center  py-4 border-b border-zinc-200">
      <div className="flex flex-col gap-1">
        <div className="text-sm font-bold">Nombre del plan de carrera</div>
        <div className="text-sm"># de cargos</div>
      </div>
      {current && (
        <div className="flex flex-row gap-2 items-center">
          <div className="h-14 w-14 rounded bg-sky-300"></div>
          <div className="flex flex-col gap-1">
            <div className="text-sm font-bold">Se encuenta en este nivel</div>
            <div className="text-sm">Cargo: Cargo 3</div>
          </div>
        </div>
      )}
      <div className="bg-sky-500 h-5 w-3"></div>
    </div>
  );
};

function ColaboradorPlanCarreraPage() {
  return (
    <div className="flex justify-center items-center w-full mt-10 pb-4">
      <div className="flex flex-col w-full md:w-5/6 gap-3">
        <div className="border-b pb-2">
          <span className="font-bold text-lg">Plan de carrera</span>
        </div>

        {/* tabla */}
        <div className="flex flex-col gap-3 border border-zinc-200 rounded p-4">
          {/* items */}
          <div className="">
            <TableCustom
              data={[]}
              tableTitle={"Cargos a los que se ha postulado"}
              subject={""}
              subjectGender={"F"}
              addBtnClickHandler={null}
              dropdownActions={null}
            />
          </div>
        </div>
        <div className="flex flex-col gap-3 border border-zinc-200 rounded p-4">
          <span className="font-bold text-md">Planes de carrera</span>
          {/* lista */}
          <div className="flex flex-col gap-2">
            {/* item */}
            <PlanCarretaListItem />
            <PlanCarretaListItem />
            <PlanCarretaListItem current={true} />
            <PlanCarretaListItem />
            <PlanCarretaListItem />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ColaboradorPlanCarreraPage;
