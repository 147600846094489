// @ts-nocheck
import React, { useState } from "react";
import { CaretDownFill, CaretUpFill } from "react-bootstrap-icons";
import { useDispatch } from "react-redux";
import { getBGIndex } from "src/modules/common/handler/color";

export const CompetenciaItemFeedback = ({
  competencia = {},
  itemKey,
  onSubmit = null,
  estado = "",
  idx = null,
}) => {
  const [collapse, setCollapse] = useState(false);

  let {
    nombre = "Sin especificar",
    descripcion = "",
    peso = "",
    conductas = [],
  } = competencia;

  let dotColor = idx > -1 ? getBGIndex(idx) : "bg-zinc-400";

  return (
    <div key={itemKey} className="border border-zinc-200  rounded">
      {/* collapse header */}
      <div className="flex flex-row items-center bg-zinc-200 bg-opacity-50 p-3 gap-3">
        <div className={`rounded-full w-3 h-3 ${dotColor}`}></div>
        <div className="flex-grow">
          <div className="text-md">{nombre}</div>
          <div className="text-xs">{descripcion}</div>
        </div>
        {peso > 0 && (
          <div className={`text-sm`}>
            <div
              className={`flex h-8 w-16 border shadow-inner bg-white pr-2 items-center`}
            >
              <input
                type="text"
                max={100}
                maxLength={3}
                value={peso || ""}
                disabled={true}
                className={`bg-slate-300 w-full h-full focus:outline-none px-2 flex text-center items-center justify-center`}
              />
              %
            </div>
          </div>
        )}
        <div
          className="w-3  cursor-pointer "
          onClick={() => {
            setCollapse(!collapse);
          }}
        >
          {!!collapse && <CaretDownFill />}
          {!collapse && <CaretUpFill />}
        </div>
      </div>
      {/* collapse body */}
      {!collapse && (
        <div className="flex flex-col gap-4 px-6 py-4">
          {/* lista conficiones */}
          <div className="flex flex-col gap-3">
            <div className="text-sm font-bold">Conductas:</div>
            <div className="ml-4">
              <ul className="list-disc text-sm flex flex-col gap-4 ">
                {conductas.map((conducta) => (
                  <li key={conducta.id}>
                    <ConductaItem conducta={conducta} estado={estado} />
                  </li>
                ))}
              </ul>
            </div>
            <div className="flex justify-end mt-4">
              <button
                className="bg-sky-500 text-white text-sm px-2 py-1 rounded-md"
                onClick={() => onSubmit(competencia)}
              >
                Agregar
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const ConductaItem = ({ conducta, estado }) => {
  return (
    <div className="flex flex-col gap-1">
      <div className="">{conducta.descripcion}</div>
      {!!estado && (
        <div className="-ml-8 -mr-4 px-4 py-1">
          {/* calificar */}
          {estado == "evaluacion" && (
            <ConductaEvaluacionForm
              conducta={conducta}
            ></ConductaEvaluacionForm>
          )}
          {estado == "calibracion" && (
            <ConductaCalibracionForm
              conducta={conducta}
            ></ConductaCalibracionForm>
          )}

          {/* comentario */}
        </div>
      )}
    </div>
  );
};

const ConductaField = ({
  conducta = {},
  color = "border-zinc-100 bg-zinc-100",
}) => {
  return (
    <div
      className={
        " flex flex-row gap-4 text-sm rounded px-4 py-2 items-center justify-between border " +
        color
      }
    >
      <div className="flex flex-row gap-4 items-center">
        {[
          "siempre",
          "constantemente",
          "frecuente",
          "en ocaciones",
          "nunca",
        ].map((value) => (
          <label key={value} className=" capitalize flex items-center gap-1">
            <input
              type="radio"
              name={`conducta-${conducta.id}`}
              value={value}
            />
            {value}
          </label>
        ))}
      </div>
      <div className="  flex items-center gap-1">
        <input type="checkbox" name="" id="" /> No aplica
      </div>
    </div>
  );
};

const ConductaEvaluacionForm = ({ conducta }) => {
  return (
    <div className="flex flex-col gap-1 ">
      <ConductaField
        color="border-dashed border-orange-300 bg-orange-50"
        conducta={conducta}
      />
      <div className="comentario ">
        <button className="text-sm text-sky-500">Dejar comentario</button>
      </div>
    </div>
  );
};

const ConductaCalibracionForm = ({ conducta }) => {
  return (
    <div className="flex flex-col gap-1 ">
      <ConductaField conducta={conducta}></ConductaField>
      <ConductaField
        conducta={conducta}
        color="border-dashed border-orange-300 bg-orange-50"
      ></ConductaField>
      <div className="comentario ">
        <button className="text-sm text-sky-500">Dejar comentario</button>
      </div>
    </div>
  );
};
