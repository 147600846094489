import axios from "axios";

const URL_BASE =
  process.env.REACT_APP_API_URL || "http://localhost:8000/api/admin";

export const beneficiosApis = (token) => {
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };
  return {
    list: async (beneficios_filter) => {
      const { nombre = "", pagination = {}, ordering = "" } = beneficios_filter;
      const { page = 1, pageSize = 10 } = pagination;
      let url =
        URL_BASE +
        `/beneficio/?search=${nombre}&page=${page}&page_size=${pageSize}`;
      if (ordering) {
        url = url + `&ordering=${ordering}`;
      }
      try {
        const response = await axios.get(url, { headers });
        return response.data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG &&
          console.log("get beneficios error", error);
        throw error;
      }
    },
    detail: async (idBeneficio) => {
      const url = `${URL_BASE}/beneficio/${idBeneficio}/detail`;
      try {
        const response = await axios.get(url, { headers });
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    listTipoConfig: async () => {
      const url = URL_BASE + "/beneficio/tipos-config-beneficio";
      try {
        const response = await axios.get(url, { headers });
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    createTipoConfig: async (params) => {
      const url = URL_BASE + "/beneficio/tipo-config-beneficio/create/";
      try {
        const response = await axios.post(url, params, { headers });
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    bulkCreate: async (params) => {
      const url = URL_BASE + "/beneficio/bulk_create/";
      try {
        const response = await axios.post(url, params, { headers });
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    update: async (params) => {
      const url = URL_BASE + "/beneficio/update";
      try {
        const response = await axios.put(url, params, { headers });
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    delete: async (idBeneficio) => {
      const url = `${URL_BASE}/beneficio/${idBeneficio}`;
      try {
        const response = await axios.delete(url, { headers });
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    bulkDelete: async (ids) => {
      try {
        const request = { beneficios_ids: ids };
        const response = await axios.post(
          `${URL_BASE}/beneficio/bulk-delete-beneficios`,
          request,
          {
            headers,
          }
        );
        !!process.env.REACT_APP_DEBUG &&
          console.log("bulk-delete-beneficios api", response);
        return response.data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log("beneficios error", error);
        throw error;
      }
    },
    cargosHabilitados: async (cargos_habilitados_filter) => {
      const {
        nombre = "",
        pagination = {},
        ordering = "nombre",
      } = cargos_habilitados_filter;
      const { page = 1, pageSize = 999 } = pagination;
      const url =
        URL_BASE +
        `/beneficio/cargos-habilitados?ordering=${ordering}&search=${nombre}&page=${page}&page_size=${pageSize}`;
      try {
        const response = await axios.get(url, { headers });
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    colaboradoresHabilitados: async (colaboradores_habilitados_filter) => {
      const {
        nombre = "",
        pagination = {},
        ordering = "nombre",
      } = colaboradores_habilitados_filter;
      const { page = 1, pageSize = 999 } = pagination;
      const url =
        URL_BASE +
        `/beneficio/colaboradores-habilitados?ordering=${ordering}&search=${nombre}&page=${page}&page_size=${pageSize}`;
      try {
        const response = await axios.get(url, { headers });
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    vincularCargos: async (payload) => {
      const { beneficioId, cargosIds = [] } = payload;
      const url = `${URL_BASE}/beneficio/beneficio-cargo/${beneficioId}/vincular-cargo/`;
      try {
        const response = await axios.post(
          url,
          { cargos_ids: cargosIds },
          { headers }
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    desvincularCargo: async (cargoBeneficioId) => {
      const url = `${URL_BASE}/beneficio/beneficio-cargo/${cargoBeneficioId}/delete`;
      try {
        const response = await axios.delete(url, { headers });
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    desvincularCargosMasivo: async (payload) => {
      const { cargosBeneficiosIds = [] } = payload;
      const url = `${URL_BASE}/beneficio/beneficio-cargo/desvincular-cargos/`;
      try {
        const response = await axios.post(
          url,
          { cargos_beneficios_ids: cargosBeneficiosIds },
          { headers }
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    desvincularBeneficiosCargo: async (payload) => {
      const { cargosIds = [] } = payload;
      const url = `${URL_BASE}/beneficio/cargo/desvincular-beneficios/`;
      try {
        const response = await axios.post(
          url,
          { cargos_ids: cargosIds },
          { headers }
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    cargosList: async (cargos_list_filter) => {
      const {
        nombre = "",
        pagination = {},
        ordering = "nombre",
      } = cargos_list_filter;
      const { page = 1, pageSize = 50 } = pagination;
      const url =
        URL_BASE +
        `/beneficio/cargos-beneficios-list?ordering=${ordering}&search=${nombre}&page=${page}&page_size=${pageSize}`;
      try {
        const response = await axios.get(url, { headers });
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    beneficiosCargo: async (idCargo) => {
      const url =
        URL_BASE +
        `/beneficio/beneficios-cargo/${idCargo}/?ordering=nombre&page=1&page_size=999`;
      try {
        const response = await axios.get(url, { headers });
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    colaboradoresList: async (colaboradores_list_filter) => {
      const {
        nombre = "",
        pagination = {},
        ordering = "nombre",
      } = colaboradores_list_filter;
      const { page = 1, pageSize = 50 } = pagination;
      const url =
        URL_BASE +
        `/beneficio/colaboradores-beneficios-list?ordering=${ordering}&search=${nombre}&page=${page}&page_size=${pageSize}`;
      try {
        const response = await axios.get(url, { headers });
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    colaborador: async (idColaborador) => {
      const url = `${URL_BASE}/beneficio/colaborador-detail/${idColaborador}/`;
      try {
        const response = await axios.get(url, { headers });
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    deleteColaboradoresBeneficio: async (payload) => {
      const { colaboradoresBeneficiosIds = [] } = payload;
      const url = `${URL_BASE}/beneficio/colaborador-beneficio/bulk-delete/`;
      try {
        const response = await axios.post(
          url,
          { colaboradores_beneficios_ids: colaboradoresBeneficiosIds },
          { headers }
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    createColaboradoresBeneficio: async (payload) => {
      const { beneficiosId = [], colaboradoresIds = [] } = payload;
      const url = `${URL_BASE}/beneficio/colaboradores-beneficio/bulk-create`;
      try {
        const response = await axios.post(
          url,
          { beneficios_ids: beneficiosId, colaboradores_ids: colaboradoresIds },
          { headers }
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    cambiarBeneficioHabilitadoColaborador: async (idColaborador) => {
      const url = `${URL_BASE}/beneficio/colaborador/${idColaborador}/cambiar-estado/`;
      try {
        const response = await axios.post(url, {}, { headers });
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    beneficiosColaborador: async (payload) => {
      const { idColaborador, beneficios_colaborador_filter } = payload;
      const {
        nombre = "",
        pagination = {},
        ordering = "nombre",
      } = beneficios_colaborador_filter;
      const { page = 1, pageSize = 50 } = pagination;
      const url =
        URL_BASE +
        `/beneficio/colaborador/${idColaborador}/beneficios/?ordering=${ordering}&search=${nombre}&page=${page}&page_size=${pageSize}`;
      try {
        const response = await axios.get(url, { headers });
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    excluirBeneficiosTotalColaboradores: async (payload) => {
      const { colaboradoresIds = [] } = payload;
      const url = `${URL_BASE}/beneficio/colaboradores-excluir-total-beneficios`;
      try {
        const response = await axios.post(
          url,
          { colaboradores_ids: colaboradoresIds },
          { headers }
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    cargo: async (idCargo) => {
      const url = `${URL_BASE}/cargo/${idCargo}/`;
      try {
        const response = await axios.get(url, { headers });
        return response.data;
      } catch (error) {
        throw error;
      }
    },
  };
};
