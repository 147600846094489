import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactSelect from "react-select";
import { cargoActions } from "../handlers/redux";

export const CargoSelect = ({
  value,
  cargoNombre = "",
  noDefaultValue = false,
  defaultLabel = "Todos los cargos",
  disabled = false,
  ...props
}) => {
  const dispatch = useDispatch();
  let cargos = useSelector((state) => state.cargo.cargos || {});
  const { results = [] } = cargos;
  cargos = results.map((s) => ({
    value: s.id,
    label: s.nombre,
  }));

  if (!noDefaultValue) {
    cargos.unshift({ value: null, label: defaultLabel });
  }

  const selected = !props.isMulti
    ? cargos.find((v) => v.value == value)
    : cargos.filter((v) => value.includes(v.value));

  !!process.env.REACT_APP_DEBUG && console.log({ value, selected, cargos });

  const onSearch = (value) => {
    value = value.toLowerCase();
    /*
    dispatch(
      cargoActions.setCargosFilter({
        nombre: value,
        pagination: { page: 1, pageSize: 100 },
      })
    );
    */
    cargos.filter((e) => value.includes(e.value))
  };

  useEffect(() => {
    dispatch(
      cargoActions.setCargosFilter({
        nombre: cargoNombre,
        pagination: { page: 1, pageSize: 1000 },
      })
    );
  }, [cargoNombre]);

  return (
    <ReactSelect
      {...props}
      closeMenuOnSelect={false}
      onInputChange={onSearch}
      onChange={(item) =>
        props.onChange &&
        props.onChange({
          target: {
            value: !!props.isMulti ? item.map((i) => i.value) : item.value,
          },
        })
      }
      value={selected}
      options={cargos}
      isDisabled={disabled}
    />
  );
};
