import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ReactSelect from "react-select";
import { BuscarInput } from "src/modules/common/components/BuscarInput";
import { PrimaryButton } from "src/modules/common/components/PrimaryButton";
import { competenciaCargoActions } from "../handlers/redux";

export const CompetenciaCargoToolbar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const competenciasCargo_filter = useSelector(
    (state) => state.competenciaCargo.competencia_cargos_filter
  );

  const { nombre = "" } = competenciasCargo_filter;

  const competencia_cargos_checked = useSelector(
    (state) => state.competenciaCargo.competencia_cargos_checked
  );

  const onClickNew = () => {
    dispatch(competenciaCargoActions.competenciaCargoSet({}));
    dispatch(competenciaCargoActions.setCompetenciaCargoEditing(true));
    navigate("/competencias/cargo/create");
  };

  const onChange = (v) => {
    if (v.value === "cancel") {
      dispatch(competenciaCargoActions.resetCheckCompetenciaCargo());
    } else if (v.value === "eliminar") {
      let deseaEliminar = window.confirm(
        "Desea eliminar las competencias seleccionadas?"
      );
      if (deseaEliminar) {
        dispatch(
          competenciaCargoActions.setCompetenciaCargosDelete(
            competencia_cargos_checked
          )
        );
      }
    } else {
      // @ts-ignore
      window.alert(JSON.stringify(v));
    }
  };

  const onChangeText = (event) => {
    dispatch(
      competenciaCargoActions.setCompetenciaCargosFilter({
        nombre: event.target.value,
      })
    );
  };

  return (
    <div className="flex flex-row gap-2">
      <BuscarInput
        onChange={onChangeText}
        value={nombre}
        placeholder="Buscar por nombre/descripción"
      ></BuscarInput>
      <ReactSelect
        className="w-52 h-10 bg-blue-200 inline-grid"
        value={{
          value: "",
          label: "(" + competencia_cargos_checked.length + ") seleccionados",
        }}
        options={[
          { value: "eliminar", label: "Eliminar" },
          { value: "cancel", label: "Cancelar" },
        ]}
        onChange={onChange}
      ></ReactSelect>
      <PrimaryButton onClick={onClickNew}>
        Añadir grupo de competencias
      </PrimaryButton>
    </div>
  );
};
