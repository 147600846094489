import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { Check, CheckLg, PencilFill, XLg } from "react-bootstrap-icons";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { empresaActions } from "src/modules/ajuste/handlers/redux";
import backButton from "src/modules/common/components/images/back-arrow.png";
import { PrimaryButton } from "src/modules/common/components/PrimaryButton";
import { commonActions } from "src/modules/common/handler/redux";
import ribbon from "src/modules/estructura/modules/estructura/images/blue-ribbon.png";
import { CalificacionRow } from "../components/CalificacionRow";
import { evaluacionActions } from "../handlers/redux";
import DatePicker from "react-datepicker";
import { format, parseISO } from "date-fns";
import PuntajeObservacionTable from "../components/PuntajeObservacionTable";
import { puntuacionesDefault } from "../helpers/constants";
import LoadingSpinButton from "src/modules/common/components/LoadingSpinButton";
import { WarningToast } from "src/modules/common/components/WarningToast";

function EvaluacionConfiguracion() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const empresa = useSelector((state) => state.empresa.empresa);
  const { idEvaluacion } = params;
  const evaluacion = useSelector((state) => state.evaluacion.evaluacion);
  const evaluacion_loading = useSelector(
    (state) => state.evaluacion.evaluacion_loading
  );
  const { cantidad_formularios = 0 } = evaluacion;
  const duplicar_evaluacion_id = useSelector(
    (state) => state.evaluacion.duplicar_evaluacion_id
  );
  const calificacionesTemplate = [
    {
      nombre: "Excelente",
      puntaje_desde: 90,
      puntaje_hasta: 100,
      isEditing: false,
    },
    {
      nombre: "Muy bueno",
      puntaje_desde: 65,
      puntaje_hasta: 89.99,
      isEditing: false,
    },
    {
      nombre: "Bueno",
      puntaje_desde: 31,
      puntaje_hasta: 64.99,
      isEditing: false,
    },
    {
      nombre: "Necesita mejorar",
      puntaje_desde: 0.1,
      puntaje_hasta: 30.99,
      isEditing: false,
    },
    {
      nombre: "Pendiente",
      puntaje_desde: 0,
      puntaje_hasta: 0,
      isEditing: false,
    },
  ];
  const [calificacionesLocal, setCalificacionesLocal] = useState([]);
  const [puntuaciones, setPuntuaciones] = useState(puntuacionesDefault);
  //
  const sePuedeEditarTotalmente = () => {
    return !!idEvaluacion ? cantidad_formularios < 1 : true;
  };
  //
  useEffect(() => {
    if (idEvaluacion) {
      dispatch(evaluacionActions.getEvaluacion({ id: idEvaluacion }));
    } else if (duplicar_evaluacion_id) {
      dispatch(
        evaluacionActions.duplicarEvaluacion({ id: duplicar_evaluacion_id })
      );
    } else {
      setCalificacionesLocal(calificacionesTemplate);
      dispatch(
        evaluacionActions.setEvaluacion({
          clasificaciones: calificacionesTemplate,
          puntuaciones_observacion: puntuacionesDefault,
        })
      );
    }
    dispatch(commonActions.setTitle(""));
    dispatch(commonActions.showSidebar(false));
    dispatch(empresaActions.empresa());
  }, []);

  useEffect(() => {
    if (evaluacion.clasificaciones?.length)
      setCalificacionesLocal(evaluacion.clasificaciones);
    if (evaluacion.puntuaciones_observacion?.length)
      setPuntuaciones(evaluacion.puntuaciones_observacion);
  }, [evaluacion.clasificaciones, evaluacion.puntuaciones_observacion]);

  const renderInput = ({
    type = "text",
    label = "title",
    field = "",
    value = "",
    placeholder = "Sin especificar",
    disabled = false,
  }) => {
    return (
      <div className="flex flex-col">
        <label className="font-bold text-base" htmlFor="">
          {label}
        </label>
        <input
          type={type}
          value={value}
          disabled={disabled}
          onChange={onChange(field)}
          placeholder={placeholder}
          className="border rounded placeholder-zinc-600 border-zinc-200 px-2 py-2"
        />
      </div>
    );
  };

  const checkBoxItem = ({
    label = "",
    description = "",
    checked = false,
    onChange,
    disabled = false,
  }) => {
    return (
      <div className="flex items-start">
        <div>
          <input
            className="w-5 h-5 mr-2"
            type="checkbox"
            checked={checked}
            onChange={onChange}
            disabled={disabled}
          />
        </div>
        <div>
          <h3 className="font-bold">{label}</h3>
          <div className="text-sm text-gray-400">{description}</div>
        </div>
      </div>
    );
  };

  const tienePlanAvanzado = () => {
    return empresa.tipo_plan === "Avanzado";
  };

  const onChange =
    (field, type = "") =>
    ({ target }) => {
      let value = target.value;
      if (type === "checkbox") {
        value = target.checked;
      }
      //
      let evaluacionCopy = { ...evaluacion };
      delete evaluacionCopy.clasificaciones;
      //
      dispatch(
        evaluacionActions.setEvaluacion({
          ...evaluacionCopy,
          [field]: value,
        })
      );
    };

  const onChangeDate = (field) => (date) => {
    let evaluacionCopy = { ...evaluacion };
    delete evaluacionCopy.clasificaciones;
    //
    dispatch(
      evaluacionActions.setEvaluacion({
        ...evaluacionCopy,
        [field]: date,
      })
    );
  };

  const onChangeClasificaciones = (idx, nombre, puntaje_desde) => {
    let calificaciones = [...calificacionesLocal];
    console.log("calificacionesLocal", calificacionesLocal);
    //
    if (calificaciones[idx]) {
      calificaciones[idx] = {
        ...calificaciones[idx],
        nombre: nombre,
        puntaje_desde: puntaje_desde,
      };
      //
      let actual = calificaciones[idx];
      let anterior = calificaciones[idx + 1];
      if (anterior && anterior.puntaje_desde > 0.0) {
        calificaciones[idx + 1] = {
          ...anterior,
          puntaje_hasta: actual.puntaje_desde - 0.01,
        };
      }
    }
    setCalificacionesLocal(calificaciones);
  };

  /* Maneja el valor del campo isEditing para mostrar botones */
  const onChangeEditing =
    (idx) =>
    (isEditing = false, isCancel = false) => {
      let calificaciones = [...calificacionesLocal];
      if (calificaciones[idx]) {
        if (isCancel) {
          calificaciones[idx] = {
            ...evaluacion.clasificaciones[idx],
            isEditing: false,
          };
          let anterior = calificaciones[idx + 1];
          if (anterior) {
            calificaciones[idx + 1] = {
              ...evaluacion.clasificaciones[idx + 1],
            };
          }
        } else {
          calificaciones[idx] = {
            ...calificaciones[idx],
            isEditing: isEditing,
          };
        }
      }

      setCalificacionesLocal(calificaciones);
    };

  const addCalificacion = () => {
    if (calificacionesLocal.length >= 7) return;
    //
    let calificaciones = [...calificacionesLocal];
    let pendiente = calificaciones.pop();
    let ultimo = calificaciones[calificaciones.length - 1];
    let newCalificacion = {
      nombre: "Nueva calificacion",
      puntaje_desde: 1,
      puntaje_hasta: ultimo.puntaje_desde - 0.01,
      isEditing: true,
      isRemovable: true,
    };
    calificaciones.push(newCalificacion);
    calificaciones.push(pendiente);
    setCalificacionesLocal(calificaciones);
  };

  const deleteCalificacion = (idx) => () => {
    if (calificacionesLocal.length <= 5) return;
    //
    let calificaciones = calificacionesLocal.filter((c, i) => i !== idx);
    setCalificacionesLocal(calificaciones);
  };

  const validarIndicadoresObservacion = (puntuacionesObs) => {
    // Valida que no haya dos indicadores con el mismo puntaje
    const porcentajes = new Set();
    let duplicadosEncontrados = false;

    puntuacionesObs.forEach((p) => {
      if (porcentajes.has(p.porcentaje)) {
        duplicadosEncontrados = true;
      } else {
        porcentajes.add(p.porcentaje);
      }
    });
    return duplicadosEncontrados;
  };

  const onSubmit = () => {
    const idsValidos = new Set(
      evaluacion.puntuaciones_observacion.map((p) => p.id)
    );
    // Pone como null las puntuaciones nuevas para que los cree en el backend, solo crea si va id = null
    let puntuacionesObsCopy = puntuaciones.map((p) => {
      if (!idsValidos.has(p.id)) {
        return { ...p, id: null };
      }
      return p;
    });
    //
    if (validarIndicadoresObservacion(puntuacionesObsCopy)) {
      WarningToast({
        message:
          "No pueden haber más de un Indicador por Observación con el mismo valor. Favor verificar.",
      });
      return;
    }

    dispatch(
      evaluacionActions.setEvaluacion({
        ...evaluacion,
        clasificaciones: calificacionesLocal,
        puntuaciones_observacion: puntuacionesObsCopy,
      })
    );
    //
    if (evaluacion.id) {
      dispatch(evaluacionActions.updateEvaluacion());
      return;
    }
    dispatch(evaluacionActions.createEvaluacion());
  };

  const onGoBack = () => {
    navigate("/evaluaciones");
    dispatch(commonActions.showSidebar(true));
  };

  return (
    <>
      <div className="flex items-center bg-white -mx-8 py-4 px-8 gap-3 border-b">
        <div
          onClick={onGoBack}
          className={"h-full  flex items-center pr-2 -mr-2 z-10 cursor-pointer"}
        >
          <img className="back" src={backButton} alt="Regresar" />
        </div>
        <div className="font-medium px-4 text-lg">
          Configuración de evaluación
        </div>
      </div>
      {/*  */}
      {!evaluacion_loading && (
        <div className="flex flex-col items-center justify-center mt-8">
          {/* CONFIGURACION INICIAL */}
          <form className="w-11/12 md:w-8/12">
            <div className="border-b py-2 mb-4">
              <h3 className="font-bold text-lg">Configuración inicial</h3>
            </div>
            <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="col-span-1 md:col-span-2">
                {renderInput({
                  label: "Nombre de la evaluación:",
                  value: evaluacion?.nombre,
                  field: "nombre",
                })}
              </div>
              <div className="col-span-1 md:col-span-2">
                {renderInput({
                  label: "Descripción:",
                  value: evaluacion?.descripcion,
                  field: "descripcion",
                })}
              </div>
              <div className="col-span-1">
                <div className="flex flex-col">
                  <label className="font-bold text-base" htmlFor="">
                    Fecha inicio
                  </label>
                  <DatePicker
                    className="border rounded placeholder-zinc-600 border-zinc-200 px-2 py-2 w-full"
                    locale="es"
                    selected={Date.parse(evaluacion?.fecha_inicio)}
                    onChange={onChangeDate("fecha_inicio")}
                    dateFormat="dd/MM/yyyy"
                  />
                </div>
              </div>
              <div className="col-span-1">
                <div className="flex flex-col">
                  <label className="font-bold text-base" htmlFor="">
                    Fecha finalización
                  </label>
                  <DatePicker
                    className="border rounded placeholder-zinc-600 border-zinc-200 px-2 py-2 w-full"
                    locale="es"
                    selected={Date.parse(evaluacion?.fecha_finalizacion)}
                    onChange={onChangeDate("fecha_finalizacion")}
                    dateFormat="dd/MM/yyyy"
                  />
                </div>
              </div>
              <div className="col-span-1 flex items-center gap-4">
                {renderInput({
                  label: "Antigüedad mínima:",
                  type: "number",
                  value: evaluacion?.antiguedad_minima,
                  field: "antiguedad_minima",
                  disabled: !sePuedeEditarTotalmente(),
                })}
                <div className="mt-4">meses</div>
              </div>
            </div>
          </form>
          {/* TIPO DE CALIFICACIONES */}
          <div className="flex flex-col w-11/12 md:w-8/12">
            <div className="border-b py-2 my-4">
              <h3 className="font-bold text-lg">Tipo de calificaciones</h3>
            </div>
            <div className="text-sm">
              Indique el rango de puntos que debe obtener cada calificación. El
              rango debe ir de 1 a 100.
            </div>
            <div className="calificaciones-container flex flex-col mt-4 p-4 border rounded-md">
              {/*  */}
              <div className="w-full justify-between flex font-bold">
                <div className="w-1/2">Tipo de calificación</div>
                <div className="flex flex-1">
                  <div className="flex-1">Puntaje desde</div>
                  <div className="flex-1">Puntaje hasta</div>
                </div>
              </div>
              {/*  */}
              <div className="mt-2">
                {/* evaluacion?.clasificaciones.map */}
                {calificacionesLocal.map((cal, idx) =>
                  CalificacionRow(
                    cal,
                    idx,
                    onChangeClasificaciones,
                    cal.isEditing,
                    onChangeEditing(idx),
                    cal.puntaje_desde !== 0.0,
                    deleteCalificacion(idx),
                    false //!sePuedeEditarTotalmente()
                  )
                )}
                <div>
                  <button
                    onClick={addCalificacion}
                    className="font-bold text-sky-500 text-sm"
                  >
                    Agregar nueva calificación
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* PUNTAJES OBSERVACION */}
          <div className="flex flex-col w-11/12 md:w-8/12">
            <div className="border-b py-2 my-4">
              <h3 className="font-bold text-lg">Indicadores por Observación</h3>
            </div>
            <div className="text-sm">
              Personaliza las opciones de los indicadores del tipo "Por
              Observación" según tus necesidades y criterios.
            </div>
            <div className="calificaciones-container flex flex-col mt-4 p-4 border rounded-md">
              {/*  */}
              <div className="mt-2">
                <PuntajeObservacionTable
                  puntuaciones={puntuaciones}
                  setPuntuaciones={setPuntuaciones}
                  sePuedeEditarTotalmente={sePuedeEditarTotalmente()}
                  // disabled={!sePuedeEditarTotalmente()}
                />
              </div>
            </div>
          </div>
          {/* OPCIONES CALIFICACIONES */}
          <div className="flex flex-col w-11/12 md:w-8/12">
            <div className="flex flex-col gap-4 mt-6">
              {checkBoxItem({
                checked: evaluacion?.visualizar_pesos_indicadores_observacion,
                disabled: !tienePlanAvanzado(), // || !sePuedeEditarTotalmente()
                onChange: onChange(
                  "visualizar_pesos_indicadores_observacion",
                  "checkbox"
                ),
                label:
                  "Visualizar los porcentajes de los indicadores por observación",
                description:
                  "En las evaluaciones se podrá visualizar el peso de cada indicador por observación.",
              })}
            </div>
          </div>
          {/* PROCESOS DE EVALUACION JEFE DIRECTO */}
          <div className="flex flex-col w-11/12 md:w-8/12">
            <div className="border-b py-2 my-4">
              <h3 className="font-bold text-lg">
                Procesos de evaluación del jefe directo
              </h3>
            </div>
            <div className="flex flex-col gap-4">
              {checkBoxItem({
                checked: evaluacion?.tiene_calibracion_superior,
                disabled: !tienePlanAvanzado() || !sePuedeEditarTotalmente(),
                onChange: onChange("tiene_calibracion_superior", "checkbox"),
                label: "Calibración del superior",
                description:
                  "Al ser evaluados todos los formularios de un evaluador, se solicitará la calibracion de los mismos al superior de este, antes de realizar el feedback con el colaborador.",
              })}
              {!tienePlanAvanzado && (
                <div className="my-2 mx-6">
                  <div className="flex items-center p-1 border-2 rounded border-sky-500 bg-sky-50">
                    <img className="p-2" src={ribbon} alt="ribbon" />
                    <div className="p-2 text-sm">
                      Calibración del superior pertenece al PLAN AVANZADO.
                    </div>
                  </div>
                </div>
              )}
              {checkBoxItem({
                checked: evaluacion?.tiene_devolucion,
                onChange: onChange("tiene_devolucion", "checkbox"),
                label: "Devolución de resultados",
                description:
                  "El jefe directo deberá dar un feedback a sus subalternos sobre la evaluación realizada.",
                disabled: !sePuedeEditarTotalmente(),
              })}
              {checkBoxItem({
                checked: evaluacion?.tiene_comentarios_evaluado,
                onChange: onChange("tiene_comentarios_evaluado", "checkbox"),
                label: "Comentarios del evaluado",
                disabled: !sePuedeEditarTotalmente(),
                description:
                  "Enviar el formulario luego del feedback con el colaborador, para que pueda dejar por escrito sus comentarios.",
              })}
            </div>
          </div>
          {/* OPCIONES SUBALTERNOS */}
          <div className="flex flex-col w-11/12 md:w-8/12">
            <div className="border-b py-2 my-4">
              <h3 className="font-bold text-lg">Opciones para subalternos</h3>
            </div>
            <div className="flex flex-col gap-4">
              {checkBoxItem({
                checked: evaluacion?.subalterno_evalua_competencias_cargo,
                disabled: !tienePlanAvanzado() || !sePuedeEditarTotalmente(),
                onChange: onChange(
                  "subalterno_evalua_competencias_cargo",
                  "checkbox"
                ),
                label: "Habilitar evaluación de competencias de cargo",
                description:
                  "Un subalterno podrá evaluar las competencias de cargo de su Jefe Directo.",
              })}
            </div>
          </div>
          {/* OPCIONES PARES */}
          <div className="flex flex-col w-11/12 md:w-8/12">
            <div className="border-b py-2 my-4">
              <h3 className="font-bold text-lg">Opciones para pares</h3>
            </div>
            <div className="flex flex-col gap-4">
              {checkBoxItem({
                checked: evaluacion?.par_evalua_competencias_cargo,
                disabled: !tienePlanAvanzado() || !sePuedeEditarTotalmente(),
                onChange: onChange("par_evalua_competencias_cargo", "checkbox"),
                label: "Habilitar evaluación de competencias de cargo",
                description:
                  "Un par podrá evaluar las competencias de cargo de su colaborador par.",
              })}
            </div>
          </div>
          {/* OPCIONES AUTOEVALUACION */}
          <div className="flex flex-col w-11/12 md:w-8/12">
            <div className="border-b py-2 my-4">
              <h3 className="font-bold text-lg">Opciones de autoevaluación</h3>
            </div>
            <div className="flex flex-col gap-4">
              {checkBoxItem({
                checked: evaluacion?.autoevaluacion_evalua_competencias_cargo,
                disabled: !tienePlanAvanzado() || !sePuedeEditarTotalmente(),
                onChange: onChange(
                  "autoevaluacion_evalua_competencias_cargo",
                  "checkbox"
                ),
                label: "Habilitar autoevaluación de competencias de cargo",
                description:
                  "Un colaborador podrá autoevaluar sus competencias de cargo.",
              })}
            </div>
          </div>
          {/*  */}
          <PrimaryButton className="w-11/12 md:w-8/12 my-8 bg-sky-500" onClick={onSubmit}>
            Guardar Configuración
          </PrimaryButton>
        </div>
      )}
      {!!evaluacion_loading && (
        <div className="flex flex-col items-center justify-center mt-8 h-96">
          <LoadingSpinButton />
        </div>
      )}
    </>
  );
}
export default EvaluacionConfiguracion;
