import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import { getToken } from "src/utils/helpers";
import { feedbackActions } from "./redux";
import { feedbackApis } from "./apis";
import { ErrorToast } from "src/modules/common/components/ErrorToast";
import { SuccessToast } from "src/modules/common/components/SuccessToast";
import { push } from "@lagunovsky/redux-react-router";

function* createFeedbackSaga() {
  const token = getToken();
  const feedback = yield select((state) => state.feedback.feedback);
  try {
    const data = yield call(feedbackApis(token).createFeedback, feedback);
    yield put(feedbackActions.setFeedback({}));
    yield put(feedbackActions.getFeedbacks());
    yield put(push("/feedback/"));
    SuccessToast({ message: "Feedback creado correctamente" });
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
    ErrorToast({ message: "Ocurrió un error al crear el Feedback" });
  }
}

function* updateFeedbackSaga() {
  const token = getToken();
  const feedback = yield select((state) => state.feedback.feedback);
  try {
    const data = yield call(feedbackApis(token).updateFeedback, feedback);
    yield put(feedbackActions.setFeedback({}));
    yield put(feedbackActions.getFeedbacks());
    yield put(push("/feedback/"));
    SuccessToast({ message: "Feedback modificado correctamente" });
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
    ErrorToast({ message: "Ocurrió un error al modificar el Feedback" });
  }
}

function* getFeedbacksSaga() {
  const token = getToken();
  const feedbacks_filter = yield select(
    (state) => state.feedback.feedbacks_filter
  );
  try {
    const data = yield call(
      feedbackApis(token).listFeedbacks,
      feedbacks_filter
    );
    yield put(feedbackActions.setFeedbacks(data.results));
    yield put(feedbackActions.setFeedbacksCount(data.count));
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
    yield put(feedbackActions.setFeedbacks([]));
  }
}

function* getFeedbackSaga({ payload }) {
  const token = getToken();
  try {
    const data = yield call(feedbackApis(token).detailFeedback, payload);
    yield put(feedbackActions.setFeedback(data));
  } catch (error) {
    yield put(feedbackActions.setFeedback({}));
  }
}

function* jefesDirectosSaga() {
  const token = getToken();
  const jefes_directos_filter = yield select(
    (state) => state.feedback.jefes_directos_filter
  );
  try {
    const data = yield call(
      feedbackApis(token).jefesDirectos,
      jefes_directos_filter
    );
    yield put(feedbackActions.setJefesDirectos(data.results));
    yield put(feedbackActions.setJefesDirectosCount(data.count));
  } catch (error) {
    yield put(feedbackActions.setJefesDirectos([]));
  }
}

function* jefesDirectosListSaga({ payload }) {
  const token = getToken();
  // const { idFeedback } = payload;
  const feedback_id = yield select((state) => state.feedback.feedback_id);
  const jefes_directos_list_filter = yield select(
    (state) => state.feedback.jefes_directos_list_filter
  );
  if (feedback_id) {
    try {
      const data = yield call(feedbackApis(token).jefesDirectosList, {
        idFeedback: feedback_id,
        jefes_directos_list_filter: jefes_directos_list_filter,
      });
      yield put(feedbackActions.setJefesDirectosList(data.results));
      yield put(feedbackActions.setJefesDirectosListCount(data.count));
    } catch (error) {
      !!process.env.REACT_APP_DEBUG && console.log({ error });
      yield put(feedbackActions.setJefesDirectosList([]));
    }
  }
}

function* getColaboradoresSaga({ payload }) {
  const token = getToken();
  const { idFeedback, idJefeDirecto } = payload;
  const colaboradores_filter = yield select(
    (state) => state.feedback.colaboradores_filter
  );
  const params = {
    idFeedback: idFeedback,
    idJefeDirecto: idJefeDirecto,
    colaboradores_filter: colaboradores_filter,
  };
  try {
    const data = yield call(feedbackApis(token).colaboradores, params);
    yield put(feedbackActions.setColaboradores(data.results));
    yield put(feedbackActions.setColaboradoresCount(data.count));
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
    yield put(feedbackActions.setColaboradores([]));
  }
}

function* getColaboradorFeedbackSaga({ payload }) {
  const token = getToken();
  try {
    const data = yield call(
      feedbackApis(token).detailColaboradorFeedback,
      payload
    );
    yield put(feedbackActions.setColaboradorFeedback(data));
  } catch (error) {
    yield put(feedbackActions.setColaboradorFeedback({}));
  }
}

function* getJefeDirectoSaga({ payload }) {
  const token = getToken();
  try {
    const data = yield call(
      feedbackApis(token).detailColaboradorFeedback,
      payload
    );
    yield put(feedbackActions.setJefeDirecto(data));
  } catch (error) {
    yield put(feedbackActions.setJefeDirecto({}));
  }
}

function* getFeedbackNominaSaga({ payload }) {
  const token = getToken();
  try {
    const data = yield call(feedbackApis(token).detailFeedbackNomina, payload);
    yield put(feedbackActions.setFeedbackNomina(data));
  } catch (error) {
    yield put(feedbackActions.setFeedbackNomina({}));
  }
}

function* createFeedbackNominaSaga({ payload }) {
  const token = getToken();
  const feedback_nomina = yield select(
    (state) => state.feedback.feedback_nomina
  );
  if (feedback_nomina.feedback_detalles.length === 0) {
    ErrorToast({ message: "Se debe agregar al menos un feedback a realizar." });
    return;
  }
  // const { feedback_detalles = [] } = feedback_nomina;
  const params = {
    ...payload,
    feedback_nomina,
  };
  try {
    const data = yield call(feedbackApis(token).createFeedbackNomina, params);
    yield put(feedbackActions.getFeedbackNomina(payload));
    SuccessToast({ message: "Feedback realizado correctamente!" });
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
    ErrorToast({ message: "Ocurrió un error al guardar el Feedback" });
  }
}

function* bulkDeleteFeedbackSaga({ payload }) {
  const token = getToken();
  const { ids } = payload;
  try {
    const data = yield call(feedbackApis(token).bulkDeleteFeedback, ids);
    yield put(feedbackActions.getFeedbacks());
    yield put(feedbackActions.getFeedbacksPorJefe());
    SuccessToast({ message: "Feedbacks eliminados correctamente!" });
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
    ErrorToast({ message: "Ocurrió un error al eliminar Feedback" });
  }
}

function* bulkDeleteJefesDirectosSaga({ payload }) {
  const token = getToken();
  const { feedback_id } = payload;
  try {
    const data = yield call(
      feedbackApis(token).bulkDeleteJefesDirectosFeedback,
      payload
    );
    yield put(
      feedbackActions.getJefesDirectosList({ idFeedback: feedback_id })
    );
    SuccessToast({ message: "Jefes Directos eliminados correctamente!" });
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
    ErrorToast({ message: "Ocurrió un error al eliminar Jefe Directo" });
  }
}

function* bulkDeleteColaboradoresSaga({ payload }) {
  const token = getToken();
  const { feedback_id, jefe_directo_id } = payload;
  try {
    const data = yield call(
      feedbackApis(token).bulkDeleteColaboradoresFeedback,
      payload
    );
    yield put(
      feedbackActions.getColaboradores({
        idFeedback: feedback_id,
        idJefeDirecto: jefe_directo_id,
      })
    );
    SuccessToast({ message: "Colaboradores eliminados correctamente!" });
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
    ErrorToast({ message: "Ocurrió un error al eliminar Colaborador" });
  }
}

function* kpisColaboradorSaga({ payload }) {
  const token = getToken();
  try {
    const data = yield call(feedbackApis(token).kpisColaborador, payload);
    yield put(feedbackActions.setKpisColaborador(data));
  } catch (error) {
    yield put(feedbackActions.setKpisColaborador([]));
  }
}

function* competenciasGeneralesSaga({ payload }) {
  const token = getToken();
  try {
    const data = yield call(feedbackApis(token).competenciasGenerales, payload);
    yield put(feedbackActions.setCompetenciasGenerales(data));
  } catch (error) {
    yield put(feedbackActions.setCompetenciasGenerales([]));
  }
}

function* competenciasAreasSaga({ payload }) {
  const token = getToken();
  try {
    const data = yield call(feedbackApis(token).competenciasAreas, payload);
    yield put(feedbackActions.setCompetenciasAreas(data));
  } catch (error) {
    yield put(feedbackActions.setCompetenciasAreas([]));
  }
}

function* competenciasCargosSaga({ payload }) {
  const token = getToken();
  try {
    const data = yield call(feedbackApis(token).competenciasCargos, payload);
    yield put(feedbackActions.setCompetenciasCargos(data));
  } catch (error) {
    yield put(feedbackActions.setCompetenciasCargos([]));
  }
}

function* getFeedbacksPorJefeSaga() {
  const token = getToken();
  const feedbacks_por_jefe_filter = yield select(
    (state) => state.feedback.feedbacks_por_jefe_filter
  );
  try {
    const data = yield call(
      feedbackApis(token).listFeedbacksPorJefe,
      feedbacks_por_jefe_filter
    );
    yield put(feedbackActions.setFeedbacksPorJefe(data.results));
    yield put(feedbackActions.setFeedbacksPorJefeCount(data.count));
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
    yield put(feedbackActions.setFeedbacksPorJefe([]));
  }
}

function* cambiarJefeDirectoSaga() {
  const token = getToken();
  const cambiar_jefe_directo_body = yield select(
    (state) => state.feedback.cambiar_jefe_directo_body
  );
  const { feedback_id, jefe_directo_id } = cambiar_jefe_directo_body;
  try {
    const data = yield call(
      feedbackApis(token).cambiarJefeDirecto,
      cambiar_jefe_directo_body
    );
    yield put(
      feedbackActions.getColaboradores({
        idFeedback: feedback_id,
        idJefeDirecto: jefe_directo_id,
      })
    );
    SuccessToast({ message: "Se modificó el Jefe Directo correctamente!" });
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
    ErrorToast({ message: "Ocurrió un error al modificar el Jefe Directo." });
  }
}

export function* feedbackSagas() {
  yield takeEvery(feedbackActions.createFeedback.type, createFeedbackSaga);

  yield takeEvery(feedbackActions.updateFeedback.type, updateFeedbackSaga);

  yield takeEvery(feedbackActions.getFeedbacks.type, getFeedbacksSaga);
  yield takeLatest(feedbackActions.setFeedbacksFilter.type, getFeedbacksSaga);

  yield takeEvery(feedbackActions.getFeedback.type, getFeedbackSaga);

  yield takeEvery(feedbackActions.getJefesDirectos.type, jefesDirectosSaga);
  yield takeLatest(
    feedbackActions.setJefesDirectosFilter.type,
    jefesDirectosSaga
  );

  yield takeEvery(
    feedbackActions.getJefesDirectosList.type,
    jefesDirectosListSaga
  );
  yield takeLatest(
    feedbackActions.setJefesDirectosListFilter.type,
    jefesDirectosListSaga
  );

  yield takeEvery(feedbackActions.getColaboradores.type, getColaboradoresSaga);
  yield takeLatest(
    feedbackActions.setColaboradoresFilter.type,
    getColaboradoresSaga
  );

  yield takeEvery(
    feedbackActions.getColaboradorFeedback.type,
    getColaboradorFeedbackSaga
  );

  yield takeEvery(feedbackActions.getJefeDirecto.type, getJefeDirectoSaga);

  yield takeEvery(
    feedbackActions.getFeedbackNomina.type,
    getFeedbackNominaSaga
  );

  yield takeEvery(
    feedbackActions.createFeedbackNomina.type,
    createFeedbackNominaSaga
  );

  yield takeEvery(
    feedbackActions.bulkDeleteFeedback.type,
    bulkDeleteFeedbackSaga
  );

  yield takeEvery(
    feedbackActions.bulkDeleteJefesDirectos.type,
    bulkDeleteJefesDirectosSaga
  );

  yield takeEvery(
    feedbackActions.bulkDeleteColaboradores.type,
    bulkDeleteColaboradoresSaga
  );

  yield takeEvery(feedbackActions.getKpisColaborador.type, kpisColaboradorSaga);

  yield takeEvery(
    feedbackActions.getCompetenciasGenerales.type,
    competenciasGeneralesSaga
  );

  yield takeEvery(
    feedbackActions.getCompetenciasAreas.type,
    competenciasAreasSaga
  );

  yield takeEvery(
    feedbackActions.getCompetenciasCargos.type,
    competenciasCargosSaga
  );

  yield takeEvery(
    feedbackActions.getFeedbacksPorJefe.type,
    getFeedbacksPorJefeSaga
  );
  yield takeLatest(
    feedbackActions.setFeedbacksPorJefeFilter.type,
    getFeedbacksPorJefeSaga
  );

  yield takeEvery(
    feedbackActions.cambiarJefeDirecto.type,
    cambiarJefeDirectoSaga
  );
}
