import axios from "axios";
const URL_BASE =
  process.env.REACT_APP_API_URL || "http://localhost:8000/api/admin";
//
export const politicaApi = (token) => {
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };
  return {
    evaluacion: async (filter) => {
      const { nombre = "", pagination = {} } = filter;
      const { page = 1, pageSize = 50 } = pagination;
      //
      try {
        const response = await axios.get(
          `${URL_BASE}/politica/evaluacion/?search=${nombre}&page=${page}&page_size=${pageSize}`,
          {
            headers,
          }
        );
        //
        return response.data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log("planes error", error);
        throw error;
      }
    },
    remuneracion: async (filter) => {
      const { nombre = "", pagination = {} } = filter;
      const { page = 1, pageSize = 50 } = pagination;
      //
      try {
        const response = await axios.get(
          `${URL_BASE}/politica/remuneracion/?search=${nombre}&page=${page}&page_size=${pageSize}`,
          {
            headers,
          }
        );
        //
        return response.data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log("planes error", error);
        throw error;
      }
    },
    planCarrera: async (filter) => {
      const { nombre = "", pagination = {} } = filter;
      const { page = 1, pageSize = 50 } = pagination;
      //
      try {
        const response = await axios.get(
          `${URL_BASE}/politica/plan-carrera/?search=${nombre}&page=${page}&page_size=${pageSize}`,
          {
            headers,
          }
        );
        //
        return response.data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log("planes error", error);
        throw error;
      }
    },
    //
    changeStatus: async ({ id, vigente }) => {
      try {
        const data = await axios.put(
          URL_BASE + `/politica/${id}/change-status`,
          { vigente: vigente },
          { headers }
        );
        return data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log(JSON.stringify(error));
        throw error;
      }
    },
    delete: async (id) => {
      try {
        const data = await axios.delete(URL_BASE + `/politica/${id}/delete`, {
          headers,
        });
        return data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log(JSON.stringify(error));
        throw error;
      }
    },
    bulkDelete: async (ids) => {
      try {
        const data = await axios.post(
          URL_BASE + `/politica/bulk-delete/`,
          { ids: ids },
          { headers }
        );
        return data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log(JSON.stringify(error));
        throw error;
      }
    },
    //
    upload: async ({ file, tipo }) => {
      try {
        const headersForm = {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        };
        const response = await axios.post(
          URL_BASE + `/politica/upload/`,
          { archivo: file, tipo: tipo },
          { headers: headersForm }
        );
        //
        return response.data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log("nomina error", error);
        throw error;
      }
    },
    replace: async ({ id, file }) => {
      try {
        const headersForm = {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        };
        const response = await axios.put(
          URL_BASE + `/politica/${id}/replace`,
          { archivo: file },
          { headers: headersForm }
        );
        //
        return response.data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log("nomina error", error);
        throw error;
      }
    },
  };
};
