import React from "react";

export const PrimaryButton = ({
  className = "bg-sky-500",
  children = null,
  ...props
}) => {
  return (
    <button
      {...props}
      className={`h-10 text-white text-sm px-4 rounded ${className}`}
    >
      {children}
    </button>
  );
};
