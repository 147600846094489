import { isDisabled } from "@testing-library/user-event/dist/utils";
import React, { useState } from "react";
import { CheckLg, PencilFill, TrashFill, XLg } from "react-bootstrap-icons";
import { spawn } from "redux-saga/effects";
import { WarningToast } from "src/modules/common/components/WarningToast";

const PuntajeTableRow = ({
  item,
  onRowChange,
  onRowDelete,
  canDelete,
  disabled = false,
}) => {
  const [isEdit, setIsEdit] = useState(false);
  const [previousItem, setPreviousItem] = useState({});
  const { nombre, porcentaje, descripcion } = item;
  const cellPadding = "py-4";
  const inputClass =
    "border rounded placeholder-zinc-600 border-zinc-200 px-2 py-1";
  const onEdit = () => {
    setPreviousItem(item);
    setIsEdit(true);
  };
  const onSave = () => {
    setPreviousItem({});
    setIsEdit(false);
  };
  const onCancel = () => {
    onRowChange(previousItem);
    setIsEdit(false);
    setPreviousItem({});
  };
  const onChange = (field) => (e) => {
    // TODO: ver para recuperar valor anterior en cancelar
    // se deberia hacer una copia local y en el onSave recien pasar los cambios...
    let value = e.target.value;
    if (field === "porcentaje") {
      let valueNumber = Number(value);
      if (valueNumber < 0 || valueNumber > 100) {
        value = 0;
      }
    }
    const itemCopy = { ...item, [field]: value };
    //
    onRowChange(itemCopy);
  };
  const canSave = () => {
    return porcentaje && nombre;
  };
  const onDelete = () => {
    onRowDelete(item);
  };
  //
  return (
    <div className="table-row">
      <div className={`table-cell w-2/5 ${cellPadding}`}>
        {!isEdit ? (
          <div className="flex flex-col gap-2">
            <div>{nombre}</div>
            <div
              title={descripcion}
              className="text-xs text-slate-500 w-4/5 whitespace-nowrap text-ellipsis overflow-hidden"
            >
              {descripcion}
            </div>
          </div>
        ) : (
          <div className="flex flex-col gap-2 md:w-2/3">
            <input
              type="text"
              value={nombre}
              className={inputClass}
              onChange={onChange("nombre")}
            />
            <textarea
              name={`descripcion-${item.id}`}
              id={`descripcion-${item.id}`}
              className={inputClass}
              rows="3"
              value={descripcion}
              onChange={onChange("descripcion")}
            ></textarea>
          </div>
        )}
      </div>
      <div className={`table-cell w-2/5 ${cellPadding}`}>
        {!isEdit ? (
          `${porcentaje}%`
        ) : (
          <input
            type="number"
            value={porcentaje}
            min="0"
            max="100"
            className={inputClass}
            onChange={onChange("porcentaje")}
          />
        )}
      </div>
      <div className={`table-cell w-1/5 ${cellPadding}`}>
        <div className="flex gap-2">
          {!!disabled && <></>}
          {!disabled && (
            <>
              {!isEdit && (
                <div className="flex gap-x-2">
                  <button className="border p-2 rounded-md" onClick={onEdit}>
                    <PencilFill size={20} />
                  </button>
                  {canDelete && (
                    <button
                      className="border p-2 rounded-md"
                      onClick={onDelete}
                    >
                      <TrashFill size={20}></TrashFill>
                    </button>
                  )}
                </div>
              )}
              {!!isEdit && (
                <>
                  <button
                    className={`${
                      !!canSave() ? "bg-sky-500" : "bg-slate-300"
                    } border p-2 rounded-md `}
                    onClick={onSave}
                    disabled={!canSave()}
                  >
                    <CheckLg size={20} color={"white"}></CheckLg>
                  </button>
                  <button className="border p-2 rounded-md" onClick={onCancel}>
                    <XLg size={20}></XLg>
                  </button>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const PuntajeObservacionTable = ({
  puntuaciones,
  setPuntuaciones,
  disabled = false,
  sePuedeEditarTotalmente,
}) => {
  const onRowChange = (item) => {
    let puntuacionesCopy = puntuaciones.filter(
      (pun, pidx) => pun.id !== item.id
    );
    puntuacionesCopy = [...puntuacionesCopy, item];
    const sortedData = [...puntuacionesCopy].sort((a, b) => a.id - b.id);
    setPuntuaciones(sortedData);
  };
  const onRowDelete = (item) => {
    if (puntuaciones.length > 2) {
      const updatedPuntuaciones = puntuaciones.filter(
        (pun) => pun.id !== item.id
      );
      setPuntuaciones(updatedPuntuaciones);
    } else {
      WarningToast({
        message: "Cantidad mínima de Indicadores por Observación: 2",
      });
    }
  };
  const addPuntuacion = () => {
    if (puntuaciones.length < 6) {
      const newPuntuacion = {
        id: puntuaciones.length + 1,
        nombre: "",
        descripcion: "",
        porcentaje: 0,
      };
      setPuntuaciones([...puntuaciones, newPuntuacion]);
    } else {
      WarningToast({
        message: "Cantidad máxima de Indicadores por Observación: 6",
      });
    }
  };
  //
  return (
    <div className="w-full">
      <div className="table w-full">
        <div className="table-row font-bold">
          <div className="table-cell w-2/4">Nombre</div>
          <div className="table-cell w-1/4">Valor</div>
          <div className="table-cell w-1/4"></div>
        </div>
        {puntuaciones.map((puntuacion, pidx) => (
          <PuntajeTableRow
            key={`ptw-${pidx}`}
            item={puntuacion}
            onRowChange={onRowChange}
            onRowDelete={onRowDelete}
            canDelete={sePuedeEditarTotalmente}
            disabled={disabled}
          />
        ))}
        {sePuedeEditarTotalmente && (
          <div>
            <button
              onClick={addPuntuacion}
              className="font-bold text-sky-500 text-sm"
            >
              Agregar nuevo indicador
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default PuntajeObservacionTable;
