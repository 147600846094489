export const puntuacionesDefault = [
  {
    id: 1,
    nombre: "Siempre",
    porcentaje: 100,
    descripcion:
      "En todas las ocasiones se observa esta conducta. Equivalente a excelente.",
  },
  {
    id: 2,
    nombre: "Constantemente",
    porcentaje: 75,
    descripcion: "La mayoría de las veces se observa. Equivalente a muy buena",
  },
  {
    id: 3,
    nombre: "Frecuentemente",
    porcentaje: 50,
    descripcion: "Algunas veces se observa esta conducta. Equivalente a buena",
  },
  {
    id: 4,
    nombre: "En Ocasiones",
    porcentaje: 25,
    descripcion: "Pocas veces se observa la conducta. Equivalente a regular",
  },
  {
    id: 5,
    nombre: "Nunca",
    porcentaje: 0,
    descripcion: "No se observa la conducta.",
  },
  // { label: "No aplica", value: -1 },
];

export const RELACIONES = {
  AUTOEVALUACION: "Autoevaluación",
  JEFE_DIRECTO: "Jefe directo",
  PAR: "Par",
  SUBALTERNO: "Subalterno",
  SIN_ESPECIFICAR: "Sin especificar",
  SIN_EVALUADOR: "Sin evaluador",
};
