import React, { useEffect, useState } from "react";
import { BeneficiosLayout } from "../components/BeneficiosLayout";
import SearchInput from "src/modules/remuneracion/components/SearchInput";
import MostrarPaginas from "src/modules/common/components/MostrarPaginas";
import ReactSelect from "react-select";
import DataTable from "src/modules/common/components/DataTable";
import { useNavigate } from "react-router-dom";
import { beneficiosRoutesWindow } from "../handlers/routes";
import { useDispatch, useSelector } from "react-redux";
import { beneficioActions } from "../handlers/redux";
import { ErrorToast } from "src/modules/common/components/ErrorToast";

const initialBeneficiosBody = {
  beneficios: [],
  cargos: [],
  colaboradores: [],
  tipo_config_beneficio: 0,
};

export function BeneficiosPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const beneficios = useSelector((state) => state.beneficio.beneficios);
  const beneficios_loading = useSelector(
    (state) => state.beneficio.beneficios_loading
  );
  const beneficios_count = useSelector(
    (state) => state.beneficio.beneficios_count
  );
  const beneficios_checked = useSelector(
    (state) => state.beneficio.beneficios_checked
  );
  const beneficios_filter = useSelector(
    (state) => state.beneficio.beneficios_filter
  );

  const { nombre } = beneficios_filter;

  useEffect(() => {
    dispatch(beneficioActions.getBeneficios());
  }, []);

  const getEstadoColor = (estado) => {
    return estado ? "bg-green-500 text-white" : "bg-red-500 text-white";
  };

  const goToDetail = (id) => {
    dispatch(beneficioActions.resetCheckCargosList());
    dispatch(beneficioActions.resetCheckColaboradoresList());
    navigate("/beneficios/view/" + id);
  };

  const onDeleteBeneficio = (id) => {
    let deseaEliminar = window.confirm(
      "¿Desea eliminar el beneficio seleccionado?"
    );
    if (!!deseaEliminar) {
      dispatch(beneficioActions.deleteBeneficio(id));
    }
  };

  const setOrdering =
    (field) =>
    (order = "") => {
      let { ordering = "" } = beneficios_filter;
      let orderingArray = ordering.split(",") || [];
      let emptyIndex = orderingArray.indexOf("");
      if (emptyIndex) {
        orderingArray.splice(emptyIndex, 1);
      }
      let fieldIndex = orderingArray.indexOf(field);
      fieldIndex =
        fieldIndex === -1 ? orderingArray.indexOf(`-${field}`) : fieldIndex;
      // si ya esta el campo, vemos si se modifica o elimina
      if (fieldIndex > 0) {
        orderingArray.splice(fieldIndex, 1);
        if (order) {
          field = order === "desc" ? `-${field}` : field;
          orderingArray[fieldIndex] = field;
        }
      } else {
        // si no está, lo agregamos
        field = order === "desc" ? `-${field}` : field;
        orderingArray.push(field);
        !!process.env.REACT_APP_DEBUG &&
          console.info("orderingArray 2", orderingArray);
      }
      ordering = orderingArray.join(",");
      //
      dispatch(
        beneficioActions.setBeneficiosFilter({
          ...beneficios_filter,
          ordering: ordering,
        })
      );
    };

  const datatableConfig = {
    headers: [
      {
        title: "Agregados",
        contentClass: "font-bold px-2 py-4",
        onOrder: setOrdering("nombre"),
        // accessor: "nombre",
        contentRenderer: (item) => (
          <div className="flex flex-col">
            <span className="font-semibold">{item.nombre}</span>
          </div>
        ),
      },
      {
        title: "Cargos",
        contentClass: "font-bold px-2 py-4",
        // onOrder: setOrdering(""),
        // accessor: "nombre",
        contentRenderer: (item) => (
          <div className="flex flex-col">
            <span className="">{item.cantidad_cargos}</span>
          </div>
        ),
      },
      {
        title: "Excluidos",
        contentClass: "font-bold px-2 py-4",
        // onOrder: setOrdering(""),
        // accessor: "nombre",
        contentRenderer: (item) => (
          <div className="flex flex-col">
            <span className="">{item.cantidad_colaboradores_excluidos}</span>
          </div>
        ),
      },
      {
        title: "Estado",
        contentClass: "font-bold px-2 py-4",
        onOrder: setOrdering("vigente"),
        // accessor: "nombre",
        contentRenderer: (item) => (
          <div className="flex items-center">
            <span
              className={`p-2 rounded-md text-sm ${getEstadoColor(
                item.vigente
              )}`}
            >
              {item.vigente ? "Vigente" : "No vigente"}
            </span>
          </div>
        ),
      },
      {
        title: "Configuración",
        contentClass: "font-bold px-2 py-4",
        // accessor: "nombre",
        contentRenderer: (item) => (
          <button
            className="flex items-center gap-2 border rounded-md py-2 px-3 border-gray-500 text-sm"
            onClick={() => goToDetail(item.id)}
          >
            <span className="text-sm">Ver detalles</span>
          </button>
        ),
      },
    ],
    rowActions: [
      {
        label: "Eliminar",
        action: (item) => {
          onDeleteBeneficio(item.id);
        },
      },
    ],
  };
  const goToConfiguracion = () => {
    dispatch(beneficioActions.setMostrarAlcance(true));
    dispatch(beneficioActions.setMostrarExcluidos(true));
    dispatch(beneficioActions.setBeneficiosBody(initialBeneficiosBody));
    dispatch(
      beneficioActions.setOnClickBackBeneficioConfiguracion(
        beneficiosRoutesWindow.BENEFICIOS
      )
    );
    navigate(beneficiosRoutesWindow.CONFIG);
  };

  const onCheckAll = () => {
    let checkedData = JSON.parse(JSON.stringify(beneficios));
    if (beneficios_checked.length > 0) {
      checkedData = [];
    }
    dispatch(beneficioActions.setCheckBeneficios(checkedData));
  };

  const onCheckBeneficio = (item) => (event) => {
    dispatch(
      beneficioActions.checkBeneficios({
        beneficio_aux: item,
        checked: event.target.checked,
      })
    );
  };

  const onPaginate = (page, pageSize) => {
    //
    dispatch(
      beneficioActions.setBeneficiosFilter({
        ...beneficios_filter,
        pagination: {
          page: page,
          pageSize: pageSize,
        },
      })
    );
  };

  const [outerPageSize, setOuterPageSize] = useState(10);
  const onChangePageSize = (e) => {
    let value = e.target.value;
    dispatch(
      beneficioActions.setBeneficiosFilter({
        ...beneficios_filter,
        pagination: {
          pageSize: parseInt(value),
          page: 1,
        },
      })
    );
    setOuterPageSize(parseInt(value));
  };

  const onSearchBeneficios = (e) => {
    let searchText = e.target.value;
    dispatch(
      beneficioActions.setBeneficiosFilter({
        ...beneficios_filter,
        nombre: searchText,
      })
    );
  };

  const actionOptions = [
    {
      value: "delete",
      label: "Eliminar",
      // action: () => {},
    },
  ];

  const onBulkDelete = () => {
    if (beneficios_checked.length < 1) {
      ErrorToast({ message: "Debe seleccionar al menos un beneficio" });
      return;
    }
    dispatch(beneficioActions.bulkDelete());
  };

  const onAction = (v) => {
    if (v.value === "delete") {
      const deseaEliminar = window.confirm(
        "¿Está seguro de eliminar los beneficios seleccionados?"
      );
      if (!!deseaEliminar) {
        onBulkDelete();
      }
      return;
    }
  };

  return (
    <BeneficiosLayout>
      <div className="border-b pb-2 mt-4 flex justify-between items-center mb-4">
        <h2 className="font-semibold text-xl">Lista de beneficios</h2>
      </div>
      <div className="px-4 py-2 w-full md:w-11/12">
        <div className="flex items-center gap-2 mt-4">
          <SearchInput
            containerClass={`w-1/2`}
            placeHolder={"Buscar por nombre..."}
            value={nombre}
            onChange={onSearchBeneficios}
          />
          <MostrarPaginas
            containerClass={"w-1/2x"}
            onChangePageSize={onChangePageSize}
            defaultOption={10}
          />
          <div className="text-sm">
            <ReactSelect
              className="w-52 h-10 bg-blue-200 inline-grid"
              value={{
                value: "",
                label: `(${beneficios_checked.length}) seleccionados`,
              }}
              options={actionOptions}
              onChange={onAction}
            ></ReactSelect>
          </div>
          <div>
            <button
              onClick={goToConfiguracion}
              className="rounded-md bg-sky-500 text-white p-3"
            >
              Nuevo beneficio
            </button>
          </div>
        </div>
        <div></div>
      </div>
      <DataTable
        containerClass={"my-2"}
        config={datatableConfig}
        data={beneficios}
        loading={beneficios_loading}
        noHeaders={false}
        //
        totalRecords={beneficios_count}
        onPaginate={onPaginate}
        outerPageSize={outerPageSize}
        //
        checkable={true}
        onCheckAll={onCheckAll}
        checkedItems={beneficios_checked}
        onItemCheck={onCheckBeneficio}
      />
    </BeneficiosLayout>
  );
}
