import { push } from "@lagunovsky/redux-react-router";
import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import { ErrorToast } from "src/modules/common/components/ErrorToast";
import { SuccessToast } from "src/modules/common/components/SuccessToast";
import { getToken, ObjectToString } from "src/utils/helpers";
import { departamentoApi } from "./api";
import { departamentoActions } from "./redux";

function* departamentoDeleteSaga({ payload }) {
  const token = getToken();
  const departamento = payload;
  const params = { ...departamento };
  !!process.env.REACT_APP_DEBUG && console.log({ payload });
  try {
    const data = yield call(departamentoApi(token).delete, params);
    yield put(departamentoActions.departamentos());
    yield put(departamentoActions.departamentoDeleteSuccess());
    SuccessToast({ message: "Departamento eliminado con éxito!" });
  } catch (error) {
    const errores = error.response.data;
    const message = ObjectToString(errores);
    ErrorToast({ message: message });
    yield put(departamentoActions.departamentoDeleteSuccess());
    !!process.env.REACT_APP_DEBUG && console.log({ error });
  }
}

function* departamentoUpdateSaga({ payload }) {
  const token = getToken();
  const departamento = yield select((state) => state.departamento.departamento);

  const params = { ...departamento };
  try {
    const data = yield call(departamentoApi(token).update, params);
    yield put(departamentoActions.departamentoUpdateSuccess());
    yield put(push("/estructura/departamentos"));
    yield put(departamentoActions.setDepartamentoCreateModal({ open: false }));
    yield put(departamentoActions.departamentos());
    SuccessToast({ message: "Departamento editado con éxito!" });
  } catch (error) {
    const errores = error.response.data;
    const message = ObjectToString(errores);
    ErrorToast({ message: message });
    yield put(departamentoActions.departamentoUpdateSuccess());
    !!process.env.REACT_APP_DEBUG && console.log({ error });
  }
}

function* departamentoCreateSaga({ payload }) {
  const token = getToken();
  const departamento = yield select((state) => state.departamento.departamento);

  const params = { ...departamento };
  try {
    const data = yield call(departamentoApi(token).create, params);
    yield put(departamentoActions.departamentoCreateSuccess());
    yield put(push("/estructura/departamentos"));
    yield put(departamentoActions.setDepartamentoCreateModal({ open: false }));
    yield put(departamentoActions.departamentos());
    SuccessToast({ message: "Departamento creado con éxito!" });
  } catch (error) {
    const errores = error.response.data;
    const message = ObjectToString(errores);
    ErrorToast({ message: message });
    yield put(departamentoActions.departamentoCreateSuccess());
    !!process.env.REACT_APP_DEBUG && console.log({ error });
  }
}

function* departamentosSaga({ payload }) {
  const token = getToken();
  const departamentos_filter = yield select(
    (state) => state.departamento.departamentos_filter
  );
  try {
    const data = yield call(departamentoApi(token).list, {
      departamentos_filter,
    });
    yield put(departamentoActions.setDepartamentos({ departamentos: data }));
  } catch (error) {
    const errores = error.response.data;
    const message = ObjectToString(errores);
    ErrorToast({ message: message });
    !!process.env.REACT_APP_DEBUG && console.log({ error });
  }
}

function* departamentosDeleteSaga({ payload }) {
  const token = getToken();
  const departamentosDelete = payload;
  !!process.env.REACT_APP_DEBUG && console.log({ payload });
  try {
    const data = yield call(departamentoApi(token).departamentosDelete, {
      departamentosIds: departamentosDelete,
    });
    yield put(departamentoActions.departamentos());
    yield put(departamentoActions.resetCheckDepartamentos());
    SuccessToast({ message: "Departamentos eliminados con éxito!" });
  } catch (error) {
    const errores = error.response.data;
    const message = ObjectToString(errores);
    ErrorToast({ message: message });
    !!process.env.REACT_APP_DEBUG && console.log({ error });
  }
}

function* departamentosImportarVerificarSaga({ payload }) {
  const token = getToken();
  const fileToUpload = yield select((state) => state.departamento.fileToUpload);
  try {
    const data = yield call(departamentoApi(token).importarVerificar, {
      fileToUpload,
    });
    yield put(departamentoActions.setDatosVerificados(data));
    yield put(push("/importador/departamento"));
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
  }
}

function* departamentosImportarSaga({ payload }) {
  const token = getToken();
  const datos = yield select((state) => state.departamento.datosImportar);
  try {
    const data = yield call(departamentoApi(token).importar, datos);
    yield put(departamentoActions.importarDatosComplete());
    SuccessToast({ message: "Departamentos importados con éxito!" });
    yield put(push("/estructura/departamentos"));
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
  }
}

export function* departamentoSagas() {
  yield takeEvery(
    departamentoActions.setFileToUpload.type,
    departamentosImportarVerificarSaga
  );
  yield takeEvery(
    departamentoActions.setDatosImportar.type,
    departamentosImportarSaga
  );
  // create
  yield takeEvery(
    departamentoActions.departamentoCreate.type,
    departamentoCreateSaga
  );
  // update
  yield takeEvery(
    departamentoActions.departamentoUpdate.type,
    departamentoUpdateSaga
  );
  // delete
  yield takeEvery(
    departamentoActions.departamentoDelete.type,
    departamentoDeleteSaga
  );
  yield takeEvery(
    departamentoActions.setDepartamentosDelete.type,
    departamentosDeleteSaga
  );
  // list
  yield takeLatest(departamentoActions.departamentos.type, departamentosSaga);
  yield takeLatest(
    departamentoActions.setDepartamentosFilter.type,
    departamentosSaga
  );
}
