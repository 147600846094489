import React from "react";
import { useEffect } from "react";
import { ChevronRight } from "react-bootstrap-icons";
import { useDispatch, useSelector } from "react-redux";
import { CheckInput } from "src/modules/common/components/CheckInput";
import { kpiActions } from "../handlers/redux";
import { KpiColaboradorTableAccionMenu } from "./KpiColaboradorTableAccionMenu";

export const ColaboradorAsignarTable = ({ kpi = {} }) => {
  const dispatch = useDispatch();

  // @ts-ignore
  const colaboradores = useSelector((state) => state.kpi.colaboradores);
  const colaboradores_checked = useSelector(
    (state) => state.kpi.colaboradores_checked
  );

  useEffect(() => {
    dispatch(kpiActions.colaboradores());
  }, []);

  const { results = [] } = colaboradores;

  !!process.env.REACT_APP_DEBUG &&
    console.info("colaboradores info", colaboradores);

  const renderTableHeader = () => {
    return (
      <div className="border-b flex flex-col border-zinc-100 ">
        <div className="flex flex-row">
          <div className="p-1 font-bold flex-grow">Lista de integrantes</div>
          <div className="p-1 font-bold text-center w-60  mr-4"># KPIs</div>
          <div className=" w-6"></div>
        </div>
      </div>
    );
  };

  const renderTableItem = (item, idx) => {
    return (
      <div
        key={idx}
        className="hover:bg-sky-100 flex flex-row border-b align-middle "
      >
        <div className="p-1 h-16 align-text-top  flex flex-row items-center">
          <CheckInput
            checked={colaboradores_checked.includes(item.id)}
            onChange={(event) => {
              dispatch(
                kpiActions.checkColaborador({
                  colaborador: item,
                  checked: event.target.checked,
                })
              );
            }}
          />
        </div>
        <div className="p-1 flex flex-grow flex-col justify-center">
          <div className="font-bold">{item.nombre}</div>
          <div className="text-sm">
            Cargo: {item.cargo?.nombre || "Sin definir"}
          </div>
        </div>
        <div className="p-1 text-center w-48 flex flex-row justify-center items-center">
          {item.cantidad_kpis}
        </div>
        <div className="p-1 w-12 flex flex-row items-center">
          {/* <KpiColaboradorTableAccionMenu item={item} kpi={kpi} /> */}
        </div>
      </div>
    );
  };
  //
  return (
    <div>
      <div className="flex flex-col w-full">
        {renderTableHeader()}
        <div className="flex flex-col  h-72 overflow-y-scroll">
          {results.length > 1 && results.map(renderTableItem)}
          {results.length < 1 && <div>No existen Colaboradores.</div>}
        </div>
      </div>
    </div>
  );
};
