import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Input from "src/modules/common/components/Input";
import Modal from "src/modules/common/components/Modal";
import { empresaActions } from "../handlers/redux";

function NombreModal(props) {
  const dispatch = useDispatch();
  const modal = useSelector(
    (state) => state.empresa.empresa_nombre_update_modal
  );
  const empresa = useSelector((state) => state.empresa.empresa);

  const setOpen = (open) => {
    dispatch(empresaActions.setEmpresaNombreModal({ open }));
  };

  const onChange =
    (name) =>
    ({ target }) => {
      dispatch(empresaActions.empresaSet({ ...empresa, [name]: target.value }));
    };

  const onSubmit = (event) => {
    event.preventDefault();
    if (!empresa.nombre) return;
    dispatch(empresaActions.empresaUpdate());
    setOpen(false);
  };

  return (
    <Modal isOpen={modal.open}>
      <div className=" rounded-lg bg-white w-6/12">
        <form onSubmit={onSubmit} className="flex flex-col gap-3 p-4">
          <div className="flex flex-row justify-between text-lg font-bold">
            <div>Empresa</div>
            <div onClick={() => setOpen(false)}>X</div>
          </div>
          <div className="flex flex-col gap-2">
            <div className="text-slate-600 text-sm font-bold">Nombre</div>
            <Input value={empresa.nombre} onChange={onChange("nombre")}></Input>
          </div>
          <div className="flex flex-row gap-2">
            <button
              type="submit"
              className="bg-sky-500 text-white rounded-md px-3 py-2 active:bg-gray-200"
            >
              Guardar
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
}

export default NombreModal;
