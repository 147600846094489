import { createSlice } from "@reduxjs/toolkit";

const competenciaAreaSlice = createSlice({
  name: "CompetenciaArea",
  initialState: {
    competencia_area_create_loading: false,
    competencia_area_update_loading: false,
    competencia_area_delete_loading: false,
    competencia_area: {},
    competencia_area_loading: false,
    competencia_areas: { result: [] },
    competencia_areas_filter: {},
    competencia_areas_checked: [],
    competencia_areas_delete: {},
    competencia_areas_loading: false,
    competencia_area_create_modal: { open: false },
    aplicar_pesos: false,
    competencia_area_editing: true,
    //
    libreria_competencias: [],
    libreria_competencias_filter: {},
    libreria_competencias_loading: false,
  },
  reducers: {
    getCompetenciasBase: (state, { payload = {} }) => {
      state.libreria_competencias_loading = true;
    },
    setCompetenciasBase: (state, { payload = {} }) => {
      state.libreria_competencias = payload;
    },
    getCompetenciasBaseComplete: (state, { payload = {} }) => {
      state.libreria_competencias_loading = false;
    },
    //
    getCompetenciaArea: (state, { payload = {} }) => {
      state.competencia_area_loading = true;
    },
    competenciaAreaSet: (state, { payload = {} }) => {
      state.competencia_area = payload;
      state.competencia_area_loading = false;
    },
    setCompetenciaAreas: (state, { payload }) => {
      state.competencia_areas = payload.competencia_areas;
      state.competencia_areas_loading = false;
    },
    setCompetenciaAreasFilter: (state, { payload = {} }) => {
      state.competencia_areas_filter = payload;
    },
    setCompetenciaAreasDelete: (state, { payload = {} }) => {
      state.competencia_areas_delete = payload;
    },
    setAplicarPesos: (state, { payload }) => {
      state.aplicar_pesos = payload;
    },
    setCompetenciaAreaEditing: (state, { payload }) => {
      state.competencia_area_editing = payload;
    },
    //
    checkCompetenciaArea: (
      state,
      { payload: { competenciaArea, checked = true } }
    ) => {
      state.competencia_areas_checked = state.competencia_areas_checked.filter(
        (id) => id !== competenciaArea.id
      );
      if (!!checked) {
        state.competencia_areas_checked.push(competenciaArea.id);
      }
      state.competencia_areas_loading = false;
    },
    resetCheckCompetenciaArea: (state) => {
      state.competencia_areas_checked = [];
      state.competencia_areas_loading = false;
    },
    // create
    setCompetenciaAreaCreateModal: (state, { payload = {} }) => {
      !!process.env.REACT_APP_DEBUG && console.log({ payload });
      state.competencia_area_create_modal = payload;
    },
    competenciaAreaCreate: (state, { payload = { conductas: [] } }) => {
      state.competencia_area_create_loading = true;
    },
    competenciaAreaCreateSuccess: (state) => {
      state.competencia_area_create_loading = false;
    },
    // update
    competenciaAreaUpdate: (state, { payload = { conductas: [] } }) => {
      state.competencia_area_update_loading = true;
    },
    competenciaAreaUpdateSuccess: (state) => {
      state.competencia_area_update_loading = false;
    },
    // delete
    competenciaAreaDelete: (state, { payload = {} }) => {
      state.competencia_area_delete_loading = true;
    },
    competenciaAreaDeleteSuccess: (state) => {
      state.competencia_area_delete_loading = false;
    },
    // list
    competenciaAreas: (state, { payload }) => {
      state.competencia_areas_checked = [];
      state.competencia_areas_loading = true;
    },
  },
});

export const competenciaAreaActions = competenciaAreaSlice.actions;
export const competenciaAreaReducer = competenciaAreaSlice.reducer;
