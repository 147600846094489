import React from "react";
import { useDispatch } from "react-redux";
import { DotMenu, DotMenuItem } from "../../../../common/components/DotMenu";
import { departamentoActions } from "../handlers/redux";

export const DepartamentoTableAccionMenu = ({ item = {} }) => {
  const dispatch = useDispatch();

  const onEdit = () => {
    dispatch(departamentoActions.departamentoSet({ ...item }));
    dispatch(departamentoActions.setDepartamentoCreateModal({ open: true }));
  };

  const onDelete = () => {
    let deseaEliminar = window.confirm(
      "Desea eliminar el departamento seleccionado?"
    );
    if (deseaEliminar) {
      // @ts-ignore
      dispatch(departamentoActions.departamentoDelete({ id: item?.id }));
    }
  };

  return (
    <DotMenu
      dir="left"
      className="w-10 h-6  flex flex-row justify-center items-center"
    >
      <>
        <DotMenuItem onClick={onEdit}>Editar</DotMenuItem>
        <DotMenuItem onClick={onDelete}>Eliminar</DotMenuItem>
      </>
    </DotMenu>
  );
};
