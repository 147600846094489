import { QuestionCircle } from "react-bootstrap-icons";

function FinalizarConfiguracion({ onSecondaryClick, onPrimaryClick }) {
  //
  return (
    <div>
      <h3 className="text-center text-lg font-semibold">
        ¿Desea activar esta escala?
      </h3>
      <div className="w-full flex justify-center my-4  #0EA5E9">
        <div className="bg-yellow-400 p-2 rounded-md">
          <QuestionCircle size={48} color={"white"} />
        </div>
      </div>
      <p className="text-center text-sm my-2">
        De activar esta escala, la anterior quedará como "No vigente".
      </p>
      <div className="flex items-center gap-2 mt-4">
        <button
          onClick={onSecondaryClick}
          className="w-full border border-black rounded-md py-3"
        >
          Sólo guardar
        </button>
        <button
          onClick={onPrimaryClick}
          className="w-full border border-sky-500 bg-sky-500 py-3 text-white rounded-md"
        >
          Activar ahora
        </button>
      </div>
    </div>
  );
}

export default FinalizarConfiguracion;
