import React, { useState } from "react";
import { CaretDownFill, ChevronBarDown } from "react-bootstrap-icons";

const Dropdown = ({
  label = "",
  options = [],
  buttonClassName = "",
  aditionalAction = null,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    if (aditionalAction) {
      const value = !isOpen ? "h-24" : "";
      aditionalAction(value);
    }
    setIsOpen(!isOpen);
  };
  const buttonClassNameDefault =
    "inline-flex items-center gap-2 justify-center w-full rounded-md text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-nonex focus:ring-2x focus:ring-offset-2x focus:ring-indigo-500x bg-whitex borderx border-gray-300x px-4x py-2x shadow-smx";
  return (
    <div className="relative inline-block text-left">
      <div>
        <button
          type="button"
          className={`${buttonClassNameDefault} ${buttonClassName}`}
          id="options-menu"
          onClick={toggleDropdown}
        >
          {label}
          <CaretDownFill size={11} />
        </button>
      </div>

      {isOpen && (
        <div
          className="z-20 origin-top-right absolute right-0 mt-2 w-56x rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="options-menu"
        >
          <div className="py-1" role="none">
            {/* Dropdown items go here */}
            {options.map((option, odix) => (
              <button
                onClick={option.action}
                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                role="menuitem"
              >
                {option.label}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Dropdown;
