import React, { useEffect, useState } from "react";
import { BeneficioConfiguracionLayout } from "../components/BeneficioConfiguracionLayout";
import { useNavigate, useParams } from "react-router-dom";
import { beneficiosRoutes, beneficiosRoutesWindow } from "../handlers/routes";
import { useDispatch, useSelector } from "react-redux";
import { commonActions } from "src/modules/common/handler/redux";
import { TipoConfiguracionBeneficioSelect } from "../components/TipoConfiguracionBeneficio";
import { PersonDashFill } from "react-bootstrap-icons";
import { CategoriaBeneficio } from "../components/CategoriaBeneficio";
import SelectModal from "src/modules/common/components/SelectModal";
import { BeneficioConfiguracionForm } from "./BeneficioConfiguracionForm";
import { PencilFill, TrashFill, X } from "react-bootstrap-icons";
import DataTable from "src/modules/common/components/DataTable";
import SearchInput from "src/modules/remuneracion/components/SearchInput";
import MostrarPaginas from "src/modules/common/components/MostrarPaginas";
import ReactSelect from "react-select";
import { beneficioActions } from "../handlers/redux";
import { BeneficioConfiguracionFormEdit } from "./BeneficioConfiguracionFormEdit";
import CargosHabilitadosList from "../components/CargosHabilitadosList";
import ColaboradoresHabilitadosList from "../components/ColaboradoresHabilitadosList";
import Modal from "src/modules/common/components/Modal";
import { BeneficioFormView } from "./BeneficioFormView";
import userDefault from "src/modules/common/components/images/user_default.png";
import { colaboradorActions } from "src/modules/colaborador/modules/colaborador/handlers/redux";
import { ErrorToast } from "src/modules/common/components/ErrorToast";
import { WarningToast } from "src/modules/common/components/WarningToast";

const initialBeneficio = {
  id: 0,
  nombre: "beneficio",
  descripcion: "desc",
  tipo_config_beneficio: {
    id: 0,
    nombre: "",
  },
  requisitos: [],
};

export function BeneficioColaboradorDetail() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const params = useParams();
  const { idColaborador } = params;
  const colaborador = useSelector((state) => state.beneficio.colaborador);
  const beneficio = useSelector((state) => state.beneficio.beneficio);
  const beneficio_loading = useSelector(
    (state) => state.beneficio.beneficio_loading
  );
  const beneficios_colaborador = useSelector(
    (state) => state.beneficio.beneficios_colaborador
  );
  const beneficios_colaborador_count = useSelector(
    (state) => state.beneficio.beneficios_colaborador_count
  );
  const beneficios_colaborador_loading = useSelector(
    (state) => state.beneficio.beneficios_colaborador_loading
  );
  const beneficios_colaborador_filter = useSelector(
    (state) => state.beneficio.beneficios_colaborador_filter
  );
  const beneficios_colaborador_checked = useSelector(
    (state) => state.beneficio.beneficios_colaborador_checked
  );
  const [openDetailModal, setOpenDetailModal] = useState(false);
  const [beneficioDetail, setBeneficioDetail] = useState(initialBeneficio);

  useEffect(() => {
    dispatch(commonActions.setTitle("Admin"));
    dispatch(commonActions.showSidebar(false));
    dispatch(beneficioActions.getColaborador({ idColaborador }));
    dispatch(beneficioActions.getBeneficiosColaborador({ idColaborador }));
    dispatch(beneficioActions.resetCheckBeneficiosColaborador());
  }, []);

  const onClickBack = () => {
    navigate(beneficiosRoutesWindow.COLABORADORES);
  };

  const getEstadoColor = (estado) => {
    return estado ? "bg-green-500 text-white" : "bg-red-500 text-white";
  };

  const onUpdateBeneficio = (updateBeneficio) => {
    let updateBeneficioCopy = { ...updateBeneficio };
    updateBeneficioCopy.tipo_config_beneficio =
      updateBeneficio.tipo_config_beneficio.id;
    delete updateBeneficioCopy.cargos;
    delete updateBeneficioCopy.colaboradores;
    dispatch(beneficioActions.updateBeneficio(updateBeneficioCopy));
    dispatch(commonActions.setSelectModal({ open: false }));
  };

  const onOpenDetailModal = (beneficio) => {
    setBeneficioDetail(beneficio);
    setOpenDetailModal(true);
  };

  const onOpeneEditModal = (beneficio) => {
    setBeneficioDetail(beneficio);
    dispatch(commonActions.setSelectModal({ open: true }));
  };

  const agregarBeneficio = () => {
    if (!colaborador.beneficio_habilitado) {
      WarningToast({
        message:
          "El colaborador se encuentra excluído de los beneficios por el momento.",
      });
      return;
    }
    if (colaborador.cargo) {
      dispatch(beneficioActions.setMostrarAlcance(false));
      dispatch(beneficioActions.setMostrarExcluidos(true));
      dispatch(
        beneficioActions.setBeneficiosBody({
          cargos: [colaborador.cargo.id],
          colaboradores: [],
        })
      );
      dispatch(
        beneficioActions.setOnClickBackBeneficioConfiguracion(
          beneficiosRoutesWindow.COLABORADORES
        )
      );
      navigate(beneficiosRoutesWindow.CONFIG);
    } else {
      WarningToast({
        message:
          "El colaborador debe pertenecer a un cargo para poder asignarle beneficios.",
      });
    }
  };

  const datatableConfig = {
    headers: [
      {
        title: "Disponibles",
        contentClass: "font-bold px-2 py-4",
        onOrder: false,
        // accessor: "nombre",
        contentRenderer: (item) => (
          <div className="flex flex-col">
            <span className="font-semibold">{item.nombre}</span>
            <span className="text-sm">{item.descripcion}</span>
          </div>
        ),
      },
      {
        title: "Estado",
        contentClass: "font-bold px-2 py-4",
        // onOrder: setOrdering(""),
        // accessor: "nombre",
        contentRenderer: (item) => (
          <div className="flex items-center">
            <span
              className={`p-2 rounded-md text-sm ${getEstadoColor(
                item.vigente
              )}`}
            >
              {item.vigente ? "Vigente" : "No vigente"}
            </span>
          </div>
        ),
      },
      {
        title: "Requisitos",
        contentClass: "font-bold px-2 py-4",
        // accessor: "nombre",
        contentRenderer: (item) => (
          <div className="flex flex-row">
            <button
              className="flex items-center gap-2 border rounded-md py-2 px-3 border-gray-500 text-sm"
              onClick={() => onOpenDetailModal(item)}
            >
              <span className="text-sm">Ver requisitos</span>
            </button>
            <div
              className="flex flex-row gap-2 items-center pl-3 cursor-pointer ml-2"
              onClick={() => onOpeneEditModal(item)}
            >
              <PencilFill color="#0EA5E9" />
            </div>
          </div>
        ),
      },
    ],
    rowActions: [
      {
        label: "Excluir",
        action: (item) => {
          const deseaExcluir = window.confirm(
            "¿Está seguro de excluir a este colaborador del beneficio seleccionado?"
          );
          if (deseaExcluir) {
            excluirColaboradorBeneficio([item.id]);
          }
        },
      },
    ],
  };

  const handleOnChangeExcluir = (e) => {
    const value = e.target.checked;
    const message = value
      ? "¿Esta seguro de excluir a este colaborador de todos los beneficios?"
      : "¿Esta seguro de incluir a este colaborador a los beneficios?";
    const deseaModificar = window.confirm(message);
    if (deseaModificar) {
      // TODO: Cambiar el valor de beneficio_habilitado de Empleado
      console.log("colaborador", idColaborador);
      dispatch(
        beneficioActions.cambiarBeneficioHabilitadoColaborador(idColaborador)
      );
    }
  };

  const getImagenJefe = () => {
    if (colaborador.jefe_directo.foto_empleado) {
      return colaborador.jefe_directo.foto_empleado;
    } else {
      return userDefault;
    }
  };

  const getRelacionColor = (relacion) => {
    let relacionColor = "bg-slate-200 text-black";
    const colores = {
      "Jefe directo": "bg-red-500",
      Par: "bg-orange-400",
      Subalterno: "bg-sky-500",
      Autoevaluación: "bg-black",
    };
    if (colores[relacion]) return colores[relacion];
    return relacionColor;
  };

  const onCheckAll = () => {
    let checkedData = JSON.parse(JSON.stringify(beneficios_colaborador));
    if (beneficios_colaborador_checked.length > 0) {
      checkedData = [];
    }
    dispatch(beneficioActions.setCheckBeneficiosColaborador(checkedData));
  };

  const onCheckBeneficio = (item) => (event) => {
    dispatch(
      beneficioActions.checkBeneficiosColaborador({
        beneficio_aux: item,
        checked: event.target.checked,
      })
    );
  };

  const excluirColaboradorBeneficio = (beneficiosId) => {
    const payload = {
      beneficiosId: beneficiosId,
      colaboradoresIds: [idColaborador],
    };
    dispatch(beneficioActions.excluirColaboradoresBeneficio(payload));
  };

  const actionOptions = [
    {
      value: "excluir",
      label: "Excluir",
      // action: () => {},
    },
  ];

  const onAction = (v) => {
    if (v.value === "excluir") {
      if (beneficios_colaborador_checked.length < 1) {
        ErrorToast({ message: "Debe seleccionar al menos un beneficio." });
        return;
      }
      const deseaExcluir = window.confirm(
        "¿Está seguro de excluir a este colaborador de los beneficios seleccionados?"
      );
      if (deseaExcluir) {
        const beneficiosId = beneficios_colaborador_checked.map(
          (beneficio) => beneficio.id
        );
        excluirColaboradorBeneficio(beneficiosId);
      }
      return;
    }
  };

  return (
    <BeneficioConfiguracionLayout
      title={`${colaborador.nombre} ${
        colaborador.apellido ? colaborador.apellido : ""
      }`}
      onClickBack={onClickBack}
    >
      <div className="py-2 px-8 -mx-8">
        <div className="form flex flex-col items-center mt-4">
          <div className="flex flex-col gap-4 w-full md:w-8/12 border rounded-xl p-4">
            <div className="flex flex-row justify-between">
              <h2 className="font-semibold text-2xl">
                Ficha técnica del colaborador
              </h2>
            </div>
            <div className="flex flex-row justify-between gap-2">
              <div className="flex flex-col w-4/12">
                <div className="my-4 w-40">
                  <img
                    className="object-cover h-22 w-22 rounded-md"
                    src={
                      colaborador.foto_empleado
                        ? colaborador.foto_empleado
                        : userDefault
                    }
                    alt="Imagen del colaborador"
                  />
                </div>
                <span className="font-semibold">Jefe directo:</span>
                {colaborador.jefe_directo && (
                  <div className="flex flex-row py-4 text-sm">
                    <div className="w-auto mr-2">
                      <img
                        className="object-cover h-20 w-20 rounded-md"
                        src={getImagenJefe()}
                        alt="Imagen del colaborador"
                      />
                    </div>
                    <div className="flex flex-col">
                      <span className="">
                        {colaborador.jefe_directo.nombre}{" "}
                        {colaborador.jefe_directo.apellido}
                      </span>
                      <span className="font-semibold">
                        {colaborador.jefe_directo.cargo
                          ? colaborador.jefe_directo.cargo.nombre
                          : "Sin cargo"}
                      </span>
                    </div>
                  </div>
                )}
                {!colaborador.jefe_directo && (
                  <span className="text-sm mb-6">
                    Sin jefe directo asignado
                  </span>
                )}
                <div className="text-sm flex flex-col">
                  <span className="">Email de acceso:</span>
                  <span className="font-semibold">
                    {colaborador.email ? colaborador.email : "Sin email"}
                  </span>
                  <span className=" mt-2">Rol de acceso:</span>
                  <div className="font-semibold mt-2">
                    <span
                      className={`${getRelacionColor(
                        ""
                      )} p-1 rounded-md text-white`}
                    >
                      {"" || "Sin asignar"}
                    </span>
                  </div>
                </div>
              </div>
              <div className="flex flex-col w-8/12 pt-4 space-y-6">
                <div className="border rounded p-5">
                  <h3 className="font-semibold">Datos personales</h3>
                  <div className="columns-2 mt-2 space-y-6 text-sm">
                    <div>
                      <h3 className="">Nombre:</h3>
                      <span className="font-semibold">
                        {colaborador.nombre}
                      </span>
                    </div>
                    <div>
                      <h3 className="">Número de ID:</h3>
                      <span className="font-semibold">{colaborador.ci}</span>
                    </div>
                    <div>
                      <h3 className="">Apellido:</h3>
                      <span className="font-semibold">
                        {colaborador.apellido ? colaborador.apellido : ""}
                      </span>
                    </div>
                    <div>
                      <h3 className="">RUC:</h3>
                      <span className="font-semibold">
                        {colaborador.ruc ? colaborador.ruc : ""}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="border rounded p-5">
                  <h3 className="font-semibold">Datos laborales</h3>
                  <div className="columns-2 mt-2 space-y-6 text-sm">
                    <div>
                      <h3 className="">Cargo:</h3>
                      <span className="font-semibold">
                        {colaborador.cargo
                          ? colaborador.cargo.nombre
                          : "Sin cargo"}
                      </span>
                    </div>
                    <div>
                      <h3 className="">Sucursal:</h3>
                      <span className="font-semibold">
                        {colaborador.sucursal
                          ? colaborador.sucursal.nombre
                          : "Sin sucursal"}
                      </span>
                    </div>
                    <div>
                      <h3 className="">Departamento:</h3>
                      <span className="font-semibold">
                        {colaborador.departamento
                          ? colaborador.departamento.nombre
                          : "Sin departamento"}
                      </span>
                    </div>
                    <div>
                      <h3 className="">Fecha de ingreso:</h3>
                      <span className="font-semibold">
                        {colaborador.fecha_ingreso
                          ? colaborador.fecha_ingreso
                          : ""}
                      </span>
                    </div>
                    <div>
                      <h3 className="">Área:</h3>
                      <span className="font-semibold">
                        {colaborador.area
                          ? colaborador.area.nombre
                          : "Sin área"}
                      </span>
                    </div>
                    {false && (
                      <div>
                        <h3 className="">Escala salarial:</h3>
                        <span className="font-semibold">
                          Verificar en nómina
                        </span>
                      </div>
                    )}
                    {true && (
                      <div>
                        <h3 className=""></h3>
                        <span className="font-semibold"></span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="space-y-2 mt-4">
              <div className="flex flex-col items-start">
                <div className="border-b pb-2 w-10/12">
                  <div className="flex flex-row">
                    <PersonDashFill className={"mt-1"} size={20} />
                    <h2 className="font-semibold text-lg ml-2">
                      Excluir colaborador
                    </h2>
                  </div>
                </div>
                <div className="flex flex-row mt-4">
                  <div>
                    <input
                      className="w-5 h-5 mr-2"
                      type="checkbox"
                      checked={!colaborador.beneficio_habilitado}
                      onChange={handleOnChangeExcluir}
                    />
                  </div>
                  <div className="ml-2">
                    <h3 className="font-bold">No incluir en los beneficios</h3>
                    <div className="text-sm text-gray-400">
                      Excluye la visualización de beneficios a este colaborador
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="pb-2 w-full md:w-8/12 mt-6">
            <div className="flex flex-row justify-between">
              <h2 className="font-semibold text-2xl">Beneficios asignados</h2>
              <div className="flex flex-row align-middle gap-4">
                <div className="text-sm">
                  <ReactSelect
                    className="w-52 h-10 bg-blue-200 inline-grid"
                    value={{
                      value: "",
                      label: `(${beneficios_colaborador_checked.length}) seleccionados`,
                    }}
                    options={actionOptions}
                    onChange={onAction}
                  ></ReactSelect>
                </div>
                <button
                  onClick={agregarBeneficio}
                  className="rounded-md bg-sky-500 text-white p-3"
                >
                  Agregar nuevo beneficio
                </button>
              </div>
            </div>
            <DataTable
              containerClass={""}
              config={datatableConfig}
              data={beneficios_colaborador}
              loading={beneficios_colaborador_loading}
              noHeaders={false}
              //
              totalRecords={beneficios_colaborador_count}
              // onPaginate={onPaginate}
              // outerPageSize={outerPageSize}
              //
              checkable={true}
              onCheckAll={onCheckAll}
              checkedItems={beneficios_colaborador_checked}
              onItemCheck={onCheckBeneficio}
            />
          </div>
          <SelectModal
            title="Agregar"
            content={
              <BeneficioConfiguracionFormEdit
                beneficio={beneficioDetail}
                onSubmit={onUpdateBeneficio}
              />
            }
          />
          <Modal isOpen={openDetailModal}>
            <div className="rounded-lg bg-white w-10/12 md:w-6/12 p-2 md:p-5 flex flex-col gap-5">
              <div className="modal-title">
                <div className="w-full flex justify-between items-center">
                  <div className="flex items-center gap-4">
                    <h2 className="font-semibold text-xl">Beneficio</h2>
                  </div>
                  <button
                    onClick={() => {
                      setOpenDetailModal(false);
                    }}
                  >
                    <X size={24} />
                  </button>
                </div>
              </div>
              <div className="modal-body">
                <div className="">
                  <div>
                    <BeneficioFormView beneficio={beneficioDetail} />
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        </div>
      </div>
    </BeneficioConfiguracionLayout>
  );
}
