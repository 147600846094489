import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate, useParams, useLocation } from "react-router-dom";
import ReactSelect from "react-select";
import backButton from "src/modules/common/components/images/back-arrow.png";
import ToggleSwitch from "src/modules/common/components/ToggleSwitch";
import { commonActions } from "src/modules/common/handler/redux";
import { kpiActions } from "../handlers/redux";

function KpiFormularioPage() {
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const kpi = useSelector((state) => state.kpi.kpi);
  const metricas = useSelector((state) => state.kpi.metricas);
  const periodos = useSelector((state) => state.kpi.periodos);
  const [activarEscalaChecked, setActivarEscalaChecked] = useState(false);
  const metricasConMeta = [1, 2];
  const checkMetaDisplay = (metrica) => {
    if (activarEscalaChecked) {
      return false;
    }
    if (!metrica) return true;
    return metricasConMeta.includes(parseInt(metrica));
  };
  const switchChangeHandler = () => {
    setActivarEscalaChecked(!activarEscalaChecked);
    // resetamos valor de Meta
    dispatch(
      kpiActions.kpiSet({
        ...kpi,
        meta: 0,
        rangos: [],
      })
    );
  };

  !!process.env.REACT_APP_DEBUG && console.log({ params });
  !!process.env.REACT_APP_DEBUG &&
    console.info("location state", location.state?.colaboradorId);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(commonActions.setTitle(""));
    dispatch(commonActions.showSidebar(false));
    dispatch(kpiActions.metricas());
    dispatch(kpiActions.periodos());
    if (kpi.id) setActivarEscalaChecked(kpi?.rangos?.length > 0);
  }, []);

  useEffect(() => {
    checkMetaDisplay(kpi.metrica);
    !!process.env.REACT_APP_DEBUG && console.info("kpi", kpi);
  }, [kpi]);

  const onChange =
    (field) =>
    ({ target }) => {
      dispatch(
        kpiActions.kpiSet({
          ...kpi,
          [field]: target.value,
        })
      );
    };

  const onChangeActualizarTodos = (e) => {
    !!process.env.REACT_APP_DEBUG &&
      console.info("onChangeActualizarTodos", e.target.checked);
    dispatch(
      kpiActions.kpiSet({
        ...kpi,
        modifica_copias: e.target.checked,
      })
    );
  };

  const onSubmit = (e) => {
    e.preventDefault();
    checkRangos();
    if (kpi.id) {
      dispatch(kpiActions.kpiUpdate());
      return;
    }
    const colaboradorId = location.state?.colaboradorId || false;
    if (colaboradorId) {
      dispatch(kpiActions.kpiCreate({ colaboradorId: colaboradorId }));
      return;
    }
    dispatch(kpiActions.kpiCreate());
  };

  const onChangeRango = (index, field, value) => {
    const tieneRangos = kpi?.rangos?.length > 0;
    let rangos = [];
    let lastRangoIndex = 0;
    if (tieneRangos) {
      rangos = JSON.parse(JSON.stringify(kpi.rangos));
    }
    // rellenamos posibles indexes vacios
    if (rangos.length < 5) {
      lastRangoIndex = rangos.length;
      for (let i = lastRangoIndex; i < 5; i++) {
        rangos[i] = { desde: "", hasta: "", resultado: "" };
      }
    }
    rangos[index][field] = Number(value);
    !!process.env.REACT_APP_DEBUG && console.info("new rangos", rangos);
    //
    dispatch(
      kpiActions.kpiSet({
        ...kpi,
        rangos: rangos,
      })
    );
  };

  const checkRangos = () => {
    const tieneRangos = kpi?.rangos?.length > 0;
    let rangos = [];
    //
    if (tieneRangos) {
      rangos = JSON.parse(JSON.stringify(kpi.rangos));
      for (let i = 0; i < rangos.length; i++) {
        let estaVacio =
          !rangos[i].desde && !rangos[i].hasta && !rangos[i].resultado;
        if (estaVacio) {
          rangos.splice(i, 1);
          i--;
        }
      }
      //
      dispatch(
        kpiActions.kpiSet({
          ...kpi,
          rangos: rangos,
        })
      );
    }
  };

  const renderInput = (
    field,
    type,
    name,
    placeholder = null,
    description = null,
    options = []
  ) => {
    //
    let inputContainerClass = "flex flex-col gap-2";
    if (field === "meta") {
      inputContainerClass = checkMetaDisplay(kpi["metrica"])
        ? inputContainerClass
        : "hidden";
    }
    if (type === "select") {
      return (
        <div className="flex flex-col gap-2">
          <label className="font-bold text-base" htmlFor="">
            {name}
            {!!name && ":"}
            {!name && <span>&nbsp;</span>}
          </label>
          <select
            name={`select-${field}`}
            id={`select-${field}`}
            value={kpi[field]}
            onChange={onChange(field)}
            className="border rounded placeholder-zinc-600 border-zinc-200 px-2 py-3 text-sm"
          >
            {options.map((opt, idx) => (
              <option key={`opt-${field}-${idx}`} value={opt.value}>
                {opt.label}
              </option>
            ))}
          </select>
          {!!description && <div className="text-sm">{description}</div>}
        </div>
      );
    }
    //
    return (
      <div className={inputContainerClass}>
        <label className="font-bold text-base" htmlFor="">
          {name}
          {!!name && ":"}
          {!name && <span>&nbsp;</span>}
        </label>
        <input
          type={type}
          name={field}
          value={kpi[field] || ""}
          onChange={onChange(field)}
          placeholder={placeholder || "Sin especificar"}
          className="border rounded placeholder-zinc-600 border-zinc-200 px-2 py-3 text-sm"
        />
        {!!description && <div className="text-sm">{description}</div>}
      </div>
    );
  };

  const renderTablaEscala = () => {
    if (!activarEscalaChecked) {
      return <></>;
    }
    const inputClass =
      "border rounded placeholder-zinc-600 border-zinc-200 px-2 py-2 text-sm mr-1 mb-1";
    return (
      <>
        <div className="col-span-1 mr-6 my-2 flex justify-around">
          <span className="font-bold">Desde</span>
          <span className="font-bold">Hasta</span>
          <span className="font-bold">Resultado</span>
        </div>
        <div className="col-span-1 mr-6 my-2 md:col-span-2">
          {[1, 2, 3, 4, 5].map((rango, index) => (
            <div key={`rango-${rango}`}>
              <input
                className={inputClass}
                type="text"
                id={`desde-${rango}`}
                key={`desde-${rango}`}
                value={kpi?.rangos ? kpi?.rangos[index]?.desde : ""}
                onChange={(e) => onChangeRango(index, "desde", e.target.value)}
              />
              <input
                className={inputClass}
                type="text"
                id={`hasta-${rango}`}
                key={`hasta-${rango}`}
                value={kpi?.rangos ? kpi?.rangos[index]?.hasta : ""}
                onChange={(e) => onChangeRango(index, "hasta", e.target.value)}
              />
              <input
                className={inputClass}
                type="text"
                id={`resultado-${rango}`}
                key={`resultado-${rango}`}
                value={kpi?.rangos ? kpi?.rangos[index]?.resultado : ""}
                onChange={(e) =>
                  onChangeRango(index, "resultado", e.target.value)
                }
              />
            </div>
          ))}
        </div>
      </>
    );
  };
  return (
    <>
      <div className="flex items-center bg-zinc-100 -mx-8 py-2.5 px-7 border-b">
        <div
          onClick={() => navigate("/kpis/colaboradores")}
          className={"h-full  flex items-center pr-2 -mr-2 z-10 cursor-pointer"}
        >
          <img className="back" src={backButton} alt="Regresar" />
        </div>
        <div className="font-medium px-4 text-xl">Configuracion de KPI</div>
      </div>

      <div className="flex justify-center items-center w-full mt-8">
        <div className="flex flex-col w-full md:w-11/12">
          <div className="grid grid-cols-1 md:grid-cols-12">
            {/* DATOS KPI */}
            <div className="col-span-1 md:col-span-12 ">
              <div className="font-bold text-lg border-b mb-2 pb-2">
                Detalle de KPI
              </div>
              <form onSubmit={onSubmit}>
                <div className="grid grid-cols-1 md:grid-cols-2">
                  {/* nombre */}
                  <div className="col-span-2 mr-6 my-2 ">
                    {renderInput(
                      "nombre",
                      "text",
                      "Nombre del Indicador a Evaluar",
                      "Depositos a la Vista/Depositos totales"
                    )}
                  </div>
                  {/* descripcion */}
                  <div className="col-span-2 mr-6 my-2 ">
                    {renderInput(
                      "descripcion",
                      "text",
                      "Breve descripcion del indicador"
                    )}
                  </div>
                  {/* metrica */}
                  <div className="col-span-1 mr-6 my-2 ">
                    {renderInput(
                      "metrica",
                      "select",
                      "Metricas a evaluar",
                      "",
                      "",
                      metricas
                    )}
                    <div className="flex items-center gap-8">
                      <div className="flex items-centers mt-4 gap-4">
                        <p>Activar escala</p>
                        <ToggleSwitch
                          className="mr-2"
                          checked={activarEscalaChecked}
                          onChange={switchChangeHandler}
                        />
                      </div>
                    </div>
                  </div>
                  {/* meta */}
                  <div className="col-span-1 mr-6 my-2 ">
                    {renderInput("meta", "number", "Meta")}
                  </div>
                  {/* tabla de escala */}
                  {renderTablaEscala()}
                  {/* periodicidad */}
                  <div className="col-span-1 mr-6 my-2">
                    {renderInput(
                      "periodo",
                      "select",
                      "Perido o Fecuencia",
                      "",
                      "",
                      periodos
                    )}
                  </div>
                  <div className="col-span-2 mt-4 flex justify-start">
                    <button className="bg-sky-500 border-2 border-sky-500 rounded px-4 py-1 text-white">
                      Guardar indicador
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default KpiFormularioPage;
