import axios from "axios";
import { useSelector } from "react-redux";

export function DownloadPlantilla({ plantilla = "estructura", token = "" }) {
  !!process.env.REACT_APP_DEBUG && console.info("PLANTILLA", plantilla);
  const URL_BASE =
    process.env.REACT_APP_API_URL || "http://localhost:8000/api/admin";
  const requestURL = URL_BASE + `/estructura/descargar-archivo-${plantilla}/`;
  const headers = {
    "Content-Type": "blob",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };
  //
  axios({
    url: requestURL,
    method: "GET",
    headers: headers,
    responseType: "blob",
  })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      // La general de antes ahora se volvió parcial y el general ahora es uno con más datos
      const nameFile =
        plantilla === "general"
          ? "Plantilla Nomina Parcial - Wel 2.0"
          : "Plantilla Nomina General - Wel 2.0";
      link.setAttribute("download", `${nameFile}.xlsx`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    })
    .catch(
      (e) =>
        !!process.env.REACT_APP_DEBUG &&
        console.info("Error al descargar el archivo")
    );
}
